import { Pipe, PipeTransform } from '@angular/core';
import { DeviceInformation } from '../models';

@Pipe({
  name: 'verizonStatusIs'
})
export class VerizonStatusPipe implements PipeTransform {
  transform(verizonDeviceStatus: string, expectedStatus: string, deviceInformation: DeviceInformation): boolean {
    if ((deviceInformation.imei && deviceInformation.imei !== 'Unknown') &&
      (deviceInformation.iccId && deviceInformation.iccId !== 'Unknown') &&
      (deviceInformation.deviceId && deviceInformation.deviceId !== 'Unknown') &&
      (verizonDeviceStatus === expectedStatus)) {
      return true;
    } else {
      return false;
    }
  }
}

import { AccessLevel, UserPermission } from './../../models/enum.models';
import { DeviceHelperService } from './../../services/helpers/device-helper.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { NotificationService, DataService, UserPermissionService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import {
  InstrumentType, DeviceDataRequest, DeviceInformationResult, SearchRequest, DeviceInformation,
  InstrumentDialogData, ConfirmationDialogData, ConfirmationDialogResponse, InstrumentTypeModel
} from 'src/app/models';
import { InstrumentDialogComponent } from '../instrument-dialog/instrument-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate } from 'src/app/shared/translateServiceHelper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unregistered-instruments',
  templateUrl: './unregistered-instruments.component.html',
  styleUrls: ['./unregistered-instruments.component.scss']
})
export class UnregisteredInstrumentsComponent implements OnInit, OnDestroy {
  public deviceInformationResult: DeviceInformationResult;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;

  private subscription: Subscription = new Subscription();
  private instrumentType: InstrumentType;
  private selectedModels: InstrumentTypeModel[];
  private currentDeviceDataRequest: DeviceDataRequest;

  get selectedInstrumentType(): InstrumentType {
    return this.instrumentType;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.instrumentType !== instrumentType) {
      this.instrumentType = instrumentType;
    }
  }

  constructor(
    private deviceHelperService: DeviceHelperService,
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.getAllData();
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private getAllData(): void {
    this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
      this.selectedInstrumentType = selectionState.instrumentType;
      // Changing the InstrumentType causes the DeviceListComponent to request data
      this.selectedModels = selectionState.models;
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);
    }));
  }

  public deviceDataRequested($event: DeviceDataRequest): void {
    this.currentDeviceDataRequest = $event;
    this.getDevicesWithCurrentSearchState();
  }

  private getDevicesWithCurrentSearchState(): void {
    const searchRequest: SearchRequest = this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequest, this.selectedInstrumentType?.instrumentTypeId, this.selectedModels?.map(m => m.modelId));

    this.subscription.add(this.dataService.getUnregisteredDeviceInformation(searchRequest).subscribe((result: DeviceInformationResult) => {
      this.deviceInformationResult = result;
    }));
  }

  public async onEditDevice(deviceInformation: DeviceInformation): Promise<void> {
    if (deviceInformation?.deviceInformationId > 0) {
      this.subscription.add(this.dataService.getDeviceInformationById(deviceInformation.deviceInformationId).subscribe((fullDeviceInformation: DeviceInformation) => {
        const options: InstrumentDialogData = {
          deviceInformation: fullDeviceInformation
        };

        this.dialog.open(InstrumentDialogComponent, {
          data: options,
          width: '75%',
          disableClose: true
        });

        this.getDevicesWithCurrentSearchState();
      }));
    } else {
      const initialDeviceInformation = this.deviceHelperService.getInitialDeviceInformation();

      const options: InstrumentDialogData = {
        deviceInformation: initialDeviceInformation
      };

      const dialogRef = this.dialog.open(InstrumentDialogComponent, {
        data: options,
        width: '75%',
        disableClose: true
      });

      await firstValueFrom(dialogRef.afterClosed());

      this.getDevicesWithCurrentSearchState();
    }
  }

  public async onDeleteDevice($deviceInformation: DeviceInformation): Promise<void> {
    const dialogOptions: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.InstrumentKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.InstrumentKey, $deviceInformation.serialNumber)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: dialogOptions
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteDeviceInformation($deviceInformation.deviceInformationId)
        .subscribe(() => {
          this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.InstrumentKey });
          this.getDevicesWithCurrentSearchState();
        }));
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { NotificationService, UserPermissionService, DataService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import { UserPermission, AccessLevel, AssayDefinition, AssayDefinitionTemplate, ConfirmationDialogData, ConfirmationDialogResponse } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-assay-definitions',
  templateUrl: './assay-definitions.component.html',
  styleUrls: ['./assay-definitions.component.scss']
})
export class AssayDefinitionsComponent implements OnInit, AfterViewInit, OnDestroy {

  public displayedColumns: string[] = ['code', 'definitionName', 'actions'];
  public definitions: AssayDefinition[] = [];
  public dataSource: MatTableDataSource<AssayDefinition> = new MatTableDataSource<AssayDefinition>();

  private subscription: Subscription = new Subscription();
  @ViewChild(MatSort) private sort: MatSort;
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAssayFiles], null, selectionState.instrumentType?.instrumentTypeId);
    }));

    this.loadDefinitions();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public async onAddDefinition(template: TemplateRef<AssayDefinitionTemplate>): Promise<void> {
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, AssayDefinitionTemplate | 'false'>(template, { width: '75%' });

    const result = await firstValueFrom<AssayDefinitionTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const definition: AssayDefinition = {
        assayDefinitionId: 0,
        code: result.code,
        definitionName: result.name,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null
      };

      this.subscription.add(this.dataService.addAssayDefinition(definition).subscribe(id => {
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.AssayDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }

  public async onEditDefinition(template: TemplateRef<unknown>, definition: AssayDefinition): Promise<void> {
    const dialogRef = this.dialog.open<unknown, AssayDefinition, AssayDefinitionTemplate | 'false'>(template, { data: definition, width: '75%' });

    const result = await firstValueFrom<AssayDefinitionTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      definition.code = result.code;
      definition.definitionName = result.name;

      this.subscription.add(this.dataService.updateAssayDefinition(definition).subscribe(id => {
        this.notificationService.success(TranslateConstants.UpdateSuccessKey, { type: TranslateConstants.AssayDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }

  public async onDeleteDefinition(definition: AssayDefinition): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.AssayDefinitionKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.AssayDefinitionKey, definition.definitionName)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteAssayDefinition(definition.assayDefinitionId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.AssayDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }


  public loadDefinitions(): void {
    this.subscription.add(this.dataService.getAssayDefinitions().subscribe((definitions: AssayDefinition[]) => {
      this.definitions = definitions;
      this.dataSource.data = this.definitions;
    }));
  }
}

<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content class="content">
    <p class="dialog-message" [innerHTML]="data.message"></p>

    <mat-form-field appearance="outline">
      <input matInput [(ngModel)]="enteredValue" (ngModelChange)="enteredValue_Changed($event)">
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="actions">
    <button mat-button class="btn" style="margin-right:1.0rem;" (click)="onNoClick()" cdkFocusInitial>{{ data.cancelText
      }}</button>
    <button mat-button class="btn btn-confirm" (click)="onYesClick()" [disabled]="yesButtonDisabled">{{ data.confirmText
      }}</button>
  </div>
</div>

<div class="container">
  <div class="container-grid">
    <div class="certificate-refresh-container">
      <h1>{{ 'CertificateRefresh.RefreshDeviceCertificates' | translate }}</h1>
      <div *ngIf="canRefreshCertificate">
        <button mat-raised-button color="warn" [disabled]="!selectedInstrumentType" (click)="forceCertificateRefresh()">
          {{ 'CertificateRefresh.ForceCertificateRefresh' | translate }}
        </button>
        <div class="refresh-device-cert-warning">{{ 'Utilities.RefreshDeviceCertificatesWarning' | translate }}</div>
        <div>{{ 'Utilities.RefreshDeviceCertificatesInfo' | translate }}</div>
      </div>
      <div *ngIf="!canRefreshCertificate">{{ 'CertificateRefresh.UnableToRefreshCertificateForInstrumentType' |
        translate }}</div>
    </div>
  </div>

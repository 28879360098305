import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { AppInsightsService } from '../services/app-insights.service';
import { ApiError, ValidationResult } from '../models/error.models';
import { NotificationService } from '../services/notification.service';
import { expectedErrorsString, ignoreErrorsString } from '../constants/interceptors.constants';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '../constants/translate-constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private appInsights: AppInsightsService,
    private injector: Injector) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const expectedErrors: string[] = [];
    if (request.headers.has(expectedErrorsString)) {
      expectedErrors.push(...request.headers.getAll(expectedErrorsString));
    }
    const ignoreErrors = request.headers.has(ignoreErrorsString);
    const updatedReq = request.clone({ headers: request.headers.delete(expectedErrorsString).delete(ignoreErrorsString) });

    return next.handle(updatedReq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let isClientSide = false;
          let errorMessage = '';

          if (ignoreErrors || (expectedErrors && expectedErrors.includes(error.status.toString()))) {
            return throwError(() => error);
          }

          // Can't directly inject the NotificationService as it relies on the TranslateService, and the interceptor is part of the
          // https://stackoverflow.com/a/67153551
          const notificationService = this.injector.get(NotificationService);
          if (error.status === 403) {
            // Can't directly inject the TranslateService as it relies on the HttpClient, and the interceptor is part of the
            // https://stackoverflow.com/a/67153551
            const translateService = this.injector.get(TranslateService);
            translateService.get([TranslateConstants.UnauthorizedMessageKey, TranslateConstants.UnauthorizedTitleKey])
              .subscribe((translatedText: unknown) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                notificationService.errorExact(translatedText[TranslateConstants.UnauthorizedMessageKey], translatedText[TranslateConstants.UnauthorizedTitleKey]);
              });
          } else if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            isClientSide = true;
          } else {
            // server-side error
            if (typeof error.error === 'string') {
              errorMessage = this.getErrorMessage(error.error);
            } else if (error.error) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (error.error?.errors) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                errorMessage = this.getErrorMessage(JSON.stringify(error.error.errors));
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                errorMessage = this.getErrorMessage(error.error?.message ? error.error?.message as string : error.message);
              }
            } else {
              errorMessage = this.getErrorMessage(error.message);
            }

            notificationService.errorExact(errorMessage);

            isClientSide = false;
          }

          this.appInsights.logError(error, { message: errorMessage, isClientSide: isClientSide.toString() });
          console.error(errorMessage);

          return throwError(() => error);
        })
      );
  }

  private getErrorMessage(error: string): string {
    let message = '';

    try {
      const apiError: ApiError = JSON.parse(error) as ApiError;
      if (!apiError.message) {
        throw new Error('unknown ApiError');
      }
      return this.sanitizeErrorMessage(apiError.message);
    } catch (e) {
      try {
        const validationResult: ValidationResult = JSON.parse(error) as ValidationResult;

        validationResult.errors.forEach(err => {
          message += `${err.errorMessage}<br/>`;
        });

        return message;
      } catch (ex) {
        try {
          const errors = JSON.parse(error) as unknown;

          if (errors) {
            for (const key of Object.keys(errors)) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const value = errors[key];

              if (typeof value === 'string') {
                message += `${this.sanitizeErrorMessage(value)}<br/>`;
              } else if (value instanceof Array) {
                message += `${value.reduce((prev: string, curr: string) => {
                  return prev ? prev + `<br/>${curr}` : curr;
                }) as string}<br/>`;
              }
            }
          }

          return message;
        } catch (ex) {
          return this.sanitizeErrorMessage(error);
        }
      }
    }
  }

  private sanitizeErrorMessage(error: string): string {
    let message = error;

    const newLineIndex = error.indexOf('\n');
    if (newLineIndex >= 0) {
      message = error.substring(0, newLineIndex);
    }

    const regex = /System.([a-zA-Z])*Exception:/g;
    return message.replace(regex, '').trim();
  }
}

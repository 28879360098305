import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, forkJoin, Observable, Subscription } from 'rxjs';
import { clone, isFeatureEnabledForInstrumentTypeName, tryGetSettingsFromJson } from 'src/app/shared/utils';
import { UserPermissionService, DataService, NotificationService, DeviceHelperService, ConfigurationService, SelectionAndCacheService } from 'src/app/services';
import {
  DeviceInformationResult, DeviceInformation, InstrumentGroup, DeviceInformationId, ListMode, DeviceDataRequest, SearchRequest,
  UpsertInstrumentGroupRequest, AccessLevel, UserPermission, CheckedDevicesEvent, DeviceRequest, InstrumentGroupDialogData, InstrumentType, Features, InstrumentTypeId
} from 'src/app/models';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DeviceSettingsConstants } from 'src/app/constants/deviceSettings-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { isTranslationLoaded, translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { addNestedFormControl, addNestedFormGroup, formGroupContains, getMinMaxValidationValues, removeNestedFormControl } from 'src/app/shared/utils.forms';
import { MatSelectChange } from '@angular/material/select';
import { MatTabGroup } from '@angular/material/tabs';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-instrument-group-dialog',
  templateUrl: './instrument-group-dialog.component.html',
  styleUrls: ['./instrument-group-dialog.component.scss']
})
export class InstrumentGroupDialogComponent implements OnInit, OnDestroy {
  @ViewChild('updateAllowlistFolderPaths_file', { static: false }) private updateAllowlistFolderPathsFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  public InstrumentTypeId = InstrumentTypeId;
  public deviceInformationResult: DeviceInformationResult;
  public groupDeviceInformations: DeviceInformation[] = [];
  public instrumentGroup: InstrumentGroup;
  public initialCheckedDevicesIds: DeviceInformationId[];
  public ListMode = ListMode;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private manageAllInstrumentsAccessLevel: AccessLevel = AccessLevel.Unauthorized; // explicitly All instruments permission
  private allowlistFolderPathsAccessLevel: AccessLevel = AccessLevel.Unauthorized;
  public instrumentTypes: InstrumentType[] = [];

  public devicesTabText: string;
  public manageDeviceSettingsTabText: string;
  public utilitiesTabText: string;

  public showManageDeviceSettingsTab = false;
  public showChangeDevicesButton = false;
  public showUpdateDeviceSettings = false;
  public showDeviceMessageDisclaimer = false;
  public showVirenaSettings = false;
  public showRetrySettings = false;
  public showDownloadSettings = false;
  public showDownloadSettings_Software = false;
  public showChunkDownloadTimeoutNotHonoredMessage = false;
  public chunkDownloadTimeoutNotHonoredMessage = '';
  public showClinicalModeSettings = false;
  public showTestResultsUpload = false;
  public showTestResultsUpload_DeleteAfterUpload = true;
  public showPerformanceDataSettings = false;
  public showAllowlistFolderPaths = false;

  public tryGetTranslateKey = DeviceSettingsConstants.tryGetTranslateKey;
  public DeviceSettingsConstants = DeviceSettingsConstants;

  private subscription = new Subscription();
  private updatedUnsavedCheckedDevices: DeviceInformation[] = [];
  private currentDeviceDataRequested: DeviceDataRequest;
  private disableUnregisteredExactMatch = true;
  private features: Features;

  private instrumentSettingDefaultsJsonByKey: { [key: string]: string };
  private modelIdsForSelectedInstrumentType: number[] = [];

  private testResultEnabledValueChangeSubscription: Subscription;

  private minMaxValidationValuesByKeyParts: Map<string, { min: string, max: string, minValue: number, maxValue: number }> = new Map<string, { min: string, max: string, minValue: number, maxValue: number }>();

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  // explicitly All instruments permission
  get canManageAllInstruments(): boolean {
    return this.manageAllInstrumentsAccessLevel === AccessLevel.AddUpdate;
  }

  get canAddUpdateAllowlistFolderPaths(): boolean {
    return this.canManageAllInstruments && this.allowlistFolderPathsAccessLevel === AccessLevel.AddUpdate;
  }

  private _changeDevicesMode = false;
  get changeDevicesMode(): boolean {
    return this._changeDevicesMode;
  }
  set changeDevicesMode(newValue: boolean) {
    this._changeDevicesMode = newValue;
    this.updateTabRelatedButtonVisibilities();
  }

  private _selectedTabIndex = 0;
  get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }
  set selectedTabIndex(newIndex: number) {
    this._selectedTabIndex = newIndex;
    this.updateTabRelatedButtonVisibilities();
  }

  public manageDeviceSettingsForm: UntypedFormGroup = this.fb.group({});

  constructor(@Inject(MAT_DIALOG_DATA) public data: InstrumentGroupDialogData,
    private dataService: DataService,
    private mdDialogRef: MatDialogRef<InstrumentGroupDialogComponent>,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private deviceHelperService: DeviceHelperService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService,
    private numberPipe: DecimalPipe,
    configurationService: ConfigurationService) {
    this.instrumentGroup = data.instrumentGroup;

    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
    if (this.instrumentGroup.instrumentTypeId === 0) {
      this.instrumentGroup.instrumentTypeId = this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId;
    }

    const clientConfig = configurationService.getClientConfiguration();
    if (clientConfig.instrumentSettingDefaultsJsonByKey) {
      this.instrumentSettingDefaultsJsonByKey = clientConfig.instrumentSettingDefaultsJsonByKey;
    } else {
      this.instrumentSettingDefaultsJsonByKey = {};
    }

    if (this.instrumentGroup.instrumentTypeId > 0) {
      this.updateModelIdsForSelectedInstrumentType();
    }

    if (isTranslationLoaded(this.translateService)) {
      this.updateTabsText();
    }
    this.subscription.add(this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.updateTabsText();
    }));

    this.features = clientConfig.features;
    this.refreshFromInstrumentTypeChange();
  }

  private updateTabsText() {
    this.devicesTabText = translate(this.translateService, TranslateConstants.InstrumentGroupsTabDevicesKey);
    this.manageDeviceSettingsTabText = translate(this.translateService, TranslateConstants.InstrumentGroupsTabManageDeviceSettingsKey);
    this.utilitiesTabText = translate(this.translateService, TranslateConstants.InstrumentGroupsTabUtilitiesKey);
  }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstrumentGroups], [UserPermission.ViewEverything], this.instrumentGroup?.instrumentTypeId);
      this.manageAllInstrumentsAccessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [UserPermission.ViewEverything], this.instrumentGroup?.instrumentTypeId);
      this.allowlistFolderPathsAccessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllowlistFolderPaths], [UserPermission.ViewEverything], this.instrumentGroup?.instrumentTypeId);

      this.refreshFeatureFlagsBySelectedInstrumentType();
    }));

    this.getDevicesByGroupId();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public getAllData(): void {
    this.subscription.add(
      forkJoin([
        this.dataService.getInstrumentGroupById(this.instrumentGroup.instrumentGroupId),
        this.dataService.getDeviceInformationByInstrumentGroupId(this.instrumentGroup.instrumentGroupId)
      ]).subscribe((results: [InstrumentGroup, DeviceInformation[]]) => {
        this.instrumentGroup = results[0];
        this.groupDeviceInformations = results[1];
      }));
  }

  public getDevicesByGroupId(): void {
    if (this.instrumentGroup?.instrumentGroupId) {
      this.subscription.add(this.dataService.getDeviceInformationByInstrumentGroupId(this.instrumentGroup.instrumentGroupId).subscribe((deviceInformationArray: DeviceInformation[]) => {
        this.groupDeviceInformations = deviceInformationArray;
      }));
    }
  }

  public removeDeviceFromGroup($event: DeviceInformation): void {
    const deviceInformationIndex = this.groupDeviceInformations.findIndex(x => x.deviceInformationId === $event.deviceInformationId);
    this.groupDeviceInformations.splice(deviceInformationIndex, 1);
    this.groupDeviceInformations = clone(this.groupDeviceInformations);
  }

  public updateDevices(): void {
    this.groupDeviceInformations = clone(this.updatedUnsavedCheckedDevices);
    this.changeDevicesMode = false;
    this.updatedUnsavedCheckedDevices.length = 0;
  }

  public cancelDevicesMode(): void {
    this.changeDevicesMode = false;
    this.updatedUnsavedCheckedDevices.length = 0;
  }

  public showDevicesGroups(): void {
    this.changeDevicesMode = true;
  }

  public saveInstrumentGroup(close: boolean = false): void {
    const upsertModel: UpsertInstrumentGroupRequest = {
      name: this.instrumentGroup.name,
      instrumentTypeId: this.instrumentGroup.instrumentTypeId,
      notes: this.instrumentGroup.notes?.length > 0 ? this.instrumentGroup.notes : undefined,
      deviceInformationIds: this.groupDeviceInformations.map(x => x.deviceInformationId),
      userIds: []
    };
    const addOrUpdateAction: Observable<number> = this.instrumentGroup.instrumentGroupId
      ? this.dataService.updateInstrumentGroup(upsertModel)
      : this.dataService.addInstrumentGroup(upsertModel);
    addOrUpdateAction.subscribe((id: number) => {
      if (this.instrumentGroup.instrumentGroupId) {
        this.notificationService.success(TranslateConstants.UpdateSuccessKey, { type: TranslateConstants.InstrumentGroupKey });
      } else {
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.InstrumentGroupKey });
      }
      this.instrumentGroup.instrumentGroupId = id;
      if (close) {
        this.close(false);
      }
    });
  }

  public onCheckedDevices($event: CheckedDevicesEvent): void {
    this.updatedUnsavedCheckedDevices = $event.checkedDevices;
  }

  public deviceDataRequested($event: DeviceDataRequest): void {
    this.currentDeviceDataRequested = $event;
    this.getDevicesWithCurrentSearchState();
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public enableFormGroupSettings(formGroupKey: string, applyDefaults: boolean): void {
    if (formGroupContains(this.manageDeviceSettingsForm, formGroupKey)) {
      return;
    }

    if (formGroupKey.includes('.')) {
      addNestedFormControl(this.fb, this.manageDeviceSettingsForm, formGroupKey, DeviceSettingsConstants.getDefaultFormControlValue(formGroupKey));
    } else {
      const instrumentType = this.instrumentTypes.find(it => it.instrumentTypeId === this.instrumentGroup?.instrumentTypeId);
      const settingsFormConfigOverrideKey: string = instrumentType?.code;
      const control: AbstractControl = this.fb.group(DeviceSettingsConstants.getDefaultFormConfig(formGroupKey, settingsFormConfigOverrideKey));
      this.manageDeviceSettingsForm.addControl(formGroupKey, control);
    }

    if (formGroupKey === DeviceSettingsConstants.DownloadSettings_FormKey
      && this.showDownloadSettings_Software) {
      addNestedFormGroup(this.fb, this.manageDeviceSettingsForm, DeviceSettingsConstants.DownloadSettings_Software_FullFormKey, DeviceSettingsConstants.DownloadSettings_Software_Form_DefaultConfig);
    }

    if (formGroupKey === DeviceSettingsConstants.TestResultsUpload_FormKey) {
      this.testResultEnabledValueChangeSubscription = this.manageDeviceSettingsForm
        .get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey]).valueChanges
        .pipe(
          // https://stackoverflow.com/a/59678874/10752002
          debounceTime(100), // ensuring that on creation of this control we don't update validation between enabled and disabled until final results
          distinctUntilChanged()
        )
        .subscribe((enabled: boolean) => {
          DeviceSettingsConstants.updateTestResultsUploadFormValidators(this.manageDeviceSettingsForm, enabled);
        });
    }

    if (applyDefaults) {
      const settings = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, formGroupKey);
      if (settings) {
        const patch = {};
        patch[formGroupKey] = settings;
        this.manageDeviceSettingsForm.patchValue(patch);
      }
    }
  }

  public disableFormGroupSettings(formGroupKey: string): void {
    if (!formGroupContains(this.manageDeviceSettingsForm, formGroupKey)) {
      return;
    }

    removeNestedFormControl(this.manageDeviceSettingsForm, formGroupKey);

    if (formGroupKey === DeviceSettingsConstants.TestResultsUpload_FormKey) {
      this.testResultEnabledValueChangeSubscription?.unsubscribe();
    }
  }

  public isDeviceSettingEnabled(formGroupKey: string): boolean {
    return formGroupContains(this.manageDeviceSettingsForm, formGroupKey);
  }

  public isAtLeastOneSettingEnabled(): boolean {
    const data = this.manageDeviceSettingsForm.value as unknown;
    return Object.keys(data).length > 0;
  }

  public updateDeviceSettings(): void {
    const request = {
      instrumentTypeId: this.instrumentGroup.instrumentTypeId,
      instrumentGroupIds: [this.instrumentGroup.instrumentGroupId],
      ...this.manageDeviceSettingsForm.value
    } as DeviceRequest;

    if (this.manageDeviceSettingsForm.contains(DeviceSettingsConstants.VirenaSettings_FormKey)) {
      request[DeviceSettingsConstants.VirenaSettings_OptIn_FormKey] = this.manageDeviceSettingsForm.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]).value as boolean;
      request[DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey] = this.manageDeviceSettingsForm.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey]).value as boolean;

      delete request[DeviceSettingsConstants.VirenaSettings_FormKey];
    }

    this.subscription.add(this.dataService.updateInstrumentGroupDeviceSettings(request)
      .subscribe(() => {
        this.notificationService.success(TranslateConstants.DeviceSettingsUpdateRequestSuccessKey);
      }));
  }

  private getDevicesWithCurrentSearchState(): void {
    const searchRequest: SearchRequest = this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.instrumentGroup.instrumentTypeId, this.modelIdsForSelectedInstrumentType, this.disableUnregisteredExactMatch);

    this.subscription.add(
      this.dataService.getDeviceInformation(searchRequest)
        .subscribe((results) => {
          this.deviceInformationResult = results;
        }));
  }



  public triggerUpdateAllowlistFolderPaths(): void {
    this.updateAllowlistFolderPathsFileInput.nativeElement.click();
  }

  public async updateAllowlistFolderPaths(files: File[]): Promise<void> {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];

    const newFolderPathsTest = await file.text();

    this.updateAllowlistFolderPathsFileInput.nativeElement.value = null;

    const newFolderPaths = newFolderPathsTest.split('\n');
    this.setAllowlistFolderPaths(newFolderPaths);
  }

  public onInstrumentTypeChange($event: MatSelectChange): void {
    this.instrumentGroup.instrumentTypeId = $event.value as number;
    this.refreshFromInstrumentTypeChange();
  }

  public getMinMaxValidationValuesFromEditForm(keyParts: string[]): { min: string, max: string, minValue: number, maxValue: number } {
    return getMinMaxValidationValues(keyParts, this.manageDeviceSettingsForm, this.numberPipe, this.minMaxValidationValuesByKeyParts);
  }

  private refreshFromInstrumentTypeChange() {
    // Remove any selected device ids
    const devicesMatchingInstrumentTypeId = this.groupDeviceInformations.filter(di => di.instrumentTypeId === this.instrumentGroup.instrumentTypeId);
    const numberOfDevicesRemoved = this.groupDeviceInformations.length - devicesMatchingInstrumentTypeId.length;
    if (numberOfDevicesRemoved > 0) {
      this.notificationService.warning(TranslateConstants.NonMatchingDevicesRemovedFromGroupKey, { count: numberOfDevicesRemoved.toString() });

      this.groupDeviceInformations = devicesMatchingInstrumentTypeId;
    }

    this.updateModelIdsForSelectedInstrumentType();
    this.refreshFeatureFlagsBySelectedInstrumentType();
  }

  private setAllowlistFolderPaths(allowlistFolderPaths: string[]) {
    const iotPatch = {};
    iotPatch[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey] = allowlistFolderPaths;
    const settingsPatch = {};
    settingsPatch[DeviceSettingsConstants.Settings_IoT_FormKey] = iotPatch;
    const rootPatch = {};
    rootPatch[DeviceSettingsConstants.Settings_FormKey] = settingsPatch;
    this.manageDeviceSettingsForm.patchValue(rootPatch);
  }

  private updateModelIdsForSelectedInstrumentType() {
    this.modelIdsForSelectedInstrumentType = this.selectionAndCacheService.getModelsForInstrumentTypeId(this.instrumentGroup.instrumentTypeId)?.map(m => m.modelId);
  }

  private updateTabRelatedButtonVisibilities(): void {
    if (!this.tabGroup || !this.tabGroup._tabs) {
      return;
    }

    let label = 'unknown';
    const selectedTab = this.tabGroup._tabs.toArray()[this._selectedTabIndex];
    if (selectedTab) {
      label = selectedTab.textLabel;
    }

    this.showChangeDevicesButton = this.canAddUpdate && label === this.devicesTabText && this.instrumentGroup.instrumentTypeId > 0 && !this.changeDevicesMode;
    this.showUpdateDeviceSettings = this.showManageDeviceSettingsTab && label === this.manageDeviceSettingsTabText && !this.changeDevicesMode;
    this.showDeviceMessageDisclaimer = this.showManageDeviceSettingsTab && label === this.utilitiesTabText && !this.changeDevicesMode;
  }

  private refreshFeatureFlagsBySelectedInstrumentType() {
    const instrumentType = this.instrumentTypes.find(i => this.instrumentGroup.instrumentTypeId === i.instrumentTypeId);
    if (instrumentType) {
      this.showVirenaSettings = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.VirenaSettings);
      this.showRetrySettings = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.RetrySettingsSuffix);
      this.showDownloadSettings = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.DownloadSettingsSuffix);
      this.showDownloadSettings_Software = this.canManageAllInstruments && this.showDownloadSettings && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.DownloadSettings_Software);
      this.showClinicalModeSettings = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.ClinicalModeSettingsSuffix);
      this.showTestResultsUpload = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.TestResultsUploadSuffix);
      this.showTestResultsUpload_DeleteAfterUpload = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.TestResultsUpload_DeleteAfterUploadOption);
      this.showPerformanceDataSettings = this.canManageAllInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.PerformanceDataSettingSuffix);
      this.showAllowlistFolderPaths = this.canAddUpdateAllowlistFolderPaths && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AllowlistFolderPaths);
      this.showChunkDownloadTimeoutNotHonoredMessage = instrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf();
      this.chunkDownloadTimeoutNotHonoredMessage = translateWithInterpolateParams(this.translateService, TranslateConstants.DeviceSettingsDownloadChunkDownloadTimeoutNotHonoredKey,
        { instrumentTypeName: instrumentType.name });
    } else {
      this.showVirenaSettings = false;
      this.showRetrySettings = false;
      this.showDownloadSettings = false;
      this.showDownloadSettings_Software = false;
      this.showClinicalModeSettings = false;
      this.showTestResultsUpload = false;
      this.showTestResultsUpload_DeleteAfterUpload = false;
      this.showPerformanceDataSettings = false;
      this.showAllowlistFolderPaths = false;
      this.showChunkDownloadTimeoutNotHonoredMessage = false;
      this.chunkDownloadTimeoutNotHonoredMessage = '';
    }

    this.showManageDeviceSettingsTab = this.showVirenaSettings || this.showRetrySettings || this.showDownloadSettings || this.showClinicalModeSettings
      || this.showTestResultsUpload || this.showPerformanceDataSettings || this.showAllowlistFolderPaths;
  }
}


import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { InstrumentType, InstrumentFileType, InstrumentTypeModel, InstrumentTypeId } from 'src/app/models/instrument.models';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { enumToArray, filterItemsToSupportedInstrumentTypes } from 'src/app/shared/utils';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { FirmwareFileDialogComponent } from '../firmware-file-dialog/firmware-file-dialog.component';
import { NotificationService, UserPermissionService, DataService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import {
  Firmware, PublishedFlag, AssayInterface, Assay, LanguageInterface, Language, formatVersionForSort,
  PublishedFlagTranslateKeyMap, AccessLevel, UserPermission, FirmwareFileDialogData, ConfirmationDialogData, ConfirmationDialogResponse
} from 'src/app/models';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-firmware-files',
  templateUrl: './firmware-files.component.html',
  styleUrls: ['./firmware-files.component.scss']
})
export class FirmwareFilesComponent implements OnInit, AfterViewInit, OnDestroy {

  public versionFieldName = 'version';
  public idFieldName = 'id';
  public statusFieldName = 'status';
  public modelIdsCountFieldName = 'modelIdsCount';

  public PublishedFlag = PublishedFlag;
  public publishedFlags = enumToArray(PublishedFlag, 'Unknown');

  private displayedColumns: string[] = [this.idFieldName, 'title', 'instrumentFileTypeId', 'instrumentTypeId', this.modelIdsCountFieldName, this.versionFieldName, this.statusFieldName, 'devicesCount', 'actions'];
  public columnsToUse = this.displayedColumns;
  public firmwares: Firmware[] = [];
  public availableFirmwares: Firmware[] = [];
  public assayInterfaces: AssayInterface[] = [];
  public availableAssayInterfaces: AssayInterface[] = [];
  public availableAssays: Assay[] = [];
  public languageInterfaces: LanguageInterface[] = [];
  public availableLanguageInterfaces: LanguageInterface[] = [];
  public availableLanguages: Language[] = [];
  public instrumentTypes: InstrumentType[] = [];
  public instrumentFileTypes: InstrumentFileType[] = [];
  public dataSource: MatTableDataSource<Firmware> = new MatTableDataSource<Firmware>();

  public firmwareFilesTranslateKey: string = TranslateConstants.FirmwaresFirmwareFilesKey;
  public firmwareTypeTranslateKey: string = TranslateConstants.FirmwareKey;

  private subscription: Subscription = new Subscription();
  @ViewChild(MatSort) private sort: MatSort;
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private SELECTED_INSTRUMENT_FILE_TYPE: InstrumentFileType;
  private selectedModels: InstrumentTypeModel[];

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
    }
  }

  get selectedInstrumentFileType(): InstrumentFileType {
    return this.SELECTED_INSTRUMENT_FILE_TYPE;
  }

  set selectedInstrumentFileType(instrumentType: InstrumentFileType) {
    if (this.SELECTED_INSTRUMENT_FILE_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_FILE_TYPE = instrumentType;
      this.loadFirmwares();
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {

    this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageFirmwareFiles], null, selectionState.instrumentType?.instrumentTypeId);
    }));

    this.subscription.add(
      forkJoin([
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.dataService.getAssayInterfaces(),
        this.dataService.getLanguageInterfaces()
      ])
        .subscribe((results: [boolean, AssayInterface[], LanguageInterface[]]) => {
          this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
          this.instrumentFileTypes = this.selectionAndCacheService.instrumentFileTypes;
          this.assayInterfaces = results[1];
          this.languageInterfaces = results[2];

          this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
            this.updateColumnsToUse();
            this.selectedInstrumentType = selectionState.instrumentType;
            this.selectedModels = selectionState.models;

            this.loadFirmwares();
            this.updateUIForSelectedInstrumentType();
          }));
        }));

    // set version sort value
    this.dataSource.sortingDataAccessor = (firmware: Firmware, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case this.versionFieldName:
          return formatVersionForSort(firmware.version);
        case this.idFieldName:
          return firmware.firmwareId;
        case this.statusFieldName:
          return this.getPublishedFlagDisplayValue(firmware.publishedFlag);
        case this.modelIdsCountFieldName:
          return this.getInstrumentTypeModelsCount(firmware);
        default:
          return firmware[sortHeaderId] as string | number;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentTypeModelsCount(firmware: Firmware): number {
    const models = this.selectionAndCacheService.getModelsForInstrumentTypeId(firmware.instrumentTypeId)?.filter(m => firmware.modelIds.includes(m.modelId));
    return models?.length || 0;
  }

  public getInstrumentFileTypeDisplayValue(instrumentFileTypeId: number): string {
    return this.instrumentFileTypes?.find(i => i.instrumentFileTypeId === instrumentFileTypeId)?.name;
  }

  public getPublishedFlagDisplayValue(flag: PublishedFlag): string {
    return translate(this.translateService, PublishedFlagTranslateKeyMap.get(flag));
  }

  public async onAddFirmware(): Promise<void> {
    await this.showEditDialog();
  }

  public async onEditFirmware(firmware: Firmware): Promise<void> {
    await this.showEditDialog(firmware);
  }

  private async showEditDialog(firmware?: Firmware): Promise<void> {
    if (firmware) {
      this.subscription.add(this.dataService.getFirmwareById(firmware.firmwareId).subscribe(async (fullFirmware: Firmware) => {
        const options: FirmwareFileDialogData = {
          firmware: fullFirmware
        };

        const dialogRef = this.dialog.open(FirmwareFileDialogComponent, {
          data: options,
          width: '75%',
          disableClose: true
        });

        await firstValueFrom(dialogRef.afterClosed());

        this.loadFirmwares();
      }));
    } else {
      firmware = {
        firmwareId: 0,
        assayIds: [],
        assayInterfaceIds: [],
        compatibleFirmwareIds: [],
        languageIds: [],
        languageInterfaceIds: [],
        lnItemNumber: null,
        fileUri: null,
        instrumentFileTypeId: 0,
        instrumentTypeId: 0,
        modelIds: [],
        countryIds: null,
        notes: null,
        mD5: null,
        title: null,
        version: { major: 0, minor: 0, revision: 0 },
        publishedFlag: PublishedFlag.Unpublished,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null,
        devicesCount: 0
      };

      const options: FirmwareFileDialogData = {
        firmware: firmware
      };

      const dialogRef = this.dialog.open(FirmwareFileDialogComponent, {
        data: options,
        width: '75%',
        disableClose: true
      });

      await firstValueFrom(dialogRef.afterClosed());

      this.loadFirmwares();
    }
  }

  public async onDeleteFirmware(firmware: Firmware): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, this.firmwareTypeTranslateKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, this.firmwareTypeTranslateKey, firmware.title)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteFirmware(firmware.firmwareId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: this.firmwareTypeTranslateKey });
        this.loadFirmwares();
      }));
    }
  }

  public loadFirmwares(firmwareId?: number): void {
    this.subscription.add(this.dataService.getFirmwares(this.selectedInstrumentFileType?.instrumentFileTypeId, this.selectedInstrumentType?.instrumentTypeId, this.selectedModels?.map(m => m.modelId))
      .subscribe((firmwares: Firmware[]) => {
        this.firmwares = filterItemsToSupportedInstrumentTypes(this.instrumentTypes.map(i => i.instrumentTypeId), firmwares);

        this.dataSource.data = this.firmwares;
      }));
  }

  private updateColumnsToUse() {
    const tempColumnsToUse = [...this.displayedColumns];
    const removeModelColumn = this.selectionAndCacheService.modelsForSelectedInstrumentType?.length <= 1;
    if (removeModelColumn) {
      const indexOfModelIdsCountColumn = tempColumnsToUse.indexOf(this.modelIdsCountFieldName);
      if (indexOfModelIdsCountColumn !== -1) {
        tempColumnsToUse.splice(indexOfModelIdsCountColumn, 1);
      }
    }

    this.columnsToUse = tempColumnsToUse;
  }

  private updateUIForSelectedInstrumentType() {
    if (!this.selectedInstrumentType) {
      return;
    }

    if (this.selectedInstrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.firmwareFilesTranslateKey = TranslateConstants.FirmwaresModFilesKey;
      this.firmwareTypeTranslateKey = TranslateConstants.ModKey;
    } else {
      // All Other Instrument Types
      this.firmwareFilesTranslateKey = TranslateConstants.FirmwaresFirmwareFilesKey;
      this.firmwareTypeTranslateKey = TranslateConstants.FirmwareKey;
    }
  }
}

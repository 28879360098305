import { KeyValue } from '@angular/common';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationConstants } from './validation-constants';
import { TranslateConstants } from './translate-constants';
import { InstrumentTypeCode } from '../models';

export class DeviceSettingsConstants {
  public static readonly VirenaSettings_FormKey = 'virenaSettings';
  public static readonly VirenaSettings_OptIn_FormKey = 'virena_enabled';
  public static readonly VirenaSettings_AutoSend_FormKey = 'virena_auto_send';
  public static readonly VirenaSettings_Form_DefaultConfig = {
    virena_enabled: [false],
    virena_auto_send: [false]
  };

  public static readonly RetrySettings_FormKey = 'retry_settings';
  public static readonly RetrySettings_TimeDelayInMs_FormKey = 'time_delay_milliseconds';
  public static readonly RetrySettings_TimeDelayInMs_Default = 100;
  public static readonly RetrySettings_TimeDelayInMs_Min = 100;
  public static readonly RetrySettings_TimeDelayInMs_Max = 1000;
  public static readonly RetrySettings_TimeDelayInMs_Vision_1_15_Default = 96000; // 96,000 is 1.6 min
  public static readonly RetrySettings_TimeDelayInMs_Vision_1_15_Min = 60000; // 60,000 is 1 min
  public static readonly RetrySettings_TimeDelayInMs_Vision_1_15_Max = 240000; // 240,000 is 4 min
  public static readonly RetrySettings_NumberRetries_FormKey = 'number_retries';
  public static readonly RetrySettings_NumberRetries_Default = 10;
  public static readonly RetrySettings_NumberRetries_Min = 0;
  public static readonly RetrySettings_NumberRetries_Max = 10;
  public static readonly AwsSettings_OverrideLength_Min = 1;
  public static readonly AwsSettings_OverrideLength_Max = 300;

  /* eslint-disable @typescript-eslint/unbound-method */
  public static readonly RetrySettings_Form_DefaultConfig = {
    time_delay_milliseconds: [DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Default, [Validators.min(DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Min), Validators.max(DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Max), Validators.required]],
    number_retries: [DeviceSettingsConstants.RetrySettings_NumberRetries_Default, [Validators.min(DeviceSettingsConstants.RetrySettings_NumberRetries_Min), Validators.max(DeviceSettingsConstants.RetrySettings_NumberRetries_Max), Validators.required]]
  };
  public static readonly RetrySettings_Form_DefaultConfig_Vision_1_15 = {
    time_delay_milliseconds: [DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Vision_1_15_Default, [Validators.min(DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Vision_1_15_Min), Validators.max(DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Vision_1_15_Max), Validators.required]],
    number_retries: [DeviceSettingsConstants.RetrySettings_NumberRetries_Default, [Validators.min(DeviceSettingsConstants.RetrySettings_NumberRetries_Min), Validators.max(DeviceSettingsConstants.RetrySettings_NumberRetries_Max), Validators.required]]
  };
  /* eslint-enable @typescript-eslint/unbound-method */

  public static readonly DownloadSettings_FormKey = 'download_settings';
  public static readonly DownloadSettings_TimeoutInS_FormKey = 'timeout_seconds';
  public static readonly DownloadSettings_TimeoutInS_Default = 900;
  public static readonly DownloadSettings_TimeoutInS_Min = 1;
  public static readonly DownloadSettings_TimeoutInS_Max = 86400; // 86,400 is 24 hrs
  public static readonly DownloadSettings_TimeoutInS_MaxHrEquivalent = 24;
  public static readonly DownloadSettings_Software_FormKey = 'software';
  public static readonly DownloadSettings_Software_FullFormKey = `${DeviceSettingsConstants.DownloadSettings_FormKey}.${DeviceSettingsConstants.DownloadSettings_Software_FormKey}`;
  public static readonly DownloadSettings_Software_MaxRetries_FormKey = 'max_retries';
  public static readonly DownloadSettings_Software_MaxRetries_Default = 3;
  public static readonly DownloadSettings_Software_MaxRetries_Min = 1;
  public static readonly DownloadSettings_Software_MaxRetries_Max = 100;
  public static readonly DownloadSettings_Software_RetryTimeoutMinutes_FormKey = 'retry_timeout_minutes';
  public static readonly DownloadSettings_Software_RetryTimeoutMinutes_Default = 60;
  public static readonly DownloadSettings_Software_RetryTimeoutMinutes_Min = 1;
  public static readonly DownloadSettings_Software_RetryTimeoutMinutes_Max = 1440; // 1,440 is 24 hrs
  public static readonly DownloadSettings_Software_RetryTimeoutMinutes_MaxHrEquivalent = 24;
  /* eslint-disable @typescript-eslint/unbound-method */
  public static readonly DownloadSettings_Form_DefaultConfig = {
    timeout_seconds: [DeviceSettingsConstants.DownloadSettings_TimeoutInS_Default, [Validators.min(DeviceSettingsConstants.DownloadSettings_TimeoutInS_Min), Validators.max(DeviceSettingsConstants.DownloadSettings_TimeoutInS_Max), Validators.required]]
  };

  public static readonly DownloadSettings_Software_Form_DefaultConfig = {
    max_retries: [DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Default, [Validators.min(DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Min), Validators.max(DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Max), Validators.required]],
    retry_timeout_minutes: [DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Default, [Validators.min(DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Min), Validators.max(DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Max), Validators.required]],
  };
  /* eslint-enable @typescript-eslint/unbound-method */

  public static readonly ClinicalMode_FormKey = 'clinical_mode';
  public static readonly ClinicalMode_SaveClinicalImages_FormKey = 'save_clinical_images';
  public static readonly ClinicalMode_ExportDataToUsb_FormKey = 'export_data_to_usb';
  /* eslint-disable @typescript-eslint/unbound-method */
  public static readonly ClinicalMode_Form_DefaultConfig = {
    save_clinical_images: [false],
    export_data_to_usb: [false]
  };
  /* eslint-enable @typescript-eslint/unbound-method */

  public static readonly TestResultsUpload_FormKey = 'test_result_upload';
  public static readonly TestResultsUpload_Enabled_FormKey = 'enabled';
  public static readonly TestResultsUpload_AccessToken_FormKey = 'access_token';
  public static readonly TestResultsUpload_Server_FormKey = 'server';
  public static readonly TestResultsUpload_ApiPath_FormKey = 'api_path';
  public static readonly TestResultsUpload_UploadDelayMinutes_FormKey = 'upload_delay_minutes';
  public static readonly TestResultsUpload_UploadDelayMinutes_Min = 1;
  public static readonly TestResultsUpload_UploadDelayMinutes_Max = 180;
  public static readonly TestResultsUpload_DeleteAfterUpload_FormKey = 'delete_test_result_after_upload';
  public static readonly TestResultsUpload_Form_DefaultConfig = {
    enabled: [false],
    access_token: [''],
    server: [''],
    api_path: [''],
    upload_delay_minutes: [1],
    delete_test_result_after_upload: [false]
  };

  /* eslint-disable @typescript-eslint/unbound-method */
  public static updateTestResultsUploadFormValidators = (rootFormGroup: UntypedFormGroup, enabled: boolean): void => {
    const accessTokenControl = rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey]);
    const serverControl = rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_Server_FormKey]);
    const apiPathControl = rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey]);
    const uploadDelayMinutesControl = rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey]);
    if (enabled) {
      accessTokenControl.setValidators([Validators.required]);
      serverControl.setValidators([Validators.required, Validators.pattern(ValidationConstants.urlPattern)]);
      apiPathControl.setValidators([Validators.required]);
      uploadDelayMinutesControl.setValidators([Validators.required, Validators.min(DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Min), Validators.max(DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Max)]);
    } else {
      accessTokenControl.setValidators(null);
      serverControl.setValidators(null);
      apiPathControl.setValidators(null);
      uploadDelayMinutesControl.setValidators(null);
    }

    accessTokenControl.updateValueAndValidity();
    serverControl.updateValueAndValidity();
    apiPathControl.updateValueAndValidity();
    uploadDelayMinutesControl.updateValueAndValidity();
    rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey]).markAllAsTouched();
  };
  /* eslint-enable @typescript-eslint/unbound-method */

  public static readonly PerformanceData_FormKey = 'performance_data';
  public static readonly PerformanceData_Enabled_FormKey = 'enabled';
  public static readonly PerformanceData_Verbosity_FormKey = 'verbosity';
  public static readonly PerformanceData_Verbosity_Default = 0;
  public static readonly PerformanceData_Verbosity_Min = 0;
  public static readonly PerformanceData_Verbosity_Max = 9;
  /* eslint-disable @typescript-eslint/unbound-method */
  public static readonly PerformanceData_Form_DefaultConfig = {
    enabled: [false],
    verbosity: [DeviceSettingsConstants.PerformanceData_Verbosity_Default, [Validators.min(DeviceSettingsConstants.PerformanceData_Verbosity_Min), Validators.max(DeviceSettingsConstants.PerformanceData_Verbosity_Max), Validators.required]]
  };
  /* eslint-enable @typescript-eslint/unbound-method */

  // Settings and IoT Groupings are displayed separately visually, so no default config for it
  public static readonly Settings_FormKey = 'settings';
  public static readonly Settings_IoT_FormKey = 'iot';
  public static readonly Settings_IoT_AllowlistFolderPaths_FormKey = 'allowlist_folderpaths';
  public static readonly Settings_IoT_AllowlistFolderPaths_FullFormKeyPath = `${DeviceSettingsConstants.Settings_FormKey}.${DeviceSettingsConstants.Settings_IoT_FormKey}.${DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey}`;
  public static readonly Settings_IoT_AllowlistFolderPaths_Default = new Array<string>();

  // AWS settings
  public static readonly AwsSettings_FormKey = 'aws_settings';
  public static readonly AwsSettings_FolderOverride_FormKey = 'destinationFolderOverride';
  public static readonly AwsSettings_EnableFolderOverride_FormKey = 'enableFolderOverride';
  public static readonly AwsSettings_Form_DefaultConfig = {
    enableFolderOverride: false,
    destinationFolderOverride: ''
  };

  public static readonly InstrumentSettingsRootKeys: string[] =
    [
      DeviceSettingsConstants.VirenaSettings_OptIn_FormKey, DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey,
      DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.ClinicalMode_FormKey, DeviceSettingsConstants.TestResultsUpload_FormKey,
      DeviceSettingsConstants.PerformanceData_FormKey, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath,
      DeviceSettingsConstants.AwsSettings_FormKey
    ];

  public static sortInstrumentSettingsRootKeys = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number => {
    const aIndex = DeviceSettingsConstants.InstrumentSettingsRootKeys.indexOf(a.key);
    const bIndex = DeviceSettingsConstants.InstrumentSettingsRootKeys.indexOf(b.key);
    if (aIndex > bIndex) {
      return 1;
    } else if (aIndex < bIndex) {
      return -1;
    }
    else {
      return 0;
    }
  };

  public static getDefaultFormConfig = (formGroupKey: string, overrideKey?: string): { [key: string]: unknown } => {
    switch (formGroupKey) {
      case DeviceSettingsConstants.VirenaSettings_FormKey:
        return DeviceSettingsConstants.VirenaSettings_Form_DefaultConfig;
      case DeviceSettingsConstants.RetrySettings_FormKey:
        if (overrideKey === InstrumentTypeCode.Vision.valueOf()) {
          return DeviceSettingsConstants.RetrySettings_Form_DefaultConfig_Vision_1_15;
        }
        else {
          return DeviceSettingsConstants.RetrySettings_Form_DefaultConfig;
        }
        break;
      case DeviceSettingsConstants.DownloadSettings_FormKey:
        return DeviceSettingsConstants.DownloadSettings_Form_DefaultConfig;
      case DeviceSettingsConstants.DownloadSettings_Software_FullFormKey:
        return DeviceSettingsConstants.DownloadSettings_Software_Form_DefaultConfig;
      case DeviceSettingsConstants.ClinicalMode_FormKey:
        return DeviceSettingsConstants.ClinicalMode_Form_DefaultConfig;
      case DeviceSettingsConstants.TestResultsUpload_FormKey:
        return DeviceSettingsConstants.TestResultsUpload_Form_DefaultConfig;
      case DeviceSettingsConstants.PerformanceData_FormKey:
        return DeviceSettingsConstants.PerformanceData_Form_DefaultConfig;
      case DeviceSettingsConstants.AwsSettings_FormKey:
        return DeviceSettingsConstants.AwsSettings_Form_DefaultConfig;
      default:
        throw new Error(`Unsupported formGroupKey when attempting to get default form config. (${formGroupKey})`);
    }
  };

  public static getDefaultFormControlValue = (formControlKey: string): (unknown)[] => {
    switch (formControlKey) {
      case DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath:
        return [DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_Default];
      default:
        throw new Error(`Unsupported formControlKey when attempting to get default form control config. (${formControlKey})`);
    }
  };

  public static readonly translateKeyPartSeparator = '.';
  private static readonly defaultKey = 'default';
  private static translateKeysByFormKeyAndThenOverrideKey: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();
  public static tryGetTranslateKey = (keyParts: string[], overrideKey?: string): string => {
    if (DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.size === 0) {
      DeviceSettingsConstants.buildTranslateKeysByFormKeyAndInstrumentType();
    }

    const key = keyParts.join(DeviceSettingsConstants.translateKeyPartSeparator);
    const translateKeysByOverrideKey = DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.get(key);
    if (translateKeysByOverrideKey) {
      let translateKey: string = undefined;
      if (overrideKey && translateKeysByOverrideKey.has(overrideKey)) {
        translateKey = translateKeysByOverrideKey.get(overrideKey);
      } else {
        translateKey = translateKeysByOverrideKey.get(DeviceSettingsConstants.defaultKey);
      }

      if (translateKey) {
        return translateKey;
      }
    }

    return keyParts[keyParts.length - 1];
  };

  private static buildTranslateKeysByFormKeyAndInstrumentType() {

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.VirenaSettings_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsVirenaHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.VirenaSettings_OptIn_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsVirenaOptInKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsVirenaAutoSendKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.RetrySettings_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsRetryHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.RetrySettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey}`,
        new Map<string, string>([
          [DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsRetryTimeDelayMsKey],
          ['seconds', TranslateConstants.DeviceSettingsRetryTimeDelaySKey],
          [InstrumentTypeCode.Vision.valueOf(), TranslateConstants.DeviceSettingsRetryTimeDelayKey]
        ]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.RetrySettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsRetryNumberOfRetriesKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.DownloadSettings_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsDownloadHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.DownloadSettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsDownloadChunkDownloadTimeoutSKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(`${DeviceSettingsConstants.DownloadSettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_Software_FormKey}`,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsDownloadSoftwareTitleKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.DownloadSettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_Software_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsDownloadSoftwareMaxRetriesKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.DownloadSettings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_Software_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsDownloadSoftwareRetryTimeoutMinutesKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.ClinicalMode_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsClinicalModeHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.ClinicalMode_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.ClinicalMode_SaveClinicalImages_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsClinicalModeSaveClinicalImagesKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.ClinicalMode_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.ClinicalMode_ExportDataToUsb_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsClinicalModeExportDataToUsbKey]]));


    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.TestResultsUpload_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadEnabledKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadAccessTokenKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_Server_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadServerKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadApiPathKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadUploadDelayMinutesKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.TestResultsUpload_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsTestResultsUploadDeleteLocallyAfterUploadKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.PerformanceData_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsPerformanceDataHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.PerformanceData_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.PerformanceData_Enabled_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsPerformanceDataEnabledKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.PerformanceData_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.PerformanceData_Verbosity_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsPerformanceDataVerbosityKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.Settings_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsSettingsHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.Settings_IoT_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsSettingsIoTHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey
      .set(`${DeviceSettingsConstants.Settings_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.Settings_IoT_FormKey}${DeviceSettingsConstants.translateKeyPartSeparator}${DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey}`,
        new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsSettingsIoTAllowlistFolderPathsKey]]));

    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.AwsSettings_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsAwsSettingsHeaderKey]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsAwsSettingsEnableFolderOverride]]));
    DeviceSettingsConstants.translateKeysByFormKeyAndThenOverrideKey.set(DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey,
      new Map<string, string>([[DeviceSettingsConstants.defaultKey, TranslateConstants.DeviceSettingsAwsSettingsFolderOverride]]));
  }
}

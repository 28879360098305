import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Country, SetSerialNumberOverrideDialogData, SetSerialNumberOverrideDialogResponse } from 'src/app/models';
import { SelectionAndCacheService } from 'src/app/services';

@Component({
  selector: 'app-set-serialnumber-overrides-dialog',
  templateUrl: './set-serialnumber-overrides-dialog.component.html',
  styleUrls: ['./set-serialnumber-overrides-dialog.component.scss']
})
export class SetSerialNumberOverridesDialogComponent {

  public SetSerialNumberOverridesDialogComponent = SetSerialNumberOverridesDialogComponent;

  /* eslint-disable @typescript-eslint/unbound-method */
  public setOverrideForm = this.fb.group(
    {
      overrideCountryId: [0, [Validators.required, Validators.min(1)]]
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  public countries: Country[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SetSerialNumberOverrideDialogData,
    private mdDialogRef: MatDialogRef<SetSerialNumberOverridesDialogComponent>,
    private fb: FormBuilder,
    private selectionAndCacheService: SelectionAndCacheService) {
    this.countries = this.selectionAndCacheService.countries;
  }

  public cancel(): void {
    this.close({
      result: false
    });
  }

  public confirm(): void {
    this.close({
      result: true,
      overrideCountryId: this.setOverrideForm.get('overrideCountryId').value
    });
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close({
      result: false
    });
  }

  private close(result: SetSerialNumberOverrideDialogResponse): void {
    this.mdDialogRef.close(result);
  }
}

import { Component, Inject, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClearUpgradeDialogData } from 'src/app/models';

@Component({
  selector: 'app-clear-upgrade-dialog',
  templateUrl: './clear-upgrade-dialog.component.html',
  styleUrls: ['./clear-upgrade-dialog.component.scss']
})
export class ClearUpgradeDialogComponent {
  /* eslint-disable @typescript-eslint/unbound-method */
  public clearUpgradeForm = this.fb.group(
    {
      disabledUpgradeRequestFunction: [false, [Validators.required]],
      clearedRequestQueue: [false, [Validators.required]],
      selectedItemsIndividually: [false, [Validators.required]],
      finalConfirmation: [false, [Validators.required]],
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  constructor(@Inject(MAT_DIALOG_DATA) public data: ClearUpgradeDialogData,
    private mdDialogRef: MatDialogRef<ClearUpgradeDialogComponent>,
    private fb: FormBuilder) { }

  public cancel(): void {
    this.close(false);
  }

  public confirm(): void {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close(false);
  }

  private close(value: boolean): void {
    this.mdDialogRef.close(value);
  }
}

import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DataService, InstrumentTypeSelectionState, SelectionAndCacheService, NotificationService, UserPermissionService } from 'src/app/services';
import { AccessLevel, InstrumentType, AssayInterface, VersionTemplate, UserPermission, ConfirmationDialogData, ConfirmationDialogResponse } from 'src/app/models';
import { wholeNumberEntered } from 'src/app/shared/utils';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-assay-interface-versions',
  templateUrl: './assay-interface-versions.component.html',
  styleUrls: ['./assay-interface-versions.component.scss']
})
export class AssayInterfaceVersionsComponent implements OnInit, OnDestroy {

  public instrumentTypes: InstrumentType[] = [];
  public assayInterfaces: AssayInterface[] = [];
  public filteredInterfaces: AssayInterface[] = [];
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;

  private subscription: Subscription = new Subscription();
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.filterAssayInterfaces();
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {

    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.selectedInstrumentType = selectionState.instrumentType;
        this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAssayFiles], [UserPermission.ViewEverything], this.selectionAndCacheService.selectedInstrumentType?.instrumentTypeId);
      }));
  
    }));

    this.loadAssayInterfaces();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async onAddVersion(template: TemplateRef<unknown>): Promise<void> {
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, VersionTemplate | 'false'>(template, { width: '75%' });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const assayInterface: AssayInterface = {
        instrumentTypeId: this.selectedInstrumentType?.instrumentTypeId,
        version: result.version,
        assayInterfaceId: 0,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null
      };

      this.subscription.add(this.dataService.addAssayInterface(assayInterface).subscribe(id => {
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.AssayInterfaceKey });
        this.loadAssayInterfaces();
      }));
    }
  }

  public async onDeleteVersion(assayInterface: AssayInterface): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.AssayInterfaceKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.AssayInterfaceKey, assayInterface.version.toString())
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteAssayInterface(assayInterface.assayInterfaceId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.AssayInterfaceKey });
        this.loadAssayInterfaces();
      }));
    }
  }

  public onVersionKeyPress(e: KeyboardEvent): boolean {
    return wholeNumberEntered(e);
  }

  private loadAssayInterfaces(): void {
    this.subscription.add(this.dataService.getAssayInterfaces().subscribe((interfaces: AssayInterface[]) => {
      this.assayInterfaces = interfaces;
      this.filterAssayInterfaces();
    }));
  }

  private filterAssayInterfaces(): void {
    this.filteredInterfaces = this.assayInterfaces?.filter(i => i.instrumentTypeId === this.selectedInstrumentType?.instrumentTypeId);
  }
}

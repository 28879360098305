import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { AccessLevel, UserPermission, UserPermissionWithScope } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  private userPermissions: UserPermissionWithScope[] = [];
  private initialized = false;
  private apiUrl: string = this.configurationService.getApiUrl();

  // returns false until initialized
  private _isReadySubject = new BehaviorSubject(false);

  public get isReadyEvent$(): Observable<boolean> {
    return this._isReadySubject
      .pipe(filter(ready => ready === true));
  }

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) { }

  public async initializePermissions(): Promise<void> {
    if (this.initialized) {
      console.debug('UserPermissionService already initialized.');
      return;
    }

    const results = await firstValueFrom(this.getUserPermissions());
    this.userPermissions = results;
    this.initialized = true;

    this._isReadySubject.next(true);
  }

  private getUserPermissions(): Observable<UserPermissionWithScope[]> {
    const url = `${this.apiUrl}/Application/GetUserPermissionsWithScope`;

    return this.httpClient.get<UserPermissionWithScope[]>(url);
  }

  public getAccessLevel(editPermissions: UserPermission[],
    viewPermissions: UserPermission[] = [UserPermission.ViewEverything], instrumentTypeId: number = null): AccessLevel {
    if (!this.initialized) {
      throw new Error('UserPermissionService is not initialized.');
    }

    let accessLevel = AccessLevel.Unauthorized;

    if (this.userPermissions && this.userPermissions.length > 0) {
      if (viewPermissions && viewPermissions.length > 0) {
        this.userPermissions.forEach(function (userPermission) {
          if (viewPermissions.includes(userPermission.userPermissionClaim)) {
            accessLevel = AccessLevel.ViewOnly;
            return;
          }
        });
      }

      if (editPermissions && editPermissions.length > 0) {
        this.userPermissions.forEach(function (userPermission) {
          if (editPermissions.includes(userPermission.userPermissionClaim)) {
            if (instrumentTypeId) {
              if (userPermission.isGlobal || userPermission.instrumentTypeId === instrumentTypeId) {
                accessLevel = AccessLevel.AddUpdate;
                return;
              }
            }
            else {
              accessLevel = AccessLevel.AddUpdate;
              return;
            }
          }

        });
      }
    }

    return accessLevel;
  }
}


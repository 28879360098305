<div class="about dialog-root">
  <div class="logo-container">
    <img src="./assets/images/qo_logo_stk_tm_vlt_rgb.png">
    <span class="app-title noselect">
      {{ 'About.QOIoT' | translate }}
    </span>
  </div>
  <div>{{ 'About.Version' | translate: {applicationVersion: applicationVersion} }}</div>
  <a *ngIf="showLicensesLink" href="./3rdpartylicenses.txt" target="_blank">{{ 'About.Licenses' | translate }}</a>
  <div>&copy; {{ 'About.QuidelOrthoCopyright' | translate: {year: (currentDate | date: yearFormat)} }}</div>
</div>

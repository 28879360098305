import { Pipe, PipeTransform } from '@angular/core';
import { format, BytesOptions } from 'bytes';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(value: number, options?: BytesOptions): string {
    return format(value, options);
  }
}

import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { DeviceFileMetadata, DeviceFilesRequest, DeviceFileType, Version, BrowserEntityRequest, BrowserEntityResult, DeviceFileStatus, DeviceFilesDownloadRequest, RecentDeviceFileStatusRequest } from '../models';
import { expectedErrorsString } from '../constants/interceptors.constants';
import { InactivityService } from './inactivity.service';
import { NotificationService } from './notification.service';
import { TranslateConstants } from '../constants/translate-constants';

@Injectable({
  providedIn: 'root'
})
export class DeviceFileService {
  private static readonly msVersionHeaders = new HttpHeaders({ 'x-ms-version': '2021-08-06' });
  private baseUrl = this.configurationService.getApiUrl();

  private deviceFilesUrl = '/DeviceFiles';
  private getDeviceFilesBySerialNumberUri = '/GetDeviceFileListBySerialNumber';
  private getDeviceFileDownloadUri = '/GetDeviceFileSasUri';
  private getDeviceFileStatusUri = '/GetDeviceFileStatus';
  private getDeviceFileExplorerUri = '/GetDeviceDirectoryInformation';
  private requestDeviceFilesDownloadUri = '/RequestDeviceFilesDownload';
  private requestRecentDeviceFileStatusExportUri = '/RequestRecentDeviceFileStatusExport';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService, private notificationService: NotificationService) { }

  downloadDeviceFile(downloadUrl: string, fileName: string): void {
    this.inactivityService.activityNow();
    this.httpClient.get(downloadUrl, { headers: DeviceFileService.msVersionHeaders, responseType: 'blob' })
      .subscribe((file: Blob) => {
        const blobUrl = window.URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.click();
      });
  }

  getDeviceFiles(serialNumber: string, instrumentTypeId: number, firmwareVersion: Version, type: DeviceFileType): Observable<DeviceFileMetadata[]> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.getDeviceFilesBySerialNumberUri}`;

    const request: DeviceFilesRequest = {
      serialNumber,
      instrumentTypeId,
      firmwareVersion,
      type: type
    };

    return this.httpClient.post<DeviceFileMetadata[]>(url, request);
  }

  getDeviceFileDownloadUrl(fileToDownload: DeviceFileMetadata): Observable<string> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.getDeviceFileDownloadUri}`;

    return this.httpClient.post<string>(url, fileToDownload)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  getDeviceFileStatus(fileToDownload: DeviceFileMetadata): Observable<DeviceFileStatus[]> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.getDeviceFileStatusUri}`;

    return this.httpClient.post<DeviceFileStatus[]>(url, fileToDownload)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  getDeviceDirectoryInfo(browserEntityRequests: BrowserEntityRequest[]): Observable<BrowserEntityResult[]> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.getDeviceFileExplorerUri}`;
    const headers = new HttpHeaders()
      .set(expectedErrorsString, ['400', '404']);
    return this.httpClient.post<BrowserEntityResult[]>(url, browserEntityRequests, { headers })
      .pipe(tap(() => this.inactivityService.activityNow()));

  }

  requestDeviceFilesDownload(downloadRequest: DeviceFilesDownloadRequest): Observable<boolean> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.requestDeviceFilesDownloadUri}`;

    return this.httpClient.post<boolean>(url, downloadRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  requestRecentDeviceFileStatusExport(exportRequest: RecentDeviceFileStatusRequest): Observable<void> {
    const url = `${this.baseUrl}${this.deviceFilesUrl}${this.requestRecentDeviceFileStatusExportUri}`;

    return this.httpClient.post<void>(url, exportRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  processDeviceDirctoryInfoBadRequestReponse(err: HttpErrorResponse): void {
    let results: BrowserEntityResult[];
    if (typeof err.error === 'string') {
      results = JSON.parse(err.error) as BrowserEntityResult[];
    } else {
      results = err.error as BrowserEntityResult[];
    }

    if (Array.isArray(results)) {
      const numErrorsToShowInNotification = 4;
      let errorsToShowInNotification = '';
      let failedResultsCount = 0;
      results.forEach(result => {
        if (result.error) {
          failedResultsCount++;
          const errorMessage = result.error.errorMessage;
          console.error(errorMessage);

          if (failedResultsCount <= numErrorsToShowInNotification) {
            errorsToShowInNotification += `<br/><br/>${errorMessage}`;
          }
        }
      });

      if (failedResultsCount > 0) {
        if (failedResultsCount > numErrorsToShowInNotification) {
          this.notificationService.error(TranslateConstants.FileBrowserRequestPartialFailureMessageMoreThanShownKey, { count: `${failedResultsCount}`, shownCount: `${numErrorsToShowInNotification}`, errorMessages: errorsToShowInNotification },
            TranslateConstants.FileBrowserRequestErrorTitleKey);
        } else {
          this.notificationService.error(TranslateConstants.FileBrowserRequestPartialFailureMessageAllShownKey, { count: `${failedResultsCount}`, errorMessages: errorsToShowInNotification },
            TranslateConstants.FileBrowserRequestErrorTitleKey);
        }
      }
    } else {
      this.notificationService.error(TranslateConstants.FileBrowserBadRequestKey);
    }
  }
}

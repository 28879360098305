<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="setOverrideForm">
      <p class="dialog-message" [innerHTML]="data.message"></p>

      <mat-form-field class="full-width">
        <mat-label>{{ 'Global.OverrideCountry' | translate }}</mat-label>
        <mat-select required formControlName="overrideCountryId">
          <mat-option [value]="country.countryId" *ngFor="let country of countries">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button class="btn" style="margin-right:1.0rem;" (click)="cancel()">{{ data.cancelText }}</button>
    <button mat-button class="btn btn-confirm" (click)="confirm()" [disabled]="setOverrideForm.invalid">{{
      data.confirmText }}</button>
  </div>
</div>

<div class="flex" [formGroup]="timeForm">
  <div class="time-item">
    <mat-form-field class="full-width">
      <mat-label>{{ 'Global.Milliseconds' | translate }} {{ 'Global.ReadOnly' | translate }}
      </mat-label>
      <input matInput readonly [placeholder]="'Global.Milliseconds' | translate" type="text"
        formControlName="milliseconds">
    </mat-form-field>
  </div>
  <div class="time-item">
    <mat-form-field class="full-width">
      <mat-label>{{ 'Global.Seconds' | translate }}
      </mat-label>
      <input matInput [placeholder]="'Global.Seconds' | translate" required type="number" formControlName="seconds">
      <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
        { min: minSeconds | number, max: maxSeconds | number } }}</mat-hint>
      <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
        { min: minSeconds | number, max: maxSeconds | number } }}</mat-error>
    </mat-form-field>
  </div>
  <div class="time-item">
    <mat-form-field class="full-width">
      <mat-label>{{ 'Global.Minutes' | translate }} {{ 'Global.ReadOnly' | translate }}
      </mat-label>
      <input matInput readonly [placeholder]="'Global.Minutes' | translate" type="text" formControlName="minutes">
    </mat-form-field>
  </div>
</div>

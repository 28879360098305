import { Version } from './version.model';
import { DeviceInformationConnectionsState, FileState } from './enum.models';
import { Country, InstrumentGroupsRequest } from '.';

export interface DeviceInformation {
  deviceInformationId: number;
  serialNumber: string;
  lnItemNumber: string;
  firmwareVersion: Version;
  lanMACAddress: string;
  wifiMACAddress: string;
  desiredPropertiesJson: string;
  reportedPropertiesJson: string;
  instrumentSchemaDesiredPropertiesJson: string;
  instrumentSchemaReportedPropertiesJson: string;
  virenaOptIn: boolean;
  virenaAutoSend: boolean;
  instrumentTypeId: number;
  modelId: number;
  notes: string;
  countryId: number;
  lastHeartbeatDateTime?: string;
  lastHeartbeatIsHealthy?: boolean;
  lastHeartbeatStatus?: string;
  lastHeartbeatExceedsMaxTime: boolean;
  lastUpdateDateTime?: string;
  assayVersions: AssayVersion[];
  currentAssayVersions?: AssayVersion[];
  requestedAssayVersions?: AssayVersion[];
  languageVersions: LanguageVersion[];
  currentLanguageVersions?: LanguageVersion[];
  requestedLanguageVersions?: LanguageVersion[];
  requestedFirmwareStatus?: string;
  requestedFirmwareVersion?: Version;
  requestedFirmwareInfo?: string;
  retryTimeDelayMilliseconds?: number;
  numberRetries?: number;
  chunkDownloadTimeoutInSeconds?: number;
  deviceInformationSettingsJsonByKey: { [key: string]: string };
  cloudToDeviceMessagesJsonByType: { [key: string]: string };
  awsDestinationFolderOverride?: string;
  currentConnectionState?: DeviceInformationConnectionsState;
  lastConnectionDate?: string;
  lastDisconnectionDate?: string;
}

export interface AssayVersion {
  deviceAssayVersionId: number;
  deviceInformationId: number;
  code: string;
  version: Version;
  interfaceVersion: number;
  status: string;
  state: FileState;
  info: string;
  forced?: boolean;
}

export interface LanguageVersion {
  deviceLanguageVersionId: number;
  deviceInformationId: number;
  code: string;
  version: Version;
  interfaceVersion: number;
  status: string;
  state: FileState;
  info: string;
}

export interface DeviceInformationRequest {
  deviceInformation: DeviceInformation;
  initialProperties?: string;
}

export interface PagingInformation {
  pageNumber: number;
  pageSize: number;
  pageNavigation: string;
  sortDirection: SortDirection;
  isScrollQuery: boolean;
}

export interface DeviceRequest {
  serialNumbers?: string[];
  queryByInstrumentSearchRequest?: SearchRequest;
  instrumentGroupIds?: number[];
  queryByInstrumentGroupSearchRequest?: InstrumentGroupsRequest;
}

export interface DeviceUpgradeRequest extends DeviceRequest {
  firmwareId: number;
  assayIds: number[];
  languageIds: number[];
  forceAssayDowngrade?: boolean;
}

export interface ForceCertificateRefreshRequest extends DeviceRequest {
  instumentTypeId: number;
}

export interface SearchRequest {
  serialNumber: string;
  firmware: FirmwareSearchRequest;
  assay: AssaySearchRequest;
  language: LanguageSearchRequest;
  instrumentTypeIds: number[];
  modelIds: number[];
  countryIds: number[];
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
  connectionState?: DeviceInformationConnectionsState;
  disableUnregisteredExactMatch: boolean;
}

export const prepareDeviceSearchRequest = (searchRequest: SearchRequest): void => {
  if (searchRequest.sortColumn) {
    searchRequest.sortColumn = `DeviceInformation.${searchRequest.sortColumn}`;
  }

  if (searchRequest.sortDirection === SortDirection.None.valueOf()) {
    delete searchRequest.sortDirection;
  }
};

export const prepareSearchGroupRequest = (instrumentGroupsRequest: InstrumentGroupsRequest): void => {
  if (instrumentGroupsRequest.sortColumn) {
    instrumentGroupsRequest.sortColumn = `InstrumentGroup.${instrumentGroupsRequest.sortColumn}`;
  }

  if (instrumentGroupsRequest.sortDirection === SortDirection.None) {
    delete instrumentGroupsRequest.sortDirection;
  }
};

export interface FirmwareSearchRequest {
  version: string;
  lessThan: boolean;
}

export interface AssaySearchRequest {
  negate: boolean;
  code: string;
}

export interface LanguageSearchRequest {
  negate: boolean;
  code: string;
}

export enum SortDirection {
  None = '--',
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export interface MatSortChangeEvent {
  active: string;
  direction: string;
}

export interface DeviceDataRequest {
  sortDirection: SortDirection;
  pageNumber: number;
  column: string;
  serialNumberSearch: string;
  firmwareVersionSearch: string;
  firmwareLessThan: boolean;
  amfCodeSearch: string;
  amfNegate: boolean;
  languageCodeSearch: string;
  languageNegate: boolean;
  pageSize: number;
  countries: Country[];
  connectionStateSearch?: DeviceInformationConnectionsState;
}

export interface MatPageChangeEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

export interface MatCheckboxChangeEvent {
  currentTarget: MatTarget;
}

export interface MatTarget {
  checked: boolean;
}

export interface DeviceManualApprovalEntry {
  serialNumber: string;
  instrumentTypeCode: string;
  instrumentTypeModelCode: string;
  deviceIdentifiersJson: string;
  countryCode: string;
  timestamp: Date;
  existingIsApproved?: boolean;
  existingDeviceIdentifiersJson?: string;
}

import { Injectable } from '@angular/core';
import { SerialNumberOverridesRequest } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class SerialNumberOverridesService {
  private defaultPageSize = 100;
  private defaultPageNumber = 1;

  getOverrideSearchRequest(overridesRequest: SerialNumberOverridesRequest, instrumentTypeId: number): SerialNumberOverridesRequest {
    let serialNumberOverridesRequest: SerialNumberOverridesRequest;
    if (overridesRequest) {
      serialNumberOverridesRequest = {
        serialNumber: overridesRequest.serialNumber,
        instrumentTypeId: instrumentTypeId,
        pageNumber: overridesRequest.pageNumber,
        pageSize: overridesRequest.pageSize,
        sortColumn: overridesRequest.sortColumn,
        sortDirection: overridesRequest.sortDirection
      };
    } else {
      serialNumberOverridesRequest = {
        serialNumber: undefined,
        instrumentTypeId: instrumentTypeId,
        pageNumber: this.defaultPageNumber,
        pageSize: this.defaultPageSize,
        sortColumn: undefined,
        sortDirection: undefined,
      };
    }

    return serialNumberOverridesRequest;
  }
}

<div class="dialog-root">
  <ng-container *ngIf="!deviceInformation?.deviceInformationId;else editTemplate">
    <div class="dialog-header">
      <h2>{{ 'Instruments.NewInstrumentHeader' | translate }} <span class="warning-text">
          {{ 'Instruments.NewInstrumentHeaderWarning' | translate }}</span></h2>
      <button class="close-button" mat-icon-button mat-dialog-close="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form [formGroup]="editInstrumentForm">
      <mat-dialog-content>
        <div [hidden]="changeGroupsMode">
          <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
            <mat-tab [label]="'Global.Details' | translate">
              <div class="field-container">
                <div class="instrument-type-container">
                  <mat-form-field class="full-width" [class.span-all-columns]="!showModelSelection">
                    <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
                    <mat-select formControlName="instrumentTypeId" (selectionChange)="onInstrumentTypeChange($event)"
                      required>
                      <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentType' | translate }}</mat-option>
                      <mat-option [value]="instrumentType.instrumentTypeId"
                        *ngFor="let instrumentType of instrumentTypes">
                        {{ instrumentType.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="editInstrumentForm.get('instrumentTypeId').invalid">
                      {{ 'Instruments.FormErrors.InstrumentTypeRequired' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="full-width" *ngIf="showModelSelection">
                    <mat-label>{{ 'Global.InstrumentTypeModel' | translate }}</mat-label>
                    <mat-select formControlName="modelId" required>
                      <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentTypeModel' | translate }}</mat-option>
                      <mat-option [value]="model.modelId" *ngFor="let model of modelsForSelectedInstrumentType">
                        {{ model.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'Global.SerialNumber' | translate }}</mat-label>
                  <input matInput [placeholder]="'Global.SerialNumber' | translate" type="text" required
                    formControlName="serialNumber">
                  <mat-error *ngIf="editInstrumentForm.get('serialNumber').hasError('required')">
                    {{ 'Instruments.FormErrors.SerialNumberRequired' | translate }}</mat-error>
                  <mat-error *ngIf="editInstrumentForm.get('serialNumber').hasError('maxlength')">
                    {{ 'Instruments.FormErrors.SerialNumberMaxLength' | translate: {maxLength:
                    editInstrumentForm.get('serialNumber').errors.maxlength.requiredLength} }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'Global.LnItemNumber' | translate }}</mat-label>
                  <input matInput [placeholder]="'Global.LnItemNumber' | translate" type="text" required
                    formControlName="lnItemNumber">
                  <mat-error *ngIf="editInstrumentForm.get('lnItemNumber').hasError('required')">
                    {{ 'Instruments.FormErrors.LnItemNumberRequired' | translate }}</mat-error>
                  <mat-error *ngIf="editInstrumentForm.get('lnItemNumber').hasError('maxlength')">
                    {{ 'Instruments.FormErrors.LnItemNumberMaxLength' | translate: {maxLength:
                    editInstrumentForm.get('lnItemNumber').errors.maxlength.requiredLength} }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="!editInstrumentForm.get('lanMACAddress').disabled" class="full-width">
                  <mat-label>{{ 'Instruments.LanMACAddress' | translate }}</mat-label>
                  <input matInput [placeholder]="'Instruments.LanMACAddress' | translate" type="text" required
                    formControlName="lanMACAddress">
                  <mat-error *ngIf="editInstrumentForm.get('lanMACAddress').hasError('required')">
                    {{ 'Instruments.FormErrors.LanMACAddressRequired' | translate }}</mat-error>
                  <mat-error *ngIf="editInstrumentForm.get('lanMACAddress').hasError('maxlength')">
                    {{ 'Instruments.FormErrors.LanMACAddressMaxLength' | translate: {maxLength:
                    editInstrumentForm.get('lanMACAddress').errors.maxlength.requiredLength} }}</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="!editInstrumentForm.get('wifiMACAddress').disabled" class="full-width">
                  <mat-label>{{ 'Instruments.WifiMACAddress' | translate }}</mat-label>
                  <input matInput [placeholder]="'Instruments.WifiMACAddress' | translate" type="text" required
                    formControlName="wifiMACAddress">
                  <mat-error *ngIf="editInstrumentForm.get('wifiMACAddress').hasError('required')">
                    {{ 'Instruments.FormErrors.WifiMACAddressRequired' | translate }}</mat-error>
                  <mat-error *ngIf="editInstrumentForm.get('wifiMACAddress').hasError('maxlength')">
                    {{ 'Instruments.FormErrors.WifiMACAddressMaxLength' | translate: {maxLength:
                    editInstrumentForm.get('wifiMACAddress').errors.maxlength.requiredLength} }}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'Global.Country' | translate }}</mat-label>
                  <input matInput [placeholder]="'Global.Country' | translate" type="text" required
                    formControlName="country" [matAutocomplete]="countryAuto">
                  <mat-autocomplete #countryAuto="matAutocomplete" [displayWith]="getCountryName">
                    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                      {{country.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error> {{ 'Instruments.FormErrors.CountryRequired' | translate }}</mat-error>
                </mat-form-field>

                <mat-accordion multi>
                  <ng-container *ngIf="showVirenaSettings">
                    <ng-container *ngTemplateOutlet="virenaSettings; context: {virenaSettingsForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showRetrySettings">
                    <ng-container *ngTemplateOutlet="retrySettings; context: {retrySettingsForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showDownloadSettings">
                    <ng-container
                      *ngTemplateOutlet="downloadSettings; context: {downloadSettingsForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showClinicalModeSettings">
                    <ng-container *ngTemplateOutlet="clinicalMode; context: {clinicalModeForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showTestResultsUpload">
                    <ng-container
                      *ngTemplateOutlet="testResultsUpload; context: {testResultsUploadForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showPerformanceDataSettings">
                    <ng-container
                      *ngTemplateOutlet="performanceData; context: {performanceDataForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="showAllowlistFolderPaths">
                    <ng-container
                      *ngTemplateOutlet="allowlistFolderPaths; context: {allowlistFolderPathsForm: editInstrumentForm}">
                    </ng-container>
                  </ng-container>
                </mat-accordion>

                <mat-form-field class="full-width">
                  <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
                  <textarea matInput [placeholder]="'Global.Notes' | translate" type="text"
                    formControlName="notes"></textarea>
                </mat-form-field>
              </div>
            </mat-tab>

            <mat-tab *ngIf="deviceInformation?.firmwareVersion" label="Instrument groups">
              <div class="group-list">
                <app-instrument-group-list [unpaginatedInstrumentGroupsArray]="instrumentGroups"
                  [mode]="ListMode.Filtered" [accessLevel]="accessLevel"
                  (removeInstrumentFromGroup)="removeFromInstrumentGroup($event)"
                  (refreshData)="getInstrumentGroupsByDeviceInformationId()">
                </app-instrument-group-list>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div *ngIf="changeGroupsMode">
          <div class="change-groups-grid">
            <app-instrument-group-list class="group-list" [instrumentGroupsResult]="allInstrumentGroups"
              [accessLevel]="accessLevel" (dataRequested)="instrumentGroupDataRequested($event)"
              [allCheckedInstrumentGroupsArray]="instrumentGroups" [mode]="ListMode.Multiselect"
              (checkedGroups)="onCheckedGroups($event)" (refreshData)="getInstrumentGroupsWithCurrentSearchState()">
            </app-instrument-group-list>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <button *ngIf="!changeGroupsMode" mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
          {{ 'Global.Cancel' | translate }}</button>
        <button *ngIf="!changeGroupsMode" mat-raised-button color="primary" (click)="onAddInstrument(true)"
          [disabled]="editInstrumentForm.invalid">
          {{ 'Global.Save' | translate }}</button>
        <!-- change groups -->
        <button *ngIf="changeGroupsMode" mat-raised-button color="warn" cdkFocusInitial (click)="cancelGroupsMode()">
          {{ 'Global.Cancel' | translate }}</button>
        <button *ngIf="changeGroupsMode" mat-raised-button color="primary" (click)="updateGroups()">
          {{ 'Instruments.Tabs.Footer.SaveGroups' | translate }}</button>
      </mat-dialog-actions>
    </form>
  </ng-container>

  <ng-template #editTemplate>
    <div [hidden]="hideMainContent">
      <div class="dialog-header">
        <h2 *ngIf="canAddUpdate">{{ 'Instruments.EditInstrumentHeader' | translate }}
          {{ headerInformation }}</h2>
        <h2 *ngIf="!canAddUpdate">{{ 'Instruments.ViewInstrumentHeader' | translate }}
          {{ headerInformation }}</h2>
        <button mat-icon-button class="refresh-dialog-btn" (click)="refreshAllData(deviceInformation)">
          <mat-icon>refresh</mat-icon>
        </button>
        <button class="close-button" mat-icon-button mat-dialog-close="false">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <form [formGroup]="editInstrumentForm">
        <mat-dialog-content>
          <div class="full-height" [hidden]="changeGroupsMode">
            <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" class="full-height" mat-stretch-tabs="false">
              <mat-tab [label]="'Global.Details' | translate">
                <div class="field-container">
                  <div class="instrument-type-container">
                    <mat-form-field class="full-width" [class.span-all-columns]="!showModelSelection">
                      <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
                      <mat-select formControlName="instrumentTypeId" required>
                        <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentType' | translate }}</mat-option>
                        <mat-option [value]="instrumentType.instrumentTypeId"
                          *ngFor="let instrumentType of instrumentTypes">
                          {{ instrumentType.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" *ngIf="showModelSelection">
                      <mat-label>{{ 'Global.InstrumentTypeModel' | translate }}</mat-label>
                      <mat-select formControlName="modelId" required>
                        <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentTypeModel' | translate
                          }}</mat-option>
                        <mat-option [value]="model.modelId" *ngFor="let model of modelsForSelectedInstrumentType">
                          {{ model.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>

                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Global.SerialNumber' | translate }} {{ 'Global.ReadOnly' | translate
                      }}</mat-label>
                    <input matInput readonly [placeholder]="'Global.SerialNumber' | translate" type="text"
                      formControlName="serialNumber">
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Global.LnItemNumber' | translate }}</mat-label>
                    <input matInput [placeholder]="'Global.LnItemNumber' | translate" type="text" required
                      formControlName="lnItemNumber">
                    <mat-error *ngIf="editInstrumentForm.get('lnItemNumber').hasError('required')">
                      {{ 'Instruments.FormErrors.LnItemNumberRequired' | translate }}</mat-error>
                    <mat-error *ngIf="editInstrumentForm.get('lnItemNumber').hasError('maxlength')">
                      {{ 'Instruments.FormErrors.LnItemNumberMaxLength' | translate: {maxLength:
                      editInstrumentForm.get('lnItemNumber').errors.maxlength.requiredLength} }}</mat-error>
                  </mat-form-field>

                  <mat-form-field *ngIf="!editInstrumentForm.get('lanMACAddress').disabled" class="full-width">
                    <mat-label>{{ 'Instruments.LanMACAddress' | translate }}</mat-label>
                    <input matInput [placeholder]="'Instruments.LanMACAddress' | translate" type="text" required
                      formControlName="lanMACAddress">
                    <mat-error *ngIf="editInstrumentForm.get('lanMACAddress').hasError('required')">
                      {{ 'Instruments.FormErrors.LanMACAddressRequired' | translate }}</mat-error>
                    <mat-error *ngIf="editInstrumentForm.get('lanMACAddress').hasError('maxlength')">
                      {{ 'Instruments.FormErrors.LanMACAddressMaxLength' | translate: {maxLength:
                      editInstrumentForm.get('lanMACAddress').errors.maxlength.requiredLength} }}</mat-error>
                  </mat-form-field>

                  <mat-form-field *ngIf="!editInstrumentForm.get('wifiMACAddress').disabled" class="full-width">
                    <mat-label>{{ 'Instruments.WifiMACAddress' | translate }}</mat-label>
                    <input matInput [placeholder]="'Instruments.WifiMACAddress' | translate" type="text" required
                      formControlName="wifiMACAddress">
                    <mat-error *ngIf="editInstrumentForm.get('wifiMACAddress').hasError('required')">
                      {{ 'Instruments.FormErrors.WifiMACAddressRequired' | translate }}</mat-error>
                    <mat-error *ngIf="editInstrumentForm.get('wifiMACAddress').hasError('maxlength')">
                      {{ 'Instruments.FormErrors.WifiMACAddressMaxLength' | translate: {maxLength:
                      editInstrumentForm.get('wifiMACAddress').errors.maxlength.requiredLength} }}</mat-error>
                  </mat-form-field>


                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Global.Country' | translate }} {{ 'Global.ReadOnly' | translate }}</mat-label>
                    <input matInput readonly="" [placeholder]="'Global.Country' | translate" type="text" required
                      [value]="getCountryName(editInstrumentForm.get('country').value)">
                  </mat-form-field>

                  <mat-accordion multi>
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ 'Instruments.ReportedSoftware' | translate }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-form-field class="full-width">
                        <mat-label>{{ firmwareVersionLabelKey | translate }} {{ 'Global.ReadOnly' | translate
                          }}</mat-label>
                        <input matInput readonly type="text" [placeholder]="firmwareVersionLabelKey | translate"
                          value="{{deviceInformation.firmwareVersion?.major}}.{{deviceInformation.firmwareVersion?.minor}}.{{deviceInformation.firmwareVersion?.revision}}">
                      </mat-form-field>

                      <div class="full-width" *ngIf="showAssays">
                        <mat-label class="label">
                          {{ 'Instruments.AssayVersions' | translate }} {{ 'Global.ReadOnly' | translate
                          }}</mat-label>
                        <div
                          *ngIf="deviceInformation.currentAssayVersions && deviceInformation.currentAssayVersions.length > 0; else noDataAvailable">
                          <div class="version-grid">
                            <div class="label">
                              {{ 'Global.Code' | translate }}
                            </div>
                            <div class="label">
                              {{ 'Global.InterfaceVersion' | translate }}
                            </div>
                            <div class="label">
                              {{ 'Global.Version' | translate }}
                            </div>
                          </div>
                          <div class="version-grid" *ngFor="let assayVersion of deviceInformation.currentAssayVersions">
                            <div>
                              {{assayVersion.code}}
                            </div>
                            <div>
                              {{assayVersion.interfaceVersion}}
                            </div>
                            <div>
                              {{assayVersion.version?.major}}.{{assayVersion.version?.minor}}.{{assayVersion.version?.revision}}
                            </div>
                            <button *ngIf="canAddUpdate && allowAssayDeletion" mat-mini-fab class="delete-assay"
                              color="warn" [attr.aria-label]="'Instruments.RequestAssayDeletion' | translate"
                              (click)="requestAssayFileDeletion(assayVersion)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="full-width" *ngIf="showLanguages">
                        <mat-label class="label">
                          {{ 'Instruments.LanguageVersions' | translate }} {{ 'Global.ReadOnly' | translate }}
                        </mat-label>
                        <div
                          *ngIf="deviceInformation.currentLanguageVersions && deviceInformation.currentLanguageVersions.length > 0; else noDataAvailable">
                          <div class="version-grid">
                            <div class="label">
                              {{ 'Global.Code' | translate }}
                            </div>
                            <div class="label">
                              {{ 'Global.InterfaceVersion' | translate }}
                            </div>
                            <div class="label">
                              {{ 'Global.Version' | translate }}
                            </div>
                          </div>
                          <div class="version-grid"
                            *ngFor="let languageVersion of deviceInformation.currentLanguageVersions">
                            <div>
                              {{languageVersion.code}}
                            </div>
                            <div>
                              {{languageVersion.interfaceVersion}}
                            </div>
                            <div>
                              {{languageVersion.version?.major}}.{{languageVersion.version?.minor}}.{{languageVersion.version?.revision}}
                            </div>
                            <button *ngIf="canAddUpdate && allowLanguageDeletion" mat-mini-fab class="delete-language"
                              color="warn" [attr.aria-label]="'Instruments.RequestLanguageDeletion' | translate"
                              (click)="requestLanguageFileDeletion(languageVersion)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="full-width" *ngIf="showSoftwareList">
                        <div class="version-grid">
                          <div class="label">
                            {{ 'Global.Software' | translate }}
                          </div>
                          <div class="label">
                            {{ 'Global.Version' | translate }}
                          </div>
                        </div>
                        <div class="version-grid" *ngFor="let software of softwareList">
                          <div>
                            {{software.name}}
                          </div>
                          <div>
                            {{software.version.display}}
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>

                    <ng-container *ngIf="showVirenaSettings">
                      <ng-container
                        *ngTemplateOutlet="virenaSettings; context: {virenaSettingsForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showRetrySettings">
                      <ng-container *ngTemplateOutlet="retrySettings; context: {retrySettingsForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showDownloadSettings">
                      <ng-container
                        *ngTemplateOutlet="downloadSettings; context: {downloadSettingsForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showClinicalModeSettings">
                      <ng-container *ngTemplateOutlet="clinicalMode; context: {clinicalModeForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showTestResultsUpload">
                      <ng-container
                        *ngTemplateOutlet="testResultsUpload; context: {testResultsUploadForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showPerformanceDataSettings">
                      <ng-container
                        *ngTemplateOutlet="performanceData; context: {performanceDataForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showAllowlistFolderPaths">
                      <ng-container
                        *ngTemplateOutlet="allowlistFolderPaths; context: {allowlistFolderPathsForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="showAwsSettings">
                      <ng-container *ngTemplateOutlet="awsSettings; context: {awsSettingsForm: editInstrumentForm}">
                      </ng-container>
                    </ng-container>
                  </mat-accordion>

                  <mat-form-field class="full-width margin-top-10">
                    <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
                    <textarea matInput #notes type="text" formControlName="notes"
                      [placeholder]="'Global.Notes' | translate"></textarea>
                  </mat-form-field>
                </div>
              </mat-tab>

              <mat-tab [label]="filesTabText" *ngIf="deviceInformation?.firmwareVersion && showFilesTab">
                <mat-accordion multi>
                  <mat-expansion-panel *ngIf="showLogFilesSection">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.Files.Sections.LogFiles' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="sub-panel">
                      <div *ngIf="showRequestLogs" class="sub-panel">
                        <button mat-raised-button color="primary" (click)="requestLogsUpload()">
                          {{ 'Instruments.Files.Sections.RequestLogsUpload' | translate }}
                        </button>

                        <span class="last-request-datetime-message">
                          {{ 'Instruments.Files.Sections.LastRequestedLabel' | translate: {lastRequested:
                          (logsLastRequestedDateTime ?
                          (logsLastRequestedDateTime | date:'full') : 'N/A')} }}</span>
                      </div>
                    </div>
                    <app-instrument-files-list [serialNumber]="deviceInformation.serialNumber"
                      [instrumentTypeId]="deviceInformation.instrumentTypeId"
                      [firmwareVersion]="deviceInformation.firmwareVersion" deviceFileType="log"
                      [noEntriesMessage]="'Instruments.NoLogs' | translate"
                      [refreshEntriesMessage]="'Instruments.Files.Sections.RefreshLogFiles' | translate"
                      [multiDownloadMessage]="'Instruments.Files.Sections.DownloadFiles' | translate"
                      [enableMultiSelect]="true" />
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="showLutFilesSection">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.Files.Sections.LutFiles' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="sub-panel">

                      <button *ngIf="showRequestLutFilesButton" mat-raised-button color="primary"
                        (click)="requestLutFileUpload()">
                        {{ 'Instruments.Files.Sections.RequestLutFilesUpload' | translate }}
                      </button>

                      <span class="last-request-datetime-message" *ngIf="showRequestLutFilesButton">
                        {{ 'Instruments.Files.Sections.LastRequestedLabel' | translate: {lastRequested:
                        (lutLastRequestedDateTime ?
                        (lutLastRequestedDateTime | date:'full') : 'N/A')} }}</span>
                    </div>

                    <app-instrument-files-list [serialNumber]="deviceInformation.serialNumber"
                      [instrumentTypeId]="deviceInformation.instrumentTypeId"
                      [firmwareVersion]="deviceInformation.firmwareVersion" deviceFileType="lut"
                      [noEntriesMessage]="'Instruments.NoLutFiles' | translate"
                      [refreshEntriesMessage]="'Instruments.Files.Sections.RefreshLutFiles' | translate"
                      [multiDownloadMessage]="'Instruments.Files.Sections.DownloadFiles' | translate"
                      [enableMultiSelect]="true" />
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="showFileBrowserSection">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.Files.Sections.UploadedFiles' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="sub-panel">
                      <button mat-raised-button color="primary" (click)="showFileBrowser()">
                        {{ 'Instruments.Files.Sections.ShowFileBrowser' | translate }}
                      </button>
                      <button *ngIf="showManualFileUploadItems" mat-raised-button color="primary"
                        (click)="beginManualFilesRequest()">
                        {{ 'Instruments.Files.Sections.ManuallyRequestFiles' | translate }}
                      </button>
                    </div>
                    <app-instrument-files-list [serialNumber]="deviceInformation.serialNumber"
                      [instrumentTypeId]="deviceInformation.instrumentTypeId"
                      [firmwareVersion]="deviceInformation.firmwareVersion" deviceFileType="uploaded"
                      [noEntriesMessage]="'Instruments.NoUploadedFiles' | translate"
                      [refreshEntriesMessage]="'Instruments.Files.Sections.RefreshUploadedFiles' | translate"
                      [multiDownloadMessage]="'Instruments.Files.Sections.DownloadFiles' | translate"
                      [showStatusColumn]="true" [enableMultiSelect]="true" />
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="showFileBrowserSection">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.Files.Sections.IoTAgentFiles' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="sub-panel">
                      <button *ngIf="showManualFileUploadItems" mat-raised-button color="primary"
                        (click)="beginManualFolderSearch()">
                        {{ 'Instruments.Files.Sections.ManualFolderSearch' | translate }}
                      </button>
                    </div>
                    <app-instrument-files-list [serialNumber]="deviceInformation.serialNumber"
                      [instrumentTypeId]="deviceInformation.instrumentTypeId"
                      [firmwareVersion]="deviceInformation.firmwareVersion" deviceFileType="iot_agent_internal"
                      [noEntriesMessage]="'Instruments.NoIoTAgentInternalFiles' | translate"
                      [refreshEntriesMessage]="'Instruments.Files.Sections.RefreshIoTAgentFiles' | translate"
                      [multiDownloadMessage]="'Instruments.Files.Sections.DownloadFiles' | translate"
                      [enableMultiSelect]="true" />
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>

              <mat-tab [label]="'Instruments.Tabs.RequestsDesiredState' | translate"
                *ngIf="deviceInformation?.firmwareVersion">
                <mat-accordion multi>
                  <mat-expansion-panel [expanded]="hasInstrumentSettingsDesiredState">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.InstrumentSettings' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="requests-container">
                      <div *ngIf="hasInstrumentSettingsDesiredState; else noUpgradesRequest">
                        <div
                          *ngFor="let item of instrumentSettingsDesiredState | keyvalue: sortInstrumentSettingsRootKeys">
                          <span class="bold">{{ tryGetTranslateKey([item.key]) | translate }}:</span>
                          <button [disabled]="!canCancelUpgrade" mat-mini-fab class="remove-request" color="warn"
                            [attr.aria-label]="'Instruments.RemoveSetting' | translate"
                            (click)="removeDeviceSettingRequest(deviceInformation, item.key)">
                            <mat-icon>delete</mat-icon>
                          </button>
                          <pre class="value json">{{ item.value | json}}</pre>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel [expanded]="hasUpgradesDesiredState">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.Upgrades' | translate }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="requests-container">
                      <ng-container>
                        <div class="title">
                          {{ requestedFirmwareLabelKey | translate }}
                        </div>
                        <div class="request-info" *ngIf="hasFirmwareUpgradeRequest; else noUpgradesRequest">
                          <div>
                            <mat-form-field class="full-width" *ngIf="deviceInformation.requestedFirmwareVersion">
                              <mat-label>{{ 'Global.Version' | translate }}</mat-label>
                              <input matInput readonly type="text"
                                value="{{deviceInformation.requestedFirmwareVersion?.major}}.{{deviceInformation.requestedFirmwareVersion?.minor}}.{{deviceInformation.requestedFirmwareVersion?.revision}}">
                              <input *ngIf="!deviceInformation.requestedFirmwareVersion" matInput readonly type="text"
                                value="-">
                            </mat-form-field>
                            <mat-form-field class="full-width" *ngIf="deviceInformation?.requestedFirmwareStatus">
                              <mat-label>{{ 'Global.Status' | translate }}</mat-label>
                              <input matInput readonly type="text" [value]="deviceInformation?.requestedFirmwareStatus">
                            </mat-form-field>
                            <div class="full-width">
                              <mat-label class="label">{{ 'Global.Info' | translate }}</mat-label>
                              <button mat-icon-button [disabled]="!deviceInformation.requestedFirmwareInfo"
                                class="copy-to-clipboard-btn"
                                [cdkCopyToClipboard]="deviceInformation.requestedFirmwareInfo"
                                (click)="showCopyToClipboardNotification()">
                                <mat-icon>file_copy</mat-icon>
                              </button>
                              <pre class="value json" [innerText]="deviceInformation.requestedFirmwareInfo || ''"></pre>
                            </div>
                          </div>
                          <button [disabled]="!canCancelUpgrade" mat-mini-fab class="remove-request" color="warn"
                            [attr.aria-label]="'Instruments.RemoveRequest' | translate"
                            (click)="removeFirmwareRequest(deviceInformation)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="showAssays">
                        <div class="title">
                          {{ 'Instruments.RequestedAssays' | translate }}
                        </div>
                        <div class="request-container" *ngIf="hasAssayUpgradeRequests; else noUpgradesRequest">
                          <div class="request-info"
                            *ngFor="let assayVersion of deviceInformation.requestedAssayVersions">
                            <div>
                              <mat-form-field class="full-width" *ngIf="assayVersion.code">
                                <span>
                                  <mat-label>{{ 'Global.Code' | translate }}</mat-label>
                                  <mat-icon *ngIf="assayVersion.forced"
                                    [matTooltip]="'Global.Forced' | translate">info</mat-icon>
                                </span>
                                <input matInput readonly [placeholder]="'Instruments.AssayCode' | translate" type="text"
                                  value="{{assayVersion.code}}">
                                <input *ngIf="!assayVersion.code" matInput readonly
                                  [placeholder]="'Instruments.AssayCode' | translate" type="text" value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width" *ngIf="assayVersion.interfaceVersion">
                                <mat-label>{{ 'Global.InterfaceVersion' | translate }}</mat-label>
                                <input matInput readonly type="text" value="{{assayVersion.interfaceVersion}}">
                                <input *ngIf="!assayVersion.interfaceVersion" matInput readonly type="text" value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width" *ngIf="assayVersion.version">
                                <mat-label>{{ 'Global.Version' | translate }}</mat-label>
                                <input matInput readonly type="text"
                                  value="{{assayVersion.version?.major}}.{{assayVersion.version?.minor}}.{{assayVersion.version?.revision}}">
                                <input *ngIf="!assayVersion.version" matInput readonly type="text" value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width">
                                <mat-label>{{ 'Global.Status' | translate }}</mat-label>
                                <input matInput readonly type="text" [value]="assayVersion.status || ''">
                              </mat-form-field>
                              <div class="full-width">
                                <mat-label class="label">{{ 'Global.Info' | translate }}</mat-label>
                                <button mat-icon-button [disabled]="!assayVersion.info" class="copy-to-clipboard-btn"
                                  [cdkCopyToClipboard]="assayVersion.info" (click)="showCopyToClipboardNotification()">
                                  <mat-icon>file_copy</mat-icon>
                                </button>
                                <pre class="value json" [innerText]="assayVersion.info || ''"></pre>
                              </div>
                            </div>
                            <button [disabled]="!canCancelUpgrade" mat-mini-fab class="remove-request" color="warn"
                              [attr.aria-label]="'Instruments.RemoveRequest' | translate"
                              (click)="removeAssayRequest(deviceInformation, assayVersion)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="showLanguages">
                        <div class="title">
                          {{ 'Instruments.RequestedLanguages' | translate }}
                        </div>
                        <div class="request-container" *ngIf="hasLanguagepgradeRequests; else noUpgradesRequest">
                          <div class="request-info "
                            *ngFor="let languageVersion of deviceInformation.requestedLanguageVersions">
                            <div>
                              <mat-form-field class="full-width" *ngIf="languageVersion.code">
                                <mat-label>{{ 'Global.Code' | translate }}</mat-label>
                                <input matInput readonly [placeholder]="'Instruments.LanguageCode' | translate"
                                  type="text" value="{{languageVersion.code}}">
                                <input *ngIf="!languageVersion.code" matInput readonly
                                  [placeholder]="'Instruments.LanguageCode' | translate" type="text" value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width" *ngIf="languageVersion.interfaceVersion">
                                <mat-label>{{ 'Global.InterfaceVersion' | translate }}</mat-label>
                                <input matInput readonly type="text" value="{{languageVersion.interfaceVersion}}">
                                <input *ngIf="!languageVersion.interfaceVersion" matInput readonly type="text"
                                  value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width" *ngIf="languageVersion.version">
                                <mat-label>{{ 'Global.Version' | translate }}</mat-label>
                                <input matInput readonly type="text"
                                  value="{{languageVersion.version?.major}}.{{languageVersion.version?.minor}}.{{languageVersion.version?.revision}}">
                                <input *ngIf="!languageVersion.version" matInput readonly type="text" value="-">
                              </mat-form-field>
                              <mat-form-field class="full-width">
                                <mat-label>{{ 'Global.Status' | translate }}</mat-label>
                                <input matInput readonly type="text" [value]="languageVersion.status || ''">
                              </mat-form-field>
                              <div class="full-width">
                                <mat-label class="label">{{ 'Global.Info' | translate }}</mat-label>
                                <button mat-icon-button [disabled]="!languageVersion.info" class="copy-to-clipboard-btn"
                                  [cdkCopyToClipboard]="languageVersion.info"
                                  (click)="showCopyToClipboardNotification()">
                                  <mat-icon>file_copy</mat-icon>
                                </button>
                                <pre class="value json" [innerText]="languageVersion.info || ''"></pre>
                              </div>
                            </div>
                            <button [disabled]="!canCancelUpgrade" mat-mini-fab class="remove-request" color="warn"
                              [attr.aria-label]="'Instruments.RemoveRequest' | translate"
                              (click)="removeLanguageRequest(deviceInformation, languageVersion)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel [expanded]="hasPendingC2DMessages">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        {{ 'Instruments.PendingMessages' | translate }}<span
                          *ngIf="hasPendingC2DMessages">&nbsp;{{'Instruments.CountLabel' | translate: {count:
                          c2dPendingMessages.length} }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="requests-container">
                      <div class="request-container" *ngIf="hasPendingC2DMessages; else noPendingMessage">
                        <mat-accordion multi>
                          <mat-expansion-panel class="pending-message-info"
                            *ngFor="let pendingMessage of c2dPendingMessages; let i = index">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <div class="title">#{{i + 1}} - <span class="extra-bold no-text-transform">
                                    {{ pendingMessage.messagePayload.type }}</span> - {{ 'Instruments.PendingFor' |
                                  translate }}
                                  <span class="extra-bold">
                                    {{ pendingMessage.pendingHours }}</span>
                                  {{ 'Instruments.Hours' | translate }}
                                  <span *ngIf="pendingMessage.resendCount > 0"> -
                                    {{pendingMessage.resendCount}} {{ 'Instruments.ResendAttempts' | translate }}</span>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <pre class="value json">{{ pendingMessage.messagePayload | json}}</pre>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>

              <mat-tab [label]="recentUpgradeHistoryTabText"
                *ngIf="deviceInformation?.firmwareVersion && showUpgradeHistoryTab">
                <app-upgrade-request-history #upgradeRequestHistory [serialNumber]="deviceInformation.serialNumber"
                  [instrumentTypeId]="deviceInformation.instrumentTypeId"></app-upgrade-request-history>
              </mat-tab>

              <!--Action History Tab-->
              <mat-tab
                [label]="(instrumentActionHistoryRows.length > 0 ? 'Instruments.Tabs.ActionHistory' : 'Instruments.Tabs.ActionHistoryWithoutCount') | translate: {count:instrumentActionHistoryRows.length}"
                *ngIf="deviceInformation?.firmwareVersion && showActionHistoryTab">
                <div class="field-container">
                  <div *ngIf="instrumentActionHistoryRows.length <= 0">
                    <p class="no-device-entries-message">
                      {{ 'Instruments.NoActionHistory' | translate }}
                    </p>
                  </div>
                  <mat-accordion *ngIf="instrumentActionHistoryRows.length > 0" multi>
                    <mat-expansion-panel *ngFor="let row of instrumentActionHistoryRows; let i = index">
                      <mat-expansion-panel-header class="taller"
                        [class.extra-tall]="row.messageInformation.type === C2DTypes.C2DFeedback">
                        <mat-panel-title>
                          <div>
                            <div class="title">#{{i + 1}} - <span class="extra-bold no-text-transform">{{
                                row.messageInformation.type }}</span> -
                              {{row?.timestamp | date:'full' }}
                              <div class="details">
                                {{ 'Instruments.ActionHistoryMessageId' | translate: {messageId:
                                row.messageInformation.messageId} }}</div>
                              <div *ngIf="row.messageInformation.type === C2DTypes.C2DFeedback" class="details">
                                {{ 'Instruments.ActionHistoryPayloadMessageId' | translate: {payloadMessageId:
                                row.messageInformation.messagePayload.messageId} }}</div>
                              <div class="details">
                                {{ 'Instruments.ActionHistoryLogTime' | translate: {logTime:
                                (row.logTimestamp | date:'full')} }}</div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <pre class="value json">{{ row.messageInformation  | json}}</pre>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-tab>

              <!--Connection Status Tab-->
              <mat-tab *ngIf="deviceInformation?.firmwareVersion">
                <ng-template mat-tab-label>
                  <div class="status" [class]="getStatusClassName(deviceInformation)"
                    [matTooltip]="getStatusTooltip(deviceInformation)"></div>
                  <span>{{ 'Instruments.Tabs.ConnectionStatus' | translate }}</span>
                </ng-template>
                <div class="field-container">
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Instruments.CurrentConnectionState' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.currentConnectionState || ('Instruments.ConnectionStateNotReceived' | translate)">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastConnectionDate !== undefined">
                    <mat-label>{{ 'Instruments.LastConnectionDateTimeLocal' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastConnectionDate | date: 'MM-dd-yyyy hh:mmaa'">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastConnectionDate !== undefined">
                    <mat-label>{{ 'Instruments.LastConnectionDateTimeUtc' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastConnectionDate | date: 'MM-dd-yyyy hh:mmaa' : 'UTC'">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastDisconnectionDate !== undefined">
                    <mat-label>{{ 'Instruments.LastDisconnectionDateTimeLocal' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastDisconnectionDate | date: 'MM-dd-yyyy hh:mmaa'">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastDisconnectionDate !== undefined">
                    <mat-label>{{ 'Instruments.LastDisconnectionDateTimeUtc' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastDisconnectionDate | date: 'MM-dd-yyyy hh:mmaa' : 'UTC'">
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ 'Instruments.LastHeartbeatStatus' | translate }}</mat-label>
                    <button mat-icon-button [disabled]="!deviceInformation.lastHeartbeatStatus"
                      class="copy-to-clipboard-heartbeat-btn"
                      [cdkCopyToClipboard]="deviceInformation.lastHeartbeatStatus"
                      (click)="showCopyToClipboardNotification()">
                      <mat-icon>file_copy</mat-icon>
                    </button>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastHeartbeatStatus || ('Instruments.HeartbeatNoStatusReceived' | translate)">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastHeartbeatDateTime !== undefined">
                    <mat-label>{{ 'Instruments.LastHeartbeatDateTimeLocal' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastHeartbeatDateTime | date: 'MM-dd-yyyy hh:mmaa'">
                  </mat-form-field>
                  <mat-form-field class="full-width" *ngIf="deviceInformation?.lastHeartbeatDateTime !== undefined">
                    <mat-label>{{ 'Instruments.LastHeartbeatDateTimeUtc' | translate }}</mat-label>
                    <input matInput readonly placeholder="" type="text"
                      [value]="deviceInformation?.lastHeartbeatDateTime | date: 'MM-dd-yyyy hh:mmaa' : 'UTC'">
                  </mat-form-field>
                </div>
              </mat-tab>

              <mat-tab [label]="instrumentGroupsTabText" *ngIf="deviceInformation?.firmwareVersion">
                <div class="group-list">
                  <app-instrument-group-list [unpaginatedInstrumentGroupsArray]="instrumentGroups"
                    [mode]="ListMode.Filtered" (removeInstrumentFromGroup)="removeFromInstrumentGroup($event)"
                    (refreshData)="getInstrumentGroupsByDeviceInformationId()" [accessLevel]="accessLevel">
                  </app-instrument-group-list>
                </div>
              </mat-tab>

              <mat-tab [label]="utilitiesTabText" *ngIf="deviceInformation?.firmwareVersion && showUtilitiesTab">
                <app-instrument-utilities [deviceInformation]="deviceInformation"
                  [instrumentTypeId]="deviceInformation?.instrumentTypeId" [instrumentTypes]="instrumentTypes">
                </app-instrument-utilities>
              </mat-tab>

              <mat-tab [label]="'Instruments.Tabs.DeviceTwinPropertiesJson' | translate"
                *ngIf="deviceInformation?.firmwareVersion">
                <div class="full-height all-device-twin-properties-container">
                  <div class="device-twin-properties-group-labels-container">
                    <mat-label class="device-twin-properties-group-label">
                      {{ 'Instruments.InstrumentSchema' | translate }}</mat-label>
                    <mat-label class="device-twin-properties-group-label">
                      {{ 'Instruments.QOIoTSchema' | translate }}</mat-label>
                  </div>
                  <div class="device-twin-properties-containers">
                    <div class="device-twin-properties-container">
                      <mat-label class="label">
                        {{ 'Instruments.Desired' | translate }} {{ 'Global.ReadOnly' | translate }}</mat-label>
                      <button mat-icon-button
                        [disabled]="!this.deviceInformation?.instrumentSchemaDesiredPropertiesJson"
                        class="copy-to-clipboard-btn"
                        [cdkCopyToClipboard]="this.deviceInformation?.instrumentSchemaDesiredPropertiesJson"
                        (click)="showCopyToClipboardNotification()">
                        <mat-icon>file_copy</mat-icon>
                      </button>
                      <pre class="value" [innerText]="instrumentSchemaDesiredPropertiesParsedJson | json"></pre>
                    </div>
                    <div class="device-twin-properties-container">
                      <mat-label class="label">{{ 'Instruments.Reported' | translate }} {{ 'Global.ReadOnly' |
                        translate }}</mat-label>
                      <button mat-icon-button
                        [disabled]="!this.deviceInformation?.instrumentSchemaReportedPropertiesJson"
                        class="copy-to-clipboard-btn"
                        [cdkCopyToClipboard]="this.deviceInformation?.instrumentSchemaReportedPropertiesJson"
                        (click)="showCopyToClipboardNotification()">
                        <mat-icon>file_copy</mat-icon>
                      </button>
                      <pre class="value" [innerText]="instrumentSchemaReportedPropertiesParsedJson | json"></pre>
                    </div>
                    <div class="device-twin-properties-container">
                      <mat-label class="label">
                        {{ 'Instruments.Desired' | translate }} {{ 'Global.ReadOnly' | translate }} </mat-label>
                      <button mat-icon-button [disabled]="!deviceInformation.desiredPropertiesJson"
                        class="copy-to-clipboard-btn" [cdkCopyToClipboard]="deviceInformation.desiredPropertiesJson"
                        (click)="showCopyToClipboardNotification()">
                        <mat-icon>file_copy</mat-icon>
                      </button>
                      <pre class="value" [innerText]="getJson(deviceInformation.desiredPropertiesJson) | json"></pre>
                    </div>
                    <div class="device-twin-properties-container">
                      <mat-label class="label">
                        {{ 'Instruments.Reported' | translate }} {{ 'Global.ReadOnly' | translate }}
                      </mat-label>
                      <button mat-icon-button [disabled]="!deviceInformation.reportedPropertiesJson"
                        class="copy-to-clipboard-btn" [cdkCopyToClipboard]="deviceInformation.reportedPropertiesJson"
                        (click)="showCopyToClipboardNotification()">
                        <mat-icon>file_copy</mat-icon>
                      </button>
                      <pre class="value" [innerText]="getJson(deviceInformation.reportedPropertiesJson) | json"></pre>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div *ngIf="changeGroupsMode">
            <div class="change-groups-grid">
              <app-instrument-group-list class="group-list" [instrumentGroupsResult]="allInstrumentGroups"
                [allCheckedInstrumentGroupsArray]="instrumentGroups" [mode]="ListMode.Multiselect"
                (dataRequested)="instrumentGroupDataRequested($event)" (checkedGroups)="onCheckedGroups($event)"
                (refreshData)="getInstrumentGroupsWithCurrentSearchState()" [accessLevel]="accessLevel">
              </app-instrument-group-list>
            </div>
          </div>
        </mat-dialog-content>

        <div class="tab-specific-buttons">
          <button *ngIf="canAddUpdate && canManageInstrumentGroups && !changeGroupsMode && showChangeGroupsButton"
            mat-raised-button color="primary" (click)="showChangeGroups()">
            {{ 'Instruments.Tabs.Footer.ChangeGroups' | translate }}
          </button>

          <button *ngIf="!changeGroupsMode && showLoadRecentUpgradeHistoryButton" mat-raised-button color="primary"
            (click)="loadRecentUpgradeRequestHistory()">
            {{ 'Instruments.Tabs.Footer.LoadRecentUpgradeRequestHistory' | translate }}
          </button>
        </div>

        <mat-dialog-actions align="end">
          <button *ngIf="(canAddUpdate || canSave) && !changeGroupsMode" mat-raised-button mat-dialog-close="false"
            color="warn" cdkFocusInitial>
            {{ 'Global.Cancel' | translate }}</button>

          <button *ngIf="!canAddUpdate && !canSave" mat-raised-button mat-dialog-close="false" color="warn"
            cdkFocusInitial>
            {{ 'Global.Close' | translate }}</button>

          <button *ngIf="!changeGroupsMode && canSave" mat-raised-button color="primary"
            (click)="onSaveInstrument(true)" [disabled]="editInstrumentForm.invalid">
            {{ 'Global.Save&Close' | translate }}</button>
          <button *ngIf="!changeGroupsMode && canSave" mat-raised-button color="primary"
            (click)="onSaveInstrument(false)" [disabled]="editInstrumentForm.invalid">
            {{ 'Global.Save' | translate }}</button>

          <!-- change groups -->
          <button *ngIf="changeGroupsMode" mat-raised-button color="warn" cdkFocusInitial (click)="cancelGroupsMode()">
            {{ 'Global.Cancel' | translate }}</button>
          <button *ngIf="changeGroupsMode && canAddUpdate" mat-raised-button color="primary" (click)="updateGroups()">
            {{ 'Instruments.Tabs.Footer.SaveGroups' | translate }}</button>
        </mat-dialog-actions>
      </form>
    </div>
    <app-file-browser *ngIf="isFileBrowserShowing" [deviceInformation]="deviceInformation"
      (closeEmitter)="closeFileBrowser()" />
    <app-folder-search *ngIf="isManualFolderSearchShowing" [deviceInformation]="deviceInformation"
      (closeEmitter)="closeManualFolderSearch()" />
    <app-request-files *ngIf="isManualRequestFilesShowing" [deviceInformation]="deviceInformation"
      (closeEmitter)="closeManualRequestFiles()" />
  </ng-template>
</div>

<ng-template #noDataAvailable>
  <div class="default-data">
    -
  </div>
</ng-template>

<ng-template #noUpgradesRequest>
  <div class="default-data">
    {{ 'Instruments.NoUpgradesRequested' | translate }}
  </div>
</ng-template>

<ng-template #noPendingMessage>
  <div class="default-data">
    {{ 'Instruments.NoPendingMessages' | translate }}
  </div>
</ng-template>


<ng-template #virenaSettings let-virenaSettingsForm="virenaSettingsForm">
  <mat-expansion-panel [formGroup]="virenaSettingsForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_FormKey]) | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [formGroupName]="DeviceSettingsConstants.VirenaSettings_FormKey">
      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]) | translate
          }}</mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle [formControlName]="DeviceSettingsConstants.VirenaSettings_OptIn_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>
      <mat-form-field *ngIf="showVirenaAutoSend" class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey]) | translate
          }}</mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle [formControlName]="DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #retrySettings let-retrySettingsForm="retrySettingsForm">
  <mat-expansion-panel [formGroup]="retrySettingsForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey]) | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [formGroupName]="DeviceSettingsConstants.RetrySettings_FormKey">
      <mat-form-field *ngIf="!showRetrySettingsTimeDelayMsTimeFormat" class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey,
          DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey],
          deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf() ? 'seconds' : undefined)
          | translate }}
        </mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]) | translate"
          required type="number" [formControlName]="DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey">
        <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
          getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,
          DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]) }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
          getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,
          DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]) }}</mat-error>
      </mat-form-field>
      <ng-container *ngIf="showRetrySettingsTimeDelayMsTimeFormat">
        <ng-container
          *ngTemplateOutlet=" retrySettingsTimeDelayTemplate; context: {retrySettingsForm: retrySettingsForm}">
        </ng-container>
      </ng-container>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey,
          DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey]) | translate"
          required type="number" [formControlName]="DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey">
        <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
          {min: DeviceSettingsConstants.RetrySettings_NumberRetries_Min,
          max: DeviceSettingsConstants.RetrySettings_NumberRetries_Max} }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
          {min: DeviceSettingsConstants.RetrySettings_NumberRetries_Min,
          max: DeviceSettingsConstants.RetrySettings_NumberRetries_Max} }}</mat-error>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #retrySettingsTimeDelayTemplate>
  <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey,
    DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey], instrumentType?.code) | translate }}
  </mat-label>
  <app-time-form expectedTimeUnits="ms"
    [minValue]="getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).minValue"
    [maxValue]="getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).maxValue"
    [inputValue]="editInstrumentForm.get([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).value"
    (valueChanged)="editInstrumentForm.get([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).setValue($event)" />
</ng-template>

<ng-template #downloadSettings let-downloadSettingsForm="downloadSettingsForm">
  <mat-expansion-panel [formGroup]="downloadSettingsForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey]) | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [formGroupName]="DeviceSettingsConstants.DownloadSettings_FormKey">
      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
          DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey]) | translate }}
        </mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey]) | translate"
          required type="number" [formControlName]="DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey">
        <mat-icon matPrefix *ngIf="showChunkDownloadTimeoutNotHonoredMessage"
          [matTooltip]="chunkDownloadTimeoutNotHonoredMessage">info</mat-icon>
        <mat-hint align="end">{{ 'DeviceSettings.MinMaxHintWithHrEquivalent' | translate:
          {min: DeviceSettingsConstants.DownloadSettings_TimeoutInS_Min,
          max: (DeviceSettingsConstants.DownloadSettings_TimeoutInS_Max | number),
          hourEquivalent: DeviceSettingsConstants.DownloadSettings_TimeoutInS_MaxHrEquivalent} }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
          {min: DeviceSettingsConstants.DownloadSettings_TimeoutInS_Min,
          max: (DeviceSettingsConstants.DownloadSettings_TimeoutInS_Max | number)} }}</mat-error>
      </mat-form-field>
      <div *ngIf="showDownloadSettings_Software"
        [formGroupName]="DeviceSettingsConstants.DownloadSettings_Software_FormKey">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
          DeviceSettingsConstants.DownloadSettings_Software_FormKey]) | translate }}</mat-label>
        <mat-form-field class="full-width">
          <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
            DeviceSettingsConstants.DownloadSettings_Software_FormKey,
            DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey]) | translate }}
          </mat-label>
          <input matInput
            [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_Software_FormKey, DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey]) | translate"
            required type="number"
            [formControlName]="DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey">
          <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
            {min: DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Min,
            max: (DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Max | number)} }}</mat-hint>
          <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
            {min: DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Min,
            max: (DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Max | number)} }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
            DeviceSettingsConstants.DownloadSettings_Software_FormKey,
            DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey]) | translate }}
          </mat-label>
          <input matInput
            [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_Software_FormKey, DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey]) | translate"
            required type="number"
            [formControlName]="DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey">
          <mat-hint align="end">{{ 'DeviceSettings.MinMaxHintWithHrEquivalent' | translate:
            {min: DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Min,
            max: (DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Max | number),
            hourEquivalent: DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_MaxHrEquivalent}
            }}</mat-hint>
          <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
            {min: DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Min,
            max: (DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Max | number)} }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #clinicalMode let-clinicalModeForm="clinicalModeForm">
  <mat-expansion-panel [formGroup]="clinicalModeForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey]) | translate }}
      </mat-panel-title>
      <mat-panel-description *ngIf="clinicalModeInDesiredPropertiesOnly">
        {{ 'DeviceSettings.InDesiredPropertiesOnly' | translate }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="!clinicalModeSettingsAdded">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="addClinicalModeSettings()">
        {{ 'Global.Add' | translate }}</button>
      <div *ngIf="!canAddUpdate">{{ 'DeviceSettings.NotConfigured' | translate }}</div>
    </div>
    <div *ngIf="clinicalModeSettingsAdded" [formGroupName]="DeviceSettingsConstants.ClinicalMode_FormKey">
      <button *ngIf="canAddUpdate && clinicalModeInDesiredPropertiesOnly" mat-raised-button color="primary"
        class="remove-setting"
        (click)="removeDeviceSettingRequest(deviceInformation, DeviceSettingsConstants.ClinicalMode_FormKey)">
        {{ 'Global.Remove' | translate }}</button>
      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey,
          DeviceSettingsConstants.ClinicalMode_SaveClinicalImages_FormKey]) | translate }}
        </mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle [formControlName]="DeviceSettingsConstants.ClinicalMode_SaveClinicalImages_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey,
          DeviceSettingsConstants.ClinicalMode_ExportDataToUsb_FormKey]) | translate }}
        </mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle [formControlName]="DeviceSettingsConstants.ClinicalMode_ExportDataToUsb_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #testResultsUpload let-testResultsUploadForm="testResultsUploadForm">
  <mat-expansion-panel [formGroup]="testResultsUploadForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey]) | translate }}
      </mat-panel-title>
      <mat-panel-description *ngIf="testResultsUploadInDesiredPropertiesOnly">
        {{ 'DeviceSettings.InDesiredPropertiesOnly' | translate }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="!testResultsUploadSettingsAdded">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="addTestResultsUploadSettings()">
        {{ 'Global.Add' | translate }}</button>
      <div *ngIf="!canAddUpdate">{{ 'DeviceSettings.NotConfigured' | translate }}</div>
    </div>
    <div *ngIf="testResultsUploadSettingsAdded" [formGroupName]="DeviceSettingsConstants.TestResultsUpload_FormKey">
      <button *ngIf="canAddUpdate && testResultsUploadInDesiredPropertiesOnly" mat-raised-button color="primary"
        class="remove-setting"
        (click)="removeDeviceSettingRequest(deviceInformation, DeviceSettingsConstants.TestResultsUpload_FormKey)">
        {{ 'Global.Remove' | translate }}</button>
      <div class="full-width flex">
        <mat-form-field class="flex-item-fill">
          <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
            DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey]) | translate }}</mat-label>
          <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
          <input matInput style="display: none" value=" ">
          <div>
            <mat-label class="leftside-slidetoggle-label">
              {{ 'Global.Off' | translate }}</mat-label>
            <mat-slide-toggle [formControlName]="DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey">
              {{ 'Global.On' | translate }}
            </mat-slide-toggle>
          </div>
        </mat-form-field>
        <button *ngIf="canAddUpdate" mat-raised-button color="primary" class="populate-defaults"
          (click)="populateDefaultSettings(DeviceSettingsConstants.TestResultsUpload_FormKey)">
          {{ 'DeviceSettings.PopulateDefaults' | translate }}</button>
      </div>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
          DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey]) | translate"
          type="text" required [formControlName]="DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
          DeviceSettingsConstants.TestResultsUpload_Server_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_Server_FormKey]) | translate"
          type="url" required [formControlName]="DeviceSettingsConstants.TestResultsUpload_Server_FormKey">
        <mat-error>{{ 'DeviceSettings.TestResultsUpload.FormErrors.Server' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
          DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey]) | translate"
          type="text" required [formControlName]="DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
          DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey]) | translate"
          required type="number"
          [formControlName]="DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey">
        <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
          {min: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Min,
          max: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Max} }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
          {min: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Min,
          max: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Max} }}</mat-error>
      </mat-form-field>

      <div class="full-width flex" *ngIf="showTestResultsUpload_DeleteAfterUpload">
        <mat-form-field class="flex-item-fill">
          <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
            DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey]) | translate }}</mat-label>
          <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
          <input matInput style="display: none" value=" ">
          <div>
            <mat-label class="leftside-slidetoggle-label">
              {{ 'Global.Off' | translate }}</mat-label>
            <mat-slide-toggle [formControlName]="DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey">
              {{ 'Global.On' | translate }}
            </mat-slide-toggle>
          </div>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #performanceData let-performanceDataForm="performanceDataForm">
  <mat-expansion-panel [formGroup]="performanceDataForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey]) | translate }}
      </mat-panel-title>
      <mat-panel-description *ngIf="performanceDataInDesiredPropertiesOnly">
        {{ 'DeviceSettings.InDesiredPropertiesOnly' | translate }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="!performanceDataSettingsAdded">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="addPerformanceDataSettings()">
        {{ 'Global.Add' | translate }}</button>
      <div *ngIf="!canAddUpdate">{{ 'DeviceSettings.NotConfigured' | translate }}</div>
    </div>
    <div *ngIf="performanceDataSettingsAdded" [formGroupName]="DeviceSettingsConstants.PerformanceData_FormKey">
      <button *ngIf="canAddUpdate && performanceDataInDesiredPropertiesOnly" mat-raised-button color="primary"
        class="remove-setting"
        (click)="removeDeviceSettingRequest(deviceInformation, DeviceSettingsConstants.PerformanceData_FormKey)">
        {{ 'Global.Remove' | translate }}</button>
      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey,
          DeviceSettingsConstants.PerformanceData_Enabled_FormKey]) | translate }}
        </mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle [formControlName]="DeviceSettingsConstants.PerformanceData_Enabled_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey,
          DeviceSettingsConstants.PerformanceData_Verbosity_FormKey]) | translate }}</mat-label>
        <input matInput
          [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey, DeviceSettingsConstants.PerformanceData_Verbosity_FormKey]) | translate"
          required type="number" [formControlName]="DeviceSettingsConstants.PerformanceData_Verbosity_FormKey">
        <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
          {min: DeviceSettingsConstants.PerformanceData_Verbosity_Min,
          max: DeviceSettingsConstants.PerformanceData_Verbosity_Max} }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
          {min: DeviceSettingsConstants.PerformanceData_Verbosity_Min,
          max: DeviceSettingsConstants.PerformanceData_Verbosity_Max} }}</mat-error>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #allowlistFolderPaths let-allowlistFolderPathsForm="allowlistFolderPathsForm">
  <mat-expansion-panel [formGroup]="allowlistFolderPathsForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.Settings_FormKey, DeviceSettingsConstants.Settings_IoT_FormKey,
        DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey]) | translate }}
      </mat-panel-title>
      <mat-panel-description *ngIf="allowlistFolderPathsInDesiredPropertiesOnly">
        {{ 'DeviceSettings.InDesiredPropertiesOnly' | translate }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="full-width flex">
      <button
        *ngIf="editInstrumentForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)?.value?.length > 0"
        mat-raised-button color="primary" class="settings-button" (click)="exportAllowlistFolderPaths()">
        {{ 'Instruments.Export' | translate }}</button>
      <button *ngIf="canAddUpdateAllowlistFolderPaths" mat-raised-button color="primary" class="settings-button"
        (click)="triggerUpdateAllowlistFolderPaths()">
        {{ 'Instruments.UpdateFromFile' | translate }}</button>
      <input class="no-display" placeholder="File" type="file" #updateAllowlistFolderPaths_file
        id="updateAllowlistFolderPaths_file" (change)="updateAllowlistFolderPaths($event.target.files)" accept=".txt">
      <button *ngIf="canAddUpdateAllowlistFolderPaths" mat-raised-button color="primary" class="settings-button"
        (click)="updateAllowlistFolderPathsWithDefaults()">
        {{ 'Instruments.UpdateWithDefaults' | translate }}</button>
      <button
        *ngIf="canAddUpdateAllowlistFolderPaths && allowlistFolderPathsAdded && allowlistFolderPathsInDesiredPropertiesOnly"
        mat-raised-button color="primary" class="remove-setting"
        (click)="removeDeviceSettingRequest(deviceInformation, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)">
        {{ 'Global.Remove' | translate }}</button>
    </div>
    <div *ngIf="!allowlistFolderPathsAdded">
      <div *ngIf="!canAddUpdate">{{ 'DeviceSettings.NotConfigured' | translate }}</div>
    </div>
    <div *ngIf="allowlistFolderPathsAdded" [formGroupName]="DeviceSettingsConstants.Settings_FormKey">
      <div [formGroupName]="DeviceSettingsConstants.Settings_IoT_FormKey">
        <div
          *ngIf="editInstrumentForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)?.value?.length > 0; else noAllowlistFolderPaths">
          <div
            *ngFor="let folderPath of editInstrumentForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath).value">
            {{ folderPath }}
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #noAllowlistFolderPaths>
  {{ 'DeviceSettings.Settings.IoT.NoAllowlistFolderPaths' | translate }}
</ng-template>

<ng-template #awsSettings let-awsSettingsForm="awsSettingsForm">
  <mat-expansion-panel [formGroup]="awsSettingsForm">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ tryGetTranslateKey([DeviceSettingsConstants.AwsSettings_FormKey]) | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [formGroupName]="DeviceSettingsConstants.AwsSettings_FormKey">
      <mat-form-field class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey]) | translate
          }}</mat-label>
        <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
        <input matInput style="display: none" value=" ">
        <div>
          <mat-label class="leftside-slidetoggle-label">
            {{ 'Global.Off' | translate }}</mat-label>
          <mat-slide-toggle #awsSettingsEnableFolder
            [formControlName]="DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey">
            {{ 'Global.On' | translate }}
          </mat-slide-toggle>
        </div>
      </mat-form-field>

      <mat-form-field *ngIf="awsSettingsEnableFolder.checked" class="full-width">
        <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey]) | translate
          }}</mat-label>
        <input matInput type="text" [formControlName]="DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey">
        <mat-hint align="end">{{ 'DeviceSettings.AlphaNumericMinMaxLengthHint' | translate:
          {min: DeviceSettingsConstants.AwsSettings_OverrideLength_Min,
          max: DeviceSettingsConstants.AwsSettings_OverrideLength_Max} }}</mat-hint>
        <mat-error>{{ 'DeviceSettings.AlphaNumericMinMaxLengthError' | translate:
          {min: DeviceSettingsConstants.AwsSettings_OverrideLength_Min,
          max: DeviceSettingsConstants.AwsSettings_OverrideLength_Max} }}</mat-error>
      </mat-form-field>

    </div>
  </mat-expansion-panel>
</ng-template>

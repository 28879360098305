import { DeviceInformation, InstrumentGroup, SerialNumberOverride } from '.';

export enum ListMode {
  All,
  Multiselect,
  Filtered,
  Singleselect
}

export interface CheckedDevicesEvent {
  checkedDevices: DeviceInformation[];
  allPages: boolean;
}

export interface CheckedGroupsEvent{
  checkedGroups: InstrumentGroup[];
  allPages: boolean;
}

export interface CheckedOverridesEvent{
  checkedOverrides: SerialNumberOverride[];
  allPages: boolean;

}

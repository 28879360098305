<form [formGroup]="utilitiesForm">
  <div class="field-container">
    <div class="title" *ngIf="showUpdateUserList">{{ 'Utilities.UpdateUserList' | translate }}</div>
    <div class="file-container" *ngIf="showUpdateUserList">
      <label class="file-label noselect" for="updateUserList_file">{{ 'Utilities.ChooseNewUserList' | translate
        }}</label>
      <input placeholder="File" type="file" #updateUserListFile id="updateUserList_file"
        (change)="updateUserList($event.target.files)">
    </div>

    <div class="title" *ngIf="showRequestUploadLut">{{ 'Utilities.LUTFiles' | translate }}</div>
    <button class="fit-content" *ngIf="showRequestUploadLut" mat-raised-button color="primary"
      (click)="requestLutFileUpload()">{{ 'Utilities.RequestLUTFiles' | translate }}</button>


    <div *ngIf="showFileDownload">
      <div class="title">{{ (instrumentGroupId ? 'Utilities.UploadFileToInstruments' :
        'Utilities.UploadFileToInstrument') | translate }}</div>
      <div [formGroupName]="DownloadFile_FormKey" class="display-flex">
        <mat-form-field class="flex-1">
          <mat-label>{{ 'Utilities.UploadFileTargetFolderPath' | translate }}
          </mat-label>
          <input matInput [placeholder]="'Utilities.UploadFileTargetFolderPath' | translate" type="text"
            [formControlName]="DownloadFile_TargetFolderPath_FormKey">
          <mat-hint align="end">{{ 'Utilities.UploadFileRelativePath' | translate }}</mat-hint>
        </mat-form-field>
        <div class="file-container" *ngIf="showFileDownload">
          <label class="file-label noselect" for="fileDownload_file">{{ 'Utilities.UploadFile' | translate }}</label>
          <input placeholder="File" type="file" #fileDownloadFile id="fileDownload_file"
            (change)="requestFileDownload($event.target.files)">
        </div>
      </div>
    </div>

    <div *ngIf="showRequestLogFiles" class="title">{{ 'Utilities.LogFiles' | translate }}</div>
    <button *ngIf="showRequestLogFiles" class="fit-content" mat-raised-button color="primary"
      (click)="requestLogFiles()">{{ 'Utilities.RequestLogFiles' | translate }}</button>


    <div *ngIf="showDisableIoTConnection" class="sub-panel">
      <button mat-raised-button color="primary" (click)="requestDisableIoTConnection()">
        {{ 'Utilities.DisableIoTConnection' | translate }}
      </button>

      <span class="last-request-datetime-message">
        {{ 'Utilities.LastRequestedLabel' | translate: {lastRequested:
        (disableIoTLastRequestedDateTime ?
        (disableIoTLastRequestedDateTime | date:'full') : 'N/A')} }}</span>
    </div>

    <div *ngIf="showCertificateRefreshButton" class="sub-panel">
      <button mat-raised-button color="primary" (click)="forceCertificateRefresh()">
        {{ (deviceInformation ? 'Utilities.RefreshDeviceCertificate' : 'Utilities.RefreshDeviceCertificates') |
        translate }}
      </button>

      <div class="refresh-device-cert-messages">
        <div class="refresh-device-cert-warning">{{ (deviceInformation ? 'Utilities.RefreshDeviceCertificateWarning' :
          'Utilities.RefreshDeviceCertificatesWarning') | translate }}</div>
        <div>{{ (deviceInformation ? 'Utilities.RefreshDeviceCertificateInfo' :
          'Utilities.RefreshDeviceCertificatesInfo') | translate }}</div>
        <div *ngIf="instrumentGroupId" class="refresh-device-cert-disclaimer">
          {{ 'Utilities.RefreshDeviceCertificatesDisclaimer' | translate }}</div>
      </div>
    </div>

    <div *ngIf="nothingShown">
      {{ 'Utilities.NoneShowing' | translate }}
    </div>
  </div>
</form>

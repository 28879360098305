import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { SortDirection, User, UsersResult } from '../models';
import { Observable, tap } from 'rxjs';
import { InactivityService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private apiUrl: string = this.configurationService.getApiUrl();
  private userUrl = '/User';
  private searchUrl = '/Search';
  private getNextPageUrl = '/GetNextPage';
  private getUserUrl = '/GetUser';
  private updateUserClaimsUrl = '/UpdateUserClaims';
  private clearUserClaimsUrl = '/ClearUserClaims';

  private searchTextField = 'searchText';
  private pageSizeField = 'pageSize';
  private sortColumnField = 'sortColumn';
  private sortDirectionField = 'sortDirection';
  private userIdField = 'userId';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService) { }

  public search(searchText: string, pageSize: number, sortColumn: string, sortDirection: SortDirection): Observable<UsersResult> {
    const query = `${this.apiUrl}${this.userUrl}${this.searchUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(this.searchTextField, searchText)
      .set(this.pageSizeField, pageSize)
      .set(this.sortColumnField, sortColumn)
      .set(this.sortDirectionField, sortDirection);
    return this.httpClient.get<UsersResult>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getNextPage(nextPageLink: string): Observable<UsersResult> {
    const query = `${this.apiUrl}${this.userUrl}${this.getNextPageUrl}`;
    return this.httpClient.post<UsersResult>(query, { pageLink: nextPageLink })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getUser(userId: string): Observable<User> {
    const query = `${this.apiUrl}${this.userUrl}${this.getUserUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(this.userIdField, userId);
    return this.httpClient.get<User>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateUserClaims(user: User): Observable<User> {
    const query = `${this.apiUrl}${this.userUrl}${this.updateUserClaimsUrl}`;
    return this.httpClient.post<User>(query, user)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public clearUserClaims(userId: string): Observable<void> {
    const query = `${this.apiUrl}${this.userUrl}${this.clearUserClaimsUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(this.userIdField, userId);
    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }
}


import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AccessLevel, Country, InstrumentType, SerialNumberOverride, SerialNumberOverrideDialogData, UserPermission } from 'src/app/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService, SelectionAndCacheService, UserPermissionService } from 'src/app/services';
import { FormBuilder, Validators } from '@angular/forms';
import { forbiddenValueValidator } from 'src/app/shared/validators';

@Component({
  selector: 'app-serialnumber-override-dialog',
  templateUrl: './serialnumber-override-dialog.component.html',
  styleUrls: ['./serialnumber-override-dialog.component.scss']
})
export class SerialNumberOverrideDialogComponent implements OnInit, OnDestroy {

  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private subscription = new Subscription();
  public countries: Country[] = [];
  public instrumentTypes: InstrumentType[] = [];

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  public serialNumberOverride: SerialNumberOverride;

  /* eslint-disable @typescript-eslint/unbound-method */
  public editOverrideForm = this.fb.group(
    {
      serialNumber: ['', [Validators.required, Validators.maxLength(50)]],
      countryId: [0, [Validators.required, Validators.min(1)]],
      overrideCountryId: [0],
      instrumentTypeId: [0, [forbiddenValueValidator(0)]],
      productCode: ['', [Validators.required, Validators.maxLength(50)]],
      createdBy: [{ value: '', disabled: true }, []],
      createdDate: [{ value: '', disabled: true }, []],
      lastModifiedBy: [{ value: '', disabled: true }, []],
      lastModifiedDate: [{ value: '', disabled: true }, []]
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  constructor(@Inject(MAT_DIALOG_DATA) public data: SerialNumberOverrideDialogData,
    private userPermissionService: UserPermissionService,
    private selectionAndCacheService: SelectionAndCacheService,
    private dataService: DataService,
    private mdDialogRef: MatDialogRef<SerialNumberOverrideDialogComponent>,
    private fb: FormBuilder,) {

    this.countries = this.selectionAndCacheService.countries;
    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;

    this.serialNumberOverride = data?.serialNumberOverride;
    if (this.serialNumberOverride?.serialNumberOverrideId) {
      this.editOverrideForm.patchValue({
        serialNumber: this.serialNumberOverride.serialNumber,
        instrumentTypeId: this.serialNumberOverride.instrumentTypeId,
        countryId: this.serialNumberOverride.countryId,
        overrideCountryId: this.serialNumberOverride.overrideCountryId ? this.serialNumberOverride.overrideCountryId : 0,
        productCode: this.serialNumberOverride.productCode,
        createdBy: this.serialNumberOverride.createdBy,
        createdDate: this.serialNumberOverride.createdDate,
        lastModifiedBy: this.serialNumberOverride.lastModifiedBy,
        lastModifiedDate: this.serialNumberOverride.lastModifiedDate
      });
      if (this.serialNumberOverride.serialNumberOverrideId != null) {
        this.editOverrideForm.get('serialNumber').disable();
        this.editOverrideForm.get('instrumentTypeId').disable();
        this.editOverrideForm.get('countryId').disable();
      }
    }
    else if (this.selectionAndCacheService.selectedInstrumentType) {
      this.editOverrideForm.patchValue({
        instrumentTypeId: this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId
      });
    }


  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [], this.editOverrideForm.value.instrumentTypeId);
    }));

  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public saveOverride(): void {
    const formValue = this.editOverrideForm.value;

    const override: SerialNumberOverride = {
      serialNumberOverrideId: this.serialNumberOverride.serialNumberOverrideId,
      serialNumber: this.serialNumberOverride.serialNumber,
      instrumentTypeId: this.serialNumberOverride.instrumentTypeId,
      countryId: this.serialNumberOverride.countryId,
      overrideCountryId: formValue.overrideCountryId,
      productCode: formValue.productCode,
      createdBy: this.serialNumberOverride.createdBy,
      createdDate: this.serialNumberOverride.createdDate,
      lastModifiedBy: this.serialNumberOverride.lastModifiedBy,
      lastModifiedDate: this.serialNumberOverride.lastModifiedDate
    };

    if(override.overrideCountryId == 0) {
      delete override.overrideCountryId;
    }

    if (!this.serialNumberOverride.serialNumberOverrideId) {
      override.instrumentTypeId = formValue.instrumentTypeId;
      override.serialNumber = formValue.serialNumber;
      override.countryId = formValue.countryId;
    }

    this.dataService.upsertSerialNumberOverride(override).subscribe((id: number) => {
      this.close(false);
    });
  }
}

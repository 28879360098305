<div class="page">
  <div class="grid">
    <h1>{{ 'Instruments.UnregisteredInstruments' | translate }}</h1>

    <app-device-list [getDeviceInformationResult]="deviceInformationResult" [showOnlyUnregistered]="true"
      [instrumentTypeId]="selectedInstrumentType?.instrumentTypeId" (deviceDataRequested)="deviceDataRequested($event)"
      (editDevice)="onEditDevice($event)" (deleteDevice)="onDeleteDevice($event)" [accessLevel]="accessLevel"
      [serialNumberSelectable]="true"></app-device-list>
  </div>
</div>

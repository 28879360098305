import { Component, Inject } from '@angular/core';
import { DateConstants } from '../../constants/date-constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AboutDialogData {
  appVersion: string;
  showLicensesLink: boolean;
}

@Component({
  templateUrl: './about.dialog.html',
  styleUrls: ['./about.dialog.scss']
})
export class AboutDialogComponent {

  public applicationVersion: string;
  public showLicensesLink = false;
  public currentDate = new Date();
  public yearFormat = DateConstants.YearFormat;

  constructor(@Inject(MAT_DIALOG_DATA) data: AboutDialogData) {
    this.applicationVersion = data.appVersion;
    this.showLicensesLink = data.showLicensesLink;
  }
}

export interface FolderEntry {
  id: string;
  name: string;
  type: 'folder' | 'file';
  size: number;
  downloadAllowed: boolean;
  authorized: boolean;
  lastModifiedDate: Date;
}

export interface BrowserEntityRequestError {
  errorMessage: string;
  errorCode: string;
}

export interface BrowserEntityRequest {
  deviceId: string;
  path: string;
  uploadRequest: boolean;
  searchRegex: string;
  maxPageSize: number;
  page: number;
  sortDescending: boolean;
  sortColumn: string;
}

export interface BrowserEntityResult {
  deviceId: string;
  name: string;
  extension: string;
  path: string;
  totalItems: number;
  folderEntries: FolderEntry[];
  error: BrowserEntityRequestError;
}

import { Injectable } from '@angular/core';
import { InstrumentGroupListRequest } from 'src/app/components/instrument-group-list/instrument-group-list.component';
import { InstrumentGroupsRequest } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class GroupHelperService {
  private defaultPageSize = 100;
  private defaultPageNumber = 1;

  getGroupSearchRequest(groupsRequest: InstrumentGroupListRequest, instrumentTypeId: number): InstrumentGroupsRequest {
    let instrumentGroupsRequest: InstrumentGroupsRequest;
    if (groupsRequest) {
      instrumentGroupsRequest = {
        instrumentGroupName: groupsRequest.instrumentGroupName,
        instrumentTypeId: instrumentTypeId,
        pageNumber: groupsRequest.pageNumber,
        pageSize: groupsRequest.pageSize,
        sortColumn: groupsRequest.sortColumn,
        sortDirection: groupsRequest.sortDirection
      };
    } else {
      instrumentGroupsRequest = {
        instrumentGroupName: undefined,
        instrumentTypeId: instrumentTypeId,
        pageNumber: this.defaultPageNumber,
        pageSize: this.defaultPageSize,
        sortColumn: undefined,
        sortDirection: undefined,
      };
    }

    return instrumentGroupsRequest;
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private inactivityTimeoutInMinutes = 60;
  private lastActivity: Date;

  constructor(private configurationService: ConfigurationService,
    private msalService: MsalService) {
  }

  public refreshSettings(): void {
    const clientConfig = this.configurationService.getClientConfiguration();
    this.inactivityTimeoutInMinutes = clientConfig.inactivityTimeoutInMinutes;
  }

  public activityNow(): void {
    this.lastActivity = new Date();
  }

  public checkForInactivityTimeout(signoutIfInactive = true): boolean {
    let inactive = false;
    if (this.lastActivity) {
      const timeElapsedInMs = (Date.now() - this.lastActivity.valueOf());

      const timeElapseInMinutes = timeElapsedInMs / 1000 / 60;
      inactive = timeElapseInMinutes >= this.inactivityTimeoutInMinutes;
    }

    if (inactive && signoutIfInactive) {
      this.msalService.logout();
    }

    return inactive;
  }
}

import { FolderEntry } from './browserEntity.models';
import { DeviceRequest } from './device.models';
import { Version } from './version.model';

export class C2DTypes {
  public static readonly C2DFeedback = 'c2d-feedback';
}

export interface UpdateUserListRequest extends DeviceRequest {
  userList: string;
}

export interface DeleteFilesRequest extends DeviceRequest {
  assayCodes: string[];
  languageCodes: string[];
}

export interface DownloadFileRequest extends DeviceRequest {
  blobName: string;
  targetFileName: string;
  targetFolderPath: string;
}

export interface DisableIoTConnectionC2DRequest {
  serialNumber: string;
  instrumentTypeId: number;
}

export const folderSearchC2DResultLimitMinMax = {
  min: 1,
  max: 500000
};
export interface FolderSearchC2DRequest {
  serialNumber: string;
  folderPath: string;
  searchRegex: string;
  sortColumn: string;
  sortDescending: boolean;
  resultLimit: number;
}

export interface C2DFeatureFlagState {
  c2DMessagesEnabled: boolean;
  specificMessageTypeEnabled: boolean;
  enabledForMessageTypeByFeatureFlagName: { [instrumentTypeName: string]: boolean };
}

export interface C2DMessageRequestResponse {
  c2DMessageType: string;
  messageSentToAllInstruments: boolean;
  featureFlagStateByInstrumentTypeName: { [instrumentTypeName: string]: C2DFeatureFlagState };
  requestedDateTimeUtc: Date;
}

export interface C2DFolderSearchResult {
  totalItems: number;
  fullPaths: string[];
  entries: FolderEntry[];
  version: Version;
}

import { Version } from './version.model';
import { PublishedFlag } from './enum.models';

export interface Firmware {
  firmwareId: number;
  lnItemNumber: string;
  title: string;
  notes: string;
  version: Version;
  instrumentFileTypeId: number;
  instrumentTypeId: number;
  modelIds: number[];
  mD5: string;
  publishedFlag: PublishedFlag;
  fileUri: string;
  compatibleFirmwareIds: number[];
  countryIds: number[];
  assayIds: number[];
  languageIds: number[];
  assayInterfaceIds: number[];
  languageInterfaceIds: number[];
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  devicesCount: number;
  lpfHeader?: string;
  lufTableOfContents?: string;
}

export interface FirmwareForm extends Firmware {
  major?: number;
  minor?: number;
  revision?: number;
}

<div class="container">
  <div class="container-grid">
    <h1>{{ firmwareFilesTranslateKey | translate }}</h1>
    <div class="action-bar">
      <div>
        <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="onAddFirmware()">
          <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
        </button>
      </div>
      <div></div>
      <div>
        <button mat-icon-button class="refresh" (click)="loadFirmwares()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="filter-bar">
      <mat-form-field>
        <mat-label>{{ 'Firmwares.InstrumentFileType' | translate }}</mat-label>
        <mat-select [(value)]="selectedInstrumentFileType">
          <mat-option [value]="undefined">
            -
          </mat-option>
          <mat-option *ngFor="let type of instrumentFileTypes" [value]="type">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="version" matSortDirection="desc"
        matSortDisableClear="true">
        <ng-container [matColumnDef]="idFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Id' | translate }}</th>
          <td mat-cell *matCellDef="let firmware"> {{firmware.firmwareId}} </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Title' | translate }}</th>
          <td mat-cell *matCellDef="let firmware"> {{firmware.title}} </td>
        </ng-container>
        <ng-container matColumnDef="instrumentFileTypeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.FileType' | translate }}</th>
          <td mat-cell *matCellDef="let firmware">{{getInstrumentFileTypeDisplayValue(firmware.instrumentFileTypeId)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="instrumentTypeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.InstrumentType' | translate }}</th>
          <td mat-cell *matCellDef="let firmware">{{getInstrumentTypeDisplayValue(firmware.instrumentTypeId)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="modelIdsCountFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.#OfModels' | translate }}</th>
          <td mat-cell *matCellDef="let firmware">{{getInstrumentTypeModelsCount(firmware)}}</td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Version' | translate }}</th>
          <td mat-cell *matCellDef="let firmware">
            {{firmware.version.major}}.{{firmware.version.minor}}.{{firmware.version.revision}} </td>
        </ng-container>
        <ng-container [matColumnDef]="statusFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Status' | translate }}</th>
          <td mat-cell *matCellDef="let firmware"> {{getPublishedFlagDisplayValue(firmware.publishedFlag)}} </td>
        </ng-container>
        <ng-container matColumnDef="devicesCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.#OfInstruments' | translate }}</th>
          <td mat-cell *matCellDef="let firmware"> {{firmware.devicesCount | number}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let firmware">
            <div *ngIf="canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditFirmware(firmware)">
                <mat-icon>edit</mat-icon>
              </button>

              <button mat-icon-button [disabled]="!canAddUpdate || firmware.publishedFlag !== PublishedFlag.Unpublished"
                (click)="onDeleteFirmware(firmware)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditFirmware(firmware)">
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToUse"></tr>
      </table>
    </div>
  </div>
</div>

<div class="row content">
  <table mat-table [dataSource]="dataSource" matSort [matSortActive]="lastModifiedDateFieldName" matSortDirection="desc"
    matSortDisableClear="true">
    <ng-container [matColumnDef]="multiSelectFieldName">
      <th mat-header-cell *matHeaderCellDef disabled mat-sort-header></th>
      <td mat-cell *matCellDef="let fileMetadata">
        <input type="checkbox" [checked]="isChecked(fileMetadata)" (change)="selectionChanged($event, fileMetadata)">
      </td>
    </ng-container>
    <ng-container [matColumnDef]="downloadFieldName">
      <th mat-header-cell *matHeaderCellDef disabled mat-sort-header></th>
      <td mat-cell *matCellDef="let fileMetadata">
        <button mat-icon-button (click)="downloadDeviceFile(fileMetadata)">
          <span class="mat-button-wrapper">
            <mat-icon class="mat-icon">cloud_download</mat-icon>
          </span>
        </button>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="fileNameFieldName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'InstrumentFilesList.FileName' | translate }}</th>
      <td mat-cell *matCellDef="let fileMetadata">{{fileMetadata.fileName}}</td>
    </ng-container>
    <ng-container [matColumnDef]="sizeFieldName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'InstrumentFilesList.Size' | translate }}</th>
      <td mat-cell *matCellDef="let fileMetadata">{{ fileMetadata.size | bytes : {unitSeparator: ' '} }}</td>
    </ng-container>
    <ng-container [matColumnDef]="lastModifiedDateFieldName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'InstrumentFilesList.LastModifiedDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let fileMetadata"> {{fileMetadata.lastModifiedDate}}</td>
    </ng-container>
    <ng-container [matColumnDef]="statusFieldName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'InstrumentFilesList.Status' | translate }}
      </th>
      <td mat-cell *matCellDef="let fileMetadata">
        <button mat-icon-button (click)="getFileStatus(fileMetadata, deviceFileStatusTemplate)">
          <mat-icon class="mat-icon">info</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
    <tr mat-row *matRowDef="let fileMetadata; columns: columnsToUse">
    </tr>
  </table>
  <div class="no-data" *ngIf="!filesLoaded">
    {{ 'InstrumentFilesList.LoadFilesRequired' | translate }}
  </div>
  <div class="no-data" *ngIf="filesLoaded && !filesExist">
    {{ noEntriesMessage }}
  </div>
</div>

<div class="row footer">
  <button mat-raised-button color="primary" (click)="refreshData()">
    {{ refreshEntriesMessage }}
  </button>
  <button *ngIf="enableMultiSelect" [disabled]="allCheckedFilesArray.length === 0" mat-raised-button color="primary"
    (click)="downloadFiles()">
    {{ multiDownloadMessage }}
  </button>
  <button mat-raised-button color="primary" (click)="getRecentDeviceFileStatusHistoryExport()"
    *ngIf="filesLoaded && filesExist && showStatusColumn">
    {{ 'InstrumentFilesList.GetFileStatusHistory' | translate }}
  </button>
  <mat-paginator class="paginator" [length]="totalFiles" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="[50, 100, 250, 500]" showFirstLastButtons (page)="clearCheckedFiles()"></mat-paginator>
</div>

<ng-template let-data #deviceFileStatusTemplate>
  <div class="dialog-root">
    <div class="dialog-header">
      <h2>{{ 'InstrumentFilesList.DeviceFileStatus.Title' | translate }}</h2>
      <button class="close-button" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="status-entries" *ngIf="data?.length > 0;else noDeviceFileStatus">
      <div class="status-entry" *ngFor="let fileStatus of data">
        <div class="status-entry-header"><span class="bold">{{ fileStatus.information.message }}</span>
          <span class="status-timestamp"> {{ fileStatus.timestamp | date:'full' }}</span>
        </div>
        <div class="status-targetfilelocation" *ngIf="fileStatus.information.targetFileLocation">
          <span class="bold">{{ 'InstrumentFilesList.DeviceFileStatus.TargetLocation' | translate }}: </span>
          {{ fileStatus.information.targetFileLocation }}
        </div>
        <div class="status-errormessage" *ngIf="fileStatus.information.errorMessage">
          <span class="bold">{{ 'InstrumentFilesList.DeviceFileStatus.Error' | translate }}: </span>
          {{ fileStatus.information.errorMessage }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noDeviceFileStatus>
  {{ 'InstrumentFilesList.DeviceFileStatus.NoStatusesAvailable' | translate }}
</ng-template>

<ng-template #downloadFilesEmailTemplate>
  <form [formGroup]="reportForm" class="dialog-root">
    <h2>{{ 'InstrumentFilesList.DownloadFilesEmailDialog.Title' | translate }}</h2>
    <div class="message"> {{ 'InstrumentFilesList.DownloadFilesEmailDialog.Message' | translate }} </div>
    <mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>{{ 'Reports.EmailAddress' | translate }}</mat-label>
        <input formControlName="email" matInput [placeholder]="'Reports.EmailAddress' | translate" #email>
        <mat-error *ngIf="reportForm.get('email').hasError('required')">
          {{ 'Reports.FormErrors.EmailRequired' | translate }}</mat-error>
        <mat-error *ngIf="reportForm.get('email').hasError('email')">
          {{ 'Reports.FormErrors.EmailValidFormat' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Cancel' | translate }}</button>
      <button mat-raised-button [mat-dialog-close]="{ email: email.value }"
        [disabled]="reportForm.invalid || !email.value" color="primary">{{
        'InstrumentFilesList.DownloadFilesEmailDialog.RequestDownload' | translate }}</button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #getRecentFileStatusHistoryEmailTemplate>
  <form [formGroup]="reportForm" class="dialog-root">
    <h2>{{ 'InstrumentFilesList.RecentFileStatusHistoryEmailDialog.Title' | translate }}</h2>
    <div class="message"> {{ 'InstrumentFilesList.RecentFileStatusHistoryEmailDialog.Message' | translate }} </div>
    <mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>{{ 'Reports.EmailAddress' | translate }}</mat-label>
        <input formControlName="email" matInput [placeholder]="'Reports.EmailAddress' | translate" #email>
        <mat-error *ngIf="reportForm.get('email').hasError('required')">
          {{ 'Reports.FormErrors.EmailRequired' | translate }}</mat-error>
        <mat-error *ngIf="reportForm.get('email').hasError('email')">
          {{ 'Reports.FormErrors.EmailValidFormat' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Cancel' | translate }}</button>
      <button mat-raised-button [mat-dialog-close]="{ email: email.value }"
        [disabled]="reportForm.invalid || !email.value" color="primary">{{
        'InstrumentFilesList.RecentFileStatusHistoryEmailDialog.RequestDownload' | translate }}</button>
    </mat-dialog-actions>
  </form>
</ng-template>

<div class="dialog-root">
  <div class="dialog-header">
    <h2 *ngIf="canAddUpdate">
      {{ (assay?.assayId ? 'Global.Edit' : 'Global.New') | translate }} {{ 'Assays.Assay' |
      translate }} {{ 'Global.File' | translate }} {{ assay?.assayId ? ('Global.IdLabel' | translate:
      {id:assay.assayId})
      : '' }}</h2>
    <h2 *ngIf="!canAddUpdate">
      {{ 'Global.View' | translate }} {{ 'Assays.Assay' | translate }} {{ 'Global.File' | translate }} {{ assay?.assayId
      ?
      ('Global.IdLabel' | translate: {id:assay.assayId}) : '' }}</h2>
    <button mat-icon-button *ngIf="assay?.assayId" class="refresh-dialog-btn" (click)="refreshAssay(assay)">
      <mat-icon>refresh</mat-icon>
    </button>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="editAssayForm">
    <mat-dialog-content class="min-height">
      <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
        <mat-tab [label]="'Global.Details' | translate">
          <div class="field-container">
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Title' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.Title' | translate" type="text" formControlName="title">
              <mat-error *ngIf="editAssayForm.get('title').hasError('required')">
                {{ 'Assays.FormErrors.TitleRequired' | translate }}</mat-error>
              <mat-error *ngIf="editAssayForm.get('title').hasError('maxlength')">
                {{ 'Assays.FormErrors.TitleMaxLength' | translate: {maxLength:
                editAssayForm.get('title').errors.maxlength.requiredLength} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LnItemNumber' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.LnItemNumber' | translate" type="text"
                formControlName="lnItemNumber">
              <mat-error *ngIf="editAssayForm.get('lnItemNumber').hasError('required')">
                {{ 'Assays.FormErrors.LnItemNumberRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="editAssayForm.get('lnItemNumber').hasError('maxlength')">
                {{ 'Assays.FormErrors.LnItemNumberMaxLength' | translate: {maxLength:
                editAssayForm.get('lnItemNumber').errors.maxlength.requiredLength} }}</mat-error>
            </mat-form-field>
            <div class="version-container">
              <mat-label class="field-label">{{ 'Global.Version' | translate }}</mat-label>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Major' | translate" type="number" formControlName="major">
                <mat-error *ngIf="editAssayForm.get('major').hasError('required')">
                  {{ 'Assays.FormErrors.VersionMajorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editAssayForm.get('major').hasError('min')">
                  {{ 'Assays.FormErrors.VersionMajorMin' | translate: {min:
                  editAssayForm.get('major').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Minor' | translate" type="number" formControlName="minor">
                <mat-error *ngIf="editAssayForm.get('minor').hasError('required')">
                  {{ 'Assays.FormErrors.VersionMinorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editAssayForm.get('minor').hasError('min')">
                  {{ 'Assays.FormErrors.VersionMinorMin' | translate: {min:
                  editAssayForm.get('minor').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Revision' | translate" type="number" formControlName="revision">
                <mat-error *ngIf="editAssayForm.get('revision').hasError('required')">
                  {{ 'Assays.FormErrors.VersionRevisionRequired' | translate }}</mat-error>
                <mat-error *ngIf="editAssayForm.get('revision').hasError('min')">
                  {{ 'Assays.FormErrors.VersionRevisionMin' | translate: {min:
                  editAssayForm.get('revision').errors.min.min} }}</mat-error>
              </mat-form-field>

            </div>
            <div class="instrument-type-container">
              <mat-form-field class="full-width" [class.span-all-columns]="!showModelSelection">
                <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
                <mat-select #instrumentTypeId formControlName="instrumentTypeId" required
                  (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                  <mat-option *ngFor="let type of instrumentTypes" [value]="type.instrumentTypeId">{{type.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="editAssayForm.get('instrumentTypeId').invalid">
                  {{ 'Assays.FormErrors.InstrumentTypeRequired' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="showModelSelection">
                <mat-label>{{ 'Global.InstrumentTypeModel' | translate }}</mat-label>
                <mat-select formControlName="modelIds" required multiple>
                  <mat-option [value]="model.modelId" *ngFor="let model of modelsForSelectedInstrumentType">
                    {{ model.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field class="full-width">
              <mat-label>{{ 'AssayInterfaces.AssayInterface' | translate }}</mat-label>
              <mat-select formControlName="assayInterfaceId" #interface appResetSelectOnClear
                (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                <mat-option *ngFor="let interface of getInterfacesForInstrument()" [value]="interface.assayInterfaceId">
                  {{interface.version}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editAssayForm.get('assayInterfaceId').invalid">
                {{ 'Assays.FormErrors.InterfaceRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'AssayDefinitions.AssayDefinition' | translate }}</mat-label>
              <mat-select formControlName="assayDefinitionId">
                <mat-option *ngFor="let definition of assayDefinitions" [value]="definition.assayDefinitionId">
                  {{definition.definitionName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editAssayForm.get('assayDefinitionId').invalid">
                {{ 'Assays.FormErrors.DefinitionRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.FileType' | translate }}</mat-label>
              <mat-select formControlName="instrumentFileTypeId" #fileType
                (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                <mat-option *ngFor="let type of instrumentFileTypes" [value]="type.instrumentFileTypeId">{{type.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editAssayForm.get('instrumentFileTypeId').invalid">
                {{ 'Assays.FormErrors.FileTypeRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="published-container">
              <mat-form-field class="full-width">
                <mat-label>{{ 'Global.PublishedFlag' | translate }}</mat-label>
                <input matInput readonly [placeholder]="'Global.PublishedFlag' | translate" type="text"
                  [value]="getPublishedFlagDisplayValue(assay.publishedFlag)">
              </mat-form-field>
              <button mat-raised-button
                *ngIf="canAddUpdate && assay?.assayId && assay?.fileUri && assay.publishedFlag !== PublishedFlag.PreviousPublished"
                style="margin-left: 10px; box-sizing: content-box;"
                (click)="onPublishAssay(file?.files?.length > 0 ? file.files[0] : null)">
                {{ (isAssayPendingOrPublished(assay) ? 'Global.Unpublish' : 'Global.Publish') | translate }}</button>
            </div>
            <mat-label class="field-label" for="assay_file">{{ 'Global.File' | translate }}</mat-label>
            <div class="file-container">
              <label class="file-label noselect"
                *ngIf="canAddUpdate && (assay?.publishedFlag === PublishedFlag.Unpublished || assay?.publishedFlag === PublishedFlag.Published)"
                for="assay_file">
                {{ (assay?.fileUri ? 'Global.Replace' : 'Global.Choose') | translate }} {{ 'Global.File' | translate }}
              </label>
              <span class="noselect" *ngIf="file.files.length === 1" [matTooltip]="file.files[0]?.name">
                {{ 'Global.FileUploaded' | translate: {fileName: file.files[0].name} }}</span>
              <div class="file-info">
                <div class="download-file">
                  <a href="{{assay?.fileUri}}" class="flex noselect">
                    <span *ngIf="file.files.length === 0 && assay?.fileUri" [matTooltip]="assay?.fileUri">
                      {{ 'Global.Download' | translate }} {{ 'Global.File' | translate }}</span>
                  </a>
                  <div class="file-status" [class.success]="fileAvailable" [class.fail]="!fileAvailable"
                    *ngIf="isAssayPendingOrPublished(assay)">
                    <mat-icon class="noselect"
                      [matTooltip]="(fileAvailable ? 'Global.FileCDNAvailable' : 'Global.FileCDNUnavailable') | translate">
                      {{fileAvailable ? 'verified' : 'warning'}}</mat-icon>
                  </div>
                </div>
                <div *ngIf="assay?.fileUri && (!file || file?.files.length === 0)" class="md5-value">
                  <div class="label">{{ 'Global.MD5Label' | translate }}</div>
                  <div>{{assay?.mD5 || '-'}}</div>
                </div>
              </div>
              <button mat-icon-button *ngIf="assay?.publishedFlag === PublishedFlag.PendingPublish"
                (click)="checkFileAvailability(assay)">
                <mat-icon>refresh</mat-icon>
              </button>
              <input placeholder="File" type="file" #file id="assay_file" (change)="fileChanged($event.target.files)">
              <mat-form-field *ngIf="signatureVerificationEnabled && file?.files?.length > 0" class="signature-validation-key">
                <mat-label>{{ 'Global.SignatureVerificationKey' | translate }}</mat-label>
                <mat-select formControlName="signatureVerificationKeyName" required>
                  <mat-option *ngFor="let keyName of signatureVerificationKeyNames" [value]="keyName">{{keyName}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{ 'Global.SignatureVerificationKeyHint' | translate }}</mat-hint>
                <mat-error *ngIf="editAssayForm.get('signatureVerificationKeyName').invalid">
                  {{ 'Assays.FormErrors.SignatureVerificationKeyRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-label *ngIf="canAddUpdate && assay?.publishedFlag === PublishedFlag.Unpublished">
              {{ 'Assays.FormErrors.FileUploadError' | translate }}
            </mat-label>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
              <textarea matInput #notes [placeholder]="'Global.Notes' | translate" type="text"
                formControlName="notes"></textarea>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedDate' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedDate' | translate" type="text" #name
                [value]="assay?.createdDate" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedBy' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedBy' | translate" type="text" #name
                [value]="assay?.createdBy" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedDate' | translate }}</mat-label>
              <input matInput placeholder="Last Modified Date" type="text" #name [value]="assay?.lastModifiedDate"
                [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedBy' | translate }}</mat-label>
              <input matInput placeholder="Last Modified By" type="text" #name [value]="assay?.lastModifiedBy"
                [disabled]="true">
            </mat-form-field>
          </div>
        </mat-tab>
        <mat-tab [label]="'Global.Dependencies' | translate">
          <div class="dependencies">
            <div class="selection-list-container">
              <div>
                <mat-label class="field-label compatible-firmwares">
                  {{ 'Global.CompatibleFirmwares' | translate }}</mat-label>
                <mat-checkbox class="margin-top-10" labelPosition="after" [disabled]="!canAddUpdate"
                  (change)="noFirmwaresCheckChanged($event.checked)"
                  [checked]="!firmwareIds || firmwareIds?.length === 0">{{ 'Global.None' | translate }}</mat-checkbox>
                <mat-checkbox labelPosition="after" *ngIf="availableFirmwares.length !== 0" [disabled]="!canAddUpdate"
                  (change)="allFirmwaresCheckChanged($event.checked)"
                  [checked]="firmwareIds && firmwareIds?.length === availableFirmwares.length">
                  {{ 'Global.All' | translate }}
                </mat-checkbox>
                <mat-selection-list class="compatible-list condensed" formControlName="firmwareIds">
                  <mat-list-option checkboxPosition="before" *ngFor="let firmware of availableFirmwares"
                    [value]="firmware.firmwareId">
                    {{firmware.version?.major}}.{{firmware.version?.minor}}.{{firmware.version?.revision}}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </form>
  <mat-dialog-actions *ngIf="canAddUpdate" align="end">
    <button mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
      {{ 'Global.Cancel' | translate }}</button>

    <button mat-raised-button (click)="saveAssay(file?.files?.length > 0 ? file.files[0] : null, true)"
      [disabled]="editAssayForm.invalid" *ngIf="assay.assayId || selectedTabIndex > 0" color="primary">
      {{ (assay.assayId ? 'Global.Save&Close' : 'Global.Create&Close') | translate }}</button>

    <button mat-raised-button (click)="saveAssay(file?.files?.length > 0 ? file.files[0] : null, false)"
      [disabled]="editAssayForm.invalid" *ngIf="assay.assayId || selectedTabIndex > 0" color="primary">
      {{ (assay.assayId ? 'Global.Save' : 'Global.Create') | translate }}</button>

    <button mat-raised-button color="primary" cdkFocusInitial *ngIf="!assay.assayId && selectedTabIndex === 0"
      (click)="selectedTabIndex = 1">{{ 'Global.Next' | translate }}</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
    <button mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
      {{ 'Global.Close' | translate }}</button>
  </mat-dialog-actions>
</div>

import { Directive, OnDestroy, AfterViewInit, QueryList } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appResetSelectOnClear]'
})
export class ResetSelectOnClearDirective implements AfterViewInit, OnDestroy {

  private subscription = new Subscription();

  constructor(private select: MatSelect) {}

  ngAfterViewInit(): void {
    this.subscription.add(this.select?.options?.changes.subscribe((changes: QueryList<unknown>) => {
      if (changes.length === 0) {
        this.select.value = undefined;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

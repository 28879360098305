import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate, translateWithInterpolateParams } from '../translateServiceHelper';

@Injectable()
export class MatFormattedLengthPaginator extends MatPaginatorIntl {

  private translationLoaded = false;
  constructor(private decimalPipe: DecimalPipe, private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.translationLoaded = true;
      this.itemsPerPageLabel = translate(this.translateService, TranslateConstants.PaginatorItemsPerPageKey);
      this.nextPageLabel = translate(this.translateService, TranslateConstants.PaginatorNextPageKey);
      this.previousPageLabel = translate(this.translateService, TranslateConstants.PaginatorPreviousPageKey);
      this.firstPageLabel = translate(this.translateService, TranslateConstants.PaginatorFirstPageKey);
      this.lastPageLabel = translate(this.translateService, TranslateConstants.PaginatorLastPageKey);
      this.changes.next(); // Making sure anything using/binding to these labels refreshes as this uses the OnPush change detection strategy
    });
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      if (!this.translationLoaded) {
        // in case the translation has not been loaded yet, falling back to english. When translation is loaded, it will update.
        return `0 of ${length}`;
      }

      return translateWithInterpolateParams(this.translateService, TranslateConstants.PaginatorRangeNoResultsKey, { totalCount: length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    const transformedStart = this.decimalPipe.transform(startIndex + 1);
    const transformedEnd = this.decimalPipe.transform(endIndex);
    const transformedTotal = this.decimalPipe.transform(length);
    if (this.translationLoaded) {
      return translateWithInterpolateParams(this.translateService, TranslateConstants.PaginatorRangeResultsKey, { startNumber: transformedStart, endNumber: transformedEnd, totalCount: transformedTotal });
    } else {
      // in case the translation has not been loaded yet, falling back to english. When translation is loaded, it will update.
      return `${this.decimalPipe.transform(startIndex + 1)} - ${this.decimalPipe.transform(endIndex)} of ${this.decimalPipe.transform(length)}`;
    }
  };

}

import { Pipe, PipeTransform } from '@angular/core';
import { Version, formatVersion } from '../models';

@Pipe({
  name: 'version'
})
export class VersionPipe implements PipeTransform {

  transform(value: Version): string {
    return formatVersion(value);
  }

}

<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content>
    <div class="set-size charts-container">
      <div class="pie-wrapper">
        <span class="label" *ngIf="!uploadComplete">{{progress}}<span class="smaller">
            {{ 'UploadDialog.PercentSign' | translate }}</span></span>
        <span class="label" *ngIf="uploadComplete"><span class="small-label">
            {{ 'UploadDialog.Processing' | translate }}</span></span>
        <div class="pie" #pie id="pie">
          <div class="left-side half-circle" id="leftSide" #leftSide></div>
          <div class="right-side half-circle" id="rightSide" #rightSide></div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button class="btn" style="margin-right:1.0rem;" (click)="cancel()">
      {{ 'Global.Cancel' | translate }}</button>
  </div>
</div>

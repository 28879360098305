import { Firmware } from './firmware.models';
import { Version } from './version.model';
import { Assay } from './assay.models';
import { Language } from './language.models';

export interface VersionTemplate {
  version: number;
}

export interface EmailTemplate {
  email: string;
}

export interface AssayDefinitionTemplate {
  code: string;
  name: string;
}

export interface LanguageDefinitionTemplate {
  code: string;
  name: string;
}

export interface FirmwareTemplate extends Firmware, Version {
  file: Blob;
}

export interface AssayTemplate extends Assay, Version {
  file: Blob;
}

export interface LanguageTemplate extends Language, Version {
  file: Blob;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { C2DMessageRequestResponse, DeviceInformation, FolderSearchC2DRequest, folderSearchC2DResultLimitMinMax } from 'src/app/models';
import { C2DMessagesService, NotificationService } from 'src/app/services';

@Component({
  selector: 'app-folder-search',
  templateUrl: './folder-search.component.html',
  styleUrls: ['./folder-search.component.scss']
})
export class FolderSearchComponent implements OnInit, OnDestroy {

  @Input() public deviceInformation: DeviceInformation;
  @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  private subscription: Subscription = new Subscription();

  public resultLimitMinMax = folderSearchC2DResultLimitMinMax;

  /* eslint-disable @typescript-eslint/unbound-method */
  public folderSearchForm = this.fb.group({
    folderPath: ['', [Validators.required]],
    searchText: [''],
    sortColumn: ['Name', [Validators.required]],
    sortDirection: [0, [Validators.required]],
    resultLimit: [5000, [Validators.required, Validators.min(folderSearchC2DResultLimitMinMax.min), Validators.max(folderSearchC2DResultLimitMinMax.max)]]
  });
  /* eslint-enable @typescript-eslint/unbound-method */

  public constructor(private fb: FormBuilder,
    private c2dMessagesService: C2DMessagesService,
    private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    if (!this.deviceInformation) {
      throw new Error('deviceInformation must be set');
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this.closeEmitter.emit();
  }

  public searchFolder(): void {
    const request: FolderSearchC2DRequest = {
      serialNumber: this.deviceInformation.serialNumber,
      folderPath: this.folderSearchForm.value.folderPath,
      searchRegex: this.folderSearchForm.value.searchText ? `(?i)${this.folderSearchForm.value.searchText}` : undefined,
      sortColumn: this.folderSearchForm.value.sortColumn,
      sortDescending: this.folderSearchForm.value.sortDirection === 1,
      resultLimit: this.folderSearchForm.value.resultLimit
    };

    this.subscription.add(this.c2dMessagesService.requestFolderSearch(request)
      .subscribe((result: C2DMessageRequestResponse) => {
        C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestFolderSearchKey);
      }));
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { DeviceDataRequest, SearchRequest, DeviceInformation, Version, DeviceInformationConnectionsState, InstrumentTypeId, SoftwareEntry, SoftwareEntryType } from 'src/app/models';
import { translate } from 'src/app/shared/translateServiceHelper';

@Injectable({
  providedIn: 'root'
})
export class DeviceHelperService {
  private defaultPageSize = 100;
  private defaultPageNumber = 1;

  private statusClassHealthy = 'status-healthy';
  private statusClassUnhealthy = 'status-unhealthy';
  private statusClassUnknown = 'status-unknown';
  private statusClassUnregistered = 'status-unregistered';

  constructor(private translateService: TranslateService) { }

  getDeviceSearchRequest(deviceDataRequest: DeviceDataRequest, instrumentTypeId?: number, modelIds?: number[], disableUnregisteredExactMatch = false): SearchRequest {
    let searchRequest: SearchRequest;

    if (deviceDataRequest) {
      searchRequest = {
        serialNumber: deviceDataRequest.serialNumberSearch,
        instrumentTypeIds: instrumentTypeId ? [instrumentTypeId] : undefined,
        modelIds: modelIds,
        countryIds: deviceDataRequest.countries?.map(c => c.countryId),
        pageNumber: deviceDataRequest.pageNumber,
        pageSize: deviceDataRequest.pageSize,
        sortColumn: deviceDataRequest.column,
        sortDirection: deviceDataRequest.sortDirection,
        firmware: {
          version: deviceDataRequest.firmwareVersionSearch,
          lessThan: deviceDataRequest.firmwareLessThan
        },
        assay: {
          code: deviceDataRequest.amfCodeSearch,
          negate: deviceDataRequest.amfNegate
        },
        language: {
          code: deviceDataRequest.languageCodeSearch,
          negate: deviceDataRequest.languageNegate
        },
        disableUnregisteredExactMatch,
        connectionState: deviceDataRequest.connectionStateSearch
      };
    } else {
      searchRequest = {
        serialNumber: undefined,
        instrumentTypeIds: instrumentTypeId ? [instrumentTypeId] : undefined,
        modelIds: modelIds,
        countryIds: undefined,
        pageNumber: this.defaultPageNumber,
        pageSize: this.defaultPageSize,
        sortColumn: undefined,
        sortDirection: undefined,
        firmware: undefined,
        assay: undefined,
        language: undefined,
        connectionState: undefined,
        disableUnregisteredExactMatch
      };
    }

    return searchRequest;
  }

  getInitialDeviceInformation(): DeviceInformation {
    const deviceInformation: DeviceInformation = {
      deviceInformationId: undefined,
      serialNumber: undefined,
      lnItemNumber: undefined,
      firmwareVersion: undefined,
      lanMACAddress: undefined,
      wifiMACAddress: undefined,
      desiredPropertiesJson: undefined,
      reportedPropertiesJson: undefined,
      instrumentSchemaDesiredPropertiesJson: undefined,
      instrumentSchemaReportedPropertiesJson: undefined,
      lastHeartbeatExceedsMaxTime: undefined,
      virenaOptIn: false,
      virenaAutoSend: false,
      instrumentTypeId: 0,
      modelId: 0,
      countryId: 0,
      retryTimeDelayMilliseconds: undefined,
      numberRetries: undefined,
      chunkDownloadTimeoutInSeconds: undefined,
      notes: undefined,
      assayVersions: undefined,
      languageVersions: undefined,
      deviceInformationSettingsJsonByKey: {},
      cloudToDeviceMessagesJsonByType: {}
    };

    return deviceInformation;
  }

  getConcatenatedVersion(version: Version): string {
    if (!version) {
      return null;
    }

    return `${version.major}.${version.minor}.${version.revision}`;
  }

  getDeviceInformationConnectionStatusClassName(deviceInformation: DeviceInformation): string {
    if (!deviceInformation) {
      return this.statusClassUnknown;
    }

    if (!deviceInformation.firmwareVersion) {
      return this.statusClassUnregistered;
    }

    if (deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      if (deviceInformation.currentConnectionState) {
        return deviceInformation.currentConnectionState == DeviceInformationConnectionsState.Connected ?
          this.statusClassHealthy : this.statusClassUnhealthy;
      }
      else {
        return this.statusClassUnknown;
      }
    }
    else {
      if (deviceInformation.lastHeartbeatExceedsMaxTime) {
        return this.statusClassUnknown;
      }
      else {
        if (deviceInformation.lastHeartbeatIsHealthy) {
          return this.statusClassHealthy;
        }
        else {
          return this.statusClassUnhealthy;
        }
      }
    }
  }

  getDeviceInformationConnectionStatusTooltip(deviceInformation: DeviceInformation): string {
    if (!deviceInformation) {
      return '';
    }

    if (!deviceInformation.firmwareVersion) {
      return translate(this.translateService, TranslateConstants.DeviceListStatusTooltipUnregisteredKey);
    }
    if (deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      if (!deviceInformation.currentConnectionState) {
        return translate(this.translateService, TranslateConstants.DeviceListStatusTooltipUnknownKey);
      }
      else {
        return deviceInformation.currentConnectionState === DeviceInformationConnectionsState.Connected ?
          translate(this.translateService, TranslateConstants.DeviceListStatusTooltipConnectedKey) :
          translate(this.translateService, TranslateConstants.DeviceListStatusTooltipDisconnectedyKey);
      }
    }
    else {
      if (!deviceInformation.lastHeartbeatIsHealthy && !deviceInformation.lastHeartbeatExceedsMaxTime) {
        return translate(this.translateService, TranslateConstants.DeviceListStatusTooltipUnknownKey);
      }
      else {
        return deviceInformation.lastHeartbeatStatus;
      }

    }
  }

  getReportedSoftwareEntry(deviceInformation: DeviceInformation, desiredEntry: SoftwareEntryType): SoftwareEntry {
    if (!deviceInformation.reportedPropertiesJson) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const softwareEntries = JSON.parse(deviceInformation.reportedPropertiesJson)['software'] as SoftwareEntry[];
    if (softwareEntries?.length > 0) {
      const swEntry = softwareEntries.find(se => se.name.toLowerCase() === desiredEntry.toLowerCase());
      return swEntry;
    } else {
      return undefined;
    }
  }
}

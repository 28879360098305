export class Country {
  countryId: number;
  code: string;
  name: string;
}

/**
 * Creates a start point for measuring how long something took and writing out to console upon completion.
 * @param logMessage Prefix of the message logged in the console
 * @returns function to execute upon completion that outputs to console
 *
 * Example usage:
 * const completed = measureMethodPerformance('Map: addSources');
 * ... code being evaluates
 * completed();
 */
export const filterCountrySearchOptions = (country: Country | string | null, allCountries: Country[], selectedCountries: Country[]): Country[] => {

  let filteredCountries: Country[];
  if (country !== null) {
    let filterValue: string;
    if (typeof country === 'string') {
      filterValue = country.toLowerCase();
    } else {
      filterValue = country.name.toLowerCase();
    }

    filteredCountries = allCountries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
  } else {
    filteredCountries = allCountries.slice();
  }

  if (selectedCountries?.length > 0) {
    // filter out already selected countries
    return filteredCountries.filter(c => selectedCountries.indexOf(c) < 0);
  } else {
    return filteredCountries;
  }
};

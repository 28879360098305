<div class="dialog-root">
  <div class="dialog-header">
    <h2 *ngIf="canAddUpdate">
      {{ (serialNumberOverride?.serialNumberOverrideId ? 'Global.Edit' : 'Global.New') | translate }} {{
      'SerialNumberOverrides.SerialNumberOverride' | translate }} {{ serialNumberOverride?.serialNumberOverrideId ?
      ('Global.IdLabel' |
      translate: {id:serialNumberOverride.serialNumberOverrideId}) : '' }}</h2>
    <h2 *ngIf="!canAddUpdate">
      {{ 'Global.View' | translate }} {{ 'SerialNumberOverrides.SerialNumberOverride' | translate }} {{
      serialNumberOverride?.serialNumberOverrideId ?
      ('Global.IdLabel' | translate: {id:serialNumberOverride.serialNumberOverrideId}) : '' }}</h2>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="editOverrideForm">
    <mat-dialog-content>
      <div class="field-container">
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.SerialNumber' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.SerialNumber' | translate" type="text" #code
            formControlName="serialNumber">
          <mat-error *ngIf="editOverrideForm.get('serialNumber').hasError('required')">
            {{ 'SerialNumberOverrides.FormErrors.SerialNumberRequired' | translate }}</mat-error>
          <mat-error *ngIf="editOverrideForm.get('serialNumber').hasError('maxlength')">
            {{ 'SerialNumberOverrides.FormErrors.SerialNumberMaxLength' | translate: {maxLength:
            editOverrideForm.get('serialNumber').errors.maxlength.requiredLength} }}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
          <mat-select formControlName="instrumentTypeId" required>
            <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentType' | translate }}</mat-option>
            <mat-option [value]="instrumentType.instrumentTypeId" *ngFor="let instrumentType of instrumentTypes">
              {{ instrumentType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.Country' | translate }}</mat-label>
          <mat-select required formControlName="countryId">
            <mat-option [value]="country.countryId" *ngFor="let country of countries">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.OverrideCountry' | translate }}</mat-label>
          <mat-select formControlName="overrideCountryId">
            <mat-option value="0">{{ 'SerialNumberOverrides.NoneSelected' | translate }}</mat-option>
            <mat-option [value]="country.countryId" *ngFor="let country of countries">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.ProductCode' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.ProductCode' | translate" type="text" #code
            formControlName="productCode">
          <mat-error *ngIf="editOverrideForm.get('productCode').hasError('required')">
            {{ 'SerialNumberOverrides.FormErrors.ProductCodeRequired' | translate }}</mat-error>
          <mat-error *ngIf="editOverrideForm.get('productCode').hasError('maxlength')">
            {{ 'SerialNumberOverrides.FormErrors.ProductCodeMaxLength' | translate: {maxLength:
            editOverrideForm.get('productCode').errors.maxlength.requiredLength} }}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.CreatedDate' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.CreatedDate' | translate" type="text" #name
            formControlName="createdDate">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.CreatedBy' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.CreatedBy' | translate" type="text" #name formControlName="createdBy">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.LastModifiedDate' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.LastModifiedDate' | translate" type="text" #name
            formControlName="lastModifiedDate">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.LastModifiedBy' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.LastModifiedBy' | translate" type="text" #name
            formControlName="lastModifiedBy">
        </mat-form-field>
      </div>
    </mat-dialog-content>
  </form>


  <mat-dialog-actions *ngIf="canAddUpdate" align="end">
    <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
      {{ 'Global.Cancel' | translate }}</button>
    <button mat-raised-button [disabled]="editOverrideForm.invalid" color="primary" (click)="saveOverride()">
      {{ (data ? 'Global.Save' : 'Global.Create') | translate }}</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
    <button mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
      {{ 'Global.Close' | translate }}</button>
  </mat-dialog-actions>
</div>

<div class="dialog-root">
  <div class="dialog-header">
    <h2 *ngIf="canAddUpdate">
      {{ (language?.languageId ? 'Global.Edit' : 'Global.New') | translate }} {{ 'Languages.Language' |
      translate }} {{ 'Global.File' | translate }} {{ language?.languageId ? ('Global.IdLabel' | translate:
      {id:language.languageId})
      : '' }}</h2>
    <h2 *ngIf="!canAddUpdate">
      {{ 'Global.View' | translate }} {{ 'Languages.Language' | translate }} {{ 'Global.File' | translate }} {{
      language?.languageId ?
      ('Global.IdLabel' | translate: {id:language.languageId}) : '' }}</h2>
    <button mat-icon-button *ngIf="language?.languageId" class="refresh-dialog-btn" (click)="refreshLanguage(language)">
      <mat-icon>refresh</mat-icon>
    </button>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="editLanguageForm">
    <mat-dialog-content class="min-height">
      <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
        <mat-tab [label]="'Global.Details' | translate">
          <div class="field-container">
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Title' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.Title' | translate" type="text" formControlName="title">
              <mat-error *ngIf="editLanguageForm.get('title').hasError('required')">
                {{ 'Languages.FormErrors.TitleRequired' | translate }}</mat-error>
              <mat-error *ngIf="editLanguageForm.get('title').hasError('maxlength')">
                {{ 'Languages.FormErrors.TitleMaxLength' | translate: {maxLength:
                editLanguageForm.get('title').errors.maxlength.requiredLength} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>LNItemNumber</mat-label>
              <input matInput placeholder="LNItemNumber" type="text" formControlName="lnItemNumber">
              <mat-error *ngIf="editLanguageForm.get('lnItemNumber').hasError('required')">
                {{ 'Languages.FormErrors.LnItemNumberRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="editLanguageForm.get('lnItemNumber').hasError('maxlength')">
                {{ 'Languages.FormErrors.LnItemNumberMaxLength' | translate: {maxLength:
                editLanguageForm.get('lnItemNumber').errors.maxlength.requiredLength} }}</mat-error>
            </mat-form-field>
            <div class="version-container">
              <mat-label class="field-label">{{ 'Global.Version' | translate }}</mat-label>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Major' | translate" type="number" formControlName="major">
                <mat-error *ngIf="editLanguageForm.get('major').hasError('required')">
                  {{ 'Languages.FormErrors.VersionMajorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editLanguageForm.get('major').hasError('min')">
                  {{ 'Languages.FormErrors.VersionMajorMin' | translate: {min:
                  editLanguageForm.get('major').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Minor' | translate" type="number" formControlName="minor">
                <mat-error *ngIf="editLanguageForm.get('minor').invalid">
                  {{ 'Languages.FormErrors.VersionMinorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editLanguageForm.get('minor').hasError('min')">
                  {{ 'Languages.FormErrors.VersionMinorMin' | translate: {min:
                  editLanguageForm.get('minor').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput [placeholder]="'Global.Revision' | translate" type="number" formControlName="revision">
                <mat-error *ngIf="editLanguageForm.get('revision').invalid">
                  {{ 'Languages.FormErrors.VersionRevisionRequired' | translate }}</mat-error>
                <mat-error *ngIf="editLanguageForm.get('revision').hasError('min')">
                  {{ 'Languages.FormErrors.VersionRevisionMin' | translate: {min:
                  editLanguageForm.get('revision').errors.min.min} }}</mat-error>
              </mat-form-field>
            </div>
            <div class="instrument-type-container">
              <mat-form-field class="full-width" [class.span-all-columns]="!showModelSelection">
                <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
                <mat-select #instrumentTypeId formControlName="instrumentTypeId"
                  (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                  <mat-option *ngFor="let type of instrumentTypes" [value]="type.instrumentTypeId">{{type.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="editLanguageForm.get('instrumentTypeId').invalid">
                  {{ 'Languages.FormErrors.InstrumentTypeRequired' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="showModelSelection">
                <mat-label>{{ 'Global.InstrumentTypeModel' | translate }}</mat-label>
                <mat-select formControlName="modelIds" required multiple>
                  <mat-option [value]="model.modelId" *ngFor="let model of modelsForSelectedInstrumentType">
                    {{ model.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field class="full-width">
              <mat-label>{{ 'LanguageInterfaces.LanguageInterface' | translate }}</mat-label>
              <mat-select formControlName="languageInterfaceId" #interface appResetSelectOnClear
                (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                <mat-option *ngFor="let interface of getInterfacesForInstrument(language.instrumentTypeId)"
                  [value]="interface.languageInterfaceId">{{interface.version}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editLanguageForm.get('languageInterfaceId').invalid">
                {{ 'Languages.FormErrors.InterfaceRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'LanguageDefinitions.LanguageDefinition' | translate }}</mat-label>
              <mat-select formControlName="languageDefinitionId">
                <mat-option *ngFor="let definition of languageDefinitions" [value]="definition.languageDefinitionId">
                  {{definition.definitionName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editLanguageForm.get('languageDefinitionId').invalid">
                {{ 'Languages.FormErrors.DefinitionRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.FileType' | translate }}</mat-label>
              <mat-select formControlName="instrumentFileTypeId" #fileType
                (selectionChange)="onDependencyValueChanged(instrumentTypeId.value, fileType.value, interface.value)">
                <mat-option *ngFor="let type of instrumentFileTypes" [value]="type.instrumentFileTypeId">{{type.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editLanguageForm.get('instrumentFileTypeId').invalid">
                {{ 'Languages.FormErrors.FileTypeRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="published-container">
              <mat-form-field class="full-width">
                <mat-label>{{ 'Global.PublishedFlag' | translate }}</mat-label>
                <input matInput readonly [placeholder]="'Global.PublishedFlag' | translate" type="text"
                  [value]="getPublishedFlagDisplayValue(language.publishedFlag)">
              </mat-form-field>
              <button mat-raised-button
                *ngIf="canAddUpdate && language?.languageId && language?.fileUri && language.publishedFlag !== PublishedFlag.PreviousPublished"
                style="margin-left: 10px; box-sizing: content-box;"
                (click)="onPublishLanguage(file?.files?.length > 0 ? file.files[0] : null)">
                {{ (isLanguagePendingOrPublished(language) ? 'Global.Unpublish' : 'Global.Publish') | translate }}
              </button>
            </div>
            <mat-label class="field-label" for="language_file">{{ 'Global.File' | translate }}</mat-label>
            <div class="file-container">
              <label class="file-label noselect"
                *ngIf="canAddUpdate && (language?.publishedFlag === PublishedFlag.Unpublished || language?.publishedFlag === PublishedFlag.Published)"
                for="language_file">
                {{ (language?.fileUri ? 'Global.Replace' : 'Global.Choose') | translate }} {{ 'Global.File' | translate
                }}
              </label>
              <span class="noselect" *ngIf="file.files.length === 1" [matTooltip]="file.files[0]?.name">
                {{ 'Global.FileUploaded' | translate: {fileName: file.files[0].name} }}</span>
              <div class="file-info">
                <div class="download-file">
                  <a href="{{language?.fileUri}}" class="noselect" [matTooltip]="language?.fileUri">
                    <span *ngIf="file.files.length === 0 && language?.fileUri">
                      {{ 'Global.Download' | translate }} {{ 'Global.File' | translate }}</span></a>
                  <div class="file-status" [class.success]="fileAvailable" [class.fail]="!fileAvailable"
                    *ngIf="isLanguagePendingOrPublished(language)">
                    <mat-icon class="noselect"
                      [matTooltip]="(fileAvailable ? 'Global.FileCDNAvailable' : 'Global.FileCDNUnavailable') | translate">
                      {{fileAvailable ? 'verified' : 'warning'}}</mat-icon>
                  </div>
                </div>
                <div *ngIf="language?.fileUri && (!file || file?.files.length === 0)" class="md5-value">
                  <div class="label">{{ 'Global.MD5Label' | translate }}</div>
                  <div>{{language?.mD5 || '-'}}</div>
                </div>
              </div>
              <button mat-icon-button *ngIf="language?.publishedFlag === PublishedFlag.PendingPublish"
                (click)="checkFileAvailability(language)">
                <mat-icon>refresh</mat-icon>
              </button>
              <input placeholder="File" type="file" #file id="language_file"
                (change)="fileChanged($event.target.files)">
              <mat-form-field *ngIf="signatureVerificationEnabled && file?.files?.length > 0"
                class="signature-validation-key">
                <mat-label>{{ 'Global.SignatureVerificationKey' | translate }}</mat-label>
                <mat-select formControlName="signatureVerificationKeyName" required>
                  <mat-option *ngFor="let keyName of signatureVerificationKeyNames" [value]="keyName">{{keyName}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{ 'Global.SignatureVerificationKeyHint' | translate }}</mat-hint>
                <mat-error *ngIf="editLanguageForm.get('signatureVerificationKeyName').invalid">
                  {{ 'Languages.FormErrors.SignatureVerificationKeyRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-label *ngIf="canAddUpdate && language?.publishedFlag === PublishedFlag.Unpublished">
              {{ 'Languages.FormErrors.FileUploadError' | translate }}</mat-label>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
              <textarea formControlName="notes" matInput [placeholder]="'Global.Notes' | translate"
                type="text"></textarea>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedDate' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedDate' | translate" type="text" #name
                [value]="language?.createdDate" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedBy' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedBy' | translate" type="text" #name
                [value]="language?.createdBy" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedDate' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.LastModifiedDate' | translate" type="text" #name
                [value]="language?.lastModifiedDate" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedBy' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.LastModifiedBy' | translate" type="text" #name
                [value]="language?.lastModifiedBy" [disabled]="true">
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab [label]="'Global.Dependencies' | translate">
          <div class="dependencies">
            <div class="selection-list-container">
              <div>
                <mat-label class="field-label compatible-firmwares">
                  {{ 'Global.CompatibleFirmwares' | translate }}</mat-label>
                <mat-checkbox class="margin-top-10" [disabled]="!canAddUpdate" labelPosition="after"
                  (change)="noFirmwaresCheckChanged($event.checked)"
                  [checked]="!firmwareIds || firmwareIds?.length === 0">{{ 'Global.None' | translate }}</mat-checkbox>
                <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after" *ngIf="availableFirmwares.length !== 0"
                  (change)="allFirmwaresCheckChanged($event.checked)"
                  [checked]="firmwareIds && firmwareIds?.length === availableFirmwares.length">
                  {{ 'Global.All' | translate }}
                </mat-checkbox>
                <mat-selection-list class="compatible-list condensed" #compatible formControlName="firmwareIds">
                  <mat-list-option checkboxPosition="before" *ngFor="let firmware of availableFirmwares"
                    [value]="firmware.firmwareId">
                    {{firmware.version?.major}}.{{firmware.version?.minor}}.{{firmware.version?.revision}}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </form>

  <mat-dialog-actions *ngIf="canAddUpdate" align="end">
    <button mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
      {{ 'Global.Cancel' | translate }}</button>
    <button mat-raised-button (click)="saveLanguage(file?.files?.length > 0 ? file.files[0] : null, true)"
      [disabled]="editLanguageForm.invalid" *ngIf="language.languageId || selectedTabIndex > 0" color="primary">
      {{ (language.languageId ? 'Global.Save&Close' : 'Global.Create&Close') | translate }}</button>
    <button mat-raised-button (click)="saveLanguage(file?.files?.length > 0 ? file.files[0] : null, false)"
      [disabled]="editLanguageForm.invalid" *ngIf="language.languageId || selectedTabIndex > 0" color="primary">
      {{ (language.languageId ? 'Global.Save' : 'Global.Create') | translate }}</button>
    <button mat-raised-button color="primary" cdkFocusInitial *ngIf="!language.languageId && selectedTabIndex === 0"
      (click)="selectedTabIndex = 1">{{ 'Global.Next' | translate }}</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
    <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
      {{ 'Global.Close' | translate }}</button>
  </mat-dialog-actions>
</div>

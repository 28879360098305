import { Features, InstrumentType } from '../models';
import { Version } from '../models/version.model';


export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const ensureUrlEndsWithSlash = (url: string): string => {
  if (!url.endsWith('/')) {
    return `${url}/`;
  }
  return url;
};

/**
 * Creates a start point for measuring how long something took and writing out to console upon completion.
 * @param logMessage Prefix of the message logged in the console
 * @returns function to execute upon completion that outputs to console
 *
 * Example usage:
 * const completed = measureMethodPerformance('Map: addSources');
 * ... code being evaluates
 * completed();
 */
export const measureMethodPerformance = (logMessage: string): () => void => {
  const startTime = performance.now();

  return () => {
    const endTime = performance.now();
    console.debug(`${logMessage}: Took ${endTime - startTime}ms`);
  };
};

export const enumToArray = <T>(enumType: T, excludeValue: string = null): string[] => {
  return Object.getOwnPropertyNames(enumType)
    .map(t => enumType[t] as string)
    .filter(v => excludeValue === null || v !== excludeValue);
};

export const clone = <T>(value: T): T => {
  return JSON.parse(JSON.stringify(value)) as T;
};

export const compare = (a: number | string | boolean, b: number | string | boolean, isAsc: boolean): number => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const compareVersion = (a: Version, b: Version, isAsc: boolean): number => {
  const asc = (isAsc ? 1 : -1);
  if (!a && !b) {
    return 0;
  }
  else if (!a) {
    return -1 * asc;
  }
  else if (!b) {
    return 1 * asc;
  }
  else if (a.major == b.major) {
    if (a.minor == b.minor) {
      if (a.revision == b.revision) {
        return 0;
      }
      else {
        return (a.revision > b.revision ? 1 : -1) * asc;
      }
    }
    else {
      return (a.minor > b.minor ? 1 : -1) * asc;
    }
  }
  else {
    return (a.major > b.major ? 1 : -1) * asc;
  }
};

// Checks if a whole number was entered so we can prevent decimals on certain fields
export const wholeNumberEntered = (e: KeyboardEvent): boolean => {
  return /^\d+$/.test(e.key);
};

export const tryGetSettingsArrayFromJson = (settingsJsonByKey: { [key: string]: string }, desiredKey: string): unknown[] => {
  const settingsJson = settingsJsonByKey[desiredKey];
  if (settingsJson) {
    return JSON.parse(settingsJson) as unknown[];
  }

  return undefined;
};

export const tryGetSettingsFromJson = (settingsJsonByKey: { [key: string]: string }, desiredKey: string): { [key: string]: unknown } => {
  const settingsJson = settingsJsonByKey[desiredKey];
  if (settingsJson) {
    return JSON.parse(settingsJson) as { [key: string]: unknown };
  }

  return undefined;
};

export const isFeatureEnabledForInstrumentTypeName = (features: Features, instrumentTypeName: string, featureNameSuffix: string, treatMissingAsEnabled = true): boolean => {
  return isFeatureEnabled(features, `${instrumentTypeName.replace(' ', '')}_${featureNameSuffix}`, treatMissingAsEnabled);
};

export const isFeatureEnabled = (features: Features, featureKey: string, treatMissingAsEnabled = true): boolean => {
  if (!features || features[featureKey] === undefined) {
    return treatMissingAsEnabled;
  }

  return features[featureKey] as boolean;
};

export const filterInstrumentTypesByFeatureFlag = (features: Features, instrumentTypes: InstrumentType[], featureNameSuffix: string): InstrumentType[] => {
  const filteredInstrumentTypes: InstrumentType[] = [];
  instrumentTypes.forEach(i => {
    if (isFeatureEnabledForInstrumentTypeName(features, i.name, featureNameSuffix)) {
      filteredInstrumentTypes.push(i);
    }
  });
  return filteredInstrumentTypes;
};

export const filterItemsToSupportedInstrumentTypes = <T extends { instrumentTypeId: number }>(supportedInstrumentTypeIds: number[], items: T[]): T[] => {
  const filteredItems: T[] = [];
  items.forEach(i => {
    if (supportedInstrumentTypeIds.includes(i.instrumentTypeId)) {
      filteredItems.push(i);
    }
  });
  return filteredItems;
};

export const exportTextFile = (textContent: string): void => {
  const blob = new Blob([textContent], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', 'allowlistFolderPaths.txt');

  // Performing a download with click
  a.click();
};

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

export const groupBy = <TItem, TKeyType>(xs: TItem[], key: string): Map<TKeyType, TItem[]> => {

  const result: Map<TKeyType, TItem[]> = new Map<TKeyType, TItem[]>();
  xs.forEach((x) => {

    const keyValue: TKeyType = x[key];
    let items = result.get(keyValue);
    if (!items) {
      items = [];
      result.set(keyValue, items);
    }
    items.push(x);

  });

  return result;
};

/* eslint-enable @typescript-eslint/no-unsafe-assignment */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
/* eslint-enable @typescript-eslint/no-unsafe-call */

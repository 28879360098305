<div class="settings dialog-root">
  <div class="title noselect">
    {{ 'Settings.Settings' | translate }}</div>
  <div>
    <form [formGroup]="settingsForm">
      <mat-form-field class="full-width">
        <mat-label>{{ 'Settings.AppLanguage' | translate }}</mat-label>
        <mat-select formControlName="appLanguage" required>
          <mat-option [value]="item.key" *ngFor="let item of supportedLanguages | keyvalue: sortSupportLanguages">
            {{ item.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { BlobUpload, C2DMessageRequestResponse, DeleteFilesRequest, DeviceRequest, DisableIoTConnectionC2DRequest, DownloadFileRequest, FolderSearchC2DRequest, UpdateUserListRequest } from '../models';
import { NotificationService } from './notification.service';
import { TranslateConstants } from '../constants/translate-constants';
import { InactivityService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class C2DMessagesService {

  private apiUrl = this.configurationService.getApiUrl();
  private c2dMessagesUrl = '/CloudToDeviceMessages';
  private requestLogsUploadUrl = '/RequestLogsUpload';
  private requestLutFilesUploadUrl = '/RequestLutFilesUpload';
  private updateUserListUrl = '/UpdateUserList';
  private requestFileDeletionUrl = '/RequestFileDeletion';
  private getDownloadFileBlobUploadUrl = '/GetDownloadFileBlobUploadUrl';
  private requestFileDownloadUrl = '/RequestFileDownload';
  private requestDisableIoTConnectionUrl = '/RequestDisableIoTConnection';
  private requestFolderSearchUrl = '/RequestFolderSearch';

  private blobField = 'blobName';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService) { }

  public requestLogsUpload(request: DeviceRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestLogsUploadUrl}`;

    return this.httpClient.post(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestLutFilesUpload(request: DeviceRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestLutFilesUploadUrl}`;

    return this.httpClient.post(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateUserList(request: UpdateUserListRequest): Observable<C2DMessageRequestResponse> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.updateUserListUrl}`;

    return this.httpClient.post<C2DMessageRequestResponse>(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestFileDeletion(request: DeleteFilesRequest): Observable<C2DMessageRequestResponse> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestFileDeletionUrl}`;

    return this.httpClient.post<C2DMessageRequestResponse>(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getdownloadFileBlobUploadUrl(blobName: string): Observable<BlobUpload> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.getDownloadFileBlobUploadUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(this.blobField, blobName);

    return this.httpClient.get<BlobUpload>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestFileDownload(request: DownloadFileRequest): Observable<C2DMessageRequestResponse> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestFileDownloadUrl}`;

    return this.httpClient.post<C2DMessageRequestResponse>(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestDisableIoTConnection(request: DisableIoTConnectionC2DRequest): Observable<C2DMessageRequestResponse> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestDisableIoTConnectionUrl}`;

    return this.httpClient.post<C2DMessageRequestResponse>(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestFolderSearch(request: FolderSearchC2DRequest): Observable<C2DMessageRequestResponse> {
    const query = `${this.apiUrl}${this.c2dMessagesUrl}${this.requestFolderSearchUrl}`;

    return this.httpClient.post<C2DMessageRequestResponse>(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  /**
     * Handles the response from a C2D Request. Displays appropriate notification based on the result.
     * @param {NotificationService} notificationService The notification service
     * @param {boolean} isSingleInstrument Whether this C2D request was for a single instrument or a group of instruments
     * @param {C2DMessageRequestResponse} c2dRequestResponse C2D Response to handle
     * @param {string} notificationTitle Either the translated title text OR the Translation Key to be used for the notification title
     * @returns {void}
     */
  public static handleC2DRequestResponse(notificationService: NotificationService, isSingleInstrument: boolean, c2dRequestResponse: C2DMessageRequestResponse, notificationTitle: string): void {
    if (c2dRequestResponse.messageSentToAllInstruments) {
      const successMessageKey = isSingleInstrument ? TranslateConstants.C2DMessageSingleInstrumentSuccessKey : TranslateConstants.C2DMessageInstrumentGroupSuccessKey;
      notificationService.success(successMessageKey, null, notificationTitle);
      return;
    }

    const filteredInstrumentTypeNames: string[] = [];
    const allInstrumentTypeNames = Object.keys(c2dRequestResponse.featureFlagStateByInstrumentTypeName);
    allInstrumentTypeNames.forEach(instrumentTypeName => {
      const featureFlagState = c2dRequestResponse.featureFlagStateByInstrumentTypeName[instrumentTypeName];

      let allFeatureFlagsForMessageTypeDisabled = false;
      if (featureFlagState.enabledForMessageTypeByFeatureFlagName) {
        const featureFlagNames = Object.keys(featureFlagState.enabledForMessageTypeByFeatureFlagName);
        if (featureFlagNames.length > 0) {
          allFeatureFlagsForMessageTypeDisabled = featureFlagNames.every(featureFlagName => {
            return !featureFlagState.enabledForMessageTypeByFeatureFlagName[featureFlagName];
          });
        }
      }

      if (!featureFlagState.c2DMessagesEnabled || allFeatureFlagsForMessageTypeDisabled) {
        filteredInstrumentTypeNames.push(instrumentTypeName);
      }
    });

    let messageKey: string;
    let messageInterpolationParamsDefinition = {};
    if (filteredInstrumentTypeNames.length == allInstrumentTypeNames.length) {
      messageKey = TranslateConstants.C2DMessageSuccessNoneRequestedKey;
    } else {
      messageKey = TranslateConstants.C2DMessageSuccessWithSkippedInstrumentTypesKey;
      messageInterpolationParamsDefinition = { instrumentTypes: filteredInstrumentTypeNames.join(', ') };
    }



    notificationService.warning(messageKey, messageInterpolationParamsDefinition, notificationTitle, null, {
      disableTimeOut: true
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessLevel, UserPermission } from '../models';
import { UserPermissionService } from '../services';
import { Router } from '@angular/router';
import { UrlConstants } from '../constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(private userPermissionService: UserPermissionService,
    private router: Router) {
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  canActivate(requiredPermissions: UserPermission[], requiredAccessLevel: AccessLevel): Promise<boolean> {
    return new Promise<boolean>((res) => {
      this.subscriptions.add(this.userPermissionService.isReadyEvent$.subscribe(() => {
        const userAccessLevel = this.userPermissionService.getAccessLevel(requiredPermissions);
        if (userAccessLevel === requiredAccessLevel) {
          res(true);
        } else {
          void this.router.navigate([UrlConstants.instrumentsUrl]);
        }
      }));
    });
  }

}

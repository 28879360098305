import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AssayFileDialogComponent } from 'src/app/views/assay-file-dialog/assay-file-dialog.component';
import { clone, filterItemsToSupportedInstrumentTypes } from 'src/app/shared/utils';
import { UserPermissionService, DataService, NotificationService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import {
  PublishedFlag, Assay, AssayFileDialogData, AssayDefinition, AssayInterface, Firmware, InstrumentType, InstrumentFileType,
  UserPermission, formatVersionForSort, PublishedFlagTranslateKeyMap, AccessLevel, ConfirmationDialogData, ConfirmationDialogResponse, InstrumentTypeModel
} from 'src/app/models';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-assay-files',
  templateUrl: './assay-files.component.html',
  styleUrls: ['./assay-files.component.scss']
})
export class AssayFilesComponent implements OnInit, AfterViewInit, OnDestroy {

  public codeFieldName = 'code';
  public versionFieldName = 'version';
  public idFieldName = 'id';
  public statusFieldName = 'status';
  public devicesCountName = 'devicesCount';
  public modelIdsCountFieldName = 'modelIdsCount';

  public PublishedFlag = PublishedFlag;

  private displayedColumns: string[] = [this.idFieldName, 'title', this.codeFieldName, 'instrumentFileTypeId', 'instrumentTypeId', this.modelIdsCountFieldName,
    'interfaceVersion', this.versionFieldName, this.statusFieldName, this.devicesCountName, 'actions'];
  public columnsToUse = this.displayedColumns;
  public assays: Assay[] = [];
  public assayDefinitions: AssayDefinition[] = [];
  public assayInterfaces: AssayInterface[] = [];
  public availableFirmwares: Firmware[] = [];
  public instrumentTypes: InstrumentType[] = [];
  public instrumentFileTypes: InstrumentFileType[] = [];
  public dataSource: MatTableDataSource<Assay> = new MatTableDataSource<Assay>();

  private subscription: Subscription = new Subscription();
  private unfilteredFirmwares: Firmware[];
  @ViewChild(MatSort) private sort: MatSort;
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private SELECTED_FIRMWARE: Firmware;
  private selectedModels: InstrumentTypeModel[];

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.SELECTED_FIRMWARE = null;
    }
  }

  get selectedFirmware(): Firmware {
    return this.SELECTED_FIRMWARE;
  }

  set selectedFirmware(firmware: Firmware) {
    if (this.SELECTED_FIRMWARE !== firmware) {
      this.SELECTED_FIRMWARE = firmware;
      this.loadAssays();
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(
      forkJoin([
        this.dataService.getAssayDefinitions(),
        this.dataService.getAssayInterfaces(),
        this.dataService.getFirmwares(),
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.userPermissionService.isReadyEvent$.pipe(take(1))
      ])
        .subscribe((results: [AssayDefinition[], AssayInterface[], Firmware[], boolean, boolean]) => {
          this.assayDefinitions = results[0];
          this.assayInterfaces = results[1];
          this.unfilteredFirmwares = results[2];
          this.availableFirmwares = results[2];
          this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
          this.instrumentFileTypes = this.selectionAndCacheService.instrumentFileTypes;

          this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
            this.updateColumnsToUse();
            this.selectedInstrumentType = selectionState.instrumentType;
            this.selectedModels = selectionState.models;

            this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAssayFiles], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);

            this.loadAssays();
            if (this.selectedInstrumentType) {
              this.availableFirmwares = clone(this.unfilteredFirmwares).filter(x => x.instrumentTypeId === this.selectedInstrumentType.instrumentTypeId);
            } else {
              this.availableFirmwares = clone(this.unfilteredFirmwares);
            }
          }));
        }));

    // set version sort value
    this.dataSource.sortingDataAccessor = (assay: Assay, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case this.versionFieldName:
          return formatVersionForSort(assay.version);
        case this.idFieldName:
          return assay.assayId;
        case this.statusFieldName:
          return this.getPublishedFlagDisplayValue(assay.publishedFlag);
        case this.codeFieldName:
          return this.getAssayCode(assay.assayDefinitionId);
        case this.modelIdsCountFieldName:
          return this.getInstrumentTypeModelsCount(assay);
        default:
          return assay[sortHeaderId] as string | number;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public loadAssays(): void {
    this.subscription.add(this.dataService.getAssays(this.selectedFirmware?.firmwareId, this.selectedInstrumentType?.instrumentTypeId, this.selectedModels?.map(m => m.modelId))
      .subscribe((assays: Assay[]) => {
        this.assays = filterItemsToSupportedInstrumentTypes(this.instrumentTypes.map(i => i.instrumentTypeId), assays);

        this.dataSource.data = this.assays;
      }));
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentTypeModelsCount(assay: Assay): number {
    const models = this.selectionAndCacheService.getModelsForInstrumentTypeId(assay.instrumentTypeId)?.filter(m => assay.modelIds.includes(m.modelId));
    return models?.length || 0;
  }

  public getInstrumentFileTypeDisplayValue(instrumentFileTypeId: number): string {
    return this.instrumentFileTypes?.find(i => i.instrumentFileTypeId === instrumentFileTypeId)?.name;
  }

  public getPublishedFlagDisplayValue(flag: PublishedFlag): string {
    return translate(this.translateService, PublishedFlagTranslateKeyMap.get(flag));
  }

  public async onAddAssay(): Promise<void> {
    await this.showEditDialog();
  }

  public async onEditAssay(assay: Assay): Promise<void> {
    await this.showEditDialog(assay);
  }

  private async showEditDialog(assay?: Assay): Promise<void> {
    if (assay) {
      this.subscription.add(this.dataService.getAssayById(assay.assayId).subscribe(async (fullAssay: Assay) => {
        const options: AssayFileDialogData = {
          assay: fullAssay
        };

        const dialogRef = this.dialog.open(AssayFileDialogComponent, {
          data: options,
          width: '75%',
          disableClose: true
        });

        await firstValueFrom(dialogRef.afterClosed());

        this.loadAssays();
      }));
    } else {
      assay = {
        assayId: 0,
        assayDefinitionId: 0,
        assayInterfaceId: 0,
        lnItemNumber: null,
        fileUri: null,
        instrumentFileTypeId: 0,
        instrumentTypeId: 0,
        modelIds: [],
        countryIds: null,
        firmwareIds: [],
        notes: null,
        mD5: null,
        title: null,
        version: { major: 0, minor: 0, revision: 0 },
        publishedFlag: PublishedFlag.Unpublished,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null,
        devicesCount: 0
      };

      const options: AssayFileDialogData = {
        assay: assay
      };

      const dialogRef = this.dialog.open(AssayFileDialogComponent, {
        data: options,
        width: '75%',
        disableClose: true
      });

      await firstValueFrom(dialogRef.afterClosed());

      this.loadAssays();
    }
  }

  public getAssayCode(assayDefinitionId: number): string {
    return this.assayDefinitions.find(x => x.assayDefinitionId === assayDefinitionId)?.code;
  }

  public async onDeleteAssay(assay: Assay): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.AssayKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.AssayKey, assay.title)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteAssay(assay.assayId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.AssayKey });
        this.loadAssays();
      }));
    }
  }

  private updateColumnsToUse() {
    const tempColumnsToUse = [...this.displayedColumns];
    const removeModelColumn = this.selectionAndCacheService.modelsForSelectedInstrumentType?.length <= 1;
    if (removeModelColumn) {
      const indexOfModelIdsCountColumn = tempColumnsToUse.indexOf(this.modelIdsCountFieldName);
      if (indexOfModelIdsCountColumn !== -1) {
        tempColumnsToUse.splice(indexOfModelIdsCountColumn, 1);
      }
    }

    this.columnsToUse = tempColumnsToUse;
  }
}

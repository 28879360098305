import { EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { compare } from 'src/app/shared/utils';
import {
  ListMode, MatSortChangeEvent, SortDirection,
  MatPageChangeEvent, MatCheckboxChangeEvent, AccessLevel, SerialNumberOverridesRequest, SerialNumberOverride, SerialNumberOverridesResult, CheckedOverridesEvent
} from 'src/app/models';
import { SelectionAndCacheService } from 'src/app/services';

@Component({
  selector: 'app-serialnumber-overrides-list',
  templateUrl: './serialnumber-overrides-list.component.html',
  styleUrls: ['./serialnumber-overrides-list.component.scss']
})
export class SerialNumberOverridesListComponent implements OnInit, OnDestroy {
  @Output() dataRequested: EventEmitter<SerialNumberOverridesRequest> = new EventEmitter<SerialNumberOverridesRequest>();
  @Output() checkedOverrides: EventEmitter<CheckedOverridesEvent> = new EventEmitter<CheckedOverridesEvent>();
  @Output() editSerialNumberOverride: EventEmitter<SerialNumberOverride> = new EventEmitter<SerialNumberOverride>();
  @Output() deleteSerialNumberOverride: EventEmitter<SerialNumberOverride> = new EventEmitter<SerialNumberOverride>();
  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  @Input() accessLevel: AccessLevel = AccessLevel.Unauthorized;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public readonly multiselectFieldName = 'multiselect';
  public readonly actionsFieldName = 'actions';
  public readonly idFieldName = 'id';
  public readonly serialNumberFieldName = 'serialnumber';
  public readonly countryFieldName = 'country';
  public readonly overrideCountryFieldName = 'overridecountry';
  public readonly instrumentTypeFieldName = 'instrumentType';
  public readonly productCodeFieldName = 'productCode';
  public multiselectDisplayedColumns: string[] = [this.multiselectFieldName, this.idFieldName, this.serialNumberFieldName, this.instrumentTypeFieldName, this.countryFieldName, this.overrideCountryFieldName, this.productCodeFieldName, this.actionsFieldName];
  public displayedColumns: string[] = [this.idFieldName, this.serialNumberFieldName, this.instrumentTypeFieldName, this.countryFieldName, this.overrideCountryFieldName, this.productCodeFieldName, this.actionsFieldName];
  public filteredDisplayedColumns: string[] = [this.idFieldName, this.serialNumberFieldName, this.instrumentTypeFieldName, this.countryFieldName, this.overrideCountryFieldName, this.productCodeFieldName, this.actionsFieldName];
  public columnsToUse = this.displayedColumns;
  public dataSource: MatTableDataSource<SerialNumberOverride> = new MatTableDataSource<SerialNumberOverride>();
  public ListMode = ListMode;
  public searchControl = new FormControl('');
  public searchInput = '';
  public pageIndex = 1;
  public pageSize = 100;
  public length: number;
  public selectAllPages = false;
  public allCheckedSerialNumberOverridesArrayInternal: SerialNumberOverride[] = [];

  private searchFilters = new Subscription();
  private sortDataSubscription = new Subscription();
  private searchDataSubject: Subject<string> = new Subject();
  private sortDataSubject: Subject<MatSortChangeEvent> = new Subject();
  private serialNumberOverridesResultInternal: SerialNumberOverridesResult;
  private unpaginatedSerialNumberOverridesArrayInternal: SerialNumberOverride[];
  private modeInternal: ListMode = ListMode.Multiselect;
  private serialNumberOverridesRequest: SerialNumberOverridesRequest = {
    serialNumber: '',
    instrumentTypeId: 0,
    pageNumber: 1,
    pageSize: this.pageSize,
    sortColumn: '',
    sortDirection: SortDirection.Ascending
  };

  get allCheckedSerialNumberOverridesArray(): SerialNumberOverride[] {
    return this.allCheckedSerialNumberOverridesArrayInternal;
  }

  @Input() set allCheckedSerialNumberOverridesArray(val: SerialNumberOverride[]) {
    this.allCheckedSerialNumberOverridesArrayInternal = val;
    if (this.allCheckedSerialNumberOverridesArrayInternal === undefined) {
      this.allCheckedSerialNumberOverridesArrayInternal = [];
    }
  }

  get serialNumberOverridesResult(): SerialNumberOverridesResult {
    return this.serialNumberOverridesResultInternal;
  }

  @Input() set serialNumberOverridesResult(val: SerialNumberOverridesResult) {
    this.serialNumberOverridesResultInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val.results;
    this.pageSize = val.pagingInformation?.pageSize;
    this.length = val.totalResults;
    this.pageIndex = val.pagingInformation?.pageNumber - 1;
  }

  get unpaginatedSerialNumberOverridesArray(): SerialNumberOverride[] {
    return this.unpaginatedSerialNumberOverridesArrayInternal;
  }

  @Input() set unpaginatedSerialNumberOverridesArray(val: SerialNumberOverride[]) {
    this.unpaginatedSerialNumberOverridesArrayInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val;
    this.dataSource.paginator = this.paginator;
  }

  @Input() showSelectAllPagesMultiselect = false;

  get mode(): ListMode {
    return this.modeInternal;
  }

  @Input() set mode(val: ListMode) {
    this.modeInternal = val;
    if (!val) {
      return;
    }
    switch (+val) {
      case +ListMode.All:
        this.columnsToUse = this.displayedColumns;
        break;
      case +ListMode.Multiselect:
        this.columnsToUse = this.multiselectDisplayedColumns;
        break;
      case +ListMode.Filtered:
        this.columnsToUse = this.filteredDisplayedColumns;
        break;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(private selectionAndCacheService: SelectionAndCacheService) {
  }

  ngOnInit(): void {
    this.sortDataSubscription = this.sortDataSubject.pipe(debounceTime(500))
      .subscribe((event: MatSortChangeEvent) => {
        if (this.mode === ListMode.Filtered) {
          this.sortUnpaginatedData(event);
        } else {
          this.sortPaginatedData(event);
        }
      });

    this.searchFilters.add(this.searchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchByName(newValue);
    }));

    this.searchFilters.add(this.searchDataSubject.pipe(debounceTime(500))
      .subscribe((event: string) => {
        this.searchByName(event);
      }));
  }

  ngOnDestroy(): void {
    this.sortDataSubscription?.unsubscribe();
    this.searchFilters?.unsubscribe();
  }

  public isCurrentPageChecked(): boolean {
    if (this.selectAllPages) {
      return false;
    }

    const someValuesAreNotIncluded = this.dataSource.data.some(x => !this.allCheckedSerialNumberOverridesArrayInternal.find(c => c.serialNumberOverrideId == x.serialNumberOverrideId));
    return !someValuesAreNotIncluded;
  }

  public getPageData($event: MatPageChangeEvent): void {
    if (this.mode === ListMode.Filtered) {
      return;
    }
    this.serialNumberOverridesRequest = {
      serialNumber: this.serialNumberOverridesRequest.serialNumber,
      instrumentTypeId: this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId,
      pageNumber: $event.pageIndex + 1,
      pageSize: $event.pageSize,
      sortColumn: this.serialNumberOverridesRequest.sortColumn,
      sortDirection: this.serialNumberOverridesRequest.sortDirection,
    };
    this.dataRequested.emit(this.serialNumberOverridesRequest);
  }


  public sort($event: MatSortChangeEvent): void {
    this.sortDataSubject.next($event);
  }

  public isChecked(override: SerialNumberOverride): boolean {
    if (this.selectAllPages) {
      return true;
    }
    if (!override) {
      return false;
    }
    return this.allCheckedSerialNumberOverridesArray.find(g => g.serialNumberOverrideId === override.serialNumberOverrideId) !== undefined;
  }

  public selectionChanged($event: MatCheckboxChangeEvent, instrumentGroup: SerialNumberOverride): void {
    if (this.selectAllPages) {
      this.allCheckedSerialNumberOverridesArray = this.allCheckedSerialNumberOverridesArray.filter(a => this.dataSource.data.some(d => a.serialNumberOverrideId === d.serialNumberOverrideId));
    }

    const groupIdFoundIndex = this.allCheckedSerialNumberOverridesArray.findIndex(x => x.serialNumberOverrideId === instrumentGroup.serialNumberOverrideId);

    if (!($event.currentTarget.checked) && groupIdFoundIndex > -1) {
      this.allCheckedSerialNumberOverridesArray.splice(groupIdFoundIndex, 1);
    } else {
      this.allCheckedSerialNumberOverridesArray.push(instrumentGroup);
    }
    this.selectAllPages = false;
    this.checkedOverrides.emit({ checkedOverrides: this.allCheckedSerialNumberOverridesArray, allPages: this.selectAllPages });
  }

  public checkCurrentPage(): void {
    if (this.selectAllPages) {
      this.allCheckedSerialNumberOverridesArray.length = 0;
      this.selectAllPages = false;
    }

    this.dataSource.data.forEach(group => {
      const groupIdFoundIndex = this.allCheckedSerialNumberOverridesArray.findIndex(x => x.serialNumberOverrideId === group.serialNumberOverrideId);
      if (groupIdFoundIndex < 0) {
        this.allCheckedSerialNumberOverridesArray.push(group);
      }
    });
    this.checkedOverrides.emit({ checkedOverrides: this.allCheckedSerialNumberOverridesArray, allPages: this.selectAllPages });
  }

  public checkAll(): void {
    this.dataSource.data.forEach(group => {
      const groupIdFoundIndex = this.allCheckedSerialNumberOverridesArray.findIndex(x => x.serialNumberOverrideId === group.serialNumberOverrideId);
      if (groupIdFoundIndex < 0) {
        this.allCheckedSerialNumberOverridesArray.push(group);
      }
    });
    this.selectAllPages = true;
    this.checkedOverrides.emit({ checkedOverrides: this.allCheckedSerialNumberOverridesArray, allPages: this.selectAllPages });
  }

  public uncheckAll(): void {
    this.allCheckedSerialNumberOverridesArray.length = 0;
    this.selectAllPages = false;
    this.checkedOverrides.emit({ checkedOverrides: this.allCheckedSerialNumberOverridesArray, allPages: this.selectAllPages });
  }

  public onEditSerialNumberOverride(instrumentGroup: SerialNumberOverride): void {
    this.editSerialNumberOverride.emit(instrumentGroup);
  }

  public onDeleteSerialNumberOverride(instrumentGroup: SerialNumberOverride): void {
    this.deleteSerialNumberOverride.emit(instrumentGroup);
  }

  public clearSearch(): void {
    this.searchInput = '';
    this.serialNumberOverridesRequest = {
      serialNumber: this.searchInput,
      instrumentTypeId: this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId,
      pageNumber: this.serialNumberOverridesRequest.pageNumber,
      pageSize: this.serialNumberOverridesRequest.pageSize,
      sortColumn: this.serialNumberOverridesRequest.sortColumn,
      sortDirection: this.serialNumberOverridesRequest.sortDirection,
    };

    this.dataRequested.emit(this.serialNumberOverridesRequest);
  }


  public onRefreshData(): void {
    this.refreshData.emit();
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  private executeSearchByName($event: string): void {
    this.searchDataSubject.next($event);
  }

  private searchByName($event: string): void {
    this.searchInput = $event;
    this.serialNumberOverridesRequest = {
      serialNumber: this.searchInput,
      instrumentTypeId: this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId,
      pageNumber: this.serialNumberOverridesRequest.pageNumber,
      pageSize: this.serialNumberOverridesRequest.pageSize,
      sortColumn: this.serialNumberOverridesRequest.sortColumn,
      sortDirection: this.serialNumberOverridesRequest.sortDirection,
    };

    this.dataRequested.emit(this.serialNumberOverridesRequest);
  }

  private sortPaginatedData(event: MatSortChangeEvent): void {
    let sortDirection = SortDirection.None;
    if (event.direction === 'asc') {
      sortDirection = SortDirection.Ascending;
    } else if (event.direction === 'desc') {
      sortDirection = SortDirection.Descending;
    }
    this.serialNumberOverridesRequest = {
      instrumentTypeId: this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId,
      sortColumn: event.active,
      sortDirection,
      pageNumber: this.serialNumberOverridesRequest.pageNumber,
      pageSize: this.serialNumberOverridesRequest.pageSize,
      serialNumber: this.serialNumberOverridesRequest.serialNumber
    };
    this.dataRequested.emit(this.serialNumberOverridesRequest);
  }

  private sortUnpaginatedData(sort: MatSortChangeEvent): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case this.idFieldName: return compare(a.serialNumberOverrideId, b.serialNumberOverrideId, isAsc);
        case this.serialNumberFieldName: return compare(a.serialNumber, b.serialNumber, isAsc);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { map, tap } from 'rxjs/operators';
import { preventBusyIndicatorHeaders } from '../interceptors/loading.interceptor';
import { UrlConstants } from '../constants/url-constants';
import {
  BlobUpload, ValidationResult, Assay, Firmware, AssayDefinition, AssayInterface, DeviceInformation, SearchRequest,
  DeviceInformationResult, SortDirection, DeviceInformationRequest, DeviceRequest, DeviceUpgradeRequest, FileState, InstrumentType,
  InstrumentFileType, CancelUpgradeRequest, UpsertInstrumentGroupRequest, InstrumentGroup, InstrumentGroupsRequest, InstrumentGroupsResult,
  Language, LanguageInterface, LanguageDefinition, InstrumentActionHistoryRow, C2DPendingMessage, prepareSearchGroupRequest, prepareDeviceSearchRequest, InstrumentTypeModel, FileSearchRequest, DeviceManualApprovalEntry, Country, SerialNumberOverridesRequest, SerialNumberOverridesResult, prepareSearchOverrideRequest, SerialNumberOverride, SetSerialNumberOverridesRequest,
  DigiDeviceCacheEntry, VerizonDeviceCacheEntry, MyVirenaDeviceCacheEntry,
  ShippingInformation
} from 'src/app/models';
import { InactivityService } from './inactivity.service';
import { expectedErrorsString } from '../constants/interceptors.constants';
import * as httpFields from '../constants/httpFields-constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private apiUrl: string = this.configurationService.getApiUrl();
  private instrumentTypeUrl = '/InstrumentType';
  private languageUrl = '/Language';
  private assayUrl = '/Assay';
  private firmwareUrl = '/Firmware';
  private deviceInformationUrl = '/DeviceInformation';
  private instrumentGroupUrl = '/InstrumentGroup';
  private countryUrl = '/Country';
  private serialNumberOverrideUrl = '/SerialNumberOverride';
  private digiDeviceUrl = '/DigiDevice';
  private verizonDeviceUrl = '/VerizonDevice';
  private myVirenaDeviceUrl = '/MyVirena';

  private getInstrumentTypesUrl = '/GetInstrumentTypes';
  private getInstrumentFileTypesUrl = '/GetInstrumentFileTypes';
  private getInstrumentTypesModelsUrl = '/GetInstrumentTypeModels';

  private getLanguagesUrl = '/GetLanguages';
  private getLanguageByIdUrl = '/GetLanguageById';
  private addLanguageUrl = '/AddLanguage';
  private updateLanguageUrl = '/UpdateLanguage';
  private deleteLanguageUrl = '/DeleteLanguage';
  private publishLanguageUrl = '/PublishLanguage';
  private finalizePublishLanguageUrl = '/FinalizePublishLanguage';
  private unpublishLanguageUrl = '/UnpublishLanguage';
  private validateLanguageUrl = '/ValidateLanguage';
  private getLanguageInterfacesUrl = '/GetLanguageInterfaces';
  private addLanguageInterfaceUrl = '/AddLanguageInterface';
  private deleteLanguageInterfaceUrl = '/DeleteLanguageInterface';
  private getLanguageDefinitionsUrl = '/GetLanguageDefinitions';
  private addLanguageDefinitionUrl = '/AddLanguageDefinition';
  private updateLanguageDefinitionUrl = '/UpdateLanguageDefinition';
  private deleteLanguageDefinitionUrl = '/DeleteLanguageDefinition';

  private getAssaysUrl = '/GetAssays';
  private getAssayByIdUrl = '/GetAssayById';
  private addAssayUrl = '/AddAssay';
  private updateAssayUrl = '/UpdateAssay';
  private deleteAssayUrl = '/DeleteAssay';
  private publishAssayUrl = '/PublishAssay';
  private finalizePublishAssayUrl = '/FinalizePublishAssay';
  private unpublishAssayUrl = '/UnpublishAssay';
  private validateAssayUrl = '/ValidateAssay';
  private getAssayDefinitionsUrl = '/GetAssayDefinitions';
  private addAssayDefinitionUrl = '/AddAssayDefinition';
  private updateAssayDefinitionUrl = '/UpdateAssayDefinition';
  private deleteAssayDefinitionUrl = '/DeleteAssayDefinition';
  private getAssayInterfacesUrl = '/GetAssayInterfaces';
  private addAssayInterfaceUrl = '/AddAssayInterface';
  private deleteAssayInterfaceUrl = '/DeleteAssayInterface';

  private getFirmwaresUrl = '/GetFirmwares';
  private getFirmwareByIdUrl = '/GetFirmwareById';
  private addFirmwareUrl = '/AddFirmware';
  private updateFirmwareUrl = '/UpdateFirmware';
  private publishFirmwareUrl = '/PublishFirmware';
  private finalizePublishFirmwareUrl = '/FInalizePublishFirmware';
  private unpublishFirmwareUrl = '/UnpublishFirmware';
  private deleteFirmwareUrl = '/DeleteFirmware';
  private getAvailableFirmwaresUrl = '/GetAvailableFirmwares';
  private getAvailableAssaysUrl = '/GetAvailableAssays';
  private getAvailableLanguagesUrl = '/GetAvailableLanguages';
  private validateFirmwareUrl = '/ValidateFirmware';

  private getDeviceInformationUrl = '/GetDeviceInformation';
  private getUnregisteredDeviceInformationUrl = '/GetUnregisteredDeviceInformation';
  private addDeviceInformationUrl = '/AddDeviceInformation';
  private updateDeviceInformationUrl = '/UpdateDeviceInformation';
  private deleteDeviceInformationUrl = '/DeleteDeviceInformation';
  private getDeviceInformationByIdUrl = '/GetDeviceInformationById';
  private updateDeviceInformationFromIdbUrl = '/UpdateDeviceInformationFromIdb';
  private getDeviceInformationBySerialNumberUrl = '/GetDeviceInformationBySerialNumber';
  private getDeviceInformationByInstrumentGroupIdUrl = '/GetDeviceInformationByInstrumentGroupId';
  private getShippingInformationFromIdbUrl = '/GetShippingInformationFromIdb';
  private cancelUpgradeRequestUrl = '/CancelUpgradeRequest';
  private cancelUpgradeRequestsUrl = '/CancelUpgradeRequests';
  private getDeviceActionHistoryUrl = '/GetDeviceActionHistory';
  private getDeviceC2DPendingMessagesUrl = '/GetDeviceC2DPendingMessages';

  private getDevicesAwaitingManualApprovalUrl = '/GetDevicesAwaitingManualApproval';
  private upsertDeviceInformationApprovalUrl = '/UpsertDeviceInformationApproval';
  private deletePendingManualApprovalUrl = '/DeletePendingManualApproval';

  private upgradeDevicesUrl = '/UpgradeDevices';
  private getDeviceCountFromCertificateRefreshRequestUrl = '/GetDeviceCountFromCertificateRefreshRequest';
  private forceDeviceTwinRefreshUrl = '/ForceDeviceTwinRefresh';
  private forceCertificateRefreshUrl = '/ForceCertificateRefresh';

  private updateInstrumentGroupDeviceSettingsUrl = '/UpdateInstrumentGroupDeviceSettings';

  private getBlobUploadUrl = '/GetBlobUploadUrl';

  private deleteInstrumentGroupUrl = '/DeleteInstrumentGroup';
  private addInstrumentGroupUrl = '/AddInstrumentGroup';
  private updateInstrumentGroupUrl = '/UpdateInstrumentGroup';
  private getInstrumentGroupByIdUrl = '/GetInstrumentGroupById';
  private getInstrumentGroupsUrl = '/GetInstrumentGroups';
  private getInstrumentGroupsByInstrumentIdUrl = '/GetInstrumentGroupsByInstrumentId';
  private updateInstrumentInstrumentGroupsUrl = '/UpdateInstrumentInstrumentGroups';

  private getCountriesUrl = '/GetCountries';

  private getSerailNumberOverridesUrl = '/GetSerialNumberOverrides';
  private deleteSerialNumberOverrideUrl = '/DeleteSerialNumberOverride';
  private upsertSerialNumberOverrideUrl = '/UpsertSerialNumberOverride';
  private setOverrideCountryForSerialNumberOverridesUrl = '/SetOverrideCountryForSerialNumberOverrides';
  private serialNumberOverridesRequestImportUrl = '/RequestImport';

  private putMethod = 'put';
  private postMethod = 'post';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService) { }

  public getInstrumentTypes(): Observable<InstrumentType[]> {
    const query = `${this.apiUrl}${this.instrumentTypeUrl}${this.getInstrumentTypesUrl}`;

    return this.httpClient.get<InstrumentType[]>(query);
  }

  public getInstrumentTypeModels(): Observable<InstrumentTypeModel[]> {
    const query = `${this.apiUrl}${this.instrumentTypeUrl}${this.getInstrumentTypesModelsUrl}`;

    return this.httpClient.get<InstrumentTypeModel[]>(query);
  }

  public getInstrumentFileTypes(): Observable<InstrumentFileType[]> {
    const query = `${this.apiUrl}${this.instrumentTypeUrl}${this.getInstrumentFileTypesUrl}`;
    return this.httpClient.get<InstrumentFileType[]>(query);
  }

  public getLanguages(firmwareId?: number, instrumentTypeId?: number, modelIds?: number[], onlyPublishedFiles?: boolean): Observable<Language[]> {
    const query = `${this.apiUrl}${this.languageUrl}${this.getLanguagesUrl}`;
    const request: FileSearchRequest = {};
    if (firmwareId || instrumentTypeId || onlyPublishedFiles !== undefined) {
      if (firmwareId) {
        request.firmwareId = firmwareId;
      }
      if (instrumentTypeId) {
        request.instrumentTypeId = instrumentTypeId;
      }
      if (modelIds) {
        request.modelIds = modelIds;
      }
      if (onlyPublishedFiles !== undefined) {
        request.onlyPublishedFiles = onlyPublishedFiles;
      }
    }

    return this.httpClient.post<Language[]>(query, request);
  }

  public getLanguageById(languageId: number): Observable<Language> {
    const query = `${this.apiUrl}${this.languageUrl}${this.getLanguageByIdUrl}`;
    const httpParams: HttpParams = new HttpParams().set(httpFields.languageIdField, languageId.toString());

    return this.httpClient.get<Language>(query, { params: httpParams });
  }

  public addLanguage(language: Language, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.languageUrl}${this.addLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, language, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateLanguage(language: Language, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.languageUrl}${this.updateLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, language, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getLanguageBlobUploadUrl(file: File): Observable<BlobUpload> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getBlobUploadUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, file.name);

    return this.httpClient.get<BlobUpload>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteLanguage(languageId: number): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.deleteLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageIdField, languageId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public publishLanguage(languageId: number): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.publishLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageIdField, languageId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public finalizePublishLanguage(languageId: number): Observable<boolean> {
    const query = `${this.apiUrl}${this.languageUrl}${this.finalizePublishLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageIdField, languageId.toString());

    return this.httpClient.get<boolean>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public unpublishLanguage(languageId: number): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.unpublishLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageIdField, languageId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public validateLanguage(language: Language, fileName: string, signatureKeyName: string): Observable<ValidationResult> {
    const query = `${this.apiUrl}${this.languageUrl}${this.validateLanguageUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');

    return this.httpClient.post<string>(query, language, { params: httpParams })
      .pipe(map(result => JSON.parse(result) as ValidationResult));
  }

  public getLanguageInterfaces(): Observable<LanguageInterface[]> {
    const query = `${this.apiUrl}${this.languageUrl}${this.getLanguageInterfacesUrl}`;

    return this.httpClient.get<LanguageInterface[]>(query);
  }

  public addLanguageInterface(languageInterface: LanguageInterface): Observable<number> {
    const query = `${this.apiUrl}${this.languageUrl}${this.addLanguageInterfaceUrl}`;

    return this.httpClient.post<number>(query, languageInterface)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteLanguageInterface(languageInterfaceId: number): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.deleteLanguageInterfaceUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageInterfaceIdField, languageInterfaceId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getLanguageDefinitions(): Observable<LanguageDefinition[]> {
    const query = `${this.apiUrl}${this.languageUrl}${this.getLanguageDefinitionsUrl}`;

    return this.httpClient.get<LanguageDefinition[]>(query);
  }

  public addLanguageDefinition(languageDefinition: LanguageDefinition): Observable<number> {
    const query = `${this.apiUrl}${this.languageUrl}${this.addLanguageDefinitionUrl}`;

    return this.httpClient.post<number>(query, languageDefinition)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateLanguageDefinition(languageDefinition: LanguageDefinition): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.updateLanguageDefinitionUrl}`;

    return this.httpClient.post<void>(query, languageDefinition)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteLanguageDefinition(languageDefinitionId: number): Observable<void> {
    const query = `${this.apiUrl}${this.languageUrl}${this.deleteLanguageDefinitionUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.languageDefinitionIdField, languageDefinitionId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getAssays(firmwareId?: number, instrumentTypeId?: number, modelIds?: number[], onlyPublishedFiles?: boolean): Observable<Assay[]> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getAssaysUrl}`;
    const request: FileSearchRequest = {};
    if (firmwareId || instrumentTypeId || onlyPublishedFiles !== undefined) {
      if (firmwareId) {
        request.firmwareId = firmwareId;
      }
      if (instrumentTypeId) {
        request.instrumentTypeId = instrumentTypeId;
      }
      if (modelIds) {
        request.modelIds = modelIds;
      }
      if (onlyPublishedFiles !== undefined) {
        request.onlyPublishedFiles = onlyPublishedFiles;
      }
    }

    return this.httpClient.post<Assay[]>(query, request);
  }

  public getAssayById(assayId: number): Observable<Assay> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getAssayByIdUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayIdField, assayId.toString());

    return this.httpClient.get<Assay>(query, { params: httpParams });
  }

  public addAssay(assay: Assay, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.assayUrl}${this.addAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, assay, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateAssay(assay: Assay, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.assayUrl}${this.updateAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, assay, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getAssayBlobUploadUrl(file: File): Observable<BlobUpload> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getBlobUploadUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, file.name);

    return this.httpClient.get<BlobUpload>(query, { params: httpParams });
  }

  public deleteAssay(assayId: number): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.deleteAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayIdField, assayId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public publishAssay(assayId: number): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.publishAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayIdField, assayId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public finalizePublishAssay(assayId: number): Observable<boolean> {
    const query = `${this.apiUrl}${this.assayUrl}${this.finalizePublishAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayIdField, assayId.toString());

    return this.httpClient.get<boolean>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public unpublishAssay(assayId: number): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.unpublishAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayIdField, assayId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public validateAssay(assay: Assay, fileName: string, signatureKeyName: string): Observable<ValidationResult> {
    const query = `${this.apiUrl}${this.assayUrl}${this.validateAssayUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');

    return this.httpClient.post<string>(query, assay, { params: httpParams })
      .pipe(map(result => JSON.parse(result) as ValidationResult));
  }

  public getAssayCompatibleFirmwares(instrumentFileTypeId: number, instrumentTypeId: number, assayInterfaceId: number): Observable<Firmware[]> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getAvailableFirmwaresUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentFileTypeIdField, instrumentFileTypeId.toString())
      .set(httpFields.instrumentTypeIdField, instrumentTypeId.toString())
      .set(httpFields.assayInterfaceIdField, assayInterfaceId.toString());

    return this.httpClient.get<Firmware[]>(query, { params: httpParams });
  }

  public getLanguageCompatibleFirmwares(instrumentFileTypeId: number, instrumentTypeId: number, languageInterfaceId: number): Observable<Firmware[]> {
    const query = `${this.apiUrl}${this.languageUrl}${this.getAvailableFirmwaresUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentFileTypeIdField, instrumentFileTypeId.toString())
      .set(httpFields.instrumentTypeIdField, instrumentTypeId.toString())
      .set(httpFields.languageInterfaceIdField, languageInterfaceId.toString());

    return this.httpClient.get<Firmware[]>(query, { params: httpParams });
  }

  public getAssayDefinitions(): Observable<AssayDefinition[]> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getAssayDefinitionsUrl}`;

    return this.httpClient.get<AssayDefinition[]>(query);
  }

  public addAssayDefinition(assayDefinition: AssayDefinition): Observable<number> {
    const query = `${this.apiUrl}${this.assayUrl}${this.addAssayDefinitionUrl}`;

    return this.httpClient.post<number>(query, assayDefinition)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateAssayDefinition(assayDefinition: AssayDefinition): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.updateAssayDefinitionUrl}`;

    return this.httpClient.post<void>(query, assayDefinition)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteAssayDefinition(assayDefinitionId: number): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.deleteAssayDefinitionUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayDefinitionIdField, assayDefinitionId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getAssayInterfaces(): Observable<AssayInterface[]> {
    const query = `${this.apiUrl}${this.assayUrl}${this.getAssayInterfacesUrl}`;

    return this.httpClient.get<AssayInterface[]>(query);
  }

  public addAssayInterface(assayInterface: AssayInterface): Observable<number> {
    const query = `${this.apiUrl}${this.assayUrl}${this.addAssayInterfaceUrl}`;

    return this.httpClient.post<number>(query, assayInterface)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteAssayInterface(assayInterfaceId: number): Observable<void> {
    const query = `${this.apiUrl}${this.assayUrl}${this.deleteAssayInterfaceUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.assayInterfaceIdField, assayInterfaceId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getFirmwares(instrumentFileTypeId?: number, instrumentTypeId?: number, modelIds?: number[], onlyPublishedFiles?: boolean): Observable<Firmware[]> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.getFirmwaresUrl}`;
    const request: FileSearchRequest = {};
    if (instrumentFileTypeId || instrumentTypeId || onlyPublishedFiles !== undefined) {
      if (instrumentFileTypeId) {
        request.instrumentFileTypeId = instrumentFileTypeId;
      }
      if (instrumentTypeId) {
        request.instrumentTypeId = instrumentTypeId;
      }
      if (modelIds) {
        request.modelIds = modelIds;
      }
      if (onlyPublishedFiles !== undefined) {
        request.onlyPublishedFiles = onlyPublishedFiles;
      }
    }

    return this.httpClient.post<Firmware[]>(query, request);
  }

  public getFirmwareById(firmwareId: number): Observable<Firmware> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.getFirmwareByIdUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.firmwareIdField, firmwareId.toString());

    return this.httpClient.get<Firmware>(query, { params: httpParams });
  }

  public getFirmwareAvailableFirmwares(firmwareId: number, major: number, minor: number, revision: number, instrumentTypeId: number, instrumentFileTypeId?: number): Observable<Firmware[]> {
    let httpParams: HttpParams = new HttpParams();

    if (firmwareId) {
      httpParams = httpParams.set(httpFields.firmwareIdField, firmwareId.toString());
    }
    httpParams = httpParams.set(httpFields.majorField, major.toString());
    httpParams = httpParams.set(httpFields.minorField, minor.toString());
    httpParams = httpParams.set(httpFields.revisionField, revision.toString());
    if (instrumentFileTypeId) {
      httpParams = httpParams.set(httpFields.instrumentFileTypeIdField, instrumentFileTypeId.toString());
    }
    httpParams = httpParams.set(httpFields.instrumentTypeIdField, instrumentTypeId.toString());

    const query = `${this.apiUrl}${this.firmwareUrl}${this.getAvailableFirmwaresUrl}`;

    return this.httpClient.get<Firmware[]>(query, { params: httpParams });
  }

  public getFirmwareAvailableAssays(instrumentFileTypeId: number, instrumentTypeId: number, assayInterfaceIds: number[], onlyPublishedFiles?: boolean): Observable<Assay[]> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.getAvailableAssaysUrl}`;
    let httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentFileTypeIdField, instrumentFileTypeId.toString())
      .set(httpFields.instrumentTypeIdField, instrumentTypeId.toString());

    if (onlyPublishedFiles !== undefined) {
      httpParams = httpParams.set(httpFields.onlyPublishedFilesField, onlyPublishedFiles.toString());
    }

    return this.httpClient.post<Assay[]>(query, assayInterfaceIds, { params: httpParams });
  }

  public getFirmwareAvailableLanguages(instrumentFileTypeId: number, instrumentTypeId: number, languageInterfaceIds: number[], onlyPublishedFiles?: boolean): Observable<Language[]> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.getAvailableLanguagesUrl}`;
    let httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentFileTypeIdField, instrumentFileTypeId.toString())
      .set(httpFields.instrumentTypeIdField, instrumentTypeId.toString());

    if (onlyPublishedFiles !== undefined) {
      httpParams = httpParams.set(httpFields.onlyPublishedFilesField, onlyPublishedFiles.toString());
    }

    return this.httpClient.post<Language[]>(query, languageInterfaceIds, { params: httpParams });
  }

  public addFirmware(firmware: Firmware, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.addFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, firmware, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getFirmwareBlobUploadUrl(file: File): Observable<BlobUpload> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.getBlobUploadUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, file.name);

    return this.httpClient.get<BlobUpload>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateFirmware(firmware: Firmware, fileName: string = null, blobName: string = null, signatureKeyName: string = null, preventBusy = false): Observable<unknown> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.updateFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.blobField, blobName || '')
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');
    const req = new HttpRequest(this.postMethod, query, firmware, { reportProgress: true, headers: preventBusy ? preventBusyIndicatorHeaders : null, params: httpParams });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public publishFirmware(firmwareId: number): Observable<void> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.publishFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.firmwareIdField, firmwareId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public finalizePublishFirmware(firmwareId: number): Observable<boolean> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.finalizePublishFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.firmwareIdField, firmwareId.toString());

    return this.httpClient.get<boolean>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public unpublishFirmware(firmwareId: number): Observable<void> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.unpublishFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.firmwareIdField, firmwareId.toString());

    return this.httpClient.get<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteFirmware(firmwareId: number): Observable<void> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.deleteFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.firmwareIdField, firmwareId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public validateFirmware(firmware: Firmware, fileName: string, signatureKeyName: string): Observable<ValidationResult> {
    const query = `${this.apiUrl}${this.firmwareUrl}${this.validateFirmwareUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.fileNameField, fileName || '')
      .set(httpFields.signatureKeyNameField, signatureKeyName || '');

    return this.httpClient.post<string>(query, firmware, { params: httpParams })
      .pipe(map(result => JSON.parse(result) as ValidationResult));
  }

  public getDeviceInformationById(id: number): Observable<DeviceInformation> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceInformationByIdUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.deviceInformationIdField, id.toString());

    return this.httpClient.get<DeviceInformation>(query, { params: httpParams })
      .pipe(tap(deviceInformation => this.postProcessDeviceInformationFromApi(deviceInformation)));
  }

  public updateDeviceInformationFromIdb(serialNumber: string, instrumentTypeId: number): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.updateDeviceInformationFromIdbUrl}`;
    return this.httpClient.post(query, { serialNumber, instrumentTypeId })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getDeviceInformationBySerialNumber(serialNumber: string, ignoreNotFound: boolean): Observable<DeviceInformation> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceInformationBySerialNumberUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, serialNumber);

    const headers = ignoreNotFound
      ? new HttpHeaders().set(expectedErrorsString, ['404'])
      : new HttpHeaders();

    return this.httpClient.get<DeviceInformation>(query, { params: httpParams, headers })
      .pipe(tap(deviceInformation => this.postProcessDeviceInformationFromApi(deviceInformation)));
  }

  public getDeviceInformationByInstrumentGroupId(instrumentGroupId: number): Observable<DeviceInformation[]> {
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentGroupIdField, instrumentGroupId.toString());
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceInformationByInstrumentGroupIdUrl}`;

    return this.httpClient.get<DeviceInformation[]>(query, { params: httpParams })
      .pipe(tap(deviceInformationArray => {
        if (deviceInformationArray?.length > 0) {
          deviceInformationArray.forEach(deviceInformation => this.postProcessDeviceInformationFromApi(deviceInformation));
        }
      }));
  }

  public getDeviceActionHistory(serialNumber: string): Observable<InstrumentActionHistoryRow[]> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceActionHistoryUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, serialNumber.toString());

    return this.httpClient.get<InstrumentActionHistoryRow[]>(query, { params: httpParams });
  }

  public getDevicePendingC2DMessages(serialNumber: string): Observable<C2DPendingMessage[]> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceC2DPendingMessagesUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, serialNumber.toString());

    return this.httpClient.get<C2DPendingMessage[]>(query, { params: httpParams })
      .pipe(tap((pendingMessages: C2DPendingMessage[]) => {
        const utcTimestamp = new Date().getTime();
        pendingMessages.forEach(pendingMessage => {
          pendingMessage.pendingHours = Math.floor(Math.abs(utcTimestamp - new Date(pendingMessage.messagePayload.timeStamp).getTime()) / 3600000);
        });
      }));
  }

  public cancelUpgradeRequest(cancelUpgradeRequestData: CancelUpgradeRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.cancelUpgradeRequestUrl}`;

    return this.httpClient.post<unknown>(query, cancelUpgradeRequestData)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public cancelUpgradeRequests(cancelUpgradeRequest: CancelUpgradeRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.cancelUpgradeRequestsUrl}`;

    return this.httpClient.post(query, cancelUpgradeRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getDeviceInformation(searchRequest: SearchRequest): Observable<DeviceInformationResult> {
    prepareDeviceSearchRequest(searchRequest);
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceInformationUrl}`;

    return this.httpClient.post<DeviceInformationResult>(query, searchRequest)
      .pipe(tap(deviceInformationResult => {
        if (deviceInformationResult?.results?.length > 0) {
          deviceInformationResult.results.forEach(deviceInformation => this.postProcessDeviceInformationFromApi(deviceInformation));
        }
      }));
  }

  public getUnregisteredDeviceInformation(searchRequest: SearchRequest): Observable<DeviceInformationResult> {
    if (searchRequest.sortColumn) {
      searchRequest.sortColumn = `DeviceInformation.${searchRequest.sortColumn}`;
    }

    if (searchRequest.sortDirection === SortDirection.None.valueOf()) {
      delete searchRequest.sortDirection;
    }

    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getUnregisteredDeviceInformationUrl}`;

    return this.httpClient.post<DeviceInformationResult>(query, searchRequest);
  }

  public addDeviceInformation(deviceInformationRequest: DeviceInformationRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.addDeviceInformationUrl}`;

    return this.httpClient.post<DeviceInformation>(query, deviceInformationRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateDeviceInformation(deviceInformationRequest: DeviceInformationRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.updateDeviceInformationUrl}`;

    return this.httpClient.post<DeviceInformation>(query, deviceInformationRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteDeviceInformation(deviceInformationId: number): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.deleteDeviceInformationUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.deviceInformationIdField, deviceInformationId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public upgradeDevice(deviceUpgradeRequest: DeviceUpgradeRequest, instrumentSearchRequest?: SearchRequest, instrumentGroupSearchRequest?: InstrumentGroupsRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.upgradeDevicesUrl}`;

    if (instrumentSearchRequest) {
      prepareDeviceSearchRequest(instrumentSearchRequest);
      deviceUpgradeRequest.queryByInstrumentSearchRequest = instrumentSearchRequest;
    }

    if (instrumentGroupSearchRequest) {
      prepareSearchGroupRequest(instrumentGroupSearchRequest);
      deviceUpgradeRequest.queryByInstrumentGroupSearchRequest = instrumentGroupSearchRequest;
    }

    return this.httpClient.post<DeviceInformation>(query, deviceUpgradeRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateInstrumentGroupDeviceSettings<T extends DeviceRequest>(request: T): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.updateInstrumentGroupDeviceSettingsUrl}`;

    return this.httpClient.post(query, request)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public forceDeviceTwinRefresh(deviceRequest: DeviceRequest): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.forceDeviceTwinRefreshUrl}`;

    return this.httpClient.post<DeviceInformation>(query, deviceRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public forceCertificateRefresh(deviceRequest: DeviceRequest): Observable<boolean> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.forceCertificateRefreshUrl}`;

    return this.httpClient.post<boolean>(query, deviceRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getDeviceCountFromCertificateRefreshRequest(deviceRequest: DeviceRequest): Observable<number> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDeviceCountFromCertificateRefreshRequestUrl}`;

    return this.httpClient.post<number>(query, deviceRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deleteInstrumentGroup(instrumentGroupId: number): Observable<unknown> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.deleteInstrumentGroupUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentGroupIdField, instrumentGroupId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public addInstrumentGroup(upsertInstrumentGroupRequest: UpsertInstrumentGroupRequest): Observable<number> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.addInstrumentGroupUrl}`;

    return this.httpClient.post<number>(query, upsertInstrumentGroupRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public updateInstrumentGroup(upsertInstrumentGroupRequest: UpsertInstrumentGroupRequest): Observable<number> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.updateInstrumentGroupUrl}`;

    return this.httpClient.post<number>(query, upsertInstrumentGroupRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getInstrumentGroupById(instrumentGroupId: number): Observable<InstrumentGroup> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.getInstrumentGroupByIdUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentGroupIdField, instrumentGroupId.toString());

    return this.httpClient.get<InstrumentGroup>(query, { params: httpParams });
  }

  public getInstrumentGroups(instrumentGroupsRequest: InstrumentGroupsRequest): Observable<InstrumentGroupsResult> {
    prepareSearchGroupRequest(instrumentGroupsRequest);
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.getInstrumentGroupsUrl}`;

    return this.httpClient.post<InstrumentGroupsResult>(query, instrumentGroupsRequest);
  }

  public getInstrumentGroupsByInstrumentId(instrumentId: number): Observable<InstrumentGroup[]> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.getInstrumentGroupsByInstrumentIdUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.instrumentIdField, instrumentId.toString());

    return this.httpClient.get<InstrumentGroup[]>(query, { params: httpParams });
  }

  public updateInstrumentInstrumentGroup(instrumentId: number, instrumentGroups: number[]): Observable<void> {
    const query = `${this.apiUrl}${this.instrumentGroupUrl}${this.updateInstrumentInstrumentGroupsUrl}`;

    return this.httpClient.post<void>(query, { instrumentId, instrumentGroups })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public uploadBlob(blobUpload: BlobUpload, file: File, preventBusy = true): Observable<unknown> {
    let headers = preventBusy ? preventBusyIndicatorHeaders : new HttpHeaders();
    headers = headers.append(UrlConstants.blobTypeHeader, UrlConstants.blockBlobType);

    const req = new HttpRequest(this.putMethod, blobUpload.blobUri, file, { reportProgress: true, headers });

    return this.httpClient.request(req)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getDevicesAwaitingManualApproval(instrumentTypeId?: number): Observable<DeviceManualApprovalEntry[]> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getDevicesAwaitingManualApprovalUrl}`;

    return this.httpClient.post<DeviceManualApprovalEntry[]>(query, { instrumentTypeId })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public upsertDeviceInformationApproval(entry: DeviceManualApprovalEntry, instrumentTypeId: number, instrumentTypeModelId: number, countryId: number, isApproved: boolean): Observable<number> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.upsertDeviceInformationApprovalUrl}`;

    return this.httpClient.post<number>(query, { serialNumber: entry.serialNumber, instrumentTypeId, instrumentTypeModelId, isApproved, deviceIdentifiersJson: entry.deviceIdentifiersJson, countryId })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deletePendingManualApproval(entry: DeviceManualApprovalEntry): Observable<void> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.deletePendingManualApprovalUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, entry.serialNumber)
      .set(httpFields.instrumentTypeCodeField, entry.instrumentTypeCode);

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getCountries(): Observable<Country[]> {
    const query = `${this.apiUrl}${this.countryUrl}${this.getCountriesUrl}`;

    return this.httpClient.get<Country[]>(query)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getSerialNumberOverrides(serialNumberOverridesRequest: SerialNumberOverridesRequest): Observable<SerialNumberOverridesResult> {
    prepareSearchOverrideRequest(serialNumberOverridesRequest);
    const query = `${this.apiUrl}${this.serialNumberOverrideUrl}${this.getSerailNumberOverridesUrl}`;

    return this.httpClient.post<SerialNumberOverridesResult>(query, serialNumberOverridesRequest);
  }

  public deleteSerialNumberOverride(serialNumberOverrideId: number): Observable<unknown> {
    const query = `${this.apiUrl}${this.serialNumberOverrideUrl}${this.deleteSerialNumberOverrideUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.serialNumberOverrideIdField, serialNumberOverrideId.toString());

    return this.httpClient.delete<void>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public upsertSerialNumberOverride(upsertSerialNumberOverride: SerialNumberOverride): Observable<number> {
    const query = `${this.apiUrl}${this.serialNumberOverrideUrl}${this.upsertSerialNumberOverrideUrl}`;

    return this.httpClient.post<number>(query, upsertSerialNumberOverride)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public setOverrideCountryForSerialNumberOverrides(setSerialNumberOverridesRequest: SetSerialNumberOverridesRequest): Observable<boolean> {
    const query = `${this.apiUrl}${this.serialNumberOverrideUrl}${this.setOverrideCountryForSerialNumberOverridesUrl}`;

    return this.httpClient.post<boolean>(query, setSerialNumberOverridesRequest)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public requestSerialNumberOverridesImport(): Observable<boolean> {
    const query = `${this.apiUrl}${this.serialNumberOverrideUrl}${this.serialNumberOverridesRequestImportUrl}`;

    return this.httpClient.get<boolean>(query)
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getVerizonDeviceStatus(deviceInformationId: number): Observable<VerizonDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.verizonDeviceUrl}/Get`;

    const params: HttpParams = new HttpParams()
      .set(httpFields.deviceInformationIdField, deviceInformationId);

    const headers = new HttpHeaders().set(expectedErrorsString, ['404']);

    return this.httpClient.get<VerizonDeviceCacheEntry>(query, { params, headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public activateVerizonDevice(deviceInformationId: number, deviceId: string): Observable<VerizonDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.verizonDeviceUrl}/Activate`;
    return this.httpClient.post<VerizonDeviceCacheEntry>(query, { deviceInformationId, digiRequest: { id: deviceId } })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public deactivateVerizonDevice(deviceInformationId: number): Observable<VerizonDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.verizonDeviceUrl}/Deactivate`;
    return this.httpClient.post<VerizonDeviceCacheEntry>(query, { deviceInformationId })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getDigiDeviceStatus(deviceInformationId: number): Observable<DigiDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.digiDeviceUrl}/Get`;

    const params: HttpParams = new HttpParams()
      .set(httpFields.deviceInformationIdField, deviceInformationId);

    const headers = new HttpHeaders().set(expectedErrorsString, ['404']);

    return this.httpClient.get<DigiDeviceCacheEntry>(query, { params, headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public addDigiDevice(deviceInformationId: number, deviceId: string, serialNumber: string): Observable<DigiDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.digiDeviceUrl}/Create`;
    return this.httpClient.post<DigiDeviceCacheEntry>(query, { deviceInformationId, digiRequest: { id: deviceId, serial_number: serialNumber } })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public removeDigiDevice(deviceInformationId: number): Observable<DigiDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.digiDeviceUrl}/Delete`;
    return this.httpClient.delete<DigiDeviceCacheEntry>(query, { body: { deviceInformationId } })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getMyVirenaDevice(serialNumber: string, deviceInformationId: number): Observable<MyVirenaDeviceCacheEntry> {
    const query = `${this.apiUrl}${this.myVirenaDeviceUrl}/Get`;

    const params: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, serialNumber)
      .set(httpFields.deviceInformationIdField, deviceInformationId);

    const headers = new HttpHeaders().set(expectedErrorsString, ['404']);

    return this.httpClient.get<MyVirenaDeviceCacheEntry>(query, { params, headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getShippingInformationFromIdb(serialNumber: string, instrumentTypeId: number): Observable<ShippingInformation> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getShippingInformationFromIdbUrl}`;

    const params: HttpParams = new HttpParams()
      .set(httpFields.serialNumberField, serialNumber)
      .set(httpFields.instrumentTypeIdField, instrumentTypeId);

    const headers = new HttpHeaders().set(expectedErrorsString, ['404']);

    return this.httpClient.get<ShippingInformation>(query, { params, headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  private postProcessDeviceInformationFromApi(deviceInformation: DeviceInformation) {
    if (!deviceInformation) {
      return;
    }

    if (deviceInformation.assayVersions?.length > 0) {
      deviceInformation.currentAssayVersions = deviceInformation.assayVersions.filter(assayVersion => assayVersion.state === FileState.Current);
      deviceInformation.requestedAssayVersions = deviceInformation.assayVersions.filter(assayVersion => assayVersion.state === FileState.Requested);
    }

    if (deviceInformation.languageVersions?.length > 0) {
      deviceInformation.currentLanguageVersions = deviceInformation.languageVersions.filter(languageVersion => languageVersion.state === FileState.Current);
      deviceInformation.requestedLanguageVersions = deviceInformation.languageVersions.filter(languageVersion => languageVersion.state === FileState.Requested);

    }
  }
}

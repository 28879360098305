<div class="dialog-header">
  <h2>{{ 'Instruments.ManuallyRequestFiles' | translate }}</h2>
  <button class="close-button" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="main-content">
  <div class="action-bar">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'ManualRequestFiles.Path' | translate }}</mat-label>
        <input matInput type="text" [value]="pathText" [formControl]="pathControl">
        <button *ngIf="pathText" matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
          (click)="clearPathText()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint>{{ 'ManualRequestFiles.PathHint' | translate }}</mat-hint>
      </mat-form-field>
    </div>
    <button mat-icon-button (click)="addPath()" [disabled]="pathText?.trim().length === 0 || maxDownloadRequestsReached">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-raised-button color="primary" [disabled]="maxDownloadRequestsReached"
      (click)="triggerAddPathsFromFile()">
      {{ 'ManualRequestFiles.AddFromFile' | translate }}</button>
    <input class="no-display" placeholder="File" type="file" #addPaths_file id="addPaths_file"
      (change)="addPathsFromFile($event.target.files)" accept=".txt,.json">
  </div>

  <div style="display: flex;">
    <h3>{{ 'ManualRequestFiles.Paths' | translate }} ({{ paths.length }})</h3>
    <span class="download-selection-count" *ngIf="maxDownloadRequestsReached"> {{
      'ManualRequestFiles.MaxDownloadRequestsReached' | translate }}</span>
  </div>
  <div class="paths">
    <div class="path-entry" *ngFor="let path of paths; index as i">
      {{ path }}
      <button mat-icon-button (click)="removePath(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="bottom-action-bar">
    <button mat-raised-button color="primary" (click)="downloadPaths()" [disabled]="paths.length <= 0">{{
      'Global.Download' | translate }}</button>
    <button mat-raised-button color="primary" (click)="removeAllPaths()" [disabled]="paths.length <= 0">
      {{ 'ManualRequestFiles.RemoveAllPaths' | translate }}
    </button>
  </div>
</div>

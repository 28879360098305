import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MsalModule, MsalInterceptor, MsalService, MsalRedirectComponent } from '@azure/msal-angular';
import { MaterialModule } from './shared/material.module';

import { HttpHandler } from '@angular/common/http';
import { HttpCachingClient, httpCachingClientCreator } from './services/http-caching-client';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ActivityInterceptor } from './interceptors/activity.interceptor';
import { SessionTokenInterceptor } from './interceptors/session-token.interceptor';
import { LanguageInterfaceVersionsComponent } from './views/language-interface-versions/language-interface-versions.component';
import { AssayInterfaceVersionsComponent } from './views/assay-interface-versions/assay-interface-versions.component';
import { AboutDialogComponent } from './components/about/about.dialog';
import { AssayFilesComponent } from './views/assay-files/assay-files.component';
import { LanguageFilesComponent } from './views/language-files/language-files.component';
import { AssayDefinitionsComponent } from './views/assay-definitions/assay-definitions.component';
import { LanguageDefinitionsComponent } from './views/language-definitions/language-definitions.component';
import { FirmwareFilesComponent } from './views/firmware-files/firmware-files.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatchedConfirmationModalComponent } from './components/matched-confirmation-modal/matched-confirmation-modal.component';
import { ResetSelectOnClearDirective } from './directives/reset-select-on-clear.directive';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { InstrumentsComponent } from './views/instruments/instruments.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { UpgradeInstrumentsComponent } from './views/upgrade-instruments/upgrade-instruments.component';
import { AssayFileDialogComponent } from './views/assay-file-dialog/assay-file-dialog.component';
import { LanguageFileDialogComponent } from './views/language-file-dialog/language-file-dialog.component';
import { FirmwareFileDialogComponent } from './views/firmware-file-dialog/firmware-file-dialog.component';
import { InstrumentGroupsComponent } from './views/instrument-groups/instrument-groups.component';
import { InstrumentGroupDialogComponent } from './views/instrument-group-dialog/instrument-group-dialog.component';
import { InstrumentDialogComponent } from './views/instrument-dialog/instrument-dialog.component';
import { InstrumentGroupListComponent } from './components/instrument-group-list/instrument-group-list.component';
import { UnregisteredInstrumentsComponent } from './views/unregistered-instruments/unregistered-instruments.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatFormattedLengthPaginator } from './shared/paginators/mat-formatted-length-paginator';
import { DecimalPipe } from '@angular/common';
import { ClearUpgradeDialogComponent } from './components/clear-upgrade-dialog/clear-upgrade-dialog.component';
import { ReportsComponent } from './views/reports/reports.component';
import { CertificateRefreshComponent } from './views/certificate-refresh/certificate-refresh.component';
import { UpgradeRequestHistoryComponent } from './views/instrument-dialog/upgrade-request-history/upgrade-request-history.component';
import { InstrumentUtilitiesComponent } from './components/instrument-utilities/instrument-utilities.component';
import { DeleteFilesComponent } from './views/delete-files/delete-files.component';
import { VersionPipe } from './pipes/version.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { DigiAddedPipe } from './pipes/digi-added.pipe';
import { DigiNotAddedPipe } from './pipes/digi-not-added.pipe';
import { VerizonStatusPipe } from './pipes/verizon-status.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsDialogComponent } from './components/settings-dialog/settings-dialog.component';
import { TranslateConstants } from './constants/translate-constants';
import { InstrumentTypeSelectorComponent } from './components/instrument-type-selector/instrument-type-selector.component';
import { FileBrowserComponent } from './components/file-browser/file-browser.component';
import { InstrumentManualApprovalComponent } from './views/instrument-manual-approval/instrument-manual-approval.component';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { UserManagementDialogComponent } from './views/user-management-dialog/user-management-dialog.component';
import { AddClaimsDialogComponent } from './views/user-management-dialog/add-claims-dialog/add-claims-dialog.component';
import { SerialNumberOverridesComponent } from './views/serialnumber-overrides/serialnumber-overrides.component';
import { SerialNumberOverridesListComponent } from './components/serialnumber-overrides-list/serialnumber-overrides-list.component';
import { SerialNumberOverrideDialogComponent } from './views/serialnumber-override-dialog/serialnumber-override-dialog.component';
import { SetSerialNumberOverridesDialogComponent } from './components/set-serialnumber-overrides-dialog/set-serialnumber-overrides-dialog.component';
import { InstrumentFilesListComponent } from './components/instrument-files-list/instrument-files-list.component';
import { FolderSearchComponent } from './components/folder-search/folder-search.component';
import { RequestFilesComponent } from './components/request-files/request-files.component';
import { TimeFormComponent } from './components/time-form/time-form.component';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LanguageInterfaceVersionsComponent,
    AssayInterfaceVersionsComponent,
    AboutDialogComponent,
    AssayFilesComponent,
    LanguageFilesComponent,
    AssayDefinitionsComponent,
    LanguageDefinitionsComponent,
    FirmwareFilesComponent,
    ConfirmationDialogComponent,
    MatchedConfirmationModalComponent,
    ClearUpgradeDialogComponent,
    ResetSelectOnClearDirective,
    DeviceListComponent,
    InstrumentsComponent,
    UploadDialogComponent,
    UpgradeInstrumentsComponent,
    AssayFileDialogComponent,
    LanguageFileDialogComponent,
    InstrumentGroupsComponent,
    InstrumentGroupDialogComponent,
    FirmwareFileDialogComponent,
    InstrumentDialogComponent,
    InstrumentGroupListComponent,
    UnregisteredInstrumentsComponent,
    ReportsComponent,
    UpgradeRequestHistoryComponent,
    CertificateRefreshComponent,
    VersionPipe,
    BytesPipe,
    DigiAddedPipe,
    DigiNotAddedPipe,
    VerizonStatusPipe,
    InstrumentUtilitiesComponent,
    DeleteFilesComponent,
    SettingsDialogComponent,
    InstrumentTypeSelectorComponent,
    FileBrowserComponent,
    InstrumentManualApprovalComponent,
    UserManagementComponent,
    UserManagementDialogComponent,
    AddClaimsDialogComponent,
    SerialNumberOverridesComponent,
    SerialNumberOverridesListComponent,
    SerialNumberOverrideDialogComponent,
    SetSerialNumberOverridesDialogComponent,
    InstrumentFilesListComponent,
    FolderSearchComponent,
    RequestFilesComponent,
    TimeFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-center', preventDuplicates: true, enableHtml: true }),
    NgxSpinnerModule,
    MsalModule,
    MaterialModule,
    TranslateModule.forRoot({
      defaultLanguage: TranslateConstants.DefaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DecimalPipe,
    {
      provide: HttpCachingClient,
      useFactory: httpCachingClientCreator,
      deps: [HttpHandler]
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatFormattedLengthPaginator
    },
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: ActivityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

<div class="page">
  <div class="page-grid">
    <h1>{{ 'DeleteFiles.DeleteFiles' | translate }}</h1>
    <div *ngIf="canDeleteAnyFiles && selectedInstrumentType" class="filter-section">
      <div class="field-container">
        <div class="selection-list-container">
          <div *ngIf="showAssays" class="section filter">
            <mat-label class="field-label">{{ 'DeleteFiles.Assays' | translate }}</mat-label>
            <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
              (change)="$event.checked ? assayDefinitionCodes = [] : null"
              [checked]="!assayDefinitionCodes || assayDefinitionCodes?.length === 0">
              <div class="properties-grid">
                <div>{{ 'Global.None' | translate }}</div>
              </div>
            </mat-checkbox>
            <mat-selection-list [disabled]="!canAddUpdate" class="compatible-list condensed"
              [(ngModel)]="assayDefinitionCodes">
              <mat-list-option checkboxPosition="before" *ngFor="let assayDefinition of assayDefinitions"
                [value]="assayDefinition.code"
                (change)="$event.checked ? assayDefinitionCodes = [assayDefinition.code] : null">
                <div>
                  <div class="properties-grid-2">
                    <div>
                      {{ assayDefinition.code}}
                    </div>
                    <div>
                      {{ assayDefinition.definitionName }}
                    </div>
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div *ngIf="showLanguages" class="filter">
            <mat-label class="field-label">{{ 'DeleteFiles.Languages' | translate }}</mat-label>
            <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
              (change)="$event.checked ? languageDefinitionCodes = [] : null"
              [checked]="!languageDefinitionCodes || languageDefinitionCodes?.length === 0">
              <div class="properties-grid">
                <div>{{ 'Global.None' | translate }}</div>
              </div>
            </mat-checkbox>
            <mat-selection-list [disabled]="!canAddUpdate" class="compatible-list condensed"
              [(ngModel)]="languageDefinitionCodes">
              <mat-list-option checkboxPosition="before" *ngFor="let languageDefinition of languageDefinitions"
                [value]="languageDefinition.code"
                (change)="$event.checked ? languageDefinitionCodes = [languageDefinition.code] : null">
                <div>
                  <div class="properties-grid-2">
                    <div>
                      {{ languageDefinition.code }}
                    </div>
                    <div>
                      {{ languageDefinition.definitionName }}
                    </div>
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group #tabs *ngIf="canDeleteAnyFiles && selectedInstrumentType" [(selectedIndex)]="selectedTabIndex"
      mat-stretch-tabs="false">
      <mat-tab [label]="'DeleteFiles.Instruments' | translate">
        <app-device-list class="device-list" [getDeviceInformationResult]="deviceInformationResult"
          [showSelectAllPagesMultiselect]="enableMultiSelect" (checkedDevices)="onCheckedDevices($event)"
          (deviceDataRequested)="deviceDataRequested($event)"
          [mode]="enableMultiSelect ? ListMode.Multiselect : ListMode.Singleselect" [accessLevel]="accessLevel"
          [instrumentTypeId]="selectedInstrumentType?.instrumentTypeId"
          [showViewInstrumentLink]="true"></app-device-list>
      </mat-tab>
      <mat-tab *ngIf="selectedInstrumentType && enableMultiSelect" [label]="'DeleteFiles.InstrumentGroups' | translate">
        <app-instrument-group-list class="group-list" [instrumentGroupsResult]="instrumentGroupsResult"
          [showSelectAllPagesMultiselect]="enableMultiSelect"
          [mode]="enableMultiSelect ? ListMode.Multiselect : ListMode.All" [accessLevel]="accessLevel"
          (dataRequested)="instrumentGroupDataRequested($event)" (checkedGroups)="onCheckedGroups($event)"
          (refreshData)="getInstrumentGroupsWithCurrentSearchState()">
        </app-instrument-group-list>
      </mat-tab>
    </mat-tab-group>
    <div class="action-bar" *ngIf="canDeleteAnyFiles && selectedInstrumentType">
      <div class="action-bar-left">
      </div>

      <div class="action-bar-right">
        <button mat-raised-button color="primary" (click)="deleteFilesDevices()"
          *ngIf="canAddUpdate && selectedTabIndex === 0" [disabled]="(!hasDevices()) || !hasDesiredProperty()">
          <mat-icon>delete</mat-icon>{{ 'Global.Delete' | translate }}
        </button>

        <button mat-raised-button color="primary" (click)="deleteFilesGroups()"
          *ngIf="canAddUpdate && selectedTabIndex === 1" [disabled]="(!hasGroups()) || !hasDesiredProperty()">
          <mat-icon>delete</mat-icon>{{ 'Global.Delete' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="!canDeleteAnyFiles">{{ 'DeleteFiles.UnableToDeleteFilesForInstrumentType' | translate }}</div>
  </div>
</div>

<div class="box">
  <div class="row header action-bar">
    <div>
      <mat-form-field class="search" *ngIf="mode !== ListMode.Filtered">
        <mat-label>{{ 'SerialNumberOverridesList.SearchBySerialNumber' | translate }}</mat-label>
        <input matInput type="text" [value]="searchInput" [formControl]="searchControl">
        <button *ngIf="searchInput" matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <button mat-icon-button class="refresh" *ngIf="mode !== ListMode.Filtered" (click)="onRefreshData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="row warning-message" *ngIf="mode === ListMode.Multiselect">
    <ng-container *ngIf="selectAllPages">
      <div
        [innerHTML]="'SerialNumberOverridesList.SelectionStatus.AllSerialNumberOverrides' | translate: {allSerialNumberOverridesCount: length | number}">
      </div>
    </ng-container>
    <ng-container *ngIf="!selectAllPages">
      <div
        [innerHTML]="'SerialNumberOverridesList.SelectionStatus.PartialSerialNumberOverrides' | translate: {selectedSerialNumberOverridesCount: allCheckedSerialNumberOverridesArrayInternal.length | number, allSerialNumberOverridesCount: length | number}">
      </div>
    </ng-container>
  </div>
  <div class="row content">
    <div>
      <div class="table">
        <table mat-table [dataSource]="dataSource" matSort [matSortActive]="serialNumberFieldName"
          matSortDirection="asc" matSortDisableClear="true" (matSortChange)="sort($event)">
          <ng-container *ngIf="mode === ListMode.Multiselect" [matColumnDef]="multiselectFieldName">
            <th mat-header-cell *matHeaderCellDef disabled mat-sort-header>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="multi-select">
                <div>
                  <mat-icon
                    *ngIf="length > 0  && (selectAllPages || allCheckedSerialNumberOverridesArrayInternal.length === length)">check_box
                  </mat-icon>
                  <mat-icon
                    *ngIf="!selectAllPages && allCheckedSerialNumberOverridesArrayInternal.length > 0  && allCheckedSerialNumberOverridesArrayInternal.length !== length">
                    indeterminate_check_box</mat-icon>
                  <mat-icon
                    *ngIf="allCheckedSerialNumberOverridesArrayInternal.length <= 0">check_box_outline_blank</mat-icon>
                  <mat-icon>arrow_drop_down</mat-icon>
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <button class="context-option" mat-menu-item (click)="checkAll()" *ngIf="showSelectAllPagesMultiselect">
                  <mat-icon [class.transparent]="!selectAllPages">check </mat-icon>
                  {{ 'SerialNumberOverrideList.Selection.AllSerialNumberOverrides' | translate:
                  {allSerialNumberOverridesCount: length | number} }}
                </button>
                <button class="context-option" mat-menu-item (click)="checkCurrentPage()">
                  <mat-icon [class.transparent]="!isCurrentPageChecked()"> check</mat-icon>
                  {{ 'SerialNumberOverrideList.Selection.CurrentPage' | translate: {pageCount: dataSource.data.length |
                  number} }}
                </button>
                <button class="context-option" mat-menu-item (click)="uncheckAll()">
                  <mat-icon
                    [class.transparent]="allCheckedSerialNumberOverridesArrayInternal.length > 0">check</mat-icon>
                  {{ 'SerialNumberOverrideList.Selection.Clear' | translate }}
                </button>
              </mat-menu>
            </th>
            <td mat-cell *matCellDef="let serialNumberOverride">
              <input [disabled]="!canAddUpdate" type="checkbox" [checked]="isChecked(serialNumberOverride)"
                (change)="selectionChanged($event, serialNumberOverride)">
            </td>
          </ng-container>
          <ng-container [matColumnDef]="idFieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Id' | translate }}</th>
            <td mat-cell *matCellDef="let serialNumberOverride"> {{serialNumberOverride.serialNumberOverrideId}} </td>
          </ng-container>
          <ng-container [matColumnDef]="serialNumberFieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.SerialNumber' | translate }}</th>
            <td mat-cell *matCellDef="let serialNumberOverride"> {{serialNumberOverride.serialNumber}} </td>
          </ng-container>
          <ng-container [matColumnDef]="instrumentTypeFieldName">
            <th mat-header-cell *matHeaderCellDef disabled mat-sort-header>{{ 'Global.InstrumentType' | translate }}
            </th>
            <td mat-cell *matCellDef="let serialNumberOverride">
              {{getInstrumentTypeDisplayValue(serialNumberOverride.instrumentTypeId)}} </td>
          </ng-container>
          <ng-container [matColumnDef]="countryFieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Country' | translate }}</th>
            <td mat-cell *matCellDef="let serialNumberOverride"> {{serialNumberOverride.country?.name}} </td>
          </ng-container>
          <ng-container [matColumnDef]="overrideCountryFieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.OverrideCountry' | translate }}</th>
            <td mat-cell *matCellDef="let serialNumberOverride"> {{serialNumberOverride.overrideCountry?.name}} </td>
          </ng-container>
          <ng-container [matColumnDef]="productCodeFieldName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.ProductCode' | translate }}</th>
            <td mat-cell *matCellDef="let serialNumberOverride"> {{serialNumberOverride.productCode}} </td>
          </ng-container>
          <ng-container [matColumnDef]="actionsFieldName">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let serialNumberOverride">
              <div *ngIf="canAddUpdate" class="actions-container">
                <button mat-icon-button (click)="onEditSerialNumberOverride(serialNumberOverride)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button (click)="onDeleteSerialNumberOverride(serialNumberOverride)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>

              <div *ngIf="!canAddUpdate" class="actions-container">
                <button mat-icon-button (click)="onEditSerialNumberOverride(serialNumberOverride)">
                  <mat-icon>visibility</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToUse"> </tr>
        </table>
        <div class="no-data" *ngIf="serialNumberOverridesResult && serialNumberOverridesResult?.results?.length <= 0">
          {{ 'SerialNumberOverridesList.NoOverrides' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row footer">
    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[100, 250, 500]"
      showFirstLastButtons (page)="getPageData($event)"></mat-paginator>
  </div>
</div>

export class UserSettingsConstants {
  public static readonly Language = 'Language';
  public static readonly InstrumentType = 'InstrumentType';
}

export type SupportedUserSetting = LanguageUserSetting | InstrumentTypeSetting;

export interface UserSetting {
  userSettingId?: number;
  userSettingKey: string;
  settings: string;
  settingsParsed: SupportedUserSetting;
}

export interface LanguageUserSetting {
  languageOverride: string;
}

export interface InstrumentTypeSetting {
  instrumentTypeCode: string;
  modelCodes: string[]
}

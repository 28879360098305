import { PagingInformation, SortDirection } from './device.models';
import { Country } from './country.models';

export interface SerialNumberOverride {
    serialNumberOverrideId: number;
    instrumentTypeId: number;
    countryId: number;
    country?: Country;
    overrideCountryId?: number;
    overrideCountry?: Country;
    serialNumber: string;
    productCode: string;
    createdDate: string;
    createdBy: string;
    lastModifiedDate: string;
    lastModifiedBy: string;
}

export interface SerialNumberOverridesResult {
    pagingInformation: PagingInformation;
    results: SerialNumberOverride[];
    totalResults: number;
}

export interface SerialNumberOverridesRequest {
    serialNumber: string;
    instrumentTypeId: number;
    pageNumber: number;
    pageSize: number;
    sortColumn: string;
    sortDirection: SortDirection;
}

export interface SerialNumberOverrideDialogData {
    serialNumberOverride: SerialNumberOverride
}

export interface SetSerialNumberOverrideDialogData {
  cancelText: string;
  confirmText: string;
  message: string;
  title: string;
}

export interface SetSerialNumberOverrideDialogResponse {
  result: boolean;
  overrideCountryId?: number;
}

export interface SetSerialNumberOverridesRequest {
    overrideCountryId?: number;
    serialNumberOverrideIds: number[];
}


export const prepareSearchOverrideRequest = (serialNumberOverridesRequest: SerialNumberOverridesRequest): void => {
  if (serialNumberOverridesRequest.sortColumn) {
    serialNumberOverridesRequest.sortColumn = `SerialNumberOverride.${serialNumberOverridesRequest.sortColumn}`;
  }

  if (serialNumberOverridesRequest.sortDirection === SortDirection.None) {
    delete serialNumberOverridesRequest.sortDirection;
  }
};


import { TranslateService } from '@ngx-translate/core';
import { ValidationErrorType } from './enum.models';
import { translate } from '../shared/translateServiceHelper';
import { TranslateConstants } from '../constants/translate-constants';

export interface ApiError {
  statusCode: number;
  statusDescription: string;
  message: string;
}

export interface ErrorStatus {
  status?: number;
}

export interface ValidationResult {
  isValid: boolean;
  errors: ValidationError[];
}

export interface ValidationError {
  validationErrorType: ValidationErrorType;
  errorMessage: string;
}

export const formatValidationResultForDisplay = (translateService: TranslateService, result: ValidationResult): string => {
  let formatted = '';

  try {
    result.errors.forEach(error => {
      formatted += `${error.errorMessage}<br/>`;
    });
  } catch (e) {
    console.debug(e);
    formatted = translate(translateService, TranslateConstants.FailedParsingValidationErrorsKey);
  }

  return formatted;
};

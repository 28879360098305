<div class="field-container">
  <ng-container *ngIf="deviceRecentUpgradeRequestHistoryExists === false">
    <p class="no-device-entries-message">{{ 'UpgradeRequestHistory.NoRecentHistory' | translate }}</p>
  </ng-container>
  <div *ngFor="let row of upgradeRequestHistoryItems; let i = index">
    <div class="title">#{{i + 1}} - {{row.messageTimeStamp | date:'full' }}</div>
    <div class="value">
      <div><span class="bold">{{ 'UpgradeRequestHistory.Type' | translate }}</span> {{row.requestType}}</div>
      <div><span class="bold">{{ 'UpgradeRequestHistory.Successful' | translate }}</span> {{row.isSuccess}}</div>
      <div><span class="bold">{{ 'UpgradeRequestHistory.ChangesMadeToDeviceTwin' | translate }}</span>
        {{row.changesMade}}</div>
      <div><span class="bold">{{ 'UpgradeRequestHistory.RequestedBy' | translate }}</span> {{row.requestUserName}}
        ({{row.requestUserId}})</div>
      <div *ngIf="row.errorMessage"><span class="bold">{{ 'UpgradeRequestHistory.Error' | translate }}</span>
        {{row.errorMessage}}</div>
      <div *ngIf="row.deviceSettings" class="value-group">
        <span class="bold">{{ 'UpgradeRequestHistory.DeviceSettings' | translate }}</span>
        <ol>
          <li *ngFor="let deviceSetting of row.deviceSettings">
            <div><span class="bold">{{ tryGetTranslateKey([deviceSetting.identifier]) | translate }}</span> </div>
            <div *ngIf="deviceSetting.unknownChangeStatus !== true" class="margin-left-12">
              <span class="bold">{{ 'UpgradeRequestHistory.ChangedDeviceTwin' | translate }}</span>
              {{deviceSetting.changed}}
            </div>
            <div *ngIf="deviceSetting.reason" class="margin-left-12">
              <span class="bold">{{ 'UpgradeRequestHistory.Reason' | translate }}</span> {{deviceSetting.reason}}
            </div>
            <div *ngIf="deviceSetting.currentState !== undefined">
              <div *ngIf="deviceSetting.isCurrentStateBoolean">
                <span class="bold">{{ 'UpgradeRequestHistory.Value' | translate }}</span> {{deviceSetting.currentState}}
              </div>
              <div *ngIf="!deviceSetting.isCurrentStateBoolean">
                <div *ngFor="let item of deviceSetting.currentState | keyvalue">
                  <span class="bold">{{ tryGetTranslateKey([deviceSetting.identifier, item.key]) | translate }}:</span>
                  <div *ngIf="isObject(item.value); else settingItemValue">
                    <div *ngFor="let subItem of item.value | keyvalue">
                      <span class="bold">{{ tryGetTranslateKey([deviceSetting.identifier, item.key, subItem.key]) |
                        translate }}:</span>
                      {{subItem.value}}
                    </div>
                  </div>
                  <ng-template #settingItemValue>{{item.value}}</ng-template>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div *ngIf="row.firmware" class="value-group">
        <span class="bold">{{ firmwareTranslateKey | translate }}</span> {{row.firmware.identifier |
        version}}
        <div><span class="bold">{{ 'UpgradeRequestHistory.ChangedDeviceTwin' | translate }}</span>
          {{row.firmware.changed}}<span *ngIf="row.firmware.reason">,
            <span class="bold">Reason:</span> {{row.firmware.reason}}</span></div>
      </div>
      <div *ngIf="row.assays" class="value-group">
        <span class="bold">{{ 'UpgradeRequestHistory.Assays' | translate }}<span
            *ngIf="row.forceAssayDowngrade">&nbsp;{{ 'UpgradeRequestHistory.Forced' | translate }}</span></span>
        <ol>
          <li *ngFor="let assay of row.assays;">
            <div><span class="bold">{{ 'UpgradeRequestHistory.Code' | translate }}</span> {{assay.identifier.code}},
              <span class="bold">{{ 'UpgradeRequestHistory.InterfaceVersion' | translate }}</span>
              {{assay.identifier.interfaceVersion}}, <span class="bold">{{ 'UpgradeRequestHistory.Version' | translate
                }}</span>
              {{assay.identifier.version | version}}
            </div>
            <div><span class="bold">{{ 'UpgradeRequestHistory.ChangedDeviceTwin' | translate }}</span>
              {{assay.changed}}<span *ngIf="assay.reason">,
                <span class="bold">{{ 'UpgradeRequestHistory.Reason' | translate }}</span> {{assay.reason}}</span></div>
          </li>
        </ol>
      </div>
      <div *ngIf="row.languages" class="value-group">
        <span class="bold">{{ 'UpgradeRequestHistory.Languages' | translate }}</span>
        <ol>
          <li *ngFor="let language of row.languages">
            <div><span class="bold">{{ 'UpgradeRequestHistory.Code' | translate }}</span> {{language.identifier.code}},
              <span class="bold">{{ 'UpgradeRequestHistory.InterfaceVersion' | translate }}</span>
              {{language.identifier.interfaceVersion}}, <span class="bold">{{ 'UpgradeRequestHistory.Version' |
                translate }}</span>
              {{language.identifier.version | version}}
            </div>
            <div><span class="bold">{{ 'UpgradeRequestHistory.ChangedDeviceTwin' | translate }}</span>
              {{language.changed}}<span *ngIf="language.reason">,
                <span class="bold">{{ 'UpgradeRequestHistory.Reason' | translate }}</span> {{language.reason}}</span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { InstrumentGroupDialogComponent } from '../instrument-group-dialog/instrument-group-dialog.component';
import { DataService, GroupHelperService, SelectionAndCacheService, NotificationService, UserPermissionService, InstrumentTypeSelectionState } from 'src/app/services';
import { InstrumentGroupsResult, ListMode, InstrumentGroupsRequest, AccessLevel, UserPermission, InstrumentGroup, ConfirmationDialogData, InstrumentType, InstrumentGroupDialogData, ConfirmationDialogResponse } from 'src/app/models';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';
import { InstrumentGroupListRequest } from 'src/app/components/instrument-group-list/instrument-group-list.component';

@Component({
  selector: 'app-instrument-groups',
  templateUrl: './instrument-groups.component.html',
  styleUrls: ['./instrument-groups.component.scss']
})
export class InstrumentGroupsComponent implements OnInit, OnDestroy {
  public instrumentGroupsResult: InstrumentGroupsResult;
  public instrumentTypes: InstrumentType[] = [];
  public ListMode = ListMode;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;

  private subscription: Subscription = new Subscription();
  private currentInstrumentGroupRequest: InstrumentGroupListRequest;

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private groupHelperService: GroupHelperService,
    private selectionAndCacheService: SelectionAndCacheService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$
        .subscribe((selectionState: InstrumentTypeSelectionState) => {
          if (selectionState.instrumentType) {
            this.getAllData();
          }
          this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstrumentGroups], [UserPermission.ViewEverything], this.selectionAndCacheService.selectedInstrumentType?.instrumentTypeId);
        }));
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async addInstrumentGroup(): Promise<void> {
    await this.showEditDialog(undefined);
  }

  public async onEditInstrumentGroup(instrumentGroup: InstrumentGroup): Promise<void> {
    await this.showEditDialog(instrumentGroup);
  }

  public async onDeleteInstrumentGroup(instrumentGroup: InstrumentGroup): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.InstrumentGroupKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.InstrumentGroupKey, instrumentGroup.name)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteInstrumentGroup(instrumentGroup.instrumentGroupId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.InstrumentGroupKey });
        this.getAllData();
      }));
    }
  }

  public getAllData(): void {
    const instrumentGroupsRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(undefined, this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId);

    this.subscription.add(
      forkJoin([
        this.dataService.getInstrumentGroups(instrumentGroupsRequest),
        this.userPermissionService.isReadyEvent$.pipe(take(1))
      ]).subscribe((results: [InstrumentGroupsResult, boolean]) => {
        this.instrumentGroupsResult = results[0];
        this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
      }));
  }

  public dataRequested($event: InstrumentGroupListRequest): void {
    this.currentInstrumentGroupRequest = $event;
    this.getInstrumentGroupsWithCurrentSearchState();
  }


  private getInstrumentGroupsWithCurrentSearchState(): void {
    const instrumentGroupsRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId);

    this.subscription.add(
      this.dataService.getInstrumentGroups(instrumentGroupsRequest)
        .subscribe((instrumentGroupsResult: InstrumentGroupsResult) => {
          this.instrumentGroupsResult = instrumentGroupsResult;
        }));
  }

  private async showEditDialog(instrumentGroup?: InstrumentGroup): Promise<void> {
    if (instrumentGroup) {
      const options: InstrumentGroupDialogData = {
        instrumentGroup: instrumentGroup
      };

      const dialogRef = this.dialog.open(InstrumentGroupDialogComponent, {
        width: '75%',
        disableClose: true,
        data: options
      });

      await firstValueFrom(dialogRef.afterClosed());
      this.getAllData();

    } else {
      const newInstrumentGroup: InstrumentGroup = {
        instrumentGroupId: undefined,
        instrumentTypeId: 0,
        devicesCount: undefined,
        usersCount: undefined,
        name: '',
        notes: ''
      };

      const options: InstrumentGroupDialogData = {
        instrumentGroup: newInstrumentGroup
      };

      const dialogRef = this.dialog.open(InstrumentGroupDialogComponent, {
        width: '75%',
        disableClose: true,
        data: options
      });

      await firstValueFrom(dialogRef.afterClosed());
      this.getAllData();
    }
  }
}

import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UrlConstants } from './constants/url-constants';
import { LanguageInterfaceVersionsComponent } from './views/language-interface-versions/language-interface-versions.component';
import { AssayInterfaceVersionsComponent } from './views/assay-interface-versions/assay-interface-versions.component';
import { AssayFilesComponent } from './views/assay-files/assay-files.component';
import { LanguageFilesComponent } from './views/language-files/language-files.component';
import { AssayDefinitionsComponent } from './views/assay-definitions/assay-definitions.component';
import { LanguageDefinitionsComponent } from './views/language-definitions/language-definitions.component';
import { FirmwareFilesComponent } from './views/firmware-files/firmware-files.component';
import { InstrumentsComponent } from './views/instruments/instruments.component';
import { UnregisteredInstrumentsComponent } from './views/unregistered-instruments/unregistered-instruments.component';
import { UpgradeInstrumentsComponent } from './views/upgrade-instruments/upgrade-instruments.component';
import { InstrumentGroupsComponent } from './views/instrument-groups/instrument-groups.component';
import { ReportsComponent } from './views/reports/reports.component';
import { PermissionGuard } from './guards/permission.guard';
import { CertificateRefreshComponent } from './views/certificate-refresh/certificate-refresh.component';
import { DeleteFilesComponent } from './views/delete-files/delete-files.component';
import { InstrumentManualApprovalComponent } from './views/instrument-manual-approval/instrument-manual-approval.component';
import { AccessLevel, UserPermission } from './models';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { SerialNumberOverridesComponent } from './views/serialnumber-overrides/serialnumber-overrides.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: UrlConstants.instrumentsUrl,
    pathMatch: 'full'
  },
  {
    path: UrlConstants.languageVersionsUrl,
    component: LanguageInterfaceVersionsComponent
  },
  {
    path: UrlConstants.languageDefinitionsUrl,
    component: LanguageDefinitionsComponent
  },
  {
    path: UrlConstants.languageFilesUrl,
    component: LanguageFilesComponent
  },
  {
    path: UrlConstants.assayVersionsUrl,
    component: AssayInterfaceVersionsComponent
  },
  {
    path: UrlConstants.assayDefinitionsUrl,
    component: AssayDefinitionsComponent
  },
  {
    path: UrlConstants.assayFilesUrl,
    component: AssayFilesComponent
  },
  {
    path: UrlConstants.firmwareFilesUrl,
    component: FirmwareFilesComponent
  },
  {
    path: UrlConstants.instrumentsUrl,
    component: InstrumentsComponent
  },
  {
    path: UrlConstants.instrumentsDeepLinkUrl,
    component: InstrumentsComponent
  },
  {
    path: UrlConstants.unregisteredInstrumentsUrl,
    component: UnregisteredInstrumentsComponent
  },
  {
    path: UrlConstants.upgradeInstrumentsUrl,
    component: UpgradeInstrumentsComponent
  },
  {
    path: UrlConstants.instrumentGroupsUrl,
    component: InstrumentGroupsComponent
  },
  {
    path: UrlConstants.reportsUrl,
    component: ReportsComponent,
    canActivate: [() => inject(PermissionGuard).canActivate([UserPermission.ManageAllInstruments], AccessLevel.AddUpdate)]
  },
  {
    path: UrlConstants.certificateRefreshUrl,
    component: CertificateRefreshComponent,
    canActivate: [() => inject(PermissionGuard).canActivate([UserPermission.ManageAllInstruments], AccessLevel.AddUpdate)]
  },
  {
    path: UrlConstants.manualApprovalUrl,
    component: InstrumentManualApprovalComponent,
    canActivate: [() => inject(PermissionGuard).canActivate([UserPermission.InstrumentManualApproval], AccessLevel.AddUpdate)]
  },
  {
    path: UrlConstants.userManagementUrl,
    component: UserManagementComponent,
    canActivate: [() => inject(PermissionGuard).canActivate([UserPermission.ManageUsers], AccessLevel.AddUpdate)]
  },
  {
    path: UrlConstants.serialNumberOverridesUrl,
    component: SerialNumberOverridesComponent,
    canActivate: [() => inject(PermissionGuard).canActivate([UserPermission.ManageAllInstruments], AccessLevel.AddUpdate)]
  },
  {
    path: UrlConstants.deleteFilesUrl,
    component: DeleteFilesComponent
  },
  {
    path: '**',
    redirectTo: UrlConstants.instrumentsUrl,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="smaller-padding">
  <div class="dialog-header">
    <h2>{{ 'Instruments.FileBrowser' | translate }}</h2>
    <button class="close-button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="main-content">
    <div class="flex">
      <button mat-icon-button [disabled]="selectedFoldersStack.length === 0" (click)="upOneDirectory()">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <span class="sub-header">{{ 'FileBrowser.CurrentFolderPath' | translate }}
        <strong>{{currentFolderPathDisplay}}</strong></span>
    </div>
    <div class="action-bar">
      <mat-form-field class="quick-select">
        <mat-label>{{ 'FileBrowser.QuickSelect' | translate }}</mat-label>
        <mat-select>
          <mat-option *ngFor="let folderPath of allowlistFolderPaths" [value]="folderPath"
            (onSelectionChange)="folderPathQuickSelect($event)">{{folderPath}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="search">
        <mat-label>{{ 'FileBrowser.SearchText' | translate }}</mat-label>
        <input #searchTextInput matInput type="text" [value]="searchText" [formControl]="searchControl"
          (keydown)="searchKeyDown($event)">
        <input type="hidden" [matDatepicker]="searchTextPicker" (dateChange)="dateChanged($event.value)">
        <mat-datepicker-toggle *ngIf="showSearchTextDatePicker" matIconSuffix
          [for]="searchTextPicker"></mat-datepicker-toggle>
        <mat-datepicker #searchTextPicker></mat-datepicker>
        <button *ngIf="searchText" matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-icon-button class="refresh" (click)="refreshFolder()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="row content">
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="nameFieldName" matSortDirection="asc"
        matSortDisableClear="true" (matSortChange)="sort($event)">
        <ng-container [matColumnDef]="multiselectFieldName">
          <th mat-header-cell *matHeaderCellDef disabled mat-sort-header>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="multi-select" [disabled]="currentPageUploadableCount === 0">
              <div>
                <mat-icon *ngIf="allCheckedFolderEntriesArrayInternal.length > 0">
                  indeterminate_check_box</mat-icon>
                <mat-icon *ngIf="allCheckedFolderEntriesArrayInternal.length <= 0">check_box_outline_blank</mat-icon>
                <mat-icon>arrow_drop_down</mat-icon>
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <button [disabled]="maxUploadRequestsReached" class="context-option" mat-menu-item
                (click)="checkCurrentPage()">
                <mat-icon [class.transparent]="!isCurrentPageChecked()"> check</mat-icon>
                {{ 'FileBrowser.Selection.CurrentPage' | translate: {pageCount: currentPageUploadableCount | number}
                }}
              </button>
              <button class="context-option" mat-menu-item (click)="uncheckAll()">
                <mat-icon [class.transparent]="allCheckedFolderEntriesArrayInternal.length > 0">check</mat-icon>
                {{ 'FileBrowser.Selection.Clear' | translate }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let folderEntry">
            <input *ngIf="folderEntry.authorized && folderEntry.type === 'file' && folderEntry.downloadAllowed
            && (!maxUploadRequestsReached || isChecked(folderEntry))" type="checkbox"
              [checked]="isChecked(folderEntry)" (change)="selectionChanged($event, folderEntry)">
          </td>
        </ng-container>
        <ng-container [matColumnDef]="nameFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'FileBrowser.Name' | translate }}</th>
          <td mat-cell *matCellDef="let folderEntry" (click)="changeDirectory(folderEntry)"
            [class.pointer]="folderEntry.type === 'folder' && folderEntry.authorized"><mat-icon
              class="entry-type">{{folderEntry.type === 'file' ?
              'insert_drive_file' : folderEntry.type}}</mat-icon>{{folderEntry.name}}</td>
        </ng-container>
        <ng-container [matColumnDef]="sizeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'FileBrowser.Size' | translate }}</th>
          <td mat-cell *matCellDef="let folderEntry" (click)="changeDirectory(folderEntry)"
            [class.pointer]="folderEntry.type === 'folder' && folderEntry.authorized">{{ folderEntry.type ===
            'file' ? ( folderEntry.size | bytes : {unitSeparator: ' '} ) : ''}}</td>
        </ng-container>
        <ng-container [matColumnDef]="lastModifiedDateFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'FileBrowser.LastModifiedDate' | translate }}
          </th>
          <td mat-cell *matCellDef="let folderEntry" (click)="changeDirectory(folderEntry)"
            [class.pointer]="folderEntry.type === 'folder' && folderEntry.authorized">
            {{folderEntry.lastModifiedDate}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let folderEntry; columns: columnsToUse"
          [class.descendant-uploadable]="folderEntry.descendantInAllowlist && folderEntry.type === 'folder'"
          [class.unauthorized]="!folderEntry.authorized">
        </tr>
      </table>
      <div class="no-data" *ngIf="!this.dataSource.data || this.dataSource.data.length === 0">
        {{ 'FileBrowser.NoContent' | translate }}
      </div>
    </div>

    <div class="row footer">
      <button mat-icon-button (click)="requestSelectedEntriesBeUploaded()"
        [disabled]="allCheckedFolderEntriesArrayInternal.length <= 0" [matTooltip]="'Global.UploadToCloud' | translate">
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <div class="upload-selection-count">
        <div *ngIf="allCheckedFolderEntriesArrayInternal.length === 1">
          {{ 'FileBrowser.Selection.UploadEntryCount' | translate }}</div>
        <div *ngIf="allCheckedFolderEntriesArrayInternal.length > 1">
          {{ 'FileBrowser.Selection.UploadEntriesCount' | translate : {count:
          allCheckedFolderEntriesArrayInternal.length} }}</div>
        <div *ngIf="maxUploadRequestsReached"> {{ 'FileBrowser.Selection.MaxUploadRequestsReached' | translate }}
        </div>
      </div>
      <div class="legend-item">
        <div class="legend-color descendant-uploadable"></div>
        <div class="legend-text">{{ 'FileBrowser.Legend.DescendantUploadable' | translate }}</div>
      </div>
      <div class="legend-item">
        <div class="legend-color unauthorized"></div>
        <div class="legend-text unauthorized-text">{{ 'FileBrowser.Legend.Unauthorized' | translate }}</div>
      </div>

      <mat-paginator [length]="totalEntries" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="[100, 250, 500]" showFirstLastButtons (page)="getPageData($event)"></mat-paginator>
    </div>
  </div>
</div>

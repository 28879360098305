<div class="container">
  <div class="container-grid">
    <h1>{{ 'UserManagement.UserManagement' | translate }}</h1>
    <div class="action-bar">
      <mat-form-field>
        <mat-label>{{ 'UserManagement.Search.DisplayName' | translate }}</mat-label>
        <input matInput type="text" [value]="displayNameSearchInput" [formControl]="displayNameSearchControl">
        <button matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
          (click)="clearDisplayNameSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div>
        <button mat-icon-button class="refresh" (click)="refreshUsers()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort [matSortActive]="displayNameFieldName" matSortDirection="asc"
        matSortDisableClear="true" (matSortChange)="sort($event)">
        <ng-container [matColumnDef]="displayNameFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UserManagement.DisplayName' | translate }}</th>
          <td mat-cell *matCellDef="let user"> {{user.displayName}} </td>
        </ng-container>
        <ng-container [matColumnDef]="userNameFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UserManagement.UserName' | translate }}</th>
          <td mat-cell *matCellDef="let user">{{user.userName}}</td>
        </ng-container>
        <ng-container [matColumnDef]="assignedClaimsFieldName">
          <th mat-header-cell *matHeaderCellDef>{{ 'UserManagement.AssignedClaims' | translate }}</th>
          <td mat-cell *matCellDef="let user">
            <mat-chip-listbox>
              <div [matTooltip]="user.globalClaimsToolTip" matTooltipClass="multiline-tooltip">
                <mat-chip-option class="claims-counts" disabled>Global:
                  {{user.globalClaims?.length ?? '0'}}
                </mat-chip-option>
              </div>
              <div *ngFor="let instrumentTypeClaims of user.claimsToolTipByInstrumentType | keyvalue"
                [matTooltip]="instrumentTypeClaims.value.claimsToolTip" matTooltipClass="multiline-tooltip">
                <mat-chip-option class="claims-counts" disabled
                  [ngClass]="instrumentTypeClaims.value.cssClassName">{{instrumentTypeClaims.key}} :
                  {{instrumentTypeClaims.value.claimsCount}} </mat-chip-option>
              </div>
            </mat-chip-listbox>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="actionsFieldName">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <div class="actions-container">
              <button mat-icon-button (click)="manageUser(user)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToUse"></tr>
      </table>
    </div>
    <div class="results-info">
      <button *ngIf="nextPageLink" mat-raised-button color="primary"
        [attr.aria-label]="'UserManagement.LoadMore' | translate" (click)="loadMoreUsers()">{{ 'UserManagement.LoadMore'
        | translate }}</button>
      {{ 'UserManagement.NumUsersShowing' | translate: { usersCount: dataSource.data.length, totalUsersCount:
      totalNumberOfUsers} }}
    </div>
  </div>
</div>

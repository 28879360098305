import { DeviceHelperService } from './../../services/helpers/device-helper.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { firstValueFrom, forkJoin, Observable, of, Subscription, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InstrumentDialogComponent } from 'src/app/views/instrument-dialog/instrument-dialog.component';
import { UserPermissionService, DataService, NotificationService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import { DeviceInformationResult, InstrumentType, AccessLevel, DeviceDataRequest, UserPermission, DeviceInformation, InstrumentDialogData, SearchRequest, ConfirmationDialogData, ConfirmationDialogResponse, InstrumentTypeModel } from 'src/app/models';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { Location } from '@angular/common';
import { UrlConstants } from 'src/app/constants/url-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate } from 'src/app/shared/translateServiceHelper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss']
})
export class InstrumentsComponent implements OnInit, OnDestroy {
  public deviceInformationResult: DeviceInformationResult;

  private subscription: Subscription = new Subscription();
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private currentDeviceDataRequested: DeviceDataRequest;
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private selectedModels: InstrumentTypeModel[];

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private urlSerializer: UrlSerializer,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private deviceHelperService: DeviceHelperService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {

      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.selectedInstrumentType = selectionState.instrumentType;
        // Changing the InstrumentType causes the DeviceListComponent to request data
        this.selectedModels = selectionState.models;
        this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);
      }));

    }));


    this.getAllData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public deviceDataRequested($event: DeviceDataRequest): void {
    this.currentDeviceDataRequested = $event;
    this.getDevicesWithCurrentSearchState();
  }

  public async onAddInstrument(): Promise<void> {
    await this.showInstrumentDialog();
  }

  public async onEditInstrument(deviceInformation: DeviceInformation): Promise<void> {
    await this.showInstrumentDialog(deviceInformation);
  }

  public refreshHealthData(deviceInformation: DeviceInformation): void {
    this.subscription.add(this.dataService.getDeviceInformationById(deviceInformation.deviceInformationId).subscribe((deviceInformationResult) => {
      deviceInformation.lastHeartbeatDateTime = deviceInformationResult.lastHeartbeatDateTime;
      deviceInformation.lastHeartbeatIsHealthy = deviceInformationResult.lastHeartbeatIsHealthy;
      deviceInformation.lastHeartbeatStatus = deviceInformationResult.lastHeartbeatStatus;
    }));
  }

  private async showInstrumentDialog(deviceInformation?: DeviceInformation): Promise<void> {
    if (deviceInformation?.deviceInformationId > 0) {
      this.location.replaceState(this.urlSerializer.serialize(this.router.createUrlTree([UrlConstants.instrumentsUrl, deviceInformation.serialNumber])));

      this.subscription.add(this.dataService.getDeviceInformationById(deviceInformation.deviceInformationId).subscribe(async (fullDeviceInformation: DeviceInformation) => {
        const options: InstrumentDialogData = {
          deviceInformation: fullDeviceInformation
        };

        const dialogRef = this.dialog.open(InstrumentDialogComponent, {
          data: options,
          width: '75%',
          disableClose: true
        });

        await firstValueFrom(dialogRef.afterClosed());

        this.location.replaceState(this.urlSerializer.serialize(this.router.createUrlTree([UrlConstants.instrumentsUrl])));

        this.getDevicesWithCurrentSearchState();
      }));
    } else {
      const newDeviceInformation: DeviceInformation = this.deviceHelperService.getInitialDeviceInformation();

      const options: InstrumentDialogData = {
        deviceInformation: newDeviceInformation
      };

      const dialogRef = this.dialog.open(InstrumentDialogComponent, {
        data: options,
        width: '75%',
        disableClose: true
      });

      await firstValueFrom(dialogRef.afterClosed());

      this.getDevicesWithCurrentSearchState();
    }
  }

  public async onDeleteInstrument($deviceInformation: DeviceInformation): Promise<void> {

    const dialogOptions: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.InstrumentKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.InstrumentKey, $deviceInformation.serialNumber)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: dialogOptions
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteDeviceInformation($deviceInformation.deviceInformationId)
        .subscribe(() => {
          this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.InstrumentKey });
          this.getDevicesWithCurrentSearchState();
        }));
    }
  }

  private getDevicesWithCurrentSearchState(): void {
    const searchRequest: SearchRequest = this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType?.instrumentTypeId, this.selectedModels?.map(m => m.modelId));

    this.subscription.add(this.dataService.getDeviceInformation(searchRequest)
      .subscribe((result: DeviceInformationResult) => {
        this.deviceInformationResult = result;
      }));
  }

  private getAllData(): void {
    let instrumentDeepLinkTask: Observable<DeviceInformation | undefined> = of(undefined);
    this.subscription.add(this.route.params.subscribe(params => {
      if (params[UrlConstants.serialNumberParamId]) {
        const serialNumber = params[UrlConstants.serialNumberParamId] as string;

        instrumentDeepLinkTask = this.dataService.getDeviceInformationBySerialNumber(serialNumber, true);
      }
    }));

    const tasks: [Observable<boolean>, Observable<DeviceInformation | undefined>] = [this.selectionAndCacheService.isReadyEvent$.pipe(take(1)), instrumentDeepLinkTask];
    this.subscription.add(forkJoin(tasks).subscribe(([ready, deviceInformation]) => {
      if (deviceInformation) {
        void this.showInstrumentDialog(deviceInformation);
      }
    }));
  }
}

import { TranslateConstants } from '../constants/translate-constants';

export enum PublishedFlag {
  Unknown = 'Unknown',
  Unpublished = 'Unpublished',
  PendingPublish = 'PendingPublish',
  Published = 'Published',
  PreviousPublished = 'PreviousPublished'
}

export enum FileState {
  Current = 'Current',
  Requested = 'Requested'
}

export const PublishedFlagTranslateKeyMap: Map<PublishedFlag, string> = new Map<PublishedFlag, string>([
  [PublishedFlag.Unknown, TranslateConstants.PublishedFlagUnknownKey],
  [PublishedFlag.Unpublished, TranslateConstants.PublishedFlagUnpublishedKey],
  [PublishedFlag.PendingPublish, TranslateConstants.PublishedFlagPendingPublishKey],
  [PublishedFlag.Published, TranslateConstants.PublishedFlagPublishedKey],
  [PublishedFlag.PreviousPublished, TranslateConstants.PublishedFlagPreviouslyPublishedKey]
]);

export enum ValidationErrorType {
  GeneralError = 0,
  AssayInterfaceFirmwareMatch = 1,
  CountryNotExist = 2,
  FirmwareNotExist = 3,
  InvalidProperty = 4,
  LanguageInterfaceFirmwareMatch = 5,
  InstrumentTypeFirmwareMatch = 6,
  FirmwareVersionAlreadyExists = 7,
  AssayNotSupported = 8,
  LanguageNotSupported = 9,
  AssayVersionAlreadyExists = 10,
  LanguageVersionAlreadyExists = 11
}

export enum UserPermission {
  ViewEverything = 'ViewEverything',
  ManageFirmwareFiles = 'ManageFirmwareFiles',
  ManageAssayFiles = 'ManageAssayFiles',
  ManageLanguageFiles = 'ManageLanguageFiles',
  ManageAllInstruments = 'ManageAllInstruments',
  ManageAllInstrumentGroups = 'ManageAllInstrumentGroups',
  UpdateAllInstruments = 'UpdateAllInstruments',
  UpdateSingleInstrument = 'UpdateSingleInstrument',
  GetFilesFromInstrument = 'GetFilesFromInstrument',
  InstrumentManualApproval = 'InstrumentManualApproval',
  ManageUsers = 'ManageUsers',
  ViewAuditLogs = 'ViewAuditLogs',
  ManageAuditLogs = 'ManageAuditLogs',
  ManageAllowlistFolderPaths = 'ManageAllowlistFolderPaths',
  SysAdmin = 'SysAdmin',
  ManageSingleInstrument = 'ManageSingleInstrument'
}

export const GlobalUserPermissions: UserPermission[] = [
  UserPermission.ViewEverything,
  UserPermission.ManageUsers,
  UserPermission.ViewAuditLogs,
  UserPermission.ManageAuditLogs,
  UserPermission.SysAdmin
];

// Permissions that can't be added/removed, they are controlled by AD groups
export const AutomaticUserPermissions: UserPermission[] = [
  UserPermission.ViewEverything,
  UserPermission.ManageUsers
];

export enum AccessLevel {
  Unauthorized = 'Unauthorized',
  ViewOnly = 'ViewOnly',
  AddUpdate = 'AddUpdate'
}

export enum DeviceInformationConnectionsState {
  Unknown = 'Unknown',
  Connected = 'Connected',
  Disconnected = 'Disconnected'
}

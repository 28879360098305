import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MatchedConfirmationModalInput {
  cancelText: string;
  confirmText: string;
  message: string;
  title: string;
  valueToMatch: string;
}

@Component({
  selector: 'core-matched-confirmation-modal',
  templateUrl: './matched-confirmation-modal.component.html',
  styleUrls: ['./matched-confirmation-modal.component.scss']
})
export class MatchedConfirmationModalComponent {

  enteredValue: string;
  yesButtonDisabled = true;

  constructor(
    public dialogRef: MatDialogRef<MatchedConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatchedConfirmationModalInput,
  ) { }

  onYesClick(): void {
    if (this.enteredValue == this.data.valueToMatch) {     
      this.dialogRef.close(true);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  enteredValue_Changed(newEnteredValue: string): void {
    if (this.enteredValue == this.data.valueToMatch) {
      this.yesButtonDisabled = false;
    }
    else {
      this.yesButtonDisabled = true;
    }
  }

}

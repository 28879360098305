import { Component, Inject, OnDestroy } from '@angular/core';
import { Subscription, firstValueFrom, take } from 'rxjs';
import { AutomaticUserPermissions, ConfirmationDialogData, ConfirmationDialogResponse, User, UserPermission } from 'src/app/models';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder } from '@angular/forms';
import { SelectionAndCacheService, UserManagementService } from 'src/app/services';
import { AddClaimsDialogComponent, AddClaimsDialogResult } from './add-claims-dialog/add-claims-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate } from 'src/app/shared/translateServiceHelper';

export class UserManagementDialogData {
  user: User;
}

class ClaimsForInstrumentType {
  instrumentTypeId: number;
  instrumentTypeName: string;
  claims: UserPermission[];
}

@Component({
  selector: 'app-user-management-dialog',
  templateUrl: './user-management-dialog.component.html',
  styleUrls: ['./user-management-dialog.component.scss']
})
export class UserManagementDialogComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();

  public displayNameFieldName = 'displayName';
  public userNameFieldName = 'userName';
  public userIdFieldName = 'userId';
  public globalClaimsFieldName = 'globalClaims';
  public instrumentTypeClaimsFieldName = 'instrumentTypeClaims';
  public claimsFieldName = 'claims';

  private currentUser: User;

  /* eslint-disable @typescript-eslint/unbound-method */
  public editUserForm = this.fb.group({
    userId: [{ value: '', disabled: true }, []],
    userName: [{ value: '', disabled: true }, []],
    displayName: [{ value: '', disabled: true }, []],
    globalClaims: this.fb.array<UserPermission>([]),
    instrumentTypeClaims: this.fb.array<ClaimsForInstrumentType>([])
  });
  /* eslint-enable @typescript-eslint/unbound-method */

  get globalClaims(): FormArray {
    return this.editUserForm.get(this.globalClaimsFieldName) as FormArray;
  }

  get instrumentTypeClaims(): FormArray {
    return this.editUserForm.get(this.instrumentTypeClaimsFieldName) as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: UserManagementDialogData,
    private fb: FormBuilder,
    private userManagementService: UserManagementService,
    private mdDialogRef: MatDialogRef<UserManagementDialogData>,
    private selectionAndCacheService: SelectionAndCacheService,
    private dialog: MatDialog,
    private translateService: TranslateService) {
    this.updateFormFromUser(dialogData.user);
    this.subscription.add(this.mdDialogRef.beforeClosed().pipe(take(1))
      .subscribe(() => mdDialogRef.close(this.currentUser)));
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public refresh(): void {
    this.subscription.add(this.userManagementService.getUser(this.editUserForm.get(this.userIdFieldName).value as string)
      .subscribe((user: User) => {
        this.updateFormFromUser(user);
      }));
  }

  public async onSaveUser(close: boolean = false): Promise<void> {
    let hasAtLeastOneClaim = false;
    const claimsByInstrumentType = {};
    this.editUserForm.value.instrumentTypeClaims.forEach(claimsForInstrumentType => {
      claimsByInstrumentType[claimsForInstrumentType.instrumentTypeId] = claimsForInstrumentType.claims;
      if (!hasAtLeastOneClaim && claimsForInstrumentType.claims.some(c => !AutomaticUserPermissions.includes(c))) {
        hasAtLeastOneClaim = true;
      }
    });

    if (!hasAtLeastOneClaim) {
      hasAtLeastOneClaim = this.editUserForm.value.globalClaims.some(c => !AutomaticUserPermissions.includes(c));
    }


    let refreshedUser: User;
    if (hasAtLeastOneClaim) {
      const user: User = {
        userId: this.editUserForm.get(this.userIdFieldName).value as string, // have to get control instead of using value cause it's disabled in the form group
        userName: this.editUserForm.get(this.userNameFieldName).value as string, // have to get control instead of using value cause it's disabled in the form group
        displayName: this.editUserForm.get(this.displayNameFieldName).value as string, // have to get control instead of using value cause it's disabled in the form group
        globalClaims: this.editUserForm.value.globalClaims,
        claimsByInstrumentTypeId: claimsByInstrumentType
      };

      refreshedUser = await firstValueFrom(this.userManagementService.updateUserClaims(user));
    } else {
      // Save needs to "Clear All Claims" behind the scenes
      await firstValueFrom(this.userManagementService.clearUserClaims(this.currentUser.userId));
      refreshedUser = await firstValueFrom(this.userManagementService.getUser(this.editUserForm.get(this.userIdFieldName).value as string));
    }


    if (close) {
      this.currentUser = refreshedUser;
      this.mdDialogRef.close(); // the before close subscription ensures that the `this.currentUser` value is what's returned
    } else {
      this.updateFormFromUser(refreshedUser);
    }
  }

  public getClaimsForInstrumentType(instrumentTypeIndex: number): FormArray {
    return this.instrumentTypeClaims
      .at(instrumentTypeIndex)
      .get(this.claimsFieldName) as FormArray;
  }

  public async addClaims(): Promise<void> {
    const dialogRef = this.dialog.open<AddClaimsDialogComponent>(AddClaimsDialogComponent, {
      width: '50%',
      disableClose: true
    });
    const result = await firstValueFrom(dialogRef.afterClosed()) as AddClaimsDialogResult | 'false';
    if (result && result !== 'false') {
      result.selectedGlobalClaims.forEach(globalClaimToAdd => {
        if (!this.currentUser.globalClaims) {
          this.currentUser.globalClaims = [];
        }

        if (!this.currentUser.globalClaims.includes(globalClaimToAdd)) {
          this.currentUser.globalClaims.push(globalClaimToAdd);
        }
      });

      result.selectedInstrumentTypes.forEach(instrumentType => {

        let claimsForInstrumentTypeId = this.currentUser.claimsByInstrumentTypeId[instrumentType.instrumentTypeId];

        if (!claimsForInstrumentTypeId) {
          claimsForInstrumentTypeId = [];
        }

        result.selectedInstrumentTypeClaims.forEach(instrumentTypeClaimToAdd => {
          if (!claimsForInstrumentTypeId.includes(instrumentTypeClaimToAdd)) {
            claimsForInstrumentTypeId.push(instrumentTypeClaimToAdd);
          }
        });

        this.currentUser.claimsByInstrumentTypeId[instrumentType.instrumentTypeId] = claimsForInstrumentTypeId;
      });
      this.updateFormFromUser(this.currentUser);
    }
  }

  public canDeleteInstrumentTypeClaim(instrumentTypeIndex: number, claimIndex: number): boolean {
    const claimsFormsArray = this.getClaimsForInstrumentType(instrumentTypeIndex);
    return this.canDeleteClaim(claimsFormsArray.at(claimIndex).value as UserPermission);
  }

  public deleteInstrumentTypeClaim(instrumentTypeIndex: number, claimIndex: number): void {
    const claimsFormsArray = this.getClaimsForInstrumentType(instrumentTypeIndex);
    claimsFormsArray.removeAt(claimIndex);
  }

  public canDeleteGlobalClaim(claimIndex: number): boolean {
    const claimsFormsArray = this.globalClaims;
    return this.canDeleteClaim(claimsFormsArray.at(claimIndex).value as UserPermission);
  }

  public deleteGlobalClaim(claimIndex: number): void {
    const claimsFormsArray = this.globalClaims;
    claimsFormsArray.removeAt(claimIndex);
  }

  private canDeleteClaim(claim: UserPermission): boolean {
    return !AutomaticUserPermissions.includes(claim);
  }

  public async clearAllClaims(): Promise<void> {

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ClearAllClaimsTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.YesKey),
      message: translate(this.translateService, TranslateConstants.ClearAllClaimsWarningKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());
    if (response.result) {
      await firstValueFrom(this.userManagementService.clearUserClaims(this.currentUser.userId));
      this.refresh();
    }
  }

  private updateFormFromUser(user: User) {
    this.currentUser = user;

    this.editUserForm.patchValue({
      userId: user.userId,
      userName: user.userName,
      displayName: user.displayName,
    });

    const globalClaimsFormArray = this.globalClaims;
    globalClaimsFormArray.clear();
    const sortedGlobalClaims = user.globalClaims?.sort((a, b) => a.localeCompare(b));
    sortedGlobalClaims?.forEach(globalClaim => {
      globalClaimsFormArray.push(this.fb.control(globalClaim));
    });

    const instrumentTypeClaimsFormArray = this.instrumentTypeClaims;
    instrumentTypeClaimsFormArray.clear();

    const instrumentTypeClaims: ClaimsForInstrumentType[] = [];
    Object.keys(user.claimsByInstrumentTypeId).forEach((instrumentTypeIdKey) => {
      const instrumentTypeId = +instrumentTypeIdKey;
      const instrumentTypeName = this.selectionAndCacheService.instrumentTypes.find(it => it.instrumentTypeId == instrumentTypeId)?.name;
      instrumentTypeClaims.push({
        instrumentTypeId,
        instrumentTypeName,
        claims: user.claimsByInstrumentTypeId[instrumentTypeId]
      });
    });

    instrumentTypeClaims.sort((a, b) => a.instrumentTypeName.localeCompare(b.instrumentTypeName))
      .forEach(claimsForInstrumentType => {
        instrumentTypeClaimsFormArray.push(this.fb.group({
          instrumentTypeName: this.fb.control({ value: claimsForInstrumentType.instrumentTypeName, disabled: true }),
          instrumentTypeId: claimsForInstrumentType.instrumentTypeId,
          claims: this.fb.array(claimsForInstrumentType.claims.sort((a, b) => a.localeCompare(b)))
        }));
      });
  }
}

import { PublishedFlag } from './enum.models';
import { Version } from './version.model';

export interface LanguageDefinition {
  languageDefinitionId: number;
  code: string;
  definitionName: string;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface LanguageInterface {
  languageInterfaceId: number;
  version: number;
  instrumentTypeId: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface Language {
  languageId: number;
  lnItemNumber: string;
  title: string;
  notes: string;
  version: Version;
  instrumentFileTypeId: number;
  languageInterfaceId: number;
  languageDefinitionId: number;
  instrumentTypeId: number;
  modelIds: number[];
  mD5: string;
  publishedFlag: PublishedFlag;
  fileUri: string;
  firmwareIds: number[];
  countryIds: number[];
  interfaceVersion?: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  devicesCount: number;
  header?: string;
}

export interface LanguageForm extends Language {
  major?: number;
  minor?: number;
  revision?: number;
}

export interface LanguageIdentifier{
  code: string;
  version: Version;
  interfaceVersion: number;
}

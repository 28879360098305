import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, filter, firstValueFrom, map, tap } from 'rxjs';
import { SupportedUserSetting, UserSetting } from '../models';
import { preventBusyIndicatorHeaders } from '../interceptors/loading.interceptor';
import { InactivityService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private initialized = false;
  private userSettingsByKey = new Map<string, UserSetting>();

  private apiUrl = this.configurationService.getApiUrl();
  private userSettingsUrl = '/UserSetting';
  private getUserSettingsUrl = '/GetUserSettings';
  private addUserSettingUrl = '/AddUserSetting';


  // returns false until initialized
  private _isReadySubject = new BehaviorSubject(false);

  public get isReadyEvent$(): Observable<boolean> {
    return this._isReadySubject
      .pipe(filter(ready => ready === true));
  }

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService) { }

  public async initializeUserSettings(): Promise<void> {
    if (this.initialized) {
      console.debug('UserSettingsService already initialized.');
      return;
    }

    const userSettings = await firstValueFrom(this.loadUserSettings());

    for (const userSetting of userSettings) {
      this.userSettingsByKey.set(userSetting.userSettingKey, userSetting);
    }

    this.initialized = true;

    this._isReadySubject.next(true);
  }

  public tryGetUserSetting(userSettingKey: string): UserSetting | undefined {
    return this.userSettingsByKey.get(userSettingKey);
  }

  public updateUserSetting(userSettingsKey: string, userSettingData: SupportedUserSetting, preventBusy = false): Observable<boolean> {
    let userSetting = this.userSettingsByKey.get(userSettingsKey);
    if (userSetting) {
      userSetting.settingsParsed = userSettingData;
      userSetting.settings = JSON.stringify(userSettingData);
    } else {
      userSetting = {
        userSettingKey: userSettingsKey,
        settingsParsed: userSettingData,
        settings: JSON.stringify(userSettingData)
      };
    }
    this.userSettingsByKey.set(userSetting.userSettingKey, userSetting);

    const query = `${this.apiUrl}${this.userSettingsUrl}${this.addUserSettingUrl}`;
    return this.httpClient.post<boolean>(query, userSetting, { headers: preventBusy ? preventBusyIndicatorHeaders : null })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  private loadUserSettings(): Observable<UserSetting[]> {
    const query = `${this.apiUrl}${this.userSettingsUrl}${this.getUserSettingsUrl}`;

    return this.httpClient.get<UserSetting[]>(query)
      .pipe(map(userSettings => {
        userSettings.forEach(userSetting => {
          userSetting.settingsParsed = JSON.parse(userSetting.settings) as SupportedUserSetting;
        });
        return userSettings;
      }));
  }
}

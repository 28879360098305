import { Configuration as MsalConfiguration } from '@azure/msal-browser';

export interface ApiEndpoint {
  uri: string;
  status: string;
}

export interface Configuration {
  version: string;
  production: boolean;
  apiEndpoint: ApiEndpoint;
  msal: MsalConfiguration;
  protectedResourceMap: [string, string[]][];
  appInsightsKey: string;
}

export interface ApiHealthResult {
  status: string;
  version: string;
}

export interface Features {
  FilterHttpCommunication: boolean;

  /* There are additional flags for features by instrument type. They can be accessed utilizing FeatureFlagConstants and isFeatureEnabledForInstrumentTypeName function, for example: */

  // chosenInstrumentType.name is 'Savanna/Sofia2/Vision/etc...'
  // isFeatureEnabledForInstrumentTypeName(this.features, chosenInstrumentType.name, FeatureFlagConstants.FWUpgradeFromVersionsVerification)

  // Some examples:
  // Savanna_AMFManagement: boolean;
  // Savanna_DownloadSettings: boolean;
  // Savanna_FWManagement: boolean;
  // Savanna_InstrumentLogFiles: boolean;
  // Savanna_LanguageManagement: boolean;
  // Savanna_RetrySettings: boolean;
  // etc...
}

export interface ClientConfig {
  inactivityTimeoutInMinutes: number;
  features: Features;
  instrumentSettingDefaultsJsonByKey: { [key: string]: string };
  disableIoTConnectionConfirmationCode?: string;
  fileBrowserBasePathByInstrumentType: { [key: string]: string };
  fileSignatureVerificationKeyNamesByFileTypeAndThenByInstrumentType: { [key: string]: { [key: string]: string[] } };
}

<div class="app-container" *ngIf="!isIframe">
  <ng-container *ngIf="isAuthenticated && isAuthorized">
    <header>
      <div class="menu-container">
        <button mat-icon-button class="menu-button" (click)="sidenav.toggle()">
          <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
        </button>
        <a routerLink="/" class="quidel-logo">
          <img src="/assets/images/qo_logo_stk_tm_vlt_rgb.png">
        </a>
        <span class="app-title noselect">{{ 'Global.ApplicationTitle' | translate }}</span>
      </div>
      <div class="user-container" fxFlex fxLayoutAlign="flex-end">

        <div class="logout-icon-container">
          <button mat-icon-button [matMenuTriggerFor]="auth">
            <div class="admin-icon-top">
              <img src="/assets/images/User logo.png" />
            </div>
            <mat-menu #auth="matMenu">
              <button mat-menu-item (click)="showSettings()">
                <mat-icon>settings</mat-icon>
                <span>{{ 'App.User.Settings' | translate }}</span>
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{ 'App.User.SignOut' | translate }}</span>
              </button>
              <button mat-menu-item (click)="showAbout()">
                <mat-icon>info</mat-icon>
                <span>{{ 'App.User.About' | translate }}</span>
              </button>
            </mat-menu>
          </button>
          <div class="admin-icon-bottom" matTooltip="{{userDisplayName}}">
            {{userDisplayName}}
          </div>
        </div>
      </div>
    </header>

    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav autoFocus="false" mode="over" [(opened)]="isMenuOpen" class="sidenav"
        (click)="onSidenavClick()">
        <a mat-button *ngIf="isAMFManagement" routerLink="/{{UrlConstants.assayDefinitionsUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.AssayDefinitions' | translate }}</a>
        <a mat-button *ngIf="isAMFManagement" routerLink="/{{UrlConstants.assayVersionsUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.AssayInterfaceVersions' | translate }}</a>
        <a mat-button *ngIf="isAMFManagement" routerLink="/{{UrlConstants.assayFilesUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.AssayFiles' | translate }}</a>
        <a mat-button *ngIf="isFWManagement" routerLink="/{{UrlConstants.firmwareFilesUrl}}"
          routerLinkActive="active-nav-item">{{ firmwareFilesTranslateKey | translate }}</a>
        <a mat-button *ngIf="isLanguageManagement" routerLink="/{{UrlConstants.languageDefinitionsUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.LanguageDefinitions' | translate }}</a>
        <a mat-button *ngIf="isLanguageManagement" routerLink="/{{UrlConstants.languageVersionsUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.LanguageInterfaceVersions' | translate }}</a>
        <a mat-button *ngIf="isLanguageManagement" routerLink="/{{UrlConstants.languageFilesUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.LanguageFiles' | translate }}</a>
        <a mat-button *ngIf="isActiveUser" routerLink="/{{UrlConstants.instrumentsUrl}}"
          routerLinkActive="active-nav-item">{{
          'App.Nav.Instruments' | translate }}</a>
        <a mat-button *ngIf="isActiveUser" routerLink="/{{UrlConstants.unregisteredInstrumentsUrl}}"
          routerLinkActive="active-nav-item">{{
          'App.Nav.UnregisteredInstruments' | translate }}</a>
        <a mat-button *ngIf="canUpgradeInstruments" routerLink="/{{UrlConstants.upgradeInstrumentsUrl}}"
          routerLinkActive="active-nav-item">{{ upgradeInstrumentsTranslateKey | translate }}</a>
        <a mat-button *ngIf="isActiveUser" routerLink="/{{UrlConstants.instrumentGroupsUrl}}"
          routerLinkActive="active-nav-item">{{
          'App.Nav.InstrumentGroups' | translate }}</a>
        <a mat-button *ngIf="isAMFDeletion || isLangDeletion" routerLink="/{{UrlConstants.deleteFilesUrl}}"
          routerLinkActive="active-nav-item">{{ 'App.Nav.DeleteFiles' | translate }}</a>
        <a mat-button *ngIf="isActiveUser && canViewReports" routerLink="/{{UrlConstants.reportsUrl}}"
          routerLinkActive="active-nav-item"> {{ 'App.Nav.Reports' | translate }}</a>
        <a mat-button *ngIf="canRefeshDeviceCertificates" routerLink="/{{UrlConstants.certificateRefreshUrl}}"
          routerLinkActive="active-nav-item"> {{ 'App.Nav.RefreshDeviceCertificates' | translate }}</a>
        <a mat-button *ngIf="canAccessManualApprovals" routerLink="/{{UrlConstants.manualApprovalUrl}}"
          routerLinkActive="active-nav-item"> {{ 'App.Nav.ManualApproval' | translate }}</a>
        <a mat-button *ngIf="canAccessUserManagement" routerLink="/{{UrlConstants.userManagementUrl}}"
          routerLinkActive="active-nav-item"> {{ 'App.Nav.UserManagement' | translate }}</a>
        <a mat-button *ngIf="canManageSerialNumberOverrides" routerLink="/{{UrlConstants.serialNumberOverridesUrl}}"
          routerLinkActive="active-nav-item"> {{ 'App.Nav.SerialNumberOverrides' | translate }}</a>
      </mat-sidenav>
      <mat-sidenav-content>
        <main>
          <app-instrument-type-selector [showSelectors]="showInstrumentTypeSelectors"
            [hideModelsSelection]="hideInstrumentTypeModelSelector"></app-instrument-type-selector>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
  <div class="errors" *ngIf="apiNotFound || !isAuthenticated || !isAuthorized || thirdPartyCookies">
    <div class="logo"></div>
    <div class="details" *ngIf="thirdPartyCookies">
      {{ 'App.Errors.ThirdPartyCookies' | translate }}</div>
    <div *ngIf="!apiNotFound">
      <div *ngIf="!isAuthenticated">
        {{ 'App.Errors.Auth.Header' | translate }}
        <div class="details" *ngIf="!arePopupsBlocked">
          {{ 'App.Errors.Auth.RefreshPageDetails' | translate }}
        </div>
        <div class="details" *ngIf="arePopupsBlocked">
          {{ 'App.Errors.Auth.PopupsBlockedDetails' | translate }}
        </div>
      </div>
      <div *ngIf="isAuthenticated && !isAuthorized">
        {{unauthorizedTitleTranslationKey | translate }}
        <div class="details">
          {{unauthorizedMessageTranslationKey | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="apiNotFound">
      {{ 'App.Errors.ApiNotFound.Header' | translate }}
      <div class="details">
        {{ 'App.Errors.ApiNotFound.Details' | translate }}
      </div>
    </div>
  </div>
</div>
<ngx-spinner size="large" type="ball-atom"></ngx-spinner>

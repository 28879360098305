export class FeatureFlagConstants {
  public static readonly AMFManagementSuffix = 'AMFManagement';
  public static readonly ClinicalModeSettingsSuffix = 'ClinicalModeSettings';
  public static readonly DownloadSettingsSuffix = 'DownloadSettings';
  public static readonly DownloadSettings_Software = 'DownloadSettings_Software';
  public static readonly FWManagementSuffix = 'FWManagement';
  public static readonly InstrumentLogFilesSuffix = 'InstrumentLogFiles';
  public static readonly LanguageManagementSuffix = 'LanguageManagement';
  public static readonly RetrySettingsSuffix = 'RetrySettings';
  public static readonly TestResultsUploadSuffix = 'TestResultsUpload';
  public static readonly PerformanceDataSettingSuffix = 'PerformanceDataSetting';
  public static readonly UpgradeInstrumentSuffix = 'UpgradeInstrument';
  public static readonly FWSignatureVerification = 'FWSignatureVerification';
  public static readonly AMFSignatureVerification = 'AMFSignatureVerification';
  public static readonly LanguageSignatureVerification = 'LanguageSignatureVerification';
  public static readonly FWUpgradeFromVersionsVerification = 'FWUpgradeFromVersionsVerification';
  public static readonly TestResultsUpload_DeleteAfterUploadOption = 'TestResultsUpload_DeleteAfterUploadOption';
  public static readonly C2DMessage = 'C2DMessage';
  public static readonly C2D_UpdateUserList = 'C2D_UpdateUserList';
  public static readonly C2D_DownloadFile = 'C2D_DownloadFile';
  public static readonly LutUpload = 'LutUpload';
  public static readonly AMFAllowForceDowngrade = 'AMFAllowForceDowngrade';
  public static readonly AMFDeletion = 'AMFDeletion';
  public static readonly LangDeletion = 'LangDeletion';
  public static readonly VirenaEnabledToggleable = 'VirenaEnabledToggleable';
  public static readonly C2D_RequestLogs = 'C2D_RequestLogs';
  public static readonly AllowlistFolderPaths = 'AllowlistFolderPaths';
  public static readonly SupportsFileBrowser = 'SupportsFileBrowser';
  public static readonly AllowDisableIoT = 'AllowDisableIoT';
  public static readonly ManualApproval = 'ManualApproval';
  public static readonly VirenaSettings = 'VirenaSettings';
  public static readonly ManualFileUpload = 'ManualFileUpload';
  public static readonly SupportsAwsDestinationFolderOverride = 'SupportsAwsDestinationFolderOverride';
  public static readonly ShowDigiDeviceStatus = 'ShowDigiStatus';
  public static readonly DisplayMyVirenaStatus = 'DisplayMyVirenaStatus';
  public static readonly ShowVerizonStatus = 'ShowVerizonStatus';
  public static readonly DisplayShippingInformation = 'DisplayShippingInformation';

  // The flags below are *not* InstrumentType feature flags
  public static readonly GlobalResultsTransmission = 'GlobalResultsTransmission';
}

import { UserPermission } from './enum.models';
import { InstrumentType } from './instrument.models';

export class UsersResult {
  totalResults?: number;
  users: User[];
  nextPageLink?: string;
}

export class User {
  // Make sure that any new fields are handled in the UserWithClaimsTooltips constructor
  userId: string;
  userName: string;
  displayName: string;
  globalClaims: UserPermission[];
  claimsByInstrumentTypeId: { [instrumentTypeCode: number]: UserPermission[] };
}

export class UserWithClaimsTooltips extends User {

  public globalClaimsToolTip: string;
  public claimsToolTipByInstrumentType: { [instrumentTypeName: string]: { claimsToolTip: string, claimsCount: number, cssClassName: string } };
  constructor(user: User, instrumentTypes: InstrumentType[]) {
    super();

    this.userId = user.userId;
    this.userName = user.userName;
    this.displayName = user.displayName;
    this.claimsByInstrumentTypeId = user.claimsByInstrumentTypeId;
    this.globalClaims = user.globalClaims;

    if (user.globalClaims?.length > 0) {
      this.globalClaimsToolTip = user.globalClaims?.sort((a, b) => a.localeCompare(b)).join(', ');
    } else {
      this.globalClaimsToolTip = 'No Claims';
    }

    this.claimsToolTipByInstrumentType = {};
    Object.keys(user.claimsByInstrumentTypeId).forEach((instrumentTypeIdKey: string) => {
      const instrumentTypeId = +instrumentTypeIdKey;
      const instrumentType = instrumentTypes.find(it => it.instrumentTypeId == instrumentTypeId);
      if (instrumentType) {
        const claimsForInstrumentTypeId = user.claimsByInstrumentTypeId[instrumentTypeId];
        const cssClassName = instrumentType.name.replace(' ', '');
        if (claimsForInstrumentTypeId?.length > 0) {
          this.claimsToolTipByInstrumentType[instrumentType.name] = {
            claimsCount: claimsForInstrumentTypeId.length,
            claimsToolTip: user.claimsByInstrumentTypeId[instrumentTypeId]?.sort((a, b) => a.localeCompare(b)).join(', '),
            cssClassName
          };
        } else {
          this.claimsToolTipByInstrumentType[instrumentType.name] = {
            claimsCount: 0,
            claimsToolTip: 'No Claims',
            cssClassName
          };
        }
      }
    });
  }
}

export interface UserPermissionWithScope {
  userPermissionClaim: UserPermission;
  isGlobal: boolean;
  instrumentTypeId?: number;
}

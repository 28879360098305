import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { LanguageUserSetting, UserSettingsConstants } from 'src/app/models';
import { NotificationService, UserSettingsService } from 'src/app/services';
import { sortValueStringAscOrder } from 'src/app/shared/keyvalue';
import { getActiveLanguage } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss']
})
export class SettingsDialogComponent implements OnInit, OnDestroy {

  public supportedLanguages = TranslateConstants.SupportedLanguagesMap;
  public sortSupportLanguages = sortValueStringAscOrder;

  public settingsForm = this.fb.group(
    {
      appLanguage: [TranslateConstants.DefaultLanguage]
    });

  private subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private userSettingsService: UserSettingsService,
    private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.settingsForm.patchValue({ appLanguage: getActiveLanguage(this.translateService) });

    this.subscription.add(this.settingsForm.valueChanges.subscribe(settings => {

      if (getActiveLanguage(this.translateService) != settings.appLanguage) {
        this.translateService.use(settings.appLanguage);
        const languageSetting: LanguageUserSetting = {
          languageOverride: settings.appLanguage
        };
        this.subscription.add(this.userSettingsService.updateUserSetting(UserSettingsConstants.Language, languageSetting)
          .subscribe(success => {
            if (success) {
              this.notificationService.success(TranslateConstants.UserSettingsLanguageUpdateSuccessKey);
            } else {
              this.notificationService.error(TranslateConstants.UserSettingsLanguageUpdateFailedKey);
            }
          }));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}


import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, firstValueFrom, forkJoin, Subscription, take } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogData, UploadDialogComponent, UploadDialogResponse } from 'src/app/components/upload-dialog/upload-dialog.component';
import { enumToArray, isFeatureEnabledForInstrumentTypeName, nameof } from 'src/app/shared/utils';
import { UserPermissionService, DataService, NotificationService, FileHeaderService, ConfigurationService, SelectionAndCacheService } from 'src/app/services';
import {
  FirmwareFileDialogData, PublishedFlag, Firmware, AssayDefinition, AssayInterface, InstrumentType, InstrumentFileType,
  Assay, LanguageInterface, Language, LanguageDefinition, AccessLevel, UserPermission, PublishedFlagTranslateKeyMap, ValidationResult,
  formatValidationResultForDisplay, BlobUpload, ConfirmationDialogData, FirmwareForm, Features, ConfirmationDialogResponse, Version, InstrumentTypeModel, InstrumentTypeId, ClientConfig, Country
} from 'src/app/models';
import { FormBuilder, Validators } from '@angular/forms';
import { forbiddenValueValidator } from 'src/app/shared/validators';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-firmware-file-dialog',
  templateUrl: './firmware-file-dialog.component.html',
  styleUrls: ['./firmware-file-dialog.component.scss']
})
export class FirmwareFileDialogComponent implements OnInit, OnDestroy {

  private static readonly assayInterfaceIdsFormFieldName = 'assayInterfaceIds';
  private static readonly assayIdsFormFieldName = 'assayIds';
  private static readonly languageInterfaceIdsFormFieldName = 'languageInterfaceIds';
  private static readonly languageIdsFormFieldName = 'languageIds';
  private static readonly compatibleFirmwareIdsFormFieldName = 'compatibleFirmwareIds';
  private static readonly countryIdsFormFieldName = 'countryIds';

  public PublishedFlag = PublishedFlag;
  public publishedFlags = enumToArray(PublishedFlag, 'Unknown');
  public assayDefinitions: AssayDefinition[] = [];
  public assayInterfaces: AssayInterface[] = [];
  public availableAssayInterfaces: AssayInterface[] = [];
  public availableFirmwares: Firmware[] = [];
  public instrumentTypes: InstrumentType[] = [];
  public signatureVerificationKeyNames: string[] = [];
  public modelsForSelectedInstrumentType: InstrumentTypeModel[] = [];
  public showModelSelection = false;
  public instrumentFileTypes: InstrumentFileType[] = [];
  public availableAssays: Assay[] = [];
  public languageInterfaces: LanguageInterface[] = [];
  public availableLanguageInterfaces: LanguageInterface[] = [];
  public availableLanguages: Language[] = [];
  public selectedTabIndex = 0;
  public fileAvailable = false;
  public signatureVerificationEnabled = false;
  public availableCountries: Country[] = [];

  public firmwareTypeTranslateKey: string = TranslateConstants.FirmwareKey;
  public requiresDependencies = true;
  public showCountries = false;
  public showLnItemNumber = false;

  /* eslint-disable @typescript-eslint/unbound-method */
  public editFirmwareForm = this.fb.group(
    {
      title: ['', [Validators.required, Validators.maxLength(300)]],
      lnItemNumber: ['', [Validators.required, Validators.maxLength(50)]],
      major: [0, [Validators.required, Validators.min(1)]],
      minor: [0, [Validators.required, Validators.min(0)]],
      revision: [0, [Validators.required, Validators.min(0)]],
      instrumentTypeId: [0, [forbiddenValueValidator(0)]],
      modelIds: [[], Validators.required],
      instrumentFileTypeId: [0, [forbiddenValueValidator(0)]],
      publishedFlag: ['', []],
      notes: ['', []],
      assayInterfaceIds: [new Array<number>(), []],
      assayIds: [new Array<number>(), []],
      languageInterfaceIds: [new Array<number>(), []],
      languageIds: [new Array<number>(), []],
      compatibleFirmwareIds: [new Array<number>(), []],
      countryIds: [new Array<number>(), [Validators.required]],
      signatureVerificationKeyName: ['', Validators.required] // gets enabled if the Signature Validation Feature Flag is enabled AND if a file has been chosen
    });
  /* eslint-enable @typescript-eslint/unbound-method */


  private instrumentFileTypeIdPropertyName = 'instrumentFileTypeId';
  private instrumentTypeIdPropertyName = 'instrumentTypeId';
  private modelIdsPropertyName = 'modelIds';
  private majorVersionPropertyName = 'major';
  private minorVersionPropertyName = 'minor';
  private revisionVersionPropertyName = 'revision';
  private signatureVerificationKeyNamePropertyName = 'signatureVerificationKeyName';

  private _firmware: Firmware;
  private subscription = new Subscription();
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  @ViewChild('file', { static: false }) private fileInput: ElementRef<HTMLInputElement>;
  private languageDefinitions: LanguageDefinition[];
  private features: Features;
  private clientConfiguration: ClientConfig;

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get compatibleFirmwareIds(): number[] {
    return this.editFirmwareForm.value.compatibleFirmwareIds;
  }

  get languageIds(): number[] {
    return this.editFirmwareForm.value.languageIds;
  }

  get languageInterfaceIds(): number[] {
    return this.editFirmwareForm.value.languageInterfaceIds;
  }

  get assayInterfaceIds(): number[] {
    return this.editFirmwareForm.value.assayInterfaceIds;
  }

  get assayIds(): number[] {
    return this.editFirmwareForm.value.assayIds;
  }

  get countryIds(): number[] {
    return this.editFirmwareForm.value.countryIds;
  }

  get firmware(): Firmware {
    return this._firmware;
  }

  set firmware(firmware: Firmware) {
    this._firmware = firmware;
    this.editFirmwareForm.patchValue({
      title: firmware.title,
      lnItemNumber: firmware.lnItemNumber,
      major: firmware.version.major,
      minor: firmware.version.minor,
      revision: firmware.version.revision,
      instrumentTypeId: firmware.instrumentTypeId,
      instrumentFileTypeId: firmware.instrumentFileTypeId,
      publishedFlag: firmware.publishedFlag,
      notes: firmware.notes,
      assayInterfaceIds: firmware.assayInterfaceIds,
      assayIds: firmware.assayIds,
      languageInterfaceIds: firmware.languageInterfaceIds,
      languageIds: firmware.languageIds,
      compatibleFirmwareIds: firmware.compatibleFirmwareIds,
      countryIds: firmware.countryIds ?? []
    });

    if (this.canAddUpdate) {
      this.disableOrEnableControlsByPublishedFlag();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FirmwareFileDialogData,
    private dataService: DataService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private mdDialogRef: MatDialogRef<FirmwareFileDialogComponent>,
    private userPermissionService: UserPermissionService,
    private fileHeaderService: FileHeaderService,
    private configurationService: ConfigurationService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) {
    this.firmware = data.firmware;
    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
    if (this.selectionAndCacheService.selectedInstrumentType) {
      this.firmware.instrumentTypeId = this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId;
      this.editFirmwareForm.patchValue({
        instrumentTypeId: this.firmware.instrumentTypeId
      });

      this.updateModelSelectionForInstrumentType();
      this.updateUIForSelectedInstrumentType();
    }
  }

  ngOnInit(): void {
    this.clientConfiguration = this.configurationService.getClientConfiguration();
    this.features = this.clientConfiguration?.features;

    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageFirmwareFiles], [UserPermission.ViewEverything], this.firmware.instrumentTypeId);
      if (!this.canAddUpdate) {
        this.editFirmwareForm.disable();
      } else {
        this.editFirmwareForm.enable();
        this.disableOrEnableControlsByPublishedFlag();
      }
    }));

    this.subscription.add(
      forkJoin([
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.dataService.getAssayInterfaces(),
        this.dataService.getLanguageInterfaces(),
        this.dataService.getAssayDefinitions(),
        this.dataService.getLanguageDefinitions()
      ])
        .subscribe((results: [boolean, AssayInterface[], LanguageInterface[], AssayDefinition[], LanguageDefinition[]]) => {
          this.instrumentFileTypes = this.selectionAndCacheService.instrumentFileTypes;
          this.availableCountries = this.selectionAndCacheService.countries;
          if (!this.firmware?.firmwareId && this.showCountries) {
            this.editFirmwareForm.patchValue({
              countryIds: this.availableCountries.map(c => c.countryId)
            });
          }

          this.assayInterfaces = results[1];
          this.languageInterfaces = results[2];
          this.assayDefinitions = results[3];
          this.languageDefinitions = results[4];

          this.updateSignatureVerificationKeyNamesForInstrumentType();

          this.onDependencyValueChanged(this.firmware.firmwareId,
            this.firmware.version.major,
            this.firmware.version.minor,
            this.firmware.version.revision,
            this.firmware.instrumentTypeId,
            this.firmware.instrumentFileTypeId,
            this.firmware.assayInterfaceIds,
            this.firmware.languageInterfaceIds);
        }));

    if (this.firmware?.firmwareId) {
      if (this.firmware?.publishedFlag === PublishedFlag.PendingPublish) {
        this.checkFileAvailability(this.firmware);
      } else if (this.firmware?.publishedFlag === PublishedFlag.Published) {
        this.fileAvailable = true;
      }

      this.editFirmwareForm.get(this.instrumentTypeIdPropertyName).disable();
      this.editFirmwareForm.get(this.modelIdsPropertyName).disable();
    }
    this.editFirmwareForm.get(this.signatureVerificationKeyNamePropertyName).disable();

    this.subscription.add(this.editFirmwareForm.get(this.instrumentTypeIdPropertyName).valueChanges
      .pipe(
        // https://stackoverflow.com/a/59678874/10752002
        debounceTime(100), // ensuring that on creation of this control we don't enable/disable until final results
        distinctUntilChanged()
      ).subscribe((instrumentTypeId: number) => {
        if (this.firmware.instrumentTypeId !== instrumentTypeId) {
          this.firmware.instrumentTypeId = instrumentTypeId;
          this.updateModelSelectionForInstrumentType(true);
          this.updateUIForSelectedInstrumentType();
          this.updateSignatureVerificationKeyNamesForInstrumentType();
        }
      }));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public noCompatibleCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ compatibleFirmwareIds: [] });
    }
  }


  public allCompatibleCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ compatibleFirmwareIds: this.getAllAvailableFirmwaresIds() });
    }
  }

  public noLanguageCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ languageIds: [] });
    }
  }

  public allLanguageCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ languageIds: this.getAllAvailableLanguagesIds() });
    }
  }

  public getValuesFromFormAndLoadAvailableLanguages(): void {
    const fileTypeId = this.editFirmwareForm.get(this.instrumentFileTypeIdPropertyName).value as number;
    const instrumentTypeId = this.firmware.instrumentTypeId;
    const languageInterfaceIds = this.editFirmwareForm.value.languageInterfaceIds;
    this.loadAvailableLanguages(fileTypeId, instrumentTypeId, languageInterfaceIds);
  }

  public noLanguageInterfacesCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ languageInterfaceIds: [] });
    }
    this.getValuesFromFormAndLoadAvailableLanguages();
  }

  public allLanguageInterfacesCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ languageInterfaceIds: this.getAllAvailableLanguageInterfacesIds() });
    }
    this.getValuesFromFormAndLoadAvailableLanguages();
  }

  public noAssaysCheckedChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ assayIds: [] });
    }
  }

  public allAssaysCheckedChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ assayIds: this.getAllAvailableAssayIds() });
    }
  }

  public getValuesFromFormAndLoadAvailableAssays(): void {
    const fileTypeId = this.editFirmwareForm.get(this.instrumentFileTypeIdPropertyName).value as number;
    const instrumentTypeId = this.firmware.instrumentTypeId;
    const assayInterfacesIds = this.editFirmwareForm.value.assayInterfaceIds;
    this.loadAvailableAssays(fileTypeId, instrumentTypeId, assayInterfacesIds);
  }

  public noAssayInterfacesCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ assayInterfaceIds: [] });
    }
    this.getValuesFromFormAndLoadAvailableAssays();
  }

  public allAssayInterfacesCheckChanged(value: boolean): void {
    if (value) {
      this.editFirmwareForm.patchValue({ assayInterfaceIds: this.getAllAvailableAssayInterfacesIds() });
    }
    this.getValuesFromFormAndLoadAvailableAssays();
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentFileTypeDisplayValue(instrumentFileTypeId: number): string {
    return this.instrumentFileTypes?.find(i => i.instrumentFileTypeId === instrumentFileTypeId)?.name;
  }

  public getPublishedFlagDisplayValue(flag: PublishedFlag): string {
    return translate(this.translateService, PublishedFlagTranslateKeyMap.get(flag));
  }

  public getAssayDefinitionCode(assayDefinitionId: number): string {
    const def = this.assayDefinitions?.find(def => def.assayDefinitionId == assayDefinitionId);
    return def ? def.code : '';
  }

  public getLanguageDefinitionCode(languageDefinitionId: number): string {
    const def = this.languageDefinitions?.find(def => def.languageDefinitionId == languageDefinitionId);
    return def ? def.code : '';
  }

  public getAssayInterfaceVersion(assayInterfaceVersionId: number): number {
    return this.assayInterfaces.find(ai => ai.assayInterfaceId === assayInterfaceVersionId)?.version;
  }

  public getLanguageInterfaceVersion(languageInterfaceVersionId: number): number {
    return this.languageInterfaces.find(li => li.languageInterfaceId === languageInterfaceVersionId)?.version;
  }


  public getAllAvailableAssayInterfacesIds(): number[] {
    return this.availableAssayInterfaces.map(x => x.assayInterfaceId);
  }

  public getAllAvailableLanguageInterfacesIds(): number[] {
    return this.availableLanguageInterfaces.map(x => x.languageInterfaceId);
  }

  public getAllAvailableAssayIds(): number[] {
    return this.availableAssays.map(x => x.assayId);
  }

  public getAllAvailableLanguagesIds(): number[] {
    return this.availableLanguages.map(x => x.languageId);
  }

  public getAllAvailableFirmwaresIds(): number[] {
    return this.availableFirmwares.map(x => x.firmwareId);
  }

  public isFirmwarePendingOrPublished(): boolean {
    return this.firmware?.publishedFlag === PublishedFlag.PendingPublish || this.firmware?.publishedFlag === PublishedFlag.Published;
  }

  public async saveFirmware(file: File, close: boolean = false): Promise<void> {
    const firmware = {
      ...this.firmware,
      ...this.editFirmwareForm.value
    } as FirmwareForm;

    delete firmware.major;
    delete firmware.minor;
    delete firmware.revision;

    firmware.version = {
      major: this.editFirmwareForm.get(nameof<Version>('major')).value as number,
      minor: this.editFirmwareForm.get(nameof<Version>('minor')).value as number,
      revision: this.editFirmwareForm.get(nameof<Version>('revision')).value as number
    };

    const chosenInstrumentType = this.instrumentTypes.find(it => it.instrumentTypeId === firmware.instrumentTypeId);
    const upgradeFromVersionsVerificationEnabled = isFeatureEnabledForInstrumentTypeName(this.features, chosenInstrumentType.name, FeatureFlagConstants.FWUpgradeFromVersionsVerification);

    if (file != undefined && file != null && (this.signatureVerificationEnabled || upgradeFromVersionsVerificationEnabled)) {
      try {

        let header: string;
        if (file.name.endsWith('.zip')) {
          // read LPF header from manifest file
          header = await this.fileHeaderService.readManifestFileHeaderFromZip(file, 'update/manifest.json');
        } else {
          // read LPF  header
          header = await this.fileHeaderService.readHeader(file);
        }

        if (this.signatureVerificationEnabled) {
          firmware.lpfHeader = header;
        }

        if (upgradeFromVersionsVerificationEnabled) {
          // read LUF Table of Contents
          const toc = await this.fileHeaderService.readTOC(file, header);
          firmware.lufTableOfContents = toc;
        }
      }
      catch (error) {
        // show error
        this.notificationService.errorExact(error as string);
        return;
      }
    }

    const signatureKeyName = this.editFirmwareForm.value.signatureVerificationKeyName;
    this.subscription.add(this.dataService.validateFirmware(firmware, file?.name, signatureKeyName).subscribe(async (validationResult: ValidationResult) => {
      if (!validationResult.isValid) {
        const errors = formatValidationResultForDisplay(this.translateService, validationResult);
        this.notificationService.errorExact(errors);
      } else {
        this.firmware = firmware;

        const options: UploadDialogData = {
          title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UploadingKey, this.firmwareTypeTranslateKey),
          observable: null
        };

        const isUpdate = this.firmware.firmwareId > 0;
        if (file) {
          this.subscription.add(this.dataService.getFirmwareBlobUploadUrl(file).subscribe(async (blobUpload: BlobUpload) => {
            options.observable = this.dataService.uploadBlob(blobUpload, file);
            const dialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
              data: options,
              disableClose: true
            });

            const uploadDialogResponse = await firstValueFrom(dialogRef.afterClosed());

            if (uploadDialogResponse.success) {
              await this.addOrUpdateFirmware(this.firmware, options, isUpdate, close, file.name, blobUpload.blobName);
            }
          }));
        } else {
          await this.addOrUpdateFirmware(this.firmware, options, isUpdate, close);
        }
      }
    }));
  }

  public async onPublishFirmware(file?: File): Promise<void> {
    if (this.firmware.publishedFlag === PublishedFlag.Unpublished) {
      const options: ConfirmationDialogData = {
        title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.PublishTitleKey, this.firmwareTypeTranslateKey),
        cancelText: translate(this.translateService, TranslateConstants.CancelKey),
        confirmText: translate(this.translateService, TranslateConstants.PublishKey),
        message: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.PublishWarningKey, this.firmwareTypeTranslateKey)
      };

      const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
        data: options
      });

      const response = await firstValueFrom(dialogRef.afterClosed());

      if (response.result) {
        const uploadOptions: UploadDialogData = {
          title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UploadingKey, this.firmwareTypeTranslateKey),
          observable: this.dataService.updateFirmware(this.firmware, null, null, null, true)
        };

        const uploadDialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
          data: uploadOptions,
          disableClose: true
        });

        const uploadResult = await firstValueFrom(uploadDialogRef.afterClosed());

        if (uploadResult.success) {
          this.subscription.add(this.dataService.publishFirmware(this.firmware.firmwareId)
            .subscribe(
              {
                complete: () => {
                  this.notificationService.success(TranslateConstants.PublishSuccessKey, { type: this.firmwareTypeTranslateKey });
                  this.refreshFirmware(this.firmware);
                },
                error: (e) => {
                  console.debug(e);
                }
              }));
        }
      }
    }
    else if (this.isFirmwarePendingOrPublished()) {

      const options: ConfirmationDialogData = {
        title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UnpublishTitleKey, this.firmwareTypeTranslateKey),
        cancelText: translate(this.translateService, TranslateConstants.CancelKey),
        confirmText: translate(this.translateService, TranslateConstants.UnpublishKey),
        message: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UnpublishWarningKey, this.firmwareTypeTranslateKey),
      };

      const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
        data: options
      });

      const response = await firstValueFrom(dialogRef.afterClosed());

      if (response.result) {
        this.subscription.add(this.dataService.updateFirmware(this.firmware)
          .subscribe(
            {
              next: (status: HttpEvent<unknown>) => {
                if (status.type === HttpEventType.Response) {
                  this.subscription.add(this.dataService.unpublishFirmware(this.firmware.firmwareId).subscribe(() => {
                    this.notificationService.success(TranslateConstants.UnpublishSuccessKey, { type: this.firmwareTypeTranslateKey });
                    this.refreshFirmware(this.firmware);
                  }));
                }
              },
              error: (e) => {
                console.debug(e);
              }
            }));
      }
    }
  }

  public getSelectedOptions(options: number[]): number[] {
    return options?.filter(n => n !== 0);
  }

  public getFileType(id: number): InstrumentFileType {
    return this.instrumentFileTypes.find(x => x.instrumentFileTypeId === id);
  }

  public getCountry(id: number): Country {
    return this.availableCountries.find(x => x.countryId === id);
  }

  public refreshFirmware(data: Firmware): void {
    this.subscription.add(this.dataService.getFirmwareById(data.firmwareId).subscribe((firmware) => {
      this.firmware = firmware;
    }));
  }

  public onDependencyValueChanged(firmwareId: number, major: number, minor: number, revision: number, instrumentTypeId?: number, instrumentFileTypeId?: number, assayInterfaceIds?: number[], languageInterfaceIds?: number[]): void {
    this.loadAvailableFirmwares(firmwareId, major, minor, revision, instrumentTypeId);
    this.loadAvailableAssayInterfaces(instrumentTypeId);
    this.loadAvailableLanguageInterfaces(instrumentTypeId);
    this.loadAvailableAssays(instrumentFileTypeId, instrumentTypeId, assayInterfaceIds);
    this.loadAvailableLanguages(instrumentFileTypeId, instrumentTypeId, languageInterfaceIds);
  }

  public checkFileAvailability(firmware: Firmware): void {
    if (!firmware.firmwareId) {
      this.fileAvailable = false;
      return;
    }

    this.subscription.add(this.dataService.finalizePublishFirmware(firmware.firmwareId)
      .subscribe(
        {
          next: (response) => {
            this.fileAvailable = (response as unknown) === 'true' ? true : false;
            if (this.fileAvailable) {
              this.subscription.add(this.dataService.getFirmwareById(firmware.firmwareId).subscribe((firmwareByIdResult) => {
                this.firmware.publishedFlag = firmwareByIdResult.publishedFlag;
              }));
            }
          },
          error: () => this.fileAvailable = false
        }));
  }

  public fileChanged(files: File[]): void {
    if (!files || files.length === 0) {
      return;
    }

    if (this.signatureVerificationEnabled) {
      this.editFirmwareForm.get(this.signatureVerificationKeyNamePropertyName).enable();
    } else {
      this.editFirmwareForm.get(this.signatureVerificationKeyNamePropertyName).disable();
    }
  }

  public selectAllCountries(): void {
    this.editFirmwareForm.patchValue({
      countryIds: this.availableCountries.map(c => c.countryId)
    });
  }

  public deselectAllCountries(): void {
    this.editFirmwareForm.patchValue({
      countryIds: []
    });
  }

  private loadAvailableAssays(instrumentFileTypeId: number, instrumentTypeId: number, interfaceIds: number[]): void {
    const sanitizedInterfaceIds = interfaceIds?.filter(i => i !== 0);

    if (!instrumentFileTypeId || !instrumentTypeId || !interfaceIds || sanitizedInterfaceIds?.length === 0) {
      this.availableAssays = [];
      return;
    }

    this.subscription.add(
      this.dataService.getFirmwareAvailableAssays(instrumentFileTypeId, instrumentTypeId, sanitizedInterfaceIds)
        .subscribe((assays: Assay[]) => {
          this.availableAssays = assays.sort((a, b) => a.title.localeCompare(b.title));
        }));
  }

  private loadAvailableLanguages(instrumentFileTypeId: number, instrumentTypeId: number, interfaceIds: number[]): void {
    if (!instrumentFileTypeId || !instrumentTypeId || !interfaceIds || interfaceIds?.length === 0) {
      this.availableLanguages = [];
      return;
    }

    this.subscription.add(
      this.dataService.getFirmwareAvailableLanguages(instrumentFileTypeId, instrumentTypeId, interfaceIds)
        .subscribe((languages: Language[]) => {
          this.availableLanguages = languages.sort((a, b) => a.title.localeCompare(b.title));
        }));
  }

  private loadAvailableFirmwares(firmwareId: number, major: number, minor: number, revision: number, instrumentTypeId: number): void {
    if (!instrumentTypeId) {
      this.availableFirmwares = [];
      return;
    }

    this.subscription.add(
      this.dataService.getFirmwareAvailableFirmwares(firmwareId, major, minor, revision, instrumentTypeId)
        .subscribe((firmwares: Firmware[]) => {
          this.availableFirmwares = firmwares;
        }));
  }

  private loadAvailableAssayInterfaces(instrumentTypeId: number): void {
    this.availableAssayInterfaces = this.assayInterfaces?.filter(i => i.instrumentTypeId === instrumentTypeId);
  }

  private loadAvailableLanguageInterfaces(instrumentTypeId: number): void {
    this.availableLanguageInterfaces = this.languageInterfaces?.filter(i => i.instrumentTypeId === instrumentTypeId);
  }

  private disableOrEnableControlsByPublishedFlag() {
    if (this.isFirmwarePendingOrPublished()
      || this.firmware?.publishedFlag === PublishedFlag.PreviousPublished) {
      this.editFirmwareForm.get(this.instrumentFileTypeIdPropertyName).disable();
      this.editFirmwareForm.get(this.majorVersionPropertyName).disable();
      this.editFirmwareForm.get(this.minorVersionPropertyName).disable();
      this.editFirmwareForm.get(this.revisionVersionPropertyName).disable();
    } else {
      this.editFirmwareForm.get(this.instrumentFileTypeIdPropertyName).enable();
      this.editFirmwareForm.get(this.majorVersionPropertyName).enable();
      this.editFirmwareForm.get(this.minorVersionPropertyName).enable();
      this.editFirmwareForm.get(this.revisionVersionPropertyName).enable();
    }
  }

  private async addOrUpdateFirmware(firmware: Firmware, dialogOptions: UploadDialogData, isUpdate: boolean, close: boolean, fileName?: string, blobName?: string): Promise<void> {

    const signatureKeyName = this.editFirmwareForm.value.signatureVerificationKeyName;
    if (isUpdate) {
      dialogOptions.observable = this.dataService.updateFirmware(firmware, fileName, blobName, signatureKeyName, true);
    } else {
      dialogOptions.observable = this.dataService.addFirmware(firmware, fileName, blobName, signatureKeyName, true);
    }
    const dialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
      data: dialogOptions,
      disableClose: true
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result.success) {
      if (isUpdate) {
        this.notificationService.success(TranslateConstants.UpdateSuccessKey, { type: this.firmwareTypeTranslateKey });
      } else {
        firmware.firmwareId = +result?.result;
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: this.firmwareTypeTranslateKey });
      }


      if (close) {
        this.close(false);
      } else {
        this.fileInput.nativeElement.value = null;
        this.refreshFirmware(firmware);
      }
    }
  }

  private updateModelSelectionForInstrumentType(clearModels = false) {
    this.modelsForSelectedInstrumentType = this.selectionAndCacheService.getModelsForInstrumentTypeId(this.firmware.instrumentTypeId);

    // If only one model exists, auto select it
    if (this.modelsForSelectedInstrumentType.length === 1) {
      this.showModelSelection = false;
      this.firmware.modelIds = [this.modelsForSelectedInstrumentType[0].modelId];
    } else {
      this.showModelSelection = true;
      if (clearModels) {
        // clearing selection of models if instrument type changes
        this.firmware.modelIds = [];
      }
    }
    const temp = {};
    temp[this.modelIdsPropertyName] = this.firmware.modelIds;
    this.editFirmwareForm.patchValue(temp);
  }

  private updateUIForSelectedInstrumentType() {
    if (this.firmware.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.requiresDependencies = false;
      this.showCountries = true;
      this.showLnItemNumber = false;
      // Creating a new mod file
      if (!this.firmware.firmwareId) {
        this.editFirmwareForm.patchValue({
          lnItemNumber: 'N/A'
        });
      }
      this.firmwareTypeTranslateKey = TranslateConstants.ModKey;

      // delaying disabling of form controls to ensure it truly takes effect
      setTimeout(() => {
        this.editFirmwareForm.get(FirmwareFileDialogComponent.countryIdsFormFieldName).enable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.assayInterfaceIdsFormFieldName).disable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.assayIdsFormFieldName).disable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.languageInterfaceIdsFormFieldName).disable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.languageIdsFormFieldName).disable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.compatibleFirmwareIdsFormFieldName).disable();
      });
    } else {
      // All Other Instrument Types
      this.requiresDependencies = true;
      this.showCountries = false;
      this.showLnItemNumber = true;
      this.firmwareTypeTranslateKey = TranslateConstants.FirmwareKey;

      // delaying disabling of form controls to ensure it truly takes effect
      setTimeout(() => {
        this.editFirmwareForm.get(FirmwareFileDialogComponent.countryIdsFormFieldName).disable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.assayInterfaceIdsFormFieldName).enable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.assayIdsFormFieldName).enable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.languageInterfaceIdsFormFieldName).enable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.languageIdsFormFieldName).enable();
        this.editFirmwareForm.get(FirmwareFileDialogComponent.compatibleFirmwareIdsFormFieldName).enable();
      });
    }
  }

  private updateSignatureVerificationKeyNamesForInstrumentType() {
    const instrumentType = this.instrumentTypes.find(it => it.instrumentTypeId === this.firmware.instrumentTypeId);
    let foundKeyNames: string[] = null;
    if (instrumentType) {
      this.signatureVerificationEnabled = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.FWSignatureVerification);

      const assaySignatureVerificationKeysByInstrumentType = this.clientConfiguration.fileSignatureVerificationKeyNamesByFileTypeAndThenByInstrumentType['firmware'];
      if (assaySignatureVerificationKeysByInstrumentType) {
        foundKeyNames = assaySignatureVerificationKeysByInstrumentType[instrumentType.code];
      }
    } else {
      this.signatureVerificationEnabled = false;
    }

    this.signatureVerificationKeyNames.length = 0;

    if (foundKeyNames?.length > 0) {
      this.signatureVerificationKeyNames = [...foundKeyNames];
    } else {
      this.signatureVerificationKeyNames.push('N/A');
    }
  }
}

<div class="dialog-root">
  <div class="dialog-header">
    <h2>
      <span *ngIf="canAddUpdate">{{ (instrumentGroup?.instrumentGroupId ? 'Global.Edit' : 'Global.New') | translate
        }}</span><span *ngIf="!canAddUpdate">{{ 'Global.View' | translate }}</span> {{
      'InstrumentGroups.InstrumentGroup' | translate }} {{ instrumentGroup?.instrumentGroupId ? ('Global.NameAndIdLabel'
      | translate: { name: instrumentGroup.name, id:instrumentGroup.instrumentGroupId }) : '' }}
    </h2>
    <button mat-icon-button *ngIf="instrumentGroup?.instrumentGroupId" class="refresh-dialog-btn"
      (click)="getAllData()">
      <mat-icon>refresh</mat-icon>
    </button>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="min-height">
    <div [hidden]="changeDevicesMode">
      <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
        <mat-tab [label]="'Global.Details' | translate">
          <div class="field-container">
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Name' | translate }} {{ instrumentGroup?.instrumentGroupId ?
                ('Global.ReadOnly' | translate) : '' }}</mat-label>
              <input matInput [readonly]="instrumentGroup?.instrumentGroupId" [placeholder]="'Global.Name' | translate"
                type="text" #title [(ngModel)]="instrumentGroup.name">
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
              <mat-select disabled [(ngModel)]="instrumentGroup.instrumentTypeId"
                (selectionChange)="onInstrumentTypeChange($event)" required>
                <mat-option [value]=0>{{ 'Instruments.Form.SelectInstrumentType' | translate }}</mat-option>
                <mat-option [value]="instrumentType.instrumentTypeId" *ngFor="let instrumentType of instrumentTypes">
                  {{ instrumentType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
              <textarea [disabled]="!canAddUpdate" matInput #notes [placeholder]="'Global.Notes' | translate"
                type="text" [(ngModel)]="instrumentGroup.notes"></textarea>
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab [label]="devicesTabText">
          <div *ngIf="instrumentGroup.instrumentTypeId === 0" class="instrumenttype-required">
            {{ 'InstrumentGroups.InstrumentTypeSelectionRequired' | translate }}
          </div>
          <div *ngIf="instrumentGroup.instrumentTypeId !== 0" class="device-list">
            <app-device-list [unpaginatedInstrumentGroupsArray]="groupDeviceInformations"
              [instrumentTypeId]="instrumentGroup?.instrumentTypeId" [mode]="ListMode.Filtered"
              [accessLevel]="accessLevel" (removeDeviceFromList)="removeDeviceFromGroup($event)"
              (refreshData)="getDevicesByGroupId()" [showViewInstrumentLink]="true">
            </app-device-list>
          </div>
        </mat-tab>

        <mat-tab *ngIf="showManageDeviceSettingsTab && instrumentGroup?.instrumentGroupId"
          [label]="manageDeviceSettingsTabText">
          <form [formGroup]="manageDeviceSettingsForm">
            <mat-accordion multi>
              <!--BEGIN VIRENA SETTINGS-->
              <mat-expansion-panel *ngIf="showVirenaSettings">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.VirenaSettings_FormKey)"
                  mat-raised-button color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.VirenaSettings_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.VirenaSettings_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.VirenaSettings_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.VirenaSettings_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]) |
                      translate
                      }}</mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle [formControlName]="DeviceSettingsConstants.VirenaSettings_OptIn_FormKey">
                        {{ 'Global.On' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey]) |
                      translate }}
                    </mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle [formControlName]="DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey">
                        {{ 'Global.On' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!--END VIRENA SETTINGS-->

              <!--BEGIN RETRY SETTINGS-->
              <mat-expansion-panel *ngIf="showRetrySettings">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.RetrySettings_FormKey)" mat-raised-button
                  color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.RetrySettings_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.RetrySettings_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.RetrySettings_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.RetrySettings_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field *ngIf="instrumentGroup.instrumentTypeId !== InstrumentTypeId.Vision.valueOf()"
                    class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey,
                      DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]) | translate"
                      required type="number"
                      [formControlName]="DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey">
                    <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
                      {min: DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Min,
                      max: DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Max} }}</mat-hint>
                    <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                      {min: DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Min,
                      max: DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Max} }}</mat-error>
                  </mat-form-field>
                  <div *ngIf="instrumentGroup.instrumentTypeId === InstrumentTypeId.Vision.valueOf()">
                    <app-time-form expectedTimeUnits="ms"
                      [minValue]="getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).minValue"
                      [maxValue]="getMinMaxValidationValuesFromEditForm([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).maxValue"
                      [inputValue]="manageDeviceSettingsForm.get([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).value"
                      (valueChanged)="manageDeviceSettingsForm.get([DeviceSettingsConstants.RetrySettings_FormKey,  DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).setValue($event)" />
                  </div>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey,
                      DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey]) | translate"
                      required type="number"
                      [formControlName]="DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey">
                    <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
                      {min: DeviceSettingsConstants.RetrySettings_NumberRetries_Min,
                      max: DeviceSettingsConstants.RetrySettings_NumberRetries_Max} }}</mat-hint>
                    <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                      {min: DeviceSettingsConstants.RetrySettings_NumberRetries_Min,
                      max: DeviceSettingsConstants.RetrySettings_NumberRetries_Max} }}</mat-error>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>

              <ng-template #retrySettingsTimeDelayTemplate>

              </ng-template>
              <!--END RETRY SETTINGS-->

              <!--BEGIN DOWNLOAD SETTINGS-->
              <mat-expansion-panel *ngIf="showDownloadSettings">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.DownloadSettings_FormKey)"
                  mat-raised-button color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.DownloadSettings_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.DownloadSettings_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.DownloadSettings_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.DownloadSettings_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
                      DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey]) | translate"
                      required type="number"
                      [formControlName]="DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey">
                    <mat-icon matPrefix *ngIf="showChunkDownloadTimeoutNotHonoredMessage"
                      [matTooltip]="chunkDownloadTimeoutNotHonoredMessage">info</mat-icon>
                    <mat-hint align="end">{{ 'DeviceSettings.MinMaxHintWithHrEquivalent' | translate:
                      {min: DeviceSettingsConstants.DownloadSettings_TimeoutInS_Min,
                      max: (DeviceSettingsConstants.DownloadSettings_TimeoutInS_Max | number),
                      hourEquivalent: DeviceSettingsConstants.DownloadSettings_TimeoutInS_MaxHrEquivalent}
                      }}</mat-hint>
                    <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                      {min: DeviceSettingsConstants.DownloadSettings_TimeoutInS_Min,
                      max: (DeviceSettingsConstants.DownloadSettings_TimeoutInS_Max | number)} }}</mat-error>
                  </mat-form-field>
                  <div *ngIf="showDownloadSettings_Software"
                    [formGroupName]="DeviceSettingsConstants.DownloadSettings_Software_FormKey">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
                      DeviceSettingsConstants.DownloadSettings_Software_FormKey]) | translate }}</mat-label>
                    <mat-form-field class="full-width">
                      <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
                        DeviceSettingsConstants.DownloadSettings_Software_FormKey,
                        DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey]) | translate }}
                      </mat-label>
                      <input matInput
                        [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_Software_FormKey, DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey]) | translate"
                        required type="number"
                        [formControlName]="DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_FormKey">
                      <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
                        {min: DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Min,
                        max: (DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Max | number)}
                        }}</mat-hint>
                      <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                        {min: DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Min,
                        max: (DeviceSettingsConstants.DownloadSettings_Software_MaxRetries_Max | number)}
                        }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                      <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey,
                        DeviceSettingsConstants.DownloadSettings_Software_FormKey,
                        DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey]) | translate }}
                      </mat-label>
                      <input matInput
                        [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_Software_FormKey, DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey]) | translate"
                        required type="number"
                        [formControlName]="DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_FormKey">
                      <mat-hint align="end">{{ 'DeviceSettings.MinMaxHintWithHrEquivalent' | translate:
                        {min: DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Min,
                        max: (DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Max | number),
                        hourEquivalent:
                        DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_MaxHrEquivalent}
                        }}</mat-hint>
                      <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                        {min: DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Min,
                        max: (DeviceSettingsConstants.DownloadSettings_Software_RetryTimeoutMinutes_Max | number)}
                        }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <!--END DOWNLOAD SETTINGS-->

              <!--BEGIN CLINICAL MODE-->
              <mat-expansion-panel *ngIf="showClinicalModeSettings">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.ClinicalMode_FormKey)" mat-raised-button
                  color="primary" (click)="enableFormGroupSettings(DeviceSettingsConstants.ClinicalMode_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.ClinicalMode_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.ClinicalMode_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.ClinicalMode_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey,
                      DeviceSettingsConstants.ClinicalMode_SaveClinicalImages_FormKey]) | translate }}</mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle
                        [formControlName]="DeviceSettingsConstants.ClinicalMode_SaveClinicalImages_FormKey">
                        {{ 'Global.On' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.ClinicalMode_FormKey,
                      DeviceSettingsConstants.ClinicalMode_ExportDataToUsb_FormKey]) | translate }}</mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle
                        [formControlName]="DeviceSettingsConstants.ClinicalMode_ExportDataToUsb_FormKey">
                        On</mat-slide-toggle>
                    </div>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!--END CLINICAL MODE-->

              <!--BEGIN TEST RESULTS UPLOAD-->
              <mat-expansion-panel *ngIf="showTestResultsUpload">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.TestResultsUpload_FormKey)"
                  mat-raised-button color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.TestResultsUpload_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.TestResultsUpload_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.TestResultsUpload_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.TestResultsUpload_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                      DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey]) | translate }}</mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle [formControlName]="DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey">
                        {{ 'Global.On' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                      DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey]) | translate"
                      type="text" required
                      [formControlName]="DeviceSettingsConstants.TestResultsUpload_AccessToken_FormKey">
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                      DeviceSettingsConstants.TestResultsUpload_Server_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_Server_FormKey]) | translate"
                      type="url" required [formControlName]="DeviceSettingsConstants.TestResultsUpload_Server_FormKey">
                    <mat-error>{{ 'DeviceSettings.TestResultsUpload.FormErrors.Server' | translate }}</mat-error>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                      DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey]) | translate"
                      type="text" required
                      [formControlName]="DeviceSettingsConstants.TestResultsUpload_ApiPath_FormKey">
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                      DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey]) | translate
                      }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey]) | translate"
                      required type="number"
                      [formControlName]="DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_FormKey">
                    <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
                      {min: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Min,
                      max: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Max} }}</mat-hint>
                    <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                      {min: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Min,
                      max: DeviceSettingsConstants.TestResultsUpload_UploadDelayMinutes_Max} }}</mat-error>
                  </mat-form-field>

                  <div *ngIf="showTestResultsUpload_DeleteAfterUpload" class="full-width flex">
                    <mat-form-field class="flex-item-fill">
                      <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.TestResultsUpload_FormKey,
                        DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey]) | translate
                        }}</mat-label>
                      <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                      <input matInput style="display: none" value=" ">
                      <div>
                        <mat-label class="leftside-slidetoggle-label">
                          {{ 'Global.Off' | translate }}</mat-label>
                        <mat-slide-toggle
                          [formControlName]="DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey">
                          {{ 'Global.On' | translate }}
                        </mat-slide-toggle>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <!--END TEST RESULTS UPLOAD-->

              <!--BEGIN PERFORMANCE DATA-->
              <mat-expansion-panel *ngIf="showPerformanceDataSettings">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.PerformanceData_FormKey)"
                  mat-raised-button color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.PerformanceData_FormKey, true)">
                  {{ 'Global.Add' | translate }}</button>
                <div *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.PerformanceData_FormKey)"
                  [formGroupName]="DeviceSettingsConstants.PerformanceData_FormKey">
                  <button mat-raised-button color="primary" class="remove-setting"
                    (click)="disableFormGroupSettings(DeviceSettingsConstants.PerformanceData_FormKey)">
                    {{ 'Global.Remove' | translate }}</button>
                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey,
                      DeviceSettingsConstants.PerformanceData_Enabled_FormKey]) | translate }}</mat-label>
                    <!--Display none and empty string for value ensures that the mat-label is positioned correctly above the controls-->
                    <input matInput style="display: none" value=" ">
                    <div>
                      <mat-label class="leftside-slidetoggle-label">
                        {{ 'Global.Off' | translate }}</mat-label>
                      <mat-slide-toggle [formControlName]="DeviceSettingsConstants.PerformanceData_Enabled_FormKey">
                        {{ 'Global.On' | translate }}
                      </mat-slide-toggle>
                    </div>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>{{ tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey,
                      DeviceSettingsConstants.PerformanceData_Verbosity_FormKey]) | translate }}</mat-label>
                    <input matInput
                      [placeholder]="tryGetTranslateKey([DeviceSettingsConstants.PerformanceData_FormKey, DeviceSettingsConstants.PerformanceData_Verbosity_FormKey]) | translate"
                      required type="number"
                      [formControlName]="DeviceSettingsConstants.PerformanceData_Verbosity_FormKey">
                    <mat-hint align="end">{{ 'DeviceSettings.MinMaxHint' | translate:
                      {min: DeviceSettingsConstants.PerformanceData_Verbosity_Min,
                      max: DeviceSettingsConstants.PerformanceData_Verbosity_Max} }}</mat-hint>
                    <mat-error>{{ 'DeviceSettings.MinMaxError' | translate:
                      {min: DeviceSettingsConstants.PerformanceData_Verbosity_Min,
                      max: DeviceSettingsConstants.PerformanceData_Verbosity_Max} }}</mat-error>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <!--END PERFORMANCE DATA-->

              <!--BEGIN ALLOWLIST FOLDER PATHS-->
              <mat-expansion-panel *ngIf="showAllowlistFolderPaths">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ tryGetTranslateKey([DeviceSettingsConstants.Settings_FormKey,
                    DeviceSettingsConstants.Settings_IoT_FormKey,
                    DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey]) | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <button
                  *ngIf="!isDeviceSettingEnabled(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)"
                  mat-raised-button color="primary"
                  (click)="enableFormGroupSettings(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath, false)">
                  {{ 'Global.Add' | translate }}</button>
                <div
                  *ngIf="isDeviceSettingEnabled(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)"
                  [formGroupName]="DeviceSettingsConstants.Settings_FormKey">
                  <div [formGroupName]="DeviceSettingsConstants.Settings_IoT_FormKey">
                    <button mat-raised-button color="primary" class="settings-button"
                      (click)="triggerUpdateAllowlistFolderPaths()">
                      {{ 'InstrumentGroups.UpdateFromFile' | translate }}</button>
                    <input class="no-display" placeholder="File" type="file" #updateAllowlistFolderPaths_file
                      id="updateAllowlistFolderPaths_file" (change)="updateAllowlistFolderPaths($event.target.files)"
                      accept=".txt">
                    <button mat-raised-button color="primary" class="remove-setting"
                      (click)="disableFormGroupSettings(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)">
                      {{ 'Global.Remove' | translate }}</button>
                    <div
                      *ngIf="manageDeviceSettingsForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath)?.value?.length > 0; else noAllowlistFolderPaths">
                      <div
                        *ngFor="let folderPath of manageDeviceSettingsForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath).value">
                        {{ folderPath }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <!--END ALLOWLIST FOLDER PATHS-->
            </mat-accordion>
          </form>
        </mat-tab>

        <mat-tab *ngIf="canManageAllInstruments && instrumentGroup?.instrumentGroupId" [label]="utilitiesTabText">
          <app-instrument-utilities [instrumentGroupId]="instrumentGroup.instrumentGroupId"
            [instrumentTypeId]="instrumentGroup.instrumentTypeId"
            [instrumentTypes]="instrumentTypes"></app-instrument-utilities>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div *ngIf="changeDevicesMode" class="change-devices-list">
      <app-device-list [getDeviceInformationResult]="deviceInformationResult"
        [instrumentTypeId]="instrumentGroup?.instrumentTypeId"
        [allCheckedDeviceInformationArray]="groupDeviceInformations" (checkedDevices)="onCheckedDevices($event)"
        (deviceDataRequested)="deviceDataRequested($event)" [mode]="ListMode.Multiselect" [accessLevel]="accessLevel"
        [showViewInstrumentLink]="true">
      </app-device-list>
    </div>
  </mat-dialog-content>

  <div class="tab-specific-buttons">
    <button class="change-devices-button" *ngIf="showChangeDevicesButton" mat-raised-button color="primary"
      (click)="showDevicesGroups()">
      {{ 'InstrumentGroups.Footer.ChangeDevices' | translate }}
    </button>

    <div *ngIf="showUpdateDeviceSettings" class="flex">
      <button class="update-device-settings-button" mat-raised-button color="primary" (click)="updateDeviceSettings()"
        [disabled]="!isAtLeastOneSettingEnabled() || manageDeviceSettingsForm.invalid">
        {{ 'InstrumentGroups.Footer.UpdateDeviceSettings' | translate }}
      </button>
      <div class="update-device-settings-disclaimer">
        {{ 'InstrumentGroups.Footer.UpdateDeviceSettingsDisclaimer' | translate }}</div>
    </div>

    <div *ngIf="showDeviceMessageDisclaimer" class="flex">
      <div class="update-device-settings-disclaimer">
        {{ 'InstrumentGroups.Footer.DeviceMessagesDisclaimer' | translate }}</div>
    </div>
  </div>

  <mat-dialog-actions *ngIf="canAddUpdate" align="end">
    <div *ngIf="selectedTabIndex !== 2">
      <div *ngIf="!changeDevicesMode">
        <button mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
          {{ 'Global.Cancel' | translate }}</button>
        <button mat-raised-button [disabled]="!instrumentGroup.name || instrumentGroup.instrumentTypeId === 0"
          (click)="saveInstrumentGroup(true)" color="primary">
          {{ (instrumentGroup?.instrumentGroupId ? 'Global.Save&Close' : 'Global.Create&Close') | translate }}</button>
        <button mat-raised-button color="primary"
          [disabled]="!instrumentGroup.name || instrumentGroup.instrumentTypeId === 0"
          (click)="saveInstrumentGroup(false)">
          {{ (instrumentGroup?.instrumentGroupId ? 'Global.Save' : 'Global.Create') | translate }}</button>
      </div>

      <div *ngIf="changeDevicesMode">
        <!-- change groups -->
        <button mat-raised-button color="warn" cdkFocusInitial (click)="cancelDevicesMode()">
          {{ 'Global.Cancel' | translate }}</button>
        <button mat-raised-button color="primary" (click)="updateDevices()">
          {{ 'InstrumentGroups.Footer.SaveDevices' | translate }}</button>
      </div>
      <!--selectedTabIndex === 2 should be empty in order to still align visually-->
    </div>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
    <button *ngIf="selectedTabIndex !== 2" mat-raised-button (click)="close(false)" color="warn" cdkFocusInitial>
      {{ 'Global.Close' | translate }}</button>
    <!--selectedTabIndex === 2 should be empty in order to still align visually-->
  </mat-dialog-actions>
</div>

<ng-template #noAllowlistFolderPaths>
  {{ 'DeviceSettings.Settings.IoT.NoAllowlistFolderPaths' | translate }}
</ng-template>

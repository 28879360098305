import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSortChangeEvent, SortDirection, User, UserWithClaimsTooltips, UsersResult } from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, debounceTime, firstValueFrom } from 'rxjs';
import { SelectionAndCacheService, UserManagementService } from 'src/app/services';
import { FormControl } from '@angular/forms';
import { UserManagementDialogComponent, UserManagementDialogData } from '../user-management-dialog/user-management-dialog.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  public displayNameFieldName = 'displayName';
  public userNameFieldName = 'userName';
  public assignedClaimsFieldName = 'assignedClaims';
  public actionsFieldName = 'actions';

  private displayedColumns: string[] = [this.displayNameFieldName, this.userNameFieldName, this.assignedClaimsFieldName, this.actionsFieldName];
  public columnsToUse = this.displayedColumns;
  public dataSource: MatTableDataSource<UserWithClaimsTooltips> = new MatTableDataSource<UserWithClaimsTooltips>();

  public totalNumberOfUsers = 0;
  public nextPageLink: string;

  public displayNameSearchControl = new FormControl('');
  public displayNameSearchInput = '';
  private pageSize = 100;
  private sortColumn = this.displayNameFieldName;
  private sortDirection = SortDirection.Ascending;

  private subscription: Subscription = new Subscription();
  private sortDataSubject: Subject<MatSortChangeEvent> = new Subject();

  constructor(private userManagementService: UserManagementService,
    private dialog: MatDialog,
    private selectionAndCacheService: SelectionAndCacheService) {
  }

  ngOnInit(): void {
    this.subscription.add(this.sortDataSubject.pipe(debounceTime(500))
      .subscribe((event: MatSortChangeEvent) => {
        this.sortColumn = event.active;
        if (event.direction === 'asc') {
          this.sortDirection = SortDirection.Ascending;
        } else if (event.direction === 'desc') {
          this.sortDirection = SortDirection.Descending;
        }

        this.refreshUsers();
      }));

    this.subscription.add(this.displayNameSearchControl.valueChanges.pipe(debounceTime(500))
      .subscribe((searchText: string) => {
        this.displayNameSearchInput = searchText;
        this.refreshUsers();
      }));

    this.subscription.add(this.selectionAndCacheService.isReadyEvent$.subscribe(() => {
      this.refreshUsers();
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public refreshUsers(): void {
    this.subscription.add(this.userManagementService.search(this.displayNameSearchInput, this.pageSize, this.sortColumn, this.sortDirection)
      .subscribe((result: UsersResult) => {
        this.totalNumberOfUsers = result.totalResults ?? 0;
        this.dataSource.data = this.encapsulateUsers(result.users);
        this.nextPageLink = result.nextPageLink;
      }));
  }

  public sort($event: MatSortChangeEvent): void {
    this.sortDataSubject.next($event);
  }

  public clearDisplayNameSearch(): void {
    this.displayNameSearchInput = '';
    this.refreshUsers();
  }

  public loadMoreUsers(): void {
    this.subscription.add(this.userManagementService.getNextPage(this.nextPageLink)
      .subscribe((result: UsersResult) => {
        this.dataSource.data = [...this.dataSource.data, ...this.encapsulateUsers(result.users)];
        this.nextPageLink = result.nextPageLink;
      }));
  }

  public async manageUser(originalUser: UserWithClaimsTooltips): Promise<void> {

    const options: UserManagementDialogData = {
      user: { ...originalUser }
    };

    const dialogRef = this.dialog.open<UserManagementDialogComponent, UserManagementDialogData, User>(UserManagementDialogComponent, {
      data: options,
      width: '75%',
      disableClose: true
    });

    const updatedUser: User = await firstValueFrom(dialogRef.afterClosed());
    const wrappedUpdatedUser = new UserWithClaimsTooltips(updatedUser, this.selectionAndCacheService.instrumentTypes);
    const newUsersCollection = [...this.dataSource.data];
    const index = newUsersCollection.indexOf(originalUser);
    newUsersCollection[index] = wrappedUpdatedUser;
    this.dataSource.data = newUsersCollection;
  }

  public getInstrumentTypeIdName(instrumentTypeId: string): string {
    return this.selectionAndCacheService.instrumentTypes.find(it => it.instrumentTypeId === +instrumentTypeId)?.name ?? 'Unknown';
  }

  private encapsulateUsers(users: User[]): UserWithClaimsTooltips[] {
    return users.map(user => new UserWithClaimsTooltips(user, this.selectionAndCacheService.instrumentTypes));
  }
}

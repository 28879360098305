import { Injectable, OnDestroy, Type } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Configuration } from 'src/app/models';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService implements OnDestroy {

  private configuration: Configuration;
  private subscription: Subscription = new Subscription();
  private appInsights: ApplicationInsights;

  constructor(configurationService: ConfigurationService, router: Router) {
    this.configuration = configurationService.getConfiguration();
    if (!this.configuration.appInsightsKey) {
      return;
    }
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.configuration.appInsightsKey
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();

    this.subscription.add(router.events.pipe(filter(event => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logNavigation(`${activatedComponent.name}`, event.urlAfterRedirects);
        }
      }));
  }

  ngOnDestroy(): void {
    void this.appInsights.flush();
    this.subscription.unsubscribe();
  }

  public addTelemetryInitializer(cloudRoleName: string, cloudRoleInstance: string): void {
    const telemetryInitializer = (envelope) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      envelope.tags['ai.cloud.role'] = cloudRoleName;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      envelope.tags['ai.cloud.roleInstance'] = cloudRoleInstance;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public setUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  public clearUserId(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logNavigation(
    name?: string,
    uri?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ): void {
    this.appInsights.trackPageView({
      name,
      uri,
      properties,
      measurements
    });
    this.flushIfDevelopment();
  }

  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }): void {
    this.appInsights.trackEvent({
      name,
      properties,
      measurements
    });
    this.flushIfDevelopment();
  }

  public logError(
    exception: Error,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ): void {
    this.appInsights.trackException({
      exception,
      properties,
      measurements
    });
    this.flushIfDevelopment();
  }

  private flushIfDevelopment(): void {
    if (this.configuration.production) {
      return;
    }

    void this.appInsights.flush();
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): Type<{ name: string }> {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component as Type<{ name: string }>;
  }
}

import { Injectable } from '@angular/core';
import { MissingEndTokenError, MissingStartTokenError } from '../models';
import { loadAsync, JSZipObject } from 'jszip';
import { TranslateService } from '@ngx-translate/core';
import { translate, translateWithInterpolateParams } from '../shared/translateServiceHelper';
import { TranslateConstants } from '../constants/translate-constants';

// don't rename; represents serialized JSON
interface LPFHeader {
  checksum: string;
  signature: string;
  luf_interface_version: number;
  toc_length: number;
}

function parse_header(headerJson: string, translateService: TranslateService): LPFHeader {
  const headerObj = JSON.parse(headerJson) as LPFHeader;

  const requiredFields = ['checksum', 'signature', 'luf_interface_version', 'toc_length'];
  for (const field of requiredFields) {
    if (field in headerObj === false)
      throw new Error(translateWithInterpolateParams(translateService, TranslateConstants.FileHeaderParsingLPFHeaderRequiredFieldKey, { field: field }));
  }

  if (headerObj.luf_interface_version != 1) {
    throw new Error(translateWithInterpolateParams(translateService, TranslateConstants.FileHeaderParsingLUFInterfaceVersionNotSupportedKey, { interfaceVersion: headerObj.luf_interface_version }));
  }

  if (headerObj.toc_length < 0) {
    throw new Error(translateWithInterpolateParams(translateService, TranslateConstants.FileHeaderParsingTOCLengthRequiredPositiveKey, { tocLength: headerObj.toc_length }));
  }

  return headerObj;
}

// don't rename; represents serialized JSON
interface LUFTableOfContents {
  version: string;
  upgrade_from_versions: string[];
  supported_hw_sw_interfaces: number[];
  blob_checksum: string;
  blob_length: number;
}

function parse_toc(tocJson: string, translateService: TranslateService): LUFTableOfContents {
  const tocObj = JSON.parse(tocJson) as LUFTableOfContents;

  const requiredFields = ['version', 'upgrade_from_versions', 'supported_hw_sw_interfaces', 'blob_checksum', 'blob_length'];
  for (const field of requiredFields) {
    if (field in tocObj === false)
      throw new Error(translateWithInterpolateParams(translateService, TranslateConstants.FileHeaderParsingLUFTOCRequiredFieldKey, { field: field }));
  }

  if (tocObj.blob_length < 0) {
    throw new Error(translateWithInterpolateParams(translateService, TranslateConstants.FileHeaderParsingBlobLengthRequiredPositiveKey, { blobLength: tocObj.blob_length }));
  }

  return tocObj;
}

@Injectable({
  providedIn: 'root'
})
export class FileHeaderService {

  constructor(private translateService: TranslateService) {

  }

  // Reads LPF file header
  public readHeader(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      console.debug(file.name);
      console.debug(file.size);

      const reader = new FileReader();
      const translateService = this.translateService;

      const offset = 0;
      const chunkSize = 4096; // max header size according to spec

      reader.onloadend = function (progress) {

        //progress.loaded
        if (reader.result) {
          const view = new Int8Array(reader.result as ArrayBuffer);

          let pos = 0;
          let state = 0;
          let startPos = -1;
          let endPos = -1;
          while (pos < progress.total) {
            const x = view[pos];

            if (state == 0) // waiting for '#'
            {
              if (pos == 0 && x == 0x23) {
                console.debug(`start token found @ ${pos}`);
                startPos = pos + 1; // skip
                state = 1;
              }
              else {
                // must start with '#'
                console.debug('start token not found');
                reject(new MissingStartTokenError(translateService));
                break;
              }
            }
            else if (state == 1) // waiting for 0x0D
            {
              if (x == 0x0D) {
                endPos = pos; // last char
                state = 2;
              }
            }
            else if (state == 2) // waiting for 0x0A
            {
              if (x == 0x0A) {
                console.debug(`end token found @ ${pos}`);
                state = 3; // parsing complete
                break;
              }
              else {
                state = 1; // reset
                endPos = -1;
              }
            }
            pos++;
          }

          if (state == 3) // found end token
          {
            const enc = new TextDecoder('utf-8');
            const headerJson = enc.decode(view.slice(startPos, endPos));
            console.debug(headerJson);

            resolve(headerJson);
          }
          else {
            console.debug('end token not found');
            reject(new MissingEndTokenError(translateService));
          }
        }
      };

      // trigger load of first chunk
      reader.readAsArrayBuffer(file.slice(offset, Math.min(offset + chunkSize, file.size)));

    });
  }

  // Reads LPF file TOC
  public readTOC(file: File, headerJson: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const translateService = this.translateService;
      try {
        const headerObj = parse_header(headerJson, translateService);
        const tocLength = headerObj.toc_length;
        const reader = new FileReader();
        reader.onloadend = function (_progress) {
          try {
            if (reader.error) {
              reject(new Error(translate(translateService, TranslateConstants.FileHeaderParsingFailedReadingFileKey)));
            }

            if (reader.result) {

              const enc = new TextDecoder('utf-8');
              const tocJson = enc.decode(reader.result as ArrayBuffer);
              console.debug(tocJson);

              // try parsing to verify syntax
              parse_toc(tocJson, translateService);

              resolve(tocJson);
            }
          } catch (error) {
            reject(error);
          }
        };

        const body_offset = headerJson.length + 2 + 1;  // +2 for \r\n, +1 for #
        reader.readAsArrayBuffer(file.slice(body_offset, body_offset + tocLength));
      } catch (error) {
        reject(error);
      }
    });
  }

  public readManifestFileHeaderFromZip(zipFile: File, manifestFileRelativePath: string): Promise<string> {
    return loadAsync(zipFile)
      .then(async (zip) => {

        const manifestFile: JSZipObject = zip.file(manifestFileRelativePath);
        if (!manifestFile) {
          throw new Error(translateWithInterpolateParams(this.translateService, TranslateConstants.FileHeaderParsingManifestFileMissingKey, { manifestFileRelativePath: manifestFileRelativePath }));
        }

        const manifestFileContent = await manifestFile.async('string');
        const manifestFileLines = manifestFileContent.split('\n');
        for (const line of manifestFileLines) {
          if (line.startsWith('#')) {
            return line.slice(1);
          }
        }

        throw new MissingStartTokenError(this.translateService);
      });
  }
}

<div class="container">
  <div class="container-grid">
    <h1>{{ 'Languages.LanguageFiles' | translate }}</h1>
    <div class="action-bar">
      <div>
        <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="onAddLanguage()">
          <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
        </button>
      </div>
      <div></div>
      <div>
        <button mat-icon-button class="refresh" (click)="loadLanguages()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="filter-bar">
      <mat-form-field>
        <mat-label>{{ 'Firmwares.Firmware' | translate }}</mat-label>
        <mat-select [(value)]="selectedFirmware">
          <mat-option [value]="undefined">
            -
          </mat-option>
          <mat-option *ngFor="let firmware of allFirmwares" [value]="firmware">
            {{firmware.title}} | Version:
            {{firmware.version?.major}}.{{firmware.version?.minor}}.{{firmware.version?.revision}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="interfaceVersion" matSortDirection="desc"
        matSortDisableClear="true">
        <ng-container [matColumnDef]="idFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Id' | translate }}</th>
          <td mat-cell *matCellDef="let language"> {{language.languageId}} </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Title' | translate }}</th>
          <td mat-cell *matCellDef="let language"> {{language.title}} </td>
        </ng-container>
        <ng-container [matColumnDef]="codeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Code' | translate }}</th>
          <td mat-cell *matCellDef="let language"> {{getLanguageCode(language.languageDefinitionId)}} </td>
        </ng-container>
        <ng-container matColumnDef="instrumentFileTypeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.FileType' | translate }}</th>
          <td mat-cell *matCellDef="let language">{{getInstrumentFileTypeDisplayValue(language.instrumentFileTypeId)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="instrumentTypeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.InstrumentType' | translate }}</th>
          <td mat-cell *matCellDef="let language">{{getInstrumentTypeDisplayValue(language.instrumentTypeId)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="modelIdsCountFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.#OfModels' | translate }}</th>
          <td mat-cell *matCellDef="let language">{{getInstrumentTypeModelsCount(language)}}</td>
        </ng-container>
        <ng-container matColumnDef="interfaceVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.InterfaceVersion' | translate }}</th>
          <td mat-cell *matCellDef="let language"> {{language.interfaceVersion}} </td>
        </ng-container>
        <ng-container [matColumnDef]="versionFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Version' | translate }}</th>
          <td mat-cell *matCellDef="let language">
            {{language.version?.major}}.{{language.version?.minor}}.{{language.version?.revision}} </td>
        </ng-container>
        <ng-container [matColumnDef]="statusFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Status' | translate }}</th>
          <td mat-cell *matCellDef="let language">
            {{getPublishedFlagDisplayValue(PublishedFlag[language.publishedFlag])}}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="devicesCountFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.#OfInstruments' | translate }}</th>
          <td mat-cell *matCellDef="let language"> {{language.devicesCount | number}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let language">


            <div *ngIf="canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditLanguage(language)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button [disabled]="!canAddUpdate || language.publishedFlag !== PublishedFlag.Unpublished"
                (click)="onDeleteLanguage(language)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditLanguage(language)">
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToUse"></tr>
      </table>
    </div>
    <div class="instrument-count-disclaimer" [innerHTML]="'Global.#OfInstrumentsDisclaimer' | translate"></div>
  </div>
</div>

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { AccessLevel, CSVExport, EmailTemplate, ErrorStatus, InstrumentTypeId, UserPermission } from 'src/app/models';
import { ExportsService, NotificationService, UserPermissionService } from 'src/app/services';
import { translate } from 'src/app/shared/translateServiceHelper';

enum ExportType {
  InstrumentSofia2 = 'InstrumentSofia2',
  InstrumentSavanna = 'InstrumentSavanna',
  InstrumentVision = 'InstrumentVision',
  RegistrationFailure = 'RegistrationFailure'
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  public ExportType = ExportType;

  public currentSofia2CSVExport: CSVExport;
  public currentSofia2CSVExportFound: boolean;
  public showSofia2CSVExportDownload = false;

  public currentSavannaCSVExport: CSVExport;
  public currentSavannaCSVExportFound: boolean;
  public showSavannaCSVExportDownload = false;

  public currentVisionCSVExport: CSVExport;
  public currentVisionCSVExportFound: boolean;
  public showVisionCSVExportDownload = false;

  public currentRegistrationFailureExport: CSVExport;
  public currentRegistrationFailureExportFound: boolean;
  // if user is able to view this Reports view then they can at least download the Registration Failure Report

  public generateDialogTitle: string;
  public generateDialogMessage: string;
  /* eslint-disable @typescript-eslint/unbound-method */
  public reportForm = this.fb.group(
    {
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]]
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  private subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private exportsService: ExportsService,
    private msalService: MsalService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private userPermissionService: UserPermissionService) {

  }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {

      const canManageAllInstrumentsSofia2 = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [UserPermission.ViewEverything], InstrumentTypeId.Sofia2) === AccessLevel.AddUpdate;
      const canManageAllInstrumentsSavanna = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [UserPermission.ViewEverything], InstrumentTypeId.Savanna) === AccessLevel.AddUpdate;
      const canManageAllInstrumentsVision = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [UserPermission.ViewEverything], InstrumentTypeId.Vision) === AccessLevel.AddUpdate;

      this.showSofia2CSVExportDownload = canManageAllInstrumentsSofia2;
      this.showSavannaCSVExportDownload = canManageAllInstrumentsSavanna;
      this.showVisionCSVExportDownload = canManageAllInstrumentsVision;

      if (this.showSofia2CSVExportDownload) {
        this.subscription.add(this.exportsService.getCurrentInstrumentExportUrl(InstrumentTypeId.Sofia2)
          .subscribe(
            {
              next: (data: CSVExport) => {
                this.currentSofia2CSVExport = data;
                this.currentSofia2CSVExportFound = true;
              }, error: (error: ErrorStatus) => {
                if (error.status == 404) {
                  this.currentSofia2CSVExportFound = false;
                }
              }
            }));
      }

      if (this.showSavannaCSVExportDownload) {
        this.subscription.add(this.exportsService.getCurrentInstrumentExportUrl(InstrumentTypeId.Savanna)
          .subscribe(
            {
              next: (data: CSVExport) => {
                this.currentSavannaCSVExport = data;
                this.currentSavannaCSVExportFound = true;
              }, error: (error: ErrorStatus) => {
                if (error.status == 404) {
                  this.currentSavannaCSVExportFound = false;
                }
              }
            }));
      }

      if (this.showVisionCSVExportDownload) {
        this.subscription.add(this.exportsService.getCurrentInstrumentExportUrl(InstrumentTypeId.Vision)
          .subscribe(
            {
              next: (data: CSVExport) => {
                this.currentVisionCSVExport = data;
                this.currentVisionCSVExportFound = true;
              }, error: (error: ErrorStatus) => {
                if (error.status == 404) {
                  this.currentVisionCSVExportFound = false;
                }
              }
            }));
      }

      this.subscription.add(this.exportsService.getCurrentRegistrationFailureExportUrl()
        .subscribe(
          {
            next: (data: CSVExport) => {
              this.currentRegistrationFailureExport = data;
              this.currentRegistrationFailureExportFound = true;
            }, error: (error: ErrorStatus) => {
              if (error.status == 404) {
                this.currentRegistrationFailureExportFound = false;
              }
            }
          }));
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public onGetSofia2CSVExport(): void {
    this.exportsService.downloadFile(this.currentSofia2CSVExport.url, `Instruments-Sofia2-${this.currentSofia2CSVExport.dateGenerated.toString()}.csv`);
  }

  public onGetSavannaCSVExport(): void {
    this.exportsService.downloadFile(this.currentSavannaCSVExport.url, `Instruments-Savanna-${this.currentSavannaCSVExport.dateGenerated.toString()}.csv`);
  }

  public onGetVisionCSVExport(): void {
    this.exportsService.downloadFile(this.currentVisionCSVExport.url, `Instruments-Vision-${this.currentVisionCSVExport.dateGenerated.toString()}.csv`);
  }

  public onGetRegistrationFailureExport(): void {
    this.exportsService.downloadFile(this.currentRegistrationFailureExport.url, `DeviceRegistrationFailures-${this.currentRegistrationFailureExport.dateGenerated.toString()}.csv`);
  }

  public async onGenerateExport(template: TemplateRef<unknown>, exportType: ExportType): Promise<void> {

    let exportTypeTranslateKey: string;
    switch (exportType) {
      case ExportType.InstrumentSofia2:
        exportTypeTranslateKey = TranslateConstants.ReportInstrumentSofia2Key;
        break;
      case ExportType.InstrumentSavanna:
        exportTypeTranslateKey = TranslateConstants.ReportInstrumentSavannaKey;
        break;
      case ExportType.InstrumentVision:
        exportTypeTranslateKey = TranslateConstants.ReportInstrumentVisionKey;
        break;
      case ExportType.RegistrationFailure:
        exportTypeTranslateKey = TranslateConstants.ReportRegistrationFailureKey;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unsupported export type: ${exportType}`);
    }

    this.generateDialogTitle = translate(this.translateService, TranslateConstants.GenerateExportTitleKey, { exportType: exportTypeTranslateKey });
    this.generateDialogMessage = translate(this.translateService, TranslateConstants.GenerateExportMessageKey, { exportType: exportTypeTranslateKey });

    const msalUser = this.msalService.instance.getActiveAccount();
    this.reportForm.patchValue({ email: msalUser.username });
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, EmailTemplate | 'false'>(template, { width: '75%' });
    const result = await firstValueFrom<EmailTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      let exportServiceCall: Observable<unknown>;
      switch (exportType) {
        case ExportType.InstrumentSofia2:
          exportServiceCall = this.exportsService.generateInstrumentExportAndSendEmail(result.email, InstrumentTypeId.Sofia2);
          break;
        case ExportType.InstrumentSavanna:
          exportServiceCall = this.exportsService.generateInstrumentExportAndSendEmail(result.email, InstrumentTypeId.Savanna);
          break;
        case ExportType.InstrumentVision:
          exportServiceCall = this.exportsService.generateInstrumentExportAndSendEmail(result.email, InstrumentTypeId.Vision);
          break;
        case ExportType.RegistrationFailure:
          exportServiceCall = this.exportsService.generateRegistrationFailureExportAndSendEmail(result.email);
          break;
        default:
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Unsupported export type: ${exportType}`);
      }

      this.subscription.add(exportServiceCall.subscribe({
        complete: () => this.notificationService.success(TranslateConstants.ReportExportGenerationRequestSuccessKey, { type: exportTypeTranslateKey }),
        error: (err) => this.notificationService.error(TranslateConstants.ReportExportGenerationRequestFailedKey, { type: exportTypeTranslateKey }),
      }));
    }
  }
}

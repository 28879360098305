<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ 'UserManagement.Dialog.AddClaims' | translate }}</h1>
  </div>
  <div mat-dialog-content class="content">
    {{ 'UserManagement.Dialog.GlobalClaims' | translate }}
    <mat-form-field appearance="outline">
      <mat-label>{{ 'UserManagement.Dialog.Claims' | translate }}</mat-label>
      <mat-chip-grid #globalClaimsChipList>
        <mat-chip-row *ngFor="let claim of selectedGlobalClaims" (removed)="removeGlobalClaim(claim)" matChipRemove>
          {{claim}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input [placeholder]="'UserManagement.Dialog.Claim' | translate" #globalClaimInput
        [formControl]="searchGlobalClaimControl" [matChipInputFor]="globalClaimsChipList"
        [matAutocomplete]="globalAuto" />
      <mat-autocomplete #globalAuto="matAutocomplete" (optionSelected)="selectedGlobalClaimSearch($event)">
        <mat-option *ngFor="let claim of filteredSearchGlobalClaims | async" [value]="claim">
          {{claim}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <br />
    {{ 'UserManagement.Dialog.InstrumentTypeClaims' | translate }}
    <mat-form-field appearance="outline">
      <mat-label>{{ 'UserManagement.Dialog.InstrumentTypes' | translate }}</mat-label>
      <mat-select [formControl]="selectedInstrumentTypes" multiple>
        <mat-select-trigger>
          <div
            *ngIf="(selectedInstrumentTypes.value?.length || 0) > 0 && (selectedInstrumentTypes.value?.length || 0) < instrumentTypes.length">
            {{ selectedInstrumentTypes.value[0].name }}
            <span *ngIf="(selectedInstrumentTypes.value?.length || 0) > 1" class="additional-selection">
              (+{{ (selectedInstrumentTypes.value?.length || 0) - 1 }} {{ selectedInstrumentTypes.value?.length === 2 ?
              ('Global.Other' |
              translate) : ('Global.Others' | translate) }})
            </span>
          </div>
          <div *ngIf="(selectedInstrumentTypes.value?.length || 0) === instrumentTypes.length">
            {{ 'Global.All' | translate }}
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let instrumentType of instrumentTypes" [value]="instrumentType">
          {{instrumentType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'UserManagement.Dialog.Claims' | translate }}</mat-label>
      <mat-chip-grid #instrumentTypeclaimsChipList>
        <mat-chip-row *ngFor="let claim of selectedInstrumentTypeClaims" (removed)="removeInstrumentTypeClaim(claim)"
          matChipRemove>
          {{claim}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input [placeholder]="'UserManagement.Dialog.Claim' | translate" #instrumentTypeClaimInput
        [formControl]="searchInstrumentTypeClaimControl" [matChipInputFor]="instrumentTypeclaimsChipList"
        [matAutocomplete]="instrumentTypeAuto" />
      <mat-autocomplete #instrumentTypeAuto="matAutocomplete"
        (optionSelected)="selectedInstrumentTypeClaimSearch($event)">
        <mat-option *ngFor="let claim of filteredSearchInstrumentTypeClaims | async" [value]="claim">
          {{claim}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
      {{ 'Global.Cancel' | translate }}</button>
    <button mat-raised-button color="primary" (click)="add()" [disabled]="!canSave">
      {{ 'Global.Add' | translate }}</button>
  </mat-dialog-actions>
</div>

import { DecimalPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export const formGroupContains = (formGroup: FormGroup, path: string | (readonly string[])): boolean => {
  let pathParts: string[];
  if (typeof path === 'string') {
    pathParts = path.split('.');
  } else {
    pathParts = path as string[];
  }

  let containingFormGroup = formGroup;
  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i];
    if (containingFormGroup.contains(pathPart)) {
      containingFormGroup = containingFormGroup.get(pathPart) as FormGroup;
    } else {
      return false;
    }
  }

  return true;
};

export const addNestedFormControl = (fb: FormBuilder, rootFormGroup: FormGroup, path: string | (readonly string[]), formState: (unknown)[]): void => {
  let pathParts: string[];
  if (typeof path === 'string') {
    pathParts = path.split('.');
  } else {
    pathParts = path as string[];
  }

  let containingFormGroup = rootFormGroup;
  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i];

    if (!containingFormGroup.contains(pathPart)) {
      if (i === (pathParts.length - 1)) {
        // last part, create the control
        containingFormGroup.addControl(pathPart, fb.control(formState));
      } else {
        // create an empty group
        containingFormGroup.addControl(pathPart, fb.group({}));
      }
    }

    if (i < pathParts.length - 1) {
      // not at the last part yet
      containingFormGroup = containingFormGroup.get(pathPart) as FormGroup;
    }
  }
};

export const removeNestedFormControl = (rootFormGroup: FormGroup, path: string | (readonly string[])): void => {
  const formControl = rootFormGroup.get(path);
  if (!formControl) {
    return;
  }

  let pathParts: string[];
  if (typeof path === 'string') {
    pathParts = path.split('.');
  } else {
    pathParts = path as string[];
  }

  const controlName = pathParts[pathParts.length - 1];
  let currentFormGroup: FormGroup = formControl.parent as FormGroup;

  if (currentFormGroup) {
    currentFormGroup.removeControl(controlName);

    // ensure that any empty form groups above are removed
    for (let i = pathParts.length - 2; i > -1; i--) {
      const groupName = pathParts[i];

      if (!currentFormGroup.controls
        || Object.keys(currentFormGroup.controls).length == 0) {
        currentFormGroup = currentFormGroup.parent as FormGroup;
        currentFormGroup.removeControl(groupName);
      } else {
        // there are other controls, stop attemping to remove empty groups
        break;
      }
    }
  }
};

export const addNestedFormGroup = (fb: FormBuilder, rootFormGroup: FormGroup, path: string | (readonly string[]), formState: unknown): void => {
  let pathParts: string[];
  if (typeof path === 'string') {
    pathParts = path.split('.');
  } else {
    pathParts = path as string[];
  }

  let containingFormGroup = rootFormGroup;
  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i];

    if (!containingFormGroup.contains(pathPart)) {
      if (i === (pathParts.length - 1)) {
        // last part, create the nested form group
        containingFormGroup.addControl(pathPart, fb.group(formState));
      } else {
        // create an empty group
        containingFormGroup.addControl(pathPart, fb.group({}));
      }
    }

    if (i < pathParts.length - 1) {
      // not at the last part yet
      containingFormGroup = containingFormGroup.get(pathPart) as FormGroup;
    }
  }
};


export const getMinMaxValidationValues = (keyParts: string[], formGroup: FormGroup, numberPipe: DecimalPipe,
  minMaxValidationValuesByKeyPartsCache?: Map<string, { min: string, max: string, minValue: number, maxValue: number }>): { min: string, max: string, minValue: number, maxValue: number } => {

  const combinedKeyParts = keyParts.join('.');
  if (minMaxValidationValuesByKeyPartsCache && minMaxValidationValuesByKeyPartsCache.has(combinedKeyParts)) {
    return minMaxValidationValuesByKeyPartsCache.get(combinedKeyParts);
  }

  const formControl = formGroup.get(keyParts);

  let min = 0;
  let max = 1;
  // https://codegen.studio/blog/m05omy/how-to-check-if-a-form-control-has-a-min-validator/
  if (formControl.validator) {
    const minValidator = formControl.validator(new FormControl(-Infinity));
    if (minValidator) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      min = minValidator['min']['min'] as number;
    }
    const maxValidator = formControl.validator(new FormControl(Infinity));
    if (maxValidator) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      max = maxValidator['max']['max'] as number;
    }
  }

  const result = { min: numberPipe.transform(min), max: numberPipe.transform(max), minValue: min, maxValue: max };
  if (minMaxValidationValuesByKeyPartsCache) {
    minMaxValidationValuesByKeyPartsCache.set(combinedKeyParts, result);
  }
  return result;
};

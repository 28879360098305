import { EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { compare } from 'src/app/shared/utils';
import {
  ListMode, InstrumentGroup, InstrumentGroupsResult, MatSortChangeEvent, SortDirection,
  MatPageChangeEvent, MatCheckboxChangeEvent, AccessLevel, CheckedGroupsEvent
} from 'src/app/models';
import { SelectionAndCacheService } from 'src/app/services';

export interface InstrumentGroupListRequest {
  instrumentGroupName: string;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
}

@Component({
  selector: 'app-instrument-group-list',
  templateUrl: './instrument-group-list.component.html',
  styleUrls: ['./instrument-group-list.component.scss']
})
export class InstrumentGroupListComponent implements OnInit, OnDestroy {
  @Output() dataRequested: EventEmitter<InstrumentGroupListRequest> = new EventEmitter<InstrumentGroupListRequest>();
  @Output() checkedGroups: EventEmitter<CheckedGroupsEvent> = new EventEmitter<CheckedGroupsEvent>();
  @Output() editInstrumentGroup: EventEmitter<InstrumentGroup> = new EventEmitter<InstrumentGroup>();
  @Output() deleteInstrumentGroup: EventEmitter<InstrumentGroup> = new EventEmitter<InstrumentGroup>();
  @Output() removeInstrumentFromGroup: EventEmitter<InstrumentGroup> = new EventEmitter<InstrumentGroup>();
  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  @Input() accessLevel: AccessLevel = AccessLevel.Unauthorized;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public readonly multiselectFieldName = 'multiselect';
  public readonly actionsFieldName = 'actions';
  public readonly idFieldName = 'id';
  public readonly nameFieldName = 'name';
  public readonly devicesFieldName = 'devices';
  public readonly instrumentTypeFieldName = 'instrumentType';
  public multiselectDisplayedColumns: string[] = [this.multiselectFieldName, this.idFieldName, this.nameFieldName, this.instrumentTypeFieldName, this.devicesFieldName];
  public displayedColumns: string[] = [this.idFieldName, this.nameFieldName, this.instrumentTypeFieldName, this.devicesFieldName, this.actionsFieldName];
  public filteredDisplayedColumns: string[] = [this.idFieldName, this.nameFieldName, this.instrumentTypeFieldName, this.devicesFieldName, this.actionsFieldName];
  public columnsToUse = this.displayedColumns;
  public dataSource: MatTableDataSource<InstrumentGroup> = new MatTableDataSource<InstrumentGroup>();
  public ListMode = ListMode;
  public searchControl = new FormControl('');
  public searchInput = '';
  public pageIndex = 1;
  public pageSize = 100;
  public length: number;
  public selectAllPages = false;
  public allCheckedInstrumentGroupsArrayInternal: InstrumentGroup[] = [];

  private searchFilters = new Subscription();
  private sortDataSubscription = new Subscription();
  private searchDataSubject: Subject<string> = new Subject();
  private sortDataSubject: Subject<MatSortChangeEvent> = new Subject();
  private instrumentGroupsResultInternal: InstrumentGroupsResult;
  private unpaginatedInstrumentGroupsArrayInternal: InstrumentGroup[];
  private modeInternal: ListMode = ListMode.All;
  private instrumentGroupsRequest: InstrumentGroupListRequest = {
    instrumentGroupName: '',
    pageNumber: 1,
    pageSize: this.pageSize,
    sortColumn: '',
    sortDirection: SortDirection.Ascending
  };

  get allCheckedInstrumentGroupsArray(): InstrumentGroup[] {
    return this.allCheckedInstrumentGroupsArrayInternal;
  }

  @Input() set allCheckedInstrumentGroupsArray(val: InstrumentGroup[]) {
    this.allCheckedInstrumentGroupsArrayInternal = val;
    if (this.allCheckedInstrumentGroupsArrayInternal === undefined) {
      this.allCheckedInstrumentGroupsArrayInternal = [];
    }
  }

  get instrumentGroupsResult(): InstrumentGroupsResult {
    return this.instrumentGroupsResultInternal;
  }

  @Input() set instrumentGroupsResult(val: InstrumentGroupsResult) {
    this.instrumentGroupsResultInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val.results;
    this.pageSize = val.pagingInformation?.pageSize;
    this.length = val.totalResults;
    this.pageIndex = val.pagingInformation?.pageNumber - 1;
  }

  get unpaginatedInstrumentGroupsArray(): InstrumentGroup[] {
    return this.unpaginatedInstrumentGroupsArrayInternal;
  }

  @Input() set unpaginatedInstrumentGroupsArray(val: InstrumentGroup[]) {
    this.unpaginatedInstrumentGroupsArrayInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val;
    this.dataSource.paginator = this.paginator;
  }

  @Input() showSelectAllPagesMultiselect = false;

  get mode(): ListMode {
    return this.modeInternal;
  }

  @Input() set mode(val: ListMode) {
    this.modeInternal = val;
    if (!val) {
      return;
    }
    switch (+val) {
      case +ListMode.All:
        this.columnsToUse = this.displayedColumns;
        break;
      case +ListMode.Multiselect:
        this.columnsToUse = this.multiselectDisplayedColumns;
        break;
      case +ListMode.Filtered:
        this.columnsToUse = this.filteredDisplayedColumns;
        break;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(private selectionAndCacheService: SelectionAndCacheService) {
  }

  ngOnInit(): void {
    this.sortDataSubscription = this.sortDataSubject.pipe(debounceTime(500))
      .subscribe((event: MatSortChangeEvent) => {
        if (this.mode === ListMode.Filtered) {
          this.sortUnpaginatedData(event);
        } else {
          this.sortPaginatedData(event);
        }
      });

    this.searchFilters.add(this.searchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchByName(newValue);
    }));

    this.searchFilters.add(this.searchDataSubject.pipe(debounceTime(500))
      .subscribe((event: string) => {
        this.searchByName(event);
      }));
  }

  ngOnDestroy(): void {
    this.sortDataSubscription?.unsubscribe();
    this.searchFilters?.unsubscribe();
  }

  public isCurrentPageChecked(): boolean {
    if (this.selectAllPages) {
      return false;
    }

    const someValuesAreNotIncluded = this.dataSource.data.some(x => !this.allCheckedInstrumentGroupsArrayInternal.find(c => c.instrumentGroupId == x.instrumentGroupId));
    return !someValuesAreNotIncluded;
  }

  public getPageData($event: MatPageChangeEvent): void {
    if (this.mode === ListMode.Filtered) {
      return;
    }
    this.instrumentGroupsRequest.pageNumber = $event.pageIndex + 1;
    this.instrumentGroupsRequest.pageSize = $event.pageSize;
    this.dataRequested.emit(this.instrumentGroupsRequest);
  }


  public sort($event: MatSortChangeEvent): void {
    this.sortDataSubject.next($event);
  }

  public isChecked(instrumentGroup: InstrumentGroup): boolean {
    if (this.selectAllPages) {
      return true;
    }
    if (!instrumentGroup) {
      return false;
    }
    return this.allCheckedInstrumentGroupsArray.find(g => g.instrumentGroupId === instrumentGroup.instrumentGroupId) !== undefined;
  }

  public selectionChanged($event: MatCheckboxChangeEvent, instrumentGroup: InstrumentGroup): void {
    if (this.selectAllPages) {
      this.allCheckedInstrumentGroupsArray = this.allCheckedInstrumentGroupsArray.filter(a => this.dataSource.data.some(d => a.instrumentGroupId === d.instrumentGroupId));
    }

    const groupIdFoundIndex = this.allCheckedInstrumentGroupsArray.findIndex(x => x.instrumentGroupId === instrumentGroup.instrumentGroupId);

    if (!($event.currentTarget.checked) && groupIdFoundIndex > -1) {
      this.allCheckedInstrumentGroupsArray.splice(groupIdFoundIndex, 1);
    } else {
      this.allCheckedInstrumentGroupsArray.push(instrumentGroup);
    }
    this.selectAllPages = false;
    this.checkedGroups.emit({ checkedGroups: this.allCheckedInstrumentGroupsArray, allPages: this.selectAllPages });
  }

  public checkCurrentPage(): void {
    if (this.selectAllPages) {
      this.allCheckedInstrumentGroupsArray.length = 0;
      this.selectAllPages = false;
    }

    this.dataSource.data.forEach(group => {
      const groupIdFoundIndex = this.allCheckedInstrumentGroupsArray.findIndex(x => x.instrumentGroupId === group.instrumentGroupId);
      if (groupIdFoundIndex < 0) {
        this.allCheckedInstrumentGroupsArray.push(group);
      }
    });
    this.checkedGroups.emit({ checkedGroups: this.allCheckedInstrumentGroupsArray, allPages: this.selectAllPages });
  }

  public checkAll(): void {
    this.dataSource.data.forEach(group => {
      const groupIdFoundIndex = this.allCheckedInstrumentGroupsArray.findIndex(x => x.instrumentGroupId === group.instrumentGroupId);
      if (groupIdFoundIndex < 0) {
        this.allCheckedInstrumentGroupsArray.push(group);
      }
    });
    this.selectAllPages = true;
    this.checkedGroups.emit({ checkedGroups: this.allCheckedInstrumentGroupsArray, allPages: this.selectAllPages });
  }

  public uncheckAll(): void {
    this.allCheckedInstrumentGroupsArray.length = 0;
    this.selectAllPages = false;
    this.checkedGroups.emit({ checkedGroups: this.allCheckedInstrumentGroupsArray, allPages: this.selectAllPages });
  }

  public onEditInstrumentGroup(instrumentGroup: InstrumentGroup): void {
    this.editInstrumentGroup.emit(instrumentGroup);
  }

  public onDeleteInstrumentGroup(instrumentGroup: InstrumentGroup): void {
    this.deleteInstrumentGroup.emit(instrumentGroup);
  }

  public onRemoveInstrumentFromInstrumentGroup(instrumentGroup: InstrumentGroup): void {
    this.removeInstrumentFromGroup.emit(instrumentGroup);
  }

  public clearSearch(): void {
    this.searchInput = '';
    this.instrumentGroupsRequest.instrumentGroupName = this.searchInput;

    this.dataRequested.emit(this.instrumentGroupsRequest);
  }


  public onRefreshData(): void {
    this.refreshData.emit();
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  private executeSearchByName($event: string): void {
    this.searchDataSubject.next($event);
  }

  private searchByName($event: string): void {
    this.searchInput = $event;
    this.instrumentGroupsRequest.instrumentGroupName = this.searchInput;

    this.dataRequested.emit(this.instrumentGroupsRequest);
  }

  private sortPaginatedData(event: MatSortChangeEvent): void {
    let sortDirection = SortDirection.None;
    if (event.direction === 'asc') {
      sortDirection = SortDirection.Ascending;
    } else if (event.direction === 'desc') {
      sortDirection = SortDirection.Descending;
    }

    this.instrumentGroupsRequest.sortColumn = event.active;
    this.instrumentGroupsRequest.sortDirection = sortDirection;
    this.dataRequested.emit(this.instrumentGroupsRequest);
  }

  private sortUnpaginatedData(sort: MatSortChangeEvent): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case this.idFieldName: return compare(a.instrumentGroupId, b.instrumentGroupId, isAsc);
        case this.nameFieldName: return compare(a.name, b.name, isAsc);
        case this.devicesFieldName: return compare(a.devicesCount, b.devicesCount, isAsc);
      }
    });
  }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { InstrumentTypeId, UpgradeRequestHistoryItem } from 'src/app/models';
import { Subscription } from 'rxjs';
import { DeviceUpgradeRequestHistoryService } from 'src/app/services';
import { DeviceSettingsConstants } from 'src/app/constants/deviceSettings-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';

@Component({
  selector: 'app-upgrade-request-history',
  templateUrl: './upgrade-request-history.component.html',
  styleUrls: ['./upgrade-request-history.component.scss']
})
export class UpgradeRequestHistoryComponent implements OnDestroy {

  public deviceRecentUpgradeRequestHistoryExists: boolean;
  public upgradeRequestHistoryItems: UpgradeRequestHistoryItem[] = [];

  public firmwareTranslateKey: string = TranslateConstants.UpgradeRequestHistoryFirmwareKey;

  public tryGetTranslateKey = DeviceSettingsConstants.tryGetTranslateKey;

  @Input() public serialNumber: string;
  @Input() public instrumentTypeId: number;

  private subscription: Subscription = new Subscription();

  constructor(
    private deviceUpgradeRequestHistoryService: DeviceUpgradeRequestHistoryService) { }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public loadRecentUpgradeRequestHistory(): void {
    this.subscription.add(this.deviceUpgradeRequestHistoryService.getUpgradeRequestHistory(this.serialNumber, this.instrumentTypeId)
      .subscribe(upgradeHistoryItems => {

        if (this.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
          this.firmwareTranslateKey = TranslateConstants.UpgradeRequestHistoryModKey;
        }
        else {
          this.firmwareTranslateKey = TranslateConstants.UpgradeRequestHistoryFirmwareKey;
        }

        if (upgradeHistoryItems !== undefined && upgradeHistoryItems.length > 0) {
          this.deviceRecentUpgradeRequestHistoryExists = true;
          upgradeHistoryItems.forEach(uhi => {
            if (uhi.deviceSettings) {
              uhi.deviceSettings.forEach(ds => {
                if (ds.currentState !== undefined) {
                  ds.isCurrentStateBoolean = typeof ds.currentState == 'boolean';
                }
              });
            }
          });
          this.upgradeRequestHistoryItems = upgradeHistoryItems;
        } else {
          this.deviceRecentUpgradeRequestHistoryExists = false;
        }
      }));
  }

  public isObject(item: unknown): boolean {
    return typeof item === 'object';
  }
}

import { PublishedFlag } from './enum.models';
import { Version } from './version.model';

export interface AssayDefinition {
  assayDefinitionId: number;
  code: string;
  definitionName: string;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface AssayInterface {
  assayInterfaceId: number;
  version: number;
  instrumentTypeId: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
}

export interface Assay {
  assayId: number;
  lnItemNumber: string;
  title: string;
  notes: string;
  version: Version;
  instrumentFileTypeId: number;
  assayInterfaceId: number;
  instrumentTypeId: number;
  modelIds: number[];
  assayDefinitionId: number;
  mD5: string;
  publishedFlag: PublishedFlag;
  fileUri: string;
  firmwareIds: number[];
  countryIds: number[];
  interfaceVersion?: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  devicesCount: number;
  header?: string;
}

export interface AssayForm extends Assay {
  major?: number;
  minor?: number;
  revision?: number;
}

export interface AssayIdentifier{
  code: string;
  version: Version;
  interfaceVersion: number;
}

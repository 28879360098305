import { enableProdMode, Injector, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { ConfigurationService } from 'src/app/services';
import { APP_BASE_HREF } from '@angular/common';
import { InteractionType, Logger, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { v4 } from 'uuid';

if (environment.production) {
  enableProdMode();
}

export function loggerCallback(logLevel: LogLevel, message: string): void {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(`msal: ${message}`);
      return;
    case LogLevel.Info:
      console.debug(`msal: ${message}`);
      return;
    case LogLevel.Verbose:
      console.debug(`msal: ${message}`);
      return;
    case LogLevel.Warning:
      console.warn(`msal: ${message}`);
      return;
  }
}

let appBaseHref = window['base-href'] as string;
if (appBaseHref === '{BaseHrefReplacement}') {
  appBaseHref = '/';
}

const injector = Injector.create({
  providers: [
    { provide: APP_BASE_HREF, useValue: appBaseHref },
    { provide: HttpClient, deps: [HttpHandler] },
    { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    { provide: ConfigurationService, deps: [HttpClient, APP_BASE_HREF] }]
});

const configurationService = injector.get(ConfigurationService as Type<ConfigurationService>);
void configurationService.initializeConfiguration(environment, true).then(() => {
  const currentConfig = configurationService.getConfiguration();

  void PublicClientApplication.createPublicClientApplication(currentConfig.msal)
    .then(msalInstance => {
      msalInstance.setLogger(new Logger({
        correlationId: v4(),
        piiLoggingEnabled: false,
        loggerCallback
      }));

      const interceptorConfig: MsalInterceptorConfiguration = {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map(currentConfig.protectedResourceMap)
      };

      const guardConfig: MsalGuardConfiguration = {
        interactionType: InteractionType.Popup
      };

      platformBrowserDynamic([
        { provide: ConfigurationService, useValue: configurationService },
        { provide: MSAL_INSTANCE, useValue: msalInstance },
        { provide: MSAL_INTERCEPTOR_CONFIG, useValue: interceptorConfig },
        { provide: MSAL_GUARD_CONFIG, useValue: guardConfig }
      ])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    });
});

<div class="box">
  <div class="row header action-bar" *ngIf="mode !== ListMode.Filtered">
    <div class="search-bar">
      <mat-form-field>
        <mat-label>{{ 'DeviceList.Search.SerialNumber' | translate }}</mat-label>
        <input matInput type="text" [value]="searchInput" [formControl]="searchControl">
        <button *ngIf="searchInput" matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
          (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="!showOnlyUnregistered" class="filter-separator">
        {{ 'DeviceList.Search.And' | translate }}
      </div>
      <div *ngIf="!showOnlyUnregistered" class="filter">
        <mat-form-field>
          <mat-label>{{ 'DeviceList.Search.Country' | translate }}</mat-label>
          <mat-chip-grid #countryChipList>
            <mat-chip-row *ngFor="let country of searchCountries" (removed)="removeCountrySearch(country)" matChipRemove
              [attr.aria-label]="'DeviceList.Search.RemoveCountry' | translate : {countryName: country.name}">
              {{country.name}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input [placeholder]="'DeviceList.Search.Country' | translate" #countryInput
            [formControl]="searchCountryControl" [matChipInputFor]="countryChipList" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCountrySearch($event)">
            <mat-option *ngFor="let country of filteredSearchCountries | async" [value]="country">
              {{country.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div *ngIf="!showOnlyUnregistered" class="filter-separator">
        {{ 'DeviceList.Search.And' | translate }}
      </div>
      <div *ngIf="!showOnlyUnregistered" class="filter">
        <mat-form-field>
          <mat-label>{{ statusSearchLabelTranslateKey | translate }}</mat-label>
          <mat-select [formControl]="connectionStateSearchControl" [(value)]="connectionStateSearchInput">
            <mat-option [value]="value[1]"
              *ngFor="let value of statusSearchFilterValues">
              {{ value[0] | translate }}
            </mat-option>
          </mat-select>
          <button *ngIf="connectionStateSearchInput" matSuffix mat-icon-button 
            [attr.aria-label]="'Global.Clear' | translate" (click)="clearConnectionStateSearch()">
            <mat-icon>close</mat-icon>
          </button>          
        </mat-form-field>
      </div>      
      <div *ngIf="!showOnlyUnregistered" class="filter-separator">
        {{ 'DeviceList.Search.And' | translate }}
      </div>
      <div *ngIf="!showOnlyUnregistered" class="filter">
        <mat-form-field>
          <mat-label>{{ firmwareVersionSearchLabelTranslateKey | translate }}</mat-label>
          <input matInput type="text" [value]="firmwareVersionSearchInput" [formControl]="firmwareVersionSearchControl">
          <button *ngIf="firmwareVersionSearchInput" matSuffix mat-icon-button
            [attr.aria-label]="'Global.Clear' | translate" (click)="clearFirmwareSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput style="display: none">
          <mat-checkbox labelPosition="after" [formControl]="firmwareVersionLessThanControl"
            [checked]="firmwareVersionLessThan">{{ 'DeviceList.Search.LessThan' | translate }}</mat-checkbox>
        </mat-form-field>
      </div>
      <div *ngIf="!showOnlyUnregistered && showAssays" class="filter-separator">
        {{ 'DeviceList.Search.And' | translate }}
      </div>
      <div *ngIf="!showOnlyUnregistered && showAssays" class="filter">
        <mat-form-field>
          <mat-label>{{ 'DeviceList.Search.AssayCode' | translate }}</mat-label>
          <input matInput type="text" [value]="amfCodeSearchInput" [formControl]="amfCodeSearchControl">
          <button *ngIf="amfCodeSearchInput" matSuffix mat-icon-button [attr.aria-label]="'Global.Clear' | translate"
            (click)="clearAMFCodeSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput style="display: none">
          <mat-checkbox labelPosition="after" [formControl]="assayNegateControl" [checked]="assayNegate">{{
            'DeviceList.Search.NotEquals' | translate }}
          </mat-checkbox>
        </mat-form-field>
      </div>
      <div *ngIf="!showOnlyUnregistered && showLanguages" class="filter-separator">
        {{ 'DeviceList.Search.And' | translate }}
      </div>
      <div *ngIf="!showOnlyUnregistered && showLanguages" class="filter">
        <mat-form-field>
          <mat-label>{{ 'DeviceList.Search.LanguageCode' | translate }}</mat-label>
          <input matInput type="text" [value]="languageCodeSearchInput" [formControl]="languageCodeSearchControl">
          <button *ngIf="languageCodeSearchInput" matSuffix mat-icon-button
            [attr.aria-label]="'Global.Clear' | translate" (click)="clearLanguageCodeSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput style="display: none">
          <mat-checkbox labelPosition="after" [formControl]="languageNegateControl" [checked]="languageNegate">{{
            'DeviceList.Search.NotEquals' | translate }}
          </mat-checkbox>
        </mat-form-field>
      </div>
    </div>
    <button mat-icon-button class="refresh" (click)="refreshData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="row warning-message" *ngIf="mode === ListMode.Multiselect">
    <ng-container *ngIf="selectAllPages">
      <div
        [innerHTML]="'DeviceList.SelectionStatus.AllInstruments' | translate: {allInstrumentsCount: length | number}">
      </div>
    </ng-container>
    <ng-container *ngIf="!selectAllPages">
      <div
        [innerHTML]="'DeviceList.SelectionStatus.PartialInstruments' | translate: {selectedInstrumentsCount: allCheckedDeviceInformationArrayInternal.length | number, allInstrumentsCount: length | number}">
      </div>
    </ng-container>
  </div>
  <div class="row content">
    <div>
      <table mat-table [dataSource]="dataSource" matSort matSortDirection="asc"
        matSortDisableClear="true" (matSortChange)="sort($event)">
        <ng-container [matColumnDef]="multiselectFieldName">
          <th mat-header-cell *matHeaderCellDef disabled mat-sort-header>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="multi-select">
              <div>
                <mat-icon
                  *ngIf="length > 0 && (selectAllPages || allCheckedDeviceInformationArrayInternal.length === length)">
                  check_box
                </mat-icon>
                <mat-icon
                  *ngIf="!selectAllPages && allCheckedDeviceInformationArrayInternal.length > 0  && allCheckedDeviceInformationArrayInternal.length !== length">
                  indeterminate_check_box</mat-icon>
                <mat-icon
                  *ngIf="allCheckedDeviceInformationArrayInternal.length <= 0">check_box_outline_blank</mat-icon>
                <mat-icon>arrow_drop_down</mat-icon>
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <button class="context-option" mat-menu-item (click)="checkAll()" *ngIf="showSelectAllPagesMultiselect">
                <mat-icon [class.transparent]="!selectAllPages">check
                </mat-icon>
                {{ 'DeviceList.Selection.AllInstruments' | translate: {allInstrumentsCount: length | number} }}
              </button>
              <button class="context-option" mat-menu-item (click)="checkCurrentPage()">
                <mat-icon [class.transparent]="!isCurrentPageChecked()">
                  check</mat-icon>
                {{ 'DeviceList.Selection.CurrentPage' | translate: {pageCount: dataSource.data.length | number} }}
              </button>
              <button class="context-option" mat-menu-item (click)="uncheckAll()">
                <mat-icon [class.transparent]="allCheckedDeviceInformationArrayInternal.length > 0">check</mat-icon>
                {{ 'DeviceList.Selection.Clear' | translate }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            <input [disabled]="!canAddUpdate" type="checkbox" [checked]="isChecked(deviceInformation)"
              (change)="selectionChanged($event, deviceInformation)">
          </td>
        </ng-container>
        <ng-container [matColumnDef]="singleselectFieldName">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let deviceInformation">
            <input [disabled]="!canAddUpdate" type="radio" name="singleselect" [checked]="isChecked(deviceInformation)"
              (change)="singleSelectionChanged($event, deviceInformation)">
          </td>
        </ng-container>
        <ng-container [matColumnDef]="idFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.Id' | translate }} </th>
          <td mat-cell *matCellDef="let deviceInformation"> {{deviceInformation.deviceInformationId}} </td>
        </ng-container>
        <ng-container [matColumnDef]="serialNumberFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DeviceList.Headers.SerialNumber' | translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            <div class="serial-number-field">
              <a *ngIf="showViewInstrumentLink" target="_blank"
                [routerLink]="[ instrumentsUrl, deviceInformation.serialNumber ]">
                <mat-icon class="small-icon" color="accent">open_in_new</mat-icon>
              </a>
              <div [class.clickable]="serialNumberSelectable"
                (click)="serialNumberSelectable && onEditDevice(deviceInformation)">{{deviceInformation.serialNumber}}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="lnItemNumberFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.LnItemNumber' | translate }} </th>
          <td mat-cell *matCellDef="let deviceInformation">{{deviceInformation.lnItemNumber}}</td>
        </ng-container>
        <ng-container [matColumnDef]="instrumentTypeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.InstrumentType' | translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{getInstrumentTypeDisplayValue(deviceInformation.instrumentTypeId)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="modelFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.InstrumentTypeModel' | translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{getInstrumentTypeModelDisplayValue(deviceInformation)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="countryFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.Country' | translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{getCountryDisplayValue(deviceInformation.countryId)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="firmwareVersionFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ firmwareVersionHeaderLabelTranslateKey | translate
            }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            <ng-container *ngIf="deviceInformation.firmwareVersion; else noFirmware">
              {{deviceInformation.firmwareVersion?.major}}.{{deviceInformation.firmwareVersion?.minor}}.{{deviceInformation.firmwareVersion?.revision}}
            </ng-container>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="appswFieldName">
          <th mat-header-cell *matHeaderCellDef disabled mat-sort-header> {{ 'DeviceList.Headers.AppswVersion' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{getAppswDisplayValue(deviceInformation)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="assaysFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DeviceList.Headers.Assays' | translate }} </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{deviceInformation.currentAssayVersions ? deviceInformation.currentAssayVersions ?.length : '0'}}</td>
        </ng-container>
        <ng-container [matColumnDef]="languagesFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DeviceList.Headers.Languages' | translate }} </th>
          <td mat-cell *matCellDef="let deviceInformation">
            {{deviceInformation.currentLanguageVersions ? deviceInformation.currentLanguageVersions ?.length : '0'}}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="virenaOptInFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DeviceList.Headers.VirenaOptIn' | translate }}
          </th>
          <td mat-cell *matCellDef="let deviceInformation"> {{deviceInformation.virenaOptIn}} </td>
        </ng-container>
        <ng-container [matColumnDef]="statusFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="showOnlyUnregistered"> {{ 'Global.Status' | translate }} </th>
          <td mat-cell *matCellDef="let deviceInformation">
            <div class="status" [class]="getStatusClassName(deviceInformation)"
              [matTooltip]="getStatusTooltip(deviceInformation)"></div>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="actionsFieldName" *ngIf="mode === ListMode.All">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let deviceInformation">
            <div *ngIf="canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditDevice(deviceInformation)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="onDeleteDevice(deviceInformation)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditDevice(deviceInformation)">
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="actionsFieldName" *ngIf="mode === ListMode.Filtered">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let deviceInformation">
            <div *ngIf="canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onRemoveDeviceInformation(deviceInformation)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToUse"> </tr>
      </table>
      <div class="no-data" *ngIf="getDeviceInformationResult && getDeviceInformationResult.results.length <= 0">
        {{ 'DeviceList.NoInstruments' | translate }}
      </div>
    </div>
  </div>

  <div class="row footer">
    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[100, 250, 500]"
      showFirstLastButtons (page)="getPageData($event)">
    </mat-paginator>
  </div>
</div>

<ng-template #noFirmware>-</ng-template>

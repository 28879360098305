import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '../constants/translate-constants';
import { translate } from '../shared/translateServiceHelper';

export class MissingStartTokenError extends Error {
  constructor(translateService: TranslateService) {
    super();
    this.name = translate(translateService, TranslateConstants.FileHeaderParsingMissingStartTokenNameKey);
    this.message = translate(translateService, TranslateConstants.FileHeaderParsingMissingStartTokenMessageKey);
  }
}

export class MissingEndTokenError extends Error {
  constructor(translateService: TranslateService) {
    super();
    this.name = translate(translateService, TranslateConstants.FileHeaderParsingMissingEndTokenNameKey);
    this.message = translate(translateService, TranslateConstants.FileHeaderParsingMissingEndTokenMessageKey);
  }
}

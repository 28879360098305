import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import {
  CSVExport
} from 'src/app/models';
import { expectedErrorsString } from '../constants/interceptors.constants';
import * as httpFields from '../constants/httpFields-constants';
import { InactivityService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class ExportsService {

  private apiUrl: string = this.configurationService.getApiUrl();
  private getCurrentInstrumentExportUrlUrl = '/GetCurrentInstrumentExportUrl';
  private generateInstrumentExportAndSendEmailUrl = '/GenerateInstrumentExportAndSendEmail';
  private getCurrentRegistrationFailureExportUrlUrl = '/GetCurrentRegistrationFailureExportUrl';
  private generateRegistrationFailureExportAndSendEmailUrl = '/GenerateRegistrationFailureExportAndSendEmail';
  private deviceInformationUrl = '/DeviceInformation';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService,
    private inactivityService: InactivityService) { }

  public getCurrentInstrumentExportUrl(instrumentTypeId: number): Observable<CSVExport> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getCurrentInstrumentExportUrlUrl}`;
    const params = new HttpParams()
      .set(httpFields.instrumentTypeIdField, instrumentTypeId.toString());
    const headers = new HttpHeaders()
      .set(expectedErrorsString, ['404']);
    return this.httpClient.get<CSVExport>(query, { params, headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public getCurrentRegistrationFailureExportUrl(): Observable<CSVExport> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.getCurrentRegistrationFailureExportUrlUrl}`;
    const headers = new HttpHeaders()
      .set(expectedErrorsString, ['404']);
    return this.httpClient.get<CSVExport>(query, { headers })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public generateInstrumentExportAndSendEmail(email: string, instrumentTypeId: number): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.generateInstrumentExportAndSendEmailUrl}`;
    return this.httpClient.post<unknown>(query, { email, instrumentTypeId })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }

  public generateRegistrationFailureExportAndSendEmail(email: string): Observable<unknown> {
    const query = `${this.apiUrl}${this.deviceInformationUrl}${this.generateRegistrationFailureExportAndSendEmailUrl}`;
    const httpParams: HttpParams = new HttpParams()
      .set(httpFields.emailField, email || '');
    return this.httpClient.get<unknown>(query, { params: httpParams })
      .pipe(tap(() => this.inactivityService.activityNow()));
  }


  public downloadFile(downloadUrl: string, fileName: string): void {
    this.inactivityService.activityNow();
    this.httpClient.get(downloadUrl, { responseType: 'blob' }).subscribe((file: Blob) => {
      const blobUrl = window.URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();
    });
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { BrowserEntityRequest, DeviceInformation, FolderEntry, InstrumentTypeId, MatCheckboxChangeEvent, MatPageChangeEvent, MatSortChangeEvent } from 'src/app/models';
import { ConfigurationService, DeviceFileService, NotificationService, SelectionAndCacheService } from 'src/app/services';
import { FormControl } from '@angular/forms';
import { DeviceSettingsConstants } from 'src/app/constants/deviceSettings-constants';
import { tryGetSettingsArrayFromJson } from 'src/app/shared/utils';
import { MatOptionSelectionChange } from '@angular/material/core';
import * as keyConstants from '../../constants/key-constants';
import dayjs from 'dayjs';

export interface FileBrowserFolderEntry extends FolderEntry {
  descendantInAllowlist: boolean;
}

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.scss']
})
export class FileBrowserComponent implements OnInit, OnDestroy {
  private serialNumber: string;

  private subscription: Subscription = new Subscription();

  @Input() public deviceInformation: DeviceInformation;
  @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('searchTextInput') searchTextInput: ElementRef<HTMLInputElement>;

  public readonly multiselectFieldName = 'multiselect';
  public readonly nameFieldName = 'name';
  public readonly sizeFieldName = 'size';
  public readonly lastModifiedDateFieldName = 'lastModifiedDate';
  public displayedColumns: string[] = [this.multiselectFieldName, this.nameFieldName, this.sizeFieldName, this.lastModifiedDateFieldName];
  public columnsToUse = this.displayedColumns;

  public pageIndex = 0;
  public pageSize = 100;
  public totalEntries = 0;
  public dataSource: MatTableDataSource<FileBrowserFolderEntry> = new MatTableDataSource<FileBrowserFolderEntry>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public allCheckedFolderEntriesArrayInternal: FileBrowserFolderEntry[] = [];
  public currentPageUploadableCount = 0;
  private maxUploadRequestsCount = 500;
  public maxUploadRequestsReached = false;

  public allowlistFolderPaths: string[];
  public currentFolderPathDisplay: string;
  private currentFolderPath: string;
  private baseFolderPath: string;
  public selectedFoldersStack: FileBrowserFolderEntry[] = [];
  public searchControl = new FormControl('');
  public searchText: string;
  public showSearchTextDatePicker = false;
  private static readonly folderPathsToShowSearchTextDatePicker = ['C:\\ProgramData\\Tecan\\LoggingServer\\LogFiles\\'];
  private sortColumn = 'name';
  private sortDescending = false;

  private sortDataSubject: Subject<MatSortChangeEvent> = new Subject();
  private pageDataSubject: Subject<void> = new Subject();
  private currentFolderPathUpdatedSubject: Subject<void> = new Subject();
  private changingDirectory = false;
  private quickSelectPreviousSelectedFoldersStack: FileBrowserFolderEntry[];
  private folderDelimiter = '/';

  constructor(
    private deviceFileService: DeviceFileService,
    private notificationService: NotificationService,
    private configurationService: ConfigurationService,
    private selectionAndCacheService: SelectionAndCacheService) {
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    if (!this.deviceInformation) {
      throw new Error('deviceInformation must be set');
    }

    this.serialNumber = this.deviceInformation.serialNumber;
    if (this.deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.folderDelimiter = '\\';
    }

    this.allowlistFolderPaths = tryGetSettingsArrayFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath) as string[];
    if (!this.allowlistFolderPaths) {
      this.allowlistFolderPaths = [];
    } else {
      this.allowlistFolderPaths = this.allowlistFolderPaths.sort((a, b) => a.localeCompare(b));
    }

    const clientConfig = this.configurationService.getClientConfiguration();
    const instrumentType = this.selectionAndCacheService.instrumentTypes.find(it => it.instrumentTypeId === this.deviceInformation.instrumentTypeId);
    this.baseFolderPath = clientConfig.fileBrowserBasePathByInstrumentType[instrumentType?.code] ?? 'C:/';

    this.dataSource.paginator = this.paginator;

    this.subscription.add(this.sortDataSubject.pipe(debounceTime(500))
      .subscribe((event: MatSortChangeEvent) => {
        this.sortColumn = event.active;
        this.sortDescending = event.direction === 'desc';
        this.refreshFolder();
      }));

    this.subscription.add(this.pageDataSubject.pipe(debounceTime(500))
      .subscribe(() => {
        this.refreshFolder();
      }));

    this.subscription.add(this.searchControl.valueChanges
      .subscribe((newValue: string) => {
        this.searchText = newValue;
      }));

    this.subscription.add(this.currentFolderPathUpdatedSubject.pipe(debounceTime(500))
      .subscribe(() => {
        this.refreshFolder();
      }));

    this.updateCurrentFolderPath();
  }

  public refreshFolder(): void {
    const entityRequest: BrowserEntityRequest = {
      path: this.currentFolderPath,
      deviceId: this.serialNumber,
      uploadRequest: false,
      maxPageSize: this.pageSize,
      page: this.pageIndex,
      searchRegex: this.searchText ? `(?i)${this.searchText}` : undefined,
      sortColumn: this.sortColumn,
      sortDescending: this.sortDescending
    };

    this.sendEntityRequest([entityRequest]);
  }

  public clearSearch(): void {
    this.searchText = '';
    this.refreshFolder();
  }

  public sort($event: MatSortChangeEvent): void {
    this.sortDataSubject.next($event);
  }

  public searchKeyDown($event: KeyboardEvent): void {
    if ($event.key === keyConstants.enterKey) {
      this.refreshFolder();
    }
  }

  public getPageData($event: MatPageChangeEvent): void {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.pageDataSubject.next();
  }

  public changeDirectory(entry: FileBrowserFolderEntry): void {
    if (entry.type !== 'folder' || !entry.authorized || this.changingDirectory) {
      return;
    }

    this.changingDirectory = true;

    try {
      this.clearSelectedFolderEntries();
      this.selectedFoldersStack.push(entry);
      this.updateCurrentFolderPath();
    }
    catch {
      this.changingDirectory = false;
    }
  }

  public upOneDirectory(): void {
    if (this.selectedFoldersStack.length === 0) {
      return;
    }

    this.changingDirectory = true;

    try {
      this.clearSelectedFolderEntries();
      this.selectedFoldersStack.pop();
      this.updateCurrentFolderPath();
    }
    catch {
      this.changingDirectory = false;
    }
  }

  public isCurrentPageChecked(): boolean {
    const someValuesAreNotIncluded = this.dataSource.data.some(x => !this.allCheckedFolderEntriesArrayInternal.find(folderEntry => folderEntry.name == x.name));
    return !someValuesAreNotIncluded;
  }

  public checkCurrentPage(): void {
    this.dataSource.data.forEach(folderEntry => {
      if (!folderEntry.authorized || !folderEntry.downloadAllowed || folderEntry.type !== 'file' || this.maxUploadRequestsReached) {
        return;
      }
      const folderEntryFoundIndex = this.allCheckedFolderEntriesArrayInternal.findIndex(fe => fe.name === folderEntry.name);
      if (folderEntryFoundIndex < 0) {
        this.allCheckedFolderEntriesArrayInternal.push(folderEntry);

        this.updateMaxUploadRequestsReached();
      }
    });
  }

  public uncheckAll(): void {
    this.clearSelectedFolderEntries();
  }

  public isChecked(folderEntry: FolderEntry): boolean {
    if (!folderEntry) {
      return false;
    }
    return this.allCheckedFolderEntriesArrayInternal.find(fe => fe.name === folderEntry.name) !== undefined;
  }

  public selectionChanged($event: MatCheckboxChangeEvent, folderEntry: FileBrowserFolderEntry): void {

    const folderEntryFoundIndex = this.allCheckedFolderEntriesArrayInternal.findIndex(fe => fe.name === folderEntry.name);

    if (!($event.currentTarget.checked) && folderEntryFoundIndex > -1) {
      this.allCheckedFolderEntriesArrayInternal.splice(folderEntryFoundIndex, 1);
    } else {
      this.allCheckedFolderEntriesArrayInternal.push(folderEntry);
    }

    this.updateMaxUploadRequestsReached();
  }

  public requestSelectedEntriesBeUploaded(): void {
    const entityRequsts: BrowserEntityRequest[] = [];
    this.allCheckedFolderEntriesArrayInternal.forEach(e => {
      entityRequsts.push({
        path: `${this.currentFolderPath}${e.name}`,
        deviceId: this.serialNumber,
        uploadRequest: true,
        // explicitly passing undefined for the search related parameters
        maxPageSize: undefined,
        page: undefined,
        searchRegex: undefined,
        sortColumn: undefined,
        sortDescending: undefined
      });
    });

    this.sendEntityRequest(entityRequsts);
  }

  public close(): void {
    this.closeEmitter.emit();
  }

  public folderPathQuickSelect($event: MatOptionSelectionChange): void {
    $event.source.deselect(false);

    this.changingDirectory = true;

    try {
      let basePathToOmit = this.baseFolderPath;
      if (this.deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
        basePathToOmit = basePathToOmit.replace('/', this.folderDelimiter);
      }

      const folders = (<string>$event.source.value).replace(basePathToOmit, '').split(this.folderDelimiter);
      this.quickSelectPreviousSelectedFoldersStack = [...this.selectedFoldersStack];
      this.selectedFoldersStack.length = 0;
      folders.forEach(folder => {
        this.selectedFoldersStack.push({
          id: folder,
          name: folder,
          authorized: undefined,
          descendantInAllowlist: undefined,
          downloadAllowed: undefined,
          lastModifiedDate: undefined,
          size: undefined,
          type: 'folder'
        });
      });

      this.clearSelectedFolderEntries();
      this.updateCurrentFolderPath();
    } catch {
      this.changingDirectory = false;
    }
  }

  public dateChanged(selectedDate: Date): void {
    this.searchText = dayjs(selectedDate).format('YYYY-MM-DD');
    setTimeout(() => {
      this.searchTextInput.nativeElement.focus();
      this.searchTextInput.nativeElement.setSelectionRange(this.searchText.length, this.searchText.length);
    }, 200); // having to wait otherwise the mat-datepicker-toggle steals the focus back
  }

  private updateCurrentFolderPath(notifyOfUpdate = true) {
    let newCurrentFolderPath = this.baseFolderPath;
    if (!newCurrentFolderPath.endsWith('/')) {
      newCurrentFolderPath += '/';
    }
    if (this.selectedFoldersStack.length > 0) {
      newCurrentFolderPath += `${this.selectedFoldersStack.map(fe => fe.name).join('/')}/`;
    }

    this.currentFolderPath = newCurrentFolderPath;
    if (this.deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.currentFolderPathDisplay = this.currentFolderPath.replaceAll('/', '\\');
    } else {
      this.currentFolderPathDisplay = this.currentFolderPath;
    }

    this.showSearchTextDatePicker = FileBrowserComponent.folderPathsToShowSearchTextDatePicker.some(path => this.currentFolderPathDisplay.startsWith(path));

    if (notifyOfUpdate) {
      this.searchText = '';
      this.currentFolderPathUpdatedSubject.next();
    }
  }

  private sendEntityRequest(entityRequests: BrowserEntityRequest[]): void {
    if (!entityRequests || entityRequests.length === 0) {
      return;
    }

    if (entityRequests != null) {
      this.subscription.add(this.deviceFileService.getDeviceDirectoryInfo(entityRequests).subscribe({
        next: results => {
          if (entityRequests.length === 1 && !entityRequests[0].uploadRequest) {

            let fileBrowserFolderEntries: FileBrowserFolderEntry[];
            if (results[0].folderEntries) {
              fileBrowserFolderEntries = results[0].folderEntries.map(entry => {
                let descendantInAllowlist = false;
                if (entry.type === 'folder') {
                  let entryFullDisplayPath = `${this.currentFolderPathDisplay}${entry.name}`;
                  if (!entryFullDisplayPath.endsWith(this.folderDelimiter)) {
                    entryFullDisplayPath += this.folderDelimiter;
                  }

                  descendantInAllowlist = this.allowlistFolderPaths.some(allowlistFolderPath => {
                    let pathToCompare = allowlistFolderPath;
                    if (!pathToCompare.endsWith(this.folderDelimiter)) {
                      pathToCompare += this.folderDelimiter;
                    }
                    if (entryFullDisplayPath.length <= pathToCompare.length) {
                      return pathToCompare.startsWith(entryFullDisplayPath);
                    } else {
                      return entryFullDisplayPath.startsWith(pathToCompare);
                    }
                  });
                }
                const fileBrowserFolderEntry = {
                  ...entry,
                  descendantInAllowlist
                };
                return fileBrowserFolderEntry;
              });
            } else {
              fileBrowserFolderEntries = [];
            }

            this.dataSource.data = fileBrowserFolderEntries;
            this.totalEntries = results[0].totalItems;
            this.currentPageUploadableCount = this.dataSource.data.filter(fe => fe.authorized && fe.downloadAllowed && fe.type === 'file').length;
            this.quickSelectPreviousSelectedFoldersStack = undefined; // successfully retrieved a folder, so clear out as no need to switch back
          } else {
            this.notificationService.success(TranslateConstants.FileBrowserRequestSuccessKey);
          }

          this.changingDirectory = false;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.deviceFileService.processDeviceDirctoryInfoBadRequestReponse(err);

            if (this.quickSelectPreviousSelectedFoldersStack) {
              // Quick Select failed, resetting to the previous selected folder
              this.selectedFoldersStack = this.quickSelectPreviousSelectedFoldersStack;
              this.quickSelectPreviousSelectedFoldersStack = undefined;
              this.updateCurrentFolderPath(false);
            } else if (this.changingDirectory) {
              this.selectedFoldersStack.pop();
              this.updateCurrentFolderPath(false);
            }
          } else {
            this.notificationService.error(TranslateConstants.FileBrowserConnectionFailedKey);
            this.close();
          }

          this.changingDirectory = false;
        }
      }));
    }
  }

  private clearSelectedFolderEntries() {
    this.allCheckedFolderEntriesArrayInternal.length = 0;
    this.updateMaxUploadRequestsReached();
  }

  private updateMaxUploadRequestsReached() {
    this.maxUploadRequestsReached = this.allCheckedFolderEntriesArrayInternal.length === this.maxUploadRequestsCount;
  }
}

import { Component, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { ConfirmationDialogResponse, ConfirmationDialogData } from 'src/app/models';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  public ConfirmationDialogComponent = ConfirmationDialogComponent;
  public confirmationForm: FormGroup<{
    amf?: FormGroup<{
      forcePossibleAMFDowngrade: FormControl<boolean>
    }>
  }>;
  public static readonly AMF_FormKey = 'amf';
  public static readonly AMF_ForcePossibleAMFDowngrade_FormKey = 'forcePossibleAMFDowngrade';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private mdDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService) {

    this.confirmationForm = this.fb.group({});
    if (data.allowAMFForceDowngrade) {
      /* eslint-disable @typescript-eslint/unbound-method */
      this.confirmationForm.addControl(ConfirmationDialogComponent.AMF_FormKey, this.fb.group(
        {
          forcePossibleAMFDowngrade: [false],
        }));
      /* eslint-enable @typescript-eslint/unbound-method */
    }
  }

  public cancel(): void {
    this.close({
      result: false
    });
  }

  public async confirm(): Promise<void> {
    const forcePossibleAMFDowngrade = this.confirmationForm.value.amf?.forcePossibleAMFDowngrade;
    if (forcePossibleAMFDowngrade) {
      const extraConfirmationOptions: ConfirmationDialogData = {
        title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.ForceDowngradesTitleKey, TranslateConstants.AssayKey),
        cancelText: translate(this.translateService, TranslateConstants.CancelKey),
        confirmText: translate(this.translateService, TranslateConstants.YesKey),
        message: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.ForceDowngradesWarningKey, TranslateConstants.AssayKey)
      };

      const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
        data: extraConfirmationOptions
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      if (!result || !result.result) {
        return;
      }
    }

    this.close({
      result: true,
      forceAMFDowngrade: forcePossibleAMFDowngrade
    });
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close({
      result: false
    });
  }

  private close(result: ConfirmationDialogResponse): void {
    this.mdDialogRef.close(result);
  }
}

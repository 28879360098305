import { Version } from './version.model';

export type DeviceFileType = 'log' | 'lut' | 'uploaded' | 'iot_agent_internal';

export interface DeviceFilesRequest {
  type: DeviceFileType;
  serialNumber: string;
  instrumentTypeId: number;
  firmwareVersion: Version;
}

export interface DeviceFileMetadata {
  serialNumber: string;
  fileName: string;
  blobName: string;
  type: DeviceFileType;
  size?: number;
  lastModifiedDate?: Date;
}

export interface DeviceFileStatus {
  serialNumber: string;
  blobName: string;
  information: DeviceFileStatusInformation;
  timestamp: string;
}

export interface DeviceFileStatusInformation {
  message: string;
  targetFileLocation?: string;
  errorMessage?: string;
}

export interface DeviceFilesDownloadRequest {
  serialNumber: string;
  email: string;
  blobNames: string[];
  type: DeviceFileType;
}

export interface RecentDeviceFileStatusRequest {
  serialNumber: string;
  email: string;
}

import { KeyValue } from '@angular/common';

// Preserve original property order
export const sortOriginalOrder = (a: KeyValue<unknown, unknown>, b: KeyValue<unknown, unknown>): number => {
  return 0;
};

// Order by ascending property value
export const sortKeyStringAscOrder = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number => {
  return sortStrings(a.key, b.key, true);
};

// Order by descending property value
export const sortKeyStringDescOrder = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number => {
  return sortStrings(a.key, b.key, false);
};

// Order by ascending property value
export const sortValueStringAscOrder = (a: KeyValue<unknown, string>, b: KeyValue<unknown, string>): number => {
  return sortStrings(a.value, b.value, true);
};

// Order by descending property value
export const sortValueStringDescOrder = (a: KeyValue<unknown, string>, b: KeyValue<unknown, string>): number => {
  return sortStrings(a.value, b.value, false);
};

const sortStrings = (a: string, b: string, ascending: boolean): number => {
  const comparisonResult = a.localeCompare(b);
  if (ascending) {
    return comparisonResult;
  } else {
    return comparisonResult * -1; // inverts the comparison
  }
};

import { AssayIdentifier } from './assay.models';
import { PagingInformation, SortDirection, SearchRequest } from './device.models';
import { LanguageIdentifier } from './language.models';
import { Version } from './version.model';

export enum InstrumentTypeId {
  Unknown = 0,
  // Sofia = 1,
  // Solana = 2,
  Savanna = 3,
  Sofia2 = 4,
  Vision = 5
}

export enum InstrumentTypeCode {
  Sofia2 = 'SL',
  Vision = 'VI'
}

export const InstrumentTypeIdsSupportingCertificateAuthentication = [InstrumentTypeId.Savanna.valueOf(), InstrumentTypeId.Sofia2.valueOf(), InstrumentTypeId.Vision.valueOf()];

export interface InstrumentType {
  instrumentTypeId: number;
  name: string;
  code: string;
  description: string;
  enabled: boolean
}

export interface InstrumentTypeModel {
  modelId: number;
  name: string;
  code: string;
  instrumentTypeId: number;
}

export interface InstrumentFileType {
  instrumentFileTypeId: number;
  name: string;
}

export interface InstrumentGroup {
  instrumentGroupId: number;
  instrumentTypeId: number;
  name: string;
  notes: string;
  devicesCount: number;
  usersCount: number;
}

export interface DeviceInformationId {
  deviceInformationId: number;
  serialNumber: string;
}

export interface UpsertInstrumentGroupRequest {
  name: string;
  instrumentTypeId: number;
  notes: string;
  deviceInformationIds?: number[];
  userIds: number[];
}

export interface CancelUpgradeRequest {
  serialNumbers?: string[];
  instrumentTypeId: number;
  firmwareVersion?: Version;
  assays?: { [key: string]: CancelUpgradeVersion[]; };
  languages?: { [key: string]: CancelUpgradeVersion[]; };
  deviceSettings?: string[];
}

export interface CancelMultipleUpgradeRequest extends CancelUpgradeRequest {
  instrumentGroupIds?: number[];
  queryByInstrumentSearchRequest?: SearchRequest;
  queryByInstrumentGroupSearchRequest?: InstrumentGroupsRequest;
}

export interface CancelUpgradeVersion {
  version: Version;
  interfaceVersion: number;
}

export interface InstrumentGroupsRequest {
  instrumentGroupName: string;
  instrumentTypeId: number;
  pageNumber: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
}

export interface InstrumentGroupsResult {
  pagingInformation: PagingInformation;
  results: InstrumentGroup[];
  totalResults: number;
}

export interface InstrumentActionHistoryRow {
  timestamp: Date;
  logTimestamp: string;
  messageInformation: BasePayload;
}

export interface RetrySettings {
  time_delay_milliseconds?: number;
  number_retries?: number;
}

export interface DownloadSettings {
  timeout_seconds?: number;
}

export interface DeviceTwinChangeStatus<T extends Version | AssayIdentifier | LanguageIdentifier | string> {
  identifier: T;
  changed: boolean;
  reason: string;
}

export interface DeviceTwinChangeStatusWithCurrentState<T extends Version | AssayIdentifier | LanguageIdentifier | string> extends DeviceTwinChangeStatus<T> {
  currentState: { [key: string]: unknown } | boolean;
  isCurrentStateBoolean: boolean;
  unknownChangeStatus: boolean;
}

export interface UpgradeRequestHistoryItem {
  isSuccess: boolean;
  changesMade: boolean;
  errorMessage?: string;
  messageId: string;
  messageTimeStamp: Date;
  requestId: string;
  serialNumber: string;
  requestType: string;
  firmware?: DeviceTwinChangeStatus<Version>;
  assays?: DeviceTwinChangeStatus<AssayIdentifier>[];
  languages?: DeviceTwinChangeStatus<LanguageIdentifier>[];
  deviceSettings?: DeviceTwinChangeStatusWithCurrentState<string>[];
  requestUserId: string;
  requestUserName: string;
  forceAssayDowngrade: boolean;
}

export interface BasePayload {
  type: string;
  messageId: string;
  timeStamp: string;
  version: Version;
}

export interface C2DPendingMessage {
  serialNumber: string;
  messageId: string;
  messagePayload: BasePayload;
  messageProperties: { [key: string]: string };
  pendingHours: number;
  resendCount: number;
}

export interface InstrumentDefaultSettings {
  [key: string]: unknown;
  min_version?: string;
}

export enum InstrumentDefaultSettingsType {
  default = 'default'
}

export interface InstrumentDefaultSettingsTestResultUpload extends InstrumentDefaultSettings {
  enabled?: boolean;
  server?: string;
  api_path?: string;
  upload_delay_minutes?: number;
}

export interface InstrumentDefaultSettingsAllowlistFolderPaths extends InstrumentDefaultSettings {
  allowlist_folderpaths: string[];
}

export interface InstrumentDefaultSettingsMap {
  [key: string]: InstrumentDefaultSettings[]
}

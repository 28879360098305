<div class="container">
  <h1>{{ 'LanguageInterfaces.LanguageInterfaceVersions' | translate }}</h1>
  <mat-selection-list class="interface-list" [multiple]="false" hideSingleSelectionIndicator>
    <mat-list-option *ngFor="let interface of filteredInterfaces" [value]="interface">
      <div class="list-item">
        <span>{{interface.version}}</span>
        <button *ngIf="canAddUpdate" mat-icon-button (click)="onDeleteVersion(interface)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-list-option>
  </mat-selection-list>

  <div class="action-bar">
    <button *ngIf="canAddUpdate" mat-raised-button color="primary" [disabled]="!canAddUpdate || !selectedInstrumentType"
      (click)="onAddVersion(newVersionTemplate)">
      <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
    </button>
  </div>
</div>

<ng-template #newVersionTemplate>
  <div class="dialog-root">
    <h2>{{ 'Global.New' | translate }} {{ 'Languages.Language' | translate }} {{ 'Global.InterfaceVersion' | translate
      }}
    </h2>
    <mat-dialog-content>
      <mat-form-field class="full-width">
        <mat-label>{{ 'LanguageInterfaces.VersionNumber' | translate }}</mat-label>
        <input matInput [placeholder]="'LanguageInterfaces.VersionNumber'" (keypress)="onVersionKeyPress($event)"
          type="number" #version>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Cancel' | translate }}</button>
      <button mat-raised-button [mat-dialog-close]="{ version: version.value }" [disabled]="!version.value"
        color="primary">{{ 'Global.Create' | translate }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>

import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageFileDialogComponent } from '../language-file-dialog/language-file-dialog.component';
import { clone, filterItemsToSupportedInstrumentTypes } from 'src/app/shared/utils';
import { DataService, InstrumentTypeSelectionState, SelectionAndCacheService, NotificationService, UserPermissionService } from 'src/app/services';
import {
  PublishedFlag, Language, LanguageDefinition, LanguageInterface, Firmware, InstrumentType,
  InstrumentFileType, formatVersionForSort, PublishedFlagTranslateKeyMap, AccessLevel, UserPermission, LanguageFileDialogData, ConfirmationDialogData, ConfirmationDialogResponse, InstrumentTypeModel
} from 'src/app/models';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate } from 'src/app/shared/translateServiceHelper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-files',
  templateUrl: './language-files.component.html',
  styleUrls: ['./language-files.component.scss']
})
export class LanguageFilesComponent implements OnInit, AfterViewInit, OnDestroy {

  public codeFieldName = 'code';
  public versionFieldName = 'version';
  public idFieldName = 'id';
  public statusFieldName = 'status';
  public devicesCountFieldName = 'devicesCount';
  public modelIdsCountFieldName = 'modelIdsCount';

  public PublishedFlag = PublishedFlag;

  private displayedColumns: string[] = [this.idFieldName, 'title', this.codeFieldName, 'instrumentFileTypeId', 'instrumentTypeId', this.modelIdsCountFieldName,
    'interfaceVersion', this.versionFieldName, this.statusFieldName, this.devicesCountFieldName, 'actions'];
  public columnsToUse = this.displayedColumns;
  public languages: Language[] = [];
  public languageDefinitions: LanguageDefinition[] = [];
  public languageInterfaces: LanguageInterface[] = [];
  public availableFirmwares: Firmware[] = [];
  public instrumentTypes: InstrumentType[] = [];
  public instrumentFileTypes: InstrumentFileType[] = [];
  public dataSource: MatTableDataSource<Language> = new MatTableDataSource<Language>();
  public allFirmwares: Firmware[] = [];

  private subscription: Subscription = new Subscription();
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  @ViewChild(MatSort) private sort: MatSort;
  private unfilteredFirmwares: Firmware[] = [];
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private SELECTED_FIRMWARE: Firmware;
  private selectedModels: InstrumentTypeModel[];

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.SELECTED_FIRMWARE = null;
    }
  }

  get selectedFirmware(): Firmware {
    return this.SELECTED_FIRMWARE;
  }

  set selectedFirmware(firmware: Firmware) {
    if (this.SELECTED_FIRMWARE !== firmware) {
      this.SELECTED_FIRMWARE = firmware;
      this.loadLanguages();
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(
      forkJoin([
        this.dataService.getLanguageDefinitions(),
        this.dataService.getLanguageInterfaces(),
        this.dataService.getFirmwares(),
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.userPermissionService.isReadyEvent$.pipe(take(1))
      ])
        .subscribe((results: [LanguageDefinition[], LanguageInterface[], Firmware[], boolean, boolean]) => {
          this.languageDefinitions = results[0];
          this.languageInterfaces = results[1];
          this.unfilteredFirmwares = results[2];
          this.allFirmwares = results[2];
          this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
          this.instrumentFileTypes = this.selectionAndCacheService.instrumentFileTypes;

          this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
            this.updateColumnsToUse();
            this.selectedInstrumentType = selectionState.instrumentType;
            this.selectedModels = selectionState.models;

            this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageLanguageFiles], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);

            if (this.selectedInstrumentType) {
              this.allFirmwares = clone(this.unfilteredFirmwares).filter(x => x.instrumentTypeId === this.selectedInstrumentType.instrumentTypeId);
            } else {
              this.allFirmwares = clone(this.unfilteredFirmwares);
            }

            this.loadLanguages();
          }));

        }));

    // set version sort value
    this.dataSource.sortingDataAccessor = (language: Language, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case this.versionFieldName:
          return formatVersionForSort(language.version);
        case this.idFieldName:
          return language.languageId;
        case this.statusFieldName:
          return this.getPublishedFlagDisplayValue(language.publishedFlag);
        case this.codeFieldName:
          return this.getLanguageCode(language.languageDefinitionId);
        case this.modelIdsCountFieldName:
          return this.getInstrumentTypeModelsCount(language);
        default:
          return language[sortHeaderId] as string | number;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public getLanguageCode(languageDefinitionId: number): string {
    return this.languageDefinitions.find(x => x.languageDefinitionId === languageDefinitionId)?.code;
  }

  public loadLanguages(languageId?: number): void {
    this.subscription.add(this.dataService.getLanguages(this.selectedFirmware?.firmwareId, this.selectedInstrumentType?.instrumentTypeId, this.selectedModels?.map(m => m.modelId)).subscribe((languages: Language[]) => {
      this.languages = filterItemsToSupportedInstrumentTypes(this.instrumentTypes.map(i => i.instrumentTypeId), languages);

      this.dataSource.data = this.languages;
    }));
  }

  public getAllFirmwareIds(): number[] {
    return this.availableFirmwares.map(x => x.firmwareId);
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentTypeModelsCount(language: Language): number {
    const models = this.selectionAndCacheService.getModelsForInstrumentTypeId(language.instrumentTypeId)?.filter(m => language.modelIds.includes(m.modelId));
    return models?.length || 0;
  }

  public getInstrumentFileTypeDisplayValue(instrumentFileTypeId: number): string {
    return this.instrumentFileTypes?.find(i => i.instrumentFileTypeId === instrumentFileTypeId)?.name;
  }

  public getPublishedFlagDisplayValue(flag: PublishedFlag): string {
    return translate(this.translateService, PublishedFlagTranslateKeyMap.get(flag));
  }

  public getInterfacesForInstrument(instrumentId?: number): LanguageInterface[] {
    return this.languageInterfaces?.filter(i => i.instrumentTypeId == instrumentId);
  }

  public async onAddLanguage(): Promise<void> {
    await this.showEditDialog();
  }

  public async onEditLanguage(language: Language): Promise<void> {
    await this.showEditDialog(language);
  }

  private async showEditDialog(language?: Language): Promise<void> {
    if (language) {
      this.subscription.add(this.dataService.getLanguageById(language.languageId).subscribe(async (fullLanguage: Language) => {
        const options: LanguageFileDialogData = {
          language: fullLanguage
        };

        const dialogRef = this.dialog.open(LanguageFileDialogComponent, {
          data: options,
          width: '75%',
          disableClose: true
        });

        await firstValueFrom(dialogRef.afterClosed());

        this.loadLanguages();
      }));
    } else {
      language = {
        languageId: 0,
        languageDefinitionId: 0,
        languageInterfaceId: 0,
        lnItemNumber: null,
        fileUri: null,
        instrumentFileTypeId: 0,
        instrumentTypeId: 0,
        modelIds: [],
        countryIds: null,
        firmwareIds: [],
        notes: null,
        mD5: null,
        title: null,
        version: { major: 0, minor: 0, revision: 0 },
        publishedFlag: PublishedFlag.Unpublished,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null,
        devicesCount: 0
      };

      const options: LanguageFileDialogData = {
        language: language
      };

      const dialogRef = this.dialog.open(LanguageFileDialogComponent, {
        data: options,
        width: '75%',
        disableClose: true
      });

      await firstValueFrom(dialogRef.afterClosed());

      this.loadLanguages();
    }
  }

  public async onDeleteLanguage(language: Language): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.LanguageKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.LanguageKey, language.title)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteLanguage(language.languageId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.LanguageKey });
        this.loadLanguages();
      }));
    }
  }

  private updateColumnsToUse() {
    const tempColumnsToUse = [...this.displayedColumns];
    const removeModelColumn = this.selectionAndCacheService.modelsForSelectedInstrumentType?.length <= 1;
    if (removeModelColumn) {
      const indexOfModelIdsCountColumn = tempColumnsToUse.indexOf(this.modelIdsCountFieldName);
      if (indexOfModelIdsCountColumn !== -1) {
        tempColumnsToUse.splice(indexOfModelIdsCountColumn, 1);
      }
    }

    this.columnsToUse = tempColumnsToUse;
  }
}

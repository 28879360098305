import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject, firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ensureUrlEndsWithSlash } from '../shared/utils';
import { APP_BASE_HREF, } from '@angular/common';
import { expectedErrorsString } from '../constants/interceptors.constants';
import { Configuration, ApiHealthResult, ClientConfig } from 'src/app/models';

@Injectable()
export class ConfigurationService {

  public static ApiVersion = '1.0';

  private static readonly notInitializedError = new Error('ConfigurationService must be initialized first.');

  private httpClientWithInterceptor: HttpClient;
  private configuration: Configuration;
  private initialized = false;
  public isClientConfigInitialized = false;
  private initializationClientConfigPromise: Promise<number | void | undefined>;

  private configurationUrl = '/Configuration/GetClientConfiguration';

  private clientConfiguration = new BehaviorSubject<ClientConfig>(undefined);
  public clientConfigurationObservable = this.clientConfiguration.asObservable();

  constructor(private httpClient: HttpClient, @Inject(APP_BASE_HREF) private baseHref: string) { }

  public async initializeConfiguration(environment: { version: string, production: boolean }, writeLogMessages: boolean = true): Promise<void> {
    if (this.initialized) {
      console.debug('ConfigurationService already intitialized. Skipping initializeConfiguration.initializeConfiguration execution.');
      return;
    }

    let basePath = this.baseHref;
    if (!this.baseHref.endsWith('/')) {
      basePath = `${basePath}/`;
    }
    const configuration = await firstValueFrom(this.httpClient.get<Configuration>(`${basePath}assets/configuration.json`));

    this.configuration = {
      version: environment.version,
      production: environment.production,
      apiEndpoint: configuration.apiEndpoint,
      msal: configuration.msal,
      protectedResourceMap: configuration.protectedResourceMap,
      appInsightsKey: configuration.appInsightsKey
    };

    if (writeLogMessages) {
      console.log(`Application Version: ${this.configuration.version}`);
    }

    this.configuration.apiEndpoint.uri = ensureUrlEndsWithSlash(this.configuration.apiEndpoint.uri);

    const apiHealthCheck = await firstValueFrom(this.apiHealthCheck(this.configuration.apiEndpoint.uri, writeLogMessages));
    if (apiHealthCheck) {
      this.configuration.apiEndpoint.status = apiHealthCheck.status;

      if (writeLogMessages) {
        console.log(`API Status: ${this.configuration.apiEndpoint.status}, Version: ${apiHealthCheck.version}`);
      }
    }

    this.initialized = true;
  }

  public async initializeClientConfig(httpClientWithInterceptor: HttpClient): Promise<number | void | undefined> {
    if (!this.initialized) {
      throw ConfigurationService.notInitializedError;
    }

    this.httpClientWithInterceptor = httpClientWithInterceptor;

    if (this.isClientConfigInitialized) {
      return Promise.resolve<undefined>(undefined);
    }

    if (this.initializationClientConfigPromise !== undefined) {
      return this.initializationClientConfigPromise;
    }

    if (!this.configuration.apiEndpoint.status) {
      return Promise.reject('ConfigurationService: Unable to connect to API');
    }

    console.debug('Client Configuration: Initializing');
    this.initializationClientConfigPromise = new Promise<number | void | undefined>((resolve, reject) => {
      void this.callGetClientConfig()
        .then(() => {
          this.isClientConfigInitialized = true;
          console.debug('Client Configuration finished loading');
          resolve();
        }).catch((err: HttpErrorResponse) => {
          reject(err?.status);
        });
    });
    return this.initializationClientConfigPromise;
  }

  public getConfiguration(): Configuration {
    if (!this.initialized) {
      throw ConfigurationService.notInitializedError;
    }

    return this.configuration;
  }

  public getClientConfiguration(): ClientConfig {
    return this.clientConfiguration.value;
  }

  public getApiUrl(): string {
    return `${this.configuration.apiEndpoint.uri}api/v${ConfigurationService.ApiVersion}`;
  }

  private apiHealthCheck(endpoint: string, writeLogMessages: boolean): Observable<ApiHealthResult> {
    return this.httpClient.get<ApiHealthResult>(`${endpoint}health`)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (writeLogMessages) {
          console.error(`Unable to connect to API: ${err.message}`);
        }
        return of<ApiHealthResult>(undefined);
      }));
  }

  private async callGetClientConfig(): Promise<void> {
    const fullUrl = this.getApiUrl() + this.configurationUrl;
    const headers = new HttpHeaders()
      .set(expectedErrorsString, ['403']);
    const clientConfiguration = await firstValueFrom(this.httpClientWithInterceptor.get<ClientConfig>(fullUrl, { headers }));
    this.clientConfiguration.next(clientConfiguration);
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, filter, firstValueFrom, forkJoin } from 'rxjs';
import { Country, InstrumentFileType, InstrumentType, InstrumentTypeModel } from 'src/app/models';
import { InstrumentTypeSelectorComponent } from '../components/instrument-type-selector/instrument-type-selector.component';
import { DataService } from './data.service';

export class InstrumentTypeSelectionState {
  instrumentType?: InstrumentType;
  models?: InstrumentTypeModel[];
}

@Injectable({
  providedIn: 'root'
})
export class SelectionAndCacheService implements OnDestroy {

  private subscription = new Subscription();
  private initialized = false;

  // returns false until initialized
  private _isReadySubject = new BehaviorSubject(false);
  public get isReadyEvent$(): Observable<boolean> {
    return this._isReadySubject
      .pipe(filter(ready => ready === true));
  }

  private _selectedInstrumentTypeAndModels = new BehaviorSubject<InstrumentTypeSelectionState>({ models: [] });
  public get selectedInstrumentTypeAndModelsChangeEvent$(): Observable<InstrumentTypeSelectionState> {
    return this._selectedInstrumentTypeAndModels.asObservable();
  }
  public get selectedInstrumentType(): InstrumentType {
    return this._selectedInstrumentTypeAndModels.getValue()?.instrumentType;
  }

  private _instrumentTypes: InstrumentType[] = [];
  public get instrumentTypes(): InstrumentType[] {
    return this._instrumentTypes;
  }

  private _instrumentFileTypes: InstrumentFileType[] = [];
  public get instrumentFileTypes(): InstrumentFileType[] {
    return this._instrumentFileTypes;
  }

  private _allModels: InstrumentTypeModel[] = [];
  public get allModels(): InstrumentTypeModel[] {
    return this._allModels;
  }
  private _modelsForSelectedInstrumentType: InstrumentTypeModel[] = [];
  public get modelsForSelectedInstrumentType(): InstrumentTypeModel[] {
    return this._modelsForSelectedInstrumentType;
  }

  private _countries: Country[] = [];
  public get countries(): Country[] {
    return this._countries;
  }

  constructor(private dataService: DataService) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async initialize(): Promise<void> {
    if (this.initialized) {
      console.debug('SelectionAndCacheService already initialized.');
      return;
    }

    this.initialized = true;

    const result: [InstrumentType[], InstrumentFileType[], InstrumentTypeModel[], Country[]] = await firstValueFrom(
      forkJoin([
        this.dataService.getInstrumentTypes(),
        this.dataService.getInstrumentFileTypes(),
        this.dataService.getInstrumentTypeModels(),
        this.dataService.getCountries()
      ]));

    this._instrumentTypes = result[0];
    this._instrumentFileTypes = result[1];
    this._allModels = result[2];
    this._countries = result[3];

    this._isReadySubject.next(true);
  }

  public updateSelectedInstrumentTypeAndModels(instrumentType: InstrumentType, models: InstrumentTypeModel[], caller: string): void {
    if (caller != InstrumentTypeSelectorComponent.name) {
      throw new Error(`Only the ${InstrumentTypeSelectorComponent.name} should call this function.`);
    }
    this._modelsForSelectedInstrumentType = this.getModelsForInstrumentType(instrumentType);
    this._selectedInstrumentTypeAndModels.next({ instrumentType, models });
  }

  public getModelsForInstrumentType(instrumentType: InstrumentType): InstrumentTypeModel[] {
    return this.getModelsForInstrumentTypeId(instrumentType?.instrumentTypeId);
  }

  public getModelsForInstrumentTypeId(instrumentTypeId: number): InstrumentTypeModel[] {
    return this._allModels?.filter(itm => itm.instrumentTypeId === instrumentTypeId);
  }
}

import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription, firstValueFrom } from 'rxjs';
import { DeviceFileMetadata, DeviceFileStatus, DeviceFileType, DeviceFilesDownloadRequest, EmailTemplate, MatCheckboxChangeEvent, RecentDeviceFileStatusRequest, Version } from 'src/app/models';
import { DeviceFileService, NotificationService } from 'src/app/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateConstants } from 'src/app/constants/translate-constants';

@Component({
  selector: 'app-instrument-files-list',
  templateUrl: './instrument-files-list.component.html',
  styleUrls: ['./instrument-files-list.component.scss']
})
export class InstrumentFilesListComponent implements OnDestroy, OnInit {

  private subscription: Subscription = new Subscription();
  public readonly downloadFieldName = 'download';
  public readonly fileNameFieldName = 'fileName';
  public readonly sizeFieldName = 'size';
  public readonly lastModifiedDateFieldName = 'lastModifiedDate';
  public readonly statusFieldName = 'status';
  public readonly multiSelectFieldName = 'multiSelect';

  private displayedColumns: string[] = [this.downloadFieldName, this.fileNameFieldName, this.sizeFieldName, this.lastModifiedDateFieldName];
  public columnsToUse = this.displayedColumns;

  public allCheckedFilesArrayInternal: DeviceFileMetadata[] = [];

  public pageIndex = 0;
  public pageSize = 50;
  public totalFiles = 0;

  public dataSource: MatTableDataSource<DeviceFileMetadata> = new MatTableDataSource<DeviceFileMetadata>();
  public filesExist = false;
  public filesLoaded = false;

  /* eslint-disable @typescript-eslint/unbound-method */
  public reportForm = this.fb.group(
    {
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]]
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;
  @ViewChild('downloadFilesEmailTemplate', { static: false }) downloadFilesEmailTemplate: TemplateRef<unknown>;
  @ViewChild('getRecentFileStatusHistoryEmailTemplate', { static: false }) recentFileStatusHistoryEmailTemplate: TemplateRef<unknown>;

  @Input() public serialNumber: string;
  @Input() public instrumentTypeId: number;
  @Input() public firmwareVersion: Version;
  @Input() public deviceFileType: DeviceFileType;
  @Input() public noEntriesMessage: string;
  @Input() public refreshEntriesMessage: string;
  @Input() public multiDownloadMessage: string;
  @Input() public showStatusColumn = false;
  @Input() public enableMultiSelect = false;

  get allCheckedFilesArray(): DeviceFileMetadata[] {
    return this.allCheckedFilesArrayInternal;
  }

  @Input() set allCheckedFilesArray(val: DeviceFileMetadata[]) {
    this.allCheckedFilesArrayInternal = val;
    if (this.allCheckedFilesArrayInternal === undefined) {
      this.allCheckedFilesArrayInternal = [];
    }
  }

  constructor(private fb: FormBuilder,
    private deviceFileService: DeviceFileService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    if (this.showStatusColumn) {
      this.displayedColumns.push(this.statusFieldName);
    }

    if (this.enableMultiSelect) {
      this.displayedColumns.unshift(this.multiSelectFieldName);
    }

    this.columnsToUse = this.displayedColumns;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public refreshData(): void {
    this.subscription.add(this.deviceFileService.getDeviceFiles(this.serialNumber, this.instrumentTypeId, this.firmwareVersion, this.deviceFileType)
      .subscribe(files => {
        this.filesLoaded = true;
        if (files !== undefined && files.length !== 0) {
          this.filesExist = true;
          this.dataSource.data = files;
          // paginator and sort have to be re applied every time the data is refreshed
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalFiles = files.length;
        } else {
          this.dataSource.data.length = 0;
          this.totalFiles = 0;
          this.filesExist = false;
        }
      }));
  }

  public downloadDeviceFile(deviceFileToDownload: DeviceFileMetadata): void {
    this.subscription.add(this.deviceFileService.getDeviceFileDownloadUrl(deviceFileToDownload).subscribe(downloadUrl => {
      this.deviceFileService.downloadDeviceFile(downloadUrl, deviceFileToDownload.fileName);
    }));
  }

  public async downloadFiles(): Promise<void> {
    if (this.allCheckedFilesArray.length === 0) {
      return;
    }
    if (this.allCheckedFilesArray.length === 1) {
      this.downloadDeviceFile(this.allCheckedFilesArray[0]);
    } else {
      await this.requestMultifileDownload();
    }

    this.allCheckedFilesArray = [];
  }

  public async getRecentDeviceFileStatusHistoryExport(): Promise<void> {
    if (!this.filesLoaded || !this.filesExist || !this.showStatusColumn) {
      return;
    }

    const msalUser = this.msalService.instance.getActiveAccount();
    this.reportForm.patchValue({ email: msalUser.username });
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, EmailTemplate | 'false'>(this.recentFileStatusHistoryEmailTemplate, { width: '75%' });
    const result = await firstValueFrom<EmailTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const request: RecentDeviceFileStatusRequest = {
        serialNumber: this.serialNumber,
        email: result.email
      };

      this.subscription.add(this.deviceFileService.requestRecentDeviceFileStatusExport(request).subscribe({
        complete: () => this.notificationService.success(TranslateConstants.InstrumentFilesListRecentFileStatusHistoryEmailDialogSuccessKey),
        error: (err) => this.notificationService.error(TranslateConstants.InstrumentFilesListRecentFileStatusHistoryEmailDialogFailureKey),
      }));
    }
  }

  public getFileStatus(deviceFileToDownload: DeviceFileMetadata, template: TemplateRef<unknown>): void {
    this.subscription.add(this.deviceFileService.getDeviceFileStatus(deviceFileToDownload).subscribe(async (fileStatuses: DeviceFileStatus[]) => {
      const dialogRef = this.dialog.open<unknown, DeviceFileStatus[], unknown>(template, { width: '50%', data: fileStatuses });
      await firstValueFrom<unknown>(dialogRef.afterClosed());
    }));
  }

  public isChecked(fileMetadata: DeviceFileMetadata): boolean {
    if (!fileMetadata) {
      return false;
    }
    return this.allCheckedFilesArray.find(g => g.blobName === fileMetadata.blobName) !== undefined;
  }

  public selectionChanged($event: MatCheckboxChangeEvent, fileMetadata: DeviceFileMetadata): void {
    const metadataFoundIndex = this.allCheckedFilesArray.findIndex(x => x.blobName === fileMetadata.blobName);

    if (!($event.currentTarget.checked) && metadataFoundIndex > -1) {
      this.allCheckedFilesArray.splice(metadataFoundIndex, 1);
    } else {
      this.allCheckedFilesArray.push(fileMetadata);
    }
  }

  public clearCheckedFiles(): void {
    this.allCheckedFilesArray = [];
  }

  public async requestMultifileDownload(): Promise<void> {

    const msalUser = this.msalService.instance.getActiveAccount();
    this.reportForm.patchValue({ email: msalUser.username });
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, EmailTemplate | 'false'>(this.downloadFilesEmailTemplate, { width: '75%' });
    const result = await firstValueFrom<EmailTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const request: DeviceFilesDownloadRequest = {
        serialNumber: this.serialNumber,
        email: result.email,
        blobNames: this.allCheckedFilesArray.map(f => f.blobName),
        type: this.deviceFileType
      };

      this.subscription.add(this.deviceFileService.requestDeviceFilesDownload(request).subscribe({
        complete: () => this.notificationService.success(TranslateConstants.InstrumentFilesListDownloadFilesEmailDialogSuccessKey),
        error: (err) => this.notificationService.error(TranslateConstants.InstrumentFilesListDownloadFilesEmailDialogFailureKey),
      }));
    }
  }
}

import { Injectable } from '@angular/core';
import { AssayDefinition, LanguageDefinition, Assay, CancelUpgradeVersion, Language } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class UpgradeHelperService {
  getAssayDefinitionCode(assayDefinitions: AssayDefinition[], assayDefinitionId: number): string {
    const def = assayDefinitions?.find(def => def.assayDefinitionId == assayDefinitionId);

    return def ? def.code : '';
  }

  getLanguageDefinitionCode(languageDefinitions: LanguageDefinition[], languageDefinitionId: number): string {
    const def = languageDefinitions?.find(def => def.languageDefinitionId == languageDefinitionId);

    return def ? def.code : '';
  }

  getCancelableAssayVersions(assays: Assay[], assayDefinitions: AssayDefinition[], assayIds: number[]): { [key: string]: CancelUpgradeVersion[] } {
    const assayVersions = {} as { [key: string]: CancelUpgradeVersion[] };

    assayIds?.forEach(id => {
      const assay = assays.find(a => a.assayId === id);

      const assayCode = this.getAssayDefinitionCode(assayDefinitions, assay.assayDefinitionId);
      let cancelUpgradeVersionCollection = assayVersions[assayCode];
      if (!cancelUpgradeVersionCollection) {
        cancelUpgradeVersionCollection = [];
        assayVersions[assayCode] = cancelUpgradeVersionCollection;
      }

      cancelUpgradeVersionCollection.push({ version: assay.version, interfaceVersion: assay.interfaceVersion });
    });

    return assayVersions;
  }

  getCancelableLanguageVersions(languages: Language[], languageDefinitions: LanguageDefinition[], languageIds: number[]): { [key: string]: CancelUpgradeVersion[] } {
    const languageVersions = {} as { [key: string]: CancelUpgradeVersion[] };

    languageIds?.forEach(id => {
      const language = languages.find(a => a.languageId === id);

      const languageCode = this.getLanguageDefinitionCode(languageDefinitions, language.languageDefinitionId);
      let cancelUpgradeVersionCollection = languageVersions[languageCode];
      if (!cancelUpgradeVersionCollection) {
        cancelUpgradeVersionCollection = [];
        languageVersions[languageCode] = cancelUpgradeVersionCollection;
      }

      cancelUpgradeVersionCollection.push({ version: language.version, interfaceVersion: language.interfaceVersion });
    });

    return languageVersions;
  }
}

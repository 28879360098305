import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DeviceInformation, DeviceDataRequest, MatSortChangeEvent, SortDirection, MatPageChangeEvent, MatCheckboxChangeEvent } from 'src/app/models/device.models';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Subject, debounceTime, Observable, startWith, map } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UrlConstants } from 'src/app/constants/url-constants';
import { ListMode, CheckedDevicesEvent } from '../../models/list.models';
import { AccessLevel, Country, DeviceInformationConnectionsState, DeviceInformationResult, Features, InstrumentTypeId, filterCountrySearchOptions } from 'src/app/models';
import { compare, compareVersion, isFeatureEnabledForInstrumentTypeName } from 'src/app/shared/utils';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ConfigurationService, DeviceHelperService, SelectionAndCacheService } from 'src/app/services';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatRadioChange } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})

export class DeviceListComponent implements OnInit, OnDestroy {
  @Output() deviceDataRequested: EventEmitter<DeviceDataRequest> = new EventEmitter<DeviceDataRequest>();
  @Output() checkedDevices: EventEmitter<CheckedDevicesEvent> = new EventEmitter<CheckedDevicesEvent>();
  @Output() deleteDevice: EventEmitter<DeviceInformation> = new EventEmitter<DeviceInformation>();
  @Output() fileExplorer: EventEmitter<DeviceInformation> = new EventEmitter<DeviceInformation>();
  @Output() editDevice: EventEmitter<DeviceInformation> = new EventEmitter<DeviceInformation>();
  @Output() removeDeviceFromList: EventEmitter<DeviceInformation> = new EventEmitter<DeviceInformation>();

  @Input() showOnlyUnregistered: boolean;
  @Input() accessLevel: AccessLevel = AccessLevel.Unauthorized;
  @Input() serialNumberSelectable = false;
  @Input() showViewInstrumentLink = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sortRef: MatSort;

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;

  public idFieldName = 'id';
  public serialNumberFieldName = 'serialNumber';
  public lnItemNumberFieldName = 'lnItemNumber';
  public singleselectFieldName = 'singleselect';
  public assaysFieldName = 'assays';
  public languagesFieldName = 'languages';
  public multiselectFieldName = 'multiselect';
  public instrumentTypeFieldName = 'instrumentType';
  public modelFieldName = 'model';
  public countryFieldName = 'country';
  public firmwareVersionFieldName = 'firmwareVersion';
  public appswFieldName = 'appswVersion';
  public virenaOptInFieldName = 'virenaOptIn';
  public statusFieldName = 'status';
  public actionsFieldName = 'actions';
  public currentConnectionStateFieldName = 'currentConnectionState';
  public lastHeartbeatIsHealthyFieldName = 'lastHeartbeatIsHealthy';

  private multiselectDisplayedColumns: string[] = [
    this.multiselectFieldName, this.idFieldName, this.serialNumberFieldName, this.lnItemNumberFieldName, this.instrumentTypeFieldName, this.modelFieldName, this.countryFieldName,
    this.firmwareVersionFieldName, this.assaysFieldName, this.languagesFieldName, this.virenaOptInFieldName, this.statusFieldName];
  private singleselectDisplayedColumns: string[] = [
    this.singleselectFieldName, this.idFieldName, this.serialNumberFieldName, this.lnItemNumberFieldName, this.instrumentTypeFieldName, this.modelFieldName, this.countryFieldName,
    this.firmwareVersionFieldName, this.assaysFieldName, this.languagesFieldName, this.virenaOptInFieldName, this.statusFieldName];
  private displayedColumns: string[] = [
    this.idFieldName, this.serialNumberFieldName, this.lnItemNumberFieldName, this.instrumentTypeFieldName, this.modelFieldName, this.countryFieldName, this.firmwareVersionFieldName,
    this.assaysFieldName, this.languagesFieldName, this.virenaOptInFieldName, this.statusFieldName, this.actionsFieldName];
  private filteredDisplayedColumns: string[] = [
    this.idFieldName, this.serialNumberFieldName,
    this.lnItemNumberFieldName, this.instrumentTypeFieldName, this.modelFieldName, this.countryFieldName, this.firmwareVersionFieldName, this.assaysFieldName, this.languagesFieldName,
    this.virenaOptInFieldName, this.statusFieldName, this.actionsFieldName];
  public columnsToUse = this.displayedColumns;
  private allDesiredColumnsToUse = this.columnsToUse;
  public ListMode = ListMode;
  public pageIndex = 1;
  public pageSize = 100;
  public length: number;
  public dataSource: MatTableDataSource<DeviceInformation> = new MatTableDataSource<DeviceInformation>();
  public searchControl = new FormControl('');
  public searchInput = '';
  public firmwareVersionSearchControl = new FormControl('');
  public firmwareVersionSearchInput = '';
  public firmwareVersionLessThanControl = new FormControl(false);
  public firmwareVersionLessThan = false;
  public amfCodeSearchControl = new FormControl('');
  public amfCodeSearchInput = '';
  public assayNegateControl = new FormControl(false);
  public assayNegate = false;
  public languageCodeSearchControl = new FormControl('');
  public languageCodeSearchInput = '';
  public languageNegateControl = new FormControl(false);
  public languageNegate = false;
  public selectAllPages = false;
  public allCheckedDeviceInformationArrayInternal: DeviceInformation[] = [];
  public userIsOnUnregisteredInstruments: boolean;
  public instrumentsUrl = '/' + UrlConstants.instrumentsUrl;
  public connectionStateSearchControl = new FormControl('');
  public connectionStateSearchInput = '';
  public firmwareVersionSearchLabelTranslateKey: string = TranslateConstants.DeviceListSearchFirmwareVersionKey;
  public firmwareVersionHeaderLabelTranslateKey: string = TranslateConstants.DeviceListHeadersFirmwareVersionKey;
  public statusSearchLabelTranslateKey: string = TranslateConstants.DeviceListSearchStatusKey;

  public statusSearchFilterConnectedValues: string[][] = [
    [TranslateConstants.All, 'All'],
    [TranslateConstants.DeviceListStatusTooltipConnectedKey, DeviceInformationConnectionsState.Connected],
    [TranslateConstants.DeviceListStatusTooltipDisconnectedyKey, DeviceInformationConnectionsState.Disconnected]];

  public statusSearchFilterHealthyValues: string[][] = [
    [TranslateConstants.All, 'All'],
    [TranslateConstants.DeviceListStatusTooltipHealthyKey, DeviceInformationConnectionsState.Connected],
    [TranslateConstants.DeviceListStatusTooltipUnhealthyKey, DeviceInformationConnectionsState.Disconnected]];

  public statusSearchFilterValues: string[][] = [];

  public searchCountryControl = new FormControl('');
  public searchCountries: Country[] = [];
  public allCountries: Country[] = [];
  public filteredSearchCountries: Observable<Country[]>;

  public showAssays = false;
  public showLanguages = false;
  private showAppsw = false;
  private showVirena = false;

  private getDeviceInformationResultInternal: DeviceInformationResult;
  private unpaginatedDeviceInformationArrayInternal: DeviceInformation[];
  private modeInternal: ListMode = ListMode.All;
  private instrumentTypeIdInternal: number;
  private subscription = new Subscription();

  private requestDeviceDataSubject: Subject<void> = new Subject();

  private features: Features;

  private currentDeviceDataRequest: DeviceDataRequest = {
    column: '',
    sortDirection: SortDirection.Ascending,
    pageNumber: 1,
    pageSize: this.pageSize,
    serialNumberSearch: '',
    firmwareVersionSearch: '',
    amfCodeSearch: '',
    languageCodeSearch: '',
    firmwareLessThan: false,
    amfNegate: false,
    languageNegate: false,
    countries: []
  };

  get allCheckedDeviceInformationArray(): DeviceInformation[] {
    return this.allCheckedDeviceInformationArrayInternal;
  }

  @Input() set allCheckedDeviceInformationArray(val: DeviceInformation[]) {
    this.allCheckedDeviceInformationArrayInternal = val;
    if (this.allCheckedDeviceInformationArrayInternal === undefined) {
      this.allCheckedDeviceInformationArrayInternal = [];
    }
  }

  get getDeviceInformationResult(): DeviceInformationResult {
    return this.getDeviceInformationResultInternal;
  }

  @Input() set getDeviceInformationResult(val: DeviceInformationResult) {
    this.getDeviceInformationResultInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val.results;
    this.pageSize = val.pagingInformation.pageSize;
    this.length = val.totalResults;
    this.pageIndex = val.pagingInformation.pageNumber - 1;
  }

  get unpaginatedInstrumentGroupsArray(): DeviceInformation[] {
    return this.unpaginatedDeviceInformationArrayInternal;
  }

  @Input() set unpaginatedInstrumentGroupsArray(val: DeviceInformation[]) {
    this.unpaginatedDeviceInformationArrayInternal = val;
    if (!val) {
      return;
    }
    this.dataSource.data = val;
    this.dataSource.paginator = this.paginator;
  }

  @Input() showSelectAllPagesMultiselect = false;

  get mode(): ListMode {
    return this.modeInternal;
  }

  @Input() set mode(val: ListMode) {
    this.modeInternal = val;
    if (!val) {
      return;
    }
    switch (+val) {
      case +ListMode.All:
        this.updateColumnsToUse(this.displayedColumns);
        break;
      case +ListMode.Multiselect:
        this.updateColumnsToUse(this.multiselectDisplayedColumns);
        break;
      case +ListMode.Filtered:
        this.updateColumnsToUse(this.filteredDisplayedColumns);
        break;
      case +ListMode.Singleselect:
        this.updateColumnsToUse(this.singleselectDisplayedColumns);
        break;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get instrumentTypeId(): number {
    return this.instrumentTypeIdInternal;
  }

  @Input() set instrumentTypeId(val: number) {
    this.instrumentTypeIdInternal = val;
    this.updateVisibilitiesForSelectedInstrumentType();
  }

  constructor(public router: Router,
    private selectionAndCacheService: SelectionAndCacheService,
    private configurationService: ConfigurationService,
    private deviceHelperService: DeviceHelperService) {
  }

  ngOnInit(): void {
    // Ensuring subscription to requestDeviceDataSubject happens first so that request for device data will always occur if refreshData has been called
    this.subscription.add(this.requestDeviceDataSubject.pipe(debounceTime(500))
      .subscribe(() => {
        this.deviceDataRequested.emit(this.currentDeviceDataRequest);
      }));

    this.subscription.add(this.selectionAndCacheService.isReadyEvent$.subscribe(() => {
      const clientConfig = this.configurationService.getClientConfiguration();

      this.features = clientConfig.features;

      this.allCountries = [...this.selectionAndCacheService.countries];

      this.filteredSearchCountries = this.searchCountryControl.valueChanges.pipe(
        startWith(null),
        map((country: Country | string | null) => filterCountrySearchOptions(country, this.allCountries, this.searchCountries))
      );

      this.updateVisibilitiesForSelectedInstrumentType();

      setTimeout(() => {
        // Check specifically in a setTimeout to ensure that this error isn't thrown when loading a page for the first time
        // AND the instrumentTypeId is actually set
        if (!this.instrumentTypeId) {
          throw new Error('Instrument Type input must be supplied');
        }
      }, 500);
    }));

    this.subscription.add(this.searchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchBySerialNumber(newValue);
    }));

    this.subscription.add(this.firmwareVersionSearchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchByFirmwareVersion(newValue);
    }));

    this.subscription.add(this.amfCodeSearchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchBYAMFCode(newValue);
    }));

    this.subscription.add(this.languageCodeSearchControl.valueChanges.subscribe((newValue: string) => {
      this.executeSearchByLanguageCode(newValue);
    }));

    this.subscription.add(this.firmwareVersionLessThanControl.valueChanges.subscribe((newValue: boolean) => {
      this.executeFirmwareVersionLessThan(newValue);
    }));

    this.subscription.add(this.assayNegateControl.valueChanges.subscribe((newValue: boolean) => {
      this.executeAssayNegate(newValue);
    }));

    this.subscription.add(this.languageNegateControl.valueChanges.subscribe((newValue: boolean) => {
      this.executeLanguageNegate(newValue);
    }));

    this.subscription.add(this.connectionStateSearchControl.valueChanges.subscribe((newValue: string) => {
      this.searchByConnectionState(newValue);
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public isCurrentPageChecked(): boolean {
    if (this.selectAllPages) {
      return false;
    }

    const someValuesAreNotIncluded = this.dataSource.data.some(x => !this.allCheckedDeviceInformationArrayInternal.find(c => c.deviceInformationId == x.deviceInformationId));
    return !someValuesAreNotIncluded;
  }

  public onRemoveDeviceInformation($deviceInformation: DeviceInformation): void {
    this.removeDeviceFromList.emit($deviceInformation);
  }

  public refreshData(): void {
    this.requestDeviceDataSubject.next();
  }

  public isChecked(deviceInformation: DeviceInformation): boolean {
    if (this.selectAllPages) {
      return true;
    }
    if (!deviceInformation) {
      return false;
    }
    return this.allCheckedDeviceInformationArray.find(d => d.deviceInformationId === deviceInformation.deviceInformationId) !== undefined;
  }

  public singleSelectionChanged($event: MatRadioChange, deviceInformation: DeviceInformation): void {
    this.allCheckedDeviceInformationArray = [deviceInformation];
    this.checkedDevices.emit({ checkedDevices: this.allCheckedDeviceInformationArray, allPages: false });

  }

  public selectionChanged($event: MatCheckboxChangeEvent, deviceInformation: DeviceInformation): void {
    if (this.selectAllPages) {
      this.allCheckedDeviceInformationArray = this.allCheckedDeviceInformationArray.filter(a => this.dataSource.data.some(d => a.deviceInformationId === d.deviceInformationId));
    }

    const deviceIdFoundIndex = this.allCheckedDeviceInformationArray.findIndex(x => x.deviceInformationId === deviceInformation.deviceInformationId);
    if (!($event.currentTarget.checked) && deviceIdFoundIndex > -1) {
      this.allCheckedDeviceInformationArray.splice(deviceIdFoundIndex, 1);
    } else {
      this.allCheckedDeviceInformationArray.push(deviceInformation);
    }
    this.selectAllPages = false;
    this.checkedDevices.emit({ checkedDevices: this.allCheckedDeviceInformationArray, allPages: this.selectAllPages });
  }

  public clearSearch(): void {
    this.searchInput = '';
    this.currentDeviceDataRequest.serialNumberSearch = this.searchInput;
    this.refreshData();
  }

  public clearFirmwareSearch(): void {
    this.firmwareVersionSearchInput = '';
    this.currentDeviceDataRequest.firmwareVersionSearch = this.firmwareVersionSearchInput;
    this.refreshData();
  }

  public clearAMFCodeSearch(): void {
    this.amfCodeSearchInput = '';
    this.currentDeviceDataRequest.amfCodeSearch = this.amfCodeSearchInput;
    this.refreshData();
  }

  public clearLanguageCodeSearch(): void {
    this.languageCodeSearchInput = '';
    this.currentDeviceDataRequest.languageCodeSearch = this.languageCodeSearchInput;
    this.refreshData();
  }

  public clearConnectionStateSearch(): void {
    this.connectionStateSearchInput = '';
    this.currentDeviceDataRequest.connectionStateSearch = null;
    this.refreshData();
  }

  public checkCurrentPage(): void {
    if (this.selectAllPages) {
      this.allCheckedDeviceInformationArray.length = 0;
      this.selectAllPages = false;
    }

    this.dataSource.data.forEach(deviceInformation => {
      const deviceIdFoundIndex = this.allCheckedDeviceInformationArray.findIndex(x => x.deviceInformationId === deviceInformation.deviceInformationId);
      if (deviceIdFoundIndex < 0) {
        this.allCheckedDeviceInformationArray.push(deviceInformation);
      }
    });
    this.checkedDevices.emit({ checkedDevices: this.allCheckedDeviceInformationArray, allPages: this.selectAllPages });
  }

  public checkAll(): void {
    this.dataSource.data.forEach(deviceInformation => {
      const deviceIdFoundIndex = this.allCheckedDeviceInformationArray.findIndex(x => x.deviceInformationId === deviceInformation.deviceInformationId);
      if (deviceIdFoundIndex < 0) {
        this.allCheckedDeviceInformationArray.push(deviceInformation);
      }
    });
    this.selectAllPages = true;
    this.checkedDevices.emit({ checkedDevices: this.allCheckedDeviceInformationArray, allPages: this.selectAllPages });
  }

  public uncheckAll(): void {
    this.allCheckedDeviceInformationArray.length = 0;
    this.selectAllPages = false;
    this.checkedDevices.emit({ checkedDevices: this.allCheckedDeviceInformationArray, allPages: this.selectAllPages });
  }

  public sort($event: MatSortChangeEvent): void {
    if (this.mode === ListMode.Filtered) {
      this.sortUnpaginatedData($event);
    } else {
      this.sortPaginatedData($event);
    }
  }

  public onEditDevice(deviceInformation: DeviceInformation): void {
    this.editDevice.emit(deviceInformation);
  }

  public onDeleteDevice(deviceInformation: DeviceInformation): void {
    this.deleteDevice.emit(deviceInformation);
  }

  public getPageData($event: MatPageChangeEvent): void {
    if (this.mode === ListMode.Filtered) {
      return;
    }
    this.currentDeviceDataRequest.pageNumber = $event.pageIndex + 1;
    this.currentDeviceDataRequest.pageSize = $event.pageSize;
    this.refreshData();
  }

  private executeSearchBySerialNumber($event: string): void {
    this.searchBySerialNumber($event);
  }

  private executeSearchByFirmwareVersion($event: string): void {
    this.searchByFirmwareVersion($event);
  }

  private executeSearchBYAMFCode($event: string): void {
    this.searchByAMFCode($event);
  }

  private executeSearchByLanguageCode($event: string): void {
    this.searchByLanguageCode($event);
  }

  public executeFirmwareVersionLessThan($event: boolean): void {
    this.searchByFirmwareVersionLessThan($event);
  }

  public executeAssayNegate($event: boolean): void {
    this.searchByAMFCodeNegate($event);
  }

  public executeLanguageNegate($event: boolean): void {
    this.searchByLanguageCodeNegate($event);
  }


  private searchBySerialNumber($event: string): void {
    this.searchInput = $event;
    this.currentDeviceDataRequest.serialNumberSearch = this.searchInput;
    this.refreshData();
  }

  private searchByAMFCode($event: string): void {
    this.amfCodeSearchInput = $event;
    this.currentDeviceDataRequest.amfCodeSearch = this.amfCodeSearchInput;
    this.refreshData();
  }

  private searchByAMFCodeNegate($event: boolean): void {
    this.currentDeviceDataRequest.amfNegate = $event;
    this.refreshData();
  }

  private searchByLanguageCode($event: string): void {
    this.languageCodeSearchInput = $event;
    this.currentDeviceDataRequest.languageCodeSearch = this.languageCodeSearchInput;
    this.refreshData();
  }

  private searchByLanguageCodeNegate($event: boolean): void {
    this.currentDeviceDataRequest.languageNegate = $event;
    this.refreshData();
  }

  private searchByFirmwareVersionLessThan($event: boolean): void {
    this.currentDeviceDataRequest.firmwareLessThan = $event;
    this.refreshData();
  }

  private searchByFirmwareVersion($event: string): void {
    this.firmwareVersionSearchInput = $event;
    this.currentDeviceDataRequest.firmwareVersionSearch = this.firmwareVersionSearchInput;
    this.refreshData();
  }

  private searchRefreshForCountriesSelected() {
    this.currentDeviceDataRequest.countries = [...this.searchCountries];
    this.refreshData();
  }

  private searchByConnectionState($event: string): void {
    this.connectionStateSearchInput = $event;

    switch ($event) {
      case DeviceInformationConnectionsState.Connected.valueOf():
        this.currentDeviceDataRequest.connectionStateSearch = DeviceInformationConnectionsState.Connected;
        break;
      case DeviceInformationConnectionsState.Disconnected.valueOf():
        this.currentDeviceDataRequest.connectionStateSearch = DeviceInformationConnectionsState.Disconnected;
        break;
      default:
        this.currentDeviceDataRequest.connectionStateSearch = null;
    }
    this.refreshData();
  }

  private sortPaginatedData(event: MatSortChangeEvent): void {
    let sortDirection = SortDirection.None;
    if (event.direction === 'asc') {
      sortDirection = SortDirection.Ascending;
    } else if (event.direction === 'desc') {
      sortDirection = SortDirection.Descending;
    }

    if (event.active == this.statusFieldName) {
      const instrumentType = this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === this.instrumentTypeId);
      if (instrumentType) {
        if (instrumentType.instrumentTypeId == InstrumentTypeId.Vision.valueOf()) {
          this.currentDeviceDataRequest.column = this.currentConnectionStateFieldName;
        }
        else {
          this.currentDeviceDataRequest.column = this.lastHeartbeatIsHealthyFieldName;
        }
      }
    } else {
      this.currentDeviceDataRequest.column = event.active;
    }
    this.currentDeviceDataRequest.sortDirection = sortDirection;
    this.refreshData();
  }

  private sortUnpaginatedData(sort: MatSortChangeEvent): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    const instrumentType = this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === this.instrumentTypeId);

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case this.idFieldName: return compare(a.deviceInformationId, b.deviceInformationId, isAsc);
        case this.serialNumberFieldName: return compare(a.serialNumber, b.serialNumber, isAsc);
        case this.lnItemNumberFieldName: return compare(a.lnItemNumber, b.lnItemNumber, isAsc);
        case this.virenaOptInFieldName: return compare(a.virenaOptIn, b.virenaOptIn, isAsc);
        case this.instrumentTypeFieldName: return compare(a.instrumentTypeId, b.instrumentTypeId, isAsc);
        case this.firmwareVersionFieldName: return compareVersion(a.firmwareVersion, b.firmwareVersion, isAsc);
        case this.assaysFieldName: return compare(a.assayVersions?.length ?? 0, b.assayVersions?.length ?? 0, isAsc);
        case this.languagesFieldName: return compare(a.languageVersions?.length ?? 0, b.languageVersions?.length ?? 0, isAsc);
        case this.statusFieldName:
          if (instrumentType && instrumentType.instrumentTypeId == InstrumentTypeId.Vision.valueOf()) {
            return compare(a.currentConnectionState, b.currentConnectionState, isAsc);
          }
          else {
            return compare(a.lastHeartbeatDateTime, b.lastHeartbeatDateTime, isAsc);
          }
      }
    });
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentTypeModelDisplayValue(deviceInformation: DeviceInformation): string {
    return this.selectionAndCacheService.getModelsForInstrumentTypeId(deviceInformation.instrumentTypeId)?.find(m => m.modelId === deviceInformation.modelId)?.name;
  }

  public getCountryDisplayValue(countryId: number): string {
    return this.selectionAndCacheService.countries?.find(c => c.countryId === countryId)?.name;
  }

  public getAppswDisplayValue(deviceInformation: DeviceInformation): string {
    const appswEntry = this.deviceHelperService.getReportedSoftwareEntry(deviceInformation, 'APPSW');
    return appswEntry?.version?.display;
  }

  public getStatusClassName(deviceInformation: DeviceInformation): string {
    return this.deviceHelperService.getDeviceInformationConnectionStatusClassName(deviceInformation);
  }

  public getStatusTooltip(deviceInformation: DeviceInformation): string {
    return this.deviceHelperService.getDeviceInformationConnectionStatusTooltip(deviceInformation);
  }

  private updateVisibilitiesForSelectedInstrumentType() {
    if (!this.instrumentTypeId) {
      return;
    }

    const instrumentType = this.selectionAndCacheService.instrumentTypes?.find(i => i.instrumentTypeId === this.instrumentTypeId);
    if (instrumentType) {
      this.showAssays = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFManagementSuffix);
      this.showLanguages = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LanguageManagementSuffix);
      this.showVirena = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.VirenaSettings);
      if (instrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
        this.showAppsw = true;
        this.firmwareVersionSearchLabelTranslateKey = TranslateConstants.DeviceListSearchModVersionKey;
        this.firmwareVersionHeaderLabelTranslateKey = TranslateConstants.DeviceListHeadersModVersionKey;
        this.statusSearchFilterValues = this.statusSearchFilterConnectedValues;
      } else {
        this.showAppsw = false;
        this.firmwareVersionSearchLabelTranslateKey = TranslateConstants.DeviceListSearchFirmwareVersionKey;
        this.firmwareVersionHeaderLabelTranslateKey = TranslateConstants.DeviceListHeadersFirmwareVersionKey;
        this.statusSearchFilterValues = this.statusSearchFilterHealthyValues;
      }
    } else {
      this.showAssays = false;
      this.showLanguages = false;
      this.showVirena = false;
      this.showAppsw = false;
      this.firmwareVersionSearchLabelTranslateKey = TranslateConstants.DeviceListSearchFirmwareVersionKey;
      this.firmwareVersionHeaderLabelTranslateKey = TranslateConstants.DeviceListHeadersFirmwareVersionKey;
    }

    // reset sort and paginator
    let sortingOrPagingReset = false;
    if (this.sortRef) {
      if (this.sortRef.active !== this.serialNumberFieldName || this.sortRef.direction !== 'asc') {
        this.sortRef.sort({ id: this.serialNumberFieldName, start: 'asc', disableClear: false });
        sortingOrPagingReset = true;
      }
    }
    if (this.paginator && this.paginator.pageIndex > 0) {
      this.paginator.firstPage();
      sortingOrPagingReset = true;
    }

    this.updateColumnsToUse(this.allDesiredColumnsToUse);

    if (!sortingOrPagingReset) {
      // Sorting and Paging both trigger a refresh of data already, so only request data for instrument type change if sorting and paging remains the same
      this.refreshData();
    }
  }

  private updateColumnsToUse(desiredColumnsToUse: string[]) {
    this.allDesiredColumnsToUse = desiredColumnsToUse;
    const tempColumnsToUse = [...this.allDesiredColumnsToUse];
    const removeModelColumn = this.selectionAndCacheService.getModelsForInstrumentTypeId(this.instrumentTypeId)?.length <= 1;
    if (removeModelColumn) {
      const indexOfModelColumn = tempColumnsToUse.indexOf(this.modelFieldName);
      if (indexOfModelColumn !== -1) {
        tempColumnsToUse.splice(indexOfModelColumn, 1);
      }
    }

    if (!this.showAssays) {
      const indexOfAssaysColumn = tempColumnsToUse.indexOf(this.assaysFieldName);
      if (indexOfAssaysColumn !== -1) {
        tempColumnsToUse.splice(indexOfAssaysColumn, 1);
      }
    }

    if (!this.showLanguages) {
      const indexOfLanguagesColumn = tempColumnsToUse.indexOf(this.languagesFieldName);
      if (indexOfLanguagesColumn !== -1) {
        tempColumnsToUse.splice(indexOfLanguagesColumn, 1);
      }
    }

    if (!this.showVirena) {
      const indexOfVirenaOptInColumn = tempColumnsToUse.indexOf(this.virenaOptInFieldName);
      if (indexOfVirenaOptInColumn !== -1) {
        tempColumnsToUse.splice(indexOfVirenaOptInColumn, 1);
      }
    }

    if (this.showAppsw) {
      const indexOfFirmwareVersionColumn = tempColumnsToUse.indexOf(this.firmwareVersionFieldName);
      if (indexOfFirmwareVersionColumn !== -1) {
        tempColumnsToUse.splice(indexOfFirmwareVersionColumn + 1, 0, this.appswFieldName);
      }
    }

    this.columnsToUse = tempColumnsToUse;
  }

  public removeCountrySearch(country: Country): void {
    const index = this.searchCountries.indexOf(country);

    if (index >= 0) {
      this.searchCountries.splice(index, 1);
      this.searchRefreshForCountriesSelected();
    }
  }

  public selectedCountrySearch(event: MatAutocompleteSelectedEvent): void {
    const selectedCountry = event.option.value as Country;
    if (!this.searchCountries.includes(selectedCountry)) {
      this.searchCountries.push(selectedCountry);
      this.searchRefreshForCountriesSelected();
    }
    this.countryInput.nativeElement.value = '';
    this.searchCountryControl.setValue(null);
  }
}

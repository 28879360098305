import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Subscription, firstValueFrom } from 'rxjs';
import { AccessLevel, ConfirmationDialogData, ConfirmationDialogResponse, Country, DeviceManualApprovalEntry, InstrumentType, InstrumentTypeModel, UserPermission } from 'src/app/models';
import { DataService, InstrumentTypeSelectionState, SelectionAndCacheService, NotificationService, UserPermissionService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { translate } from 'src/app/shared/translateServiceHelper';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-instrument-manual-approval',
  templateUrl: './instrument-manual-approval.component.html',
  styleUrls: ['./instrument-manual-approval.component.scss']
})
export class InstrumentManualApprovalComponent implements OnInit, AfterViewInit, OnDestroy {
  public serialNumberFieldName = 'serialNumber';
  public instrumentTypeCodeFieldName = 'instrumentTypeCode';
  public instrumentTypeModelCodeFieldName = 'instrumentTypeModelCode';
  public countryCodeFieldName = 'countryCode';
  public timestampFieldName = 'timestamp';
  public deviceIdentifiersJsonFieldName = 'deviceIdentifiersJson';
  public actionsFieldName = 'actions';
  public existingApprovalFieldName = 'existingApproval';
  public existingDeviceIdentifiersFieldName = 'existingDeviceIdentifiersJson';

  public accessLevel: AccessLevel = AccessLevel.Unauthorized;

  private displayedColumns: string[] = [this.serialNumberFieldName, this.instrumentTypeCodeFieldName, this.instrumentTypeModelCodeFieldName, this.countryCodeFieldName, this.timestampFieldName, this.deviceIdentifiersJsonFieldName, this.actionsFieldName];
  public columnsToUse = this.displayedColumns;
  public dataSource: MatTableDataSource<DeviceManualApprovalEntry> = new MatTableDataSource<DeviceManualApprovalEntry>();

  public showAllInstrumentTypes = false;

  private subscription: Subscription = new Subscription();
  @ViewChild(MatSort) private sort: MatSort;
  private instrumentTypes: InstrumentType[] = [];
  private countries: Country[] = [];

  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private userPermissionService: UserPermissionService,
    private selectionAndCacheService: SelectionAndCacheService) {
    dayjs.extend(relativeTime);
  }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(
        this.selectionAndCacheService.isReadyEvent$
          .subscribe(() => {
            this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
            this.countries = this.selectionAndCacheService.countries;

            this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
              this.selectedInstrumentType = selectionState.instrumentType;

              this.loadDevicesAwaitingManualApproval();
              this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.InstrumentManualApproval], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);

            }));
          }));
    }));
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public loadDevicesAwaitingManualApproval(): void {
    const instrumentTypeId = this.showAllInstrumentTypes
      ? undefined
      : this.selectedInstrumentType.instrumentTypeId;
    this.subscription.add(this.dataService.getDevicesAwaitingManualApproval(instrumentTypeId)
      .subscribe((entries: DeviceManualApprovalEntry[]) => {

        if (entries.some(e => e.existingIsApproved)) {
          this.columnsToUse = [...this.displayedColumns];
          this.columnsToUse.splice(this.displayedColumns.indexOf(this.actionsFieldName), 0, this.existingApprovalFieldName, this.existingDeviceIdentifiersFieldName);
        }

        this.dataSource.data = entries;
      }));
  }

  public getInstrumentTypeDisplayValue(instrumentTypeCode: string): string {
    return this.instrumentTypes?.find(i => i.code === instrumentTypeCode)?.name ?? 'Unknown';
  }

  public getInstrumentTypeModelDisplayValue(entry: DeviceManualApprovalEntry): string {
    const result = this.getInstrumentTypeAndInstrumentTypeModel(entry);
    if (!result.instrumentType) {
      return 'Unknown';
    }
    return result.model?.name ?? 'Unknown';
  }

  public getCountryCodeDisplayValue(countryCode: string): string {
    const country = this.getCountry(countryCode);
    if (!country) {
      return 'Unknown';
    }
    return country.name ?? 'Unknown';
  }

  public canApproveDenyInstrument(entry: DeviceManualApprovalEntry): boolean {
    const country = this.getCountry(entry.countryCode);
    const result = this.getInstrumentTypeAndInstrumentTypeModel(entry);
    if (result.instrumentType && result.model && country && entry.existingIsApproved === undefined) {
      return true;
    }

    return false;
  }

  public getLastModifiedDisplayValue(timestamp: Date): string {
    return dayjs(timestamp).fromNow();
  }

  public async onApproveOrDeny(entry: DeviceManualApprovalEntry, approve: boolean): Promise<void> {

    const actionTranslationKey = approve ? TranslateConstants.ApproveKey : TranslateConstants.DenyKey;
    const successActionTranslationKey = approve ? TranslateConstants.ManualApprovalApprovedKey : TranslateConstants.ManualApprovalDeniedKey;

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ManualApprovalTitleKey, { action: actionTranslationKey }),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, actionTranslationKey),
      message: translate(this.translateService, TranslateConstants.ManualApprovalWarningKey, { action: actionTranslationKey })
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const result = this.getInstrumentTypeAndInstrumentTypeModel(entry);
      const country = this.getCountry(entry.countryCode);
      this.subscription.add(this.dataService.upsertDeviceInformationApproval(entry, result.instrumentType.instrumentTypeId, result.model.modelId, country.countryId, approve)
        .subscribe(() => {
          this.notificationService.success(TranslateConstants.ManualApprovalSuccessKey, { action: successActionTranslationKey });
          this.loadDevicesAwaitingManualApproval();
        }));
    }
  }

  public async onDeleteManualApproval(entry: DeviceManualApprovalEntry): Promise<void> {

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ManualApprovalDeleteTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: translate(this.translateService, TranslateConstants.ManualApprovalDeleteWarningKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deletePendingManualApproval(entry)
        .subscribe(() => {
          this.notificationService.success(TranslateConstants.ManualApprovalSuccessKey, { action: TranslateConstants.ManualApprovalDeniedKey });
          this.loadDevicesAwaitingManualApproval();
        }));
    }
  }

  private getInstrumentTypeAndInstrumentTypeModel(entry: DeviceManualApprovalEntry): { instrumentType: InstrumentType, model: InstrumentTypeModel } {
    const instrumentType = this.instrumentTypes?.find(i => i.code === entry.instrumentTypeCode);
    if (!instrumentType) {
      return { instrumentType, model: undefined };
    }
    const model = this.selectionAndCacheService.allModels?.find(m => m.instrumentTypeId === instrumentType.instrumentTypeId && m.code === entry.instrumentTypeModelCode);
    return { instrumentType, model };
  }

  private getCountry(countryCode: string): Country {
    return this.countries.find(c => c.code === countryCode);
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, firstValueFrom } from 'rxjs';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import {
  AccessLevel, BlobUpload, C2DMessageRequestResponse, ClientConfig, ConfirmationDialogData, ConfirmationDialogResponse, DeviceInformation, DeviceRequest, DisableIoTConnectionC2DRequest,
  DownloadFileRequest, Features, InstrumentType, InstrumentTypeId, InstrumentTypeIdsSupportingCertificateAuthentication, UpdateUserListRequest, UserPermission, Version, VersionConstants
} from 'src/app/models';
import { C2DMessagesService, ConfigurationService, DataService, NotificationService, UserPermissionService } from 'src/app/services';
import { compareVersion, isFeatureEnabledForInstrumentTypeName } from 'src/app/shared/utils';
import { UploadDialogComponent, UploadDialogData, UploadDialogResponse } from '../upload-dialog/upload-dialog.component';

import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { C2DMessagesConstants } from 'src/app/constants/c2dMessages-constants';
import { MatchedConfirmationModalComponent, MatchedConfirmationModalInput } from '../matched-confirmation-modal/matched-confirmation-modal.component';
import { translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-instrument-utilities',
  templateUrl: './instrument-utilities.component.html',
  styleUrls: ['./instrument-utilities.component.scss']
})
export class InstrumentUtilitiesComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  private _clientConfig: ClientConfig;
  @ViewChild('updateUserListFile', { static: false }) private updateUserListFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileDownloadFile', { static: false }) private fileDownloadFileInput: ElementRef<HTMLInputElement>;

  private static readonly SixtyKBSize = 60 * 1024;
  public showUpdateUserList = false;
  public showRequestUploadLut = false;
  public showRequestLogFiles = false;
  public showFileDownload = false;
  public showDisableIoTConnection = false;
  public disableIoTLastRequestedDateTime?: Date;
  public showCertificateRefreshButton = false;
  public nothingShown = true;

  public readonly DownloadFile_FormKey = 'download_file';
  public readonly DownloadFile_TargetFolderPath_FormKey = 'target_folder_path';
  /* eslint-disable @typescript-eslint/unbound-method */
  public utilitiesForm = this.fb.group({
    download_file: this.fb.group({
      target_folder_path: ['./', [Validators.required]]
    })
  });
  /* eslint-enable @typescript-eslint/unbound-method */

  @Input() public instrumentTypes: InstrumentType[];
  @Input() public instrumentGroupId: number | undefined;
  @Input() public instrumentTypeId: number | undefined;


  private _deviceInformation: DeviceInformation | undefined;
  public get deviceInformation(): DeviceInformation | undefined {
    return this._deviceInformation;
  }
  @Input()
  public set deviceInformation(value: DeviceInformation | undefined) {
    this._deviceInformation = value;
    this.refreshCloudToDeviceMessagesData();
  }

  private features: Features;

  constructor(configurationService: ConfigurationService, private notificationService: NotificationService,
    private c2dMessagesService: C2DMessagesService, private dataService: DataService,
    private dialog: MatDialog, private fb: FormBuilder, private translateService: TranslateService,
    private userPermissionService: UserPermissionService) {
    this._clientConfig = configurationService.getClientConfiguration();
    this.features = this._clientConfig.features;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {

    if (this.deviceInformation && this.instrumentGroupId) {
      throw new Error('Either supply deviceInformation OR instrumentGroupId, not both.');
    }

    if (!this.instrumentTypeId || this.instrumentTypeId === 0) {
      throw new Error('Valid instrumentTypeId must be supplied');
    }

    if (!this.instrumentTypes || this.instrumentTypes.length === 0) {
      throw new Error('instrumentTypes must be supplied.');
    }

    const instrumentType = this.instrumentTypes.find(i => this.instrumentTypeId === i.instrumentTypeId);
    if (this.deviceInformation) {
      if (instrumentType) {
        this.showUpdateUserList = InstrumentUtilitiesComponent.ShowUpdateUserList(this.features, instrumentType, this.deviceInformation.firmwareVersion, this.userPermissionService,
          [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument]);
        this.showFileDownload = InstrumentUtilitiesComponent.ShowDownloadFile(this.features, instrumentType, this.deviceInformation.firmwareVersion);
        this.showDisableIoTConnection = InstrumentUtilitiesComponent.ShowDisableIoTConnection(this.features, instrumentType, this.deviceInformation.firmwareVersion, this.userPermissionService);
        this.showCertificateRefreshButton = InstrumentUtilitiesComponent.ShowCertificateRefresh(instrumentType, this.deviceInformation.firmwareVersion, this.userPermissionService,
          [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument]);
      }

      this.showRequestUploadLut = false;
      this.showRequestLogFiles = false;
    } else {
      // Instrument Group
      this.showUpdateUserList = InstrumentUtilitiesComponent.ShowUpdateUserList(this.features, instrumentType, undefined, this.userPermissionService,
        [UserPermission.ManageAllInstruments], true);
      this.showFileDownload = InstrumentUtilitiesComponent.ShowDownloadFile(this.features, instrumentType, undefined);
      this.showDisableIoTConnection = false; // This capability is for SINGLE instrument only
      this.showRequestUploadLut = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LutUpload);
      this.showRequestLogFiles = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.C2D_RequestLogs);
      this.showCertificateRefreshButton = InstrumentUtilitiesComponent.ShowCertificateRefresh(instrumentType, undefined, this.userPermissionService,
        [UserPermission.ManageAllInstruments], true);
    }

    this.nothingShown = !this.showUpdateUserList && !this.showFileDownload && !this.showDisableIoTConnection
      && !this.showRequestUploadLut && !this.showRequestLogFiles && !this.showCertificateRefreshButton;
  }

  public async updateUserList(files: File[]): Promise<void> {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    if (this.isFileTooLarge(file.size)) {
      return;
    }

    const updateUserListRequest: UpdateUserListRequest = {
      userList: await file.text()
    };

    this.updateUserListFileInput.nativeElement.value = null;

    if (this.deviceInformation) {
      updateUserListRequest.serialNumbers = [this.deviceInformation.serialNumber];
    }
    else if (this.instrumentGroupId) {
      updateUserListRequest.instrumentGroupIds = [this.instrumentGroupId];
    }

    this.subscription.add(this.c2dMessagesService.updateUserList(updateUserListRequest).subscribe((result: C2DMessageRequestResponse) => {
      C2DMessagesService.handleC2DRequestResponse(this.notificationService, this.deviceInformation !== null && this.deviceInformation !== undefined, result, TranslateConstants.UpdateUserListKey);
    }));
  }

  public requestLutFileUpload(): void {
    if (this.deviceInformation) {
      throw new Error('This is intended only for use when there\'s an Instrument Group Id set.');
    }

    const deviceRequest: DeviceRequest = {
      instrumentGroupIds: [this.instrumentGroupId]
    };

    this.subscription.add(this.c2dMessagesService.requestLutFilesUpload(deviceRequest)
      .subscribe((result: C2DMessageRequestResponse) => {
        C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestLUTFilesKey);
      }));
  }

  public requestLogFiles(): void {
    if (this.deviceInformation) {
      throw new Error('This is intended only for use when there\'s an Instrument Group Id set.');
    }

    const deviceRequest = {
      instrumentGroupIds: [this.instrumentGroupId]
    } as DeviceRequest;

    this.subscription.add(this.c2dMessagesService.requestLogsUpload(deviceRequest)
      .subscribe((result: C2DMessageRequestResponse) => {
        C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestLogsUploadKey);
      }));
  }

  public requestFileDownload(files: File[]): void {

    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    this.subscription.add(this.c2dMessagesService.getdownloadFileBlobUploadUrl(file.name)
      .subscribe(async (blobUpload: BlobUpload) => {
        const uploadDialogOptions: UploadDialogData = {
          title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UploadingKey, TranslateConstants.FileKey),
          observable: null
        };

        uploadDialogOptions.observable = this.dataService.uploadBlob(blobUpload, file);

        const dialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
          data: uploadDialogOptions,
          disableClose: true
        });

        const result = await firstValueFrom(dialogRef.afterClosed());

        this.fileDownloadFileInput.nativeElement.value = null;

        if (result.success) {
          const targetFolderPath = this.utilitiesForm.value.download_file.target_folder_path;
          const fileDownloadRequest: DownloadFileRequest = {
            blobName: blobUpload.blobName,
            targetFileName: file.name,
            targetFolderPath: targetFolderPath
          };


          if (this.deviceInformation) {
            fileDownloadRequest.serialNumbers = [this.deviceInformation.serialNumber];
          }
          else if (this.instrumentGroupId) {
            fileDownloadRequest.instrumentGroupIds = [this.instrumentGroupId];
          }

          this.subscription.add(this.c2dMessagesService.requestFileDownload(fileDownloadRequest)
            .subscribe((result: C2DMessageRequestResponse) => {
              C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestUploadFileToInstrumentKey);
            }));
        }
      }));
  }

  public async requestDisableIoTConnection(): Promise<void> {
    if (this.instrumentGroupId) {
      throw new Error('This is intended only for use when there\'s an individual Device set.');
    }

    if (!this._clientConfig.disableIoTConnectionConfirmationCode) {
      throw new Error('ClientConfig is missing the disableIoTConnectionConfirmationCode');
    }

    const options: MatchedConfirmationModalInput = {
      title: translate(this.translateService, TranslateConstants.DisableIoTConnectionTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DisableKey),
      message: translate(this.translateService, TranslateConstants.DisableIoTConnectionMessageKey),
      valueToMatch: this._clientConfig.disableIoTConnectionConfirmationCode
    };

    const dialogRef = this.dialog.open<MatchedConfirmationModalComponent, MatchedConfirmationModalInput, boolean>(MatchedConfirmationModalComponent, {
      data: options
    });

    const result: boolean = await firstValueFrom(dialogRef.afterClosed());
    if (result) {

      const deviceRequest = {
        serialNumber: this.deviceInformation.serialNumber,
        instrumentTypeId: this.instrumentTypeId
      } as DisableIoTConnectionC2DRequest;

      this.subscription.add(this.c2dMessagesService.requestDisableIoTConnection(deviceRequest)
        .subscribe((result: C2DMessageRequestResponse) => {
          C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestDisableIoTConnectionKey);
          if (result.requestedDateTimeUtc) {
            this.disableIoTLastRequestedDateTime = result.requestedDateTimeUtc;
          }
        }));
    }
  }

  /**
     * Returns if any of the utilities will be enabled/showing for the current instrument. This is assuming showing for a SINGLE instrument NOT and Instrument Group
     * @param {Features} features Feature Flags
     * @param {InstrumentType} instrumentType Type of the current instrument.
     * @param {Version} reportedFwVersion Reported FW version of the current instrument.
     * @param {UserPermissionService} userPermissionService Permission service used to check if the user has permission(s) required for each specific utility.
     * @returns {boolean} If any of the instrument utilities will be enabled and showing.
     */
  public static AreAnyInstrumentUtilitiesEnabled(features: Features, instrumentType: InstrumentType, reportedFwVersion: Version, userPermissionService: UserPermissionService): boolean {
    return InstrumentUtilitiesComponent.ShowUpdateUserList(features, instrumentType, reportedFwVersion, userPermissionService, [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument])
      || InstrumentUtilitiesComponent.ShowDownloadFile(features, instrumentType, reportedFwVersion)
      || InstrumentUtilitiesComponent.ShowDisableIoTConnection(features, instrumentType, reportedFwVersion, userPermissionService)
      || InstrumentUtilitiesComponent.ShowCertificateRefresh(instrumentType, reportedFwVersion, userPermissionService, [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument]);
  }

  public async forceCertificateRefresh(): Promise<void> {
    if (this.deviceInformation) {
      await this.forceSingleInstrumentCertificateRefresh();
    } else {
      this.forceInstrumentGroupCertificateRefresh();
    }
  }

  private async forceSingleInstrumentCertificateRefresh() {
    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ForceCertificateRefreshTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.ForceRefreshKey),
      message: translate(this.translateService, TranslateConstants.ForceCertificateRefreshMessageKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    // TODO: Update to act differently for single instrument vs instrument group
    if (response.result) {
      const deviceRequest: DeviceRequest = {
        serialNumbers: [this.deviceInformation.serialNumber]
      };
      this.subscription.add(this.dataService.forceCertificateRefresh(deviceRequest)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.ForceCertificateRefreshSuccessKey);
            },
            error: (e) => {
              this.notificationService.error(TranslateConstants.ForceCertificateRefreshFailedKey);
            }
          }));
    }
  }

  private forceInstrumentGroupCertificateRefresh() {
    const deviceRequest: DeviceRequest = {
      instrumentGroupIds: [this.instrumentGroupId]
    };

    this.subscription.add(this.dataService.getDeviceCountFromCertificateRefreshRequest(deviceRequest)
      .subscribe({
        next: async (count) => {
          const options: MatchedConfirmationModalInput = {
            title: translate(this.translateService, TranslateConstants.ForceCertificateRefreshTitleKey),
            cancelText: translate(this.translateService, TranslateConstants.CancelKey),
            confirmText: translate(this.translateService, TranslateConstants.ForceRefreshKey),
            message: translateWithInterpolateParams(this.translateService, TranslateConstants.ForceInstrumentGroupCertificateRefreshMessageKey, { deviceCount: count }),
            valueToMatch: count.toString()
          };

          const dialogRef = this.dialog.open<MatchedConfirmationModalComponent, MatchedConfirmationModalInput, boolean>(MatchedConfirmationModalComponent, {
            data: options
          });

          const result: boolean = await firstValueFrom(dialogRef.afterClosed());

          if (result) {
            this.subscription.add(this.dataService.forceCertificateRefresh(deviceRequest)
              .subscribe(
                {
                  complete: () => {
                    this.notificationService.success(TranslateConstants.ForceCertificateRefreshSuccessKey);
                  },
                  error: (e) => {
                    this.notificationService.error(TranslateConstants.ForceCertificateRefreshFailedKey);
                  }
                }));
          }
        },
        error: (e) => {
          this.notificationService.error(TranslateConstants.ForceCertificateRefreshFailedKey);
        }
      }));
  }

  private static ShowUpdateUserList(features: Features, instrumentType: InstrumentType, reportedFwVersion: Version, userPermissionService: UserPermissionService, requiredEditPermissions: UserPermission[], ignoreReportedFwVersion = false): boolean {
    const canManageInstrument = userPermissionService.getAccessLevel(requiredEditPermissions, [UserPermission.ViewEverything], instrumentType.instrumentTypeId) === AccessLevel.AddUpdate;
    let showUpdateUserList = isFeatureEnabledForInstrumentTypeName(features, instrumentType.name, FeatureFlagConstants.C2D_UpdateUserList);
    if (showUpdateUserList
      && !ignoreReportedFwVersion
      && reportedFwVersion
      && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
      showUpdateUserList = compareVersion(reportedFwVersion, VersionConstants.V1_15, true) >= 0;
    }
    return showUpdateUserList && canManageInstrument;
  }

  private static ShowDownloadFile(features: Features, instrumentType: InstrumentType, reportedFwVersion: Version): boolean {
    const featureEnabled = isFeatureEnabledForInstrumentTypeName(features, instrumentType.name, FeatureFlagConstants.C2D_DownloadFile);
    return featureEnabled;
  }

  private static ShowDisableIoTConnection(features: Features, instrumentType: InstrumentType, reportedFwVersion: Version, userPermissionService: UserPermissionService): boolean {
    const isSysAdmin = userPermissionService.getAccessLevel([UserPermission.SysAdmin]) === AccessLevel.AddUpdate;
    const featureEnabled = isFeatureEnabledForInstrumentTypeName(features, instrumentType.name, FeatureFlagConstants.AllowDisableIoT);
    return isSysAdmin && featureEnabled;
  }

  private static ShowCertificateRefresh(instrumentType: InstrumentType, reportedFwVersion: Version, userPermissionService: UserPermissionService, requiredEditPermissions: UserPermission[], ignoreReportedFwVersion = false): boolean {
    const canManageInstrument = userPermissionService.getAccessLevel(requiredEditPermissions, [UserPermission.ViewEverything], instrumentType.instrumentTypeId) === AccessLevel.AddUpdate;
    const instrumentSupportsCertificateAuth = InstrumentTypeIdsSupportingCertificateAuthentication.includes(instrumentType.instrumentTypeId);
    let showCertificateRefreshButton = canManageInstrument && instrumentSupportsCertificateAuth;
    if (showCertificateRefreshButton
      && !ignoreReportedFwVersion
      && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
      // Sofia 2 should only show it v2+
      showCertificateRefreshButton = compareVersion(reportedFwVersion, VersionConstants.V2, true) >= 0;
    }
    return showCertificateRefreshButton;
  }

  private isFileTooLarge(fileSize: number): boolean {
    if (fileSize > InstrumentUtilitiesComponent.SixtyKBSize) {
      this.notificationService.error(TranslateConstants.FileSizeTooLargeKBKey, { maxSize: '60.0' });
      return true;
    }

    return false;
  }

  private refreshCloudToDeviceMessagesData(): void {

    this.disableIoTLastRequestedDateTime = undefined;

    if (this.deviceInformation?.cloudToDeviceMessagesJsonByType) {
      const disableIoTConnectionC2DMessageMetadata = this.deviceInformation.cloudToDeviceMessagesJsonByType[C2DMessagesConstants.DisableIoTConnection];
      if (disableIoTConnectionC2DMessageMetadata) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.disableIoTLastRequestedDateTime = JSON.parse(disableIoTConnectionC2DMessageMetadata).LastRequestedUtc as Date;
      }
    }
  }
}

<div class="container">
  <div class="container-grid">
    <h1>{{ 'ManualApproval.ManualApproval' | translate }}</h1>
    <div class="action-bar">
      <mat-form-field>
        <input matInput style="display: none">
        <mat-checkbox [(ngModel)]="showAllInstrumentTypes" [ngModelOptions]="{standalone: true}"
          labelPosition="after">{{ 'ManualApproval.ShowAllInstrumentTypes' | translate }}</mat-checkbox>
      </mat-form-field>
      <div>
        <button mat-icon-button class="refresh" (click)="loadDevicesAwaitingManualApproval()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="version" matSortDirection="desc"
        matSortDisableClear="true">
        <ng-container [matColumnDef]="serialNumberFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Global.SerialNumber' | translate }} </th>
          <td mat-cell *matCellDef="let entry"> {{entry.serialNumber}} </td>
        </ng-container>
        <ng-container [matColumnDef]="instrumentTypeCodeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.InstrumentType' | translate }}</th>
          <td mat-cell *matCellDef="let entry">{{getInstrumentTypeDisplayValue(entry.instrumentTypeCode)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="instrumentTypeModelCodeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.InstrumentTypeModel' | translate }}</th>
          <td mat-cell *matCellDef="let entry">{{getInstrumentTypeModelDisplayValue(entry)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="countryCodeFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Country' | translate }}</th>
          <td mat-cell *matCellDef="let entry">{{getCountryCodeDisplayValue(entry.countryCode)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="timestampFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.LastModifiedDate' | translate }}</th>
          <td mat-cell *matCellDef="let entry" [matTooltip]="entry.timestamp | date:'full'">
            {{getLastModifiedDisplayValue(entry.timestamp)}}</td>
        </ng-container>
        <ng-container [matColumnDef]="deviceIdentifiersJsonFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ManualApproval.DeviceIdentifiers' | translate }}
          </th>
          <td mat-cell *matCellDef="let entry">{{entry.deviceIdentifiersJson}}</td>
        </ng-container>
        <ng-container [matColumnDef]="existingApprovalFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ManualApproval.ExistingApproval' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let entry">
            <div *ngIf="entry.existingIsApproved !== undefined"><mat-icon style="vertical-align: middle;"
                [matTooltip]="'ManualApproval.ExistingDeviceWithApproval' | translate">warning</mat-icon>
              {{ (entry.existingIsApproved ? 'ManualApproval.Approved' :
              'ManualApproval.Denied') | translate}}
            </div>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="existingDeviceIdentifiersFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ManualApproval.ExistingDeviceIdentifiers' |
            translate }}
          </th>
          <td mat-cell *matCellDef="let entry"> {{entry.existingDeviceIdentifiersJson}} </td>
        </ng-container>
        <ng-container [matColumnDef]="actionsFieldName">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let entry">
            <div class="actions-container" *ngIf="canAddUpdate && canApproveDenyInstrument(entry)">
              <button mat-icon-button (click)="onApproveOrDeny(entry, true)"
                [matTooltip]="'ManualApproval.ApproveDevice' | translate">
                <mat-icon>check</mat-icon>
              </button>

              <button mat-icon-button (click)="onApproveOrDeny(entry, false)"
                [matTooltip]="'ManualApproval.DenyDevice' | translate">
                <mat-icon>block</mat-icon>
              </button>
            </div>
            <div class="actions-container" *ngIf="canAddUpdate && !canApproveDenyInstrument(entry)">
              <button mat-icon-button (click)="onDeleteManualApproval(entry)"
                [matTooltip]="'ManualApproval.DeleteDeviceApproval' | translate">
                <mat-icon>block</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToUse; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToUse"></tr>
      </table>
    </div>
  </div>
</div>

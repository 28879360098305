import { Pipe, PipeTransform } from '@angular/core';
import { DeviceInformation } from '../models';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../shared/translateServiceHelper';
import { TranslateConstants } from '../constants/translate-constants';

@Pipe({
  name: 'digiAdded'
})
export class DigiAddedPipe implements PipeTransform {
  constructor(private translateService: TranslateService){}

  transform(value: DeviceInformation, digiDeviceStatus: string): boolean {
    if (value.firmwareVersion.major < 2 &&
      (value.imei && value.imei !== 'Unknown') &&
      (value.iccId && value.iccId !== 'Unknown') &&
      (value.deviceId && value.deviceId !== 'Unknown') &&
      (digiDeviceStatus == translate(this.translateService, TranslateConstants.DigiStatusAdded))) {
      return true;
    } else {
      return false;
    }
  }
}

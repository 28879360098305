import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { BrowserEntityRequest, C2DFolderSearchResult, ConfirmationDialogData, ConfirmationDialogResponse, DeviceInformation } from 'src/app/models';
import { DeviceFileService, NotificationService } from 'src/app/services';
import { translate } from 'src/app/shared/translateServiceHelper';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { nameof } from 'src/app/shared/utils';

@Component({
  selector: 'app-request-files',
  templateUrl: './request-files.component.html',
  styleUrls: ['./request-files.component.scss']
})
export class RequestFilesComponent implements OnInit, OnDestroy {

  @Input() public deviceInformation: DeviceInformation;
  @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('addPaths_file', { static: false }) private addPathsFileInput: ElementRef<HTMLInputElement>;

  private subscription: Subscription = new Subscription();

  public paths: string[] = [];
  public pathControl = new FormControl('');
  public pathText = '';
  private maxDownloadRequestsCount = 500;
  public maxDownloadRequestsReached = false;

  public constructor(private deviceFileService: DeviceFileService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialog: MatDialog) {
  }

  public ngOnInit(): void {
    if (!this.deviceInformation) {
      throw new Error('deviceInformation must be set');
    }

    this.subscription.add(this.pathControl.valueChanges
      .subscribe((newValue: string) => {
        this.pathText = newValue;
      }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this.closeEmitter.emit();
  }

  public removePath(index: number): void {
    if (index < 0) {
      return;
    }

    this.paths.splice(index, 1);
    this.updateMaxDownloadRequestsReached();
  }

  public addPath(): void {
    const added = this.tryAddUniquePath(this.pathText);
    if (added) {
      this.clearPathText();
      this.updateMaxDownloadRequestsReached();
    } else {
      this.notificationService.warning(TranslateConstants.RequestFilesDuplicatePathKey);
    }
  }

  public clearPathText(): void {
    this.pathText = '';
  }

  public async removeAllPaths(): Promise<void> {
    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.RemoveAllPathsTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.YesKey),
      message: translate(this.translateService, TranslateConstants.RemoveAllPathsWarningKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());
    if (response.result) {
      this.paths.length = 0;
      this.updateMaxDownloadRequestsReached();
    }
  }

  public downloadPaths(): void {
    const entityRequests: BrowserEntityRequest[] = [];
    this.paths.forEach(p => {
      entityRequests.push({
        path: p,
        deviceId: this.deviceInformation.serialNumber,
        uploadRequest: true,
        // explicitly passing undefined for the search related parameters
        maxPageSize: undefined,
        page: undefined,
        searchRegex: undefined,
        sortColumn: undefined,
        sortDescending: undefined
      });
    });

    this.subscription.add(this.deviceFileService.getDeviceDirectoryInfo(entityRequests).subscribe({
      next: () => {
        this.notificationService.success(TranslateConstants.FileBrowserRequestSuccessKey);
        this.close();
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.deviceFileService.processDeviceDirctoryInfoBadRequestReponse(err);
        } else {
          this.notificationService.error(TranslateConstants.FileBrowserConnectionFailedKey);
        }
        this.close();
      }
    }));
  }

  public triggerAddPathsFromFile(): void {
    this.addPathsFileInput.nativeElement.click();
  }

  public async addPathsFromFile(files: File[]): Promise<void> {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];

    const pathsText = await file.text();
    const isJsonFile = file.name.endsWith('.json');

    this.addPathsFileInput.nativeElement.value = null;
    let pathsToAdd: string[];
    if (isJsonFile) {
      const folderSearchResult = JSON.parse(pathsText) as C2DFolderSearchResult;
      if (Array.isArray(folderSearchResult?.fullPaths) && folderSearchResult.fullPaths.every(p => typeof p === 'string')) {
        pathsToAdd = folderSearchResult.fullPaths;
      } else {
        this.notificationService.error(TranslateConstants.RequestFilesUnsupportedJsonFileSchemaKey, { rootPropertyName: nameof<C2DFolderSearchResult>('fullPaths') });
      }
    } else {
      pathsToAdd = pathsText.split('\n').map(s => s?.trim()).filter(s => s?.length > 0);
    }

    let addedAllPaths = true;
    let duplicatePathsCount = 0;
    for (let i = 0; i < pathsToAdd.length; i++) {
      const path = pathsToAdd[i];
      this.updateMaxDownloadRequestsReached();
      if (this.maxDownloadRequestsReached) {
        addedAllPaths = false;
        if (i === 0) {
          this.notificationService.error(TranslateConstants.RequestFilesNoPathsAddedKey);
        } else {
          this.notificationService.warning(TranslateConstants.RequestFilesTooManyPathsInFileWarningKey, { addedCount: `${i}` }, TranslateConstants.RequestFilesTooManyPathsInFileTitleKey);
        }
        break;
      }

      const added = this.tryAddUniquePath(path.trim());
      if (!added) {
        addedAllPaths = false;
        duplicatePathsCount++;
      }
    }

    if (addedAllPaths) {
      this.notificationService.success(TranslateConstants.RequestFilesAddedAllPathsKey, { addedCount: `${pathsToAdd.length}` });
    }

    if (duplicatePathsCount > 0) {
      this.notificationService.warning(TranslateConstants.RequestFilesDuplicatePathsInFileKey, { duplicateCount: `${duplicatePathsCount}` });
    }
  }

  private updateMaxDownloadRequestsReached() {
    this.maxDownloadRequestsReached = this.paths.length >= this.maxDownloadRequestsCount;
  }

  private tryAddUniquePath(path: string): boolean {
    const index = this.paths.indexOf(path);
    if (index < 0) {
      this.paths.push(path);
      return true;
    }

    return false;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { AccessLevel, CheckedOverridesEvent, ConfirmationDialogData, ConfirmationDialogResponse, InstrumentType, ListMode, SerialNumberOverride, SerialNumberOverrideDialogData, SerialNumberOverridesRequest, SerialNumberOverridesResult, SetSerialNumberOverrideDialogData, SetSerialNumberOverrideDialogResponse, SetSerialNumberOverridesRequest, UserPermission } from 'src/app/models';
import { DataService, InstrumentTypeSelectionState, NotificationService, SelectionAndCacheService, SerialNumberOverridesService, UserPermissionService } from 'src/app/services';
import { translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { SerialNumberOverrideDialogComponent } from '../serialnumber-override-dialog/serialnumber-override-dialog.component';
import { SetSerialNumberOverridesDialogComponent } from 'src/app/components/set-serialnumber-overrides-dialog/set-serialnumber-overrides-dialog.component';

@Component({
  selector: 'app-serialnumber-overrides',
  templateUrl: './serialnumber-overrides.component.html',
  styleUrls: ['./serialnumber-overrides.component.scss']
})
export class SerialNumberOverridesComponent implements OnInit, OnDestroy {
  public serialNumberOverridesResult: SerialNumberOverridesResult;
  public serialNumberOverrides: SerialNumberOverride[] = [];
  public ListMode = ListMode;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  public instrumentTypes: InstrumentType[] = [];

  private subscription: Subscription = new Subscription();
  private currentSerialNumberOverrideRequest: SerialNumberOverridesRequest;

  public checkedOverridesEvent: CheckedOverridesEvent;

  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private overridesHelperService: SerialNumberOverridesService,
    private selectionAndCacheService: SelectionAndCacheService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        if (!selectionState.instrumentType) {
          return;
        }
        this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [], selectionState.instrumentType.instrumentTypeId);
        this.selectedInstrumentType = selectionState.instrumentType;
        this.getAllData();
      }));
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async addSerialNumberOverride(): Promise<void> {
    await this.showEditDialog(undefined);
  }

  public async onEditSerialNumberOverride(serialNumberOverride: SerialNumberOverride): Promise<void> {
    await this.showEditDialog(serialNumberOverride);
  }

  public async onDeleteSerialNumberOverride(serialNumberOverride: SerialNumberOverride): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.SerialNumberOverrideKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.SerialNumberOverrideKey, serialNumberOverride.serialNumber)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteSerialNumberOverride(serialNumberOverride.serialNumberOverrideId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.SerialNumberOverrideKey });
        this.getAllData();
      }));
    }
  }

  public getAllData(): void {
    if (!this.selectedInstrumentType) {
      return;
    }

    // initially it's expected that this.currentSerialNumberOverrideRequest will be undefined, which is a search without any filtering of data
    const serialNumberOverridesRequest: SerialNumberOverridesRequest = this.overridesHelperService.getOverrideSearchRequest(this.currentSerialNumberOverrideRequest, this.selectedInstrumentType?.instrumentTypeId);

    this.subscription.add(
      forkJoin([
        this.dataService.getSerialNumberOverrides(serialNumberOverridesRequest),
        this.userPermissionService.isReadyEvent$.pipe(take(1))
      ]).subscribe((results: [SerialNumberOverridesResult, boolean]) => {
        this.serialNumberOverridesResult = results[0];
        this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
      }));
  }

  public dataRequested($event: SerialNumberOverridesRequest): void {
    this.currentSerialNumberOverrideRequest = $event;
    this.getSerialNumberOverridesWithCurrentSearchState();
  }

  public requestImport(): void {
    this.subscription.add(this.dataService.requestSerialNumberOverridesImport()
      .subscribe((result) => {
        if (result) {
          this.notificationService.success(TranslateConstants.SerialNumberOverridesImportRequestSuccessKey);
        } else {
          this.notificationService.success(TranslateConstants.SerialNumberOverridesImportRequestFailedKey);
        }
      }));
  }

  private getSerialNumberOverridesWithCurrentSearchState(): void {
    const serialNumberOverridesRequest: SerialNumberOverridesRequest = this.overridesHelperService.getOverrideSearchRequest(this.currentSerialNumberOverrideRequest, this.selectedInstrumentType?.instrumentTypeId);

    this.subscription.add(
      this.dataService.getSerialNumberOverrides(serialNumberOverridesRequest)
        .subscribe((serialNumberOverridesResult: SerialNumberOverridesResult) => {
          this.serialNumberOverridesResult = serialNumberOverridesResult;
        }));
  }

  private async showEditDialog(serialNumberOverride?: SerialNumberOverride): Promise<void> {
    if (serialNumberOverride) {
      const options: SerialNumberOverrideDialogData = {
        serialNumberOverride: serialNumberOverride
      };

      const dialogRef = this.dialog.open(SerialNumberOverrideDialogComponent, {
        width: '75%',
        disableClose: true,
        data: options
      });

      await firstValueFrom(dialogRef.afterClosed());
      this.getAllData();

    } else {
      const newSerialNumberOverride: SerialNumberOverride = {
        serialNumberOverrideId: undefined,
        serialNumber: '',
        instrumentTypeId: 0,
        countryId: 0,
        productCode: '',
        createdBy: '',
        createdDate: '',
        lastModifiedBy: '',
        lastModifiedDate: ''
      };

      const options: SerialNumberOverrideDialogData = {
        serialNumberOverride: newSerialNumberOverride
      };

      const dialogRef = this.dialog.open(SerialNumberOverrideDialogComponent, {
        width: '75%',
        disableClose: true,
        data: options
      });

      await firstValueFrom(dialogRef.afterClosed());
      this.getAllData();
    }
  }

  hasOverrides(): boolean {
    if (!this.checkedOverridesEvent?.allPages) {
      return this.checkedOverridesEvent?.checkedOverrides.length > 0;
    } else {
      return true;
    }
  }

  async setOverrides(): Promise<void> {
    if (!this.hasOverrides()) {
      return;
    }

    const options: SetSerialNumberOverrideDialogData = {
      title: translate(this.translateService, TranslateConstants.SetSerialNumberOverrideTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.AssignKey),
      message: translateWithInterpolateParams(this.translateService, TranslateConstants.SetSerialNumberOverrideMessageKey, { count: this.checkedOverridesEvent?.checkedOverrides.length })
    };

    const dialogRef = this.dialog.open<SetSerialNumberOverridesDialogComponent, SetSerialNumberOverrideDialogData, SetSerialNumberOverrideDialogResponse>(SetSerialNumberOverridesDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const request: SetSerialNumberOverridesRequest = {
        overrideCountryId: response.overrideCountryId,
        serialNumberOverrideIds: this.checkedOverridesEvent?.checkedOverrides.map(o => o.serialNumberOverrideId)
      };

      this.subscription.add(this.dataService.setOverrideCountryForSerialNumberOverrides(request)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.SerialNumberOverridesSetSuccessKey);
              this.getSerialNumberOverridesWithCurrentSearchState();
            }, error: () => {
              this.notificationService.error(TranslateConstants.SerialNumberOverridesSetFailedKey);
            }
          }));
    }


  }

  async clearOverrides(): Promise<void> {
    if (!this.hasOverrides()) {
      return;
    }

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.SerialNumberOverrideClearKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: translateWithInterpolateParams(this.translateService, TranslateConstants.SerialNumberOverrideWarningKey, { count: this.checkedOverridesEvent?.checkedOverrides.length })
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const request: SetSerialNumberOverridesRequest = {
        serialNumberOverrideIds: this.checkedOverridesEvent?.checkedOverrides.map(o => o.serialNumberOverrideId)
      };

      this.subscription.add(this.dataService.setOverrideCountryForSerialNumberOverrides(request)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.SerialNumberOverridesClearSuccessKey);
              this.getSerialNumberOverridesWithCurrentSearchState();
            }, error: () => {
              this.notificationService.error(TranslateConstants.SerialNumberOverridesClearFailedKey);
            }
          }));
    }

  }

  get canManageAllInstruments(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  public onCheckedOverrides($event: CheckedOverridesEvent): void {
    this.checkedOverridesEvent = $event;
  }

}


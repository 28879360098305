import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime, firstValueFrom } from 'rxjs';
import { InstrumentType, InstrumentTypeCode, InstrumentTypeModel, InstrumentTypeSetting, UserSettingsConstants } from 'src/app/models';
import { SelectionAndCacheService, UserSettingsService } from 'src/app/services';

@Component({
  selector: 'app-instrument-type-selector',
  templateUrl: './instrument-type-selector.component.html',
  styleUrls: ['./instrument-type-selector.component.scss']
})
export class InstrumentTypeSelectorComponent implements OnInit, OnDestroy {
  @Input() showSelectors = true;
  @Input() hideModelsSelection = false;

  public instrumentTypes: InstrumentType[] = [];
  public showModelsSelection = false;
  public modelsForSelectedInstrumentType: InstrumentTypeModel[] = [];
  public selectedModels = new FormControl<InstrumentTypeModel[]>([]);

  private subscription: Subscription = new Subscription();
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private skipUpdatingInstrumentTypeSetting = false;

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.modelsForSelectedInstrumentType = this.selectionAndCacheService.getModelsForInstrumentType(this.SELECTED_INSTRUMENT_TYPE);
      this.showModelsSelection = this.modelsForSelectedInstrumentType?.length > 1;
      // Updating the selected models triggers value change on the form control which will then trigger notifying the service
      this.selectedModels.patchValue([...this.modelsForSelectedInstrumentType]);
    }
  }

  constructor(
    private selectionAndCacheService: SelectionAndCacheService,
    private userSettingsService: UserSettingsService) { }

  ngOnInit(): void {

    this.subscription.add(
      this.selectionAndCacheService.isReadyEvent$.subscribe(() => {
        this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;

        this.subscription.add(this.userSettingsService.isReadyEvent$.subscribe(() => {
          this.getInstrumentTypeSetting();
          this.selectionAndCacheService.updateSelectedInstrumentTypeAndModels(this.selectedInstrumentType, this.selectedModels.value, InstrumentTypeSelectorComponent.name);

          this.subscription.add(this.selectedModels.valueChanges.pipe(debounceTime(500)).subscribe(models => {
            void this.setInstrumentTypeSetting();
            this.selectionAndCacheService.updateSelectedInstrumentTypeAndModels(this.selectedInstrumentType, this.selectedModels.value, InstrumentTypeSelectorComponent.name);
          }));
        }));
      }));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getInstrumentTypeSetting(): void {
    const instrumentTypeSetting = this.userSettingsService.tryGetUserSetting(UserSettingsConstants.InstrumentType)?.settingsParsed as InstrumentTypeSetting;

    let instrumentTypeCode: string = InstrumentTypeCode.Sofia2;
    let instrumentTypeModelCodes: string[] = [];
    if (instrumentTypeSetting) {
      instrumentTypeCode = instrumentTypeSetting.instrumentTypeCode;
      instrumentTypeModelCodes = instrumentTypeSetting.modelCodes;
    }

    this.skipUpdatingInstrumentTypeSetting = true;
    try {
      this.selectedInstrumentType = this.instrumentTypes.find(it => it.code === instrumentTypeCode);
      let selectedModelsTemp: InstrumentTypeModel[];
      if (instrumentTypeModelCodes?.length === 0) {
        selectedModelsTemp = [...this.modelsForSelectedInstrumentType];
      } else {
        selectedModelsTemp = this.modelsForSelectedInstrumentType.filter(itm => instrumentTypeModelCodes.includes(itm.code));
      }
      this.selectedModels.patchValue(selectedModelsTemp);
    } finally {
      this.skipUpdatingInstrumentTypeSetting = false;
    }
  }

  private async setInstrumentTypeSetting(): Promise<void> {
    if (this.skipUpdatingInstrumentTypeSetting) {
      return;
    }

    const instrumentTypeSetting: InstrumentTypeSetting = {
      instrumentTypeCode: this.SELECTED_INSTRUMENT_TYPE.code,
      modelCodes: this.selectedModels.value.map(itm => itm.code)
    };

    // Silently calling update user setting
    await firstValueFrom(this.userSettingsService.updateUserSetting(UserSettingsConstants.InstrumentType, instrumentTypeSetting, true));
  }
}

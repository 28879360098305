import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { countryValidator, forbiddenValueValidator } from 'src/app/shared/validators';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { clone, compareVersion, exportTextFile, isFeatureEnabledForInstrumentTypeName, tryGetSettingsArrayFromJson } from 'src/app/shared/utils';
import { UserPermissionService, DataService, NotificationService, DeviceFileService, GroupHelperService, DeviceHelperService, ConfigurationService, C2DMessagesService, SelectionAndCacheService } from 'src/app/services';
import {
  DeviceInformation, InstrumentType, InstrumentGroup, InstrumentGroupsResult, ListMode, InstrumentGroupsRequest, AccessLevel,
  InstrumentDialogData, UserPermission, CancelUpgradeRequest, AssayVersion, CancelUpgradeVersion, LanguageVersion, ConfirmationDialogData, CheckedGroupsEvent, InstrumentActionHistoryRow,
  Features, DeviceRequest, DeviceFileMetadata, C2DPendingMessage, InstrumentTypeId, VersionConstants, C2DMessageRequestResponse,
  ConfirmationDialogResponse, DeleteFilesRequest, C2DTypes, convertToVersion, Version, InstrumentDefaultSettingsMap, InstrumentDefaultSettingsType, InstrumentDefaultSettings, InstrumentTypeModel, SoftwareEntry, InstrumentDefaultSettingsAllowlistFolderPaths, Country, filterCountrySearchOptions,
  InstrumentTypeCode
} from 'src/app/models';
import { UpgradeRequestHistoryComponent } from './upgrade-request-history/upgrade-request-history.component';
import { DeviceSettingsConstants } from 'src/app/constants/deviceSettings-constants';
import { tryGetSettingsFromJson } from 'src/app/shared/utils';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { C2DMessagesConstants } from 'src/app/constants/c2dMessages-constants';
import { MatSelectChange } from '@angular/material/select';
import { MatTabGroup } from '@angular/material/tabs';
import { InstrumentUtilitiesComponent } from '../../components/instrument-utilities/instrument-utilities.component';
import { sort } from 'json-keys-sort';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { isTranslationLoaded, translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { addNestedFormControl, addNestedFormGroup, getMinMaxValidationValues, removeNestedFormControl } from 'src/app/shared/utils.forms';
import { ValidationConstants } from 'src/app/constants/validation-constants';
import { InstrumentGroupListRequest } from 'src/app/components/instrument-group-list/instrument-group-list.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-instrument-dialog',
  templateUrl: './instrument-dialog.component.html',
  styleUrls: ['./instrument-dialog.component.scss']
})
export class InstrumentDialogComponent implements OnInit, OnDestroy {

  private static readonly lanMacAddressFormFieldName = 'lanMACAddress';
  private static readonly wifiMacAddressFormFieldName = 'wifiMACAddress';
  private static readonly countryFormFieldName = 'country';
  private static readonly unknownMacAddressValue = 'Unknown';

  @ViewChild(UpgradeRequestHistoryComponent) requestHistoryComponent: UpgradeRequestHistoryComponent;
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  @ViewChild('updateAllowlistFolderPaths_file', { static: false }) private updateAllowlistFolderPathsFileInput: ElementRef<HTMLInputElement>;

  public sortInstrumentSettingsRootKeys = DeviceSettingsConstants.sortInstrumentSettingsRootKeys;
  public DeviceSettingsConstants = DeviceSettingsConstants;
  public InstrumentTypeId = InstrumentTypeId;
  public C2DTypes = C2DTypes;

  public instrumentTypes: InstrumentType[] = [];
  public modelsForSelectedInstrumentType: InstrumentTypeModel[] = [];
  public showModelSelection = false;
  public isFileBrowserShowing = false;
  public isManualFolderSearchShowing = false;
  public isManualRequestFilesShowing = false;

  public filteredCountries: Observable<Country[]>;
  private allCountries: Country[] = [];

  private _selectedTabIndex = 0;
  get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }
  set selectedTabIndex(newIndex: number) {
    this._selectedTabIndex = newIndex;
    this.updateTabRelatedButtonVisibilities();
  }

  get hideMainContent(): boolean {
    return this.isFileBrowserShowing || this.isManualFolderSearchShowing || this.isManualRequestFilesShowing;
  }

  public instrumentGroupsTabText: string;
  public showChangeGroupsButton = false;
  public filesTabText: string;
  public showRequestLutFilesButton = false;
  public lutLastRequestedDateTime?: Date;
  public logsLastRequestedDateTime?: Date;

  public recentUpgradeHistoryTabText: string;
  public utilitiesTabText: string;
  public showLoadRecentUpgradeHistoryButton = false;
  public allowAssayDeletion = false;
  public allowLanguageDeletion = false;

  public instrumentGroups: InstrumentGroup[];
  public allInstrumentGroups: InstrumentGroupsResult;
  public ListMode = ListMode;
  public changeGroupsMode = false;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  public manageInstrumentGroupsAccessLevel: AccessLevel = AccessLevel.Unauthorized;
  private virenaOptInAccessLevel: AccessLevel = AccessLevel.Unauthorized;
  private cancelUpgradeAccessLevel: AccessLevel = AccessLevel.Unauthorized;
  private allowlistFolderPathsAccessLevel: AccessLevel = AccessLevel.Unauthorized;
  private awsSettingsAccessLevel: AccessLevel = AccessLevel.ViewOnly;

  public instrumentActionHistoryRows: InstrumentActionHistoryRow[] = [];
  public c2dPendingMessages: C2DPendingMessage[] = [];

  public clinicalModeSettingsAdded = false;
  public clinicalModeInDesiredPropertiesOnly = false;

  public testResultsUploadSettingsAdded = false;
  public testResultsUploadSettingsEnabled = false;
  public testResultsUploadInDesiredPropertiesOnly = false;
  private testResultEnabledValueChangeSubscription: Subscription;

  public performanceDataSettingsAdded = false;
  public performanceDataInDesiredPropertiesOnly = false;

  public allowlistFolderPathsAdded = false;
  public allowlistFolderPathsInDesiredPropertiesOnly = false;

  public hasInstrumentSettingsDesiredState = false;
  public instrumentSettingsDesiredState: { [key: string]: unknown } = undefined;
  public hasUpgradesDesiredState = false;
  public hasFirmwareUpgradeRequest = false;
  public hasAssayUpgradeRequests = false;
  public hasLanguagepgradeRequests = false;
  public hasPendingC2DMessages = false;
  public showActionHistoryTab = true;
  public showUpgradeHistoryTab = true;
  public showUtilitiesTab = true;
  public showFilesTab = false;
  public showLogFilesSection = true;
  public showLutFilesSection = true;
  public showFileBrowserSection = false;
  public showManualFileUploadItems = false;
  public showVirenaSettings = true;
  public showRetrySettings = true;
  public showDownloadSettings = true;
  public showDownloadSettings_Software = false;
  public showClinicalModeSettings = true;
  public showTestResultsUpload = true;
  public showTestResultsUpload_DeleteAfterUpload = true;
  public showPerformanceDataSettings = true;
  public showAllowlistFolderPaths = false;
  public showVirenaAutoSend = true;
  public showRequestLogs = false;
  public showAssays = false;
  public showLanguages = false;
  public showSoftwareList = false;
  public showAwsSettings = false;
  public showChunkDownloadTimeoutNotHonoredMessage = false;
  public chunkDownloadTimeoutNotHonoredMessage = '';
  public showRetrySettingsTimeDelayMsTimeFormat = false;

  private awsEnableFolderOverrideValueChangeSubscription: Subscription;

  public softwareList: SoftwareEntry[] = [];

  public firmwareVersionLabelKey: string = TranslateConstants.InstrumentsFirmwareVersionKey;
  public requestedFirmwareLabelKey: string = TranslateConstants.InstrumentsRequestedFirmwareKey;
  public firmwareTypeKey: string = TranslateConstants.FirmwareKey;

  public instrumentSchemaDesiredPropertiesParsedJson: object;
  public instrumentSchemaReportedPropertiesParsedJson: object;

  private subscription: Subscription = new Subscription();
  private updatedUnsavedCheckedGroups: InstrumentGroup[] = [];
  private currentInstrumentGroupRequest: InstrumentGroupListRequest;
  private _deviceInformation: DeviceInformation;
  public instrumentType: InstrumentType;
  private features: Features;
  private canToggleVirenaEnabled = true;
  private instrumentTypeIdPropertyName = 'instrumentTypeId';
  private modelIdPropertyName = 'modelId';

  private instrumentSettingDefaultsJsonByKey: { [key: string]: string };

  private minMaxValidationValuesByKeyParts: Map<string, { min: string, max: string, minValue: number, maxValue: number }> = new Map<string, { min: string, max: string, minValue: number, maxValue: number }>();

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get canManageInstrumentGroups(): boolean {
    return this.manageInstrumentGroupsAccessLevel === AccessLevel.AddUpdate;
  }

  get canAddUpdateAllowlistFolderPaths(): boolean {
    return this.canAddUpdate && this.allowlistFolderPathsAccessLevel === AccessLevel.AddUpdate;
  }

  get canAddUpdateAwsSettings(): boolean {
    return this.canAddUpdate && this.awsSettingsAccessLevel === AccessLevel.AddUpdate;
  }

  get canCancelUpgrade(): boolean {
    return this.cancelUpgradeAccessLevel === AccessLevel.AddUpdate;
  }

  get canSave(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate ||
      (this.virenaOptInAccessLevel === AccessLevel.AddUpdate
        && this.showVirenaSettings);
  }

  /* eslint-disable @typescript-eslint/unbound-method */
  public editInstrumentForm: UntypedFormGroup = this.fb.group({
    serialNumber: ['', [Validators.required, Validators.maxLength(8)]],
    lnItemNumber: ['', [Validators.required, Validators.maxLength(50)]],
    lanMACAddress: ['', [Validators.required, Validators.maxLength(50)]],
    wifiMACAddress: ['', [Validators.required, Validators.maxLength(50)]],
    country: [{}, [Validators.required]],
    instrumentTypeId: [0, [forbiddenValueValidator(0)]],
    modelId: [0, [forbiddenValueValidator(0)]],
    virenaSettings: this.fb.group(DeviceSettingsConstants.VirenaSettings_Form_DefaultConfig),
    // retry_settings: Applied at runtime based on the instrument type and reported version
    download_settings: this.fb.group(DeviceSettingsConstants.DownloadSettings_Form_DefaultConfig),
    aws_settings: this.fb.group(DeviceSettingsConstants.AwsSettings_Form_DefaultConfig),
    // clinical_mode: Applied at runtime based on if device information has this setting
    // test_result_upload: Applied at runtime based on if device information has this setting
    // performance_data: Applied at runtime based on if device information has this setting
    notes: ['', []],
  });
  /* eslint-enable @typescript-eslint/unbound-method */

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: InstrumentDialogData,
    private dataService: DataService,
    private userPermissionService: UserPermissionService,
    private notificationService: NotificationService,
    private deviceFileService: DeviceFileService,
    private dialog: MatDialog,
    private mdDialogRef: MatDialogRef<InstrumentDialogComponent>,
    private fb: UntypedFormBuilder,
    private groupHelperService: GroupHelperService,
    private deviceHelperService: DeviceHelperService,
    private c2dMessagesService: C2DMessagesService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService,
    private numberPipe: DecimalPipe,
    configurationService: ConfigurationService) {

    const clientConfig = configurationService.getClientConfiguration();
    if (clientConfig.instrumentSettingDefaultsJsonByKey) {
      this.instrumentSettingDefaultsJsonByKey = clientConfig.instrumentSettingDefaultsJsonByKey;
    } else {
      this.instrumentSettingDefaultsJsonByKey = {};
    }

    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
    this.allCountries = this.selectionAndCacheService.countries;
    this.editInstrumentForm.get(InstrumentDialogComponent.countryFormFieldName).addValidators(countryValidator(this.allCountries));

    if (!dialogData.deviceInformation.deviceInformationId && this.selectionAndCacheService.selectedInstrumentType) {
      // New device creation
      dialogData.deviceInformation.instrumentTypeId = this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId;
    }
    this.instrumentType = this.instrumentTypes.find(i => dialogData.deviceInformation.instrumentTypeId === i.instrumentTypeId);
    // ensure setting this.deviceInformation does NOT get moved before any other items in this constructor as items in the setter are reliant on above
    //    happening first
    this.deviceInformation = dialogData.deviceInformation;

    const temp = {};
    temp[this.instrumentTypeIdPropertyName] = this.deviceInformation.instrumentTypeId;
    if (this.deviceInformation.countryId) {
      temp[InstrumentDialogComponent.countryFormFieldName] = this.allCountries.find(c => c.countryId === this.deviceInformation.countryId);
    }
    this.editInstrumentForm.patchValue(temp);

    this.updateModelSelectionForInstrumentType();

    this.features = clientConfig.features;

    this.refreshCloudToDeviceMessagesData();

    if (isTranslationLoaded(this.translateService)) {
      this.updateTabsText();
    }
    this.subscription.add(this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.updateTabsText();
    }));
  }

  private updateTabsText() {
    this.instrumentGroupsTabText = translate(this.translateService, TranslateConstants.InstrumentsTabInstrumentGroupsKey);
    this.filesTabText = translate(this.translateService, TranslateConstants.InstrumentsTabFilesKey);
    this.recentUpgradeHistoryTabText = translate(this.translateService, TranslateConstants.InstrumentsTabRecentUpgradeRequestHistoryKey);
    this.utilitiesTabText = translate(this.translateService, TranslateConstants.InstrumentsTabUtilitiesKey);
  }

  private refreshFeatureFlagsBySelectedInstrumentType() {
    if (this.instrumentType) {
      const instrumentType = this.instrumentType;
      this.showActionHistoryTab =
        this.showUpgradeHistoryTab = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.UpgradeInstrumentSuffix);
      this.showLogFilesSection = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.InstrumentLogFilesSuffix);
      this.showVirenaSettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.VirenaSettings);
      this.showRetrySettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.RetrySettingsSuffix);
      this.showDownloadSettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.DownloadSettingsSuffix);
      this.showDownloadSettings_Software = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.DownloadSettings_Software);
      this.showClinicalModeSettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.ClinicalModeSettingsSuffix);
      this.showTestResultsUpload = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.TestResultsUploadSuffix);
      this.showTestResultsUpload_DeleteAfterUpload = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.TestResultsUpload_DeleteAfterUploadOption);
      if (this.showTestResultsUpload_DeleteAfterUpload && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        this.showTestResultsUpload_DeleteAfterUpload = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V1_15, true) >= 0;
      }
      this.showPerformanceDataSettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.PerformanceDataSettingSuffix);
      if (this.showPerformanceDataSettings && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        this.showPerformanceDataSettings = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V1_15, true) >= 0;
      }
      this.showAllowlistFolderPaths = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AllowlistFolderPaths);
      this.showUtilitiesTab = this.userPermissionService
        .getAccessLevel([UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument, UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument]) === AccessLevel.AddUpdate
        && InstrumentUtilitiesComponent.AreAnyInstrumentUtilitiesEnabled(this.features, instrumentType, this.deviceInformation.firmwareVersion, this.userPermissionService);

      this.showLutFilesSection = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LutUpload);
      if (this.showLutFilesSection && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        this.showLutFilesSection = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V1_15, true) >= 0;
      }

      this.showRequestLutFilesButton = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.C2DMessage);

      this.allowAssayDeletion = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFDeletion);
      if (this.allowAssayDeletion && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        this.allowAssayDeletion = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V1_15, true) >= 0;
      }

      this.allowLanguageDeletion = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LangDeletion);
      if (this.allowLanguageDeletion && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        this.allowLanguageDeletion = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V1_15, true) >= 0;
      }

      this.canToggleVirenaEnabled = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.VirenaEnabledToggleable);

      const newInstrumentVirenaEnabledControl = this.editInstrumentForm.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]);
      if (this.canToggleVirenaEnabled) {
        newInstrumentVirenaEnabledControl.enable();
      } else {
        newInstrumentVirenaEnabledControl.disable();
        if (instrumentType.instrumentTypeId === InstrumentTypeId.Savanna.valueOf()) {
          newInstrumentVirenaEnabledControl.setValue(true);
          this.handleVirenaOptIn(true);
        }
      }

      if (instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()
        && compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V2, true) >= 0) {
        this.showVirenaAutoSend = false;
        const editInstrumentVirenaSettingsFormGroup = this.editInstrumentForm.get([DeviceSettingsConstants.VirenaSettings_FormKey]) as UntypedFormGroup;
        editInstrumentVirenaSettingsFormGroup.removeControl(DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey);
      }

      this.showRequestLogs = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.C2D_RequestLogs, false);
      if (this.showRequestLogs
        && instrumentType.instrumentTypeId === InstrumentTypeId.Sofia2.valueOf()) {
        // Sofia 2 should only show it v2+
        this.showRequestLogs = compareVersion(this.deviceInformation.firmwareVersion, VersionConstants.V2, true) >= 0;
      }

      this.showFileBrowserSection = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.SupportsFileBrowser);
      this.showManualFileUploadItems = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.ManualFileUpload);

      this.showAssays = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFManagementSuffix);
      this.showLanguages = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LanguageManagementSuffix);

      this.showAwsSettings = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.SupportsAwsDestinationFolderOverride, false);

      this.showChunkDownloadTimeoutNotHonoredMessage = instrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf();
      this.chunkDownloadTimeoutNotHonoredMessage = translateWithInterpolateParams(this.translateService, TranslateConstants.DeviceSettingsDownloadChunkDownloadTimeoutNotHonoredKey,
        { instrumentTypeName: instrumentType.name });
    } else {
      this.showActionHistoryTab = true;
      this.showUpgradeHistoryTab = true;
      this.showLogFilesSection = true;
      this.showVirenaSettings = true;
      this.showRetrySettings = true;
      this.showDownloadSettings = true;
      this.showDownloadSettings_Software = false;
      this.showClinicalModeSettings = true;
      this.showTestResultsUpload = true;
      this.showTestResultsUpload_DeleteAfterUpload = true;
      this.showPerformanceDataSettings = true;
      this.showAllowlistFolderPaths = false;
      this.showUtilitiesTab = true;
      this.showLutFilesSection = true;
      this.showRequestLutFilesButton = false;
      this.allowAssayDeletion = false;
      this.allowLanguageDeletion = false;
      this.canToggleVirenaEnabled = true;
      this.showVirenaAutoSend = true;
      this.showRequestLogs = false;
      this.showFileBrowserSection = false;
      this.showManualFileUploadItems = false;
      this.showAssays = false;
      this.showLanguages = false;
      this.showAwsSettings = false;
      this.showChunkDownloadTimeoutNotHonoredMessage = false;
      this.chunkDownloadTimeoutNotHonoredMessage = '';
    }

    const fileAccess = this.userPermissionService.getAccessLevel([UserPermission.GetFilesFromInstrument], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.showFilesTab = this.canAddUpdate && fileAccess === AccessLevel.AddUpdate && (this.showLogFilesSection || this.showLutFilesSection || this.showFileBrowserSection);

    if (this.showDownloadSettings_Software) {
      this.addDownloadSettingsSoftwareSettings();
    } else {
      this.removeDownloadSettingsSoftwareSettings();
    }
  }

  get deviceInformation(): DeviceInformation { return this._deviceInformation; }

  set deviceInformation(deviceInformation: DeviceInformation) {
    this._deviceInformation = deviceInformation;

    this.refreshRetrySettingsFormControl();
    this.editInstrumentForm.patchValue(deviceInformation);

    this.updateFormGroupSettingsFromDeviceInformation();
    this.tryLoadDeviceInformationSettingsAndApplyToFormGroup();
    this.refreshRequestsDesiredState();

    this.instrumentSchemaDesiredPropertiesParsedJson = this.getJson(this.deviceInformation?.instrumentSchemaDesiredPropertiesJson);
    this.instrumentSchemaReportedPropertiesParsedJson = this.getJson(this.deviceInformation?.instrumentSchemaReportedPropertiesJson);


    this.softwareList = this.instrumentSchemaReportedPropertiesParsedJson
      ? (this.instrumentSchemaReportedPropertiesParsedJson['software'] as SoftwareEntry[]) ?? []
      : [];
  }

  private tryLoadDeviceInformationSettingsAndApplyToFormGroup() {
    if (!this.deviceInformation.deviceInformationSettingsJsonByKey) {
      return;
    }

    let parsedDesiredProperties: { [key: string]: unknown } = undefined;
    if (this.deviceInformation.desiredPropertiesJson) {
      parsedDesiredProperties = JSON.parse(this.deviceInformation.desiredPropertiesJson) as { [key: string]: unknown };
    }

    const patch = {};

    // download_settings.software
    let downloadSoftwareSettingsToPatch: { [key: string]: unknown } = undefined;
    const downloadSoftwareSettings = tryGetSettingsFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.DownloadSettings_Software_FullFormKey);
    if (downloadSoftwareSettings) {
      downloadSoftwareSettingsToPatch = downloadSoftwareSettings;
    }

    if (downloadSoftwareSettingsToPatch) {
      this.addDownloadSettingsSoftwareSettings();
      let downloadSettingsPatch = patch[DeviceSettingsConstants.DownloadSettings_FormKey] as { [key: string]: unknown };
      if (!downloadSettingsPatch) {
        downloadSettingsPatch = {};
        patch[DeviceSettingsConstants.DownloadSettings_FormKey] = downloadSettingsPatch;
      }
      downloadSettingsPatch[DeviceSettingsConstants.DownloadSettings_Software_FormKey] = downloadSoftwareSettingsToPatch;
    }

    // clinical_mode
    let clinicalModeSettingsToPatch: { [key: string]: unknown } = undefined;
    const clinicalModeSettings = tryGetSettingsFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.ClinicalMode_FormKey);
    if (clinicalModeSettings) {
      this.clinicalModeInDesiredPropertiesOnly = false;
      clinicalModeSettingsToPatch = clinicalModeSettings;
    } else if (parsedDesiredProperties) {
      const clinicalModeDesiredProperty = parsedDesiredProperties[DeviceSettingsConstants.ClinicalMode_FormKey] as { [key: string]: unknown };
      if (clinicalModeDesiredProperty) {
        this.clinicalModeInDesiredPropertiesOnly = true;
        clinicalModeSettingsToPatch = clinicalModeDesiredProperty;
      }
    }

    if (clinicalModeSettingsToPatch) {
      this.addClinicalModeSettings();
      patch[DeviceSettingsConstants.ClinicalMode_FormKey] = clinicalModeSettingsToPatch;
    } else {
      this.removeClinicalModeSettings();
    }

    // test_result_upload
    let testResultsUploadSettingsToPatch: { [key: string]: unknown } = undefined;
    const testResultsUploadSettings = tryGetSettingsFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.TestResultsUpload_FormKey);
    if (testResultsUploadSettings) {
      this.testResultsUploadInDesiredPropertiesOnly = false;
      testResultsUploadSettingsToPatch = testResultsUploadSettings;
    } else if (parsedDesiredProperties) {
      const testResultsUploadDesiredProperty = parsedDesiredProperties[DeviceSettingsConstants.TestResultsUpload_FormKey] as { [key: string]: unknown };
      if (testResultsUploadDesiredProperty) {
        this.testResultsUploadInDesiredPropertiesOnly = true;
        testResultsUploadSettingsToPatch = testResultsUploadDesiredProperty;
      }
    }

    if (testResultsUploadSettingsToPatch) {
      this.addTestResultsUploadSettings();
      patch[DeviceSettingsConstants.TestResultsUpload_FormKey] = testResultsUploadSettingsToPatch;
    }
    else {
      this.disableTestResultsUploadSettings();
    }

    // performance_data
    let performanceDataSettingsToPatch: { [key: string]: unknown } = undefined;
    const performanceDataSettings = tryGetSettingsFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.PerformanceData_FormKey);
    if (performanceDataSettings) {
      this.performanceDataInDesiredPropertiesOnly = false;
      performanceDataSettingsToPatch = performanceDataSettings;
    } else if (parsedDesiredProperties) {
      const performanceDataDesiredProperty = parsedDesiredProperties[DeviceSettingsConstants.PerformanceData_FormKey] as { [key: string]: unknown };
      if (performanceDataDesiredProperty) {
        this.performanceDataInDesiredPropertiesOnly = true;
        performanceDataSettingsToPatch = performanceDataDesiredProperty;
      }
    }

    if (performanceDataSettingsToPatch) {
      this.addPerformanceDataSettings();
      patch[DeviceSettingsConstants.PerformanceData_FormKey] = performanceDataSettingsToPatch;
    } else {
      this.removePerformanceDataSettings();
    }

    this.addAwsSettings();

    this.tryApplySettingsToPatch(patch, parsedDesiredProperties);

    this.editInstrumentForm.patchValue(patch);

    this.updateEditFormControlPermissions();
  }

  private tryApplySettingsToPatch(patch: unknown, parsedDesiredProperties: { [key: string]: unknown }) {

    let settingsPatch: { [key: string]: unknown } = undefined;
    let addAllowlistFolderPaths = false;
    let allowlistFolderPaths = tryGetSettingsArrayFromJson(this.deviceInformation.deviceInformationSettingsJsonByKey, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath) as string[];
    if (allowlistFolderPaths?.length > 0) {
      addAllowlistFolderPaths = true;
      this.allowlistFolderPathsInDesiredPropertiesOnly = false;
    } else if (parsedDesiredProperties) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      allowlistFolderPaths = parsedDesiredProperties[DeviceSettingsConstants.Settings_FormKey]?.[DeviceSettingsConstants.Settings_IoT_FormKey]?.[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey] as string[];
      if (allowlistFolderPaths) {
        addAllowlistFolderPaths = true;
        this.allowlistFolderPathsInDesiredPropertiesOnly = true;
      }
    }

    if (addAllowlistFolderPaths) {
      allowlistFolderPaths = allowlistFolderPaths.sort((a, b) => a.localeCompare(b));
      settingsPatch = {};
      const settingsIoTPath = {};
      settingsIoTPath[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey] = allowlistFolderPaths;
      settingsPatch[DeviceSettingsConstants.Settings_IoT_FormKey] = settingsIoTPath;

      this.addAllowlistFolderPaths();
    } else {
      this.removeAllowlistFolderPaths();
    }

    if (settingsPatch) {
      patch[DeviceSettingsConstants.Settings_FormKey] = settingsPatch;
    }

    this.removeSettingsFormGroupIfEmpty();
  }

  private refreshRequestsDesiredState() {
    this.hasFirmwareUpgradeRequest = Boolean(this.deviceInformation?.requestedFirmwareStatus || this.deviceInformation?.requestedFirmwareVersion || this.deviceInformation?.requestedFirmwareInfo);
    this.hasAssayUpgradeRequests = Boolean(this.deviceInformation?.requestedAssayVersions && this.deviceInformation?.requestedAssayVersions?.length > 0);
    this.hasLanguagepgradeRequests = Boolean(this.deviceInformation?.requestedLanguageVersions && this.deviceInformation?.requestedLanguageVersions?.length > 0);
    this.hasUpgradesDesiredState = this.hasFirmwareUpgradeRequest || this.hasAssayUpgradeRequests || this.hasLanguagepgradeRequests;


    if (!this.deviceInformation || !this.deviceInformation.desiredPropertiesJson) {
      this.hasInstrumentSettingsDesiredState = false;
      this.instrumentSettingsDesiredState = undefined;
    } else {
      const allDesiredProperties = JSON.parse(this.deviceInformation.desiredPropertiesJson) as { [key: string]: unknown };
      const instrumentSettingsDesiredProperties = { ...allDesiredProperties };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const allowlistFolderPaths = instrumentSettingsDesiredProperties[DeviceSettingsConstants.Settings_FormKey]?.[DeviceSettingsConstants.Settings_IoT_FormKey]?.[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey] as string[];
      if (allowlistFolderPaths) {
        instrumentSettingsDesiredProperties[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath] = allowlistFolderPaths;
      }

      Object.keys(instrumentSettingsDesiredProperties).forEach(key => {
        if (!DeviceSettingsConstants.InstrumentSettingsRootKeys.includes(key)) {
          delete instrumentSettingsDesiredProperties[key];
        }
      });

      this.hasInstrumentSettingsDesiredState = Object.keys(instrumentSettingsDesiredProperties).length > 0;
      this.instrumentSettingsDesiredState = instrumentSettingsDesiredProperties;

      const amfDesiredProperties = allDesiredProperties['amf'] as { [key: string]: { force: boolean } };
      if (amfDesiredProperties && this._deviceInformation?.requestedAssayVersions) {
        this._deviceInformation.requestedAssayVersions.forEach(requestedAssayVersion => {
          const matchingAmfDesiredProperty = amfDesiredProperties[requestedAssayVersion.code];
          if (matchingAmfDesiredProperty) {
            requestedAssayVersion.forced = matchingAmfDesiredProperty.force;
          }
        });
      }
    }
  }

  public addClinicalModeSettings(): void {
    this.clinicalModeSettingsAdded = true;

    if (!this.editInstrumentForm.contains(DeviceSettingsConstants.ClinicalMode_FormKey)) {
      this.editInstrumentForm.addControl(DeviceSettingsConstants.ClinicalMode_FormKey, this.fb.group(DeviceSettingsConstants.ClinicalMode_Form_DefaultConfig));
    }

    const defaultSettings = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, DeviceSettingsConstants.ClinicalMode_FormKey);
    if (defaultSettings) {
      const patch = {};
      patch[DeviceSettingsConstants.ClinicalMode_FormKey] = defaultSettings;
      this.editInstrumentForm.patchValue(patch);
    }
  }

  public removeClinicalModeSettings(): void {
    this.clinicalModeSettingsAdded = false;
    this.clinicalModeInDesiredPropertiesOnly = false;

    if (this.editInstrumentForm.contains(DeviceSettingsConstants.ClinicalMode_FormKey)) {
      this.editInstrumentForm.removeControl(DeviceSettingsConstants.ClinicalMode_FormKey);
    }
  }

  public addAwsSettings(): void {

    const patch = {};
    const awsSettings = DeviceSettingsConstants.AwsSettings_Form_DefaultConfig;
    awsSettings[DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey] = this.deviceInformation.awsDestinationFolderOverride;
    awsSettings[DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey] = this.deviceInformation.awsDestinationFolderOverride && this.deviceInformation.awsDestinationFolderOverride != '';
    patch[DeviceSettingsConstants.AwsSettings_FormKey] = awsSettings;
    this.editInstrumentForm.patchValue(patch);

    const awsFolderOverrideControl = this.editInstrumentForm.get([DeviceSettingsConstants.AwsSettings_FormKey, DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey]);

    this.awsEnableFolderOverrideValueChangeSubscription = this.editInstrumentForm
      .get([DeviceSettingsConstants.AwsSettings_FormKey, DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey]).valueChanges
      .pipe(
        // https://stackoverflow.com/a/59678874/10752002
        debounceTime(100), // ensuring that on creation of this control we don't update validation between enabled and disabled until final results
        distinctUntilChanged()
      )
      .subscribe((enabled: boolean) => {
        if (enabled) {
          /* eslint-disable @typescript-eslint/unbound-method */
          awsFolderOverrideControl.setValidators([
            Validators.required,
            Validators.minLength(DeviceSettingsConstants.AwsSettings_OverrideLength_Min),
            Validators.maxLength(DeviceSettingsConstants.AwsSettings_OverrideLength_Max),
            Validators.pattern(ValidationConstants.alphaNumericPattern)]);
          /* eslint-enable @typescript-eslint/unbound-method */

          awsFolderOverrideControl.updateValueAndValidity();
        } else {
          awsFolderOverrideControl.setValidators(null);
          awsFolderOverrideControl.setValue('');
        }
      });
  }

  public addTestResultsUploadSettings(): void {
    this.testResultsUploadSettingsAdded = true;

    if (!this.editInstrumentForm.contains(DeviceSettingsConstants.TestResultsUpload_FormKey)) {
      this.editInstrumentForm.addControl(DeviceSettingsConstants.TestResultsUpload_FormKey, this.fb.group(DeviceSettingsConstants.TestResultsUpload_Form_DefaultConfig));
      this.testResultEnabledValueChangeSubscription = this.editInstrumentForm
        .get([DeviceSettingsConstants.TestResultsUpload_FormKey, DeviceSettingsConstants.TestResultsUpload_Enabled_FormKey]).valueChanges
        .pipe(
          // https://stackoverflow.com/a/59678874/10752002
          debounceTime(100), // ensuring that on creation of this control we don't update validation between enabled and disabled until final results
          distinctUntilChanged()
        )
        .subscribe((enabled: boolean) => {
          this.testResultsUploadSettingsEnabled = enabled;
          DeviceSettingsConstants.updateTestResultsUploadFormValidators(this.editInstrumentForm, enabled);
        });
    }

    const defaultSettings = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, DeviceSettingsConstants.TestResultsUpload_FormKey);
    if (defaultSettings) {
      const patch = {};
      patch[DeviceSettingsConstants.TestResultsUpload_FormKey] = defaultSettings;
      this.editInstrumentForm.patchValue(patch);
    }
  }

  private disableTestResultsUploadSettings(): void {
    this.testResultsUploadSettingsAdded = false;
    this.testResultsUploadInDesiredPropertiesOnly = false;

    if (this.editInstrumentForm.contains(DeviceSettingsConstants.TestResultsUpload_FormKey)) {
      this.editInstrumentForm.removeControl(DeviceSettingsConstants.TestResultsUpload_FormKey);
    }

    this.testResultEnabledValueChangeSubscription?.unsubscribe();
  }

  public addPerformanceDataSettings(): void {
    this.performanceDataSettingsAdded = true;

    if (!this.editInstrumentForm.contains(DeviceSettingsConstants.PerformanceData_FormKey)) {
      this.editInstrumentForm.addControl(DeviceSettingsConstants.PerformanceData_FormKey, this.fb.group(DeviceSettingsConstants.PerformanceData_Form_DefaultConfig));
    }

    const defaultSettings = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, DeviceSettingsConstants.PerformanceData_FormKey);
    if (defaultSettings) {
      const patch = {};
      patch[DeviceSettingsConstants.PerformanceData_FormKey] = defaultSettings;
      this.editInstrumentForm.patchValue(patch);
    }
  }

  public removePerformanceDataSettings(): void {
    this.performanceDataSettingsAdded = false;
    this.performanceDataInDesiredPropertiesOnly = false;

    if (this.editInstrumentForm.contains(DeviceSettingsConstants.PerformanceData_FormKey)) {
      this.editInstrumentForm.removeControl(DeviceSettingsConstants.PerformanceData_FormKey);
    }
  }

  private addAllowlistFolderPaths(): void {
    this.allowlistFolderPathsAdded = true;

    addNestedFormControl(this.fb, this.editInstrumentForm, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath, [DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_Default]);
  }

  private removeAllowlistFolderPaths(): void {
    this.allowlistFolderPathsAdded = false;
    this.allowlistFolderPathsInDesiredPropertiesOnly = false;
    removeNestedFormControl(this.editInstrumentForm, DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath);
  }

  private removeSettingsFormGroupIfEmpty(): void {
    InstrumentDialogComponent.removeFormGroupIfEmpty(this.editInstrumentForm, DeviceSettingsConstants.Settings_FormKey);
  }

  private static removeFormGroupIfEmpty(containingFormGroup: FormGroup, groupNameToRemoveIfEmpty: string): void {
    if (!containingFormGroup.contains(groupNameToRemoveIfEmpty)) {
      return;
    }

    const formGroup = containingFormGroup.get(groupNameToRemoveIfEmpty) as FormGroup;
    if (!formGroup.controls
      || Object.keys(formGroup.controls).length == 0) {
      containingFormGroup.removeControl(groupNameToRemoveIfEmpty);
    }
  }

  private updateFormGroupSettingsFromDeviceInformation() {
    const optIn = this.deviceInformation.virenaOptIn === undefined
      ? false
      : this.deviceInformation.virenaOptIn;
    const autoSend = this.deviceInformation.virenaAutoSend === undefined
      ? false
      : this.deviceInformation.virenaAutoSend;

    const virenaSettingsTemp = {};
    virenaSettingsTemp[DeviceSettingsConstants.VirenaSettings_OptIn_FormKey] = optIn;
    virenaSettingsTemp[DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey] = autoSend;

    const retryTimeDelayMilliseconds = this.deviceInformation.retryTimeDelayMilliseconds === undefined
      ? DeviceSettingsConstants.RetrySettings_TimeDelayInMs_Default
      : this.deviceInformation.retryTimeDelayMilliseconds;
    const numberRetries = this.deviceInformation.numberRetries === undefined
      ? DeviceSettingsConstants.RetrySettings_NumberRetries_Default
      : this.deviceInformation.numberRetries;

    const retrySettingsTemp = {};
    retrySettingsTemp[DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey] = retryTimeDelayMilliseconds;
    retrySettingsTemp[DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey] = numberRetries;

    const chunkDownloadTimeoutInSeconds = this.deviceInformation.chunkDownloadTimeoutInSeconds === undefined
      ? DeviceSettingsConstants.DownloadSettings_TimeoutInS_Default
      : this.deviceInformation.chunkDownloadTimeoutInSeconds;

    const downloadSettingsTemp = {};
    downloadSettingsTemp[DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey] = chunkDownloadTimeoutInSeconds;

    const patch = {};
    patch[DeviceSettingsConstants.VirenaSettings_FormKey] = virenaSettingsTemp;
    patch[DeviceSettingsConstants.RetrySettings_FormKey] = retrySettingsTemp;
    patch[DeviceSettingsConstants.DownloadSettings_FormKey] = downloadSettingsTemp;

    this.editInstrumentForm.patchValue(patch);
    this.handleVirenaOptIn(optIn);
  }

  get headerInformation(): string {
    if (!this._deviceInformation) {
      return '';
    }

    const serialNumber = this._deviceInformation.serialNumber;
    const id = this._deviceInformation.deviceInformationId;

    return translateWithInterpolateParams(this.translateService, TranslateConstants.InstrumentSerialNumberAndIdLabelKey, { serialNumber: serialNumber, id: id });
  }

  ngOnInit(): void {
    this.getInstrumentGroupsByDeviceInformationId();
    this.getInstrumentHistory();
    this.getPendingC2DMessages();
    this.subscription.add(
      this.userPermissionService.isReadyEvent$
        .subscribe(_ => {
          this.setUserPermissions();

          this.refreshFeatureFlagsBySelectedInstrumentType();
        }));

    this.ensureInstrumentTypeAndModelFieldsHaveCorrectEnabledState();

    this.subscription.add(this.editInstrumentForm.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]).valueChanges.subscribe(data => {
      const optIn = data as boolean;
      this.handleVirenaOptIn(optIn);
    }));

    this.updateUIForSelectedInstrumentType();

    this.filteredCountries = this.editInstrumentForm.get(InstrumentDialogComponent.countryFormFieldName).valueChanges.pipe(
      startWith(null),
      map((country: Country | string | null) => filterCountrySearchOptions(country, this.allCountries, undefined)));
  }

  private handleVirenaOptIn(optIn: boolean) {
    setTimeout(() => {
      const autoSendControl: AbstractControl = this.editInstrumentForm.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey]);
      if (autoSendControl) {
        if (optIn && this.virenaOptInAccessLevel == AccessLevel.AddUpdate) {
          autoSendControl.enable();
        } else {
          autoSendControl.disable();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.testResultEnabledValueChangeSubscription?.unsubscribe();
    this.awsEnableFolderOverrideValueChangeSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  private setUserPermissions(): void {
    this.accessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.virenaOptInAccessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument, UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.cancelUpgradeAccessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.ManageAllInstruments, UserPermission.ManageSingleInstrument, UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.allowlistFolderPathsAccessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.ManageAllowlistFolderPaths], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.manageInstrumentGroupsAccessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.ManageAllInstrumentGroups], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.awsSettingsAccessLevel = this.userPermissionService.getAccessLevel(
      [UserPermission.SysAdmin], [UserPermission.ViewEverything], this.deviceInformation.instrumentTypeId);

    this.updateEditFormControlPermissions();
  }

  private updateEditFormControlPermissions(): void {
    for (const field in this.editInstrumentForm.controls) {
      const control = this.editInstrumentForm.get(field);

      let virenaEnabledControl: AbstractControl;
      let level = this.accessLevel;
      if (field == DeviceSettingsConstants.VirenaSettings_FormKey) {
        level = this.virenaOptInAccessLevel;
        virenaEnabledControl = control.get(DeviceSettingsConstants.VirenaSettings_OptIn_FormKey);
      }

      if (level === AccessLevel.AddUpdate) {
        control.enable();
      } else {
        control.disable();
      }

      // enabling/disabling an individual control has to happen AFTER enabling/disabling the parent control
      // since we want the parent control to affect all its child controls
      if (virenaEnabledControl) {
        if (level == AccessLevel.AddUpdate && this.canToggleVirenaEnabled) {
          virenaEnabledControl.enable();
        } else {
          virenaEnabledControl.disable();
        }
      }

      if (field == DeviceSettingsConstants.Settings_FormKey) {
        const allowlistFolderPathsControl = this.editInstrumentForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath);
        if (allowlistFolderPathsControl) {
          if (this.canAddUpdateAllowlistFolderPaths) {
            allowlistFolderPathsControl.enable();
          } else {
            allowlistFolderPathsControl.disable();
          }
        }
      }

      if (field == DeviceSettingsConstants.AwsSettings_FormKey) {
        const awsSettingsEnableFolderControl = control.get(DeviceSettingsConstants.AwsSettings_EnableFolderOverride_FormKey);
        if (awsSettingsEnableFolderControl) {
          if (this.canAddUpdateAwsSettings) {
            awsSettingsEnableFolderControl.enable();
          } else {
            awsSettingsEnableFolderControl.disable();
          }
        }

        const awsSettingsFolderControl = control.get(DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey);
        if (awsSettingsFolderControl) {
          if (this.canAddUpdateAwsSettings) {
            awsSettingsFolderControl.enable();
          } else {
            awsSettingsFolderControl.disable();
          }
        }

      }
    }
  }

  public getJson(data: string): object {
    if (!data) {
      return {};
    }
    const parsedJson = JSON.parse(data) as Record<string, unknown>;
    const iotPropertiesMetadataKey = '$metadata';
    const iotMetadata = parsedJson[iotPropertiesMetadataKey];
    delete parsedJson[iotPropertiesMetadataKey];

    const iotPropertiesVersionKey = '$version';
    const iotVersion = parsedJson[iotPropertiesVersionKey];
    delete parsedJson[iotPropertiesVersionKey];

    const sortedJson = sort(parsedJson);
    if (iotMetadata) {
      sortedJson[iotPropertiesMetadataKey] = iotMetadata;
    }
    if (iotVersion) {
      sortedJson[iotPropertiesVersionKey] = iotVersion;
    }

    return sortedJson;
  }

  public updateGroups(): void {
    this.instrumentGroups = clone(this.updatedUnsavedCheckedGroups);
    this.changeGroupsMode = false;
    this.updatedUnsavedCheckedGroups.length = 0;
  }

  public cancelGroupsMode(): void {
    this.changeGroupsMode = false;
    this.updatedUnsavedCheckedGroups.length = 0;
  }

  public showChangeGroups(): void {
    this.getAllInstrumentGroups();
    this.changeGroupsMode = true;
  }

  public removeFromInstrumentGroup($instrumentGroup: InstrumentGroup): void {
    const instrumentGroupIndex = this.instrumentGroups.findIndex(x => x.instrumentGroupId === $instrumentGroup.instrumentGroupId);
    this.instrumentGroups.splice(instrumentGroupIndex, 1);
    this.instrumentGroups = clone(this.instrumentGroups);
  }

  public showCopyToClipboardNotification(): void {
    this.notificationService.success(TranslateConstants.CopyToClipboardSuccessKey);
  }

  public refreshAllData(deviceInformation: DeviceInformation): void {
    this.refreshInstrumentData(deviceInformation);
    this.getInstrumentGroupsByDeviceInformationId();
    this.getInstrumentHistory();
    this.getPendingC2DMessages();
  }

  public getInstrumentGroupsByDeviceInformationId(): void {
    if (!this.deviceInformation || !this.deviceInformation.deviceInformationId || this.deviceInformation?.deviceInformationId <= 0) {
      return;
    }

    this.subscription.add(this.dataService.getInstrumentGroupsByInstrumentId(this.deviceInformation.deviceInformationId).subscribe((instrumentGroups) => {
      this.instrumentGroups = instrumentGroups;
    }));
  }

  private getInstrumentHistory(): void {
    if (!this.deviceInformation || !this.deviceInformation.deviceInformationId || this.deviceInformation?.deviceInformationId <= 0) {
      return;
    }

    this.subscription.add(this.dataService.getDeviceActionHistory(this.deviceInformation.serialNumber).subscribe((instrumentActionHistoryRows: InstrumentActionHistoryRow[]) => {
      this.instrumentActionHistoryRows = instrumentActionHistoryRows;
    }));
  }

  private getPendingC2DMessages(): void {
    if (!this.deviceInformation || !this.deviceInformation.deviceInformationId || this.deviceInformation?.deviceInformationId <= 0) {
      return;
    }

    this.subscription.add(this.dataService.getDevicePendingC2DMessages(this.deviceInformation.serialNumber).subscribe((c2dPendingMessages: C2DPendingMessage[]) => {
      this.c2dPendingMessages = c2dPendingMessages;
      this.hasPendingC2DMessages = this.c2dPendingMessages?.length > 0;
    }));
  }

  private getAllInstrumentGroups(): void {
    const instrumentGroupsRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(undefined, this.deviceInformation.instrumentTypeId);
    this.subscription.add(this.dataService.getInstrumentGroups(instrumentGroupsRequest).subscribe((instrumentGroupsResult: InstrumentGroupsResult) => {
      this.allInstrumentGroups = instrumentGroupsResult;
    }));
  }

  public instrumentGroupDataRequested($event: InstrumentGroupListRequest): void {
    this.currentInstrumentGroupRequest = $event;
    this.getInstrumentGroupsWithCurrentSearchState();
  }

  public getInstrumentGroupsWithCurrentSearchState(): void {
    const instrumentGroupsRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.deviceInformation.instrumentTypeId);
    this.subscription.add(
      this.dataService.getInstrumentGroups(instrumentGroupsRequest)
        .subscribe((instrumentGroupsResult: InstrumentGroupsResult) => {
          this.allInstrumentGroups = instrumentGroupsResult;
        }));
  }

  public onCheckedGroups($event: CheckedGroupsEvent): void {
    this.updatedUnsavedCheckedGroups = $event.checkedGroups;
  }

  public onInstrumentTypeChange($event: MatSelectChange): void {
    this.deviceInformation.instrumentTypeId = $event.value as number;
    this.instrumentType = this.instrumentTypes.find(i => this.deviceInformation.instrumentTypeId === i.instrumentTypeId);
    this.updateModelSelectionForInstrumentType();
    this.updateUIForSelectedInstrumentType();

    this.refreshFeatureFlagsBySelectedInstrumentType();
  }

  public async removeFirmwareRequest(deviceInformation: DeviceInformation): Promise<void> {
    const version = this.deviceHelperService.getConcatenatedVersion(deviceInformation?.requestedFirmwareVersion);
    const labeledVersion = version ? translateWithInterpolateParams(this.translateService, TranslateConstants.VersionLabelKey, { version: version }) : '';

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.RemoveRequestTileKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.RemoveUpgradeRequestMessageKey, this.firmwareTypeKey, labeledVersion)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const cancelRequest: CancelUpgradeRequest = {
        serialNumbers: [deviceInformation.serialNumber],
        instrumentTypeId: deviceInformation.instrumentTypeId,
        firmwareVersion: deviceInformation.requestedFirmwareVersion
      };

      this.subscription.add(this.dataService.cancelUpgradeRequest(cancelRequest).subscribe(() => {
        this.refreshInstrumentData(deviceInformation);
        this.notificationService.success(TranslateConstants.CancelRequestSuccessKey);
      }));
    }
  }

  public async removeAssayRequest(deviceInformation: DeviceInformation, assayVersion: AssayVersion): Promise<void> {
    const version = this.deviceHelperService.getConcatenatedVersion(assayVersion?.version);
    const labeledVersion = version ? translateWithInterpolateParams(this.translateService, TranslateConstants.VersionLabelKey, { version: version }) : '';
    const labeledCode = assayVersion?.code ? translateWithInterpolateParams(this.translateService, TranslateConstants.CodeLabelKey, { code: assayVersion.code }) : '';
    const labeledInterfaceVersion = assayVersion?.interfaceVersion ? translateWithInterpolateParams(this.translateService, TranslateConstants.InterfaceVersionLabelKey, { interfaceVersion: assayVersion.interfaceVersion }) : '';

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.RemoveRequestTileKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.RemoveUpgradeRequestMessageKey, TranslateConstants.AssayKey, labeledCode + labeledInterfaceVersion + labeledVersion)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const assayVersions = {} as { [key: string]: CancelUpgradeVersion[] };
      assayVersions[assayVersion.code] = [{ version: assayVersion.version, interfaceVersion: assayVersion.interfaceVersion } as CancelUpgradeVersion];

      const cancelRequest: CancelUpgradeRequest = {
        serialNumbers: [deviceInformation.serialNumber],
        instrumentTypeId: deviceInformation.instrumentTypeId,
        assays: assayVersions
      };

      this.subscription.add(this.dataService.cancelUpgradeRequest(cancelRequest).subscribe(() => {
        this.refreshInstrumentData(deviceInformation);
        this.notificationService.success(TranslateConstants.CancelRequestSuccessKey);
      }));
    }
  }

  public async removeLanguageRequest(deviceInformation: DeviceInformation, languageVersion: LanguageVersion): Promise<void> {
    const version = this.deviceHelperService.getConcatenatedVersion(languageVersion?.version);
    const labeledVersion = version ? translateWithInterpolateParams(this.translateService, TranslateConstants.VersionLabelKey, { version: version }) : '';
    const labeledCode = languageVersion?.code ? translateWithInterpolateParams(this.translateService, TranslateConstants.CodeLabelKey, { code: languageVersion.code }) : '';
    const labeledInterfaceVersion = languageVersion?.interfaceVersion ? translateWithInterpolateParams(this.translateService, TranslateConstants.InterfaceVersionLabelKey, { interfaceVersion: languageVersion.interfaceVersion }) : '';

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.RemoveRequestTileKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.RemoveUpgradeRequestMessageKey, TranslateConstants.LanguageKey, labeledCode + labeledInterfaceVersion + labeledVersion)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const languageVersions = {} as { [key: string]: CancelUpgradeVersion[] };
      languageVersions[languageVersion.code] = [{ version: languageVersion.version, interfaceVersion: languageVersion.interfaceVersion } as CancelUpgradeVersion];

      const cancelRequest: CancelUpgradeRequest = {
        serialNumbers: [deviceInformation.serialNumber],
        instrumentTypeId: deviceInformation.instrumentTypeId,
        languages: languageVersions
      };

      this.subscription.add(this.dataService.cancelUpgradeRequest(cancelRequest).subscribe(() => {
        this.refreshInstrumentData(deviceInformation);
        this.notificationService.success(TranslateConstants.CancelRequestSuccessKey);
      }));
    }
  }

  public async removeDeviceSettingRequest(deviceInformation: DeviceInformation, deviceSettingKey: string): Promise<boolean> {
    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.RemoveRequestTileKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: translateWithInterpolateParams(this.translateService, TranslateConstants.RemoveDeviceSettingRequestMessageKey, { identifier: translate(this.translateService, this.tryGetTranslateKey([deviceSettingKey])) })
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const cancelRequest: CancelUpgradeRequest = {
        serialNumbers: [deviceInformation.serialNumber],
        instrumentTypeId: deviceInformation.instrumentTypeId,
        deviceSettings: [deviceSettingKey]
      };

      this.subscription.add(this.dataService.cancelUpgradeRequest(cancelRequest).subscribe(() => {
        this.refreshInstrumentData(deviceInformation);
        this.notificationService.success(TranslateConstants.CancelRequestSuccessKey);
      }));
    }

    return response.result;
  }

  public populateDefaultSettings(deviceSettingKey: string): void {

    let defaultSettings: InstrumentDefaultSettings;

    switch (deviceSettingKey) {
      case DeviceSettingsConstants.TestResultsUpload_FormKey:
        defaultSettings = this.populateDefaultSettingsByInstrumentType(deviceSettingKey);
        break;
      default:
        defaultSettings = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, deviceSettingKey);

    }

    if (defaultSettings) {
      const patch = {};
      patch[deviceSettingKey] = defaultSettings;

      this.editInstrumentForm.patchValue(patch);
    }
  }

  private populateDefaultSettingsByInstrumentType(desiredKey: string): InstrumentDefaultSettings {
    const defaultSettings: InstrumentDefaultSettingsMap = tryGetSettingsFromJson(this.instrumentSettingDefaultsJsonByKey, desiredKey) as InstrumentDefaultSettingsMap;

    if (defaultSettings) {
      const firmwareVersion: Version = this._deviceInformation.firmwareVersion;
      let instrumentDefaultSettings: InstrumentDefaultSettings;

      if (defaultSettings[this.instrumentType.code]) {
        const settings = defaultSettings[this.instrumentType.code];

        if (settings.length > 0) {
          if (!firmwareVersion) {
            instrumentDefaultSettings = settings[0];
          }
          else {
            const settingsNdx = settings.findIndex(d => compareVersion(firmwareVersion, convertToVersion(d.min_version), true) < 0);

            if (settingsNdx > -1) {
              if (settingsNdx > 0) {
                instrumentDefaultSettings = settings[settingsNdx - 1];
              }
            }
            else {
              // device firmware is higher than all of the listed values, so use the last one
              instrumentDefaultSettings = settings[settings.length - 1];
            }
          }
        }
      }

      // If doesn't match, use default
      if (!instrumentDefaultSettings && defaultSettings[InstrumentDefaultSettingsType.default]) {
        const defaultInstrumentSettings = defaultSettings[InstrumentDefaultSettingsType.default];

        instrumentDefaultSettings = defaultInstrumentSettings[0];
      }

      return instrumentDefaultSettings;
    }

  }

  public onAddInstrument(close: boolean = false): void {
    // override existing properties with form value
    const deviceInformation = {
      ...this.deviceInformation,
      ...this.editInstrumentForm.value,
    } as DeviceInformation;

    this.updateDeviceInformationFromNestedFormGroups(deviceInformation, this.editInstrumentForm);

    delete deviceInformation.desiredPropertiesJson;
    delete deviceInformation.reportedPropertiesJson;
    this.clearNestedFormGroupPropertiesFromDeviceInformation(deviceInformation);

    this.saveDeviceInformation(deviceInformation, close);
  }

  public onSaveInstrument(close: boolean = false): void {
    // override existing properties with form value
    const deviceInformation = {
      ...this.deviceInformation,
      ...this.editInstrumentForm.value,
    } as DeviceInformation;

    this.updateDeviceInformationFromNestedFormGroups(deviceInformation, this.editInstrumentForm);
    this.clearNestedFormGroupPropertiesFromDeviceInformation(deviceInformation);

    this.saveDeviceInformation(deviceInformation, close);
  }

  private updateDeviceInformationFromNestedFormGroups(deviceInformation: DeviceInformation, rootFormGroup: UntypedFormGroup) {
    deviceInformation.countryId = (<Country>rootFormGroup.get(InstrumentDialogComponent.countryFormFieldName).value).countryId;

    deviceInformation.virenaOptIn = rootFormGroup.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_OptIn_FormKey]).value as boolean;
    deviceInformation.virenaAutoSend = rootFormGroup.get([DeviceSettingsConstants.VirenaSettings_FormKey, DeviceSettingsConstants.VirenaSettings_AutoSend_FormKey])?.value as boolean;

    deviceInformation.retryTimeDelayMilliseconds = rootFormGroup.get([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]).value as number;
    deviceInformation.numberRetries = rootFormGroup.get([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_NumberRetries_FormKey]).value as number;

    deviceInformation.chunkDownloadTimeoutInSeconds = rootFormGroup.get([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_TimeoutInS_FormKey]).value as number;

    const downloadSettingsSoftwareFormGroup = rootFormGroup.get([DeviceSettingsConstants.DownloadSettings_FormKey, DeviceSettingsConstants.DownloadSettings_Software_FormKey]);
    if (downloadSettingsSoftwareFormGroup) {
      deviceInformation.deviceInformationSettingsJsonByKey[DeviceSettingsConstants.DownloadSettings_Software_FullFormKey] = JSON.stringify(downloadSettingsSoftwareFormGroup.value);
    }

    deviceInformation.awsDestinationFolderOverride = rootFormGroup.get([DeviceSettingsConstants.AwsSettings_FormKey, DeviceSettingsConstants.AwsSettings_FolderOverride_FormKey])?.value as string;

    const clinicalModeFormGroup = rootFormGroup.get([DeviceSettingsConstants.ClinicalMode_FormKey]);
    if (clinicalModeFormGroup) {
      deviceInformation.deviceInformationSettingsJsonByKey[DeviceSettingsConstants.ClinicalMode_FormKey] = JSON.stringify(clinicalModeFormGroup.value);
    }

    const testResultsUploadFormGroup = rootFormGroup.get([DeviceSettingsConstants.TestResultsUpload_FormKey]);
    if (testResultsUploadFormGroup) {
      const data: unknown = testResultsUploadFormGroup.value;
      if (!this.showTestResultsUpload_DeleteAfterUpload) {
        delete data[DeviceSettingsConstants.TestResultsUpload_DeleteAfterUpload_FormKey];
      }
      deviceInformation.deviceInformationSettingsJsonByKey[DeviceSettingsConstants.TestResultsUpload_FormKey] = JSON.stringify(data);
    }

    const performanceDataFormGroup = rootFormGroup.get([DeviceSettingsConstants.PerformanceData_FormKey]);
    if (performanceDataFormGroup) {
      deviceInformation.deviceInformationSettingsJsonByKey[DeviceSettingsConstants.PerformanceData_FormKey] = JSON.stringify(performanceDataFormGroup.value);
    }

    const settingsFormGroup = rootFormGroup.get([DeviceSettingsConstants.Settings_FormKey]);
    if (settingsFormGroup) {
      const iotFormGroup = settingsFormGroup.get([DeviceSettingsConstants.Settings_IoT_FormKey]);
      if (iotFormGroup) {
        const allowlistFolderPathsControl = iotFormGroup.get([DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey]);
        if (allowlistFolderPathsControl) {
          deviceInformation.deviceInformationSettingsJsonByKey[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath] = JSON.stringify(allowlistFolderPathsControl.value);
        }
      }
    }
  }

  private clearNestedFormGroupPropertiesFromDeviceInformation(deviceInformation: DeviceInformation) {
    delete deviceInformation[InstrumentDialogComponent.countryFormFieldName];
    delete deviceInformation[DeviceSettingsConstants.VirenaSettings_FormKey];
    delete deviceInformation[DeviceSettingsConstants.RetrySettings_FormKey];
    delete deviceInformation[DeviceSettingsConstants.DownloadSettings_FormKey];
    delete deviceInformation[DeviceSettingsConstants.Settings_FormKey];
    delete deviceInformation[DeviceSettingsConstants.AwsSettings_FormKey];
  }

  private saveDeviceInformation($deviceInformation: DeviceInformation, close: boolean = false): void {
    const instrumentGroupIds = this.instrumentGroups?.length > 0
      ? this.instrumentGroups.map(x => x.instrumentGroupId)
      : [];
    if ($deviceInformation.deviceInformationId > 0) {
      this.subscription.add(this.dataService.updateDeviceInformation({
        deviceInformation: $deviceInformation
      }).subscribe((data) => {
        if (this.canAddUpdate) {
          // Users with the UpdateInstruments claim can update the device information, but they can't update instrument groups
          if (this.canManageInstrumentGroups) {
            this.subscription.add(this.dataService.updateInstrumentInstrumentGroup(this.deviceInformation.deviceInformationId, instrumentGroupIds).subscribe(() => {
              this.onSaveDeviceInformationSuccess(true, close);
            }));
          } else {
            this.onSaveDeviceInformationSuccess(true, close);
          }

        } else {
          this.onSaveDeviceInformationSuccess(true, close);
        }
      }));
    } else {
      this.subscription.add(this.dataService.addDeviceInformation({
        deviceInformation: $deviceInformation
      }).subscribe((id: number) => {
        this.onSaveDeviceInformationSuccess(false, close);
      }));
    }
  }

  private onSaveDeviceInformationSuccess(wasAnUpdate: boolean, close: boolean = false) {
    this.getInstrumentGroupsByDeviceInformationId();
    this.changeGroupsMode = false;
    if (this.updatedUnsavedCheckedGroups) {
      this.updatedUnsavedCheckedGroups.length = 0;
    }

    if (close) {
      this.close(false);
    }
    const messageKey = wasAnUpdate ? TranslateConstants.UpdateSuccessKey : TranslateConstants.CreateSuccessKey;
    this.notificationService.success(messageKey, { type: TranslateConstants.InstrumentKey });
  }

  private close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public downloadDeviceFile(deviceFileToDownload: DeviceFileMetadata): void {
    this.deviceFileService.getDeviceFileDownloadUrl(deviceFileToDownload).subscribe(downloadUrl => {
      this.deviceFileService.downloadDeviceFile(downloadUrl, deviceFileToDownload.fileName);
    });
  }

  public loadRecentUpgradeRequestHistory(): void {
    this.requestHistoryComponent.loadRecentUpgradeRequestHistory();
  }

  private refreshInstrumentData(deviceInformation: DeviceInformation): void {
    this.subscription.add(this.dataService.getDeviceInformationById(deviceInformation.deviceInformationId).subscribe((deviceInformationResult) => {
      this.minMaxValidationValuesByKeyParts.clear();
      this.deviceInformation = deviceInformationResult;
      this.refreshCloudToDeviceMessagesData();
      this.refreshFeatureFlagsBySelectedInstrumentType();
      this.updateUIForSelectedInstrumentType();
      this.ensureInstrumentTypeAndModelFieldsHaveCorrectEnabledState();
    }));
  }

  public requestLutFileUpload(): void {
    const deviceRequest = {
      serialNumbers: [this.deviceInformation.serialNumber]
    } as DeviceRequest;
    this.subscription.add(this.c2dMessagesService.requestLutFilesUpload(deviceRequest)
      .subscribe((result: C2DMessageRequestResponse) => {
        C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestLUTFilesKey);
        if (result.requestedDateTimeUtc) {
          this.lutLastRequestedDateTime = result.requestedDateTimeUtc;
        }
      }));
  }

  public requestLogsUpload(): void {
    const deviceRequest = {
      serialNumbers: [this.deviceInformation.serialNumber]
    } as DeviceRequest;
    this.subscription.add(this.c2dMessagesService.requestLogsUpload(deviceRequest)
      .subscribe((result: C2DMessageRequestResponse) => {
        C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, TranslateConstants.RequestLogsUploadKey);
        if (result.requestedDateTimeUtc) {
          this.logsLastRequestedDateTime = result.requestedDateTimeUtc;
        }
      }));
  }

  public async requestAssayFileDeletion(assayVersion: AssayVersion): Promise<void> {
    const title = TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteFileTitleKey, TranslateConstants.AssayKey);
    const options: ConfirmationDialogData = {
      title: title,
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: translate(this.translateService, TranslateConstants.DeleteFileWarningKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.requestFileDeletion(title, [assayVersion.code]);
    }
  }

  public async requestLanguageFileDeletion(languageVersion: LanguageVersion): Promise<void> {
    const title = TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteFileTitleKey, TranslateConstants.LanguageKey);
    const options: ConfirmationDialogData = {
      title: title,
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.RemoveKey),
      message: translate(this.translateService, TranslateConstants.DeleteFileWarningKey)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.requestFileDeletion(title, null, [languageVersion.code]);
    }
  }

  private requestFileDeletion(translatedTitle: string, assayCodes?: string[], languageCodes?: string[]): void {
    const request: DeleteFilesRequest = {
      serialNumbers: [this.deviceInformation.serialNumber],
      assayCodes,
      languageCodes
    };

    this.subscription.add(this.c2dMessagesService.requestFileDeletion(request).subscribe((result: C2DMessageRequestResponse) => {
      C2DMessagesService.handleC2DRequestResponse(this.notificationService, true, result, translatedTitle);
    }));
  }

  private updateTabRelatedButtonVisibilities(): void {
    let label = 'unknown';
    const selectedTab = this.tabGroup._tabs.toArray()[this._selectedTabIndex];
    if (selectedTab) {
      label = selectedTab.textLabel;
    }

    this.showChangeGroupsButton = label === this.instrumentGroupsTabText;
    this.showLoadRecentUpgradeHistoryButton = label === this.recentUpgradeHistoryTabText;
  }

  private refreshCloudToDeviceMessagesData(): void {

    this.lutLastRequestedDateTime = undefined;
    this.logsLastRequestedDateTime = undefined;

    if (this.deviceInformation?.cloudToDeviceMessagesJsonByType) {
      const lutUploadC2DMessageMetadata = this.deviceInformation.cloudToDeviceMessagesJsonByType[C2DMessagesConstants.LutUpload];
      if (lutUploadC2DMessageMetadata) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.lutLastRequestedDateTime = JSON.parse(lutUploadC2DMessageMetadata).LastRequestedUtc as Date;
      }

      const logsUploadC2DMessageMetadata = this.deviceInformation.cloudToDeviceMessagesJsonByType[C2DMessagesConstants.LogsUpload];
      if (logsUploadC2DMessageMetadata) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.logsLastRequestedDateTime = JSON.parse(logsUploadC2DMessageMetadata).LastRequestedUtc as Date;
      }

    }
  }

  public showFileBrowser(): void {
    this.isFileBrowserShowing = true;
  }

  public closeFileBrowser(): void {
    this.isFileBrowserShowing = false;
  }

  public closeManualFolderSearch(): void {
    this.isManualFolderSearchShowing = false;
  }

  public closeManualRequestFiles(): void {
    this.isManualRequestFilesShowing = false;
  }

  private updateModelSelectionForInstrumentType() {
    this.modelsForSelectedInstrumentType = this.selectionAndCacheService.getModelsForInstrumentTypeId(this.deviceInformation.instrumentTypeId);

    // If only one model exists, auto select it
    if (this.modelsForSelectedInstrumentType.length === 1) {
      this.showModelSelection = false;
      this.deviceInformation.modelId = this.modelsForSelectedInstrumentType[0].modelId;
    } else {
      this.showModelSelection = true;
    }
    const temp = {};
    temp[this.modelIdPropertyName] = this.deviceInformation.modelId;
    this.editInstrumentForm.patchValue(temp);
  }

  private updateUIForSelectedInstrumentType() {
    if (this.deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {

      if (!this.deviceInformation.lanMACAddress) {
        this.deviceInformation.lanMACAddress = InstrumentDialogComponent.unknownMacAddressValue;
      }
      if (!this.deviceInformation.wifiMACAddress) {
        this.deviceInformation.wifiMACAddress = InstrumentDialogComponent.unknownMacAddressValue;
      }

      this.editInstrumentForm.get(InstrumentDialogComponent.lanMacAddressFormFieldName).disable();
      this.editInstrumentForm.get(InstrumentDialogComponent.wifiMacAddressFormFieldName).disable();
      this.firmwareVersionLabelKey = TranslateConstants.InstrumentsModVersionKey;
      this.requestedFirmwareLabelKey = TranslateConstants.InstrumentsRequestedModKey;
      this.firmwareTypeKey = TranslateConstants.ModKey;
      this.showSoftwareList = true;
    } else {
      // All Other Instrument Types
      this.editInstrumentForm.get(InstrumentDialogComponent.lanMacAddressFormFieldName).enable();
      this.editInstrumentForm.get(InstrumentDialogComponent.wifiMacAddressFormFieldName).enable();
      this.firmwareVersionLabelKey = TranslateConstants.InstrumentsFirmwareVersionKey;
      this.requestedFirmwareLabelKey = TranslateConstants.InstrumentsRequestedFirmwareKey;
      this.firmwareTypeKey = TranslateConstants.FirmwareKey;
      this.showSoftwareList = false;
    }
  }

  private addDownloadSettingsSoftwareSettings() {
    addNestedFormGroup(this.fb, this.editInstrumentForm, DeviceSettingsConstants.DownloadSettings_Software_FullFormKey, DeviceSettingsConstants.DownloadSettings_Software_Form_DefaultConfig);
  }

  private removeDownloadSettingsSoftwareSettings() {
    removeNestedFormControl(this.editInstrumentForm, DeviceSettingsConstants.DownloadSettings_Software_FullFormKey);
  }

  private ensureInstrumentTypeAndModelFieldsHaveCorrectEnabledState() {
    if (this._deviceInformation?.deviceInformationId > 0) {
      this.editInstrumentForm.get(this.modelIdPropertyName).disable();
    }
    this.editInstrumentForm.get(this.instrumentTypeIdPropertyName).disable();
  }

  public exportAllowlistFolderPaths(): void {
    const allowlistFolderPaths = this.editInstrumentForm.get(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath).value as string[];
    exportTextFile(allowlistFolderPaths.join('\n'));
  }

  public triggerUpdateAllowlistFolderPaths(): void {
    this.updateAllowlistFolderPathsFileInput.nativeElement.click();
  }

  public updateAllowlistFolderPathsWithDefaults(): void {
    if (!this.allowlistFolderPathsAdded) {
      this.addAllowlistFolderPaths();
    }

    const defaultSettings = this.populateDefaultSettingsByInstrumentType(DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FullFormKeyPath) as InstrumentDefaultSettingsAllowlistFolderPaths;
    if (defaultSettings) {
      this.setAllowlistFolderPaths(defaultSettings.allowlist_folderpaths);
    } else {
      this.notificationService.error(TranslateConstants.DefaultSettingNotFoundKey);
    }
  }

  public async updateAllowlistFolderPaths(files: File[]): Promise<void> {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];

    const newFolderPathsText = await file.text();

    this.updateAllowlistFolderPathsFileInput.nativeElement.value = null;

    const newFolderPaths = newFolderPathsText.split('\n');


    if (!this.allowlistFolderPathsAdded) {
      this.addAllowlistFolderPaths();
    }
    this.setAllowlistFolderPaths(newFolderPaths);
  }

  public getCountryName(country: Country): string {
    return country?.name ?? '';
  }

  private setAllowlistFolderPaths(allowlistFolderPaths: string[]) {
    const iotPatch = {};
    iotPatch[DeviceSettingsConstants.Settings_IoT_AllowlistFolderPaths_FormKey] = allowlistFolderPaths;
    const settingsPatch = {};
    settingsPatch[DeviceSettingsConstants.Settings_IoT_FormKey] = iotPatch;
    const rootPatch = {};
    rootPatch[DeviceSettingsConstants.Settings_FormKey] = settingsPatch;
    this.editInstrumentForm.patchValue(rootPatch);
  }

  private refreshRetrySettingsFormControl() {

    this.showRetrySettingsTimeDelayMsTimeFormat = false;
    let retrySettingsFormConfigOverrideKey: string = undefined;
    if (this.deviceInformation.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      const iotAgentEntry = this.deviceHelperService.getReportedSoftwareEntry(this.deviceInformation, 'IoT Agent');
      if (iotAgentEntry && compareVersion(iotAgentEntry.version, VersionConstants.V1_15, true) >= 0) {
        retrySettingsFormConfigOverrideKey = InstrumentTypeCode.Vision.valueOf();
        this.showRetrySettingsTimeDelayMsTimeFormat = true;
      }
    }
    const defaultFormConfig = DeviceSettingsConstants.getDefaultFormConfig(DeviceSettingsConstants.RetrySettings_FormKey, retrySettingsFormConfigOverrideKey);

    if (!this.editInstrumentForm.get(DeviceSettingsConstants.RetrySettings_FormKey)) {
      this.editInstrumentForm.addControl(DeviceSettingsConstants.RetrySettings_FormKey, this.fb.group(defaultFormConfig));
    } else {
      const timeDelayInMsFormConfig = defaultFormConfig[DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey] as (number | ValidatorFn[])[];
      if (timeDelayInMsFormConfig) {
        const validators = timeDelayInMsFormConfig.find(i => Array.isArray(i)) as ValidatorFn[];
        if (validators) {
          const timeDelayInMsControl = this.editInstrumentForm.get([DeviceSettingsConstants.RetrySettings_FormKey, DeviceSettingsConstants.RetrySettings_TimeDelayInMs_FormKey]);
          timeDelayInMsControl.setValidators(validators);
        }
      }
    }
  }

  public beginManualFolderSearch(): void {
    this.isManualFolderSearchShowing = true;
  }

  public beginManualFilesRequest(): void {
    this.isManualRequestFilesShowing = true;
  }

  public getStatusClassName(deviceInformation: DeviceInformation): string {
    return this.deviceHelperService.getDeviceInformationConnectionStatusClassName(deviceInformation);
  }

  public getStatusTooltip(deviceInformation: DeviceInformation): string {
    return this.deviceHelperService.getDeviceInformationConnectionStatusTooltip(deviceInformation);
  }

  public tryGetTranslateKey(keyParts: string[], overrideKey?: string): string {
    return DeviceSettingsConstants.tryGetTranslateKey(keyParts, overrideKey);
  }

  public getMinMaxValidationValuesFromEditForm(keyParts: string[]): { min: string, max: string, minValue: number, maxValue: number } {
    return getMinMaxValidationValues(keyParts, this.editInstrumentForm, this.numberPipe, this.minMaxValidationValuesByKeyParts);
  }
}

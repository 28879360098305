import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, firstValueFrom, forkJoin, of, take } from 'rxjs';
import { ClearUpgradeDialogComponent } from 'src/app/components/clear-upgrade-dialog/clear-upgrade-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService, GroupHelperService, NotificationService, UserPermissionService, UpgradeHelperService, DeviceHelperService, ConfigurationService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import {
  DeviceInformationResult, InstrumentType, InstrumentGroupsResult, Firmware, Assay, Language, AssayDefinition,
  LanguageDefinition, InstrumentGroupsRequest, DeviceDataRequest, SearchRequest, AccessLevel, UserPermission, ListMode,
  ConfirmationDialogData, CheckedDevicesEvent, CheckedGroupsEvent, ClearUpgradeDialogData, DeviceRequest, CancelMultipleUpgradeRequest, Features, ConfirmationDialogResponse, InstrumentTypeModel, InstrumentTypeId,
  formatVersion
} from 'src/app/models';
import { DecimalPipe } from '@angular/common';
import { filterInstrumentTypesByFeatureFlag, groupBy, isFeatureEnabledForInstrumentTypeName, nameof } from 'src/app/shared/utils';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { TranslateService } from '@ngx-translate/core';
import { DeviceListComponent } from 'src/app/components/device-list/device-list.component';
import { InstrumentGroupListComponent, InstrumentGroupListRequest } from 'src/app/components/instrument-group-list/instrument-group-list.component';

@Component({
  selector: 'app-upgrade-instruments',
  templateUrl: './upgrade-instruments.component.html',
  styleUrls: ['./upgrade-instruments.component.scss']
})
export class UpgradeInstrumentsComponent implements OnInit, OnDestroy {
  @ViewChild(DeviceListComponent) deviceListComponent: DeviceListComponent;
  @ViewChild(InstrumentGroupListComponent) instrumentGroupListComponent: InstrumentGroupListComponent;

  public deviceInformationResult: DeviceInformationResult;
  public instrumentGroupsResult: InstrumentGroupsResult;
  public firmwares: Firmware[];
  public assays: Assay[];
  public languages: Language[];
  public selectedTabIndex = 0;
  public ListMode = ListMode;
  public checkedGroupsEvent: CheckedGroupsEvent;
  public checkedDevicesEvent: CheckedDevicesEvent;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  public showAssays = true;
  public showLanguages = true;
  public enableMultiSelect = false;

  public titleTranslateKey: string = TranslateConstants.UpgradeUpgradeInstrumentsLabelKey;
  public upgradeFirmwareTranslateKey: string = TranslateConstants.UpgradeFirmwareLabelKey;
  public firmwaresLabelTranslateKey: string = TranslateConstants.UpgradeFirmwaresLabelKey;
  public upgradeButtonTranslateKey: string = TranslateConstants.UpgradeKey;
  public clearUpgradesButtonTranslateKey: string = TranslateConstants.UpgradeClearUpgradesLabelKey;
  public upgradeInstrumentsConfirmationTitleTranslateKey: string = TranslateConstants.UpgradeInstrumentsTitleKey;
  public upgradeInstrumentsConfirmationMessageTranslateKey: string = TranslateConstants.UpgradeInstrumentsMessageKey;
  public upgradeInstrumentGroupsConfirmationTitleTranslateKey: string = TranslateConstants.UpgradeInstrumentGroupsTitleKey;
  public upgradeInstrumentGroupsConfirmationMessageTranslateKey: string = TranslateConstants.UpgradeInstrumentGroupsMessageKey;

  private subscription: Subscription = new Subscription();
  public isSysAdmin = false;
  private allFirmwares: Firmware[];
  private allAssays: Assay[];
  private allLanguages: Language[];
  private assayDefinitions: AssayDefinition[];
  private languageDefinitions: LanguageDefinition[];
  private currentInstrumentGroupRequest: InstrumentGroupListRequest;
  private currentDeviceDataRequested: DeviceDataRequest;
  private instrumentTypes: InstrumentType[] = [];
  private instrumentTypes_FirmwaresEnabled: InstrumentType[] = [];
  private instrumentTypes_AssaysEnabled: InstrumentType[] = [];
  private instrumentTypes_LanguagesEnabled: InstrumentType[] = [];
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private selectedModels: InstrumentTypeModel[];
  private FIRMWARE_ID: number;
  private ASSAY_IDS: number[];
  private LANGUAGE_IDS: number[];
  private disableUnregisteredExactMatch = true;
  private features: Features;

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.refreshFeatureFlagsBySelectedInstrumentType();
      this.updateFirmwaresCollection();
      this.updateAssaysCollection();
      this.updateLanguagesCollection();

      this.firmwareId = undefined;
      this.assayIds = [];
      this.languageIds = [];
      // First set these components aren't set set. Ok not to uncheck them all
      this.deviceListComponent?.uncheckAll();
      this.instrumentGroupListComponent?.uncheckAll();
    }
  }

  get firmwareId(): number {
    return this.FIRMWARE_ID;
  }

  set firmwareId(id: number) {
    if (this.FIRMWARE_ID !== id) {
      this.FIRMWARE_ID = id;
      this.assayIds = [];
      this.languageIds = [];
      this.getFilteredDataByFirmwareId();
    }
  }

  get assayIds(): number[] {
    return this.ASSAY_IDS;
  }

  set assayIds(ids: number[]) {
    if (this.ASSAY_IDS !== ids) {
      this.ASSAY_IDS = ids;
    }
  }

  get languageIds(): number[] {
    return this.LANGUAGE_IDS;
  }

  set languageIds(ids: number[]) {
    if (this.LANGUAGE_IDS !== ids) {
      this.LANGUAGE_IDS = ids;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get numberOfDevicesMessage(): string {
    let numberOfDevices = 0;

    if (this.checkedDevicesEvent) {
      numberOfDevices = !this.checkedDevicesEvent.allPages ? this.checkedDevicesEvent.checkedDevices.length : this.deviceInformationResult.totalResults;
    }

    return translateWithInterpolateParams(this.translateService, TranslateConstants.NumberOfInstrumentsLabelKey, { deviceCount: this.decimalPipe.transform(numberOfDevices) });
  }

  get numberOfGroupsMessage(): string {
    let numberOfGroups = 0;

    if (this.checkedGroupsEvent) {
      numberOfGroups = !this.checkedGroupsEvent.allPages ? this.checkedGroupsEvent.checkedGroups.length : this.instrumentGroupsResult.totalResults;
    }
    return translateWithInterpolateParams(this.translateService, TranslateConstants.NumberOfInstrumentGroupsLabelKey, { groupCount: this.decimalPipe.transform(numberOfGroups) });
  }

  get firmwareMessage(): string {
    if (this.firmwareId) {
      const firmware = this.firmwares.find(x => x.firmwareId === this.firmwareId);
      return translateWithInterpolateParams(this.translateService, this.upgradeFirmwareTranslateKey, { title: firmware.title, version: formatVersion(firmware.version) });
    } else {
      return '';
    }
  }

  get languagesMessage(): string {
    if (this.languageIds?.length > 0) {
      const delimitedTitles = this.languages.filter(x => this.languageIds.some(id => id === x.languageId)).map(x => x.title).join(', ');
      return translateWithInterpolateParams(this.translateService, TranslateConstants.UpgradeLanguagesLabelKey, { delimitedTitles: delimitedTitles });
    } else {
      return '';
    }
  }

  get assaysMessage(): string {
    if (this.assayIds?.length > 0) {
      const delimitedTitles = this.assays.filter(x => this.assayIds.some(id => id === x.assayId)).map(x => x.title).join(', ');
      return translateWithInterpolateParams(this.translateService, TranslateConstants.UpgradeAssaysLabelKey, { delimitedTitles: delimitedTitles });
    } else {
      return '';
    }
  }

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private deviceHelperService: DeviceHelperService,
    private groupHelperService: GroupHelperService,
    private upgradeHelperService: UpgradeHelperService,
    private configurationService: ConfigurationService,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.isSysAdmin = this.userPermissionService.getAccessLevel([UserPermission.SysAdmin]) === AccessLevel.AddUpdate;

      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.selectedInstrumentType = selectionState.instrumentType;
        // Changing the InstrumentType causes the DeviceListComponent to request data
        this.selectedModels = selectionState.models;

        if (this.selectedInstrumentType) {
          this.getInstrumentGroupsWithCurrentSearchState();
          this.updateUIForSelectedInstrumentType();

          this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument], [UserPermission.ViewEverything], this.selectedInstrumentType.instrumentTypeId);
          this.enableMultiSelect = this.userPermissionService.getAccessLevel([UserPermission.UpdateAllInstruments], [UserPermission.ViewEverything], this.selectedInstrumentType.instrumentTypeId) === AccessLevel.AddUpdate;
        }
      }));
    }));



    this.getAllData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public hasDevices(): boolean {
    if (this.selectedTabIndex === 1) {
      return false;
    } else if (!this.checkedDevicesEvent?.allPages) {
      return this.checkedDevicesEvent?.checkedDevices.length > 0;
    } else {
      return true;
    }
  }

  public hasGroups(): boolean {
    if (this.selectedTabIndex === 0) {
      return false;
    } else if (!this.checkedGroupsEvent?.allPages) {
      return this.checkedGroupsEvent?.checkedGroups.length > 0;
    } else {
      return true;
    }
  }

  public hasDesiredProperty(): boolean {
    if (((!this.languageIds || this.languageIds.length <= 0) && (!this.assayIds || this.assayIds.length <= 0) && !this.firmwareId)) {
      return false;
    }

    return true;
  }

  public onCheckedDevices($event: CheckedDevicesEvent): void {
    this.checkedDevicesEvent = $event;
  }

  public async upgradeDevices(): Promise<void> {
    if (this.selectedTabIndex !== 0) {
      return;
    }


    const options: ConfirmationDialogData = {
      title: translate(this.translateService, this.upgradeInstrumentsConfirmationTitleTranslateKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, this.upgradeButtonTranslateKey),
      message: translate(this.translateService, this.upgradeInstrumentsConfirmationMessageTranslateKey) + this.numberOfDevicesMessage + this.firmwareMessage + this.languagesMessage + this.assaysMessage,
      allowAMFForceDowngrade: this.shouldAllowAMFForceDowngrade()
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const deviceDataRequested = this.checkedDevicesEvent.allPages
        ? this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch)
        : undefined;
      this.subscription.add(this.dataService.upgradeDevice({
        serialNumbers: !this.checkedDevicesEvent.allPages ? this.checkedDevicesEvent.checkedDevices.map(x => x.serialNumber) : null,
        firmwareId: this.firmwareId,
        assayIds: this.assayIds,
        languageIds: this.languageIds,
        forceAssayDowngrade: response.forceAMFDowngrade
      }, deviceDataRequested)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.UpgradeSuccessKey);
              this.getDevicesWithCurrentSearchState();
            },
            error: () => {
              this.notificationService.error(TranslateConstants.UpgradeFailedKey);
            }
          }));
    }
  }

  public async upgradeGroups(): Promise<void> {
    if (this.selectedTabIndex !== 1) {
      return;
    }

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, this.upgradeInstrumentGroupsConfirmationTitleTranslateKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, this.upgradeButtonTranslateKey),
      message: translate(this.translateService, this.upgradeInstrumentGroupsConfirmationMessageTranslateKey) + this.numberOfGroupsMessage + this.firmwareMessage + this.languagesMessage + this.assaysMessage,
      allowAMFForceDowngrade: this.shouldAllowAMFForceDowngrade()
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const instrumentGroups = this.checkedGroupsEvent.checkedGroups.map(x => x.instrumentGroupId);
      this.subscription.add(this.dataService.upgradeDevice({
        instrumentGroupIds: !this.checkedGroupsEvent?.allPages ? instrumentGroups : null,
        firmwareId: this.firmwareId,
        assayIds: this.assayIds,
        languageIds: this.languageIds,
        forceAssayDowngrade: response.forceAMFDowngrade
      }, undefined, this.checkedGroupsEvent?.allPages
        ? this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId)
        : undefined)
        .subscribe(
          {
            next: (response: string) => {
              this.notificationService.success(TranslateConstants.UpgradeSuccessKey);
              if (response) {
                this.notificationService.successExact(response);
              }
              this.getDevicesWithCurrentSearchState();
            }, error: () => {
              this.notificationService.error(TranslateConstants.UpgradeFailedKey);
            }
          }));
    }
  }

  public async cancelUpgrades(): Promise<void> {
    let searchRequest: SearchRequest;
    let groupSearchRequest: InstrumentGroupsRequest;
    const serialNumbers: string[] = [];
    const instrumentGroupIds: number[] = [];
    const numbersMessage = this.selectedTabIndex === 1 ? this.numberOfGroupsMessage : this.numberOfDevicesMessage;

    const options: ClearUpgradeDialogData = {
      message: numbersMessage + this.firmwareMessage + this.languagesMessage + this.assaysMessage
    };

    if (this.selectedTabIndex === 0) {
      searchRequest = this.checkedDevicesEvent?.allPages
        ? this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch)
        : null;
      this.checkedDevicesEvent?.checkedDevices.forEach(device => {
        serialNumbers.push(device.serialNumber);
      });
    } else if (this.selectedTabIndex === 1) {
      groupSearchRequest = this.checkedGroupsEvent?.allPages ? this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId) : null;
      this.checkedGroupsEvent?.checkedGroups.forEach(group => {
        instrumentGroupIds.push(group.instrumentGroupId);
      });
    }

    const cancelRequest: CancelMultipleUpgradeRequest = {
      instrumentTypeId: this.selectedInstrumentType.instrumentTypeId,
      firmwareVersion: this.firmwareId ? this.firmwares.find(x => x.firmwareId === this.firmwareId).version : null,
      assays: this.upgradeHelperService.getCancelableAssayVersions(this.assays, this.assayDefinitions, this.assayIds),
      languages: this.upgradeHelperService.getCancelableLanguageVersions(this.languages, this.languageDefinitions, this.languageIds),
      queryByInstrumentSearchRequest: searchRequest,
      queryByInstrumentGroupSearchRequest: groupSearchRequest
    };

    if (!this.checkedDevicesEvent?.allPages) {
      cancelRequest.serialNumbers = serialNumbers;
    }

    if (!this.checkedGroupsEvent?.allPages) {
      cancelRequest.instrumentGroupIds = instrumentGroupIds;
    }

    const dialogRef = this.dialog.open<ClearUpgradeDialogComponent, ClearUpgradeDialogData, boolean>(ClearUpgradeDialogComponent, {
      data: options
    });

    const confirmed: boolean = await firstValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      this.subscription.add(this.dataService.cancelUpgradeRequests(cancelRequest).subscribe(() => {
        this.notificationService.success(TranslateConstants.ClearUpgradesSuccessKey);
        this.getDevicesWithCurrentSearchState();
      }));
    }
  }

  public async forceDeviceTwinRefreshOnInstruments(): Promise<void> {

    if (this.selectedTabIndex !== 0) {
      return;
    }

    if (!this.hasDevices()) {
      return;
    }

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ForceDeviceTwinRefreshTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.ForceRefreshKey),
      message: translateWithInterpolateParams(this.translateService, TranslateConstants.ForceDeviceTwinRefreshWarningKey, { identifier: this.numberOfDevicesMessage })
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const deviceRequest: DeviceRequest = {};

      if (!this.checkedDevicesEvent.allPages) {
        deviceRequest.serialNumbers = this.checkedDevicesEvent.checkedDevices.map(x => x.serialNumber);
      }
      else {
        deviceRequest.queryByInstrumentSearchRequest = this.deviceHelperService.getDeviceSearchRequest(
          this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch);
      }

      this.subscription.add(this.dataService.forceDeviceTwinRefresh(deviceRequest)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.ForceDeviceTwinRefreshSuccessKey);
              this.getDevicesWithCurrentSearchState();
            }, error: () => {
              this.notificationService.error(TranslateConstants.ForceDeviceTwinRefreshFailedKey);
            }
          }));
    }
  }

  public async forceDeviceTwinRefreshOnInstrumentGroups(): Promise<void> {

    if (this.selectedTabIndex !== 1) {
      return;
    }

    if (!this.hasGroups()) {
      return;
    }

    const options: ConfirmationDialogData = {
      title: translate(this.translateService, TranslateConstants.ForceDeviceTwinRefreshTitleKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.ForceRefreshKey),
      message: translateWithInterpolateParams(this.translateService, TranslateConstants.ForceDeviceTwinRefreshWarningKey, { identifier: this.numberOfGroupsMessage })
    };
    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const deviceRequest: DeviceRequest = {};

      if (!this.checkedGroupsEvent.allPages) {
        deviceRequest.instrumentGroupIds =
          this.checkedGroupsEvent.checkedGroups.map(x => x.instrumentGroupId);
      }
      else {
        deviceRequest.queryByInstrumentGroupSearchRequest =
          this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId);
      }

      this.subscription.add(this.dataService.forceDeviceTwinRefresh(deviceRequest)
        .subscribe(
          {
            complete: () => {
              this.notificationService.success(TranslateConstants.ForceDeviceTwinRefreshSuccessKey);
              this.getDevicesWithCurrentSearchState();
            },
            error: () => {
              this.notificationService.error(TranslateConstants.ForceDeviceTwinRefreshFailedKey);
            }
          }
        ));
    }
  }

  public instrumentGroupDataRequested($event: InstrumentGroupListRequest): void {
    this.currentInstrumentGroupRequest = $event;
    this.getInstrumentGroupsWithCurrentSearchState();
  }

  public deviceDataRequested($event: DeviceDataRequest): void {
    this.currentDeviceDataRequested = $event;
    this.getDevicesWithCurrentSearchState();
  }

  public onCheckedGroups($event: CheckedGroupsEvent): void {
    this.checkedGroupsEvent = $event;
  }

  public getInstrumentGroupsWithCurrentSearchState(): void {
    const searchRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId);

    this.subscription.add(
      this.dataService.getInstrumentGroups(searchRequest)
        .subscribe((instrumentGroupsResult: InstrumentGroupsResult) => {
          this.instrumentGroupsResult = instrumentGroupsResult;
        }));
  }

  private updateUIForSelectedInstrumentType() {

    if (this.selectedInstrumentType && this.selectedInstrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.firmwaresLabelTranslateKey = TranslateConstants.UpgradeModsLabelKey;
      this.upgradeFirmwareTranslateKey = TranslateConstants.UpgradeModLabelKey;
      this.titleTranslateKey = TranslateConstants.UpgradeDeploySoftwareLabelKey;
      this.upgradeButtonTranslateKey = TranslateConstants.DeployKey;
      this.clearUpgradesButtonTranslateKey = TranslateConstants.UpgradeClearDeploymentsLabelKey;
      this.upgradeInstrumentsConfirmationTitleTranslateKey = TranslateConstants.DeploySoftwareTitleKey;
      this.upgradeInstrumentsConfirmationMessageTranslateKey = TranslateConstants.DeploySoftwareMessageKey;
      this.upgradeInstrumentGroupsConfirmationTitleTranslateKey = TranslateConstants.DeploySoftwareTitleKey;
      this.upgradeInstrumentGroupsConfirmationMessageTranslateKey = TranslateConstants.DeploySoftwareToInstrumentGroupsMessageKey;
    } else {
      // All Other Instrument Types
      this.firmwaresLabelTranslateKey = TranslateConstants.UpgradeFirmwaresLabelKey;
      this.upgradeFirmwareTranslateKey = TranslateConstants.UpgradeFirmwareLabelKey;
      this.titleTranslateKey = TranslateConstants.UpgradeUpgradeInstrumentsLabelKey;
      this.upgradeButtonTranslateKey = TranslateConstants.UpgradeKey;
      this.clearUpgradesButtonTranslateKey = TranslateConstants.UpgradeClearUpgradesLabelKey;
      this.upgradeInstrumentsConfirmationTitleTranslateKey = TranslateConstants.UpgradeInstrumentsTitleKey;
      this.upgradeInstrumentsConfirmationMessageTranslateKey = TranslateConstants.UpgradeInstrumentsMessageKey;
      this.upgradeInstrumentGroupsConfirmationTitleTranslateKey = TranslateConstants.UpgradeInstrumentGroupsTitleKey;
      this.upgradeInstrumentGroupsConfirmationMessageTranslateKey = TranslateConstants.UpgradeInstrumentGroupsMessageKey;
    }
  }

  private getDevicesWithCurrentSearchState(): void {
    const searchRequest: SearchRequest = this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch);

    this.subscription.add(
      this.dataService.getDeviceInformation(searchRequest)
        .subscribe((results) => {
          this.deviceInformationResult = results;
        }));
  }

  private getAllData(): void {
    this.subscription.add(
      forkJoin([
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.dataService.getFirmwares(undefined, undefined, undefined, true),
        this.dataService.getAssays(undefined, undefined, undefined, true),
        this.dataService.getLanguages(undefined, undefined, undefined, true),
        this.dataService.getAssayDefinitions(),
        this.dataService.getLanguageDefinitions(),
      ]).subscribe((results: [boolean, Firmware[], Assay[], Language[], AssayDefinition[], LanguageDefinition[]]) => {
        this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
        this.allFirmwares = this.firmwares = results[1];
        this.allAssays = this.assays = results[2];
        this.allLanguages = this.languages = results[3];
        this.assayDefinitions = results[4];
        this.languageDefinitions = results[5];

        this.features = this.configurationService.getClientConfiguration()?.features;

        this.instrumentTypes = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.UpgradeInstrumentSuffix);
        this.instrumentTypes_FirmwaresEnabled = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.FWManagementSuffix);
        this.instrumentTypes_AssaysEnabled = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.AMFManagementSuffix);
        this.instrumentTypes_LanguagesEnabled = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.LanguageManagementSuffix);

        this.filterFirmwaresAssaysLanguagesByEnabledInstrumentTypes();
        this.refreshFeatureFlagsBySelectedInstrumentType();
      }));
  }

  private getFilteredDataByFirmwareId(): void {
    if (this.firmwareId) {
      this.subscription.add(this.dataService.getFirmwareById(this.firmwareId).subscribe((selectedFirmware) => {
        this.subscription.add(
          forkJoin([
            of(selectedFirmware),
            this.showAssays // only attempt to retrieve available languages if showing languages
              ? this.dataService.getFirmwareAvailableAssays(selectedFirmware.instrumentFileTypeId, selectedFirmware.instrumentTypeId, selectedFirmware.assayInterfaceIds, true)
              : of([]),
            this.showLanguages // only attempt to retrieve available languages if showing languages
              ? this.dataService.getFirmwareAvailableLanguages(selectedFirmware.instrumentFileTypeId, selectedFirmware.instrumentTypeId, selectedFirmware.languageInterfaceIds, true)
              : of([])
          ]).subscribe((results: [Firmware, Assay[], Language[]]) => {
            const selectedFirmware = results[0];
            // filter down the assays and languages to only items marked as compatible with the selected FW
            this.allAssays = results[1].filter(assay => selectedFirmware.assayIds ? selectedFirmware.assayIds.includes(assay.assayId) : false);
            this.allLanguages = results[2].filter(language => selectedFirmware.languageIds ? selectedFirmware.languageIds.includes(language.languageId) : false);
            this.filterFirmwaresAssaysLanguagesByEnabledInstrumentTypes();
          }));
      }));
    } else {
      forkJoin([
        this.dataService.getAssays(undefined, undefined, undefined, true),
        this.dataService.getLanguages(undefined, undefined, undefined, true),
      ]).subscribe((results: [Assay[], Language[]]) => {
        this.allAssays = results[0];
        this.allLanguages = results[1];
        this.filterFirmwaresAssaysLanguagesByEnabledInstrumentTypes();
      });
    }
  }

  private filterFirmwaresAssaysLanguagesByEnabledInstrumentTypes(): void {
    const filteredAssays: Assay[] = [];
    const filteredFirmwares: Firmware[] = [];
    const filteredLanguages: Language[] = [];

    const groupedFirmwares = groupBy<Firmware, number>(this.allFirmwares, nameof<Firmware>('instrumentTypeId'));
    groupedFirmwares.forEach((values, key) => {
      if (this.instrumentTypes_FirmwaresEnabled.find(i => i.instrumentTypeId === key)) {
        filteredFirmwares.push(...values);
      }
    });
    this.allFirmwares = filteredFirmwares;

    const groupedAssays = groupBy<Assay, number>(this.allAssays, nameof<Assay>('instrumentTypeId'));
    groupedAssays.forEach((values, key) => {
      if (this.instrumentTypes_AssaysEnabled.find(i => i.instrumentTypeId === key)) {
        filteredAssays.push(...values);
      }
    });
    this.allAssays = filteredAssays;

    const groupedLanguages = groupBy<Language, number>(this.allLanguages, nameof<Language>('instrumentTypeId'));
    groupedLanguages.forEach((values, key) => {
      if (this.instrumentTypes_LanguagesEnabled.find(i => i.instrumentTypeId === key)) {
        filteredLanguages.push(...values);
      }
    });
    this.allLanguages = filteredLanguages;

    this.updateFirmwaresCollection();
    this.updateAssaysCollection();
    this.updateLanguagesCollection();
  }

  public getAssayDefinitionCode(assayDefinitionId: number): string {
    return this.upgradeHelperService.getAssayDefinitionCode(this.assayDefinitions, assayDefinitionId);
  }

  public getLanguageDefinitionCode(languageDefinitionId: number): string {
    return this.upgradeHelperService.getLanguageDefinitionCode(this.languageDefinitions, languageDefinitionId);
  }

  private updateFirmwaresCollection() {
    if (this.selectedInstrumentType) {
      this.firmwares = this.allFirmwares?.filter(x => x.instrumentTypeId === this.SELECTED_INSTRUMENT_TYPE.instrumentTypeId);
    } else {
      this.firmwares = this.allFirmwares;
    }
  }

  private updateAssaysCollection() {

    if (this.selectedInstrumentType) {
      this.assays = this.allAssays?.filter(a => a.instrumentTypeId === this.SELECTED_INSTRUMENT_TYPE.instrumentTypeId);
    } else {
      this.assays = this.allAssays;
    }
  }

  private updateLanguagesCollection() {

    if (this.selectedInstrumentType) {
      this.languages = this.allLanguages?.filter(l => l.instrumentTypeId === this.SELECTED_INSTRUMENT_TYPE.instrumentTypeId);
    } else {
      this.languages = this.allLanguages;
    }
  }

  private shouldAllowAMFForceDowngrade(): boolean {
    // Is Sys Admin or Tech Support, at least 1 Assay is selected, and no FW or Languages are selected
    return this.accessLevel === AccessLevel.AddUpdate
      && isFeatureEnabledForInstrumentTypeName(this.features, this.SELECTED_INSTRUMENT_TYPE.name, FeatureFlagConstants.AMFAllowForceDowngrade)
      && this.assayIds.length > 0
      && !this.firmwareId
      && this.languageIds.length === 0;
  }

  private refreshFeatureFlagsBySelectedInstrumentType() {
    if (this.selectedInstrumentType) {
      this.showAssays = isFeatureEnabledForInstrumentTypeName(this.features, this.selectedInstrumentType.name, FeatureFlagConstants.AMFManagementSuffix);
      this.showLanguages = isFeatureEnabledForInstrumentTypeName(this.features, this.selectedInstrumentType.name, FeatureFlagConstants.LanguageManagementSuffix);
    } else {
      this.showAssays = false;
      this.showLanguages = false;
    }
  }
}

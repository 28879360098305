<div class="page">
  <div class="page-grid">
    <h1>{{ titleTranslateKey | translate }}</h1>

    <div *ngIf="selectedInstrumentType" class="filter-section">
      <div class="field-container">
        <div class="selection-list-container">
          <div class="filter" [class.section]="showAssays || showLanguages">
            <mat-label class="field-label">
              {{ firmwaresLabelTranslateKey | translate }}
            </mat-label>
            <mat-radio-group [disabled]="!canAddUpdate" class="radio-group" [(ngModel)]="firmwareId">
              <mat-radio-button labelPosition="after" class="radio-button" [value]="undefined">
                <div class="properties-grid">
                  <div>{{ 'Global.None' | translate }}</div>
                </div>
              </mat-radio-button>
              <mat-radio-button labelPosition="after" class="radio-button" *ngFor="let firmware of firmwares"
                [value]="firmware.firmwareId">
                <div class="properties-grid">
                  <div>
                    {{firmware.title}}
                  </div>
                  <div>
                    {{ 'UpgradeInstruments.Version' | translate }}
                    {{firmware.version.major}}.{{firmware.version.minor}}.{{firmware.version.revision}}
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="showAssays" class="section filter">
            <mat-label class="field-label">
              {{ 'UpgradeInstruments.Assays' | translate }}</mat-label>
            <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
              (change)="$event.checked ? assayIds = [] : null" [checked]="!assayIds || assayIds?.length === 0">
              <div class="properties-grid">
                <div>{{ 'Global.None' | translate }}</div>
              </div>
            </mat-checkbox>
            <mat-selection-list [disabled]="!canAddUpdate" class="compatible-list condensed" [(ngModel)]="assayIds">
              <mat-list-option checkboxPosition="before" *ngFor="let assay of assays" [value]="assay.assayId"
                (change)="$event.checked ? assayIds = [assay.assayId] : null">
                <div>
                  <div class="properties-grid-2">
                    <div>
                      {{ getAssayDefinitionCode(assay.assayDefinitionId)}}
                    </div>
                    <div>
                      {{assay.title}}
                    </div>
                    <div>
                      {{ 'UpgradeInstruments.Int' | translate }} {{assay.interfaceVersion}}
                    </div>
                    <div>
                      {{ 'UpgradeInstruments.Ver' | translate }}
                      {{assay.version.major}}.{{assay.version.minor}}.{{assay.version.revision}}
                    </div>
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div *ngIf="showLanguages" class="filter">
            <mat-label class="field-label">
              {{ 'UpgradeInstruments.Languages' | translate }}</mat-label>
            <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
              (change)="$event.checked ? languageIds = [] : null" [checked]="!languageIds || languageIds?.length === 0">
              <div class="properties-grid">
                <div>{{ 'Global.None' | translate }}</div>
              </div>
            </mat-checkbox>
            <mat-selection-list [disabled]="!canAddUpdate" class="compatible-list condensed" [(ngModel)]="languageIds">
              <mat-list-option checkboxPosition="before" *ngFor="let language of languages"
                [value]="language.languageId" (change)="$event.checked ? languageIds = [language.languageId] : null">
                <div>
                  <div class="properties-grid-2">
                    <div>
                      {{ getLanguageDefinitionCode(language.languageDefinitionId)}}
                    </div>
                    <div>
                      {{language.title}}
                    </div>
                    <div>
                      {{ 'UpgradeInstruments.Int' | translate }} {{language.interfaceVersion}}
                    </div>
                    <div>
                      {{ 'UpgradeInstruments.Ver' | translate }}
                      {{language.version.major}}.{{language.version.minor}}.{{language.version.revision}}
                    </div>
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <mat-tab-group #tabs *ngIf="selectedInstrumentType" [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
      <mat-tab [label]="'UpgradeInstruments.Instruments' | translate">
        <app-device-list class="device-list" [getDeviceInformationResult]="deviceInformationResult"
          [instrumentTypeId]="selectedInstrumentType?.instrumentTypeId"
          [showSelectAllPagesMultiselect]="enableMultiSelect" (checkedDevices)="onCheckedDevices($event)"
          (deviceDataRequested)="deviceDataRequested($event)"
          [mode]="enableMultiSelect ? ListMode.Multiselect : ListMode.Singleselect" [accessLevel]="accessLevel"
          [showViewInstrumentLink]="true"></app-device-list>
      </mat-tab>
      <mat-tab *ngIf="selectedInstrumentType && enableMultiSelect"
        [label]="'UpgradeInstruments.InstrumentGroups' | translate">
        <app-instrument-group-list class="group-list" [instrumentGroupsResult]="instrumentGroupsResult"
          [showSelectAllPagesMultiselect]="enableMultiSelect"
          [mode]="enableMultiSelect ? ListMode.Multiselect : ListMode.All" [accessLevel]="accessLevel"
          (dataRequested)="instrumentGroupDataRequested($event)" (checkedGroups)="onCheckedGroups($event)"
          (refreshData)="getInstrumentGroupsWithCurrentSearchState()">
        </app-instrument-group-list>
      </mat-tab>
    </mat-tab-group>
    <div class="action-bar" *ngIf="selectedInstrumentType">
      <div class="action-bar-left">
        <button mat-raised-button color="primary" *ngIf="canAddUpdate && isSysAdmin && selectedTabIndex === 0"
          (click)="forceDeviceTwinRefreshOnInstruments()" [disabled]="!hasDevices()">
          <mat-icon>refresh</mat-icon>{{ 'UpgradeInstruments.ForceDeviceTwinRefresh' | translate }}
        </button>

        <button mat-raised-button color="primary" *ngIf="canAddUpdate && isSysAdmin && selectedTabIndex === 1"
          (click)="forceDeviceTwinRefreshOnInstrumentGroups()" [disabled]="!hasGroups()">
          <mat-icon>refresh</mat-icon>{{ 'UpgradeInstruments.ForceDeviceTwinRefresh' | translate }}
        </button>

        <button *ngIf="isSysAdmin && selectedTabIndex === 0" mat-raised-button color="warn"
          [disabled]="!hasDevices() || !hasDesiredProperty()" (click)="cancelUpgrades()">
          <mat-icon>clear</mat-icon>{{ clearUpgradesButtonTranslateKey | translate }}
        </button>

        <button *ngIf="isSysAdmin && selectedTabIndex === 1" mat-raised-button color="warn"
          [disabled]="!hasGroups() || !hasDesiredProperty()" (click)="cancelUpgrades()">
          <mat-icon>clear</mat-icon>{{ clearUpgradesButtonTranslateKey | translate }}
        </button>
      </div>

      <div class="action-bar-right">
        <button mat-raised-button color="primary" (click)="upgradeDevices()"
          *ngIf="canAddUpdate && selectedTabIndex === 0" [disabled]="(!hasDevices()) || !hasDesiredProperty()">
          <mat-icon>upgrade</mat-icon>{{ upgradeButtonTranslateKey | translate }}
        </button>

        <button mat-raised-button color="primary" (click)="upgradeGroups()"
          *ngIf="canAddUpdate && selectedTabIndex === 1" [disabled]="(!hasGroups()) || !hasDesiredProperty()">
          <mat-icon>upgrade</mat-icon>{{ upgradeButtonTranslateKey | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DataService, InstrumentTypeSelectionState, NotificationService, SelectionAndCacheService, UserPermissionService } from 'src/app/services';
import { AccessLevel, LanguageDefinition, UserPermission, LanguageDefinitionTemplate, ConfirmationDialogData, ConfirmationDialogResponse } from 'src/app/models';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-language-definitions',
  templateUrl: './language-definitions.component.html',
  styleUrls: ['./language-definitions.component.scss']
})
export class LanguageDefinitionsComponent implements OnInit, AfterViewInit, OnDestroy {

  public displayedColumns: string[] = ['code', 'definitionName', 'actions'];
  public definitions: LanguageDefinition[] = [];
  public dataSource: MatTableDataSource<LanguageDefinition> = new MatTableDataSource<LanguageDefinition>();

  private subscription: Subscription = new Subscription();
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  @ViewChild(MatSort) private sort: MatSort;

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe( _ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageLanguageFiles], null, selectionState.instrumentType?.instrumentTypeId);
      }));
    }));

    this.loadDefinitions();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public async onAddDefinition(template: TemplateRef<unknown>): Promise<void> {
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, LanguageDefinitionTemplate | 'false'>(template, { width: '75%' });

    const result = await firstValueFrom<LanguageDefinitionTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const definition: LanguageDefinition = {
        languageDefinitionId: 0,
        code: result.code,
        definitionName: result.name,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null
      };

      this.subscription.add(this.dataService.addLanguageDefinition(definition).subscribe(id => {
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.LanguageDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }

  public loadDefinitions(): void {
    this.subscription.add(this.dataService.getLanguageDefinitions().subscribe((definitions: LanguageDefinition[]) => {
      this.definitions = definitions;
      this.dataSource.data = this.definitions;
    }));
  }

  public async onEditDefinition(template: TemplateRef<unknown>, definition: LanguageDefinition): Promise<void> {
    const dialogRef = this.dialog.open<unknown, LanguageDefinition, LanguageDefinitionTemplate | 'false'>(template, { data: definition, width: '75%' });

    const result = await firstValueFrom<LanguageDefinitionTemplate | 'false'>(dialogRef.afterClosed());

    if (result && result !== 'false') {
      definition.code = result.code;
      definition.definitionName = result.name;

      this.subscription.add(this.dataService.updateLanguageDefinition(definition).subscribe(id => {
        this.notificationService.success(TranslateConstants.UpdateSuccessKey, { type: TranslateConstants.LanguageDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }

  public async onDeleteDefinition(definition: LanguageDefinition): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.LanguageDefinitionKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.LanguageDefinitionKey, definition.definitionName)
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteLanguageDefinition(definition.languageDefinitionId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.LanguageDefinitionKey });
        this.loadDefinitions();
      }));
    }
  }

}

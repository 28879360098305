import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, firstValueFrom, forkJoin, take } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GroupHelperService, UserPermissionService, DeviceHelperService, ConfigurationService, NotificationService, C2DMessagesService, DataService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import {
  DeviceInformationResult, InstrumentType, InstrumentGroupsResult, AssayDefinition,
  LanguageDefinition, InstrumentGroupsRequest, DeviceDataRequest, SearchRequest, AccessLevel, UserPermission, ListMode,
  ConfirmationDialogData, CheckedDevicesEvent, CheckedGroupsEvent, Features, ConfirmationDialogResponse, DeleteFilesRequest, C2DMessageRequestResponse, prepareDeviceSearchRequest, prepareSearchGroupRequest, InstrumentTypeModel,
} from 'src/app/models';
import { DecimalPipe } from '@angular/common';
import { filterInstrumentTypesByFeatureFlag, isFeatureEnabledForInstrumentTypeName } from 'src/app/shared/utils';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';
import { TranslateService } from '@ngx-translate/core';
import { DeviceListComponent } from 'src/app/components/device-list/device-list.component';
import { InstrumentGroupListComponent, InstrumentGroupListRequest } from 'src/app/components/instrument-group-list/instrument-group-list.component';

@Component({
  selector: 'app-delete-files',
  templateUrl: './delete-files.component.html',
  styleUrls: ['./delete-files.component.scss']
})
export class DeleteFilesComponent implements OnInit, OnDestroy {
  @ViewChild(DeviceListComponent) deviceListComponent: DeviceListComponent;
  @ViewChild(InstrumentGroupListComponent) instrumentGroupListComponent: InstrumentGroupListComponent;

  public deviceInformationResult: DeviceInformationResult;
  public instrumentTypes_AssaysEnabled: InstrumentType[] = [];
  public instrumentTypes_LanguagesEnabled: InstrumentType[] = [];
  public instrumentGroupsResult: InstrumentGroupsResult;
  public assayDefinitions: AssayDefinition[];
  public languageDefinitions: LanguageDefinition[];
  public selectedTabIndex = 0;
  public ListMode = ListMode;
  public checkedGroupsEvent: CheckedGroupsEvent;
  public checkedDevicesEvent: CheckedDevicesEvent;
  public accessLevel: AccessLevel = AccessLevel.Unauthorized;
  public canDeleteAnyFiles = true;
  public showAssays = false;
  public showLanguages = false;
  public enableMultiSelect = false;

  private subscription: Subscription = new Subscription();
  private currentInstrumentGroupRequest: InstrumentGroupListRequest;
  private currentDeviceDataRequested: DeviceDataRequest;
  private instrumentTypes: InstrumentType[] = [];
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;
  private ASSAY_DEFINITION_CODES: string[];
  private LANGUAGE_DEFINITION_CODES: string[];
  private disableUnregisteredExactMatch = true;
  private features: Features;
  private selectedModels: InstrumentTypeModel[];

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;

      this.refreshFeatureFlagsBySelectedInstrumentType();

      this.assayDefinitionCodes = [];
      this.languageDefinitionCodes = [];
      // First set these components aren't set set. Ok not to uncheck them all
      this.deviceListComponent?.uncheckAll();
      this.instrumentGroupListComponent?.uncheckAll();
    }
  }

  get assayDefinitionCodes(): string[] {
    return this.ASSAY_DEFINITION_CODES;
  }

  set assayDefinitionCodes(codes: string[]) {
    if (this.ASSAY_DEFINITION_CODES !== codes) {
      this.ASSAY_DEFINITION_CODES = codes;
    }
  }

  get languageDefinitionCodes(): string[] {
    return this.LANGUAGE_DEFINITION_CODES;
  }

  set languageDefinitionCodes(ids: string[]) {
    if (this.LANGUAGE_DEFINITION_CODES !== ids) {
      this.LANGUAGE_DEFINITION_CODES = ids;
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get numberOfDevicesMessage(): string {
    let numberOfDevices = 0;

    if (this.checkedDevicesEvent) {
      numberOfDevices = !this.checkedDevicesEvent.allPages ? this.checkedDevicesEvent.checkedDevices.length : this.deviceInformationResult.totalResults;
    }

    return translateWithInterpolateParams(this.translateService, TranslateConstants.NumberOfInstrumentsLabelKey, { deviceCount: this.decimalPipe.transform(numberOfDevices) });
  }

  get numberOfGroupsMessage(): string {
    let numberOfGroups = 0;

    if (this.checkedGroupsEvent) {
      numberOfGroups = !this.checkedGroupsEvent.allPages ? this.checkedGroupsEvent.checkedGroups.length : this.instrumentGroupsResult.totalResults;
    }

    return translateWithInterpolateParams(this.translateService, TranslateConstants.NumberOfInstrumentGroupsLabelKey, { groupCount: this.decimalPipe.transform(numberOfGroups) });
  }

  get languagesMessage(): string {
    if (this.languageDefinitionCodes?.length > 0) {
      const delimitedNames = this.languageDefinitions.filter(x => this.languageDefinitionCodes.some(code => code === x.code)).map(x => x.definitionName).join(', ');
      return translateWithInterpolateParams(this.translateService, TranslateConstants.DeleteLanguagesLabelKey, { delimitedNames: delimitedNames });
    } else {
      return '';
    }
  }

  get assaysMessage(): string {
    if (this.assayDefinitionCodes?.length > 0) {
      const delimitedNames = this.assayDefinitions.filter(x => this.assayDefinitionCodes.some(code => code === x.code)).map(x => x.definitionName).join(', ');
      return translateWithInterpolateParams(this.translateService, TranslateConstants.DeleteAssaysLabelKey, { delimitedNames: delimitedNames });
    } else {
      return '';
    }
  }

  constructor(
    private dataService: DataService,
    private c2dMessagesService: C2DMessagesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private deviceHelperService: DeviceHelperService,
    private groupHelperService: GroupHelperService,
    private configurationService: ConfigurationService,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.selectedInstrumentType = selectionState.instrumentType;
        // Changing the InstrumentType causes the DeviceListComponent to request data
        this.selectedModels = selectionState.models;

        if (this.selectedInstrumentType) {
          this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument], [UserPermission.ViewEverything], this.selectedInstrumentType.instrumentTypeId);
          this.enableMultiSelect = this.userPermissionService.getAccessLevel([UserPermission.UpdateAllInstruments], [UserPermission.ViewEverything], this.selectedInstrumentType.instrumentTypeId) === AccessLevel.AddUpdate;
          this.getInstrumentGroupsWithCurrentSearchState();
        }
      }));
    }));

    this.getAllData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public hasDevices(): boolean {
    if (this.selectedTabIndex === 1) {
      return false;
    } else if (!this.checkedDevicesEvent?.allPages) {
      return this.checkedDevicesEvent?.checkedDevices.length > 0;
    } else {
      return true;
    }
  }

  public hasGroups(): boolean {
    if (this.selectedTabIndex === 0) {
      return false;
    } else if (!this.checkedGroupsEvent?.allPages) {
      return this.checkedGroupsEvent?.checkedGroups.length > 0;
    } else {
      return true;
    }
  }

  public hasDesiredProperty(): boolean {
    if ((!this.languageDefinitionCodes || this.languageDefinitionCodes.length <= 0)
      && (!this.assayDefinitionCodes || this.assayDefinitionCodes.length <= 0)) {
      return false;
    }

    return true;
  }

  public onCheckedDevices($event: CheckedDevicesEvent): void {
    this.checkedDevicesEvent = $event;
  }

  public async deleteFilesDevices(): Promise<void> {
    if (this.selectedTabIndex !== 0) {
      return;
    }


    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteFilesTitleKey, TranslateConstants.InstrumentsKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: translate(this.translateService, TranslateConstants.DeleteFilesWarningKey) + this.numberOfDevicesMessage + this.languagesMessage + this.assaysMessage
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const request: DeleteFilesRequest = {
        serialNumbers: !this.checkedDevicesEvent.allPages ? this.checkedDevicesEvent.checkedDevices.map(x => x.serialNumber) : null,
        assayCodes: this.assayDefinitionCodes,
        languageCodes: this.languageDefinitionCodes
      };

      const deviceDataRequested = this.checkedDevicesEvent.allPages
        ? this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch)
        : undefined;
      if (deviceDataRequested) {
        prepareDeviceSearchRequest(deviceDataRequested);
        request.queryByInstrumentSearchRequest = deviceDataRequested;
      }

      this.requestFilesDeletion(request);
    }
  }

  public async deleteFilesGroups(): Promise<void> {
    if (this.selectedTabIndex !== 1) {
      return;
    }

    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteFilesTitleKey, TranslateConstants.InstrumentGroupsKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: translate(this.translateService, TranslateConstants.DeleteFilesWarningKey) + this.numberOfGroupsMessage + this.languagesMessage + this.assaysMessage
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      const instrumentGroups = this.checkedGroupsEvent.checkedGroups.map(x => x.instrumentGroupId);
      const request: DeleteFilesRequest = {
        instrumentGroupIds: !this.checkedGroupsEvent?.allPages ? instrumentGroups : null,
        assayCodes: this.assayDefinitionCodes,
        languageCodes: this.languageDefinitionCodes
      };
      const instrumentGroupDataRequested = this.checkedGroupsEvent?.allPages
        ? this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId)
        : undefined;
      if (instrumentGroupDataRequested) {
        prepareSearchGroupRequest(instrumentGroupDataRequested);
        request.queryByInstrumentGroupSearchRequest = instrumentGroupDataRequested;
      }

      this.requestFilesDeletion(request);
    }
  }

  private requestFilesDeletion(request: DeleteFilesRequest): void {
    this.subscription.add(this.c2dMessagesService.requestFileDeletion(request)
      .subscribe(
        {
          next: (result: C2DMessageRequestResponse) => {

            C2DMessagesService.handleC2DRequestResponse(this.notificationService, false, result, TranslateConstants.RequestDeleteFileKey);
            this.getDevicesWithCurrentSearchState();
          },
          error: () => {
            this.notificationService.error(TranslateConstants.DeleteFilesFailedKey);
          }
        }));
  }

  public instrumentGroupDataRequested($event: InstrumentGroupListRequest): void {
    this.currentInstrumentGroupRequest = $event;
    this.getInstrumentGroupsWithCurrentSearchState();
  }

  public deviceDataRequested($event: DeviceDataRequest): void {
    this.currentDeviceDataRequested = $event;
    this.getDevicesWithCurrentSearchState();
  }

  public onCheckedGroups($event: CheckedGroupsEvent): void {
    this.checkedGroupsEvent = $event;
  }

  public getInstrumentGroupsWithCurrentSearchState(): void {
    const searchRequest: InstrumentGroupsRequest = this.groupHelperService.getGroupSearchRequest(this.currentInstrumentGroupRequest, this.selectedInstrumentType.instrumentTypeId);

    this.subscription.add(
      this.dataService.getInstrumentGroups(searchRequest)
        .subscribe((instrumentGroupsResult: InstrumentGroupsResult) => {
          this.instrumentGroupsResult = instrumentGroupsResult;
        }));
  }

  private getDevicesWithCurrentSearchState(): void {
    const searchRequest: SearchRequest = this.deviceHelperService.getDeviceSearchRequest(this.currentDeviceDataRequested, this.selectedInstrumentType.instrumentTypeId, this.selectedModels?.map(m => m.modelId), this.disableUnregisteredExactMatch);

    this.subscription.add(
      this.dataService.getDeviceInformation(searchRequest)
        .subscribe((results) => {
          this.deviceInformationResult = results;
        }));
  }

  private getAllData(): void {
    this.subscription.add(
      forkJoin([
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.dataService.getAssayDefinitions(),
        this.dataService.getLanguageDefinitions(),
      ]).subscribe((results: [boolean, AssayDefinition[], LanguageDefinition[]]) => {
        this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
        this.assayDefinitions = results[1].sort((a, b) => a.code.localeCompare(b.code));
        this.languageDefinitions = results[2].sort((a, b) => a.code.localeCompare(b.code));

        this.features = this.configurationService.getClientConfiguration()?.features;

        this.instrumentTypes = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.UpgradeInstrumentSuffix);
        this.instrumentTypes_AssaysEnabled = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.AMFManagementSuffix);
        this.instrumentTypes_LanguagesEnabled = filterInstrumentTypesByFeatureFlag(this.features, this.instrumentTypes, FeatureFlagConstants.LanguageManagementSuffix);
      }));
  }

  private refreshFeatureFlagsBySelectedInstrumentType() {
    if (this.selectedInstrumentType) {
      this.showAssays = isFeatureEnabledForInstrumentTypeName(this.features, this.selectedInstrumentType.name, FeatureFlagConstants.AMFDeletion);
      this.showLanguages = isFeatureEnabledForInstrumentTypeName(this.features, this.selectedInstrumentType.name, FeatureFlagConstants.LangDeletion);
    } else {
      this.showAssays = false;
      this.showLanguages = false;
    }

    this.canDeleteAnyFiles = this.showAssays || this.showLanguages;
  }
}

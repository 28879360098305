import { ValidatorFn, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { Country } from '../models';

export function forbiddenValueValidator(testValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value as number | undefined;
    return controlValue === testValue
      ? { forbiddenValue: { value: controlValue } }
      : null;
  };
}

export function countryValidator(allCountries: Country[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value as Country | undefined;
    return allCountries.indexOf(controlValue) >= 0
      ? null // found country
      : { invalidCountry: { value: controlValue } };
  };
}

/**
     * Validator that requires the control's value to be valid emails. Semi-Colon delimited.
     *
     * @usageNotes
     *
     * ### Validate a string containing an invalid email
     *
     * ```typescript
     * const control = new FormControl('one@email.com;two@email.com;bad@', emailsValidator);
     *
     * console.log(control.errors); // {emails: true}
     * ```
     *
     * ### Validate an empty string
     *
     * ```typescript
     * const control = new FormControl('', emailsValidator);
     *
     * console.log(control.errors); // {emails: true}
     * ```
     *
     * @returns A validator function that returns an error map with the
     * `emails` property if the validation check fails, otherwise `null`.
     */
export function emailsValidator(control: AbstractControl): ValidationErrors | null {
  const controlValue = control.value as string | undefined;
  if (controlValue) {
    const emails = controlValue.split(';');
    const allEmailsValid = emails.every(email => {
      const emailValidatorResult = Validators.email(<AbstractControl>{ value: email });
      if (emailValidatorResult != null) {
        return false;
      }
      return true;
    });
    if (allEmailsValid) {
      return null;
    }
  }
  return { emails: true };
}

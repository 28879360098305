import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BusyService } from '../services/busy.service';

export const preventBusyIndicatorString = 'preventBusyIndicator';
export const preventBusyIndicatorHeaders = new HttpHeaders({ preventBusyIndicator: 'true' });

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private busyService: BusyService) { }
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const preventBusy = req.headers.get(preventBusyIndicatorString);

    if (!preventBusy) {
      console.debug(`Showing Busy: ${req.url}`);
      this.busyService.busy();
    }

    const updatedReq = req.clone({ headers: req.headers.delete(preventBusyIndicatorString) });
    return next.handle(updatedReq).pipe(
      finalize(() => {
        if (!preventBusy) {
          this.busyService.idle();
        }
      })
    );
  }
}


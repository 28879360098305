import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { translate } from '../shared/translateServiceHelper';

export class TranslateConstants {
  public static readonly DefaultLanguage = 'en';
  public static readonly SupportedLanguagesMap: Map<string, string> = new Map<string, string>([
    ['en', 'English'],
    // ['de', 'Deutsch'] // Commenting out until we actually have a German Translation
  ]);

  public static readonly All = marker('Global.All');

  public static readonly AssayKey = marker('Assays.Assay');
  public static readonly AssayDefinitionKey = marker('AssayDefinitions.AssayDefinition');
  public static readonly AssayInterfaceKey = marker('AssayInterfaces.AssayInterface');
  public static readonly LanguageKey = marker('Languages.Language');
  public static readonly LanguageDefinitionKey = marker('LanguageDefinitions.LanguageDefinition');
  public static readonly LanguageInterfaceKey = marker('LanguageInterfaces.LanguageInterface');
  public static readonly FirmwareKey = marker('Firmwares.Firmware');
  public static readonly ModKey = marker('Firmwares.Mod');
  public static readonly InstrumentKey = marker('Instruments.Instrument');
  public static readonly InstrumentsKey = marker('Instruments.Instruments');
  public static readonly InstrumentGroupKey = marker('InstrumentGroups.InstrumentGroup');
  public static readonly InstrumentGroupsKey = marker('InstrumentGroups.InstrumentGroups');
  public static readonly FileKey = marker('Global.File');

  public static readonly InstrumentSerialNumberAndIdLabelKey = marker('Instruments.SerialNumberAndIdLabel');

  public static readonly CancelKey = marker('Global.Cancel');
  public static readonly DeleteKey = marker('Global.Delete');
  public static readonly RemoveKey = marker('Global.Remove');
  public static readonly ForceRefreshKey = marker('Global.ForceRefresh');
  public static readonly PublishKey = marker('Global.Publish');
  public static readonly UnpublishKey = marker('Global.Unpublish');
  public static readonly UpgradeKey = marker('Global.Upgrade');
  public static readonly YesKey = marker('Global.Yes');
  public static readonly NoKey = marker('Global.No');
  public static readonly DisableKey = marker('Global.Disable');
  public static readonly ApproveKey = marker('Global.Approve');
  public static readonly DenyKey = marker('Global.Deny');
  public static readonly AssignKey = marker('Global.Assign');
  public static readonly DeployKey = marker('Global.Deploy');
  public static readonly NotRegisteredKey = marker('Global.NotRegistered');
  public static readonly RegisteredKey = marker('Global.Registered');
  public static readonly OrphanKey = marker('Global.Orphan');

  public static readonly PublishedFlagUnknownKey = marker('PublishedFlag.Unknown');
  public static readonly PublishedFlagUnpublishedKey = marker('PublishedFlag.Unpublished');
  public static readonly PublishedFlagPendingPublishKey = marker('PublishedFlag.PendingPublish');
  public static readonly PublishedFlagPublishedKey = marker('PublishedFlag.Published');
  public static readonly PublishedFlagPreviouslyPublishedKey = marker('PublishedFlag.PreviouslyPublished');

  public static readonly DeleteTitleKey = marker('Confirmation.DeleteTitle');
  public static readonly DeleteWarningKey = marker('Confirmation.DeleteWarning');
  public static readonly DeleteFileTitleKey = marker('Confirmation.DeleteFileTitle');
  public static readonly DeleteFileWarningKey = marker('Confirmation.DeleteFileWarning');
  public static readonly ForceCertificateRefreshTitleKey = marker('Confirmation.ForceCertificateRefreshTitle');
  public static readonly ForceCertificateRefreshMessageKey = marker('Confirmation.ForceCertificateRefreshMessage');
  public static readonly ForceDeviceTwinRefreshTitleKey = marker('Confirmation.ForceDeviceTwinRefreshTitle');
  public static readonly ForceDeviceTwinRefreshWarningKey = marker('Confirmation.ForceDeviceTwinRefreshWarning');
  public static readonly ForceInstrumentTypeCertificateRefreshMessageKey = marker('Confirmation.ForceInstrumentTypeCertificateRefreshMessage');
  public static readonly ForceInstrumentGroupCertificateRefreshMessageKey = marker('Confirmation.ForceInstrumentGroupCertificateRefreshMessage');
  public static readonly PublishTitleKey = marker('Confirmation.PublishTitle');
  public static readonly PublishWarningKey = marker('Confirmation.PublishWarning');
  public static readonly UnpublishTitleKey = marker('Confirmation.UnpublishTitle');
  public static readonly UnpublishWarningKey = marker('Confirmation.UnpublishWarning');
  public static readonly RemoveRequestTileKey = marker('Confirmation.RemoveRequestTitle');
  public static readonly RemoveUpgradeRequestMessageKey = marker('Confirmation.RemoveUpgradeRequestMessage');
  public static readonly RemoveDeviceSettingRequestMessageKey = marker('Confirmation.RemoveDeviceSettingRequestMessage');
  public static readonly VersionLabelKey = marker('Confirmation.VersionLabel');
  public static readonly CodeLabelKey = marker('Confirmation.CodeLabel');
  public static readonly InterfaceVersionLabelKey = marker('Confirmation.InterfaceVersionLabel');
  public static readonly NumberOfInstrumentsLabelKey = marker('Confirmation.NumberOfInstrumentsLabel');
  public static readonly NumberOfInstrumentGroupsLabelKey = marker('Confirmation.NumberOfInstrumentGroupsLabel');
  public static readonly UpgradeInstrumentsTitleKey = marker('Confirmation.UpgradeInstrumentsTitle');
  public static readonly UpgradeInstrumentGroupsTitleKey = marker('Confirmation.UpgradeInstrumentGroupsTitle');
  public static readonly UpgradeInstrumentsMessageKey = marker('Confirmation.UpgradeInstrumentsMessage');
  public static readonly UpgradeInstrumentGroupsMessageKey = marker('Confirmation.UpgradeInstrumentGroupsMessage');
  public static readonly DeleteFilesTitleKey = marker('Confirmation.DeleteFilesTitle');
  public static readonly DeleteFilesWarningKey = marker('Confirmation.DeleteFilesWarning');
  public static readonly GenerateExportTitleKey = marker('Confirmation.GenerateExportTitle');
  public static readonly GenerateExportMessageKey = marker('Confirmation.GenerateExportMessage');
  public static readonly ForceDowngradesTitleKey = marker('Confirmation.ForceDowngradesTitle');
  public static readonly ForceDowngradesWarningKey = marker('Confirmation.ForceDowngradesWarning');
  public static readonly DisableIoTConnectionTitleKey = marker('Confirmation.DisableIoTConnectionTitle');
  public static readonly DisableIoTConnectionMessageKey = marker('Confirmation.DisableIoTConnectionMessage');
  public static readonly ManualApprovalTitleKey = marker('Confirmation.ManualApprovalTitle');
  public static readonly ManualApprovalWarningKey = marker('Confirmation.ManualApprovalWarning');
  public static readonly ManualApprovalDeleteTitleKey = marker('Confirmation.ManualApprovalDeleteTitle');
  public static readonly ManualApprovalDeleteWarningKey = marker('Confirmation.ManualApprovalDeleteWarning');
  public static readonly ClearAllClaimsTitleKey = marker('Confirmation.ClearAllClaimsTitle');
  public static readonly ClearAllClaimsWarningKey = marker('Confirmation.ClearAllClaimsWarning');
  public static readonly RemoveAllPathsTitleKey = marker('Confirmation.RemoveAllPathsTitle');
  public static readonly RemoveAllPathsWarningKey = marker('Confirmation.RemoveAllPathsWarning');
  public static readonly DeploySoftwareTitleKey = marker('Confirmation.DeploySoftwareTitle');
  public static readonly DeploySoftwareMessageKey = marker('Confirmation.DeploySoftwareMessage');
  public static readonly DeploySoftwareToInstrumentGroupsMessageKey = marker('Confirmation.DeploySoftwareToInstrumentGroupsMessage');

  public static readonly CreateSuccessKey = marker('Notification.CreateSuccess');
  public static readonly UpdateSuccessKey = marker('Notification.UpdateSuccess');
  public static readonly PublishSuccessKey = marker('Notification.PublishSuccess');
  public static readonly UnpublishSuccessKey = marker('Notification.UnpublishSuccess');
  public static readonly DeleteSuccessKey = marker('Notification.DeleteSuccess');
  public static readonly DeviceSettingsUpdateRequestSuccessKey = marker('Notification.DeviceSettingsUpdateRequestSuccess');
  public static readonly CancelRequestSuccessKey = marker('Notification.CancelSuccess');
  public static readonly ForceCertificateRefreshSuccessKey = marker('Notification.ForceCertificateRefresh.Success');
  public static readonly ForceCertificateRefreshFailedKey = marker('Notification.ForceCertificateRefresh.Failed');
  public static readonly CopyToClipboardSuccessKey = marker('Notification.CopyToClipBoardSuccess');
  public static readonly UpgradeSuccessKey = marker('Notification.UpgradeSuccess');
  public static readonly UpgradeFailedKey = marker('Notification.UpgradeFailed');
  public static readonly ClearUpgradesSuccessKey = marker('Notification.ClearUpgradesSuccess');
  public static readonly ForceDeviceTwinRefreshSuccessKey = marker('Notification.ForceDeviceTwinRefreshSuccess');
  public static readonly ForceDeviceTwinRefreshFailedKey = marker('Notification.ForceDeviceTwinRefreshFailed');
  public static readonly FileSizeTooLargeKBKey = marker('Notification.FileSizeTooLargeKB');
  public static readonly FailedParsingValidationErrorsKey = marker('Notification.FailedParsingValidationErrors');
  public static readonly DeleteFilesFailedKey = marker('Notification.DeleteFilesFailed');
  public static readonly UserSettingsLanguageUpdateSuccessKey = marker('Notification.UserSettings.LanguageUpdateSuccess');
  public static readonly UserSettingsLanguageUpdateFailedKey = marker('Notification.UserSettings.LanguageUpdateFailed');
  public static readonly FileBrowserConnectionFailedKey = marker('Notification.FileBrowser.ConnectionFailed');
  public static readonly FileBrowserBadRequestKey = marker('Notification.FileBrowser.BadRequest');
  public static readonly FileBrowserRequestSuccessKey = marker('Notification.FileBrowser.RequestSuccess');
  public static readonly FileBrowserRequestErrorTitleKey = marker('Notification.FileBrowser.RequestErrorTitle');
  public static readonly FileBrowserRequestPartialFailureMessageMoreThanShownKey = marker('Notification.FileBrowser.RequestPartialFailureMessageMoreThanShown');
  public static readonly FileBrowserRequestPartialFailureMessageAllShownKey = marker('Notification.FileBrowser.RequestPartialFailureMessageAllShown');
  public static readonly InstrumentTypeRerouteKey = marker('Notification.InstrumentTypeReroute');
  public static readonly ManualApprovalSuccessKey = marker('Notification.ManualApprovalSuccess');
  public static readonly DefaultSettingNotFoundKey = marker('Notification.DefaultSettingNotFound');
  public static readonly NonMatchingDevicesRemovedFromGroupKey = marker('Notification.InstrumentGroups.NonMatchingDevicesRemoved');
  public static readonly SerialNumberOverridesImportRequestSuccessKey = marker('Notification.SerialNumberOverrides.ImportRequestSuccess');
  public static readonly SerialNumberOverridesImportRequestFailedKey = marker('Notification.SerialNumberOverrides.ImportRequestFailed');
  public static readonly RequestFilesAddedAllPathsKey = marker('Notification.RequestFiles.AddedAllPaths');
  public static readonly RequestFilesTooManyPathsInFileTitleKey = marker('Notification.RequestFiles.TooManyPathsInFileTitle');
  public static readonly RequestFilesTooManyPathsInFileWarningKey = marker('Notification.RequestFiles.TooManyPathsInFileWarning');
  public static readonly RequestFilesNoPathsAddedKey = marker('Notification.RequestFiles.NoPathsAdded');
  public static readonly RequestFilesDuplicatePathKey = marker('Notification.RequestFiles.DuplicatePath');
  public static readonly RequestFilesDuplicatePathsInFileKey = marker('Notification.RequestFiles.DuplicatePathsInFile');
  public static readonly RequestFilesUnsupportedJsonFileSchemaKey = marker('Notification.RequestFiles.UnsupportedJsonFileSchema');

  public static readonly PaginatorItemsPerPageKey = marker('Paginator.ItemsPerPage');
  public static readonly PaginatorNextPageKey = marker('Paginator.NextPage');
  public static readonly PaginatorPreviousPageKey = marker('Paginator.PreviousPage');
  public static readonly PaginatorFirstPageKey = marker('Paginator.FirstPage');
  public static readonly PaginatorLastPageKey = marker('Paginator.LastPage');
  public static readonly PaginatorRangeNoResultsKey = marker('Paginator.RangeNoResults');
  public static readonly PaginatorRangeResultsKey = marker('Paginator.RangeResults');

  public static readonly C2DMessageSingleInstrumentSuccessKey = marker('Notification.C2D.SingleInstrumentRequestSuccess');
  public static readonly C2DMessageInstrumentGroupSuccessKey = marker('Notification.C2D.InstrumentGroupRequestSuccess');
  public static readonly C2DMessageSuccessWithSkippedInstrumentTypesKey = marker('Notification.C2D.MessageSuccessWithSkippedInstrumentTypes');
  public static readonly C2DMessageSuccessNoneRequestedKey = marker('Notification.C2D.MessageSuccessNoneRequested');

  public static readonly ReportExportGenerationRequestSuccessKey = marker('Notification.Reports.ExportGenerationRequestSuccess');
  public static readonly ReportExportGenerationRequestFailedKey = marker('Notification.Reports.ExportGenerationRequestFailed');
  public static readonly ReportInstrumentSofia2Key = marker('Notification.Reports.Instrument-Sofia2');
  public static readonly ReportInstrumentSavannaKey = marker('Notification.Reports.Instrument-Savanna');
  public static readonly ReportInstrumentVisionKey = marker('Notification.Reports.Instrument-Vision');
  public static readonly ReportRegistrationFailureKey = marker('Notification.Reports.RegistrationFailure');

  public static readonly UpdateUserListKey = marker('Notification.UpdateUserList');
  public static readonly RequestLUTFilesKey = marker('Notification.RequestLUTFiles');
  public static readonly RequestLogsUploadKey = marker('Notification.RequestLogsUpload');
  public static readonly RequestUploadFileToInstrumentKey = marker('Notification.RequestUploadFileToInstrument');
  public static readonly RequestDeleteFileKey = marker('Notification.RequestDeleteFile');
  public static readonly RequestDisableIoTConnectionKey = marker('Notification.RequestDisableIoTConnection');
  public static readonly RequestFolderSearchKey = marker('Notification.RequestFolderSearch');

  public static readonly UnauthorizedTitleKey = marker('Global.UnauthorizedTitle');
  public static readonly UnauthorizedMessageKey = marker('Global.UnauthorizedMessage');

  public static readonly UploadingKey = marker('UploadDialog.Uploading');

  public static readonly DeviceSettingsAwsSettingsHeaderKey = marker('DeviceSettings.AwsSettings.Header');
  public static readonly DeviceSettingsAwsSettingsEnableFolderOverride = marker('DeviceSettings.AwsSettings.EnableFolderOverride');
  public static readonly DeviceSettingsAwsSettingsFolderOverride = marker('DeviceSettings.AwsSettings.FolderOverride');

  public static readonly DeviceSettingsVirenaHeaderKey = marker('DeviceSettings.Virena.Header');
  public static readonly DeviceSettingsVirenaOptInKey = marker('DeviceSettings.Virena.OptIn');
  public static readonly DeviceSettingsVirenaAutoSendKey = marker('DeviceSettings.Virena.AutoSend');

  public static readonly DeviceSettingsRetryHeaderKey = marker('DeviceSettings.Retry.Header');
  public static readonly DeviceSettingsRetryTimeDelayMsKey = marker('DeviceSettings.Retry.TimeDelayMs');
  public static readonly DeviceSettingsRetryTimeDelaySKey = marker('DeviceSettings.Retry.TimeDelayS');
  public static readonly DeviceSettingsRetryTimeDelayKey = marker('DeviceSettings.Retry.TimeDelay');
  public static readonly DeviceSettingsRetryNumberOfRetriesKey = marker('DeviceSettings.Retry.NumberOfRetries');

  public static readonly DeviceSettingsDownloadHeaderKey = marker('DeviceSettings.Download.Header');
  public static readonly DeviceSettingsDownloadChunkDownloadTimeoutSKey = marker('DeviceSettings.Download.ChunkDownloadTimeoutS');
  public static readonly DeviceSettingsDownloadChunkDownloadTimeoutNotHonoredKey = marker('DeviceSettings.Download.ChunkDownloadTimeoutNotHonored');
  public static readonly DeviceSettingsDownloadSoftwareTitleKey = marker('DeviceSettings.Download.Software.Title');
  public static readonly DeviceSettingsDownloadSoftwareMaxRetriesKey = marker('DeviceSettings.Download.Software.MaxRetries');
  public static readonly DeviceSettingsDownloadSoftwareRetryTimeoutMinutesKey = marker('DeviceSettings.Download.Software.RetryTimeoutMinutes');

  public static readonly DeviceSettingsClinicalModeHeaderKey = marker('DeviceSettings.ClinicalMode.Header');
  public static readonly DeviceSettingsClinicalModeSaveClinicalImagesKey = marker('DeviceSettings.ClinicalMode.SaveClinicalImages');
  public static readonly DeviceSettingsClinicalModeExportDataToUsbKey = marker('DeviceSettings.ClinicalMode.ExportDataToUsb');

  public static readonly DeviceSettingsTestResultsUploadHeaderKey = marker('DeviceSettings.TestResultsUpload.Header');
  public static readonly DeviceSettingsTestResultsUploadEnabledKey = marker('DeviceSettings.TestResultsUpload.Enabled');
  public static readonly DeviceSettingsTestResultsUploadAccessTokenKey = marker('DeviceSettings.TestResultsUpload.AccessToken');
  public static readonly DeviceSettingsTestResultsUploadServerKey = marker('DeviceSettings.TestResultsUpload.Server');
  public static readonly DeviceSettingsTestResultsUploadApiPathKey = marker('DeviceSettings.TestResultsUpload.ApiPath');
  public static readonly DeviceSettingsTestResultsUploadUploadDelayMinutesKey = marker('DeviceSettings.TestResultsUpload.UploadDelayMinutes');
  public static readonly DeviceSettingsTestResultsUploadDeleteLocallyAfterUploadKey = marker('DeviceSettings.TestResultsUpload.DeleteLocallyAfterUpload');

  public static readonly DeviceSettingsPerformanceDataHeaderKey = marker('DeviceSettings.PerformanceData.Header');
  public static readonly DeviceSettingsPerformanceDataEnabledKey = marker('DeviceSettings.PerformanceData.Enabled');
  public static readonly DeviceSettingsPerformanceDataVerbosityKey = marker('DeviceSettings.PerformanceData.Verbosity');

  public static readonly DeviceSettingsSettingsHeaderKey = marker('DeviceSettings.Settings.Header');
  public static readonly DeviceSettingsSettingsIoTHeaderKey = marker('DeviceSettings.Settings.IoT.Header');
  public static readonly DeviceSettingsSettingsIoTAllowlistFolderPathsKey = marker('DeviceSettings.Settings.IoT.AllowlistFolderPaths');

  public static readonly InstrumentsTabInstrumentGroupsKey = marker('Instruments.Tabs.InstrumentGroups');
  public static readonly InstrumentsTabFilesKey = marker('Instruments.Tabs.Files');
  public static readonly InstrumentsTabRecentUpgradeRequestHistoryKey = marker('Instruments.Tabs.RecentUpgradeRequestHistory');
  public static readonly InstrumentsTabUtilitiesKey = marker('Instruments.Tabs.Utilities');
  public static readonly InstrumentsTabConnectionStatusKey = marker('Instruments.Tabs.ConnectionStatus');
  public static readonly InstrumentsFirmwareVersionKey = marker('Instruments.FirmwareVersion');
  public static readonly InstrumentsModVersionKey = marker('Instruments.ModVersion');
  public static readonly InstrumentsRequestedFirmwareKey = marker('Instruments.RequestedFirmware');
  public static readonly InstrumentsRequestedModKey = marker('Instruments.RequestedMod');

  public static readonly DigiStatusAdded = marker('Instruments.DigiStatusAdded');
  public static readonly DigiStatusNotAdded = marker('Instruments.DigiStatusNotAdded');
  public static readonly DigiAddDialogTitle = marker('Instruments.AddToDigiDialogTitle');
  public static readonly DigiAddDialogContent = marker('Instruments.AddToDigiDialogContent');
  public static readonly DigiRemoveDialogTitle = marker('Instruments.RemoveFromDigiDialogTitle');
  public static readonly DigiRemoveDialogContent = marker('Instruments.RemoveFromDigiDialogContent');

  public static readonly VerizonStatusActivated = marker('Instruments.VerizonStatusActivated');
  public static readonly VerizonStatusDeactivated = marker('Instruments.VerizonStatusDeactivated');
  public static readonly VerizonActivateDialogTitle = marker('Instruments.ActivateWithVerizonDialogTitle');
  public static readonly VerizonActivateDialogContent = marker('Instruments.ActivateWithVerizonDialogContent');
  public static readonly VerizonDeactivateDialogTitle = marker('Instruments.DeactivateFromVerizonDialogTitle');
  public static readonly VerizonDeactivateDialogContent = marker('Instruments.DeactivateFromVerizonDialogContent');

  public static readonly MyVirenaStatusNotRegistered = marker('Instruments.MyVirenaStatusNotRegistered');
  public static readonly MyVirenaStatusRegistered = marker('Instruments.MyVirenaStatusRegistered');
  public static readonly MyVirenaStatusOrphan = marker('Instruments.MyVirenaStatusOrphan');

  public static readonly InstrumentFilesListDownloadFilesEmailDialogSuccessKey = marker('InstrumentFilesList.DownloadFilesEmailDialog.Success');
  public static readonly InstrumentFilesListDownloadFilesEmailDialogFailureKey = marker('InstrumentFilesList.DownloadFilesEmailDialog.Failure');
  public static readonly InstrumentFilesListRecentFileStatusHistoryEmailDialogSuccessKey = marker('InstrumentFilesList.RecentFileStatusHistoryEmailDialog.Success');
  public static readonly InstrumentFilesListRecentFileStatusHistoryEmailDialogFailureKey = marker('InstrumentFilesList.RecentFileStatusHistoryEmailDialog.Failure');

  public static readonly InstrumentGroupsTabDevicesKey = marker('InstrumentGroups.Tabs.Devices');
  public static readonly InstrumentGroupsTabManageDeviceSettingsKey = marker('InstrumentGroups.Tabs.ManageDeviceSettings');
  public static readonly InstrumentGroupsTabUtilitiesKey = marker('InstrumentGroups.Tabs.Utilities');

  public static readonly UpgradeFirmwaresLabelKey = marker('UpgradeInstruments.Firmwares');
  public static readonly UpgradeModsLabelKey = marker('UpgradeInstruments.Mods');
  public static readonly UpgradeFirmwareLabelKey = marker('UpgradeInstruments.FirmwareUpgradeLabel');
  public static readonly UpgradeModLabelKey = marker('UpgradeInstruments.ModUpgradeLabel');
  public static readonly UpgradeAssaysLabelKey = marker('UpgradeInstruments.AssaysUpgradeLabel');
  public static readonly UpgradeLanguagesLabelKey = marker('UpgradeInstruments.LanguagesUpgradeLabel');
  public static readonly UpgradeUpgradeInstrumentsLabelKey = marker('UpgradeInstruments.UpgradeInstruments');
  public static readonly UpgradeDeploySoftwareLabelKey = marker('UpgradeInstruments.DeploySoftware');
  public static readonly UpgradeClearUpgradesLabelKey = marker('UpgradeInstruments.ClearUpgrades');
  public static readonly UpgradeClearDeploymentsLabelKey = marker('UpgradeInstruments.ClearDeployments');

  public static readonly DeleteAssaysLabelKey = marker('DeleteFiles.DeleteAssaysLabel');
  public static readonly DeleteLanguagesLabelKey = marker('DeleteFiles.DeleteLanguagesLabel');

  public static readonly ManualApprovalApprovedKey = marker('ManualApproval.Approved');
  public static readonly ManualApprovalDeniedKey = marker('ManualApproval.Denied');

  public static readonly UpgradeRequestHistoryFirmwareKey = marker('UpgradeRequestHistory.Firmware');
  public static readonly UpgradeRequestHistoryModKey = marker('UpgradeRequestHistory.Mod');

  public static readonly FileHeaderParsingMissingStartTokenNameKey = marker('FileHeaderParsingErrors.MissingStartTokenName');
  public static readonly FileHeaderParsingMissingStartTokenMessageKey = marker('FileHeaderParsingErrors.MissingStartTokenMessage');
  public static readonly FileHeaderParsingMissingEndTokenNameKey = marker('FileHeaderParsingErrors.MissingEndTokenName');
  public static readonly FileHeaderParsingMissingEndTokenMessageKey = marker('FileHeaderParsingErrors.MissingEndTokenMessage');
  public static readonly FileHeaderParsingLPFHeaderRequiredFieldKey = marker('FileHeaderParsingErrors.LPFHeaderRequiredField');
  public static readonly FileHeaderParsingLUFInterfaceVersionNotSupportedKey = marker('FileHeaderParsingErrors.LUFInterfaceVersionNotSupported');
  public static readonly FileHeaderParsingTOCLengthRequiredPositiveKey = marker('FileHeaderParsingErrors.TOCLengthRequiredPositive');
  public static readonly FileHeaderParsingLUFTOCRequiredFieldKey = marker('FileHeaderParsingErrors.LUFTOCRequiredField');
  public static readonly FileHeaderParsingBlobLengthRequiredPositiveKey = marker('FileHeaderParsingErrors.BlobLengthRequiredPositive');
  public static readonly FileHeaderParsingFailedReadingFileKey = marker('FileHeaderParsingErrors.FailedReadingFile');
  public static readonly FileHeaderParsingManifestFileMissingKey = marker('FileHeaderParsingErrors.ManifestFileMissing');

  public static readonly DeviceListSearchFirmwareVersionKey = marker('DeviceList.Search.FirmwareVersion');
  public static readonly DeviceListSearchModVersionKey = marker('DeviceList.Search.ModVersion');
  public static readonly DeviceListHeadersFirmwareVersionKey = marker('DeviceList.Headers.FirmwareVersion');
  public static readonly DeviceListHeadersModVersionKey = marker('DeviceList.Headers.ModVersion');
  public static readonly DeviceListSearchStatusKey = marker('DeviceList.Search.Status');

  public static readonly DeviceListStatusTooltipConnectedKey = marker('DeviceList.StatusTooltip.Connected');
  public static readonly DeviceListStatusTooltipDisconnectedyKey = marker('DeviceList.StatusTooltip.Disconnected');
  public static readonly DeviceListStatusTooltipHealthyKey = marker('DeviceList.StatusTooltip.Healthy');
  public static readonly DeviceListStatusTooltipUnknownKey = marker('DeviceList.StatusTooltip.Unknown');
  public static readonly DeviceListStatusTooltipUnhealthyKey = marker('DeviceList.StatusTooltip.Unhealthy');
  public static readonly DeviceListStatusTooltipUnregisteredKey = marker('DeviceList.StatusTooltip.Unregistered');

  public static readonly FirmwaresFirmwareFilesKey = marker('Firmwares.FirmwareFiles');
  public static readonly FirmwaresModFilesKey = marker('Firmwares.ModFiles');

  public static readonly AppNavUpgradeInstrumentsKey = marker('App.Nav.UpgradeInstruments');
  public static readonly AppNavDeploySoftwareKey = marker('App.Nav.DeploySoftware');
  public static readonly AppNavFirmwareFilesKey = marker('App.Nav.FirmwareFiles');
  public static readonly AppNavModFilesKey = marker('App.Nav.ModFiles');

  public static readonly SerialNumberOverrideKey = marker('SerialNumberOverrides.SerialNumberOverride');
  public static readonly SerialNumberOverridesClearSuccessKey = marker('SerialNumberOverrides.SerialNumberOverridesClearSuccess');
  public static readonly SerialNumberOverridesClearFailedKey = marker('SerialNumberOverrides.SerialNumberOverridesClearFailed');
  public static readonly SerialNumberOverridesSetSuccessKey = marker('SerialNumberOverrides.SerialNumberOverridesSetSuccess');
  public static readonly SerialNumberOverridesSetFailedKey = marker('SerialNumberOverrides.SerialNumberOverridesSetFailed');
  public static readonly SerialNumberOverrideClearKey = marker('Confirmation.SerialNumberOverrideClearKey');
  public static readonly SerialNumberOverrideWarningKey = marker('Confirmation.SerialNumberOverrideWarningKey');
  public static readonly SetSerialNumberOverrideTitleKey = marker('SetSerialNumberOverrideDialog.Title');
  public static readonly SetSerialNumberOverrideMessageKey = marker('SetSerialNumberOverrideDialog.Message');

  /**
   * Gets translation text for the current language of message with a {{type}} parameter
   * @param {TranslateService} translateService The translate service used to get the translation
   * @param {string} key The translation key for retrieving the text
   * @param {string} typeKey The translation key for retrieving the type to then use in the outer translation's interpolation of `type`
   * @returns {string} Translated text
   */
  public static BuildTypeMessage(translateService: TranslateService, key: string, typeKey: string): string {
    const title = translate(translateService, key, { type: typeKey });

    return title;
  }

  /**
   * Gets translation text for the current language of message with {{type}} and {{identifier}} parameters
   * @param {TranslateService} translateService The translate service used to get the translation
   * @param {string} key The translation key for retrieving the text
   * @param {string} typeKey The translation key for retrieving the type to then use in the outer translation's interpolation of `type`
   * @param {string} identifier The identifier string value to use in the outer translation's interpolation of `identifier`.
   *      If this matches a Translation key it will then be translated.
   * @returns {string} Translated text
   */
  public static BuildTypeMessageWithIdentifier(translateService: TranslateService, messageKey: string, typeKey: string, identifier: string): string {
    const message = translate(translateService, messageKey, { type: typeKey, identifier });

    return message;
  }
}

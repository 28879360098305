import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { InstrumentType, LanguageInterface, AccessLevel, VersionTemplate, UserPermission, ConfirmationDialogData, ConfirmationDialogResponse } from 'src/app/models';
import { UserPermissionService, DataService, NotificationService, SelectionAndCacheService, InstrumentTypeSelectionState } from 'src/app/services';
import { wholeNumberEntered } from 'src/app/shared/utils';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';


@Component({
  selector: 'app-language-interface-versions',
  templateUrl: './language-interface-versions.component.html',
  styleUrls: ['./language-interface-versions.component.scss']
})
export class LanguageInterfaceVersionsComponent implements OnInit, OnDestroy {

  public languageInterfaces: LanguageInterface[] = [];
  public filteredInterfaces: LanguageInterface[] = [];

  private subscription: Subscription = new Subscription();
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;
  private SELECTED_INSTRUMENT_TYPE: InstrumentType;

  get selectedInstrumentType(): InstrumentType {
    return this.SELECTED_INSTRUMENT_TYPE;
  }

  set selectedInstrumentType(instrumentType: InstrumentType) {
    if (this.SELECTED_INSTRUMENT_TYPE !== instrumentType) {
      this.SELECTED_INSTRUMENT_TYPE = instrumentType;
      this.filterLanguageInterfaces();
    }
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userPermissionService: UserPermissionService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) { }

  ngOnInit(): void {
    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
        this.selectedInstrumentType = selectionState.instrumentType;
        this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageLanguageFiles], [UserPermission.ViewEverything], this.selectedInstrumentType?.instrumentTypeId);
      }));
    }));


    this.loadLanguageInterfaces();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async onAddVersion(template: TemplateRef<unknown>): Promise<void> {
    const dialogRef = this.dialog.open<unknown, MatDialogConfig, VersionTemplate | 'false'>(template, { width: '75%' });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result !== 'false') {
      const languageInterface: LanguageInterface = {
        instrumentTypeId: this.selectedInstrumentType?.instrumentTypeId,
        version: result.version,
        languageInterfaceId: 0,
        createdDate: null,
        createdBy: null,
        lastModifiedDate: null,
        lastModifiedBy: null
      };

      this.subscription.add(this.dataService.addLanguageInterface(languageInterface).subscribe(id => {
        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.LanguageInterfaceKey });
        this.loadLanguageInterfaces();
      }));
    }
  }

  public async onDeleteVersion(languageInterface: LanguageInterface): Promise<void> {
    const options: ConfirmationDialogData = {
      title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.DeleteTitleKey, TranslateConstants.LanguageInterfaceKey),
      cancelText: translate(this.translateService, TranslateConstants.CancelKey),
      confirmText: translate(this.translateService, TranslateConstants.DeleteKey),
      message: TranslateConstants.BuildTypeMessageWithIdentifier(this.translateService, TranslateConstants.DeleteWarningKey, TranslateConstants.LanguageInterfaceKey, languageInterface.version.toString())
    };

    const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
      data: options
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    if (response.result) {
      this.subscription.add(this.dataService.deleteLanguageInterface(languageInterface.languageInterfaceId).subscribe(() => {
        this.notificationService.success(TranslateConstants.DeleteSuccessKey, { type: TranslateConstants.LanguageInterfaceKey });
        this.loadLanguageInterfaces();
      }));
    }
  }

  public onVersionKeyPress(e: KeyboardEvent): boolean {
    return wholeNumberEntered(e);
  }

  private loadLanguageInterfaces(): void {
    this.subscription.add(this.dataService.getLanguageInterfaces().subscribe((interfaces: LanguageInterface[]) => {
      this.languageInterfaces = interfaces;
      this.filterLanguageInterfaces();
    }));
  }

  private filterLanguageInterfaces(): void {
    this.filteredInterfaces = this.languageInterfaces?.filter(i => i.instrumentTypeId === this.selectedInstrumentType?.instrumentTypeId);
  }
}

<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ 'ClearUpgrades.ClearUpgrades' | translate }}</h1>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="clearUpgradeForm">
      <p class="dialog-message" [innerHTML]="data.message"></p>
      <b class="dialog-message">{{ 'ClearUpgrades.RequiredStepsMessage' | translate }}</b>
      <mat-form-field class="full-width">
        <input matInput style="display: none">
        <mat-checkbox required labelPosition="after" formControlName="disabledUpgradeRequestFunction">
          {{ 'ClearUpgrades.UpgradeRequestFunctionDisabled' | translate }}
        </mat-checkbox>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput style="display: none">
        <mat-checkbox required labelPosition="after" formControlName="clearedRequestQueue">
          {{ 'ClearUpgrades.RequestQueueCleared' | translate }}</mat-checkbox>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput style="display: none">
        <mat-checkbox required labelPosition="after" formControlName="selectedItemsIndividually">
          {{ 'ClearUpgrades.SelectedIndividualAssaysAndLanguages' | translate }}</mat-checkbox>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput style="display: none">
        <mat-checkbox required labelPosition="after" formControlName="finalConfirmation">
          {{ 'ClearUpgrades.FinalConfirmation' | translate }}</mat-checkbox>
      </mat-form-field>
    </form>
  </div>

  <div mat-dialog-actions class="actions">
    <button mat-button class="btn btn-cancel" (click)="cancel()">{{ 'Global.Cancel' | translate }}</button>
    <button mat-button class="btn" [disabled]="clearUpgradeForm.invalid" (click)="confirm()">
      {{ 'ClearUpgrades.ConfirmClear' | translate }}</button>
  </div>
</div>

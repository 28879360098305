<div class="page">
  <div class="page-grid">
    <h1>{{ 'SerialNumberOverrides.SerialNumberOverrides' | translate }}</h1>
    <div class="action-bar">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="addSerialNumberOverride()">
        <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
      </button>
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="requestImport()">
        <mat-icon>import_export</mat-icon>{{ 'SerialNumberOverrides.RequestImport' | translate }}
      </button>
    </div>
    <div>
      <app-serialnumber-overrides-list [serialNumberOverridesResult]="serialNumberOverridesResult"
        [mode]="ListMode.Multiselect" (dataRequested)="dataRequested($event)"
        (editSerialNumberOverride)="onEditSerialNumberOverride($event)"
        (deleteSerialNumberOverride)="onDeleteSerialNumberOverride($event)" (refreshData)="getAllData()"
        [accessLevel]="accessLevel" (checkedOverrides)="onCheckedOverrides($event)">
      </app-serialnumber-overrides-list>
    </div>
    <div class="action-bar" *ngIf="selectedInstrumentType">
      <div class="action-bar-left">
        <button *ngIf="canManageAllInstruments" mat-raised-button color="warn" [disabled]="!hasOverrides()"
          (click)="clearOverrides()">
          <mat-icon>clear</mat-icon>{{ 'SerialNumberOverrides.ClearOverrides' | translate }}
        </button>
      </div>


      {{ 'SerialNumberOverrides.SerialNumberOverridesDisclaimer' | translate }}

      <div class="action-bar-right">
        <button mat-raised-button color="primary" (click)="setOverrides()" *ngIf="canAddUpdate"
          [disabled]="!hasOverrides()">
          <mat-icon>add</mat-icon>{{ 'SerialNumberOverrides.Override' | translate }}
        </button>
      </div>
    </div>

  </div>
</div>

<div class="dialog-root">
  <div class="dialog-header">
    <h2 *ngIf="canAddUpdate">{{ (firmware?.firmwareId ? 'Global.Edit' : 'Global.New') | translate }} {{
      firmwareTypeTranslateKey | translate }} {{ 'Global.File' | translate }} {{ firmware?.firmwareId ?
      ('Global.IdLabel'
      | translate: {id:firmware.firmwareId}) : '' }}</h2>
    <h2 *ngIf="!canAddUpdate">
      {{ 'Global.View' | translate }} {{ 'Firmwares.Firmware' | translate }} {{ 'Global.File' | translate }} {{
      firmware?.firmwareId ?
      ('Global.IdLabel' | translate: {id:firmware.firmwareId}) : '' }}</h2>
    <button mat-icon-button *ngIf="firmware.firmwareId" class="refresh-dialog-btn" (click)="refreshFirmware(firmware)">
      <mat-icon>refresh</mat-icon>
    </button>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="editFirmwareForm">
    <mat-dialog-content>
      <mat-tab-group #tabs class="mat-tab-fill-height" [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false">
        <mat-tab [label]="'Global.Details' | translate">
          <div class="field-container">
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Title' | translate }}</mat-label>
              <input formControlName="title" matInput [placeholder]="'Global.Title' | translate" type="text" #title>
              <mat-error *ngIf="editFirmwareForm.get('title').hasError('required')">
                {{ 'Firmwares.FormErrors.TitleRequired' | translate }}</mat-error>
              <mat-error *ngIf="editFirmwareForm.get('title').hasError('maxlength')">
                {{ 'Firmwares.FormErrors.TitleMaxLength' | translate: {maxLength:
                editFirmwareForm.get('title').errors.maxlength.requiredLength} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showLnItemNumber" class="full-width">
              <mat-label>{{ 'Global.LnItemNumber' | translate }}</mat-label>
              <input formControlName="lnItemNumber" matInput [placeholder]="'Global.LnItemNumber' | translate"
                type="text" #lnItemNumber>
              <mat-error *ngIf="editFirmwareForm.get('lnItemNumber').hasError('required')">
                {{ 'Firmwares.FormErrors.LnItemNumberRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="editFirmwareForm.get('lnItemNumber').hasError('maxlength')">
                {{ 'Firmwares.FormErrors.LnItemNumberMaxLength' | translate: {maxLength:
                editFirmwareForm.get('lnItemNumber').errors.maxlength.requiredLength} }}</mat-error>
            </mat-form-field>
            <div class="version-container">
              <mat-label class="field-label">{{ 'Global.Version' | translate }}</mat-label>
              <mat-form-field>
                <input formControlName="major" matInput [placeholder]="'Global.Major' | translate" type="number" #major
                  (change)="onDependencyValueChanged(firmware?.firmwareId, editFirmwareForm.get('major').value,  editFirmwareForm.get('minor').value, editFirmwareForm.get('revision').value, editFirmwareForm.get('instrumentTypeId').value, editFirmwareForm.get('instrumentFileTypeId').value, getSelectedOptions(assayInterfaceIds), getSelectedOptions(languageInterfaceIds))">
                <mat-error *ngIf="editFirmwareForm.get('major').hasError('required')">
                  {{ 'Firmwares.FormErrors.VersionMajorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editFirmwareForm.get('major').hasError('min')">
                  {{ 'Firmwares.FormErrors.VersionMajorMin' | translate: {min:
                  editFirmwareForm.get('major').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input formControlName="minor" matInput [placeholder]="'Global.Minor' | translate" type="number" #minor
                  (change)="onDependencyValueChanged(firmware?.firmwareId, editFirmwareForm.get('major').value,  editFirmwareForm.get('minor').value, editFirmwareForm.get('revision').value, editFirmwareForm.get('instrumentTypeId').value, editFirmwareForm.get('instrumentFileTypeId').value, getSelectedOptions(assayInterfaceIds), getSelectedOptions(languageInterfaceIds))">
                <mat-error *ngIf="editFirmwareForm.get('minor').invalid">
                  {{ 'Firmwares.FormErrors.VersionMinorRequired' | translate }}</mat-error>
                <mat-error *ngIf="editFirmwareForm.get('minor').hasError('min')">
                  {{ 'Firmwares.FormErrors.VersionMinorMin' | translate: {min:
                  editFirmwareForm.get('minor').errors.min.min} }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input formControlName="revision" matInput [placeholder]="'Global.Revision' | translate" type="number"
                  #revision
                  (change)="onDependencyValueChanged(firmware?.firmwareId, editFirmwareForm.get('major').value,  editFirmwareForm.get('minor').value, editFirmwareForm.get('revision').value, editFirmwareForm.get('instrumentTypeId').value, editFirmwareForm.get('instrumentFileTypeId').value, getSelectedOptions(assayInterfaceIds), getSelectedOptions(languageInterfaceIds))">
                <mat-error *ngIf="editFirmwareForm.get('revision').invalid">
                  {{ 'Firmwares.FormErrors.VersionRevisionRequired' | translate }}</mat-error>
                <mat-error *ngIf="editFirmwareForm.get('revision').hasError('min')">
                  {{ 'Firmwares.FormErrors.VersionRevisionMin' | translate: {min:
                  editFirmwareForm.get('revision').errors.min.min} }}</mat-error>
              </mat-form-field>
            </div>
            <div class="instrument-type-container">
              <mat-form-field class="full-width" [class.span-all-columns]="!showModelSelection">
                <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
                <mat-select formControlName="instrumentTypeId" #instrumentTypeId
                  (selectionChange)="onDependencyValueChanged(firmware?.firmwareId, editFirmwareForm.get('major').value,  editFirmwareForm.get('minor').value, editFirmwareForm.get('revision').value, editFirmwareForm.get('instrumentTypeId').value, editFirmwareForm.get('instrumentFileTypeId').value, getSelectedOptions(assayInterfaceIds), getSelectedOptions(languageInterfaceIds))">
                  <mat-option *ngFor="let type of instrumentTypes" [value]="type.instrumentTypeId">{{type.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="editFirmwareForm.get('instrumentTypeId').invalid">
                  <mat-error *ngIf="editFirmwareForm.get('instrumentTypeId').invalid">
                    {{ 'Firmwares.FormErrors.InstrumentTypeRequired' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="showModelSelection">
                <mat-label>{{ 'Global.InstrumentTypeModel' | translate }}</mat-label>
                <mat-select formControlName="modelIds" required multiple>
                  <mat-option [value]="model.modelId" *ngFor="let model of modelsForSelectedInstrumentType">
                    {{ model.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.FileType' | translate }}</mat-label>
              <mat-select formControlName="instrumentFileTypeId" #fileType
                (selectionChange)="onDependencyValueChanged(firmware?.firmwareId, editFirmwareForm.get('major').value,  editFirmwareForm.get('minor').value, editFirmwareForm.get('revision').value, editFirmwareForm.get('instrumentTypeId').value, editFirmwareForm.get('instrumentFileTypeId').value, getSelectedOptions(assayInterfaceIds), getSelectedOptions(languageInterfaceIds))">
                <mat-option *ngFor="let type of instrumentFileTypes" [value]="type.instrumentFileTypeId">{{type.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="editFirmwareForm.get('instrumentFileTypeId').invalid">
                {{ 'Firmwares.FormErrors.FileTypeRequired' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="published-container">
              <mat-form-field class="full-width">
                <mat-label>{{ 'Global.PublishedFlag' | translate }}</mat-label>
                <input matInput readonly [placeholder]="'Global.PublishedFlag' | translate" type="text"
                  [value]="getPublishedFlagDisplayValue(firmware.publishedFlag)">
              </mat-form-field>
              <button mat-raised-button
                *ngIf="canAddUpdate && firmware?.firmwareId && firmware?.fileUri && firmware.publishedFlag !== PublishedFlag.PreviousPublished"
                style="margin-left: 10px; box-sizing: content-box;"
                (click)="onPublishFirmware(file?.files?.length > 0 ? file.files[0] : null)">
                {{ (isFirmwarePendingOrPublished() ? 'Global.Unpublish' : 'Global.Publish') | translate }}</button>
            </div>

            <mat-label class="field-label" for="firmware_file">{{ 'Global.File' | translate }}</mat-label>
            <div class="file-container">
              <label class="file-label noselect"
                *ngIf="canAddUpdate && (firmware?.publishedFlag === PublishedFlag.Unpublished || firmware?.publishedFlag === PublishedFlag.Published)"
                for="firmware_file">
                {{ (firmware?.fileUri ? 'Global.Replace' : 'Global.Choose') | translate }} {{ 'Global.File' | translate
                }}</label>
              <span class="noselect" *ngIf="file.files.length === 1" [matTooltip]="file.files[0]?.name">
                {{ 'Global.FileUploaded' | translate: {fileName: file.files[0].name} }}</span>
              <div class="file-info">
                <div class="download-file">
                  <a href="{{firmware?.fileUri}}" class="noselect" [matTooltip]="firmware?.fileUri"><span
                      *ngIf="file.files.length === 0 && firmware?.fileUri">
                      {{ 'Global.Download' | translate }} {{ 'Global.File' | translate }}</span></a>
                  <div class="file-status" [class.success]="fileAvailable" [class.fail]="!fileAvailable"
                    *ngIf="isFirmwarePendingOrPublished()">
                    <mat-icon class="noselect"
                      [matTooltip]="(fileAvailable ? 'Global.FileCDNAvailable' : 'Global.FileCDNUnavailable') | translate">
                      {{fileAvailable ? 'verified' : 'warning'}}</mat-icon>
                  </div>
                </div>
                <div *ngIf="firmware?.fileUri && (!file || file?.files.length === 0)" class="md5-value">
                  <div class="label">{{ 'Global.MD5Label' | translate }}</div>
                  <div>{{firmware?.mD5 || '-'}}</div>
                </div>
              </div>
              <button mat-icon-button *ngIf="firmware?.publishedFlag === PublishedFlag.PendingPublish"
                (click)="checkFileAvailability(firmware)">
                <mat-icon>refresh</mat-icon>
              </button>
              <input placeholder="File" type="file" #file id="firmware_file"
                (change)="fileChanged($event.target.files)">
              <mat-form-field *ngIf="signatureVerificationEnabled && file?.files?.length > 0"
                class="signature-validation-key">
                <mat-label>{{ 'Global.SignatureVerificationKey' | translate }}</mat-label>
                <mat-select formControlName="signatureVerificationKeyName" required>
                  <mat-option *ngFor="let keyName of signatureVerificationKeyNames" [value]="keyName">{{keyName}}
                  </mat-option>
                </mat-select>
                <mat-hint>{{ 'Global.SignatureVerificationKeyHint' | translate }}</mat-hint>
                <mat-error *ngIf="editFirmwareForm.get('signatureVerificationKeyName').invalid">
                  {{ 'Firmwares.FormErrors.SignatureVerificationKeyRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-label *ngIf="canAddUpdate && firmware?.publishedFlag === PublishedFlag.Unpublished">
              {{ 'Firmwares.FormErrors.FileUploadError' | translate }}</mat-label>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.Notes' | translate }}</mat-label>
              <textarea matInput #notes [placeholder]="'Global.Notes' | translate" type="text"
                formControlName="notes"></textarea>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedDate' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedDate' | translate" type="text" #name
                [value]="firmware?.createdDate" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.CreatedBy' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.CreatedBy' | translate" type="text" #name
                [value]="firmware?.createdBy" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedDate' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.LastModifiedDate' | translate" type="text" #name
                [value]="firmware?.lastModifiedDate" [disabled]="true">
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{ 'Global.LastModifiedBy' | translate }}</mat-label>
              <input matInput [placeholder]="'Global.LastModifiedBy' | translate" type="text" #name
                [value]="firmware?.lastModifiedBy" [disabled]="true">
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab *ngIf="showCountries">
          <ng-template mat-tab-label>
            <div [matBadge]="countryIds?.length ?? 0" matBadgeOverlap="false"
              [matBadgeColor]="(countryIds?.length ?? 0) > 0 ? 'primary' : 'warn'">{{
              'Global.Countries' | translate }}</div>
          </ng-template>
          <div class="field-container">
            <div class="countries-selection">
              <div class="restriction-notice" *ngIf="!countryIds || countryIds.length === 0">
                {{ 'Firmwares.CountrySelectionRequiredNotice' | translate }}
              </div>
              <div class="countries-header">
                <button mat-icon-button [matMenuTriggerFor]="menu" class="multi-select">
                  <div>
                    <mat-icon *ngIf="countryIds?.length > 0 && countryIds?.length === availableCountries?.length">
                      check_box
                    </mat-icon>
                    <mat-icon *ngIf="countryIds?.length > 0  && countryIds?.length !== availableCountries?.length">
                      indeterminate_check_box</mat-icon>
                    <mat-icon *ngIf="countryIds?.length <= 0">check_box_outline_blank</mat-icon>
                    <mat-icon>arrow_drop_down</mat-icon>
                  </div>
                </button>
                <mat-menu #menu="matMenu">
                  <button class="context-option" mat-menu-item (click)="selectAllCountries()">
                    <mat-icon [class.transparent]="countryIds?.length !== availableCountries?.length">check
                    </mat-icon>
                    {{ 'Firmwares.Countries.Selection.All' | translate: {count: availableCountries?.length
                    |
                    number} }}
                  </button>
                  <button class="context-option" mat-menu-item (click)="deselectAllCountries()">
                    <mat-icon [class.transparent]="countryIds?.length > 0">check</mat-icon>
                    {{ 'Firmwares.Countries.Selection.Clear' | translate }}
                  </button>
                </mat-menu>
                <div class="field-label">{{ 'Firmwares.AvailableCountries' | translate }}
                  ({{availableCountries?.length}})</div>
              </div>
              <mat-selection-list formControlName="countryIds">
                <mat-list-option checkboxPosition="before" *ngFor="let country of availableCountries"
                  [value]="country.countryId">
                  {{country.name}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="requiresDependencies" [label]="'Global.Dependencies' | translate">
          <div class="field-container">
            <div class="selection-list-container">
              <div>
                <mat-label class="field-label">{{ 'Firmwares.CompatibleAssayInterfaces' | translate }}</mat-label>
                <div class="compatible-list">
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
                    (change)="noAssayInterfacesCheckChanged($event.checked)"
                    [checked]="!assayInterfaceIds || assayInterfaceIds?.length === 0">{{ 'Global.None' | translate }}
                  </mat-checkbox>
                  <mat-checkbox [disabled]="!canAddUpdate" #allCompatibleAssayInterfaces labelPosition="after"
                    *ngIf="availableAssayInterfaces.length !== 0"
                    (change)="allAssayInterfacesCheckChanged($event.checked)"
                    [checked]="assayInterfaceIds && assayInterfaceIds?.length === availableAssayInterfaces.length">
                    {{ 'Global.All' | translate }}
                  </mat-checkbox>
                  <mat-selection-list #compatibleAssayInterfaces formControlName="assayInterfaceIds"
                    (selectionChange)="getValuesFromFormAndLoadAvailableAssays()">
                    <mat-list-option checkboxPosition="before" *ngFor="let interface of availableAssayInterfaces"
                      [value]="interface.assayInterfaceId">
                      {{interface.version}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
              <div>
                <mat-label class="field-label">{{ 'Firmwares.CompatibleAssays' | translate }}</mat-label>
                <div class="compatible-list">
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
                    (change)="noAssaysCheckedChanged($event.checked)" [checked]="!assayIds || assayIds?.length === 0">
                    {{ 'Global.None' | translate }}</mat-checkbox>
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after" *ngIf="availableAssays.length !== 0"
                    (change)="allAssaysCheckedChanged($event.checked)"
                    [checked]="assayIds && assayIds?.length === availableAssays.length">
                    {{ 'Global.All' | translate }}
                  </mat-checkbox>
                  <mat-selection-list #compatibleAssays formControlName="assayIds">
                    <mat-list-option checkboxPosition="before" *ngFor="let assay of availableAssays"
                      [value]="assay.assayId">
                      <div class="properties-grid">
                        <div>
                          {{ getAssayDefinitionCode(assay.assayDefinitionId)}}
                        </div>
                        <div>
                          {{assay.title}}
                        </div>
                        <div class="sublabel">
                          {{ 'Firmwares.InterfaceVersion' | translate }}
                          {{assay.interfaceVersion ? assay.interfaceVersion :
                          getAssayInterfaceVersion(assay.assayInterfaceId)}}
                        </div>
                        <div class="sublabel">
                          {{ 'Firmwares.Version' | translate }}
                          {{assay.version.major}}.{{assay.version.minor}}.{{assay.version.revision}}
                        </div>
                      </div>
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
            <div class="selection-list-container">
              <div>
                <mat-label class="field-label">{{ 'Firmwares.CompatibleLanguageInterfaces' | translate }}</mat-label>
                <div class="compatible-list">
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
                    (change)="noLanguageInterfacesCheckChanged($event.checked)"
                    [checked]="!languageInterfaceIds || languageInterfaceIds?.length === 0">{{ 'Global.None' | translate
                    }}
                  </mat-checkbox>
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
                    *ngIf="availableLanguageInterfaces.length !== 0"
                    (change)="allLanguageInterfacesCheckChanged($event.checked)"
                    [checked]="languageInterfaceIds && languageInterfaceIds?.length === availableLanguageInterfaces.length">
                    {{ 'Global.All' | translate }}
                  </mat-checkbox>
                  <mat-selection-list #compatibleLanguageInterfaces formControlName="languageInterfaceIds"
                    (selectionChange)="getValuesFromFormAndLoadAvailableLanguages()">
                    <mat-list-option checkboxPosition="before" *ngFor="let interface of availableLanguageInterfaces"
                      [value]="interface.languageInterfaceId">
                      {{interface.version}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
              <div>
                <mat-label class="field-label">{{ 'Firmwares.CompatibleLanguages' | translate }}</mat-label>
                <div class="compatible-list">
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after"
                    (change)="noLanguageCheckChanged($event.checked)"
                    [checked]="!languageIds || languageIds?.length === 0">{{ 'Global.None' | translate }}</mat-checkbox>
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after" *ngIf="availableLanguages.length !== 0"
                    (change)="allLanguageCheckChanged($event.checked)"
                    [checked]="languageIds && languageIds?.length === availableLanguages.length">
                    {{ 'Global.All' | translate }}
                  </mat-checkbox>
                  <mat-selection-list #compatibleLanguages formControlName="languageIds">
                    <mat-list-option checkboxPosition="before" *ngFor="let language of availableLanguages"
                      [value]="language.languageId">
                      <div class="properties-grid">
                        <div>
                          {{ getLanguageDefinitionCode(language.languageDefinitionId)}}
                        </div>
                        <div>
                          {{language.title}}
                        </div>
                        <div class="sublabel">
                          {{ 'Firmwares.InterfaceVersion' | translate }}
                          {{language.interfaceVersion ? language.interfaceVersion :
                          getLanguageInterfaceVersion(language.languageInterfaceId)}}
                        </div>
                        <div class="sublabel">
                          {{ 'Firmwares.Version' | translate }}
                          {{language.version.major}}.{{language.version.minor}}.{{language.version.revision}}
                        </div>
                      </div>
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>

            <div class="selection-list-container">
              <div>
                <mat-label class="field-label">{{ 'Firmwares.CompatibleFirmwares' | translate }}</mat-label>
                <div class="compatible-list">
                  <mat-checkbox *ngIf="!(availableFirmwares?.length > 0)" [disabled]="!canAddUpdate"
                    labelPosition="after" (change)="noCompatibleCheckChanged($event.checked)"
                    [checked]="!compatibleFirmwareIds || compatibleFirmwareIds?.length === 0">
                    {{ 'Global.None' | translate }}
                  </mat-checkbox>
                  <mat-checkbox [disabled]="!canAddUpdate" labelPosition="after" *ngIf="availableFirmwares.length !== 0"
                    (change)="allCompatibleCheckChanged($event.checked)"
                    [checked]="compatibleFirmwareIds && compatibleFirmwareIds?.length === availableFirmwares.length">
                    {{ 'Global.All' | translate }}
                  </mat-checkbox>
                  <mat-selection-list #compatible formControlName="compatibleFirmwareIds">
                    <mat-list-option checkboxPosition="before" *ngFor="let firmware of availableFirmwares"
                      [value]="firmware.firmwareId">
                      <div class="properties-grid">
                        <div>
                          {{firmware.version?.major}}.{{firmware.version?.minor}}.{{firmware.version?.revision}} </div>
                        <div>
                          {{getFileType(firmware.instrumentFileTypeId).name}}
                        </div>
                      </div>
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </form>

  <mat-dialog-actions *ngIf="canAddUpdate" align="end">
    <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>Cancel</button>
    <button mat-raised-button (click)="saveFirmware(file?.files?.length > 0 ? file.files[0] : null, true)"
      [disabled]="editFirmwareForm.invalid" color="primary"
      *ngIf="firmware.firmwareId || selectedTabIndex > 0 || (!requiresDependencies && !showCountries)">
      {{ (firmware.firmwareId ? 'Global.Save&Close' : 'Global.Create&Close') | translate }}</button>
    <button mat-raised-button (click)="saveFirmware(file?.files?.length > 0 ? file.files[0] : null, false)"
      [disabled]="editFirmwareForm.invalid" color="primary"
      *ngIf="firmware.firmwareId || selectedTabIndex > 0 || (!requiresDependencies && !showCountries)">
      {{ (firmware.firmwareId ? 'Global.Save' : 'Global.Create') | translate }}</button>
    <button mat-raised-button color="primary" cdkFocusInitial
      *ngIf="!firmware.firmwareId && selectedTabIndex === 0 && (requiresDependencies || showCountries)"
      (click)="selectedTabIndex = 1">{{
      'Global.Next' | translate }}</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
    <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
      {{ 'Global.Close' | translate }}
    </button>
  </mat-dialog-actions>
</div>

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { translate } from 'src/app/shared/translateServiceHelper';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private tapToDismissSettings = { tapToDismiss: true, disableTimeOut: true };

  constructor(private toastr: ToastrService, private translateService: TranslateService) { }

  /**
     * Displays a Success Toast notification with exact message provided. NO TRANSLATION OCCURS
     * @param {string} message Message text of the notification
     * @param {string} [title] Title text of the notification.
     * @param {Partial<IndividualConfig>} [override] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
     * @returns {void}
     */
  public successExact(message: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toastr.success(message, title, override);
  }

  /**
   * Displays a Success Toast notification with a message key and optional title key used to retrieve the translated value for the current language
   * @param {string} messageTranslationKey The translation key for retrieving the message text
   * @param {{ [key: string]: string }} messageInterpolationParamsDefinition Object (string, string) for supporting any string interpolation in the message. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated message text
   * @param {string} [titleTranslationKey] The translation key for retrieving the title text.
   * @param {{ [key: string]: string }} [titleInterpolationParamsDefinition] Object (string, string) for supporting any string interpolation in the title. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated title text
   * @param {Partial<IndividualConfig>} [override] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
   * @returns {void}
   */
  public success(messageTranslationKey: string, messageInterpolationParamsDefinition?: { [key: string]: string }, titleTranslationKey?: string, titleInterpolationParamsDefinition?: { [key: string]: string }, override?: Partial<IndividualConfig>): void {
    const message = translate(this.translateService, messageTranslationKey, messageInterpolationParamsDefinition);
    let title: string;
    if (titleTranslationKey) {
      title = translate(this.translateService, titleTranslationKey, titleInterpolationParamsDefinition);
    }
    this.toastr.success(message, title, override);
  }
  /**
       * Displays a Warning Toast notification with exact message provided. NO TRANSLATION OCCURS
       * @param {string} message Message text of the notification
       * @param {string} [title] Title text of the notification.
       * @param {Partial<IndividualConfig>} [override] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
       * @returns {void}
       */
  public warningExact(message: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.toastr.warning(message, title, override);
  }


  /**
   * Displays a Warning Toast notification with a message key and optional title key used to retrieve the translated value for the current language
   * @param {string} messageTranslationKey The translation key for retrieving the message text
   * @param {{ [key: string]: string }} messageInterpolationParamsDefinition Object (string, string) for supporting any string interpolation in the message. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated message text
   * @param {string} [titleTranslationKey] The translation key for retrieving the title text.
   * @param {{ [key: string]: string }} [titleInterpolationParamsDefinition] Object (string, string) for supporting any string interpolation in the title. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated title text
   * @param {Partial<IndividualConfig>} [override] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
   * @returns {void}
   */
  public warning(messageTranslationKey: string, messageInterpolationParamsDefinition?: { [key: string]: string }, titleTranslationKey?: string, titleInterpolationParamsDefinition?: { [key: string]: string }, override?: Partial<IndividualConfig>): void {
    const message = translate(this.translateService, messageTranslationKey, messageInterpolationParamsDefinition);
    let title: string;
    if (titleTranslationKey) {
      title = translate(this.translateService, titleTranslationKey, titleInterpolationParamsDefinition);
    }
    this.toastr.warning(message, title, override);
  }
  /**
       * Displays an Error Toast notification with exact message provided. NO TRANSLATION OCCURS
       * @param {string} message Message text of the notification
       * @param {string} [title] Title text of the notification.
       * @param {Partial<IndividualConfig>} [override] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
       * @returns {void}
       */
  public errorExact(message: string, title?: string, override: Partial<IndividualConfig> = this.tapToDismissSettings): void {
    this.toastr.error(message, title, override);
  }

  /**
   * Displays an Error Toast notification with a message key and optional title key used to retrieve the translated value for the current language
   * @param {string} messageTranslationKey The translation key for retrieving the message text
   * @param {{ [key: string]: string }} messageInterpolationParamsDefinition Object (string, string) for supporting any string interpolation in the message. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated message text
   * @param {string} [titleTranslationKey] The translation key for retrieving the title text.
   * @param {{ [key: string]: string }} [titleInterpolationParamsDefinition] Object (string, string) for supporting any string interpolation in the title. Key is the string required by the translation to interpolate.
   *            Value is the translation key for retrieving that value.
   *            e.g. { 'type' : 'AssayDefinitions.AssayDefinition' } - indicates that the 'AssayDefinitions.AssayDefinition' key should be retrieved from translation and inserted for the 'type' string in the translated title text
   * @param {Partial<IndividualConfig>} [override="{ tapToDismiss: true, disableTimeOut: true }"] Toastr configuration override for the Toast message, in case the default configuration does not meet requirements
   * @returns {void}
   */
  public error(messageTranslationKey: string, messageInterpolationParamsDefinition?: { [key: string]: string }, titleTranslationKey?: string, titleInterpolationParamsDefinition?: { [key: string]: string },
    override: Partial<IndividualConfig> = this.tapToDismissSettings): void {
    const message = translate(this.translateService, messageTranslationKey, messageInterpolationParamsDefinition);
    let title: string;
    if (titleTranslationKey) {
      title = translate(this.translateService, titleTranslationKey, titleInterpolationParamsDefinition);
    }
    this.toastr.error(message, title, override);
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { UrlConstants } from './constants/url-constants';
import { AboutDialogData, AboutDialogComponent } from './components/about/about.dialog';
import { MatDialog } from '@angular/material/dialog';
import { TranslateConstants } from './constants/translate-constants';
import { ConfigurationService, AppInsightsService, InactivityService, UserPermissionService, UserSettingsService, SelectionAndCacheService, InstrumentTypeSelectionState, NotificationService } from 'src/app/services';
import { AccessLevel, Configuration, Features, InstrumentTypeId, InstrumentTypeIdsSupportingCertificateAuthentication, LanguageUserSetting, UserPermission, UserSettingsConstants } from 'src/app/models';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountInfo, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { SettingsDialogComponent } from './components/settings-dialog/settings-dialog.component';
import { isFeatureEnabledForInstrumentTypeName } from './shared/utils';
import { FeatureFlagConstants } from './constants/featureFlag-constants';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public UrlConstants = UrlConstants;

  public isIframe = false;
  public apiNotFound = false;
  public arePopupsBlocked = false;
  public thirdPartyCookies = false;
  public isAuthenticated = false;
  public isAuthorized = true;
  public isMenuOpen = false;

  public isAMFManagement = false;
  public isFWManagement = false;
  public isLanguageManagement = false;
  public isAMFDeletion = false;
  public isLangDeletion = false;
  public canRefeshDeviceCertificates = false;
  public canUpgradeInstruments = false;
  public canViewReports = false;
  public canAccessManualApprovals = false;
  public canAccessUserManagement = false;
  public canManageSerialNumberOverrides = false;

  public firmwareFilesTranslateKey: string = TranslateConstants.AppNavFirmwareFilesKey;
  public upgradeInstrumentsTranslateKey: string = TranslateConstants.AppNavUpgradeInstrumentsKey;

  public userDisplayName: string;
  public unauthorizedTitleTranslationKey = TranslateConstants.UnauthorizedTitleKey;
  public unauthorizedMessageTranslationKey = TranslateConstants.UnauthorizedMessageKey;

  public showInstrumentTypeSelectors = true;
  public hideInstrumentTypeModelSelector = false;

  public isActiveUser = false;

  private subscription = new Subscription();
  private configuration: Configuration;
  private applicationVersion: string;
  private isProduction: boolean;
  private features: Features;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private appInsightsService: AppInsightsService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private httpClient: HttpClient,
    private inactivityService: InactivityService,
    private userPermissionService: UserPermissionService,
    private dialog: MatDialog,
    private userSettingsService: UserSettingsService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService,
    private router: Router,
    private location: Location,
    private notificationService: NotificationService) {
    this.isIframe = window !== window.parent && !window.opener;

  }

  ngOnInit(): void {
    this.configuration = this.configurationService.getConfiguration();

    if (!this.configuration.apiEndpoint.status) {
      this.apiNotFound = true;
      return;
    }

    this.applicationVersion = this.configuration.version;
    this.isProduction = this.configuration.production;

    this.appInsightsService.addTelemetryInitializer('QO IoT - Internal', location.origin);

    this.subscription.add(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAuthorizationStatus();
        if (!this.isAuthenticated) {
          this.subscription.add(this.authService.loginPopup().subscribe(() => {
            const msalUser = this.checkAndSetActiveAccount();

            this.checkAuthorizationStatus();

            if (msalUser) {
              this.appInsightsService.logEvent('LoginSuccess', { User: msalUser.username });
              void this.initializeAsync();
            }
          }));
        } else {
          void this.initializeAsync();
        }
      }));

    this.subscription.add(this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.appInsightsService.clearUserId();

        if (result.error instanceof AuthError) {
          if (result.error.errorMessage.indexOf('AADSTS50058') > -1) {
            this.thirdPartyCookies = true;
            return;
          }

          if (result.error.errorCode === 'popup_window_error') {
            this.arePopupsBlocked = true;
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }

  retryLogin(): void {
    void this.authService.loginPopup();
  }

  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }

  public logout(): void {
    this.authService.logout();
  }

  public showAbout(): void {
    const dialogData: AboutDialogData = {
      appVersion: this.applicationVersion,
      showLicensesLink: this.isProduction
    };

    this.dialog.open(AboutDialogComponent, {
      data: dialogData
    });
  }

  public showSettings(): void {

    this.dialog.open(SettingsDialogComponent);
  }

  private checkAuthorizationStatus(): void {
    this.isAuthenticated = !!this.authService.instance.getActiveAccount();
  }

  private async initializeAsync(): Promise<void> {
    this.initializeUserDisplayName();
    const account = this.authService.instance.getActiveAccount();
    this.appInsightsService.setUserId(account.username);

    try {
      await this.configurationService.initializeClientConfig(this.httpClient);
      const clientConfiguration = this.configurationService.getClientConfiguration();
      this.features = clientConfiguration.features;

      this.isAuthorized = true;
      this.inactivityService.refreshSettings();
    } catch (errorStatus) {
      this.isAuthorized = errorStatus !== 403;
    }

    if (this.isAuthorized) {

      void this.userPermissionService.initializePermissions();
      void this.userSettingsService.initializeUserSettings();
      void this.selectionAndCacheService.initialize();

      this.subscription.add(this.userSettingsService.isReadyEvent$.subscribe(() => {
        this.setApplicationLanguage();
      }));

      this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(() => {
        this.isActiveUser = this.userPermissionService.getAccessLevel([], [UserPermission.ViewEverything]) === AccessLevel.ViewOnly;
        this.canAccessUserManagement = this.userPermissionService.getAccessLevel([UserPermission.ManageUsers]) === AccessLevel.AddUpdate;
        // Viewing reports just needs the "global" ManageAllInstruments claim, NOT instrument type specific
        this.canViewReports = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments]) === AccessLevel.AddUpdate;

        const path = this.location.path().replace('/', '');

        if (!this.canAccessUserManagement && path === UrlConstants.userManagementUrl) {
          void this.router.navigate([UrlConstants.instrumentsUrl]);
        } else if (!this.isActiveUser && this.canAccessUserManagement && path !== UrlConstants.userManagementUrl) {
          void this.router.navigate([UrlConstants.userManagementUrl]);
        }

        this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe(async (selectionState: InstrumentTypeSelectionState) => {
          await this.handleSelectedInstrumentTypeAndModelChange(selectionState);
        }));
      }));

      this.subscription.add(this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
        this.updateShowInstrumentTypeSelectors();
      }));
    }
  }

  private async handleSelectedInstrumentTypeAndModelChange(selectionState: InstrumentTypeSelectionState): Promise<void> {

    const instrumentType = selectionState.instrumentType;
    if (!instrumentType) {
      return;
    }


    // there are views specifically limited to ManageAllInstruments and not both All and Single
    const canManageAllInstruments = this.userPermissionService.getAccessLevel([UserPermission.ManageAllInstruments], [], selectionState.instrumentType?.instrumentTypeId) === AccessLevel.AddUpdate;
    const canUpdateInstruments = this.userPermissionService.getAccessLevel([UserPermission.UpdateAllInstruments, UserPermission.UpdateSingleInstrument], [], selectionState.instrumentType?.instrumentTypeId) === AccessLevel.AddUpdate;
    const canManuallyApproveInstruments = this.userPermissionService.getAccessLevel([UserPermission.InstrumentManualApproval], [], selectionState.instrumentType?.instrumentTypeId) === AccessLevel.AddUpdate;

    this.isAMFManagement = this.isActiveUser && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFManagementSuffix);
    this.isFWManagement = this.isActiveUser && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.FWManagementSuffix);
    this.isLanguageManagement = this.isActiveUser && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LanguageManagementSuffix);
    this.isLangDeletion = this.isActiveUser && canUpdateInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.LangDeletion);
    this.isAMFDeletion = this.isActiveUser && canUpdateInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFDeletion);
    this.canRefeshDeviceCertificates = this.isActiveUser && canManageAllInstruments && InstrumentTypeIdsSupportingCertificateAuthentication.includes(instrumentType.instrumentTypeId);
    this.canAccessManualApprovals = this.isActiveUser && canManuallyApproveInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.ManualApproval);
    this.canManageSerialNumberOverrides = this.isActiveUser && canManageAllInstruments;
    this.canUpgradeInstruments = this.isActiveUser && canUpdateInstruments && isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.UpgradeInstrumentSuffix);

    if (instrumentType.instrumentTypeId === InstrumentTypeId.Vision.valueOf()) {
      this.firmwareFilesTranslateKey = TranslateConstants.AppNavModFilesKey;
      this.upgradeInstrumentsTranslateKey = TranslateConstants.AppNavDeploySoftwareKey;
    } else {
      this.firmwareFilesTranslateKey = TranslateConstants.AppNavFirmwareFilesKey;
      this.upgradeInstrumentsTranslateKey = TranslateConstants.AppNavUpgradeInstrumentsKey;
    }

    const path = this.location.path().replace('/', '');
    let reroute = false;

    if (!this.isAMFManagement && (path === UrlConstants.assayDefinitionsUrl ||
      path === UrlConstants.assayFilesUrl ||
      path === UrlConstants.assayVersionsUrl)) {
      reroute = true;
    }

    if (!this.isFWManagement && path === UrlConstants.firmwareFilesUrl) {
      reroute = true;
    }

    if (!this.isLanguageManagement && (path === UrlConstants.languageDefinitionsUrl ||
      path === UrlConstants.languageFilesUrl ||
      path === UrlConstants.languageVersionsUrl)) {
      reroute = true;
    }

    if (!this.isAMFDeletion && !this.isLangDeletion && path === UrlConstants.deleteFilesUrl) {
      reroute = true;
    }

    if (!this.canRefeshDeviceCertificates && path === UrlConstants.certificateRefreshUrl) {
      reroute = true;
    }

    if (!this.canAccessManualApprovals && path === UrlConstants.manualApprovalUrl) {
      reroute = true;
    }

    if (!this.canUpgradeInstruments && path === UrlConstants.upgradeInstrumentsUrl) {
      reroute = true;
    }

    if (!this.canManageSerialNumberOverrides && path === UrlConstants.serialNumberOverridesUrl) {
      reroute = true;
    }

    this.updateShowInstrumentTypeSelectors();

    if (reroute) {
      this.notificationService.success(TranslateConstants.InstrumentTypeRerouteKey, { type: instrumentType.name });
      await this.router.navigate([UrlConstants.instrumentsUrl]);
    }
  }

  private initializeUserDisplayName() {
    const msalUser = this.authService.instance.getActiveAccount();
    if (!msalUser) {
      return;
    }

    this.userDisplayName = msalUser.name || msalUser.username;
  }

  private checkAndSetActiveAccount(): AccountInfo {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }

    if (activeAccount) {
      console.debug('Active Account set');
    } else {
      console.debug('No account available to set as active account');
    }

    return activeAccount;
  }

  private setApplicationLanguage(): void {
    const languageSetting = this.userSettingsService.tryGetUserSetting(UserSettingsConstants.Language);

    let foundSupportedLanguage = false;
    if (languageSetting) {
      const languageOverride = (<LanguageUserSetting>languageSetting.settingsParsed).languageOverride;
      if (TranslateConstants.SupportedLanguagesMap.has(languageOverride)) {
        foundSupportedLanguage = true;
        console.debug(`Language setting '${languageOverride}' is supported, switching to that translation.`);
        this.translateService.use(languageOverride);
      } else {
        console.debug(`Language setting '${languageOverride}' is NOT supported, falling back to using browser language.`);
      }
    }

    if (!foundSupportedLanguage) {
      console.debug('No language setting exists, using browser language to determine translation to display.');

      const browserLanguages = navigator.languages;
      for (let index = 0; index < browserLanguages.length; index++) {
        const browserLanguage = browserLanguages[index];
        if (TranslateConstants.SupportedLanguagesMap.has(browserLanguage)) {
          foundSupportedLanguage = true;
          console.debug(`Browser language '${browserLanguage}' is supported, switching to that translation.`);
          this.translateService.use(browserLanguage);
          break;
        }
      }
      if (!foundSupportedLanguage) {
        console.debug(`No Browser languages '${browserLanguages.join(', ')}' supported, leaving as default translation.`);
      }
    }
  }

  private updateShowInstrumentTypeSelectors() {
    const path = this.location.path().replace('/', '');
    const isCurrentPageInstrumentTypeAgnostic = path === UrlConstants.assayDefinitionsUrl
      || path === UrlConstants.languageDefinitionsUrl
      || path === UrlConstants.reportsUrl
      || path === UrlConstants.userManagementUrl;

    const isCurrentPageInstrumentTypeModelAgnostic = path === UrlConstants.instrumentGroupsUrl;

    this.showInstrumentTypeSelectors = !isCurrentPageInstrumentTypeAgnostic;
    this.hideInstrumentTypeModelSelector = isCurrentPageInstrumentTypeModelAgnostic;
  }
}

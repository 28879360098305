import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpgradeRequestHistoryItem } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DeviceUpgradeRequestHistoryService {

  private apiUrl = this.configurationService.getApiUrl();
  private deviceUpgradeRequestHistoryUrl = '/DeviceUpgradeRequestHistory';
  private getUpgradeRequestHistoryBySerialNumberUrl = '/GetUpgradeRequestHistoryBySerialNumber';

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) { }

  public getUpgradeRequestHistory(serialNumber: string, instrumentTypeId: number): Observable<UpgradeRequestHistoryItem[]> {
    const query = `${this.apiUrl}${this.deviceUpgradeRequestHistoryUrl}${this.getUpgradeRequestHistoryBySerialNumberUrl}`;

    const httpParams = new HttpParams()
      .set('serialNumber', serialNumber)
      .set('instrumentTypeId', instrumentTypeId.toString());

    return this.httpClient.get<UpgradeRequestHistoryItem[]>(query, { params: httpParams });
  }
}

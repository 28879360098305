import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { MatchedConfirmationModalComponent, MatchedConfirmationModalInput } from 'src/app/components/matched-confirmation-modal/matched-confirmation-modal.component';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { DeviceRequest, InstrumentType, InstrumentTypeIdsSupportingCertificateAuthentication, InstrumentTypeModel, SearchRequest } from 'src/app/models';
import { DataService, InstrumentTypeSelectionState, SelectionAndCacheService, NotificationService } from 'src/app/services';
import { translate, translateWithInterpolateParams } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-certificate-refresh',
  templateUrl: './certificate-refresh.component.html',
  styleUrls: ['./certificate-refresh.component.scss']
})
export class CertificateRefreshComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public selectedInstrumentType: InstrumentType;
  private selectedModels: InstrumentTypeModel[];
  public canRefreshCertificate: boolean;

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) {

  }

  ngOnInit(): void {
    this.subscription.add(this.selectionAndCacheService.selectedInstrumentTypeAndModelsChangeEvent$.subscribe((selectionState: InstrumentTypeSelectionState) => {
      this.selectedInstrumentType = selectionState.instrumentType;
      this.canRefreshCertificate = InstrumentTypeIdsSupportingCertificateAuthentication.includes(this.selectedInstrumentType.instrumentTypeId);
      this.selectedModels = selectionState.models;
    }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public forceCertificateRefresh(): void {
    const searchRequest: SearchRequest = {
      instrumentTypeIds: [this.selectedInstrumentType.instrumentTypeId],
      modelIds: this.selectedModels?.map(m => m.modelId),
      countryIds: undefined,
      serialNumber: null,
      firmware: undefined,
      assay: undefined,
      language: undefined,
      pageNumber: 0,
      pageSize: 0,
      sortColumn: null,
      sortDirection: null,
      disableUnregisteredExactMatch: false
    };
    const deviceRequest: DeviceRequest = {
      queryByInstrumentSearchRequest: searchRequest
    };

    this.subscription.add(this.dataService.getDeviceCountFromCertificateRefreshRequest(deviceRequest)
      .subscribe(
        {
          next: async (count) => {
            const options: MatchedConfirmationModalInput = {
              title: translate(this.translateService, TranslateConstants.ForceCertificateRefreshTitleKey),
              cancelText: translate(this.translateService, TranslateConstants.CancelKey),
              confirmText: translate(this.translateService, TranslateConstants.ForceRefreshKey),
              message: translateWithInterpolateParams(this.translateService, TranslateConstants.ForceInstrumentTypeCertificateRefreshMessageKey, { instrumentType: this.selectedInstrumentType.name, deviceCount: count }),
              valueToMatch: count.toString()
            };

            const dialogRef = this.dialog.open<MatchedConfirmationModalComponent, MatchedConfirmationModalInput, boolean>(MatchedConfirmationModalComponent, {
              data: options
            });

            const result: boolean = await firstValueFrom(dialogRef.afterClosed());

            if (result) {
              this.subscription.add(this.dataService.forceCertificateRefresh(deviceRequest)
                .subscribe(
                  {
                    complete: () => {
                      this.notificationService.success(TranslateConstants.ForceCertificateRefreshSuccessKey);
                    },
                    error: (e) => {
                      this.notificationService.error(TranslateConstants.ForceCertificateRefreshFailedKey);
                    }
                  }));
            }
          },
          error: (e) => {
            this.notificationService.error(TranslateConstants.ForceCertificateRefreshFailedKey);
          }
        }));
  }
}

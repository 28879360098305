import { Component, Inject, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse, HttpEventType, HttpResponse, HttpEvent } from '@angular/common/http';

export interface UploadDialogData {
  title: string;
  observable: Observable<unknown>
}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnDestroy, AfterViewInit {
  private _uploadComplete = false;
  public get uploadComplete(): boolean {
    return this._uploadComplete;
  }
  public set uploadComplete(complete: boolean) {
    this._uploadComplete = complete;

    if (this._uploadComplete) {
      setInterval(() => {
        if (this.progress >= 100) {
          this.progress = 0;
        } else {
          this.progress += 1;
        }
      }, 100);
    }
  }

  private _progress = 0;
  public get progress(): number {
    return this._progress;
  }
  public set progress(value: number) {
    this._progress = value;

    if (this._progress > 100) {
      this._progress = 100;
    } else if (this._progress < 0) {
      this._progress = 0;
    }

    this.renderer.setStyle(this.leftSide.nativeElement, 'transform', `rotate(${this._progress * 3.6}deg)`);

    if (this.progress <= 50) {
      this.renderer.setStyle(this.pie.nativeElement, 'clip', null);
      this.renderer.setStyle(this.rightSide.nativeElement, 'display', 'none');
    } else {
      this.renderer.setStyle(this.rightSide.nativeElement, 'display', 'block');
      this.renderer.setStyle(this.pie.nativeElement, 'clip', 'rect(auto, auto, auto, auto)');
      this.renderer.setStyle(this.rightSide.nativeElement, 'transform', 'rotate(180deg)');
    }
  }

  private subscription = new Subscription();
  @ViewChild('leftSide', { static: true, read: ElementRef }) private leftSide: ElementRef;
  @ViewChild('rightSide', { static: true, read: ElementRef }) private rightSide: ElementRef;
  @ViewChild('pie', { static: true, read: ElementRef }) private pie: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
    private mdDialogRef: MatDialogRef<UploadDialogComponent>,
    private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.subscription.add(this.data.observable?.subscribe(
      {
        next: (event: HttpEvent<unknown>) => {
          if (event?.type === HttpEventType.UploadProgress) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.progress = Math.min(100, Math.round(100 * event.loaded / event.total));
            this.uploadComplete = this.progress >= 100;
          } else if (event instanceof HttpResponse && event?.type === HttpEventType.Response) {
            this.close(true, event.body);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.debug(error);
          this.close(false);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancel(): void {
    this.close(false);
  }

  private close(success: boolean, result?: unknown): void {
    this.mdDialogRef.close({ success, result } as UploadDialogResponse);
  }
}

export interface UploadDialogResponse {
  success: boolean;
  result?: unknown;
}

export interface Version {
  major: number;
  minor: number;
  revision: number;
  build?: number;
  display?: string;
}

export class VersionConstants {
  public static readonly V1_15: Version = { major: 1, minor: 15, revision: 0 };
  public static readonly V2: Version = { major: 2, minor: 0, revision: 0 };
}

export const formatVersion = (version: Version): string => {
  return `${version.major}.${version.minor}.${version.revision}`;
};

export const formatVersionForSort = (version: Version): string => {
  let formatted = '';

  try {
    formatted = version.major.toString().padStart(4, '0') +
      version.minor.toString().padStart(4, '0') +
      version.revision.toString().padStart(4, '0');
  } catch (e) {
    console.debug(e);
    formatted = '0.0.0';
  }

  return formatted;
};

export const convertToVersion = (versionString: string): Version => {
  const values = versionString.split('.');
  const major = parseInt(values[0]);
  const minor = values.length > 1 ? parseInt(values[1]) : 0;
  const revision = values.length > 2 ? parseInt(values[2]) : 0;

  const version: Version = {
    major: !isNaN(major) ? major : 0,
    minor: !isNaN(minor) ? minor : 0,
    revision: !isNaN(revision) ? revision : 0
  };

  return version;
};

<div class="page">
  <div class="grid">
    <h1>{{ 'Instruments.Instruments' | translate }}</h1>
    <div class="action-bar">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="onAddInstrument()">
        <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
      </button>
    </div>

    <div class="message">
      {{ 'Instruments.ExactSerialNumberSearch' | translate }}
    </div>
    <app-device-list [getDeviceInformationResult]="deviceInformationResult" [showOnlyUnregistered]="false"
      [instrumentTypeId]="selectedInstrumentType?.instrumentTypeId" (deviceDataRequested)="deviceDataRequested($event)"
      (editDevice)="onEditInstrument($event)" (deleteDevice)="onDeleteInstrument($event)" [accessLevel]="accessLevel"
      [serialNumberSelectable]="true"></app-device-list>
  </div>
</div>

<div class="dialog-root">
  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="confirmationForm">
      <p class="dialog-message" [innerHTML]="data.message"></p>

      <mat-form-field [formGroupName]="ConfirmationDialogComponent.AMF_FormKey" *ngIf="data.allowAMFForceDowngrade"
        class="full-width">
        <input matInput style="display: none">
        <mat-checkbox required labelPosition="after"
          [formControlName]="ConfirmationDialogComponent.AMF_ForcePossibleAMFDowngrade_FormKey">
          {{ 'Confirmation.ForceAssayDowngrade' | translate }}</mat-checkbox>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button class="btn" style="margin-right:1.0rem;" (click)="cancel()">{{ data.cancelText }}</button>
    <button mat-button class="btn btn-confirm" (click)="confirm()">{{ data.confirmText }}</button>
  </div>
</div>

<div>
  <div class="grid">
    <h1>{{ 'Reports.Reports' | translate }}</h1>
    <div *ngIf="showSofia2CSVExportDownload" class="download-section">
      <button mat-icon-button class="download-report-button" (click)="onGetSofia2CSVExport()">
        <span class="mat-button-wrapper">
          <mat-icon class="mat-icon">cloud_download</mat-icon>
        </span>
      </button>
      <ng-container *ngIf="currentSofia2CSVExportFound === true && currentSofia2CSVExport">
        <div>{{ 'Reports.CurrentInstrumentExportGenerated' | translate : { instrumentName: 'Sofia 2' } }}
          <div>
            {{ 'Reports.UTCLabel' | translate : {dateTime: (currentSofia2CSVExport?.dateGenerated
            | date: 'MM-dd-yyyy hh:mmaa' : 'UTC')} }}</div>
          <div>
            {{ 'Reports.LocalLabel' | translate : {dateTime: (currentSofia2CSVExport?.dateGenerated | date:
            'MM-dd-yyyy hh:mmaa')} }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentSofia2CSVExportFound === false && !currentSofia2CSVExport">
        {{ 'Reports.NoInstrumentExport' | translate : { instrumentName: 'Sofia 2' } }}
      </ng-container>
    </div>
    <button *ngIf="showSofia2CSVExportDownload" mat-raised-button color="primary" class="generate-csv"
      (click)="onGenerateExport(newExportTemplate, ExportType.InstrumentSofia2)">
      {{ 'Reports.GenerateNewInstrumentExport' | translate : { instrumentName: 'Sofia 2' } }}
    </button>

    <div *ngIf="showSavannaCSVExportDownload" class="download-section">
      <button mat-icon-button class="download-report-button" (click)="onGetSavannaCSVExport()">
        <span class="mat-button-wrapper">
          <mat-icon class="mat-icon">cloud_download</mat-icon>
        </span>
      </button>
      <ng-container *ngIf="currentSavannaCSVExportFound === true && currentSavannaCSVExport">
        <div>{{ 'Reports.CurrentInstrumentExportGenerated' | translate : { instrumentName: 'Savanna' } }}
          <div>
            {{ 'Reports.UTCLabel' | translate : {dateTime: (currentSavannaCSVExport?.dateGenerated
            | date: 'MM-dd-yyyy hh:mmaa' : 'UTC')} }}</div>
          <div>
            {{ 'Reports.LocalLabel' | translate : {dateTime: (currentSavannaCSVExport?.dateGenerated | date:
            'MM-dd-yyyy hh:mmaa')} }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentSavannaCSVExportFound === false && !currentSavannaCSVExport">
        {{ 'Reports.NoInstrumentExport' | translate : { instrumentName: 'Savanna' } }}
      </ng-container>
    </div>
    <button *ngIf="showSavannaCSVExportDownload" mat-raised-button color="primary" class="generate-csv"
      (click)="onGenerateExport(newExportTemplate, ExportType.InstrumentSavanna)">
      {{ 'Reports.GenerateNewInstrumentExport' | translate : { instrumentName: 'Savanna' } }}
    </button>

    <div *ngIf="showVisionCSVExportDownload" class="download-section">
      <button mat-icon-button class="download-report-button" (click)="onGetVisionCSVExport()">
        <span class="mat-button-wrapper">
          <mat-icon class="mat-icon">cloud_download</mat-icon>
        </span>
      </button>
      <ng-container *ngIf="currentVisionCSVExportFound === true && currentVisionCSVExport">
        <div>{{ 'Reports.CurrentInstrumentExportGenerated' | translate : { instrumentName: 'Vision' } }}
          <div>
            {{ 'Reports.UTCLabel' | translate : {dateTime: (currentVisionCSVExport?.dateGenerated
            | date: 'MM-dd-yyyy hh:mmaa' : 'UTC')} }}</div>
          <div>
            {{ 'Reports.LocalLabel' | translate : {dateTime: (currentVisionCSVExport?.dateGenerated | date:
            'MM-dd-yyyy hh:mmaa')} }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentVisionCSVExportFound === false && !currentVisionCSVExport">
        {{ 'Reports.NoInstrumentExport' | translate : { instrumentName: 'Vision' } }}
      </ng-container>
    </div>
    <button *ngIf="showVisionCSVExportDownload" mat-raised-button color="primary" class="generate-csv"
      (click)="onGenerateExport(newExportTemplate, ExportType.InstrumentVision)">
      {{ 'Reports.GenerateNewInstrumentExport' | translate : { instrumentName: 'Vision' } }}
    </button>

    <div class="download-section">
      <button mat-icon-button class="download-report-button" (click)="onGetRegistrationFailureExport()">
        <span class="mat-button-wrapper">
          <mat-icon class="mat-icon">cloud_download</mat-icon>
        </span>
      </button>
      <ng-container *ngIf="currentRegistrationFailureExportFound === true && currentRegistrationFailureExport">
        <div>{{ 'Reports.CurrentRegistrationFailureExportGenerated' | translate }}
          <div>
            {{ 'Reports.UTCLabel' | translate : {dateTime: (currentRegistrationFailureExport?.dateGenerated | date:
            'MM-dd-yyyy hh:mmaa' : 'UTC')} }}</div>
          <div>
            {{ 'Reports.LocalLabel' | translate : {dateTime: (currentRegistrationFailureExport?.dateGenerated | date:
            'MM-dd-yyyy hh:mmaa')} }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentRegistrationFailureExportFound === false && !currentRegistrationFailureExport">
        {{ 'Reports.NoRegistrationFailureExport' | translate }}
      </ng-container>
    </div>
    <button mat-raised-button color="primary" class="generate-csv"
      (click)="onGenerateExport(newExportTemplate, ExportType.RegistrationFailure)">
      {{ 'Reports.GenerateNewRegistrationFailureExport' | translate }}
    </button>
  </div>

  <ng-template #newExportTemplate>
    <form [formGroup]="reportForm" class="dialog-root">
      <h2>{{ generateDialogTitle }}</h2>
      <div class="message"> {{ generateDialogMessage }} </div>
      <mat-dialog-content>
        <mat-form-field class="full-width">
          <mat-label>{{ 'Reports.EmailAddress' | translate }}</mat-label>
          <input formControlName="email" matInput [placeholder]="'Reports.EmailAddress' | translate" #email>
          <mat-error *ngIf="reportForm.get('email').hasError('required')">
            {{ 'Reports.FormErrors.EmailRequired' | translate }}</mat-error>
          <mat-error *ngIf="reportForm.get('email').hasError('email')">
            {{ 'Reports.FormErrors.EmailValidFormat' | translate }}
          </mat-error>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
          {{ 'Global.Cancel' | translate }}</button>
        <button mat-raised-button [mat-dialog-close]="{ email: email.value }"
          [disabled]="reportForm.invalid || !email.value" color="primary">{{ 'Global.Generate' | translate }}</button>
      </mat-dialog-actions>
    </form>
  </ng-template>

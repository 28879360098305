<div class="container">
  <div class="container-grid">
    <h1>{{ 'AssayDefinitions.AssayDefinitions' | translate }}</h1>
    <div class="action-bar">
      <div>
        <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="onAddDefinition(editTemplate)">
          <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
        </button>
      </div>
      <div></div>
      <div>
        <button mat-icon-button class="refresh" (click)="loadDefinitions()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="code" matSortDirection="asc"
        matSortDisableClear="true">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Code' | translate }}</th>
          <td mat-cell *matCellDef="let definition"> {{definition.code}} </td>
        </ng-container>

        <ng-container matColumnDef="definitionName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Global.Name' | translate }}</th>
          <td mat-cell *matCellDef="let definition">{{definition.definitionName}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let definition">
            <div *ngIf="canAddUpdate" class="actions-container">
              <button *ngIf="canAddUpdate" mat-icon-button (click)="onEditDefinition(editTemplate, definition)">
                <mat-icon>edit</mat-icon>
              </button>

              <button *ngIf="canAddUpdate" mat-icon-button (click)="onDeleteDefinition(definition)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!canAddUpdate" class="actions-container">
              <button mat-icon-button (click)="onEditDefinition(editTemplate, definition)">
                <mat-icon>visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>

<ng-template #editTemplate let-data>
  <div class="dialog-root">
    <h2 *ngIf="canAddUpdate">
      {{ (data ? 'Global.Edit' : 'Global.New') | translate }} {{ 'AssayDefinitions.AssayDefinition' | translate }}</h2>
    <h2 *ngIf="!canAddUpdate">{{ 'Global.View' | translate }} {{ 'AssayDefinitions.AssayDefinition' | translate }}</h2>
    <mat-dialog-content>
      <div class="field-container">
        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.Code' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.Code' | translate" type="text" #code [value]="data?.code"
            [disabled]="!canAddUpdate">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'AssayDefinitions.DefinitionName' | translate }}</mat-label>
          <input matInput [placeholder]="'AssayDefinitions.DefinitionName' | translate" type="text" #name
            [value]="data?.definitionName" [disabled]="!canAddUpdate">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.CreatedDate' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.CreatedDate' | translate" type="text" #name [value]="data?.createdDate"
            [disabled]="true">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.CreatedBy' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.CreatedBy' | translate" type="text" #name [value]="data?.createdBy"
            [disabled]="true">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.LastModifiedDate' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.LastModifiedDate' | translate" type="text" #name
            [value]="data?.lastModifiedDate" [disabled]="true">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ 'Global.LastModifiedBy' | translate }}</mat-label>
          <input matInput [placeholder]="'Global.LastModifiedBy' | translate" type="text" #name
            [value]="data?.lastModifiedBy" [disabled]="true">
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="canAddUpdate" align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Cancel' | translate }}</button>
      <button mat-raised-button [mat-dialog-close]="{ code: code.value, name: name.value }"
        [disabled]="!code.value || !name.value" color="primary">
        {{ (data ? 'Global.Save' : 'Global.Create') | translate }}</button>
    </mat-dialog-actions>

    <mat-dialog-actions *ngIf="!canAddUpdate" align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Close' | translate }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>

<div class="page">
  <div class="page-grid">
    <h1>{{ 'InstrumentGroups.InstrumentGroups' | translate }}</h1>
    <div class="action-bar">
      <button *ngIf="canAddUpdate" mat-raised-button color="primary" (click)="addInstrumentGroup()">
        <mat-icon>add</mat-icon>{{ 'Global.New' | translate }}
      </button>
    </div>
    <div>
      <app-instrument-group-list [instrumentGroupsResult]="instrumentGroupsResult" [mode]="ListMode.All"
        (dataRequested)="dataRequested($event)" (editInstrumentGroup)="onEditInstrumentGroup($event)"
        (deleteInstrumentGroup)="onDeleteInstrumentGroup($event)" (refreshData)="getAllData()" [accessLevel]="accessLevel">
      </app-instrument-group-list>
    </div>
  </div>
</div>

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { AutomaticUserPermissions, GlobalUserPermissions, InstrumentType, UserPermission } from 'src/app/models';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectionAndCacheService } from 'src/app/services';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export class AddClaimsDialogResult {
  selectedGlobalClaims: UserPermission[];
  selectedInstrumentTypes: InstrumentType[];
  selectedInstrumentTypeClaims: UserPermission[];
}

@Component({
  selector: 'app-add-claims-dialog',
  templateUrl: './add-claims-dialog.component.html',
  styleUrls: ['./add-claims-dialog.component.scss']
})
export class AddClaimsDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public instrumentTypes: InstrumentType[] = [];
  public selectedInstrumentTypes = new FormControl<InstrumentType[]>([]);

  @ViewChild('instrumentTypeClaimInput') instrumentTypeClaimInput: ElementRef<HTMLInputElement>;
  @ViewChild('globalClaimInput') globalClaimInput: ElementRef<HTMLInputElement>;

  private globalClaims: UserPermission[] = [...GlobalUserPermissions.filter(globalClaim => !AutomaticUserPermissions.includes(globalClaim))
    .sort((a, b) => a.localeCompare(b))];
  public searchGlobalClaimControl = new FormControl('');
  public filteredSearchGlobalClaims: Observable<UserPermission[]>;
  public selectedGlobalClaims: UserPermission[] = [];

  private instrumentTypeClaims: UserPermission[] = [];
  public searchInstrumentTypeClaimControl = new FormControl('');
  public filteredSearchInstrumentTypeClaims: Observable<UserPermission[]>;
  public selectedInstrumentTypeClaims: UserPermission[] = [];

  public canSave = false;

  constructor(private mdDialogRef: MatDialogRef<AddClaimsDialogComponent>,
    private selectionAndCacheService: SelectionAndCacheService) {
  }

  public ngOnInit(): void {
    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
    this.instrumentTypeClaims = Object.values(UserPermission)
      .filter(claim => !this.globalClaims.includes(claim)
        && !AutomaticUserPermissions.includes(claim))
      .sort((a, b) => a.localeCompare(b));

    this.subscription.add(this.selectedInstrumentTypes.valueChanges.subscribe(() => {
      this.updateCanSave();
    }));

    this.filteredSearchInstrumentTypeClaims = this.searchInstrumentTypeClaimControl.valueChanges.pipe(
      startWith(null),
      map((claimSearchText: string | null) => AddClaimsDialogComponent.filterClaimSearchOptions(claimSearchText, this.instrumentTypeClaims, this.selectedInstrumentTypeClaims))
    );

    this.filteredSearchGlobalClaims = this.searchGlobalClaimControl.valueChanges.pipe(
      startWith(null),
      map((claimSearchText: string | null) => AddClaimsDialogComponent.filterClaimSearchOptions(claimSearchText, this.globalClaims, this.selectedGlobalClaims))
    );
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public add(): void {
    const result: AddClaimsDialogResult = {
      selectedGlobalClaims: this.selectedGlobalClaims,
      selectedInstrumentTypes: this.selectedInstrumentTypes.value,
      selectedInstrumentTypeClaims: this.selectedInstrumentTypeClaims
    };
    this.mdDialogRef.close(result);
  }

  public selectedInstrumentTypeClaimSearch(event: MatAutocompleteSelectedEvent): void {
    const selectedClaim = event.option.value as UserPermission;
    if (!this.selectedInstrumentTypeClaims.includes(selectedClaim)) {
      this.selectedInstrumentTypeClaims.push(selectedClaim);
    }
    this.instrumentTypeClaimInput.nativeElement.value = '';
    this.searchInstrumentTypeClaimControl.setValue(null);

    this.updateCanSave();
  }

  public selectedGlobalClaimSearch(event: MatAutocompleteSelectedEvent): void {
    const selectedClaim = event.option.value as UserPermission;
    if (!this.selectedGlobalClaims.includes(selectedClaim)) {
      this.selectedGlobalClaims.push(selectedClaim);
    }
    this.globalClaimInput.nativeElement.value = '';
    this.searchGlobalClaimControl.setValue(null);

    this.updateCanSave();
  }

  private static filterClaimSearchOptions(claimSearchText: string | null, availableClaims: UserPermission[], selectedClaims: UserPermission[]): UserPermission[] {
    let filteredClaims: UserPermission[];
    if (claimSearchText != null) {
      filteredClaims = availableClaims.filter(claim => claim.toLowerCase().indexOf(claimSearchText.toLowerCase()) >= 0);
    } else {
      filteredClaims = availableClaims.slice();
    }

    if (selectedClaims?.length > 0) {
      // filter out already selected countries
      return filteredClaims.filter(c => selectedClaims.indexOf(c) < 0);
    } else {
      return filteredClaims;
    }
  }

  public removeInstrumentTypeClaim(claim: UserPermission): void {
    const index = this.selectedInstrumentTypeClaims.indexOf(claim);

    if (index >= 0) {
      this.selectedInstrumentTypeClaims.splice(index, 1);
    }

    this.updateCanSave();
  }

  public removeGlobalClaim(claim: UserPermission): void {
    const index = this.selectedGlobalClaims.indexOf(claim);

    if (index >= 0) {
      this.selectedGlobalClaims.splice(index, 1);
    }

    this.updateCanSave();
  }

  private updateCanSave() {
    const hasGlobalClaims = this.selectedGlobalClaims.length > 0;
    const hasInstrumentTypes = this.selectedInstrumentTypes.value.length > 0;
    const hasInstrumentTypeClaims = this.selectedInstrumentTypeClaims.length > 0;

    this.canSave = hasGlobalClaims || (hasInstrumentTypes && hasInstrumentTypeClaims);
    if (this.canSave
      && ((hasInstrumentTypes && !hasInstrumentTypeClaims)
        || (!hasInstrumentTypes && hasInstrumentTypeClaims))) {
      // can't have an instrument type or instrument type claim selected and not have the other half selected
      this.canSave = false;
    }
  }
}

export const blobField = 'blobName';
export const fileNameField = 'fileName';
export const signatureKeyNameField = 'signatureKeyName';
export const firmwareIdField = 'firmwareId';
export const assayIdField = 'assayId';
export const assayInterfaceIdField = 'assayInterfaceId';
export const assayDefinitionIdField = 'assayDefinitionId';
export const deviceInformationIdField = 'deviceInformationId';
export const languageInterfaceIdField = 'languageInterfaceId';
export const languageDefinitionIdField = 'languageDefinitionId';
export const languageIdField = 'languageId';
export const instrumentGroupIdField = 'instrumentGroupId';
export const instrumentFileTypeIdField = 'instrumentFileTypeId';
export const instrumentTypeIdField = 'instrumentTypeId';
export const onlyPublishedFilesField = 'onlyPublishedFiles';
export const instrumentIdField = 'instrumentId';
export const majorField = 'major';
export const minorField = 'minor';
export const revisionField = 'revision';
export const serialNumberField = 'serialNumber';
export const instrumentTypeCodeField = 'instrumentTypeCode';
export const serialNumberOverrideIdField = 'serialNumberOverrideId';
export const emailField = 'email';

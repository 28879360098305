export interface ConfirmationDialogData {
  cancelText: string;
  confirmText: string;
  message: string;
  title: string;
  allowAMFForceDowngrade?: boolean;
}

export interface ConfirmationDialogResponse {
  result: boolean;
  forceAMFDowngrade?: boolean;
}

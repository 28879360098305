import { SelectionModel } from '@angular/cdk/collections';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogData, UploadDialogComponent, UploadDialogResponse } from 'src/app/components/upload-dialog/upload-dialog.component';
import { enumToArray, isFeatureEnabledForInstrumentTypeName, nameof } from 'src/app/shared/utils';
import { ConfigurationService, DataService, FileHeaderService, SelectionAndCacheService, NotificationService, UserPermissionService } from 'src/app/services';
import {
  Language, PublishedFlag, LanguageDefinition, LanguageInterface, Firmware, InstrumentType, InstrumentFileType, LanguageFileDialogData,
  PublishedFlagTranslateKeyMap, ValidationResult, formatValidationResultForDisplay, BlobUpload, ConfirmationDialogData, AccessLevel, UserPermission, LanguageForm,
  Features, ConfirmationDialogResponse, Version, InstrumentTypeModel, ClientConfig
} from 'src/app/models';
import { FormBuilder, Validators } from '@angular/forms';
import { forbiddenValueValidator } from 'src/app/shared/validators';
import { FeatureFlagConstants } from 'src/app/constants/featureFlag-constants';
import { debounceTime, distinctUntilChanged, take } from 'rxjs';
import { TranslateConstants } from 'src/app/constants/translate-constants';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/shared/translateServiceHelper';

@Component({
  selector: 'app-language-file-dialog',
  templateUrl: './language-file-dialog.component.html',
  styleUrls: ['./language-file-dialog.component.scss']
})
export class LanguageFileDialogComponent implements OnInit, OnDestroy {

  public PublishedFlag = PublishedFlag;
  public publishedFlags = enumToArray(PublishedFlag, 'Unknown');
  public languageDefinitions: LanguageDefinition[] = [];
  public languageInterfaces: LanguageInterface[] = [];
  public availableFirmwares: Firmware[] = [];
  public instrumentTypes: InstrumentType[] = [];
  public signatureVerificationKeyNames: string[] = [];
  public modelsForSelectedInstrumentType: InstrumentTypeModel[] = [];
  public showModelSelection = false;
  public instrumentFileTypes: InstrumentFileType[] = [];
  public selectedTabIndex = 0;
  public fileAvailable = false;
  public signatureVerificationEnabled = false;

  /* eslint-disable @typescript-eslint/unbound-method */
  public editLanguageForm = this.fb.group(
    {
      title: ['', [Validators.required, Validators.maxLength(300)]],
      lnItemNumber: ['', [Validators.required, Validators.maxLength(50)]],
      major: [0, [Validators.required, Validators.min(1)]],
      minor: [0, [Validators.required]],
      revision: [0, [Validators.required]],
      instrumentTypeId: [0, [forbiddenValueValidator(0)]],
      modelIds: [[], Validators.required],
      languageInterfaceId: [0, [forbiddenValueValidator(0)]],
      languageDefinitionId: [0, [forbiddenValueValidator(0)]],
      instrumentFileTypeId: [0, [forbiddenValueValidator(0)]],
      publishedFlag: ['', []],
      notes: ['', []],
      firmwareIds: [new Array<number>(), []],
      signatureVerificationKeyName: ['', Validators.required] // gets enabled if the Signature Validation Feature Flag is enabled AND if a file has been chosen
    });
  /* eslint-enable @typescript-eslint/unbound-method */

  private _language: Language;
  private subscription = new Subscription();
  private accessLevel: AccessLevel = AccessLevel.Unauthorized;

  private instrumentTypeIdPropertyName = 'instrumentTypeId';
  private modelIdsPropertyName = 'modelIds';
  private languageInterfaceIdPropertyName = 'languageInterfaceId';
  private instrumentFileTypeIdPropertyName = 'instrumentFileTypeId';
  private languageDefinitionIdPropertyName = 'languageDefinitionId';
  private majorVersionPropertyName = 'major';
  private minorVersionPropertyName = 'minor';
  private revisionVersionPropertyName = 'revision';
  private signatureVerificationKeyNamePropertyName = 'signatureVerificationKeyName';
  private features: Features;
  private clientConfiguration: ClientConfig;

  @ViewChild('file', { static: false }) private fileInput: ElementRef<HTMLInputElement>;


  public get firmwareIds(): number[] {
    return this.editLanguageForm.value.firmwareIds;
  }

  get canAddUpdate(): boolean {
    return this.accessLevel === AccessLevel.AddUpdate;
  }

  get language(): Language {
    return this._language;
  }

  set language(language: Language) {
    this._language = language;
    this.editLanguageForm.patchValue({
      title: language.title,
      lnItemNumber: language.lnItemNumber,
      major: language.version.major,
      minor: language.version.minor,
      revision: language.version.revision,
      instrumentTypeId: language.instrumentTypeId,
      languageInterfaceId: language.languageInterfaceId,
      languageDefinitionId: language.languageDefinitionId,
      instrumentFileTypeId: language.instrumentFileTypeId,
      publishedFlag: language.publishedFlag,
      notes: language.notes,
      firmwareIds: language.firmwareIds
    });

    this.disableOrEnableControlsByPublishedFlag(language);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LanguageFileDialogData,
    private dataService: DataService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private mdDialogRef: MatDialogRef<LanguageFileDialogComponent>,
    private userPermissionService: UserPermissionService,
    private fileHeaderService: FileHeaderService,
    private configurationService: ConfigurationService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private selectionAndCacheService: SelectionAndCacheService) {
    this.language = data.language;
    this.instrumentTypes = this.selectionAndCacheService.instrumentTypes;
    if (this.selectionAndCacheService.selectedInstrumentType) {
      this.language.instrumentTypeId = this.selectionAndCacheService.selectedInstrumentType.instrumentTypeId;
      this.editLanguageForm.patchValue({
        instrumentTypeId: this.language.instrumentTypeId
      });

      this.updateModelSelectionForInstrumentType();
    }
  }

  ngOnInit(): void {
    this.clientConfiguration = this.configurationService.getClientConfiguration();
    this.features = this.clientConfiguration?.features;

    this.subscription.add(this.userPermissionService.isReadyEvent$.subscribe(_ => {
      this.accessLevel = this.userPermissionService.getAccessLevel([UserPermission.ManageLanguageFiles], [UserPermission.ViewEverything], this.language.instrumentTypeId);
      if (!this.canAddUpdate) {
        this.editLanguageForm.disable();
      } else {
        this.editLanguageForm.enable();
        this.disableOrEnableControlsByPublishedFlag(this.language);
      }
      this.editLanguageForm.get(this.instrumentTypeIdPropertyName).disable();
      this.editLanguageForm.get(this.signatureVerificationKeyNamePropertyName).disable();
    }));

    this.subscription.add(
      forkJoin([
        this.selectionAndCacheService.isReadyEvent$.pipe(take(1)),
        this.dataService.getLanguageDefinitions(),
        this.dataService.getLanguageInterfaces()
      ])
        .subscribe((results: [boolean, LanguageDefinition[], LanguageInterface[]]) => {
          this.instrumentFileTypes = this.selectionAndCacheService.instrumentFileTypes;
          this.languageDefinitions = results[1];
          this.languageInterfaces = results[2];
          this.updateSignatureVerificationKeyNamesForInstrumentType();
        }));

    if (this.language?.languageId) {
      if (this.language?.publishedFlag === PublishedFlag.PendingPublish) {
        this.checkFileAvailability(this.language);
      } else if (this.language?.publishedFlag === PublishedFlag.Published) {
        this.fileAvailable = true;
      }

      this.editLanguageForm.get(this.modelIdsPropertyName).disable();
    }

    this.subscription.add(this.editLanguageForm.get(this.instrumentTypeIdPropertyName).valueChanges
      .pipe(
        // https://stackoverflow.com/a/59678874/10752002
        debounceTime(100), // ensuring that on creation of this control we don't enable/disable until final results
        distinctUntilChanged()
      ).subscribe((instrumentTypeId: number) => {
        if (this.language.instrumentTypeId !== instrumentTypeId) {
          this.language.instrumentTypeId = instrumentTypeId;
          this.disableOrEnableVersionControlsByInstrumentTypeId();
          this.updateModelSelectionForInstrumentType(true);
          this.updateSignatureVerificationKeyNamesForInstrumentType();
        }
      }));

    this.disableOrEnableVersionControlsByInstrumentTypeId();

    this.loadAvailableFirmwares(this.language?.instrumentFileTypeId, this.language?.instrumentTypeId, this.language?.languageInterfaceId);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public refreshLanguage(data: Language): void {
    this.subscription.add(this.dataService.getLanguageById(data.languageId).subscribe((language) => {
      this.language = language;

      if (this.language.languageId) {
        if (this.language?.publishedFlag === PublishedFlag.PendingPublish) {
          this.checkFileAvailability(this.language);
        } else if (this.language?.publishedFlag === PublishedFlag.Published) {
          this.fileAvailable = true;
        }
      }
    }));
  }

  public noFirmwaresCheckChanged(value: boolean): void {
    if (value) {
      this.editLanguageForm.patchValue({ firmwareIds: [] });
    }
  }

  public allFirmwaresCheckChanged(value: boolean): void {
    if (value) {
      this.editLanguageForm.patchValue({ firmwareIds: this.getAllFirmwareIds() });
    }
  }

  public getInstrumentTypeDisplayValue(instrumentTypeId: number): string {
    return this.instrumentTypes?.find(i => i.instrumentTypeId === instrumentTypeId)?.name;
  }

  public getInstrumentFileTypeDisplayValue(instrumentFileTypeId: number): string {
    return this.instrumentFileTypes?.find(i => i.instrumentFileTypeId === instrumentFileTypeId)?.name;
  }

  public getPublishedFlagDisplayValue(flag: PublishedFlag): string {
    return translate(this.translateService, PublishedFlagTranslateKeyMap.get(flag));
  }

  public getInterfacesForInstrument(instrumentId?: number): LanguageInterface[] {
    const selectedInstrumentTypeId = this.language.instrumentTypeId;
    return this.languageInterfaces?.filter(i => i.instrumentTypeId == selectedInstrumentTypeId);
  }

  public onDependencyValueChanged(instrumentTypeId?: number, instrumentFileTypeId?: number, languageInterfaceId?: number): void {
    this.loadAvailableFirmwares(instrumentFileTypeId, instrumentTypeId, languageInterfaceId);
  }

  public getSelectedFirmwares(options: SelectionModel<MatListOption>): number[] {
    return options.selected.map(s => s.value as number);
  }

  public getAllFirmwareIds(): number[] {
    return this.availableFirmwares.map(x => x.firmwareId);
  }

  public isLanguagePendingOrPublished(language: Language): boolean {
    return language?.publishedFlag === PublishedFlag.PendingPublish || language?.publishedFlag === PublishedFlag.Published;
  }

  public loadAvailableFirmwares(instrumentFileTypeId: number, instrumentTypeId: number, interfaceId: number): void {
    if (!instrumentFileTypeId || !instrumentTypeId || !interfaceId) {
      this.availableFirmwares = [];
      return;
    }

    this.subscription.add(
      this.dataService.getLanguageCompatibleFirmwares(instrumentFileTypeId, instrumentTypeId, interfaceId)
        .subscribe((firmwares: Firmware[]) => {
          this.availableFirmwares = firmwares;
        }));
  }

  public checkFileAvailability(language: Language): void {
    if (!language.languageId) {
      this.fileAvailable = false;
      return;
    }

    this.subscription.add(this.dataService.finalizePublishLanguage(language.languageId)
      .subscribe(
        {
          next: (response) => {
            this.fileAvailable = (response as unknown) === 'true' ? true : false;

            if (this.fileAvailable) {
              this.subscription.add(this.dataService.getLanguageById(language.languageId).subscribe((languageByIdResult) => {
                language.publishedFlag = languageByIdResult.publishedFlag;
                Object.assign(this.language, language);
              }));
            }
          }, error: () => this.fileAvailable = false
        }));
  }

  public async saveLanguage(file: File, close: boolean = false): Promise<void> {
    const language = {
      ...this.language,
      ...this.editLanguageForm.value
    } as LanguageForm;

    delete language.major;
    delete language.minor;
    delete language.revision;

    language.version = {
      major: this.editLanguageForm.get(nameof<Version>('major')).value as number,
      minor: this.editLanguageForm.get(nameof<Version>('minor')).value as number,
      revision: this.editLanguageForm.get(nameof<Version>('revision')).value as number
    };

    if (this.signatureVerificationEnabled && file !== undefined && file != null) {
      try {

        let header: string;
        if (file.name.endsWith('.zip')) {
          // read LPF header from manifest file
          header = await this.fileHeaderService.readManifestFileHeaderFromZip(file, 'language/manifest.json');
        } else {
          // read LPF  header
          header = await this.fileHeaderService.readHeader(file);
        }
        // read file header
        language.header = header;
      }
      catch (error) {
        // show error
        this.notificationService.errorExact(error as string);
        return;
      }
    }

    const signatureKeyName = this.editLanguageForm.value.signatureVerificationKeyName;
    this.subscription.add(this.dataService.validateLanguage(language, file?.name, signatureKeyName).subscribe(async (validationResult: ValidationResult) => {
      if (!validationResult.isValid) {
        const errors = formatValidationResultForDisplay(this.translateService, validationResult);
        this.notificationService.errorExact(errors);
      } else {
        this.language = language;

        const options: UploadDialogData = {
          title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UploadingKey, TranslateConstants.LanguageKey),
          observable: null
        };

        const isUpdate = this.language.languageId > 0;

        if (file) {
          this.subscription.add(this.dataService.getLanguageBlobUploadUrl(file).subscribe(async (blobUpload: BlobUpload) => {
            options.observable = this.dataService.uploadBlob(blobUpload, file);
            const dialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
              data: options,
              disableClose: true
            });

            const result = await firstValueFrom(dialogRef.afterClosed());

            if (result.success) {
              await this.addOrUpdateLanguage(options, isUpdate, close, file.name, blobUpload.blobName);
            }
          }));
        } else {
          await this.addOrUpdateLanguage(options, isUpdate, close);
        }
      }
    }));
  }

  public async onPublishLanguage(file?: Blob): Promise<void> {
    if (this.language.publishedFlag === PublishedFlag.Unpublished) {
      const options: ConfirmationDialogData = {
        title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.PublishTitleKey, TranslateConstants.LanguageKey),
        cancelText: translate(this.translateService, TranslateConstants.CancelKey),
        confirmText: translate(this.translateService, TranslateConstants.PublishKey),
        message: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.PublishWarningKey, TranslateConstants.LanguageKey)
      };

      const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
        data: options
      });

      const response = await firstValueFrom(dialogRef.afterClosed());

      if (response.result) {
        const uploadOptions: UploadDialogData = {
          title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UploadingKey, TranslateConstants.LanguageKey),
          observable: this.dataService.updateLanguage(this.language, null, null, null, true)
        };

        const uploadDialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
          data: uploadOptions,
          disableClose: true
        });

        const uploadResult = await firstValueFrom(uploadDialogRef.afterClosed());

        if (uploadResult.success) {
          this.subscription.add(this.dataService.publishLanguage(this.language.languageId)
            .subscribe(
              {
                complete: () => {
                  this.notificationService.success(TranslateConstants.PublishSuccessKey, { type: TranslateConstants.LanguageKey });
                  this.refreshLanguage(this.language);
                }, error: (e) => {
                  console.debug(e);
                }
              }));
        }
      }
    } else if (this.isLanguagePendingOrPublished(this.language)) {
      const options: ConfirmationDialogData = {
        title: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UnpublishTitleKey, TranslateConstants.LanguageKey),
        cancelText: translate(this.translateService, TranslateConstants.CancelKey),
        confirmText: translate(this.translateService, TranslateConstants.UnpublishKey),
        message: TranslateConstants.BuildTypeMessage(this.translateService, TranslateConstants.UnpublishWarningKey, TranslateConstants.LanguageKey),
      };

      const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResponse>(ConfirmationDialogComponent, {
        data: options
      });

      const response = await firstValueFrom(dialogRef.afterClosed());

      if (response.result) {
        this.subscription.add(this.dataService.updateLanguage(this.language, null)
          .subscribe(
            {
              next: (status: HttpEvent<unknown>) => {
                if (status.type === HttpEventType.Response) {
                  this.subscription.add(this.dataService.unpublishLanguage(this.language.languageId).subscribe(() => {
                    this.notificationService.success(TranslateConstants.UnpublishSuccessKey, { type: TranslateConstants.LanguageKey });
                    this.refreshLanguage(this.language);
                  }));
                }
              },
              error: (e) => {
                console.debug(e);
              }
            }));
      }
    }
  }

  public fileChanged(files: File[]): void {
    if (!files || files.length === 0) {
      return;
    }

    if (this.signatureVerificationEnabled) {
      this.editLanguageForm.get(this.signatureVerificationKeyNamePropertyName).enable();
    } else {
      this.editLanguageForm.get(this.signatureVerificationKeyNamePropertyName).disable();
    }
  }

  private async addOrUpdateLanguage(dialogOptions: UploadDialogData, isUpdate: boolean, close: boolean, fileName?: string, blobName?: string): Promise<void> {

    const signatureKeyName = this.editLanguageForm.value.signatureVerificationKeyName;
    if (isUpdate) {
      dialogOptions.observable = this.dataService.updateLanguage(this.language, fileName, blobName, signatureKeyName, true);
    } else {
      dialogOptions.observable = this.dataService.addLanguage(this.language, fileName, blobName, signatureKeyName, true);
    }
    const dialogRef = this.dialog.open<UploadDialogComponent, UploadDialogData, UploadDialogResponse>(UploadDialogComponent, {
      data: dialogOptions,
      disableClose: true
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result.success) {
      if (isUpdate) {
        this.notificationService.success(TranslateConstants.UpdateSuccessKey, { type: TranslateConstants.LanguageKey });
      } else {
        this.language.languageId = +result?.result;

        this.notificationService.success(TranslateConstants.CreateSuccessKey, { type: TranslateConstants.LanguageKey });
      }


      if (close) {
        this.close(false);
      } else {
        this.fileInput.nativeElement.value = null;
        this.refreshLanguage(this.language);
      }
    }
  }


  private disableOrEnableControlsByPublishedFlag(language: Language) {
    if (this.isLanguagePendingOrPublished(language)
      || language?.publishedFlag === PublishedFlag.PreviousPublished) {
      this.editLanguageForm.get(this.languageInterfaceIdPropertyName).disable();
      this.editLanguageForm.get(this.instrumentFileTypeIdPropertyName).disable();
      this.editLanguageForm.get(this.majorVersionPropertyName).disable();
      this.editLanguageForm.get(this.languageDefinitionIdPropertyName).disable();
    } else {
      this.editLanguageForm.get(this.languageInterfaceIdPropertyName).enable();
      this.editLanguageForm.get(this.instrumentFileTypeIdPropertyName).enable();
      this.editLanguageForm.get(this.majorVersionPropertyName).enable();
      this.editLanguageForm.get(this.languageDefinitionIdPropertyName).enable();
    }
  }

  private disableOrEnableVersionControlsByInstrumentTypeId() {
    // TODO: IF minor and revision versions become enabled for any versions, need to ensure they are disabled
    //        once Publish has been initiated
    // const instrumentTypeId = this.language.instrumentTypeId;

    const enableMinorVersion = false; // currently all instrument types only have Major version
    const minorVersionControl = this.editLanguageForm.get(this.minorVersionPropertyName);
    if (enableMinorVersion) {
      minorVersionControl.enable();
    } else {
      minorVersionControl.disable();
      minorVersionControl.setValue(0);
    }

    const enableRevisionVersion = false; // currently all instrument types only have Major version
    const revisionVersionControl = this.editLanguageForm.get(this.revisionVersionPropertyName);
    if (enableRevisionVersion) {
      revisionVersionControl.enable();
    } else {
      revisionVersionControl.disable();
      revisionVersionControl.setValue(0);
    }
  }

  private updateModelSelectionForInstrumentType(clearModels = false) {
    this.modelsForSelectedInstrumentType = this.selectionAndCacheService.getModelsForInstrumentTypeId(this.language.instrumentTypeId);

    // If only one model exists, auto select it
    if (this.modelsForSelectedInstrumentType.length === 1) {
      this.showModelSelection = false;
      this.language.modelIds = [this.modelsForSelectedInstrumentType[0].modelId];
    } else {
      this.showModelSelection = true;
      if (clearModels) {
        // clearing selection of models if instrument type changes
        this.language.modelIds = [];
      }
    }
    const temp = {};
    temp[this.modelIdsPropertyName] = this.language.modelIds;
    this.editLanguageForm.patchValue(temp);
  }

  private updateSignatureVerificationKeyNamesForInstrumentType() {
    const instrumentType = this.instrumentTypes.find(it => it.instrumentTypeId === this.language.instrumentTypeId);
    let foundKeyNames: string[] = null;
    if (instrumentType) {
      this.signatureVerificationEnabled = isFeatureEnabledForInstrumentTypeName(this.features, instrumentType.name, FeatureFlagConstants.AMFSignatureVerification);

      const assaySignatureVerificationKeysByInstrumentType = this.clientConfiguration.fileSignatureVerificationKeyNamesByFileTypeAndThenByInstrumentType['language'];
      if (assaySignatureVerificationKeysByInstrumentType) {
        foundKeyNames = assaySignatureVerificationKeysByInstrumentType[instrumentType.code];
      }
    } else {
      this.signatureVerificationEnabled = false;
    }

    this.signatureVerificationKeyNames.length = 0;

    if (foundKeyNames?.length > 0) {
      this.signatureVerificationKeyNames = [...foundKeyNames];
    } else {
      this.signatureVerificationKeyNames.push('N/A');
    }
  }
}


import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'app-time-form',
  templateUrl: './time-form.component.html',
  styleUrl: './time-form.component.scss'
})
export class TimeFormComponent implements OnInit, OnDestroy, OnChanges {

  private subscription: Subscription = new Subscription();

  /**
   * Indicates the expected time units for all the inputs (maxValue, minValue, and inputValue) and output (valueChanged).
   * This control allows the user to edit time in seconds, but will do conversion if expectedTimeUnits is 'ms' or 'm'
   *
   * 'ms' = milliseconds
   * 's'  = seconds
   * 'm'  = minutes
   */
  @Input() expectedTimeUnits: 'ms' | 's' | 'm';

  @Input() inputValue: number;
  @Input() minValue: number;
  @Input() maxValue: number;

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();
  private emittingValueChanged = false;

  private inputSeconds: number;
  public minSeconds: number;
  public maxSeconds: number;

  /* eslint-disable @typescript-eslint/unbound-method */
  public timeForm = this.fb.group({
    milliseconds: ['0'],
    seconds: [0, [Validators.required]], // min and max value validators will be applied based on bound properties
    minutes: ['0']
  });
  /* eslint-enable @typescript-eslint/unbound-method */

  constructor(private fb: FormBuilder, numberPipe: DecimalPipe) {
    this.subscription.add(this.timeForm.get('seconds').valueChanges.pipe(debounceTime(200))
      .subscribe(s => {

        const milliseconds = s * 1000;
        const minutes = s / 60;

        this.timeForm.patchValue({ milliseconds: numberPipe.transform(milliseconds), minutes: numberPipe.transform(minutes) });

        let outputValue = s;
        if (this.expectedTimeUnits === 'ms') {
          outputValue = s * 1000;
        } else if (this.expectedTimeUnits === 'm') {
          outputValue = s / 60;
        }
        this.emittingValueChanged = true;
        this.valueChanged.emit(outputValue);
        setTimeout(() => {
          this.emittingValueChanged = false;
        }, 100);
      }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateIfInputsChanges();
  }

  ngOnInit(): void {
    this.updateIfInputsChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateIfInputsChanges() {
    let tempInputSeconds = this.inputValue;
    let tempInputMinSeconds = this.minValue;
    let tempInputMaxSeconds = this.maxValue;
    if (this.expectedTimeUnits === 'ms') {
      tempInputSeconds = this.inputValue / 1000;
      tempInputMinSeconds = this.minValue / 1000;
      tempInputMaxSeconds = this.maxValue / 1000;
    } else if (this.expectedTimeUnits === 'm') {
      tempInputSeconds = this.inputValue * 60;
      tempInputMinSeconds = this.minValue * 60;
      tempInputMaxSeconds = this.maxValue * 60;
    }

    let minMaxUpdateRequired = false;
    if (this.minSeconds !== tempInputMinSeconds) {
      minMaxUpdateRequired = true;
      this.minSeconds = tempInputMinSeconds;
    }
    if (this.maxSeconds !== tempInputMaxSeconds) {
      minMaxUpdateRequired = true;
      this.maxSeconds = tempInputMaxSeconds;
    }

    if (minMaxUpdateRequired) {
      /* eslint-disable @typescript-eslint/unbound-method */
      this.timeForm.get('seconds').setValidators([
        Validators.required, Validators.min(this.minSeconds), Validators.max(this.maxSeconds)
      ]);
      /* eslint-enable @typescript-eslint/unbound-method */
    }

    if (this.inputSeconds !== tempInputSeconds) {
      this.inputSeconds = tempInputSeconds;
      if (this.emittingValueChanged) {
        console.debug('Emitting Value Changed, skipping patching time form group.');
      } else {
        this.timeForm.patchValue({ seconds: this.inputSeconds });
      }
    }
  }
}

<div class="dialog-root">
  <div class="dialog-header">
    <h2>{{ 'UserManagement.Dialog.ManageUser' | translate }}</h2>
    <button mat-icon-button class="refresh-dialog-btn" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
    <button class="close-button" mat-icon-button mat-dialog-close="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="editUserForm">
    <mat-dialog-content>
      <div class="full-height">
        <div class="field-container">
          <mat-form-field class="full-width">
            <mat-label>{{ 'UserManagement.DisplayName' | translate }} {{ 'Global.ReadOnly' | translate
              }}</mat-label>
            <input matInput [placeholder]="'UserManagement.DisplayName' | translate" type="text"
              [formControlName]="displayNameFieldName" class="black-text">
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'UserManagement.UserName' | translate }} {{ 'Global.ReadOnly' | translate }}</mat-label>
            <input matInput [placeholder]="'UserManagement.UserName' | translate" type="text"
              [formControlName]="userNameFieldName" class="black-text">
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'UserManagement.Dialog.UserId' | translate }}
              {{ 'Global.ReadOnly' | translate }}</mat-label>
            <input matInput [placeholder]="'UserManagement.Dialog.UserId' | translate" type="text"
              [formControlName]="userIdFieldName" class="black-text">
          </mat-form-field>
          <div class="flex">
            <h2>{{ 'UserManagement.Dialog.GlobalClaims' | translate }}</h2>
            <button class="add-claim-button" mat-raised-button color="primary" (click)="addClaims()">
              <mat-icon>add</mat-icon>{{ 'UserManagement.Dialog.AddClaims' | translate }}
            </button>
          </div>
          <div class="global-claims-section">
            <mat-chip-listbox [formArrayName]="globalClaimsFieldName">
              <mat-chip-option class="full-opacity" *ngFor="let claimControl of globalClaims.controls; index as claimIndex"
                (removed)="deleteGlobalClaim(claimIndex)" [removable]="canDeleteGlobalClaim(claimIndex)" matChipRemove
                [disabled]="!canDeleteGlobalClaim(claimIndex)">
                {{claimControl.value}}
                <mat-icon *ngIf="canDeleteGlobalClaim(claimIndex)" matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>

          <h2>{{ 'UserManagement.Dialog.ClaimsByInstrumentType' | translate }}</h2>
          <div [formArrayName]="instrumentTypeClaimsFieldName">
            <div class="instrument-type-claims-group"
              *ngFor="let claimGroup of instrumentTypeClaims.controls; index as i" [formGroupName]="i">
              <div class="instrument-type-header">
                <input matInput type="text" formControlName="instrumentTypeName">
              </div>
              <mat-chip-listbox>
                <mat-chip-option [formArrayName]="claimsFieldName" class="full-opacity"
                  *ngFor="let claimGroupControl of getClaimsForInstrumentType(i).controls; index as claimIndex"
                  (removed)="deleteInstrumentTypeClaim(i, claimIndex)"
                  [removable]="canDeleteInstrumentTypeClaim(i, claimIndex)" matChipRemove>
                  {{claimGroupControl.value}}
                  <mat-icon *ngIf="canDeleteInstrumentTypeClaim(i, claimIndex)" matChipRemove>cancel</mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="bottom-left-buttons">
      <button mat-raised-button color="primary" (click)="clearAllClaims()">
        {{ 'UserManagement.Dialog.ClearAllClaims' | translate }}
      </button>
    </div>

    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close="false" color="warn" cdkFocusInitial>
        {{ 'Global.Cancel' | translate }}</button>

      <button mat-raised-button color="primary" (click)="onSaveUser(true)" [disabled]="editUserForm.invalid">
        {{ 'Global.Save&Close' | translate }}</button>
      <button mat-raised-button color="primary" (click)="onSaveUser(false)" [disabled]="editUserForm.invalid">
        {{ 'Global.Save' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>

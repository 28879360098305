<div class="selectors" *ngIf="showSelectors">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'Global.InstrumentType' | translate }}</mat-label>
    <mat-select [(value)]="selectedInstrumentType">
      <mat-option *ngFor="let type of instrumentTypes" [value]="type">
        {{type.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="!hideModelsSelection && showModelsSelection">
    <mat-label>{{ (selectedModels.value?.length || 0) === 0 ? ('Global.None' | translate) :
      ('Global.InstrumentTypeModel' | translate) }}</mat-label>
    <mat-select [formControl]="selectedModels" multiple>
      <mat-select-trigger>
        <div
          *ngIf="(selectedModels.value?.length || 0) > 0 && (selectedModels.value?.length || 0) < modelsForSelectedInstrumentType.length">
          {{ selectedModels.value[0].name }}
          <span *ngIf="(selectedModels.value?.length || 0) > 1" class="additional-selection">
            (+{{ (selectedModels.value?.length || 0) - 1 }} {{ selectedModels.value?.length === 2 ? ('Global.Other' |
            translate) : ('Global.Others' | translate) }})
          </span>
        </div>
        <div *ngIf="(selectedModels.value?.length || 0) === modelsForSelectedInstrumentType.length">
          {{ 'Global.All' | translate }}
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let model of modelsForSelectedInstrumentType" [value]="model">
        {{model.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

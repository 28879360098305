export class UrlConstants {
  public static readonly languageVersionsUrl = 'language-versions';
  public static readonly languageFilesUrl = 'language-files';
  public static readonly languageDefinitionsUrl = 'language-definitions';
  public static readonly assayVersionsUrl = 'assay-versions';
  public static readonly assayFilesUrl = 'assay-files';
  public static readonly assayDefinitionsUrl = 'assay-definitions';
  public static readonly firmwareFilesUrl = 'firmware-files';
  public static readonly instrumentsUrl = 'instruments';
  public static readonly serialNumberParamId = 'id';
  public static readonly instrumentsDeepLinkUrl = `${UrlConstants.instrumentsUrl}/:${UrlConstants.serialNumberParamId}`;
  public static readonly unregisteredInstrumentsUrl = 'unregistered-instruments';
  public static readonly upgradeInstrumentsUrl = 'upgrade-instruments';
  public static readonly deleteFilesUrl = 'delete-files';
  public static readonly instrumentGroupsUrl = 'instrument-groups';
  public static readonly reportsUrl = 'reports';
  public static readonly certificateRefreshUrl = 'certificate-refresh';
  public static readonly manualApprovalUrl = 'manual-approval';
  public static readonly userManagementUrl = 'users';
  public static readonly blobTypeHeader = 'x-ms-blob-type';
  public static readonly blockBlobType = 'BlockBlob';
  public static readonly serialNumberOverridesUrl = 'serialnumber-overrides';
}

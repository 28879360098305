<div class="dialog-header">
  <h2>{{ 'Instruments.ManualFolderSearch' | translate }}</h2>
  <button class="close-button" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="main-content">
  <form [formGroup]="folderSearchForm">
    <mat-form-field class="full-width">
      <mat-label>{{ 'ManualFolderSearch.FolderPath' | translate }}</mat-label>
      <input matInput type="text" formControlName="folderPath" required
        [placeholder]="'ManualFolderSearch.FolderPath' | translate">
      <mat-hint>{{ 'ManualFolderSearch.FolderPathHint' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>{{ 'ManualFolderSearch.SearchText' | translate }}</mat-label>
      <input matInput type="text" formControlName="searchText"
        [placeholder]="'ManualFolderSearch.SearchText' | translate">
      <mat-hint>{{ 'ManualFolderSearch.SearchTextHint' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>{{ 'ManualFolderSearch.SortColumn' | translate }}</mat-label>
      <mat-select formControlName="sortColumn" required>
        <mat-option value="Name">{{ 'ManualFolderSearch.SortColumns.Name' | translate }}</mat-option>
        <mat-option value="Size">{{ 'ManualFolderSearch.SortColumns.Size' | translate }} </mat-option>
        <mat-option value="LastModifiedDate">{{ 'ManualFolderSearch.SortColumns.LastModifiedDate' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>{{ 'ManualFolderSearch.SortDirection' | translate }}</mat-label>
      <mat-select formControlName="sortDirection" required>
        <mat-option [value]=0>{{ 'ManualFolderSearch.SortDirections.Ascending' | translate }}</mat-option>
        <mat-option [value]=1>{{ 'ManualFolderSearch.SortDirections.Descending' | translate }} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>{{ 'ManualFolderSearch.ResultLimit' | translate }}</mat-label>
      <input matInput required type="number" formControlName="resultLimit"
        [placeholder]="'ManualFolderSearch.ResultLimit' | translate">
      <mat-hint align="end">{{ 'ManualFolderSearch.ResultLimitHint' | translate :{min: resultLimitMinMax.min, max:
        (resultLimitMinMax.max | number) } }}</mat-hint>
      <mat-error>{{ 'ManualFolderSearch.ResultLimitMinMaxError' | translate: {min: resultLimitMinMax.min, max:
        (resultLimitMinMax.max | number)}
        }}</mat-error>
    </mat-form-field>
  </form>
  <div class="full-width search">
    <button mat-raised-button color="primary" [disabled]="folderSearchForm.invalid" (click)="searchFolder()">
      <mat-icon>search</mat-icon> {{ 'Global.Search' | translate }}
    </button>
  </div>
</div>
